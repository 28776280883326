import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Landing from "../views/Landing.vue";
import Login from "../modules/system_management/eboard_auth/Login.vue";
import ActivateAccount from "../modules/system_management/eboard_auth/ActivateAccount.vue";
import SendPasswordResetEmail from "../modules/system_management/eboard_auth/SendPasswordResetEmail.vue";
import PasswordResetAccount from "../modules/system_management/eboard_auth/PasswordResetAccount.vue";
import ReplyToInvitation from "../modules/system_management/eboard_auth/ReplyToInvitation.vue";
import LandingBoard from "../modules/meeting_management/eboard_myboard/LandingBoard.vue";

import meetingsRouter from "../modules/meeting_management/meetingsRouter.js";
import systemRouters from "../modules/system_management/systemRouters.js";
import settingRouter from "../modules/eboard-settings/eboardSettings.js";
import store from "../store";
import ManagementHome from "@/views/ManagementHome";
import managementMeetingsRouter from "../modules/management_meeting_management/managementMeetingsRouter";
import managementCommitteeRouter from "../modules/management_committee/managementCommitteeRouter";
import ManagementCommitteeHome from "@/modules/management_committee/ManagementCommitteeHome.vue";
import AdminPanelHome from "@/modules/administration-panel/AdminPanelHome.vue";
import adminPanelRouter from "@/modules/administration-panel/adminPanelRouter";
import ChangePassword from "@/modules/e_board_shared/change_password/ChangePassword.vue";
import { USER_TYPE_ADMIN } from "./userTypes.js";
import BoardCommitteeHome from "@/modules/board_committee/BoardCommitteeHome.vue";
import boardCommitteeRouter from "@/modules/board_committee/boardCommitteeRouter";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/landing_page",
      name: "LandingPage",
      component: Landing,
      meta: { requiresGuest: true },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresGuest: true },
    },
    {
      path: "/activate/:userToken",
      name: "ActivateAccount",
      component: ActivateAccount,
      meta: { requiresGuest: true },
    },
    {
      path: "/password-reset/:userToken",
      name: "PasswordResetAccount",
      component: PasswordResetAccount,
      meta: { requiresGuest: true },
    },
    {
      path: "/invitation/:invitationToken",
      name: "ReplyToInvitation",
      component: ReplyToInvitation,
      meta: { requiresGuest: true },
    },
    {
      path: "/send_password_reset_email",
      name: "SendPasswordResetEmail",
      component: SendPasswordResetEmail,
      meta: { requiresGuest: true },
    },
    {
      path: "/",
      name: "Landing",
      component: LandingBoard,
    },
    {
      path: "",
      name: "Home",
      component: Home,
      meta: { requiresAuth: true },
      children: [...meetingsRouter],
    },
    {
      path: "/management",
      name: "ManagementHome",
      component: ManagementHome,
      meta: { requiresAuth: true },
      children: [...managementMeetingsRouter],
    },
    {
      path: "/management-committee",
      name: "ManagementCommitteeHome",
      component: ManagementCommitteeHome,
      meta: { requiresAuth: true },
      children: [...managementCommitteeRouter],
    },
    {
      path: "/board-committee",
      name: "BoardCommitteeHome",
      component: BoardCommitteeHome,
      meta: { requiresAuth: true },
      children: [...boardCommitteeRouter],
    },
    {
      path: "/adminpanel",
      name: "AdminPanelHome",
      component: AdminPanelHome,
      meta: { requiresAdmin: true },
      children: [...adminPanelRouter],
    },
    {
      path: "/change-password",
      name: "ChangePassword",
      component: ChangePassword,
      meta: { requiresAuth: true }
    },

    ...systemRouters,
    ...settingRouter,
  ],
});

router.beforeEach((to, from, next) => {
  // Check if the user is logged in
  const isUserLoggedIn = store.getters["AuthStore/isAuthenticated"];
  const userDetails = store.getters["AuthStore/user"];


  var userType = "INST"
  try {
    userType=userDetails.profileUser.edges[0].node.userType;
  } catch (error) {
    console.log(error);
  }


  if (to.matched.some((record) => record.meta.requiresAuth) || to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!isUserLoggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      if (to.matched.some((record) => record.meta.requiresAdmin) && userType !== USER_TYPE_ADMIN) {
        next('/');
      }
      else if (to.matched.some((record) => record.meta.requiresAdmin) && userType === USER_TYPE_ADMIN) {
        next();
      }
      else if (to.matched.some((record) => record.meta.requiresAuth) && userType === USER_TYPE_ADMIN) {
        next('/');
      }else{
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    next();
  } else {
    next();
  }
});

export default router;
