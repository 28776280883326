<template>
  <v-main>
    <LandingToolBar/>
    <div style="padding: 20px">
      <sequential-entrance fromRight>
        <v-container fluid>
          <div v-if="userAccount.profileUser.edges[0].node.userType=='INST'" class="w-full border border-dotted border-slate-400 p-5 intro-x">
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-auto">
                Attending Boards
              </h2>
              <button
                  data-carousel="important-notes"
                  data-target="prev"
                  class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide w-4 h-4"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </button
              >
              <button
                  data-carousel="important-notes"
                  data-target="next"
                  class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide w-4 h-4"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </button>
            </div>
            <v-row style="margin-top: 10px">
              <v-col
                  v-for="board in boards"
                  :key="board.boardUniqueId"
                  md="4"
                  sm="4"
              >
                <div class="intro-y mx-auto w-300"
                >
                  <div class="box px-4 py-4 mb-3 zoom-in border-2 border-primary dark:border-primary">

                    <div class="flex items-center mb-2"
                         @click="
                  changeSelectedBoard(
                    board.boardUniqueId,
                    board.boardDescriptions
                  )
                ">
                      <div
                          class="w-20 h-20 flex-none image-fit rounded-md overflow-hidden"
                      >
                        <img
                            alt="Midone Tailwind HTML Admin Template"
                            :src="
                          MEDIA_SERVER +
                          board.boardInstitution.institutionLogo
                        "
                        />
                      </div>
                      <div class="ml-4 mr-auto">
                        <div class="font-medium">
                          {{
                            board.boardInstitution.institutionShortform
                          }}
                        </div>
                        <div class="text-slate-500 text-xs mt-0.5">
                          {{ board.boardDescriptions }}
                        </div>
                      </div>
                      <div
                          class="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium"
                      >
                        As: {{ userDetails.lastName }}
                      </div>
                    </div>
                    <v-divider></v-divider>

                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                            class="bg-primary/10 px-3 py-2 rounded-b flex justify-content-between"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <div>
                            Member in <span class="font-medium text-primary">{{ board.boardCommittees.length }} Committees</span>
                          </div>
                          <v-icon>mdi-chevron-down</v-icon>
                        </div>
                      </template>
                      <v-list>
                        <v-list-item
                            v-for="(item, index) in board.boardCommittees"
                            :key="index"
                            @click="changeSelectedBoardCommittee(board.boardUniqueId, board.boardDescriptions, board.boardInstitution.institutionLogo, item)"
                            class="border-b cursor-pointer committeeLink intro-x"
                        >
                          <v-list-item-title>{{ item.committeeName }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <div v-if="userAccount.profileUser.edges[0].node.userType=='INST'" class="w-full border border-dotted border-slate-400 mt-5 p-5 -intro-x">
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-auto">
                Management & Committees
              </h2>
              <button
                  data-carousel="important-notes"
                  data-target="prev"
                  class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide w-4 h-4"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </button
              >
              <button
                  data-carousel="important-notes"
                  data-target="next"
                  class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide w-4 h-4"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </button>
            </div>
            <v-row style="margin-top: 10px">
              <v-col md="4" sm="4">
                <div v-if="(this.$store.state.AuthStore.user.managementUser||{}).edges.length > 0"
                     @click="changeSelectedManagement"
                     class="box px-4 py-4 mb-3 flex items-center zoom-in border-2 border-primary dark:border-primary">

                  <v-list-item>
                    <v-list-item-avatar color="white" size="90" tile>
                      <v-img
                          :src="
                          MEDIA_SERVER +
                          (this.$store.state.AuthStore.user.managementUser||{})
                            .edges[0].node.managementMemberManagement
                            .managementBoard.boardInstitution.institutionLogo
                        "
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{
                          (this.$store.state.AuthStore.user.managementUser || {})
                              .edges[0].node.managementMemberManagement
                              .managementName
                        }}
                      </div>
                      <v-list-item-title class="text-h6 mb-1">
                        Management
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>

              <!-- Management Committees -->
              <v-col md="4" sm="4" v-for="(myManagementCommittee, index) of myManagementCommittees" :key="index">
                <div
                    @click="changeSelectedManagementCommittee(myManagementCommittee)"
                    class="box px-4 py-4 mb-3 flex items-center zoom-in border-2 border-primary dark:border-primary">

                      <v-list-item>
                        <v-list-item-avatar color="white" size="90" tile>
                          <v-img
                              src="/arm.png"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            {{
                              myManagementCommittee.committeeName
                            }}
                          </div>
                          <v-list-item-title class="text-h6 mb-1">
                            Committee
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-col>
            </v-row>
          </div>


          <div v-if="userAccount.profileUser.edges[0].node.userType=='SDM'" class="w-full border border-dotted border-slate-400 mt-5 p-5 -intro-x">
            <div class="intro-x flex items-center h-10">
              <h2 class="text-lg font-medium truncate mr-auto">
                Administration Panel
              </h2>
              <button
                  data-carousel="important-notes"
                  data-target="prev"
                  class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide w-4 h-4"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </button
              >
              <button
                  data-carousel="important-notes"
                  data-target="next"
                  class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide w-4 h-4"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </button>
            </div>
            <v-row style="margin-top: 10px">
              <v-col md="4" sm="4">
                <div @click="changeSelectedAdminPanel"
                     class="box px-4 py-4 mb-3 flex items-center zoom-in border-2 border-primary dark:border-primary">

                  <v-list-item>
                    <v-list-item-avatar color="white" size="90" tile>
                      <img
                          :aspect-ratio="3 / 3"
                          src="@/assets/admin-panel.png"
                          alt="draft"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>

                      <v-list-item-title class="text-h6 mb-1">
                        Administration Panel
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>

            </v-row>
          </div>

        </v-container>
      </sequential-entrance>
    </div>
  </v-main>
</template>

<script>
import LandingToolBar from "../e_board_shared/navigations/LandingToolBar.vue";
import allBoardsByUser from "../../../graphql/eboard-boards-graphql/allBoardsByUser.gql";
import getMyAttendingBoard from "../../../graphql/eboard-boards-graphql/getMyAttendingBoard.gql";
import store from '@/store'

export default {
  name: "LandingMain",
  components: {
    LandingToolBar,
  },
  data: () => ({
    boards: []
  }),
  apollo: {
    allBoardsByUser: allBoardsByUser,
    getMyAttendingBoard: getMyAttendingBoard,
  },
  watch: {
    getMyAttendingBoard() {
      if (this.getMyAttendingBoard?.response?.status) {
        this.boards = this.getMyAttendingBoard?.data
      }
    }
  },
  methods: {
    async changeSelectedBoard(board_uuid, board_name) {
      await this.$store.dispatch("updateCurrentMyboardName", board_name);
      localStorage.setItem("my-boardUniqueId", board_uuid);
      await this.$store.dispatch("SettingsStore/getBoardMeetingSettings", {
        root: true
      });
      await this.$router.push({
        name: "Dashboard",
        params: {refreshed: true},
      });
    },
    async changeSelectedManagement() {
      const board_uuid = (this.$store.state.AuthStore.user.managementUser || {})?.edges[0]?.node?.managementMemberManagement?.managementBoard?.boardUniqueId;
      localStorage.setItem("my-boardUniqueId", board_uuid);
      await this.$store.dispatch("SettingsStore/getBoardMeetingSettings", {
        root: true
      });
      await this.$router.push({
        name: "ManagementDashboard",
        params: {refreshed: true},
      });
    },
    async changeSelectedManagementCommittee(managementCommittee) {
      localStorage.setItem("my-managementCommitteeUniqueId", managementCommittee.id)
      localStorage.setItem("my-managementCommitteeName", managementCommittee.committeeName)
      await this.$router.push({
        name: "ManagementCommitteeMeeting",
        params: {refreshed: true},
      });
    },
    async changeSelectedBoardCommittee(boardUniqueId, boardDescription, boardInstitutionLogo, boardCommittee) {
      localStorage.setItem("my-boardCommitteeUniqueId", boardCommittee.committeeUniqueId)
      localStorage.setItem("my-boardCommitteeName", boardCommittee.committeeName)
      localStorage.setItem("my-boardCommitteeLogo", boardInstitutionLogo)
      await this.$router.push({
        name: "BoardCommitteeMeeting",
        params: {refreshed: true},
      });
    },
    // admin panel
    async changeSelectedAdminPanel() {
      await this.$router.push({
        name: "AdminPanelDashboard",
        params: {refreshed: true},
      });
    },
  },
  async mounted() {
    await this.$store.dispatch('loadMyManagementCommittees')
  },
  computed: {
    userDetails() {
      return store.getters['AuthStore/user']
    },
    myManagementCommittees() {
      return this.$store.getters.getMyManagementCommittees;
    },
    userAccount(){
      const userDetails = store.getters['AuthStore/user']
     return userDetails
    }
  }
};
</script>

<style scoped>
.committeeLink:hover {
  background-color: rgba(63, 80, 181, 0.1) !important;
}

</style>
