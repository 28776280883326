<template>
  <v-row  justify="center">
    <v-dialog
        :key="newCommitteeKey"
        v-model="editCommitteeDetailsDialog"
        persistent
        max-width="70%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-account-multiple-plus-outline</v-icon> Edit Committee Details</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    v-model="committeename"
                    :rules="nameRules"
                    :counter="10"
                    label="Committee Name *"
                    prepend-icon="mdi-alpha-n"
                >
                </v-text-field>
                <v-text-field
                    v-model="descriptions"
                    :rules="nameRules"
                    :counter="10"
                    label="Descriptions / Responsibilities *"
                    required
                    prepend-icon="mdi-text-long"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              fab
              small
              class="white--text"

              @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
              color="blue darken-1"
              fab
              small
              class="white--text"
              :loading="loadoncreate"
              @click="updateCommittee"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import updateCommittee from '../../../graphql/eboard-committees-graphql/updateCommittee.gql'
import swal from "sweetalert2";

export default {
  data: () => ({
    loadoncreate:false,
    committeename:"",
    descriptions:"",
  }),
  props: ["editCommitteeDetailsDialog", "committee"],

  methods:{
    updateCommittee(){
      this.loadoncreate=true
      this.$apollo.mutate({
        mutation:updateCommittee,
        variables:{
          committeeDescriptions:this.descriptions,
          committeeName:this.committeename,
          committeeUniqueId:this.committee.key,
        },
      }).then((response)=>{
        if (response.data.updateCommittee.success) {
          setTimeout(() => {
            this.loadoncreate=false
            this.$store.state.CommitteeStore.boardCommitees.push({ divider: true, inset: true })
  
            const foundCommitee = this.$store.state.CommitteeStore.boardCommitees.find(commitee => commitee.key === this.committee.key)
            if (foundCommitee) {
              foundCommitee.title =this.committeename;
              foundCommitee.description = this.descriptions;
            }
  
            this.$emit("close")
            swal.fire({
                toast: true,
                icon: "success",
                title: response.data.updateCommittee.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
          }, 500);
        }
      })
    },
  },

  watch: {
    committeeTitle() {
      this.committeename = this.committee.title;
    },
    committeeSubTitle() {
      this.descriptions = this.committee.description;
    }
  },

  computed: {
    committeeTitle() {
      return this.committee.title
    },
    committeeSubTitle() {
      return this.committee.description
    }
  },

}
</script>