<template>
  <v-row justify="center">
    <v-dialog
        v-model="newInstitution"
        max-width="70%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <icon class="pt-1 mr-3" name="institution" width="w-5" height="h-5" color="#000"></icon><span class="text-h6">Add Institution</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-row class="ml-1">
                    <v-avatar size="50">
                      <v-img :src="`${mediaUrl+this.$store.getters.getLogoFullName}`"></v-img>
                    </v-avatar>
                    <v-file-input
                        outlined
                        label="Institution Logo"
                        v-model="institutionLogo"
                        @change="onChange"
                        accept="image/*"
                    ></v-file-input>

                  </v-row>
                  <v-text-field
                      outlined
                    v-model="institutionShortform"
                    :counter="15"
                    :rules="nameRules"
                    label="Institution Short Form *"
                    prepend-icon="mdi-text-short"
                    required >
                  </v-text-field>

                  <v-text-field
                      outlined
                      v-model="institutionDescriptions"
                      :counter="150"
                      label="Institution Name *"
                      prepend-icon="mdi-text-long"
                      required
                  >
                  </v-text-field>

                  <v-select
                      outlined
                      v-model="categoryUniqueId"
                      :items="getInstitutionCategoryList"
                      item-text="categoryName"
                      item-value="categoryUniqueId"
                      :rules="[v => !!v || 'Institution Category is required']"
                      label="Category Name"
                      required
                      prepend-icon="mdi-home-group"
                  ></v-select>

                  <v-text-field
                      outlined
                      v-model="institutionAdminUsername"
                      :counter="100"
                      label="Institution Admin Username *"
                      prepend-icon="mdi-account-circle"
                      hint="Admin username should be a valid email address"
                      required >
                  </v-text-field>

                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                color="red"
                class="text-capitalize mr-2"
                right
                small
                @click="closeDialog"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="createInstitution"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Icon from "../../../views/Icon.vue";

export default {
  name: "AddNewInstitution",
  components: {Icon},
  data: () => ({
    instituionUniqueId: "",
    institutionShortform: "",
    institutionDescriptions: "",
    institutionLogo: [],
    institutionAdminUsername: "",
    categoryUniqueId: "",
    dialog: false,
    newInstitutionKey:0,
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ]

  }),
  props: ["newInstitution"],

  async mounted() {
    await this.$store.dispatch('loadInstitutionLists')
  },

  computed: {
    getInstitutionCategoryList (){
      return this.$store.getters.getInstitutionCategoryList
    },

    mediaUrl() {
      let _mediaUrl = process.env.VUE_APP_MEDIA_SERVER;
      return `${_mediaUrl}`;
    }
  },

  methods: {
    async closeDialog() {
      this.institutionShortform = ""
      this.institutionDescriptions = ""
      this.institutionLogo = ""
      this.institutionAdminUsername = ""
      this.categoryUniqueId = ""
      await this.$emit('close-dialog')

    },

    async onChange() {
      let file = this.institutionLogo;

      const sendData = {
        'file': file,
        'type': file.type,
        'data': file.size,
      };

      await this.$store.dispatch('uploadInstitutionLogo', sendData);
    },

    async createInstitution(){
      let input = {
        categoryUniqueId: this.categoryUniqueId,
        institutionShortform: this.institutionShortform,
        institutionDescriptions: this.institutionDescriptions,
        institutionAdminUsername: this.institutionAdminUsername,
        institutionLogo: this.$store.getters.getLogoFullName
      }
      await this.$store.dispatch('createInstitutions', input)
      this.institutionShortform = ""
      this.institutionDescriptions = ""
      this.institutionAdminUsername = ""
      this.institutionLogo = ""
      this.categoryUniqueId = ""
      this.newInstitution = false
    },
  },


}
</script>

<style scoped>

</style>