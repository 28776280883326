<template>
  <div class="ma-7">
    <v-row>
      <v-spacer></v-spacer>
      <v-col lg="3" sm="3">
        <v-avatar
          class="border border-dotted border-slate-400"
          rounded="md"
          size="111"
        >
          <img
            class="pa-2"
            :src="`${mediaUrl}${(institutionDetails() || {}).institutionLogo}`"
            alt="In Logo"
          />
        </v-avatar>
        <v-file-input
          prepend-icon="mdi-camera"
          counter
          hide-input
          truncate-length="22"
          v-model="institutionLogo"
          @change="onChange"
          accept="image/*"
        ></v-file-input>
      </v-col>
    </v-row>
    <v-text-field
      label="Institution Name"
      readonly
      outlined
      dense
      v-model="(institutionDetails() || {}).institutionDescriptions"
    >
    </v-text-field>
    <v-text-field
      label="Shortform"
      readonly
      outlined
      dense
      v-model="(institutionDetails() || {}).institutionShortform"
      >{{ (institutionDetails() || {}).institutionDescriptions }}
    </v-text-field>
  </div>
</template>

<script>
export default {
  data: () => ({
    files: [],
    institutionLogo: "",
  }),

  computed: {
    mediaUrl() {
      let _mediaUrl = process.env.VUE_APP_MEDIA_SERVER;
      return `${_mediaUrl}`;
    },
  },

  methods: {
    async updateBoardSettingsValues(editedPart, currentValue) {
      let Setting = {
        settingType: editedPart,
        settingsValue: currentValue.target._value,
      };
      await this.$store.dispatch(
        "SettingsStore/updateInstitutionDetails",
        Setting
      );
      await this.$store.dispatch("SettingsStore/loadInstitutionDetails", {
        root: true,
      });
    },

    async onChange() {
      await this.$store.dispatch("showLoadingSpinner", true);
      let file = this.institutionLogo;

      var sendData = {
        file: file,
        type: file.type,
        data: file.size,
        userId: this.$route.params.user_id,
      };

      await this.$store.dispatch("uploadInstitutionLogo", sendData);
      let Setting = {
        settingType: "institution_logo",
        settingsValue: this.$store.getters.getLogoFullName,
      };
      await this.$store.dispatch(
        "SettingsStore/updateInstitutionDetails",
        Setting
      );

      await this.$store.dispatch("SettingsStore/loadInstitutionDetails", {
        root: true,
      });
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },

  async mounted() {
    await this.$store.dispatch("SettingsStore/loadInstitutionDetails", {
      root: true,
    });
  },
};
</script>

<style></style>
