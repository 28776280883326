<template>
  <v-container fluid class="my-5" app>
    <v-dialog persistent v-model="openDraftDialog" max-width="80%">
      <v-card>
        <v-card-title class="text-h6 indigo lighten-5">
          <v-icon size="30" class="mx-2">mdi-book-open-outline</v-icon>
          Draft News
          <v-spacer></v-spacer>
        </v-card-title>
        <v-row class="flex">
          <div class="m-2 w-full">
              <table class="table p-3 w-100 table-hover bg-white">
                <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">News Title</th>
                  <th scope="col">News Description</th>
                  <th scope="col">Expire Date</th>
                  <th scope="col">Expire Time</th>
                  <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="news in boardDraftUpdates" :key="news.newsUniqueId">
                  <th scope="row">
                    <v-avatar tile size="40">
                    <img
                        :aspect-ratio="3 / 3"
                        :key="news.value"
                        src="@/assets/draft2.png"
                        alt="draft"
                    />
                  </v-avatar>
                  </th>
                  <td>{{ news.newsTitle }}</td>
                  <td class="text-truncate" style="max-width: 20px;">{{ news.newsDescriptions }}</td>
                  <td>{{ news.newsExpireDate }}</td>
                  <td>{{ news.newsExpireTime }}</td>
                  <td>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                            @click="publishNews(news)"
                            class="btn btn-primary btn-sm mr-2"
                            type="button"
                        >
                          <icon name="news" width="w-5" height="w-5" color="#ffffff"></icon>
                        </div>
                      </template>
                      <span>Publish News</span>
                    </v-tooltip>
                    <v-tooltip bottom color="warning">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                            @click="editDraftNews(news)"
                            class="btn btn-warning btn-sm mr-2"
                            type="button"
                            text-white
                        >
                          <icon name="edit" width="w-5" height="w-5" color="#ffffff"></icon>
                        </div>
                      </template>
                      <span>Edit News</span>
                    </v-tooltip>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                            v-on="on"
                            v-bind="attrs"
                            @click="deleteDraftNews(news.newsUniqueId)"
                            class="btn btn-danger btn-sm"
                            type="button"
                        >
                          <icon name="delete" width="w-5" height="w-5" color="#ffffff"></icon>
                        </div>
                      </template>
                      <span>Delete News</span>
                    </v-tooltip>
                  </td>
                </tr>
                </tbody>
              </table>
          </div>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            medium
            color="pink"
            @click="closeDraftDialog"
            class="mt-5"
          >
            <v-icon class="white--text">mdi-close</v-icon>
            <strong style="color: white"> CLOSE </strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="filePreviewDialog" persistent width="500">
      <PublishedDocuments
        :documentList="suppotingDocs"
        @OpenfileDialog="openAppealAttachment"
        @closefileDialog="
          filePreviewDialog = false;
          suppotingDocs = [];
        "
      />
    </v-dialog>
    <DocumentViwer
      v-if="OpenfileDialog == true"
      :OpenfileDialog="OpenfileDialog"
      :docValue="docValue"
      :path="path"
      :title="title"
      @CloseDocumentViwer="OpenfileDialog = false"
    />
    <EditNews
      @close-dialog="editDraftNewsDialog=false"
      :edit-draft-news-dialog="editDraftNewsDialog"
      :news-unique-id="newsUniqueId"
      :news-title="newsTitle"
      :news-expire-time="newsExpireTime"
      :news-expire-date="newsExpireDate"
      :news-descriptions="newsDescriptions"
      :news-show-delivery="newsShowDelivery"
      :news-documents="supportingDocs"
    >
    </EditNews>
  </v-container>
</template>

<script>
import PublishedDocuments from "./PublishedDocuments.vue";
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";
import EditNews from "@/modules/meeting_management/eboard_news_updates/EditNews.vue";
import Icon from "@/views/Icon.vue";

export default {
  props: ["openDraftDialog", "editDraftNewsDialog"],
  components: {
    Icon,
    DocumentViwer,
    PublishedDocuments,
    EditNews
  },

  data() {
    return {
      supportingDocs: [],
      newsIsPublished: false,
      newsUniqueId: "",
      newsTitle: "",
      newsDescriptions: "",
      newsExpireDate: "",
      newsExpireTime: "",
      newsExpire: "",
      expireTime: "",
      newsShowDelivery: false,
      filePreviewDialog: false,
      OpenfileDialog: false,
      suppotingDocs: [],
      docValue: null,
      path: "",
      title: "",

    };
  },

  methods: {

    closeDraftDialog(){
      this.openDraftDialog = false
      this.$emit('closeDraftDialog')
    },

    async publishNews(news) {
      let documents_list = [];
      this.supportingDocs.forEach((document) => {
        documents_list.push({
         documentName: document.fileName,
         filePath: document.fullFileName,
        });
      });

      let input =  {
        newsIsDraft: false,
        newsIsPublished: true,
        newsUniqueId: news.newsUniqueId
      }

      await this.$store.dispatch("publishBoardNewsAndUpdates", input)
      await this.$store.dispatch("loadBoardPublishedNewsUpdates")
      await this.$store.dispatch("loadBoardDraftNewsUpdates")
      this.$emit('closeDraftDialog')

    },

    openSupportingDocument(document) {
      this.suppotingDocs = [];
      this.suppotingDocs = document;
      this.filePreviewDialog = true;
    },

    openAppealAttachment(fileName, fullFileName) {
      this.title = fileName;
      this.docValue = fullFileName;
      this.OpenfileDialog = true;
    },

    // Edit News
    async editDraftNews(news){
      console.log(news.documents)
      this.newsUniqueId = news.newsUniqueId
      this.newsTitle = news.newsTitle
      this.newsDescriptions = news.newsDescriptions
      this.newsExpireDate = news.newsExpireDate
      this.newsExpireTime = news.newsExpireTime
      this.newsDocuments = news.documents
      this.newsShowDelivery = news.newsShowDelivery


      this.editDraftNewsDialog=true
    },


    // Delete News
    async deleteDraftNews(newsUniqueId){
      await this.$store.dispatch('deleteDraftNews', newsUniqueId)
      await this.$store.dispatch('loadBoardDraftNewsUpdates')
    }


  },

  computed: {
    boardDraftUpdates() {
      return this.$store.state.NewsUpdatesStore.boardDraftedNewsUpdatesList;
    },

    boardSupportingDocs() {
      return this.$store.state.newsandUpdateSupportingDocument;
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardDraftNewsUpdates");
  },

};
</script>

<style>
</style>