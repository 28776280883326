var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-5",attrs:{"fluid":"","app":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"90%"},model:{value:(_vm.openUnpublishedDialog),callback:function ($$v) {_vm.openUnpublishedDialog=$$v},expression:"openUnpublishedDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 indigo lighten-5"},[_c('v-icon',[_vm._v("mdi-web")]),_vm._v(" Un-Published News "),_c('v-spacer')],1),_c('v-row',{staticClass:"flex"},[_c('div',{staticClass:"m-2 w-full"},[_c('table',{staticClass:"table p-3 w-100 table-hover bg-white"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("News Title")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("News Description")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Expire Date")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Expire Time")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.boardUnPublishedUpdates),function(news){return _c('tr',{key:news.newsUniqueId},[_c('th',{attrs:{"scope":"row"}},[_c('v-avatar',{attrs:{"tile":"","size":"40"}},[_c('img',{key:news.value,attrs:{"aspect-ratio":3 / 3,"src":require("@/assets/pending.png"),"alt":"draft"}})])],1),_c('td',[_vm._v(_vm._s(news.newsTitle))]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"20px"}},[_vm._v(_vm._s(news.newsDescriptions))]),_c('td',[_vm._v(_vm._s(news.newsExpireDate))]),_c('td',[_vm._v(_vm._s(news.newsExpireTime))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-primary btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.rePublishNews(news)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"news","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Republish News")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-warning btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.editUnPublishedNews(news)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"edit","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit News")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-danger btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDraftNews(news.newsUniqueId)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"delete","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Delete News")])])],1)])}),0)])])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"medium":"","color":"pink"},on:{"click":function($event){_vm.openUnpublishedDialog = !_vm.openUnpublishedDialog;
            _vm.$emit('closeUnpublishedDialog');}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-close")]),_c('strong',{staticStyle:{"color":"white"}},[_vm._v(" CLOSE ")])],1)],1)],1)],1),_c('edit-news',{attrs:{"edit-draft-news-dialog":_vm.editDraftNewsDialog,"news-title":_vm.newsTitle,"news-descriptions":_vm.newsDescriptions,"news-expire-date":_vm.newsExpireDate,"news-expire-time":_vm.newsExpireTime,"news-show-delivery":_vm.newsShowDelivery,"news-documents":_vm.supportingDocs},on:{"close-dialog":function($event){_vm.editDraftNewsDialog=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }