<template>
  <v-row align="center" justify="center">
    <v-overlay :absolute="absolute" :value="overlay">
      <v-sheet elevation="24" width="850" color="white">
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4" style="color: indigo">
              Are you still there ?
            </div>
            <v-list-item-title class="text-h6 mb-1" style="color: indigo">
              Automatic Logout for Your Protection 
            </v-list-item-title>
            <v-list-item-subtitle style="color: indigo; padding-bottom: 20px">
              Due to Inactivity, your current work session is about to expire.
              For your security, e-Board manage sessions automatically end after
              10 minutes of inactivity. Your current session will expire in
              minute. Press any key or click anywhere to continue.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-sheet>
    </v-overlay>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      events: ["click", "mousemove", "keypress", "mousedown", "scroll", "load"],
      warningTimer: null,
      actualTimer: null,
      countDown: 30,
      warningDialog: false,
      overlay: false,
      absolute: true,
      zIndex: 1,
    };
  },
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
  },
  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    }, this);
    this.resetTimer();
  },
  methods: {
    setTimers() {
      this.warningTimer = setTimeout(() => {
        this.startWarningMessage();
      }, 3000000);
      this.actualTimer = setTimeout(() => {
        this.logOutUser();
      }, 600000);
    },

    async startWarningMessage() {
      if (localStorage.getItem("apollo-token") != null) {
        this.overlay = true;
        this.warningDialog = true;
      }
    },

    logOutUser() {
      if (localStorage.getItem("apollo-token") != null) {
        localStorage.removeItem("vuex");
        localStorage.removeItem("my-boardUniqueId");
        localStorage.removeItem("my-boardKey");
        localStorage.removeItem("apollo-token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("my-managementUniqueId");
        localStorage.removeItem("my-managementCommitteeUniqueId");
        localStorage.removeItem("my-managementCommitteeName");
        this.$store.dispatch('setLastVisitedRoute', this.$router.currentRoute.fullPath);
        this.$router.go("/landing_page");
      }
    },

    resetTimer() {
      this.overlay = false;
      this.warningDialog = false;
      clearTimeout(this.warningTimer);
      clearTimeout(this.actualTimer);
      this.setTimers();
    },
  },
};
</script>

<style>
</style>