<template>
  <div>
    <v-dialog persistent v-model="startVideoCallDialog" width="50%">
      <v-card color="indigo" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5"
              >Welcome to e-Board Video Call</v-card-title
            >

            <v-card-subtitle
              >Start or join video call on real time anywhere and
              anytime</v-card-subtitle
            >

            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                small
                @click="callVideoInviteeControlDialog"
              >
                <v-icon small>mdi-phone-outgoing</v-icon>
                START CALL
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="callMyVideoInvitationControlDialog"
                class="ml-2 mt-5"
                outlined
                rounded
                small
              >
                <v-icon small>mdi-phone-in-talk</v-icon>
                JOIN MEETING
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="$emit('endVideoCallDialog')"
                class="ml-2 mt-5"
                outlined
                rounded
                small
              >
                <v-icon small>mdi-close</v-icon>
                CANCEL
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar class="ma-3" size="125" tile>
            <v-img src="videoCall.png"></v-img>
          </v-avatar>
        </div>
      </v-card>
    </v-dialog>
    <VideoCallInviteeSelection
      @endVideoInviteesCallDialog="startVideoCallInviteeDialog = false"
      :startVideoCallInviteeDialog="startVideoCallInviteeDialog"
    />
    <VideoCallInvitations
      @endMyVideoInvitationCallDialog="startMyVideoCallInvitationDialog = false"
      :startMyVideoCallInvitationDialog="startMyVideoCallInvitationDialog"
    />
  </div>
</template>

<script>
import VideoCallInviteeSelection from "./VideoCallInviteeSelection.vue";
import VideoCallInvitations from "./VideoCallInvitations.vue";
export default {
  components: {
    VideoCallInviteeSelection,
    VideoCallInvitations,
  },
  props: ["startVideoCallDialog"],
  data: () => ({
    startVideoCallInviteeDialog: false,
    startMyVideoCallInvitationDialog: false,
  }),
  methods: {
    callVideoInviteeControlDialog() {
      this.startVideoCallInviteeDialog = true;
    },
    callMyVideoInvitationControlDialog() {
      this.startMyVideoCallInvitationDialog = true;
    },
  },
};
</script>

<style>
</style>