import BoardCommitteeMeeting
    from "@/modules/board_committee/board_committee_meeting/BoardCommitteeMeeting.vue";
import MeetingDetails from "@/modules/meeting_management/eboard-meetings/MeetingDetails.vue";


export default [
    // {
    //     path: 'my-dashboard',
    //     name: 'ManagementCommitteeDashboard',
    //     component: ManagementCommitteeDashboard,
    //     meta: {requiresAuth: true},
    // },
    {
        path: 'meeting-schedules',
        name: 'BoardCommitteeMeeting',
        component: BoardCommitteeMeeting,
        meta: {requiresAuth: true},
    },
    {
        path: 'meeting-schedules/:meeting_uuid',
        name: 'BoardCommitteeMeetingDetails',
        component: MeetingDetails,
        meta: {requiresAuth: true},
    },
]
