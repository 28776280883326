<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col class="text-lg font-weight-medium"> List of all Institutions</v-col>
      <v-spacer></v-spacer>
        <div class="relative">
          <input v-model="institutionNameQuery" type="text" class="form-control py-3 px-4 w-full lg:w-64 box pr-10" placeholder="Search by name ...">
          <i class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 text-slate-500" data-lucide="search"></i>
        </div>
<!--        <div class="relative ml-2">-->
<!--          <input v-model="searchQuery" type="text" class="form-control py-3 px-4 w-full lg:w-64 box pr-10" placeholder="Search by category...">-->
<!--          <i class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 text-slate-500" data-lucide="search"></i>-->
<!--        </div>-->

      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="clearLogoFullName"
              v-bind="attrs"
              v-on="on"
              color="indigo"
              class="mx-2 my-2"
              dark
              outlined
              small

          >
            <v-icon small>mdi-plus</v-icon> &nbsp; New Institution</v-btn
          >
        </template>
        <span>Add New Institution</span>
      </v-tooltip>
    </v-row>
    <div class="row">
      <div class="col-md-6  m-2 intro-y" v-for="institution in getInstitutions" :key="institution.id">

          <div class="p-5 bg-white flex shadow-sm w-100 items-center">
            <v-img max-width="8%" :src="MEDIA_SERVER+institution.institutionLogo" class="mr-5"></v-img>
            <div class="row">
              <div class="col-md-9 flex-row">
                <h2 class="font-weight-medium">{{institution.institutionShortform}}</h2>
                <span class="">{{institution.institutionDescriptions}}</span> <br>
                <b>Admin:</b> <span class="">{{institution.institutionAdminUsername}}</span> <br>
                <b>Category:</b> <span class="">{{institution.institutionCategory.categoryName}}</span>
              </div>
              <div class="col-md-3 justify-center align-content-center">
                  <v-row class="mt-2 justify-space-around">
                      <v-tooltip bottom color="warning">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" @click="editInstitutions(institution)" class="btn btn-warning btn-sm p-1" type="button" >
                            <v-icon color="white">mdi-pencil-box-outline</v-icon>
                          </div>
                        </template>
                        <span>Edit Institution</span>
                      </v-tooltip>
                      <v-tooltip bottom color="#3F51B5">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" @click="editInstitutionsAdmin(institution)" class="btn btn-sm p-1" type="button" style="background-color: #3F51B5">
                            <v-icon color="white">mdi-account-outline</v-icon>
                          </div>
                        </template>
                        <span>Assign Admin</span>
                      </v-tooltip>
                      <v-tooltip bottom color="#9FE080">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" @click="editInstitutionsCategory(institution)" class="btn btn-sm p-1" type="button" style="background-color: #9FE080">
                            <v-icon color="white">mdi-home-group</v-icon>
                          </div>
                        </template>
                        <span>Shift Category</span>
                      </v-tooltip>
                  </v-row>
              </div>
            </div>
          </div>
      </div>
    </div>


    <add-new-institution @close-dialog="newInstitution=false" :newInstitution="newInstitution"></add-new-institution>
    <edit-institution
        @close-dialog="editInstitution=false"
        :edit-institution="editInstitution"
        :institution-unique-id="institutionUniqueId"
        :institution-shortform="institutionShortform"
        :institution-descriptions="institutionDescriptions"
        :institution-admin-username="institutionAdminUsername"
        :institution-logo="institutionLogo"
        :category-unique-id="categoryUniqueId"

    ></edit-institution>
    <edit-institution-admin
      @close-dialog="editInstitutionAdmin=false"
      :edit-institution-admin="editInstitutionAdmin"
      :institution-unique-id="institutionUniqueId"
      :institution-shortform="institutionShortform"
    >
    </edit-institution-admin>
    <edit-institution-category
        @close-dialog="editInstitutionCategoryDialog=false"
        :edit-institution-category-dialog="editInstitutionCategoryDialog"
        :institution-unique-id="institutionUniqueId"
    >
    </edit-institution-category>
  </v-container>
</template>

<script>

import AddNewInstitution from "./AddNewInstitution.vue";
import EditInstitution from "./EditInstitution.vue";
import EditInstitutionAdmin from "@/modules/administration-panel/admin-panel-institutions/EditInstitutionAdmin.vue";
import EditInstitutionCategory
  from "@/modules/administration-panel/admin-panel-institutions/EditInstitutionCategory.vue";

export default {
  name: "AdminPanelInstitution",
  components: {EditInstitutionCategory, AddNewInstitution, EditInstitution, EditInstitutionAdmin},
  data(){
    return {
      newInstitution: false,
      editInstitution: false,
      editInstitutionAdmin: false,
      editInstitutionCategoryDialog: false,
      institutionUniqueId: "",
      institutionShortform: "",
      institutionDescriptions: "",
      institutionLogo: "",
      institutionAdminUsername: "",

      categoryUniqueId: "",

      searchQuery:"",
      institutionNameQuery: "",
      institutionsList: [],

    }
  },
  async mounted() {
    await this.$store.dispatch('loadInstitutionLists')
  },

  computed: {


    getInstitutions (){
      let institutions =  this.$store.getters.getInstitutionList;
      if (this.institutionNameQuery=="") {
        return institutions
      }else{
        return institutions.filter(institution => {
          return institution.institutionShortform.toLowerCase().includes(this.institutionNameQuery.toLowerCase());
        });
      }
    },

    getInstitutionCategoryList(){
      let institutions = this.$store.getters.getInstitutionList;
      if (this.searchQuery=="") {
        return institutions
      }else{
        return institutions.filter(institution => {
          return institution.institutionCategoryList[0].categoryName.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }
    }
  },

  methods: {

    async clearLogoFullName(){
      await this.$store.dispatch('clearLogoFullName')
      this.newInstitution=true
    },
    editInstitutions(institution) {
      this.institutionUniqueId = institution.institutionUniqueId
      this.institutionShortform = institution.institutionShortform
      this.institutionDescriptions = institution.institutionDescriptions
      this.institutionLogo = institution.institutionLogo
      this.institutionAdminUsername = institution.institutionAdminUsername
      this.categoryUniqueId = institution.categoryUniqueId

      this.editInstitution=true

    },

    editInstitutionsCategory(institution) {
      this.institutionUniqueId = institution.institutionUniqueId
      this.categoryUniqueId = institution.categoryUniqueId

      this.editInstitutionCategoryDialog=true
    },

    editInstitutionsAdmin(institution) {
      this.institutionUniqueId = institution.institutionUniqueId
      this.institutionAdminUsername = institution.institutionAdminUsername
      this.institutionShortform = institution.institutionShortform

      this.editInstitutionAdmin=true
    },
  }

}
</script>

<style scoped>

</style>