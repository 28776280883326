<template>
  <v-row justify="center">
    <v-dialog
        :key="newCommitteeKey"
        v-model="newManagementCommittee"
        max-width="50%"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"
          ><v-icon>mdi-account-multiple-plus-outline</v-icon> Edit Management
            Committee</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <div class="w-full mt-2">
                <div class="w-full">
                  <v-text-field
                      v-model="committeeName"
                      :counter="10"
                      :rules="nameRules"
                      label="Committee Name *"
                      prepend-icon="mdi-alpha-n"
                      required
                  >
                  </v-text-field>

                  <v-text-field
                      v-model="committeeDescriptions"
                      :counter="10"
                      :rules="nameRules"
                      label="Descriptions / Responsibilities *"
                      prepend-icon="mdi-text-long"
                      required
                  >
                  </v-text-field>

                  <div>
                    <span>Committee Members</span>
                    <div class="row" v-for="(member, index) of managementCommitteeMembers"
                           :key="index">
                      <div class="col-md-6">
                        <v-select
                            v-model="member.memberUserId"
                            :items="boardMembers"
                            :menu-props="{ left: true, offsetY: true }"
                            attach
                            chips
                            item-text="name"
                            item-value="id"
                            label="Select Committee Members"
                        ></v-select>
                      </div>
                      <div class="col-md-6 flex items-center">
                        <div class="w-full">
                          <v-select
                              v-model="member.memberLevel"
                              :items="enumToArray(managementMemberLevels)"
                              :menu-props="{ left: true, offsetY: true }"
                              attach
                              chips
                              item-text="name"
                              item-value="id"
                              label="Select Committee Members"
                          ></v-select>
                        </div>
                        <div class="flex">
                          <v-btn icon class="ml-2"
                                 v-if="index !== 0"
                                 @click="removeCommitteeMember(index)">
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                          <v-btn icon
                                 v-if="index === managementCommitteeMembers.length - 1"
                                 @click="addCommitteeMember">
                            <v-icon color="primary">mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn color="red"
                     class="text-capitalize mr-2"
                     right
                     small
                     @click="$emit('closeEditManagementCommitteeDialog')"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff"></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="editManagementCommittee"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff"></icon>
                Save Committee
              </v-btn>
            </div>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

import allBoardUsers from "../../../graphql/eboard-boards-graphql/allBoardUsers.gql";
import {ManagementMemberLevelTypeEnum} from "../../../interfaces/global.interface";
import committeeMembers from "../../meeting_management/eboard-committees/CommitteeMembers.vue";
import Icon from "../../../views/Icon.vue";

export default {
  name: "EditManagementCommittee",
  components: {Icon},
  computed: {
    committeeMembers() {
      return committeeMembers
    }
  },
  data: () => ({
    newCommitteeKey: 0,
    committeeMembersList: "",
    managementMemberLevels: ManagementMemberLevelTypeEnum,
    submitDisabled: true,
    dialog: false,
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length <= 100 || "Name must be less than 100 characters",
    ],
    boardMembers: [],
  }),
  props: [
    'newManagementCommittee',
    'committeeName',
    'committeeDescriptions',
    'managementCommitteeMembers',
    'committeeUniqueId',
  ],
  methods: {

    addCommitteeMember() {
      this.managementCommitteeMembers.push({memberUserId: '', memberLevel: ''})
    },
    removeCommitteeMember(index) {
      this.managementCommitteeMembers.splice(index, 1)
    },


    editManagementCommittee() {
      let membersFound = true
      this.managementCommitteeMembers = this.managementCommitteeMembers.map((member) => {
        if (member.memberUserId === '') membersFound = false
        return { memberUserId: member.memberUserId, memberLevel: member.memberLevel }
      })
      let input = {
        committeeUniqueId: this.committeeUniqueId,
        committeeName: this.committeeName,
        committeeDescriptions: this.committeeDescriptions,
        committeeMembers: membersFound ? this.managementCommitteeMembers : []
      }
      this.$store.dispatch('editManagementCommittee', input)
      this.$emit('closeEditManagementCommitteeDialog')
      this.committeeName = ''
      this.committeeDescriptions = ''
      this.managementCommitteeMembers = []
    },

    loadBoardMambers() {
      this.$apollo
          .query({
            query: allBoardUsers,
            variables: {
              boardUniqueId: localStorage.getItem("my-boardUniqueId"),
              pageNumber: 1,
            },
          })
          .then((response) => {
            var tem_users = [];
            tem_users = [...response.data.boardUsers.boardUsers];
            tem_users.push(...response.data.boardUsers.managementUsers);
            tem_users.forEach((user) => {
              this.boardMembers.push({
                id: user.id,
                name: user.firstName + " " + user.lastName,
              });
            });
          });
    },
  },
  created() {
    this.loadBoardMambers();
  },
  watch: {
    newManagementCommittee() {
      if (this.newManagementCommittee) {

        if (this.managementCommitteeMembers.length === 0) {
          this.managementCommitteeMembers = [{memberUserId: '', memberLevel: ''}]
        }
      }
    }
  }
}
</script>

<style scoped>

</style>