<template>
    <v-dialog
        v-model="builderModeDialogy"
        max-width="600px"
        persistent
      >
        <v-card>
          <v-card-title>
            <v-icon>mdi-creation</v-icon>  <span>Build Agenda</span>
          </v-card-title>
          <v-card-text>
              <span>Would you like to clone a previous agenda, or use our best practice template?</span>
                  <v-radio-group
                    v-model="bestPractise"
                    row
                    >
                    <v-col
                        cols="6"
                        sm="6"
                        md="6"
                    >
                    <v-alert
                    elevation="4"
                    outlined
                    >
                    <v-radio
                        label="Clone another Agenda"
                        value="clone"
                        @change="hidden=!hidden;disabled=true"
                    ></v-radio>
                    </v-alert>
                    </v-col>
                    <v-col
                        cols="6"
                        sm="6"
                        md="6"
                    >
                    <v-alert
                    elevation="4"
                    outlined
                    >
                    <v-radio
                        label="Best Practice Template"
                        value="template"
                        @change="hidden=false;disabled=false"
                    ></v-radio>
                    </v-alert>
                    </v-col>
            </v-radio-group>
            <v-select
              v-model="selectedMeeting"
              :items="managementMeetings"
              label="Select a Meeting to Clone"
              item-value="meetingUniqueId"
              item-text="meetingTitle"
              v-if="hidden==true"
              @change="disabled=false"
            ></v-select>
          </v-card-text>

          <v-card-actions>
              <v-spacer></v-spacer>
              <v-progress-circular
                indeterminate
                color="primary"
                :width="2"
                style="padding-right:10px"
                v-if="progressShown"
                >
                </v-progress-circular>
            <v-btn
              color=""
              text
              right
              outlined
              @click="$emit('closeBuilderModeDialogy')"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :disabled="disabled"
              right
              @click="progressShown = true;openAgendaBuilderWindow()"
            >
              Build Agenda
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props:['builderModeDialogy','currentMeeting'],
    data(){
        return{
            disabled:true,
            progressShown:false,
            bestPractise:null,
            hidden:false,
            dialog2:false,
            selectedMeeting:''
        }
    },
    methods:{
       async openAgendaBuilderWindow(){
            this.$store.dispatch('showLoadingSpinner',true)

            if(this.bestPractise=="template"){
                await this.$store.dispatch('creatManagementMeetingAgendaFromTemplate',this.currentMeeting)
            }else{
              await this.$store.dispatch('createManagementMeetingCloneAgendas',{currentMeeting: this.currentMeeting, cloneMeeting: this.selectedMeeting})
            }

            this.disabled=true
            // this.bestPractise=null
            this.$store.dispatch('showLoadingSpinner',false)
            this.$router.go('')
        }
    },
    computed: {
      managementMeetings(){
        const meetingList = []
        this.$store.state.ManagementMeetingsStore.managementMeetingsList.forEach(item => {
          if (item.meetingUniqueId != this.currentMeeting && item.meetingHasPublishedAgenda) {
            meetingList.push(item)
          }
        })
        return meetingList
      },
    }
}
</script>

<style>

</style>
