<template>
  <div>
    <v-flex xs12 md12 max-width="100%" style="margin-top: 10px">
      <v-layout>
        <v-flex xs12 md12>
          <div style="width: 100%; padding-left: 10px" id="app">
            <div>
              <div
                  :class="['dropZone', dragging ? 'dropZone-over' : '']"
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
              >
                <div class="dropZone-info" @drag="onChange">
                  <span class="fa fa-cloud-upload dropZone-title"></span>
                  <span class="dropZone-title">Drop file or click here</span>
                  <div class="dropZone-upload-limit-info">
                    <div>Maximum file size: 5 MB</div>
                  </div>
                </div>
                <input
                    type="file"
                    accept="application/pdf"
                    @change="onChange"
                />
              </div>
            </div>
          </div>
        </v-flex>
        <v-flex v-if="type!=='directiveReport'" xs12 md4>
          <div style="width: 100%; padding-left: 10px" id="app">
            <div>
              <div
                  class="dropZone"
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
              >
                <div class="dropZone-info" @drag="onChange">
                  <span class="fa fa-cloud-upload dropZone-title"></span>
                  <span class="dropZone-title"
                  ><v-icon>mdi-palette-swatch</v-icon></span
                  >
                  <div class="dropZone-upload-limit-info">
                    <div>Load from Repository</div>
                  </div>
                </div>
                <input type="file" @change="onChange" />
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>


    <v-dialog v-model="uploadDialog" max-width="500px">
      <v-card>
        <v-card-title style="font-size: 16px">
          <span
          ><v-icon>mdi-upload-outline</v-icon> Uploading Document . . .</span
          >
        </v-card-title>
        <v-card-text>
          <div style="min-height: 4px">
            <v-progress-linear
                v-model="value"
                :active="show"
                indeterminate
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>

export default {
  props: ["type", "data",'suportingDocs', "meetingUniqueId"],
  emits: ["close"],
  components: {
  },

  data() {
    return {
      selectedItem: null,
      file: "",
      show: true,
      docValue: "",
      docType: "office",
      OpenfileDialog: false,
      uploadDialog: false,
      loading3: false,
      dragging: false,
      presenter: "No Presenter",
      duration: 0,
      value: 0,
      Purpose: "",
      responseDocs: [],
    };
  },

  methods: {
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },

    async createFile(file) {
      if (!file.type.match("pdf.*")) {
        alert("Please select PDF file");
        this.dragging = false;
        return;
      }

      if (file.size > 100000000) {
        alert("Please check file size no over 5 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      this.uploadDialog = true;

      var sendData = {
        file: file,
        meetingUniqueId: this.meetingUniqueId
      };

      await this.$store.dispatch("boardRepositorySubmitFile", sendData);
      this.dragging = false;
      this.uploadDialog = false;
      this.$emit('close')
    },

    removeFile() {
      this.file = "";
    },

    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      this.OpenfileDialog = true;
    },
  },

  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    }
  },
};
</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}

iframe {
  width: 100%;
  height: 100%;
}

.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
