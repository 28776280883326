<template>
  <v-container fluid class="my-5" app>
    <v-dialog v-model="openLocationDialog" max-width="60%">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          <v-icon class="mr-2">mdi-map-marker-plus</v-icon>
          {{ title }}
        </v-card-title>
        <v-form class="px-3">
          <v-card-text>
            <v-text-field v-model="Name" autofocus label="Name*"></v-text-field>

            <v-text-field v-model="Address" label="Physical Address*"></v-text-field>

            <v-autocomplete
              v-model="TimeZone"
              :items="timeZones"
              label="TimeZone*"
              required
            ></v-autocomplete>

            <v-checkbox
              v-model="checkbox"
              :label="`Set as default`"
            ></v-checkbox>
          </v-card-text>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            small
            outlined
            color="pink"
            @click="
              openLocationDialog = !openLocationDialog;
              $emit('closeNewLocationDialog');
            "
          >
            Close
            <!--            <v-icon class="white&#45;&#45;text">mdi-close</v-icon>-->
          </v-btn>

          <v-btn @click="saveNewLocations" small outlined color="primary">
            <!--            <v-icon>mdi-plus</v-icon>-->
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ["openLocationDialog", "title", "location", "action"],
  data() {
    return {
      checkbox: false,
      Name: "",
      Address: "",
      TimeZone: "",
      nameRules: [
        (v) => !!v || "This field is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],
      timeZonesRule: [(v) => !!v || "Time Zone is required"],
    };
  },

  watch: {
    locationObj() {
      this.Name = this.location.name;
      this.Address = this.location.address;
      this.TimeZone = this.location.timezone;
    },
  },

  computed: {
    timeZones() {
      return this.getTimeZones();
    },

    locationObj() {
      return this.location;
    },
  },

  methods: {
    getTimeZones() {
      const ct = require("countries-and-timezones");

      const timezones = ct.getAllTimezones();
      const result = Object.entries(timezones);
      let current_timeZones = [];
      result.forEach((element) => {
        current_timeZones.push(element[1].name);
      });

      current_timeZones.push('Africa/Dar_es_Salaam (UTC+03:00)')
      return current_timeZones;
    },

    async saveNewLocations() {
      await this.$store.dispatch("showLoadingSpinner", true);

      if (this.action === "Add") {
        const location = {
          locationName: this.Name,
          locationAddress: this.Address,
          locationTimezone: this.TimeZone,
          locationBoard: localStorage.getItem("my-boardUniqueId"),
        };
        await this.$store.dispatch(
          "SettingsStore/addNewMeetingLocations",
          location
        );
      } else {
        const location = {
          locationName: this.Name,
          locationAddress: this.Address,
          locationTimezone: this.TimeZone,
          locationUniqueId: this.location.id,
        };
        await this.$store.dispatch("SettingsStore/updateLocation", location);
      }

      await this.$store.dispatch("SettingsStore/loadBoardMeetingLocations", {
        root: true,
      });

      this.Name = null;
      this.Address = null;
      this.TimeZone = null;

      await this.$store.dispatch("showLoadingSpinner", false);
      this.$emit("closeNewLocationDialog");
    },
  },
};
</script>

<style></style>
