<template>
  <nav>
    <v-app-bar  dense  app style="background: #3f51b5;color: #fff;">
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="white--text text-lowercase">e-</span>
        <span class="white--text font-weight-light">Board  </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" left>mdi-help-circle-outline</v-icon>
      </template>
        <v-list>
            <v-list-item link >
              <v-list-item-content>
                <v-list-item-title><v-icon>mdi-note-text</v-icon> User Manual</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
<!--            <v-list-item link >-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title><v-icon>mdi-information-variant</v-icon> About e-Board</v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--          </v-list-item>-->
        </v-list>

      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" right>mdi-chevron-down</v-icon>
          <span style="padding-left:10.1px">{{logeduser.fullname}}</span>
      </template>
        <v-list>
            <v-list-item v-for="link in topLinks" :key="link.name" link :to="link.routinglink">
              <v-list-item-content>
                <v-list-item-title><v-icon>{{ link.icon }}</v-icon> {{ link.name }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item @click="changePassword">
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-lock</v-icon>
                Change Password
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
            <v-list-item @click="callLogoutMethod" >
              <v-list-item-content>
                <v-list-item-title><v-icon>mdi-power</v-icon> Logout</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-menu>
    </v-app-bar >

 </nav>
</template>

<script>
import allBoardsByUser from '../../../../graphql/eboard-boards-graphql/allBoardsByUser.gql'
import store from '@/store'

export default {
  data() {
    return {
      board_account:"/board_account/",
      drawer: true,
      mini:true,
      topLinks:[
            {
                name:'My Profile',
                icon:'mdi-account',
                routinglink:'/eboard_my_profile'
            },
            // {
            //     name:'Help',
            //     icon:'mdi-account',
            //     routinglink:'meetings/my-eboards'
            // },
      ],
      logeduser:{'fullname':""}
    }
  },
  apollo: {
    allBoardsByUser: allBoardsByUser,
  },

  computed:{
    currentBoard(){
      return this.$store.state.MyboardStore.currentBoard
    }
  },

  methods:{
    callLogoutMethod(){
      localStorage.removeItem('vuex')
      localStorage.removeItem('my-boardUniqueId')
      localStorage.removeItem('my-managementCommitteeName')
      localStorage.removeItem('my-managementCommitteeUniqueId')
      localStorage.removeItem('my-boardCommitteeName')
      localStorage.removeItem('my-boardCommitteeUniqueId')
      localStorage.removeItem('my-boardKey')
      localStorage.removeItem('apollo-token')
      this.$router.go('/landing_page')
    },
    changePassword() {
      this.$router.push('/change-password')
    },
    changeSelectedBoard(board_uuid,board_name){
      this.$store.dispatch('updateCurrentMyboardName',board_name)
      localStorage.setItem('my-boardUniqueId',board_uuid)
      this.$router.go('')
    }
  },
  mounted(){
      const userDetails = store.getters['AuthStore/user']
      this.logeduser.fullname=userDetails.firstName +" "+userDetails.lastName
    }
}
</script>

