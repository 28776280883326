<template>

  <v-flex>
    <v-simple-table style="width:100%">
      <template>
        <v-overlay v-if="!selectedAgenda" :absolute="absolute" :value="overlay">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <thead>
        <tr>
          <th class="text-left" style="width:10%">

          </th>
          <th class="text-left" style="width:80%">
          </th>
        </tr>
        </thead>
        <tbody>
          <tr v-if="boardSetting().showAgendaPurpose">
            <td>
              <strong>Purpose :</strong>
            </td>
            <td>
              <span>
                {{ selectedAgenda.itemDetails.itemPuporse }}
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        x-small
                        @click="editAgendaDetails(selectedAgenda.itemDetails.itemPuporse,selectedAgenda.itemDetails.itemKey,'Purpose')"
                        v-on="on"
                        v-if="!meetingHasPublishedAgenda"
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Click to Edit</span>
                </v-tooltip>
              </span>
            </td>
          </tr>

          <tr v-if="boardSetting().showAgendaPresenter">
            <td>
              <strong>Presenter :</strong>
            </td>
            <td>

              <v-menu app offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn slot="activator" color="indigo" small style="text-transform: capitalize;" text v-bind="attrs"
                         v-on="on">{{ presenter }}
                  </v-btn>
                </template>
                <v-list dense v-if="!meetingHasPublishedAgenda">
                  <v-list-item v-for="member in getManagementMembers" :key="member.username" link>
                    <v-list-item-title v-if="member" @click="changePresenter(selectedAgenda.itemKey,member)">
                      {{ member.firstName }} {{ member.lastName }}
                    </v-list-item-title>
                    <v-list-item-title v-else @click="changePresenter(selectedAgenda.itemKey,member)"> No Presenter
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="changePresenter(selectedAgenda.itemKey,`None`)"> No Presenter
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>

          <tr v-if="boardSetting().showAgendaDuration">
          <td>
            <strong>Duration :</strong>
          </td>
          <td class="Editable">
            <span @blur="event => onPurposeInput(event,selectedAgenda.itemDetails.itemKey,'Time')">
              {{ selectedAgenda.itemDetails.itemDuration }}
            </span> Min
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    @click="editAgendaDetails(selectedAgenda.itemDetails.itemDuration,selectedAgenda.itemDetails.itemKey,'Time')"
                    v-on="on"
                    v-if="!meetingHasPublishedAgenda"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to Edit</span>
            </v-tooltip>
          </td>
        </tr>
        <tr></tr>
        </tbody>
      </template>
    </v-simple-table>

    <EditAgendaDetails
        :isEditAgendaDetails="isEditAgendaDetails"
        :itemDetail="itemDetail"
        @closeEditingAgendaDetails="isEditAgendaDetails=false"
    />
  </v-flex>

</template>

<script>
import swal from 'sweetalert2'
import {mapGetters} from 'vuex'
import EditAgendaDetails from "@/modules/management_meeting_management/management-agendas/EditAgendaDetails";

export default {
  components: {
    EditAgendaDetails
  },

  data() {
    return {
      absolute: true,
      overlay: true,
      file: '',
      loading3: false,
      dragging: false,
      duration: 0,
      Purpose: '',
      isEditAgendaDetails: false,
      itemDetail: {}
    }
  },

  methods: {
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },

    createFile(file) {
      if (!file.type.match('pdf.*')) {
        alert('please select PDF file');
        this.dragging = false;
        return;
      }

      if (file.size > 100000000) {
        alert('please check file size no over 5 MB.')
        this.dragging = false;
        return;
      }

      this.file = file;
      this.dragging = false;
    },

    removeFile() {
      this.file = '';
    },

    onInput(event) {
      var cleared = event.target.innerText.replace(/[^\d.-]/g, '');
      console.log(cleared);
      this.duration = cleared

    },

    clearAllUnwantedChar(event) {
      event.target.innerText = this.duration
    },

    onPurposeInput(event, selectedAgenda, updatePlace) {
      this.Purpose = event.target.innerText
      const details = {
        newPurpose: this.Purpose,
        detailKey: selectedAgenda,
        updatePlace: updatePlace
      };
      this.$store.dispatch('updateManagementAgendaItemsDetails', details)
    },

    editAgendaDetails(event, selectedAgenda, updatePlace) {
      this.itemDetail = {
        value: event,
        selectedAgenda: selectedAgenda,
        updatePlace: updatePlace,
      }
      this.isEditAgendaDetails = true
    },

    publishAgendas() {
      this.loading3 = true
      swal.fire({
        toast: true,
        icon: 'success',
        title: 'Posted successfully',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })

      setTimeout(() => (this.loading3 = false), 3000)
    },

    changePresenter(itemKey, member) {
      if (member !== "None") {
        this.presenter = member.firstName + " " + member.lastName
        this.$store.dispatch('switchMAnagementAgendaItemsPresenter', {itemKey: itemKey, member: member.id})
      } else {
        this.presenter = "No Presenter"
        this.$store.dispatch('switchMAnagementAgendaItemsPresenter', {itemKey: itemKey, member: "None"})
      }
    }
  },

  computed: {
    meetingHasPublishedAgenda() {
      return this.$store.getters.getManagementMeetingDetails.meetingHasPublishedAgenda
    },

    extension() {
      return (this.file) ? this.file.name.split('.').pop() : '';
    },

    presenter: {
      get: function () {
        return this.selectedAgenda.itemDetails.itemPresenter
      },
      set: function (newValue) {
        this.selectedAgenda.itemDetails.itemPresenter = newValue
      }
    },

    ...mapGetters({
      getManagementMembers: "getManagementMembers"
    }),

    selectedAgenda() {
      return this.$store.state.ManagementAgendasStore.selectedManagementAgenda
    }
  },
}

</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}


.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975A0;
}

.dropZone-info {
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #A8A8A8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
