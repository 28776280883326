<template>
  <v-banner outlined type="">
    <v-chart class="chart" :option="option" />
  </v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: ["dataObject"],
  name: "HelloWorld",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {},
  computed: {
    option() {
      const dataValues = [];
      this.dataObject.boardMeetingAttendance.forEach((board) => {
        dataValues.push({
          name: board.name,
          value: board.value,
        });
      });
      return {
        title: {
          text: "Meetings attendance status",
          top: "top",
        },
        tooltip: {
          trigger: "item",
          formatter:
            "<strong>{b}</strong> <br/>Total : {c} <br/>Percentage: {d}%",
        },
        legend: {
          orient: "horizontal",
          bottom: "left",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            name: "Summary",
            type: "pie",
            radius: [60, 150],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 1,
            },
            data: dataValues,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            lineStyle:{
              width:2
            }
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
