import { apolloClient } from '@/vue-apollo'
import registerUser from '../../graphql/eboard-auth-graphql/registerUser.gql'
import createBoardUser from '../../graphql/eboard-boards-graphql/createBoardUser.gql'
import allManagementUsers from '../../graphql/management-managements-graphql/allManagementUsers.gql'
import swal from 'sweetalert2'

export default {
  state: {
    managementMembers: [],
  },
  getters: {
    getManagementMembers(state) {
      return state.managementMembers
    }

  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    LOGIN_USER(state, user) {
      state.user = user
      localStorage.setItem('my-boardUniqueId', user.profileUser.edges[0].node.userProfileBoard.boardUniqueId || "")
    },
    LOGOUT_USER(state) {
      state.authStatus = ''
      state.token = '' && localStorage.removeItem('apollo-token')
      sessionStorage.clear();
    },
    clearManagementMembers(state) {
      state.managementMembers = []
    },
    saveManagementMembers(state, members) {
      state.managementMembers = [...members]
    }
  },
  actions: {
    async registerNewManagementMembers(_, membersDetails) {
      try {
        var randomWords = require('random-words');
        var pasw1 = randomWords() + "2LatraDT@"
        const { data } = await apolloClient.mutate({
          mutation: registerUser,
          variables: {
            username: membersDetails.email,
            email: membersDetails.email,
            password1: pasw1,
            password2: pasw1,
          }
        })
        if (data.register.success) {
          const { data } = await apolloClient.mutate({
            mutation: createBoardUser,
            variables: { ...membersDetails }
          })
          console.log(data);
          swal.fire({
            toast: true,
            icon: 'success',
            title: "Great - member sucsessfully created . .!",
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        } else {
          swal.fire({
            toast: true,
            icon: 'error',
            title: "Sorry - that didn't work. The credential you provided are not valid",
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    async loadManagementMembers(context) {
      context.commit('clearManagementMembers')
      await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allManagementUsers,
        variables: {
          managementUniqueId: localStorage.getItem('my-managementUniqueId')
        }
      }).then(async(response) => {
        context.commit('saveManagementMembers', response.data.allManagementMembersManagementUniqueId);
        await context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
        await swal.fire({
            toast: true,
            icon: 'error',
            title: "Sorry, we encounter some issues when processing your request. Please try again or contact your adminstrator . . . !",
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
        })
    })
    }
  },


}

