<template>

<v-dialog
    v-model="OpenfileDialog"
    max-width="80%"
>
<v-card>
    <v-card-title style="font-size:16px">
    <span><v-icon>mdi-upload-outline</v-icon> {{title}}</span>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('CloseDocumentViwer');OpenfileDialog=false"
          >
            Close
          </v-btn>
    </v-card-actions>
    </v-card-title>
    <v-card-text style="height:800px">
        <vue-pdf-app :config="config3" style="height: 100%" :pdf="MEDIA_SERVER+docValue"></vue-pdf-app>
    </v-card-text>

</v-card>
</v-dialog>


</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
    props:['docValue','docType','OpenfileDialog','title', 'packUniqueId'],
    components: {
        VuePdfApp
    },
    data() {
        return {
            config3: {
                toolbar: {
                    toolbarViewerRight:{
                        print: false,
                        download: false,
                    }
                }
            }          
        }
    }
}
</script>
