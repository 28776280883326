<template>
    <v-container fluid>  
        <quill-editor
        :content="content"
        :options="editorOption" style="height: 400px"
        @change="onEditorChange($event)"    
        />

    </v-container>

</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  props:['content','editorOption'],
  components: {
    quillEditor
  },
  data: () => ({
  }),
  methods:{
    onEditorChange(event){
      console.log(event.html);
      this.$emit("valueHasChange",event.html)
    },


  }
}
</script>