<template>
  <div class="text-center">
    <v-dialog v-model="openMinuteRecommendationEditor" persistent width="70%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="indigo">mdi-file-document</v-icon>
          {{ recommendationStatus ? 'Edit Recommendation' : 'Add New Recommendation' }}
          <v-spacer></v-spacer>
          <v-progress-circular
              v-if="saveLoading"
              indeterminate
              color="success"
              size="20"
          ></v-progress-circular>
          <span v-if="saveLoading" style="margin-left:10px">{{ bntLabel }}</span>
        </v-card-title
        >

        <v-card-text>
          <v-container>
            <v-text-field
                v-model="currentRecommendation.recommendationTitle"
                :counter="100"
                :rules="nameRules"
                label="Title *"
                prepend-icon="mdi-text-recognition"
                required
            >
            </v-text-field>
            <CustomEditor
                :content="currentRecommendation.recommendationDescriptions"
                :editorOption="editorOption"
                @onManagementEditorChange="onManagementEditorChange($event)"
                style="height: 480px"
            />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              text
              outlined
              small
              @click="$emit('closeMinuteRecommendationEditor')"
          >
            <v-icon small>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
              color="success"
              outlined
              small
              :loading="saveLoading"
              @click="saveMinuteRecommendation"
          >
            <v-icon small>mdi-floppy</v-icon>
            {{ bntLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomEditor from "@/modules/management_meeting_management/management_shared/fields/CustomEditor.vue";

export default {
  name: "ManagementCommitteeRecommendation",
  props: ["openMinuteRecommendationEditor", "selectedAgenda", "recommendationModelTitle"],

  components: {
    CustomEditor,
  },

  data: () => ({
    content: "",
    bntLabel: "Save",
    saveLoading: false,
    recommendationTitle: "",
    nameRules: [
      v => !!v || 'Recommendation title is required',
    ],
    editorOption: {
      placeholder: "Start to write recommendation . . . ",
      theme: "snow",
    },
  }),

  methods: {
    onManagementEditorChange(event) {
      this.currentRecommendation.recommendationDescriptions = event
        this.autoSaveMinuteNotesOnTyping(event)
    },
    async saveMinuteRecommendation() {
      if (this.recommendationStatus) {
        await this.$store.dispatch("editManagementCommitteeAgendaRecommendation", {
          recommendationUniqueId: this.currentRecommendation.id,
          recommendationTitle: this.currentRecommendation.recommendationTitle,
          recommendationDescriptions: this.currentRecommendation.recommendationDescriptions
        })
      } else {
        await this.$store.dispatch("createManagementCommitteeAgendaRecommendation", {
          recommendationAgenda: this.selectedAgenda.id,
          recommendationTitle: this.currentRecommendation.recommendationTitle,
          recommendationDescriptions: this.currentRecommendation.recommendationDescriptions
        })
      }
      this.$emit('closeMinuteRecommendationEditor')
    },
  },

  computed: {
    currentRecommendation() {
      return this.$store.getters.getManagementCommitteeAgendaRecommendation
    },
    recommendationStatus() {
      return this.$store.getters.getManagementCommitteeAgendaRecommendationStatus
    },
  },
};
</script>