<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col>
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">Board Commitees</h2>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="AddNewCommitteesDialog = !AddNewCommitteesDialog"
            v-bind="attrs"
            v-on="on"
            right
            fab
            x-small
            color="primary white--text"
            style="margin: 5px"
            ><v-icon>mdi-account-multiple-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Add New Committee</span>
      </v-tooltip>
    </v-row>

    <v-row class="mt-3">
      <v-col>
        <div class="mt-5">
          <div class="intro-y flex justify-start items-start" v-for="item in boardCommitees" :key="item">
            <div class="box px-4 py-4 mb-3 flex items-center">
              <div
                class="w-12 h-12 flex-none image-fit rounded-md overflow-hidden"
              >
                <img alt="Commitee" :src="item.avatar" />
              </div>
              <div class="ml-4">
                <div class="font-medium" style="font-size:16px">{{ item.title }}</div>
                <div class="text-slate-500 text-xs mt-0.5" style="font-size:14px">
                  {{ item.description }}
                </div>
                <div class="text-slate-500 text-xs mt-0.5">
                  <strong>Created: </strong> {{ item.created_date|formatDate }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class=" justify-start items-start">
                <div class="font-medium">Leader</div>
                <div class="text-slate-500 text-xs mt-0.5 justify-start items-start">
                  {{ item.subtitle }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div
                v-if="item.active"
                class="py-1 px-2 rounded-full text-xs bg-success white--text text-white cursor-pointer font-medium"
              >
                <v-icon small class="white--text">mdi-check</v-icon>
                <span class="text-capitalize success--tex white--text"
                  >Active with {{ item.members }} Members</span
                >
              </div>
              <div
                v-else
                class="py-1 px-2 white--text rounded-full text-xs bg-warning white--text text-white cursor-pointer font-medium"
              >
                <v-icon small color="white">mdi-check</v-icon>
                <span class="text-capitalize"
                  >In-Active since
                  {{ new Date(item.deactivated_date).toDateString() }}</span
                >
              </div>


                      <v-menu offset-y app>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            slot="activator"
                            icon
                            color="indigo"
                            ><v-icon>mdi-dots-vertical</v-icon></v-btn
                          >
                        </template>
                        <v-list dense>
                          <v-list-item
                            link
                            to=""
                            @click="editCommitteeDetails(item)"
                          >
                            <v-list-item-title
                              ><v-icon style="height: 16px; font-size: 16px"
                                >mdi-book-edit-outline</v-icon
                              >
                              Edit Committee</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            link
                            to=""
                            @click="
                              addCommitteeMembers(item.membersList, item.key)
                            "
                          >
                            <v-list-item-title
                              ><v-icon style="height: 16px; font-size: 16px"
                                >mdi-account-plus</v-icon
                              >
                              Add New Member</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            link
                            to=""
                            @click="
                              viewCommitteeMembers(item.membersList, item.key)
                            "
                          >
                            <v-list-item-title
                              ><v-icon style="height: 16px; font-size: 16px"
                                >mdi-account-multiple-outline</v-icon
                              >
                              View Members</v-list-item-title
                            >
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item
                            link
                            to=""
                            @click="deactivateCommittee(item.key)"
                            v-if="item.active"
                          >
                            <v-list-item-title
                              ><v-icon
                                color="error"
                                style="height: 16px; font-size: 16px"
                                >mdi-block-helper</v-icon
                              >
                              Deactivate</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            link
                            to=""
                            @click="deactivateCommittee(item.key)"
                            v-else
                          >
                            <v-list-item-title
                              ><v-icon
                                color="success"
                                style="height: 16px; font-size: 16px"
                                >mdi-check</v-icon
                              >
                              Activate</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <add-new-committees
      :AddNewCommitteesDialog="AddNewCommitteesDialog"
      @close="
        AddNewCommitteesDialog = false;
        newCommettee++;
      "
    ></add-new-committees>

    <EditCommitteeDetails
      :editCommitteeDetailsDialog="editCommitteeDetailsDialog"
      :committee="committee"
      @close="
        editCommitteeDetailsDialog = false;
        newCommettee++;
      "
    />

    <CommitteeMembers
      :CommitteeMembersDialog="CommitteeMembersDialog"
      :membersList="membersList"
      :committeeUniqueId="committeeUniqueId"
      @close="CommitteeMembersDialog = false"
    />

    <AddCommitteeMember
      :addCommitteeMembersDialog="addCommitteeMembersDialog"
      :membersList="membersList"
      :committeeUniqueId="committeeUniqueId"
      @close="addCommitteeMembersDialog = false"
    />

    <v-overlay :z-index="zIndex" :value="overlay">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import AddNewCommittees from "./AddNewCommittees.vue";
import CommitteeMembers from "./CommitteeMembers.vue";
import AddCommitteeMember from "./AddCommitteeMember.vue";
import EditCommitteeDetails from "./EditCommitteeDetails.vue";

export default {
  components: {
    AddNewCommittees,
    CommitteeMembers,
    AddCommitteeMember,
    EditCommitteeDetails,
  },

  data() {
    return {
      zIndex: 0,
      overlay: true,
      newCommettee: 23,
      AddNewCommitteesDialog: false,
      CommitteeMembersDialog: false,
      membersList: [],
      committeeUniqueId: "",
      addCommitteeMembersDialog: false,
      editCommitteeDetailsDialog: false,
      committee: {},
    };
  },

  computed: {
    boardCommitees() {
      return this.$store.state.CommitteeStore.boardCommiteesOnly;
    },
  },

  watch: {
    boardCommitees: function () {
      this.overlay = false;
    },
  },

  async mounted() {
    await this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("loadBoardCommittees");
    await this.$store.dispatch("showLoadingSpinner", false);
  },

  methods: {
    viewCommitteeMembers(membersList, committeeUniqueId) {
      this.membersList = membersList;
      this.committeeUniqueId = committeeUniqueId;
      this.CommitteeMembersDialog = true;
    },

    addCommitteeMembers(membersList, committeeUniqueId) {
      this.committeeUniqueId = committeeUniqueId;
      this.membersList = membersList;
      this.addCommitteeMembersDialog = true;
    },

    deactivateCommittee(committeeUniqueId) {
      this.$store.dispatch("deactivateCommittee", committeeUniqueId);
      setTimeout(() => {
        this.$emit("close");
      }, 2000);
    },

    deleteCommittee(committeeUniqueId) {
      this.$store.dispatch("deleteCommittee", committeeUniqueId);
      setTimeout(() => {
        this.$emit("close");
      }, 2000);
    },

    editCommitteeDetails(committee) {
      this.committee = committee;
      this.editCommitteeDetailsDialog = true;
    },
  },
};
</script>

<style></style>
