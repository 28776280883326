import {apolloClient} from '@/vue-apollo'
import allMeetingLocations from '../../graphql/eboard-settings-graphql/allMeetingLocations.gql'
import createLocation from '../../graphql/eboard-settings-graphql/createLocation.gql'
import updateMeetingQuorum from '../../graphql/eboard-settings-graphql/updateMeetingQuorum.gql'
import allBoardSettings from '../../graphql/eboard-settings-graphql/allBoardSettings.gql'
import allMinutesCustomSectionsSettings
    from '../../graphql/eboard-settings-graphql/allMinutesCustomSectionsSettings.gql'
import addCustomMinuttesButtons from '../../graphql/eboard-settings-graphql/addCustomMinuttesButtons.gql'
import deleteCustomMinuttesButtons from '../../graphql/eboard-settings-graphql/deleteCustomMinuttesButtons.gql'
import allDesignationsSettings from '../../graphql/eboard-settings-graphql/allDesignationsSettings.gql'
import addNewDesignation from '../../graphql/eboard-settings-graphql/addNewDesignation.gql'
import smsConfigurationsSettings from '../../graphql/eboard-settings-graphql/smsConfigurationsSettings.gql'
import deactivateDesignation from "../../graphql/eboard-settings-graphql/deactivateDesignation.gql"
import updateLocation from "../../graphql/eboard-settings-graphql/updateLocation.gql"
import deleteLocation from "../../graphql/eboard-settings-graphql/deleteLocation.gql"
import updateDesignation from "../../graphql/eboard-settings-graphql/updateDesignation.gql"
import updateSystemSettings from "../../graphql/eboard-settings-graphql/updateSystemSettings.gql"
import updateInstitutionDetails from "../../graphql/eboard-settings-graphql/updateInstitutionDetails.gql"
import institutionByBoardUniqueId from "../../graphql/eboard-settings-graphql/institutionByBoardUniqueId.gql"
import swal from 'sweetalert2'


export default {
    namespaced: true,

    state: {
        boardSettings: null,
        boardLocations: [],
        boardLocationsList: [],
        boardCustomMinutesSectionsList: [],
        boardDesignationsList: [],
        smsConfigurations: {},
        institutionDetails: {}
    },

    getters: {
        BoardSetting: state => state.boardSettings,
        MinutesCustomSections: state => state.boardCustomMinutesSectionsList,
        DesignationsList: state => state.boardDesignationsList,
        institutionDetails: state => state.institutionDetails
    },

    mutations: {
        cleanLocations(state) {
            state.boardLocations = []
            state.boardLocationsList = []
        },

        addLocations(state, location) {
            state.boardLocations.push({
                location: location.locationName + " (" + location.locationAddress + " )",
                id: location.primaryKey,
                locationUniqueId: location.locationUniqueId,
                locationTimezone: location.locationTimezone
            });
            state.boardLocationsList.push({
                location: location.locationName,
                locationAddress: location.locationAddress,
                id: location.primaryKey,
                locationTimezone: location.locationTimezone,
                locationCreateddate: location.locationCreateddate,
            })
        },

        clearSettings(state) {
            state.boardSettings = null
        },

        updateSettings(state, quorum) {
            state.boardSettings.settingQuorumPercentage = quorum
        },

        saveSettings(state, setting) {
            state.boardSettings = setting
        },

        saveCustomminutesSettings(state, sections) {
            state.boardCustomMinutesSectionsList = sections
        },

        saveDesignationSettings(state, designations) {
            state.boardDesignationsList.push(designations)
        },

        saveSMSConfigurationsSettings(state, configuration) {
            state.smsConfigurations = configuration
        },

        clearInstitutionDetails(state) {
            state.institutionDetails = {}
        },

        setInstitutionDetails(state, institutionObj) {
            state.institutionDetails = institutionObj;
        },

        clearBoardDesignationsList(state) {
            state.boardDesignationsList = []
        }

    },

    actions: {
        async loadBoardMeetingLocations(context) {
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMeetingLocations,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const locations = response.data.allMeetingLocations.edges
                context.commit('cleanLocations')
                locations.forEach(location => {
                    context.commit('addLocations', location.node)
                });
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async addNewMeetingLocations(context, location) {
            apolloClient.mutate({
                mutation: createLocation,
                variables: {
                    location: location
                }
            }).then((response) => {
                const saved = response.data.createLocation.newLocation.saved
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async updateLocation(context, location) {
            apolloClient.mutate({
                mutation: updateLocation,
                variables: {
                    locationAddress: location.locationAddress,
                    locationName: location.locationName,
                    locationTimezone: location.locationTimezone,
                    locationUniqueId: location.locationUniqueId,
                }
            }).then((response) => {
                const saved = response.data.updateLocation.meetingLocation.saved
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async deleteLocation(context, location) {
            apolloClient.mutate({
                mutation: deleteLocation,
                variables: {
                    locationIsActive: location.locationIsActive,
                    locationUniqueId: location.locationUniqueId,
                }
            }).then((response) => {
                const saved = response.data.deleteLocation.response.saved
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async updateMeetingQuorum(context, quorum) {
            apolloClient.mutate({
                mutation: updateMeetingQuorum,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    newQuorum: quorum,
                }
            }).then((response) => {
                const saved = response.data.updateMeetingQuorum.quorum.saved
                context.commit('updateSettings', quorum)
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async getBoardMeetingSettings(context) {
            context.commit('clearSettings');
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allBoardSettings,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const settings = response.data.allBoardSettings
                context.commit('saveSettings', settings)
            }).then(() => {
                apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: allMinutesCustomSectionsSettings,
                    variables: {
                        boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    }
                }).then((minutResponse) => {
                    const customsections = minutResponse.data.allMinutesCustomSectionsSettings
                    context.commit('saveCustomminutesSettings', customsections)
                })
            }).then(() => {
                context.dispatch('loadBoardDesignations')
            })
        },

        async loadBoardDesignations(context) {

            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allDesignationsSettings,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }
            }).then((designResponse) => {
                context.commit('clearBoardDesignationsList');
                const designations = designResponse.data.allDesignationsSettings;

                designations.forEach(item => {
                    let owners_list = [];
                    let designation_owner = "";

                    item.designationOwners.forEach(element => {
                        owners_list.push(
                            {
                                "primaryKey": element.primaryKey,
                                "ownerUniqueId": element.ownerUniqueId,
                                "designationOwner": element.designationOwner,
                                "designationProfile": element.designationProfile,
                            }
                        );
                        designation_owner += element.designationOwner + ", "
                    });

                    let designation = {
                        'designationUniqueId': item.designationUniqueId,
                        'designationShortForm': item.designationShortForm,
                        'designationName': item.designationName,
                        'designationIsActive': item.designationIsActive,
                        'designation_owners': owners_list,
                        "designation_owner": designation_owner
                    };
                    context.commit('saveDesignationSettings', designation);
                })
            })
        },

        async addCustomMinuttesButtons(context, butttonData) {
            apolloClient.mutate({
                mutation: addCustomMinuttesButtons,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    sectionButtonColor: butttonData.sectionButtonColor,
                    sectionButtonLabel: butttonData.sectionButtonLabel,
                }
            }).then((response) => {
                const saved = response.data.addCustomMinuttesButtons.response.saved
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async deleteCustomMinuttesButtons(context, sectionUniqueId) {
            apolloClient.mutate({
                mutation: deleteCustomMinuttesButtons,
                variables: {
                    sectionUniqueId: sectionUniqueId,
                }
            }).then((response) => {
                const saved = response.data.deleteCustomMinuttesButtons.response.saved
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async addNewDesignation(context, designData) {
            apolloClient.mutate({
                mutation: addNewDesignation,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    designationDescriptions: designData.designationDescriptions,
                    designationShortForm: designData.designationShortForm,
                    designationOwner: designData.designationOwner,
                }
            }).then((response) => {
                const saved = response.data.addNewDesignation.response.saved;
                const success = response.data.addNewDesignation.success;

                if (success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: saved,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: saved,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async updateDesignation(context, designData) {
            apolloClient.mutate({
                mutation: updateDesignation,
                variables: {
                    designationUniqueId: designData.designationUniqueId,
                    designationDescriptions: designData.designationDescriptions,
                    designationShortForm: designData.designationShortForm,
                    designationOwner: designData.designationOwner,
                }
            }).then((response) => {
                const saved = response.data.updateDesignation.response.saved;
                const success = response.data.updateDesignation.success;

                if (success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: saved,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: saved,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async deactivateDesignation(context, designData) {
            apolloClient.mutate({
                mutation: deactivateDesignation,
                variables: {
                    designationUniqueId: designData.designationUniqueId,
                }
            }).then((response) => {
                const saved = response.data.deactivateDesignation.response.saved
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.log(error);
            })
        },

        async loadSMSConfigurations(context) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: smsConfigurationsSettings,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }
            }).then((designResponse) => {
                const settings = designResponse.data.smsConfigurationsSettings
                context.commit('saveSMSConfigurationsSettings', settings)
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async updateSystemSettings(context, Setting) {
            apolloClient.mutate({
                mutation: updateSystemSettings,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    settingType: Setting.settingType,
                    settingsValue: Setting.settingsValue,
                    settingIsColor: Setting.settingIsColor,
                    settingsColorValue: Setting.settingsColorValue
                }
            }).then((response) => {
                const saved = response.data.updateSystemSettings.response.saved;
                const success = response.data.updateSystemSettings.success;

                if (success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: saved,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: saved,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async loadInstitutionDetails(context) {
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: institutionByBoardUniqueId,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const institutionObj = response.data.institutionByBoardUniqueId;
                context.commit('setInstitutionDetails', institutionObj);
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async updateInstitutionDetails(context, Setting) {
            apolloClient.mutate({
                mutation: updateInstitutionDetails,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    settingType: Setting.settingType,
                    settingsValue: Setting.settingsValue,
                }
            }).then((response) => {
                const saved = response.data.updateInstitutionDetails.response.saved;
                const success = response.data.updateInstitutionDetails.success;

                if (success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: saved,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: saved,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

    }

}


