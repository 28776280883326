<template>
  <v-row justify="center">
    <v-dialog v-model="OpenNewUserForm" max-width="80%" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h6"
          ><v-icon>mdi-account-plus-outline</v-icon> Add New Person to
            Board</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>

            <v-row>
              <v-col cols="12" md="12" sm="12">

                <v-row>
                  <v-col cols="6" md="6" sm="6">
                    <v-text-field
                        v-model="FirstName"
                        :counter="10"
                        :rules="nameRules"
                        label="First Name *"
                        prepend-icon="mdi-text-recognition"
                        required
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" md="6" sm="6">
                    <v-text-field
                        v-model="LastName"
                        :counter="10"
                        :rules="nameRules"
                        label="Last Name *"
                        prepend-icon="mdi-text-recognition"
                        required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-checkbox
                    v-model="has_tiltle"
                    :label="`This person has a Title/Suffix`"
                    color="success"
                ></v-checkbox>

                <div
                    v-if="has_tiltle"
                    style="
                    width: 100%;
                    padding: 20px;
                    background-color: rgba(15, 98, 201, 0.11);
                  "
                >
                  <v-select
                      v-model="title"
                      :items="titlesList"
                      :menu-props="{ left: true, offsetY: true }"
                      attach
                      dense
                      hint="Eg: Prof. Mr. Ms. Dr. etc"
                      label="Title"
                      persistent-hint
                  ></v-select>
                  <v-text-field
                      v-model="suffix"
                      :counter="30"
                      hint="Eg: Ph.D M.D. Est. etc"
                      label="Suffix"
                      persistent-hint
                  ></v-text-field>
                </div>

                <v-row>
                  <v-col cols="6" md="6" sm="6">
                    <v-text-field
                        v-model="email"
                        :counter="100"
                        :rules="nameRules"
                        label="Email Address*"
                        prepend-icon="mdi-text-recognition"
                        required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" md="6" sm="6">
                    <v-text-field
                        v-model="phoneNumber"
                        :counter="100"
                        label="Phone  Number *"
                        prepend-icon="mdi-text-recognition"
                        required
                    >
                    </v-text-field>
                  </v-col>

                </v-row>

                <v-row>
                  <v-col cols="6" md="4" sm="12">
                    <v-checkbox
                        v-model="boardRank"
                        :label="`Is Actual Member of Board`"
                        class="mb-0"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="6" md="4" sm="12">
                    <v-checkbox
                        v-model="checkboxMember"
                        :label="`Board Member Position Type`"
                        class="mb-0"
                    ></v-checkbox>
                    <v-expand-transition>
                      <v-card v-show="checkboxMember" elevation="0">
                        <v-radio-group v-model="boardMemberLevel">
                          <template v-slot:label>
                            <div>
                              Select <strong>Position</strong>
                              <v-btn color="dark" icon>
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </div>
                          </template>

                          <v-radio :value="`CHR`">
                            <template v-slot:label>
                              <div>Board Chair</div>
                            </template>
                          </v-radio>
                          <v-radio :value="`SEC`">
                            <template v-slot:label>
                              <div>Board Secretary</div>
                            </template>
                          </v-radio>
                          <v-radio :value="`MEB`">
                            <template v-slot:label>
                              <div>Board Member</div>
                            </template>
                          </v-radio>
                          <v-radio :value="`SECRT`">
                            <template v-slot:label>
                              <div>Board Secretariat / Officer</div>
                            </template>
                          </v-radio>
                        </v-radio-group>

                        <v-text-field
                            v-model="memberNIDANumber"
                            :counter="20"
                            :rules="nidaNumberRules"
                            hint="Mandatory for all board members [e.g XXXXXXXXXXXXXXXX]"
                            label="Member NIDA Number"
                            persistent-hint
                            prepend-icon="mdi-card-account-details-star-outline"
                            required
                        >
                        </v-text-field>
                      </v-card>
                    </v-expand-transition>
                  </v-col>

                  <v-col cols="6" md="4" sm="12">
                    <v-checkbox
                        v-model="checkboxManagementMember"
                        :label="`Management`"
                        class="mb-0"
                    ></v-checkbox>
                    <v-expand-transition>
                      <v-card v-show="checkboxManagementMember" elevation="0">
                        <v-radio-group v-model="managementMemberLevel">
                          <template v-slot:label>
                            <div>
                              Select <strong>Position</strong>
                              <v-btn color="dark" icon>
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </div>
                          </template>

                          <v-radio :value="`CHR`">
                            <template v-slot:label>
                              <div>Chairperson</div>
                            </template>
                          </v-radio>
                          <v-radio :value="`DIR`">
                            <template v-slot:label>
                              <div>Director</div>
                            </template>
                          </v-radio>
                          <v-radio :value="`MAN`">
                            <template v-slot:label>
                              <div>Manager</div>
                            </template>
                          </v-radio>
                          <v-radio :value="`OFF`">
                            <template v-slot:label>
                              <div>Officer</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-card>
                    </v-expand-transition>
                  </v-col>
                </v-row>

                <v-row>
                  <v-expansion-panels col="12" popout>
                    <v-expansion-panel>
                      <v-expansion-panel-header disable-icon-rotate>
                        <span>Select <strong> Access Level</strong></span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-radio-group v-model="userrole">
                          <v-radio
                              v-for="role in roles"
                              :key="role.primaryKey"
                              :value="role.primaryKey"
                          >
                            <template v-slot:label>
                              <div>{{ role.roleName }}</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="white--text"
              color="pink"
              fab
              small
              @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
              class="white--text"
              color="blue darken-1"
              fab
              small
              @click="addNewUser"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import allRolesPerBoard from "../../../graphql/eboard-auth-graphql/allRolesPerBoard.gql";
import swal from 'sweetalert2'

export default {
  data: () => ({
    memberNIDANumber: "",
    managementMember: "",
    titlesList: ["Prof", "Dr", "Mr", "Mrs", "Ms", "Madam"],
    loading: false,
    zIndex: -1,
    overlay: true,
    FirstName: "",
    LastName: "",
    has_tiltle: false,
    title: "",
    suffix: "",
    email: "",
    phoneNumber:"",
    position: "",
    checkboxMember: true,
    checkboxManagementMember: "",
    userrole: "",
    boardMemberLevel: "",
    managementMemberLevel: "",
    boardKey: localStorage.getItem("my-boardUniqueId") || "",
    checkbox: false,
    openLocationDialog: false,
    boardUniqueId: localStorage.getItem("my-boardUniqueId") || "",
    time: null,
    menu4: false,
    menu3: false,
    modal2: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    Tittle: "",
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length <= 100 || "Name must be less than 10 characters",
    ],
    nidaNumberRules: [
      (v) => !!v || "This field is required",
      (v) => v.length === 20 || "Nida number have 20 numbers",
    ],
    roles: [],
    boardRank: false
  }),

  props: ["OpenNewUserForm"],

  created() {
    this.$apollo
        .query({
          query: allRolesPerBoard,
          variables: {
            roleBoard: this.boardUniqueId,
          },
        })
        .then((response) => {
          this.roles = [...response.data.rolesPerBoard];
        });
  },

  methods: {
    async addNewUser() {
      if (this.checkboxMember && this.memberNIDANumber === "") {
        await swal.fire({
          toast: true,
          icon: "error",
          title: "All board members must have NIDA number",
          animation: false,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        await this.$store.dispatch("showLoadingSpinner", true);

        const membersDetails = {
          email: this.email,
          memberProfilePhone: this.phoneNumber,
          lastName: this.LastName,
          boardKey: this.boardUniqueId,
          hasTiltle: this.has_tiltle,
          firstName: this.FirstName,
          position: this.position,
          suffix: this.suffix,
          title: this.title,
          userRole: this.userrole,
          checkboxMember: this.checkboxMember,
          boardMemberLevel: this.boardMemberLevel,
          checkboxManagementMember: this.checkboxManagementMember,
          managementMemberLevel: this.managementMemberLevel,
          memberNidaNumber: this.memberNIDANumber,
          boardRank: this.boardRank
        };

        if (this.checkboxManagementMember && this.checkboxMember) {
          await this.$store.dispatch("registerNewMembers", membersDetails);
        } else if (this.checkboxManagementMember && !this.checkboxMember) {
          await this.$store.dispatch("registerManagementMembers", membersDetails);
        } else if (!this.checkboxManagementMember && this.checkboxMember) {
          await this.$store.dispatch("registerBoardMember", membersDetails);
        }

        await this.$store.dispatch("showLoadingSpinner", false);
        this.$emit("close");
      }
    },
  },

  computed: {
    boardDesignations() {
      let boardDesignations = this.$store.state.SettingsStore.boardDesignationsList;
      let designations = [];

      boardDesignations.forEach(item => {
        designations.push({
          id: item.designationUniqueId,
          name: item.designationName + " (" + item.designationShortForm + ")"
        })
      });

      return designations;
    }
  }
};
</script>
