import { apolloClient } from '@/vue-apollo'
import allPermisionGroups from "../../graphql/eboard-auth-graphql/allPermisionGroups.gql";

export default {
    state: {
        permissionsGroups: []
    },
    mutations: {
        clearPermissionsGroups(state) {
            state.permissionsGroups=[]
        },
        addPermissionsGroups(state, permissionGroup) {
            state.permissionsGroups.push(permissionGroup)
        },
    },
    actions: {
        async loadAllPermissionGroups(context) {
            context.commit('clearPermissionsGroups')

            apolloClient.query({
                query:allPermisionGroups,
                fetchPolicy: 'no-cache',
            }).then((response)=>{
                try {
                    const groups_response = response.data.userPermissionsGroups.edges;

                    groups_response.forEach(group => {
                        const group_id = group.node.permissionGroupUniqueId;
                        const group_name = group.node.permissionGroupName;
                        const group_children = [];

                        group.node.permissionGroup.edges.forEach(children => {
                            group_children.push({
                                id:children.node.primaryKey,
                                name:children.node.permissionName
                            })
                        });

                        context.commit('addPermissionsGroups', {
                            id:group_id,
                            name:group_name,
                            children:group_children
                        })
                    });

                } catch (e) {
                    console.log(e)
                }

            })
        },
    },


}

