<template>
  <v-container fluid>

    <v-row>
      <v-col sm="12">
        <v-card elevation="6">
          <v-tabs
              v-model="tab"
              show-arrows
          >
            <v-tab
                v-for="tab in tabs"
                :key="tab.icon"
            >
              <v-icon
                  size="20"
                  class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <BoardMembersStructure/>
            </v-tab-item>

            <v-tab-item>
              <BoardCommitteeStructure />
            </v-tab-item>

          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <AddNewUser :OpenNewUserForm="OpenNewUserForm"
                @close="OpenNewUserForm =! OpenNewUserForm; userKey++;memberDialogKey++" :key="userKey"/>

    <AddNewRole :OpenNewAccessForm="OpenNewAccessForm" @close="OpenNewAccessForm =! OpenNewAccessForm; roleKey++"
                :key="roleKey"/>

    <v-dialog
        v-model="memberDialog"
        max-width="600px"
        persistent
        :key="memberDialogKey"
    >
      <v-card>
        <v-card-title>
          <v-icon>mdi-account-plus-outline</v-icon>
          <span> Add Board Members</span>
        </v-card-title>
        <v-card-text>
          <span>You can create new member or invite the member that already using e-Board </span>
          <v-radio-group
              v-model="row"
              row
          >
            <v-col
                cols="6"
                sm="6"
                md="6"
            >
              <v-alert
                  elevation="4"
                  outlined
              >
                <v-radio
                    label="Invite Member to Board"
                    value="radio-1"
                    @change="inviteMember=!inviteMember;disabled=true"
                ></v-radio>
              </v-alert>
            </v-col>
            <v-col
                cols="6"
                sm="6"
                md="6"
            >
              <v-alert
                  elevation="4"
                  outlined
              >
                <v-radio
                    label="Create New Member"
                    value="radio-2"
                    @change="inviteMember=false;disabled=false"
                ></v-radio>
              </v-alert>
            </v-col>
          </v-radio-group>


          <v-text-field
              v-model="message"

              clearable
              label="Search member email to invite to Board"
              type="text"
              :hint="searchResults"
              v-if="inviteMember==true"
          >
            <template v-slot:prepend>
              <v-tooltip
                  bottom
                  color="primary"
              >
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                Type Member Email, Click search button to find if user exist in e-Board database
              </v-tooltip>
            </template>
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                    v-if="loading"
                    size="24"
                    color="info"
                    indeterminate
                ></v-progress-circular>
                <v-icon v-else>mdi-email-sync</v-icon>
              </v-fade-transition>
            </template>
            <template v-slot:append-outer>
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  flat
                  icon
                  rounded
                  color="primary"
                  @click="clickMe"
              >
                <v-icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div v-if="inviteMember==true">
            <v-checkbox

                v-for="member in inviteMemberList " :key="member.name"
                v-model="member.checked"
                :label="`${member.name}`"

            ></v-checkbox>
          </div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular
              indeterminate
              color="primary"
              :width="2"
              style="padding-right:10px"
              v-if="progressShown"
          >
          </v-progress-circular>
          <v-btn
              color="pink"
              text
              right
              small
              outlined
              @click="memberDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :disabled="invitationBtn"
              right
              small
              v-if="inviteMember"
              @click="progressShown = true;openAgendaBuilderWindow()"
          >
            Send Invitation
            <v-icon>mdi-email-fast-outline</v-icon>
          </v-btn>
          <v-btn
              v-else
              color="primary"
              :disabled="disabled"
              right
              small
              @click="progressShown=true; OpenNewUserFormWindow()"
          >
            Add Member
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>


</template>

<script>
// import AddNewUser from './AddNewUser.vue'
// import BoardMembers from './BoardMembers.vue'
// import NonBoardMembers from './NonBoardMembers.vue'
import AddNewRole from '../eboard_access/AddNewRole.vue'
import BoardMembersStructure from './BoardMembersStructure.vue'
// import ManagementMembersStructure from './ManagementMembersStructure.vue'
import allBoardUsers from '../../../graphql/eboard-boards-graphql/allBoardUsers.gql'
import BoardCommitteeStructure from "@/modules/meeting_management/eboard_myboard/BoardCommitteeStructure.vue";
// import CommitteesStructure from './CommitteesStructure.vue'

export default {
  components: {
    BoardCommitteeStructure,
    // AddNewUser,
    // BoardMembers,
    // NonBoardMembers,
    AddNewRole,
    BoardMembersStructure,
    //  ManagementMembersStructure,
    // CommitteesStructure
  },
  inject: {
    theme: {
      default: {isDark: false},
    },
  },

  data: () => ({
    roleKey: 0,
    userKey: 1,
    memberDialogKey: 2,
    message: '',
    disabled: true,
    progressShown: false,
    invitationBtn: true,
    inviteMemberList: [],
    searchResults: 'Eg. firstname.lastname@doamin.xx',
    loading: false,
    tab: '',
    inviteMember: false,
    memberDialog: false,
    OpenNewUserForm: false,
    OpenNewAccessForm: false,
    items: [
      {header: 'Board Members'},
      {divider: true, inset: true},
    ],
    nonitems: [

      {header: 'Non-Board Members'},
      {divider: true, inset: true},
    ],
    tabs: [
      {title: 'Board Members', icon: 'mdi-account-star-outline'},
      {title: 'Board Committees', icon: 'mdi-email-fast-outline'},
    ],
  }),
  methods: {
    clickMe() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.invitationBtn = false
        this.inviteMemberList.push({'name': "jaha.mvulla@ega.go.tztcra.go.tz", 'checked': true})
        this.inviteMemberList.push({'name': "jackson.bakari@eganet.go.tz", 'checked': false})
        this.searchResults = `Your search results`
      }, 2000)
    },
    OpenNewUserFormWindow() {
      setTimeout(() => {
        this.OpenNewUserForm = true;
        this.memberDialogKey = 90000;
        this.memberDialog = false;
        this.progressShown = false;
      }, 1000)
    }
  },

  created() {
    this.$apollo.query({
      query: allBoardUsers,
      variables: {
        boardUniqueId: localStorage.getItem('my-boardUniqueId'),
        pageNumber: 1,
      }
    }).then((response) => {
      response.data.boardUsers.forEach(boarduser => {
        if (boarduser.isBoardMember) {
          console.log(boarduser);
          this.items.push({
            avatar: boarduser.userProfilePhoto,
            fullname: boarduser.userProfileTitle + " " + boarduser.firstName + " " + boarduser.lastName + " (" + boarduser.userProfileSuffix + ")",
            position: boarduser.userProfilePosition,
            subtitle: boarduser.email,
            active: boarduser.isVerified,
            activeMessage: boarduser.userProfilePhoto,
            role: boarduser.userRole,
            verified: boarduser.isVerified
          })
          this.items.push({divider: true, inset: true})
        } else {
          console.log(boarduser);
          this.nonitems.push({
            avatar: boarduser.userProfilePhoto,
            fullname: boarduser.firstName + " " + boarduser.lastName,
            position: boarduser.userProfilePosition,
            subtitle: boarduser.email,
            active: boarduser.isVerified,
            activeMessage: boarduser.userProfilePhoto,
            role: boarduser.userRole,
            verified: boarduser.isVerified
          })
          this.nonitems.push({divider: true, inset: true})
        }
      });
    })
  }
}
</script>

<style>

</style>