import {apolloClient} from '@/vue-apollo'
import createAgendaFromTemplate from '../../graphql/eboard-agendas-graphql/createAgendaFromTemplate.gql'
import meetingAgendas from '../../graphql/eboard-agendas-graphql/meetingAgendas.gql'
import updateAgendaItemsDetails from '../../graphql/eboard-agendas-graphql/updateAgendaItemsDetails.gql'
import switchAgendaItemPresenter from '../../graphql/eboard-agendas-graphql/switchAgendaItemPresenter.gql'
import updateAgendaItem from '../../graphql/eboard-agendas-graphql/updateAgendaItem.gql'
import updateAgenda from '../../graphql/eboard-agendas-graphql/updateAgenda.gql'
import addAgendaItem from '../../graphql/eboard-agendas-graphql/addAgendaItem.gql'
import addAgenda from '../../graphql/eboard-agendas-graphql/addAgenda.gql'
import publishMeetingAgendas from '../../graphql/eboard-agendas-graphql/publishMeetingAgendas.gql'
import unPublishMeetingAgendas from '../../graphql/eboard-agendas-graphql/unPublishMeetingAgendas.gql'
import moveMeetingAgenda from '../../graphql/eboard-agendas-graphql/moveMeetingAgenda.graphql'
import moveMeetingAgendaItem from '../../graphql/eboard-agendas-graphql/moveMeetingAgendaItem.graphql'
import deleteAgenda from '../../graphql/eboard-agendas-graphql/deleteAgenda.gql'
import allMeetingsCombinedAgendas from '../../graphql/eboard-agendas-graphql/allMeetingsCombinedAgendas.gql'
import getMeetingsVotingAgendasItems from '../../graphql/eboard-agendas-graphql/getMeetingsVotingAgendasItems.gql'
import getMeetingsMinutesAgendasItems from '../../graphql/eboard-agendas-graphql/getMeetingsMinutesAgendasItems.gql'
import addVottingAgendaItem from '../../graphql/eboard-agendas-graphql/addVottingAgendaItem.gql'
import updateVottingAgendaItem from '../../graphql/eboard-agendas-graphql/updateVottingAgendaItem.gql'
import addConfirmMinutesAgendaItem from '../../graphql/eboard-agendas-graphql/addConfirmMinutesAgendaItem.gql'
import addInterestRegisterAgendaItem from '../../graphql/eboard-agendas-graphql/addInterestRegisterAgendaItem.gql'
import deleteMeetingAgendaItem from '../../graphql/eboard-agendas-graphql/deleteAgendaItem.graphql'
import addAgendaItemToSpecificPosition
    from '../../graphql/eboard-agendas-graphql/addAgendaItemToSpecificPosition.graphql'
import cloneBoardMeetingAgenda from '../../graphql/eboard-agendas-graphql/cloneBoardMeetingAgenda.gql'

import swal from 'sweetalert2'


export default {
    state: {
        listOfagendas: [],
        selecteAgenda: null,
        combinedAgendas: ''
    },

    mutations: {
        addAgendas(state, agenda) {
            state.listOfagendas = agenda
        },

        firstupdateAgendas(state) {
            try {
                state.selecteAgenda = (state.listOfagendas[0]||{}).AgendaItems[0]
            } catch (error) {
                console.log("Fail to update agenda items");
            }
        },

        updateAgendas(state, item) {
            state.selecteAgenda = item
        },

        updateSelectedItem(state, details) {
            state.listOfagendas.forEach(agenda => {
                agenda.AgendaItems.forEach(item => {
                    if (item.itemKey == details.itemUniqueId) {

                        item.itemNumber = details.itemNumber
                        item.itemKey = details.itemUniqueId
                        item.itemName = details.itemTitle
                        item.status = details.itemStatus
                    }
                });

            });
        },

        updateSelectedItemDetails(state, details) {
            state.listOfagendas.forEach(agenda => {
                agenda.AgendaItems.forEach(item => {
                    if (item.itemDetails.itemKey == details.detailsUniqueId) {
                        item.itemDetails.itemPuporse = details.detailsPuporse
                        item.itemDetails.itemDuration = details.detailsDuration
                    }
                });

            });
        },

        updateSelectedAgenda(state, details) {
            state.listOfagendas.forEach(agenda => {
                if (agenda.agendaKey == details.agendaUniqueId) {
                    agenda.Agenda = details.agendaTitle
                }
            });
        },

        addNewAgendaItem(state, itemObject) {
            state.listOfagendas.forEach(agenda => {
                if (agenda.primaryKey == itemObject.agendaKey) {
                    agenda.AgendaItems.push(itemObject.item)
                    state.selecteAgenda = itemObject.item
                }
            });
        },

        cleanSelectedAgendas(state) {
            state.selecteAgenda = null
        },

        setCombineddAgendas(state, path) {
            state.combinedAgendas = path
        }
    },

    getters: {
        getMeetingAgendas(state) {
            return state.listOfagendas
        },
        getSelecteAgenda(state) {
            return state.listOfagendas
        },
    },

    actions: {
        async creatMeetingAgendaFromTemplate(context, currentMeeting) {
            context.commit('cleanSelectedAgendas');


            await apolloClient.mutate({
                mutation: createAgendaFromTemplate,
                variables: {
                    meetingUniqueId: currentMeeting
                }
            }).then(() => {
                context.dispatch('loadBestPracticeAgendaTemplates', currentMeeting);

            }).catch(async (error) => {

                console.log(error);
            })
        },

        async cloneMeetingAgendas(context, cloneData) {
            context.commit('cleanSelectedAgendas');


            await apolloClient.mutate({
                mutation: cloneBoardMeetingAgenda,
                variables: {
                    newMeetingUniqueId: cloneData.newMeetingUniqueId,
                    oldMeetingUniqueId: cloneData.oldMeetingUniqueId,
                }
            }).then(() => {
                context.dispatch('loadBestPracticeAgendaTemplates', cloneData);

            }).catch(async (error) => {

                console.log(error);
            })
        },

        async loadBestPracticeAgendaTemplates(context, currentMeeting) {


            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingAgendas,
                variables: {
                    meetingUniqueId: currentMeeting
                }
            }).then((response) => {
                const agendas = response.data.allMeetingsAgendas.edges
                const list_of_agendas = [];

                agendas.forEach(agenda => {
                    const agendaNode = agenda.node;
                    const agendaItem = [];

                    agendaNode.agendaItem.edges.forEach(item => {
                        const agendaItemnode = item.node;
                        const agendaItemDetails = agendaItemnode.itemDetails.edges[0].node;
                        const documentDetailsList = [];
                        agendaItemDetails.documentDetails.edges.forEach(file => {
                            documentDetailsList.push(
                                {'documentSource':file.node.documentSource,'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
                            )
                        });
                        agendaItem.push(
                            {
                                "itemNumber": agendaItemnode.itemNumber,
                                "itemKey": agendaItemnode.itemUniqueId,
                                "itemName": agendaItemnode.itemTitle,
                                "status": agendaItemnode.itemStatus,
                                "itemType": agendaItemnode.itemType,
                                "itemVoteDetails": agendaItemnode.voteAgendaItem,
                                "itemDetails": {
                                    "itemKey": agendaItemDetails.detailsUniqueId,
                                    "itemName": agendaItemnode.itemTitle,
                                    "itemPuporse": agendaItemDetails.detailsPuporse,
                                    "itemDuration": agendaItemDetails.detailsDuration,
                                    "itemPresenter": agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName || "No Presenter",
                                    'documentDetails': documentDetailsList
                                }
                            }
                        )
                    })

                    list_of_agendas.push({
                        "AgendaNumber": agendaNode.agendaNumber,
                        "primaryKey": agendaNode.primaryKey,
                        "agendaKey": agendaNode.agendaUniqueId,
                        "Agenda": agendaNode.agendaTitle,
                        "AgendaItems": agendaItem
                    })
                });
                context.commit('addAgendas', list_of_agendas);
                context.commit('firstupdateAgendas');


            }).catch(async (error) => {

                console.log(error);
            })

        },

        async updateSelectedAgendaItem(context, item) {
            // context.commit('cleanSelectedAgendas')
            // context.commit('cleanNotes', null, {root: true})


            if (item.itemType === "ConfirmMinutes") {
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: getMeetingsMinutesAgendasItems,
                    variables: {
                        uniqueId: item.itemKey
                    }
                }).then((response) => {
                    const agendaItemnode = response.data.getMeetingsVottingAgendasItems;

                    const documentDetailsList = [];
                    agendaItemnode.itemDetails.edges[0].node.documentDetails.edges.forEach(file => {
                        documentDetailsList.push(
                            {'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
                        )
                    });
                    const itemObject = {
                        "itemNumber": agendaItemnode.itemNumber,
                        "itemKey": agendaItemnode.itemUniqueId,
                        "itemName": agendaItemnode.itemTitle,
                        "status": agendaItemnode.itemStatus,
                        "itemType": agendaItemnode.itemType,
                        "itemMinutesDetails": agendaItemnode.cominedMinutesDocumentItem,
                        "itemDetails": {
                            "itemKey": agendaItemnode.itemDetails.edges[0].node.detailsUniqueId,
                            "itemName": agendaItemnode.itemTitle,
                            "itemPuporse": agendaItemnode.itemDetails.edges[0].node.detailsPuporse,
                            "itemDuration": agendaItemnode.itemDetails.edges[0].node.detailsDuration,
                            "itemPresenter": agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName || "No Presenter",
                            'documentDetails': documentDetailsList
                        }
                    };
                    context.commit('updateAgendas', itemObject)

                }).catch(async (error) => {

                    console.error("error HERE ===>", error);
                })
            } else if (item.itemType === "Vote") {
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: getMeetingsVotingAgendasItems,
                    variables: {
                        uniqueId: item.itemKey
                    }
                }).then((response) => {
                    const voteItem = response.data.getMeetingsVottingAgendasItems;
                    const documentDetailsList = [];

                    voteItem.itemDetails.edges[0].node.documentDetails.edges.forEach(file => {
                        documentDetailsList.push(
                            {'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
                        )
                    });
                    const itemObject = {
                        "itemNumber": voteItem.itemNumber,
                        "itemKey": voteItem.itemUniqueId,
                        "itemName": voteItem.itemTitle,
                        "status": voteItem.itemStatus,
                        "itemType": voteItem.itemType,
                        "itemVoteDetails": voteItem.voteAgendaItem,
                        "itemDetails": {
                            "itemKey": voteItem.itemDetails.edges[0].node.detailsUniqueId,
                            "itemName": voteItem.itemTitle,
                            "itemPuporse": voteItem.itemDetails.edges[0].node.detailsPuporse,
                            "itemDuration": voteItem.itemDetails.edges[0].node.detailsDuration,
                            "itemPresenter": voteItem.itemPresenter.firstName + " " + voteItem.itemPresenter.lastName || "No Presenter",
                            'documentDetails': documentDetailsList
                        }
                    };
                    context.commit('updateAgendas', itemObject)

                }).catch(async (error) => {

                    console.log(error);
                })
            } else {
                context.commit('updateAgendas', item)
            }
        },

        async updateAgendaItem(context, item) {
            await apolloClient.mutate({
                mutation: updateAgendaItem,
                variables: {
                    itemKey: item.itemKey,
                    updatePlace: item.updatePlace,
                    newItem: item.newItem
                }
            }).catch(async (error) => {
                console.log(error);
            })
        },

        async updateAgendaItemsDetails(context, itemDetails) {
            await apolloClient.mutate({
                mutation: updateAgendaItemsDetails,
                variables: {
                    newPurpose: itemDetails.newPurpose,
                    detailKey: itemDetails.detailKey,
                    updatePlace: itemDetails.updatePlace
                }
            }).then((response) => {
                context.commit('updateSelectedItemDetails', response.data.updateAgendaItemsDetails.agendaItemDetails[0])

            }).catch(async (error) => {

                console.log(error);
            })

        },

        async updateVottingAgendaItemsDetails(context, itemDetails) {
            context.commit('cleanSelectedAgendas')
            await apolloClient.mutate({
                mutation: updateVottingAgendaItem,
                variables: {
                    newPurpose: itemDetails.newPurpose,
                    newItem: itemDetails.newItem,
                    updatePlace: itemDetails.updatePlace
                }
            }).catch(async (error) => {

                console.log(error);
            })
        },

        async switchAgendaItemsPresenter(context, member) {
            await apolloClient.mutate({
                mutation: switchAgendaItemPresenter,
                variables: {
                    itemKey: member.itemKey,
                    itemPresenter: member.member,
                }
            }).catch(async (error) => {

                console.log(error);
            })
        },

        async updateAgenda(context, agenda) {

            await apolloClient.mutate({
                mutation: updateAgenda,
                variables: {
                    agendaTitle: agenda.newItem,
                    agendaUniqueId: agenda.agendaKey
                }
            }).then((response) => {
                context.commit('updateSelectedAgenda', response.data.updateAgenda.meetingAgenda[0])

            }).catch(async (error) => {

                console.log(error);
            })

        },

        async addAgendaItem(context, agenda) {
            context.commit('cleanSelectedAgendas')

            await apolloClient.mutate({
                mutation: addAgendaItem,
                variables: {
                    agendaKey: agenda.agendaKey,
                    itemLastPosition: agenda.Position
                }
            }).then((response) => {
                const agendaItemnode = response.data.addAgendaItem.agendaItem;
                const itemObject = {
                    "itemNumber": agendaItemnode.itemNumber,
                    "itemKey": agendaItemnode.itemUniqueId,
                    "itemName": agendaItemnode.itemTitle,
                    "status": agendaItemnode.itemStatus,
                    "itemType": agendaItemnode.itemType,
                    "itemDetails": {
                        "itemKey": agendaItemnode.itemDetails.edges[0].node.detailsUniqueId,
                        "itemName": agendaItemnode.itemTitle,
                        "itemPuporse": agendaItemnode.itemDetails.edges[0].node.detailsPuporse,
                        "itemDuration": agendaItemnode.itemDetails.edges[0].node.detailsDuration,
                        "itemPresenter": agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName || "No Presenter",
                    }
                };
                context.commit('addNewAgendaItem', {item: itemObject, agendaKey: agenda.agendaKey})

            }).catch(async (error) => {

                console.log(error);
            })
        },

        async addNewVotingAgendaItem(context, agenda) {
            context.commit('cleanSelectedAgendas')

            await apolloClient.mutate({
                mutation: addVottingAgendaItem,
                variables: {
                    agendaKey: agenda.agendaKey,
                    itemLastPosition: agenda.Position
                }
            }).then((response) => {
                const voteItem = response.data.addVottingAgendaItem.agendaItem;
                const documentDetailsList = [];

                voteItem.itemDetails.edges[0].node.documentDetails.edges.forEach(file => {
                    documentDetailsList.push(
                        {'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
                    )
                });

                const itemObject = {
                    "itemNumber": voteItem.itemNumber,
                    "itemKey": voteItem.itemUniqueId,
                    "itemName": voteItem.itemTitle,
                    "status": voteItem.itemStatus,
                    "itemType": voteItem.itemType,
                    "itemVoteDetails": voteItem.voteAgendaItem,
                    "itemDetails": {
                        "itemKey": voteItem.itemDetails.edges[0].node.detailsUniqueId,
                        "itemName": voteItem.itemTitle,
                        "itemPuporse": voteItem.itemDetails.edges[0].node.detailsPuporse,
                        "itemDuration": voteItem.itemDetails.edges[0].node.detailsDuration,
                        "itemPresenter": voteItem.itemPresenter.firstName + " " + voteItem.itemPresenter.lastName || "No Presenter",
                        'documentDetails': documentDetailsList
                    }
                };
                context.commit('addNewAgendaItem', {item: itemObject, agendaKey: agenda.agendaKey})

            }).catch(async (error) => {

                console.log(error);
            })
        },

        async addAgenda(context, agenda) {
            context.commit('cleanSelectedAgendas')

            await apolloClient.mutate({
                mutation: addAgenda,
                variables: {
                    meetingKey: agenda.meetingKey,
                    agendaLastPosition: agenda.Position
                }
            }).then((response) => {
                const agendanode = response.data.addAgenda.agenda;
                const agendaObj = {
                    "AgendaNumber": agendanode.agendaNumber,
                    "primaryKey": agendanode.primaryKey,
                    "agendaKey": agendanode.agendaUniqueId,
                    "Agenda": agendanode.agendaTitle,
                    "AgendaItems": []
                };
                context.dispatch('addAgendas', agendaObj)
                context.commit('cleanAgendas')
                context.dispatch('loadBestPracticeAgendaTemplates', agenda.meetingKey)

            }).catch(async (error) => {

                console.log(error);
            })
        },

        async publishAgendas(context, agenda) {
            await apolloClient.mutate({
                mutation: publishMeetingAgendas,
                variables: {
                    meetingKey: agenda.meetingKey,
                }
            }).then(() => {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Agenda published successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }).catch((error) => {
                console.error("publishMeetingAgendas ==>", error)
            })
        },

        async unPublishAgendas(context, agenda) {
            await apolloClient.mutate({
                mutation: unPublishMeetingAgendas,
                variables: {
                    meetingKey: agenda.meetingKey,
                }
            }).then(() => {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Agenda un-published successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }).catch((error) => {
                console.error("publishMeetingAgendas ==>", error)
            })
        },

        async moveMeetingAgenda(context, agenda) {

            await apolloClient.mutate({
                mutation: moveMeetingAgenda,
                variables: {
                    agendaUniqueId: agenda.agendaUniqueId,
                    position: agenda.position,
                }
            }).then((response) => {

                if (response.data.moveMeetingAgenda.success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Agenda moved successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: ' Failed to move Agenda',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {

                console.log(error);
            })
        },

        async deleteAgendas(context, agenda) {

            await apolloClient.mutate({
                mutation: deleteAgenda,
                variables: {
                    meetingKey: agenda.meetingKey,
                    agendaKey: agenda.agendaKey,
                }
            }).then(() => {

                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Agenda deleted successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {

                console.log(error);
            })
        },

        async moveMeetingAgendaItem(context, agenda) {

            await apolloClient.mutate({
                mutation: moveMeetingAgendaItem,
                variables: {
                    itemUniqueId: agenda.itemUniqueId,
                    position: agenda.position,
                }
            }).then((response) => {

                if (response.data.moveMeetingAgendaItem.success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Agenda Item moved successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: ' Failed to move Agenda Item',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {

                console.log(error);
            })
        },

        async addAgendaItemToSpecificPosition(context, agenda) {

            await apolloClient.mutate({
                mutation: addAgendaItemToSpecificPosition,
                variables: {
                    itemUniqueId: agenda.itemUniqueId,
                }
            }).then((response) => {

                if (response.data.addAgendaItemToSpecificPosition.success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Agenda Item added successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: ' Failed to add Agenda Item',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {

                console.log(error);
            })
        },

        async deleteAgendaItem(context, agenda) {

            await apolloClient.mutate({
                mutation: deleteMeetingAgendaItem,
                variables: {
                    agendaKey: agenda.agendaKey,
                    itemUniqueId: agenda.itemUniqueId,
                }
            }).then(() => {

                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Agenda Item deleted successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {

                console.log(error);
            })
        },

        async previewAgendas(context, meetingKey) {

            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMeetingsCombinedAgendas,
                variables: {
                    meetingUniqueId: meetingKey
                }
            }).then((response) => {
                context.commit('setCombineddAgendas', response.data.allMeetingsCombinedAgendas?.combinedPath)

            }).catch(async (error) => {

                console.log(error);
            })
        },

        async addUnConfirmMeetingsMinutesAgendaItem(context, agenda) {
            context.commit('cleanSelectedAgendas')

            await apolloClient.mutate({
                mutation: addConfirmMinutesAgendaItem,
                variables: {
                    selectedAgenda: agenda.selectedAgenda,
                    lastMeeting: agenda.lastMeeting
                }
            }).then((response) => {
                const agendaItemnode = response.data.addConfirmMinutesAgendaItem.agendaItem;
                const itemObject = {
                    "itemNumber": agendaItemnode.itemNumber,
                    "itemKey": agendaItemnode.itemUniqueId,
                    "itemName": agendaItemnode.itemTitle,
                    "status": agendaItemnode.itemStatus,
                    "itemType": agendaItemnode.itemType,
                    "itemMinutesDetails": agendaItemnode.cominedMinutesDocumentItem,
                    "itemDetails": {
                        "itemKey": agendaItemnode.itemDetails.edges[0].node.detailsUniqueId,
                        "itemName": agendaItemnode.itemTitle,
                        "itemPuporse": agendaItemnode.itemDetails.edges[0].node.detailsPuporse,
                        "itemDuration": agendaItemnode.itemDetails.edges[0].node.detailsDuration,
                        "itemPresenter": agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName || "No Presenter",
                    }
                };
                context.rootState.MeetingsStore.selectedMeetingDetails.meetingHasConfirmMinutes = true
                context.commit('addNewAgendaItem', {item: itemObject, agendaKey: agenda.selectedAgenda})

            }).catch(async (error) => {

                console.log(error);
            })
        },

        async addInterestRegisterAgendaItem(context, agenda) {
            context.commit('cleanSelectedAgendas')

            await apolloClient.mutate({
                mutation: addInterestRegisterAgendaItem,
                variables: {
                    selectedAgenda: agenda.selectedAgenda,
                    lastMeeting: agenda.lastMeeting
                }
            }).then((response) => {
                const agendaItemnode = response.data.addInterestRegisterAgendaItem.agendaItem;
                const itemObject = {
                    "itemNumber": agendaItemnode.itemNumber,
                    "itemKey": agendaItemnode.itemUniqueId,
                    "itemName": agendaItemnode.itemTitle,
                    "status": agendaItemnode.itemStatus,
                    "itemType": agendaItemnode.itemType,
                    "itemDetails": {
                        "itemKey": agendaItemnode.itemDetails.edges[0].node.detailsUniqueId,
                        "itemName": agendaItemnode.itemTitle,
                        "itemPuporse": agendaItemnode.itemDetails.edges[0].node.detailsPuporse,
                        "itemDuration": agendaItemnode.itemDetails.edges[0].node.detailsDuration,
                        "itemPresenter": agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName || "No Presenter",
                    }
                };
                context.rootState.MeetingsStore.selectedMeetingDetails.meetingHasInterestRegister = true
                context.commit('addNewAgendaItem', {item: itemObject, agendaKey: agenda.selectedAgenda})

            }).catch(async (error) => {

                console.log(error);
            })
        },
    }
}

