<template>

      <v-col  md="3" cols="2" sm="6" xs="12">
            <v-card
                class="mx-auto"
                max-width="400"
            >
                <v-img
                class="white--text align-end"
                height="200px"
                src="/cover.png"
                >
                <v-card-title>{{reportdata.TemplateName}}</v-card-title>
                </v-img>

                <v-card-subtitle class="pb-0">
                    About template
                </v-card-subtitle>

                <v-card-text class="text--primary">
                <div>
                    {{reportdata.AboutTemplate}}
                    
                </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                <v-btn
                    color="indigo"
                    outlined
                    rounded
                    small
                    text
                    @click="$emit('openBuilderDialogy')"
                >
                    Use template
                </v-btn>
                </v-card-actions>
            </v-card>
      </v-col>
</template>

<script>
export default {
    props:['reportdata'],
    data(){
        return{
        }
    }

}
</script>

<style>

</style>








   