<template>
  <div>
    <v-overlay
      :absolute="absolute"
      :value="overlay"
      :z-index="index"
      color="#c7c8d1"
    >

      <div class="spinner-box">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div style="color: #3f51b5">
          <strong>Wait and you will be ridirected . . .</strong>
        </div>
      </div>
    </v-overlay>

  </div>
</template>


<script>
export default {
  data() {
    return {
      userToken: null,
      absolute: true,
      overlay: true,
      email:null,
    };
  },
  methods: {
    async verifyAccount(userToken) {
      await this.$store.dispatch("activateUserAccount", userToken);
      this.$router.push('/send_password_reset_email')
    },
  },
  mounted() {
    this.userToken = this.$route.params.userToken;
    this.verifyAccount(this.userToken);
  },
};
</script>

<style  scoped>
.spinner-box {
  padding: 20px;
  position: fixed;
  width: 15%;
  margin: auto;
  left: 0px;
  top: 40%;
  right: 0px;
  background: white;
  box-shadow: 0px 0px 9px 0px #888cbf;
  text-align: center;
}
.spinner-box23 {
  padding: 20px;
  position: fixed;
  width: 25%;
  margin: auto;
  left: 0px;
  top: 40%;
  right: 0px;
  background: white;
  box-shadow: 0px 0px 9px 0px #888cbf;
  text-align: center;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 90px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #3f51b5;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>