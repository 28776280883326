<template>
  <v-flex>
    <v-simple-table style="width: 100%">
      <template>

        <v-overlay v-if="!selectedAgenda" :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <thead>
        <tr>
          <th class="text-left" style="width: 10%"></th>
          <th class="text-left" style="width: 80%"></th>
        </tr>
        </thead>
        <tbody>

        <tr v-if="boardSetting().showAgendaPurpose">
          <td>
            <strong>Purpose :</strong>
          </td>
          <!--          <td v-if="hasAccess('can_schedule_meeting')">-->
          <td class="flex items-center">
            <span class="mr-2">{{ selectedAgenda.agendaPurpose }}</span>
            <v-menu :close-on-content-click="false" top :offset-y="offset" v-if="!hasPublishedAgenda">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>

              <v-card width="700">
                <v-card-text class="flex">
                  <input
                      type="text"
                      class="mt-2 w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Agenda Purpose"
                      style="border: 1px solid black"
                      required
                      v-model="selectedAgenda.agendaPurpose"
                  />
                  <v-btn
                      class="mr-3 mb-1 ml-4 mt-5 indigo--text"
                      @click="onPurposeInput(selectedAgenda)"
                      small
                      outlined
                      icon
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-menu>
          </td>
        </tr>

        <tr v-if="boardSetting().showAgendaPresenter">
          <td>
            <strong>Presenter :</strong>
          </td>

          <td v-if="hasAccess('can_schedule_meeting') && !hasPublishedAgenda">
            <v-menu app offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    slot="activator"
                    color="indigo"
                    small
                    style="text-transform: capitalize"
                    text
                    v-bind="attrs"
                    v-on="on"
                >{{ !presenter ? "No Presenter" : presenter }}
                </v-btn
                >
              </template>
              <v-list dense>
                <v-list-item
                    v-for="member in managementCommitteeMembers"
                    :key="member.username"
                    link
                >
                  <v-list-item-title
                      @click="changePresenter(selectedAgenda, member)"
                  >
                    {{ member.firstName }} {{ member.lastName }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>

          <td v-else>
            <v-btn
                slot="activator"
                color="indigo"
                small
                style="text-transform: capitalize"
                text
                v-bind="attrs"
                v-on="on"
            >{{ presenter }}
            </v-btn
            >
          </td>
        </tr>

        <tr v-if="boardSetting().showAgendaDuration">
          <td>
            <strong>Duration :</strong>
          </td>
          <td class="Editable">
            <span class="mr-2">{{ selectedAgenda.agendaDuration }}</span> Min
            <v-menu :close-on-content-click="false" top :offset-y="offset" v-if="hasAccess('can_schedule_meeting') && !hasPublishedAgenda">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>

              <v-card width="700">
                <v-card-text class="flex">
                  <input
                      type="number"
                      class="mt-2 w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Agenda Duration"
                      style="border: 1px solid black"
                      required
                      v-model="selectedAgenda.agendaDuration"
                  />
                  <v-btn
                      class="mr-3 mb-1 ml-4 mt-5 indigo--text"
                      @click="onPurposeInput(selectedAgenda)"
                      small
                      outlined
                      icon
                  >
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-menu>

          </td>
        </tr>

        </tbody>
      </template>
    </v-simple-table>
  </v-flex>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ManagementCommitteeAgendaDetailsTop",
  data() {
    return {
      absolute: true,
      overlay: true,
      file: "",
      loading3: false,
      dragging: false,
      duration: 0,
      Purpose: "",

      isEditItemPurpose: false,
      isEditItemDuration: false,
      autofocus: false,
      isEditItemPurposeKey: "",
      itemPurpose: "",
    };
  },
  methods: {

    //   onChange(e) {
    //     var files = e.target.files || e.dataTransfer.files;
    //
    //     if (!files.length) {
    //       this.dragging = false;
    //       return;
    //     }
    //
    //     this.createFile(files[0]);
    //   },
    //
    //   createFile(file) {
    //     if (!file.type.match("pdf.*")) {
    //       alert("please select PDF file");
    //       this.dragging = false;
    //       return;
    //     }
    //
    //     if (file.size > 100000000) {
    //       alert("please check file size no over 5 MB.");
    //       this.dragging = false;
    //       return;
    //     }
    //
    //     this.file = file;
    //     console.log(this.file);
    //     this.dragging = false;
    //   },
    //
    //   removeFile() {
    //     this.file = "";
    //   },
    //
    //   onInput(event) {
    //     var cleared = event.target.innerText.replace(/[^\d.-]/g, "");
    //     console.log(cleared);
    //     this.duration = cleared;
    //   },
    //
    //   clearAllUnwantedChar(event) {
    //     event.target.innerText = this.duration;
    //   },
    switchMemberNamesWithNames() {
      return this.managementCommitteeMembers.find((member) => member.firstName + " " + member.lastName === this.presenter).id
    }
    ,
    async onPurposeInput(selectedAgenda) {
      let input = {
        agendaUniqueId: selectedAgenda.id,
        agendaTitle: selectedAgenda.agendaTitle,
        agendaPresenter: this.switchMemberNamesWithNames(),
        agendaPurpose: selectedAgenda.agendaPurpose,
        agendaDuration: selectedAgenda.agendaDuration,
      };

      await this.$store.dispatch("editManagementCommitteeAgenda", input);
    },
    async changePresenter(selectedAgenda, member) {
      this.presenter = member.firstName + " " + member.lastName;

      let input = {
        agendaUniqueId: selectedAgenda.id,
        agendaTitle: selectedAgenda.agendaTitle,
        agendaPresenter: member.id,
        agendaPurpose: selectedAgenda.agendaPurpose,
        agendaDuration: selectedAgenda.agendaDuration,
      };

      await this.$store.dispatch("editManagementCommitteeAgenda", input);
    },
  },
  computed: {
    //   extension() {
    //     return this.file ? this.file.name.split(".").pop() : "";
    //   },
    //
    presenter: {
      get: function () {
        return this.selectedAgenda.agendaPresenter;
      },
      set: function (newValue) {
        this.selectedAgenda.agendaPresenter = newValue;
      },
    },

    ...mapGetters({
      managementCommitteeMembers: "getManagementCommitteeMembers",
    }),

    selectedAgenda() {
      return this.$store.getters.getSelectedManagementCommitteeAgenda;
    },
    hasPublishedAgenda() {
      return this.$store.getters.getSelectedManagementCommitteeMeetings.meetingHasPublishedAgenda || false
    },
  }
};
</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}

[contenteditable=true]:empty:not(:focus):before {
  content: attr(data-ph);
  color: grey;
  font-style: italic;
}

.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>