<template>
  <div class="text-center">
    <v-dialog v-model="OpenMinuteNoteEditor" persistent width="70%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="indigo">mdi-file-document</v-icon>
          {{ noteTitle }}
          <v-spacer></v-spacer>
          <v-progress-circular
              v-if="saveLoading"
              indeterminate
              color="success"
              size="20"
          ></v-progress-circular>
          <span v-if="saveLoading" style="margin-left:10px">{{ bntLabel }}</span>
        </v-card-title
        >

        <v-card-text>
          <v-container>
            <CustomEditor
                :content="currentNotes.notesDescriptions"
                :editorOption="editorOption"
                @onManagementEditorChange="onManagementEditorChange($event)"
                style="height: 480px"
            />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          Autosave after {{ numberOfTyping }} clicks
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              text
              outlined
              small
              @click="$emit('CloseMinuteNoteEditor')"
          >
            <v-icon small>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
              color="success"
              outlined
              small
              :loading="saveLoading"
              @click="saveMinuteNotesOnTyping"
          >
            <v-icon small>mdi-floppy</v-icon>
            {{ bntLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomEditor from "@/modules/management_meeting_management/management_shared/fields/CustomEditor.vue";

export default {
  name: "ManagementCommitteeMinuteNote",
  props: ["OpenMinuteNoteEditor", "selectedAgenda", "noteTitle"],

  components: {
    CustomEditor,
  },

  data: () => ({
    content: "",
    bntLabel: "Save",
    numberOfTyping: 200,
    saveLoading: false,
    editorOption: {
      placeholder: "Start to take note . . . ",
      theme: "snow",
    },
  }),

  methods: {
    onManagementEditorChange(event) {
      this.content = event
      if (this.numberOfTyping < 1) {
        this.autoSaveMinuteNotesOnTyping(event)
        this.numberOfTyping = 200;
      }
      this.numberOfTyping -= 1;
    },

    async autoSaveMinuteNotesOnTyping(notes) {
      this.saveLoading = true;
      this.bntLabel = "Auto Saving . . ."
      if (this.notesStatus) {
        await this.$store.dispatch("editManagementCommitteeAgendaNote", {
          notesUniqueId: this.currentNotes.id,
          notesDescriptions: notes
        })
      } else {
        await this.$store.dispatch("createManagementCommitteeAgendaNote", {
          notesAgenda: this.selectedAgenda.id,
          notesDescriptions: notes
        })
        this.notesStatus = true
      }
      this.saveLoading = false;
      this.bntLabel = "Save"
    },

    async saveMinuteNotesOnTyping() {
      this.saveLoading = true;
      this.bntLabel = "Saving . . ."
      this.notesStatus ?
          await this.$store.dispatch("editManagementCommitteeAgendaNote", {
            notesUniqueId: this.currentNotes.id,
            notesDescriptions: this.content
          }) :
          await this.$store.dispatch("createManagementCommitteeAgendaNote", {
            notesAgenda: this.selectedAgenda.id,
            notesDescriptions: this.content
          })
      this.$emit('CloseMinuteNoteEditor')
      this.saveLoading = false;
      this.bntLabel = "Save"

    },
  },

  computed: {
    currentNotes() {
      return this.$store.getters.getManagementCommitteeAgendaNotes
    },
    notesStatus() {
      return this.$store.getters.getManagementCommitteeAgendaNotesStatus
    },
  },
};
</script>