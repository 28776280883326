<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8" class="mt-6">
        <v-card class="pl-5 pr-5" style="color: ">
          <v-card-title>
            <span class="text-h6">
              <v-icon color="indigo">mdi-account</v-icon>Edit my profile</span
            >
          </v-card-title>
          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="First Name"
                    class="purple-input"
                    :disabled="disabled"
                    v-model="userprofile.firstName"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    label="Last Name"
                    class="purple-input"
                    :disabled="disabled"
                    v-model="userprofile.lastName"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Suffix/Ttitle" v-model="usersuffix" />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    class="purple-input"
                    label="Position/Tittle"
                    :disabled="disabled"
                    v-model="usertitle"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    label="Email Address"
                    class="purple-input"
                    :disabled="disabled"
                    v-model="userprofile.username"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    label="Acces Level"
                    class="purple-input"
                    :disabled="disabled"
                    v-model="usertitle"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="8" lg="8">
                  <v-text-field
                    class="purple-input"
                    label="My Boards"
                    :disabled="disabled"
                    v-model="userboards.boarddescription"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn
                    class="ma-2"
                    outlined
                    small
                    color="pink"
                    v-if="isHidden"
                    @click="onupdate();isHidden = false"
                 
                  >
                    <v-icon small>mdi-close</v-icon> Close
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    outlined
                    small
                    color="success"
                    class="mr-0"
                    @click="
                      onupdate();
                      loader = 'loading';
                    "
                  >
                    Update Profile
                  </v-btn>

                  <v-btn v-if="!isHidden" outlined class="ma-2" small color="orange" @click="ondisabled(); isHidden = true" >
                    Edit Profile
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" class="mt-6">
        <v-card style="background-color: rgba(255, 255, 255, 0.5)">
          <v-list-item>
            <v-row align="center" justify="center" class="mt-4">
              <v-list-item-avatar size="60" color="white">
                <v-img
                  max-width="100%"
                  :src="MEDIA_SERVER + userboards.boardinstitutionlogo"
                ></v-img>
              </v-list-item-avatar>
            </v-row>
          </v-list-item>

          <v-list-item>
            <v-row
              align="center"
              justify="center"
              class="text-h8 ml-9 pl-16 text-xs-center"
            >
              <v-list-item-content>
                <v-list-item-title class="text-h8 ma-3 text-xs-center">
                  {{ userboards.boarddescription }}
                </v-list-item-title>
              </v-list-item-content>
            </v-row>
            <v-divider></v-divider>
          </v-list-item>

          <v-span>
            <div class="text-overline text-h8 ml-4" style="color: black">
              Tittle: {{ usertitle }}
            </div>
            <div class="text-overline text-h8 ml-4" style="color: black">
              Access Level: {{ usertitle }}
            </div>
            <div class="text-overline text-h8 ml-4" style="color: black">
              Fullname : {{ logeduser.fullname }}
              <br />
            </div>
            <div class="text-overline text-h8 ml-4" style="color: black">
              Email Address : {{ userprofile.username }}
            </div>
          </v-span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      disabled: true,
      overlay: false,
      loader: null,
      loading: false,
      isHidden: false,
      logeduser: { fullname: "" },
    };
  },

  methods: {
    ondisabled() {
      this.disabled = false;
    },

    onupdate() {
      this.disabled = true;
    },
  },

  computed: {
    userprofile() {
      var profile = this.$store.state.AuthStore.user;

      return profile;
    },

    usertitle() {
      var models =
        this.$store.state.AuthStore.user.roleUser.edges[0].node.userWithRoleRole
          .roleName;

      return models;
    },

    usersuffix() {
      var suffix =
        this.$store.state.AuthStore.user.profileUser.edges[0].node
          .userProfileTitle;
      return suffix;
    },

    userboards() {
      var boarddescription =
        this.$store.state.AuthStore.user.profileUser.edges[0].node
          .userProfileBoard.boardDescriptions;

      var boardinstitutionlogo =
        this.$store.state.AuthStore.user.profileUser.edges[0].node
          .userProfileBoard.boardInstitution.institutionLogo;
      var boardinstitutionshort =
        this.$store.state.AuthStore.user.profileUser.edges[0].node
          .userProfileBoard.boardInstitution.institutionShortform;

      return {
        boarddescription,
        boardinstitutionlogo,
        boardinstitutionshort,
      };
    },

    // usermanagemnts() {
    // var boardmanagements = this.$store.state.AuthStore.user.managementUser.edges[0].node.managementMemberManagemnt.managementName
    // var boardmanagementlogo = this.$store.state.AuthStore.user.managementUser.edges[0].node.managementMemberManagemnt.managementBoard.boardInstitution.institutionLogo
    // }
  },

  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },

  mounted() {
    const userDetails = store.getters["AuthStore/user"];
    this.logeduser.fullname =
      userDetails.firstName + " " + userDetails.lastName;
  },
};
</script>

<style>
</style>