<template>
  <div class="text-center">
    <v-dialog v-model="OpenMinuteNoteEditor" persistent width="70%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="indigo">mdi-file-document</v-icon>
          {{ noteTitle }}
          <v-spacer></v-spacer>
          <v-progress-circular
          v-if="saveloading"
            indeterminate
            color="success"
            size="20"
          ></v-progress-circular> 
          <span v-if="saveloading" style="margin-left:10px">{{bntLabel}}</span>
          </v-card-title
        >

        <v-card-text>
          <v-container>
            <CustomEditor
              :content="correntNotes.notesDescriptions"
              :editorOption="editorOption"
              @onEditorChange="onEditorChange($event)"
              style="height: 480px"
            />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          Autosave after {{numberOfTyping}} clicks
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseMinuteNoteEditor')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn
            color="success"
            outlined
            small
            :loading="saveloading"
            @click="saveMinuteNotesOnTyping"
          >
            <v-icon small>mdi-floppy</v-icon> {{ bntLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomEditor from "../e_board_shared/fields/CustomEditor.vue";

export default {
  props: ["OpenMinuteNoteEditor", "selectedAgenda", "noteTitle"],

  components: {
    CustomEditor,
  },

  data: () => ({
    content: "",
    bntLabel: "Save",
    numberOfTyping: 200,
    saveloading: false,
    editorOption: {
      placeholder: "Start to take note . . . ",
      theme: "snow",
    },
  }),

  methods: {
    onEditorChange(event) {
      this.content=event
      if (this.numberOfTyping < 1) {
        this.autoSaveMinuteNotesOnTyping(event)
        this.numberOfTyping = 200;
      }
      this.numberOfTyping -= 1;
    },

    async autoSaveMinuteNotesOnTyping(note) {
        this.saveloading = true;
        this.bntLabel="Auto Saving . . ."
        await this.$store.dispatch("updateMinutesNotesOnTyping", {
          note: note,
          itemKey: this.selectedAgenda.itemKey,
        });
        this.saveloading = false;
        this.bntLabel="Save"
    },

    async saveMinuteNotesOnTyping() {
        this.saveloading = true;
        this.bntLabel="Saving . . ."
        await this.$store.dispatch("updateMinutesNotesOnTyping", {
          note: this.content,
          itemKey: this.selectedAgenda.itemKey,
        });
        this.saveloading = false;
        this.bntLabel="Save"
        this.$emit('CloseMinuteNoteEditor')
    },
  },

  computed: {
    correntNotes() {
      return this.$store.state.MinutesStore.agendaItemMinuteNote;
    },
  },
};
</script>