<template>
  <v-container fluid class="my-5" app>
    <div>
      <v-layout justify-center align-center>
        <h2 style="color:#3F51B5; font-weight ">
          &darr; Make Your NoteBook here!
        </h2>
      </v-layout>
    </div>

    <div class="flex justify-end">
      <v-spacer></v-spacer>
      <v-btn
          small
          rounded
          color="primary"
          class="text-capitalize"
          @click="createNoteBook"
      >
        <v-icon>mdi-plus</v-icon>
        Create Notebook
      </v-btn>
    </div>

    <div class="row" v-if="userNoteBooks.length !== 0">
      <div class="col-md-4" v-for="(notebook, index) in userNoteBooks"
           :key="index">
        <v-card
            class="module-box"
        >
          <v-img
              class="white--text align-end"
              height="200px"
              src="@/assets/bod33.png"
          >
            <v-card-title class="text-capitalize">
              <strong> {{ notebook.notebookTitle }} </strong>
            </v-card-title>
          </v-img>

          <div class="pb-0 mx-4 mt-2" style="color: #0d47a1">
             Pages<strong> {{ notebook.notebookPages }} </strong>
          </div>
          <div class="pb-0 mx-4 mt-2">
            <strong> Created On: <span class="font-medium" style="color: #6E6E6E;">{{ notebook.notebookCreatedDate | formatFull }}</span> </strong>
          </div>

          <v-card-actions>
            <v-btn
                fill
                fab
                small
                @click="editNoteBook(notebook)"
            >
                  <span>
                    <v-icon color="warning">mdi-pen</v-icon>
                  </span>
            </v-btn>
            <v-btn
                fill
                fab
                small
                @click="deleteNotebook(notebook.id)"
            >
                  <span style="color: #fc2b23ff">
                    <v-icon>mdi-delete</v-icon>
                  </span>
            </v-btn>
            <v-btn
                fill
                fab
                small
                link :to="`/eboard_notebook/${notebook.id}`"
            ><span>
                    <v-icon color="primary">mdi-arrow-right</v-icon>
                  </span></v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>

    <v-layout justify-center align-center
              v-else>
      <img src="@/assets/notes.png"/>
    </v-layout>
    <NewNotebook
        :new-note-book-dialog="noteBookDialog"
        :selected-note-book="selectedNoteBook"
        :note-book-edit="noteBookEdit"
        @closeNewNoteBook="noteBookDialog = false; noteBookEdit = false; selectedNoteBook = null"
    ></NewNotebook>


  </v-container>
</template>

<script>

import NewNotebook from "@/modules/meeting_management/eboard_notebook/NewNotebook.vue";
import {confirmAlert} from "@/services/notificationService";

export default {
  components: {NewNotebook},
  data() {
    return {
      noteBookDialog: false,
      selectedNoteBook: null,
      noteBookEdit: false
    };
  },
  methods: {
    createNoteBook() {
      this.noteBookDialog = true
    },
    editNoteBook(noteBook) {
      this.noteBookDialog = true
      this.noteBookEdit = true
      this.selectedNoteBook = noteBook
    },
    deleteNotebook(notebookUniqueId) {
      confirmAlert("Are you sure?", "Delete").then((data) => {
        if (data.isConfirmed) {
          this.$store.dispatch('deleteBoardNotebook', {
            notebookUniqueId: notebookUniqueId
          })
        }
      })
    },
  },
  computed: {
    userNoteBooks() {
      return this.$store.getters.getBoardNotebooks
    }
  },
  mounted() {
    this.$store.dispatch('loadBoardNotebooks')
  }
};
</script>

<style lang="scss" scoped>
img {
  position: relative;
  display: block;
  float: left;
  width: 260px;
  height: 200px;
}

.landing-content {
  //min-height: calc(100vh - 40px);
  // max-height: 100vh;
  // max-width: 100% !important;
  // background: #eaeaea;
  // background-image: url(../assets/bg1.jpg);
  margin-bottom: 40px;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;

  .header-row {
    //margin: 20px auto;
    padding: 15px 0 0 0;
    //display: flex;
    .header-title {
      position: relative;

      h1 {
        padding: 20px 0;
        text-align: center;
        font-weight: 900;
        font-size: 2rem;
        color: #19577b;
        letter-spacing: 1.5px;
      }
    }
  }

  .module-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    // margin-top: 60px;
    margin: 15px auto 0px;

    .module-box {
      background: rgb(240, 242, 245);
      border-radius: 10px;
      width: 10%;
      min-width: 300px;
      max-width: 400px;
      padding: 10px;
      border: 1px solid #ddd;
      min-height: 130px;
      // box-shadow: 2px 4px 6px #c1c1c1;
      box-shadow: 2px 1px 8px 2px #bbbbbb;
      margin: 20px 10px;
      outline: none;
      cursor: pointer;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &:hover {
        background: rgb(237, 241, 255);

        box-shadow: 4px 8px 10px 2px rgba(135, 135, 135, 0.9);
        transition: all 0.5s ease 0s;

        .v-icon {
          animation-name: bounce;
          -moz-animation-name: bounce;
          // width: 50px !important;
          // height: 50px !important;
          .mdi {
            width: 50px !important;
            height: 50px !important;
          }
        }

        .mb-name {
          letter-spacing: 1.5px;
          transform: scale(1.08);
        }
      }

      .v-icon {
        // height: 40px !important;
        // width: 40px !important;
        color: #fc2b23ff;
        // color: #616b8f;
        // color: #36366e;

        // -webkit-animation-duration: 1s;
        // animation-duration: 1s;
        // -webkit-animation-fill-mode: both;
        // animation-fill-mode: both;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;

        .mdi {
          height: 40px !important;
          width: 40px !important;
        }
      }

      .mb-name {
        margin-top: 25px;
        font-weight: 800;
        letter-spacing: 1.5px;
        color: #00629c;
        transition: all 0.3s ease 0s;
      }
    }
  }
}
</style>

