<template>
  <div class="pdf-viewer">
    <div id="webviewer" ref="viewer"></div>
  </div>
</template>

<script>
import WebViewer from "@pdftron/webviewer";

export default {
  name: "WebViewerAnnotate",
  props: { initialDoc: { type: String }, document: { type: String } },
  mounted() {
    // const path = `${process.env.BASE_URL}webviewer`;
    const viewerElement = this.$refs.viewer;

    WebViewer(
      {
        path: "/webviewer", // Path to the PDFTron WebViewer lib directory
        initialDoc: this.initialDoc, // Path to the initial PDF document,
        licenseKey: "demo:1694423505125:7c0da96c0300000000eb3e5506d2402c07e53cc285e9cec64d6053e411",
      },
      viewerElement
    ).then((instance) => {
      const { documentViewer, annotationManager} = instance.Core;

      documentViewer.addEventListener("documentLoaded", () => {

        annotationManager.addEventListener(
          "annotationChanged",
          async () => {
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations();
            const data = await doc.getFileData({xfdfString});
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: 'application/pdf' });

            const formData = new FormData();
            formData.append("file",  blob);
            formData.append("path", this.document);

            await fetch(this.MEDIA_SERVER+"/upload", {method: "POST",body: formData}).then((response)=>{
							console.log(response);
						});
            console.log(`Save state changed: ${event}`);           
          }
        );
      });
    });
  },
};
</script>

<style scoped>
#webviewer {
  height: 100vh;
}
</style>

<!--<template>-->
<!--  <div >-->
<!--    asaaawa-->
<!--    <div id="webviewer" ref="viewer"></div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--import { onMounted } from "vue";-->
<!--import WebViewer from "@pdftron/webviewer";-->

<!--export default {-->
<!--  name: "WebViewerAnnotate",-->
<!--  props: { initialDoc: { type: String } },-->
<!--  setup(props) {-->
<!--    onMounted(() => {-->
<!--      const path = `${process.env.BASE_URL}webviewer`;-->
<!--      WebViewer({-->
<!--        path,-->
<!--        initialDoc: props.initialDoc,-->
<!--        licenseKey: 'your_license_key'  // sign up to get a free trial key at https://dev.apryse.com-->
<!--      }, this.$refs.viewer).then(-->
<!--        (instance) => {-->
<!--          const { documentViewer, annotationManager, Annotations } =-->
<!--            instance.Core;-->

<!--          documentViewer.addEventListener("documentLoaded", () => {-->
<!--            const rectangleAnnot = new Annotations.RectangleAnnotation({-->
<!--              PageNumber: 1,-->
<!--              // values are in page coordinates with (0, 0) in the top left-->
<!--              X: 100,-->
<!--              Y: 150,-->
<!--              Width: 200,-->
<!--              Height: 50,-->
<!--              Author: annotationManager.getCurrentUser(),-->
<!--            });-->
<!--            annotationManager.addAnnotation(rectangleAnnot);-->
<!--            annotationManager.redrawAnnotation(rectangleAnnot);-->
<!--          });-->
<!--        }-->
<!--      );-->
<!--    });-->
<!--  },-->
<!--};-->
<!--</script>-->

<!--<style>-->
<!--#webviewer {-->
<!--  height: 100vh;-->
<!--}-->
<!--</style>-->
