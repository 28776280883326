import {apolloClient} from '@/vue-apollo'
import allNoteBookPages from '@/graphql/eboard-notebook-page-graphql/allNoteBookPages.graphql'
import createNotebookPage from '@/graphql/eboard-notebook-page-graphql/createNotebookPage.graphql'
import updateNotebookPage from '@/graphql/eboard-notebook-page-graphql/updateNotebookPage.graphql'
import deleteNotebookPage from '@/graphql/eboard-notebook-page-graphql/deleteNotebookPage.graphql'

import {errorAlert, successAlert} from "@/services/notificationService";


export default {
    state: {
        boardNotebookPages: [],
        selectedBoardNotebookPage: {},
    },
    getters: {
        getBoardNotebookPages(state) {
            return state.boardNotebookPages
        },
        getSelectedBoardNotebookPage(state) {
            return state.selectedBoardNotebookPage
        },
    },
    mutations: {
        clearBoardNotebookPages(state) {
            state.boardNotebookPages = []
        },
        saveBoardNotebookPages(state, notebookPages) {
            state.boardNotebookPages = notebookPages
        },
        addNotebookPage(state, notebookPage) {
            state.boardNotebookPages.push(notebookPage)
            state.selectedBoardNotebookPage = notebookPage
        },
        setNotebookPage(state, notebookPage) {
            state.selectedBoardNotebookPage = notebookPage
        },
        deleteNotebookPage(state, notebookPageUniqueId) {
            let notebookPageIndex = state.boardNotebookPages.findIndex((noteBook) => noteBook.id === notebookPageUniqueId)

            if (state.boardNotebookPages.length > 1) {
                if (state.boardNotebookPages.length-1 === notebookPageIndex) {
                    state.selectedBoardNotebookPage = state.boardNotebookPages[notebookPageIndex-1]
                }
                else if (notebookPageIndex > 0 && notebookPageIndex < state.boardNotebookPages.length-1) {
                    state.selectedBoardNotebookPage = state.boardNotebookPages[notebookPageIndex+1]
                } else if(notebookPageIndex === 0) {
                    state.selectedBoardNotebookPage = state.boardNotebookPages[notebookPageIndex+1]
                } else state.selectedBoardNotebookPage= {}
            } else state.selectedBoardNotebookPage= {}
            state.boardNotebookPages.splice(notebookPageIndex, 1)
        },
        updateNotebookPage(state, notebookPage) {
            state.boardNotebookPages = state.boardNotebookPages.map((boardNotebookPage) => {
                return boardNotebookPage.id === notebookPage.id ? notebookPage : boardNotebookPage
            })
            state.selectedBoardNotebookPage = notebookPage
        },
    },
    actions: {
        async loadBoardNotebookPages(context, notebookUniqueId) {
            context.commit('clearBoardNotebookPages')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allNoteBookPages,
                variables: {
                    notebookUniqueId
                }
            }).then(async (response) => {
                let result = response.data.allNoteBookPages
                if (result.response.code === 9000) {
                    context.commit('saveBoardNotebookPages', result.data ? result.data : [])
                    context.commit('setNotebookPage', result.data.length !== 0 ? result.data[0] : {})
                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async deleteBoardNotebookPage(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: deleteNotebookPage,
                variables: {
                    input
                }
            }).then(async (response) => {
                let result = response.data.deleteNotebookPage
                if (result.response.code === 9000) {
                    context.commit('deleteNotebookPage', input.notebookPageUniqueId)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async createBoardNotebookPage(context, input) {
            await context.dispatch("showLoadingSpinner", true)
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createNotebookPage,
                variables: {
                    input
                }
            }).then(async (response) => {
                let result = response.data.createNotebookPage
                if (result.response.code === 9000) {
                    context.commit('addNotebookPage', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            }).finally( async () => {
                await context.dispatch("showLoadingSpinner", false);
            })
        },
        changeSelectedBoardNotebookPage(context, notebook) {
            context.commit('setNotebookPage', notebook)
        },
        async editBoardNotebookPage(context, input) {
            await context.dispatch("showLoadingSpinner", true);
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: updateNotebookPage,
                    variables: {
                        input
                    }
                }).then( async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.updateNotebookPage
                    if (result.response.code === 9000) {
                        context.commit('updateNotebookPage', result.data)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
        },
    },
}
