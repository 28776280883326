<template>
  <v-row justify="center">
    <v-dialog
        :key="viewCommitteeMemberKey"
        v-model="viewManagementCommitteeMembers"
        max-width="50%"
        persistent
    >
      <v-card class="p-4">
        <v-card-title>
          <span class="text-h6 flex items-center"
          ><icon name="management_committee" width="w-5" height="h-5" class="mr-2"></icon> Management Committee Members</span
          >
        </v-card-title>
        <div v-for="(member, index) of managementCommitteeMembers" :key="index" class="p-2">
          <div class="flex justify-content-between pb-2 items-center" style="padding-left: 20px; padding-right: 20px;"
               >
            <div >
              <span class="font-bold">{{ index + 1 }}. </span>
              <span class="ml-7">{{ member.firstName }} {{ member.lastName }}</span>
            </div>
            <div >

            </div>
            <div >
              <v-chip>
                {{ member.memberLevel }}
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="flex justify-end text-white padding">
          <v-btn color="red"
                 class="text-capitalize mr-2"
                 right
                 small
                 @click="$emit('closeViewManagementCommitteeMembers')"
          >
            <icon name="close" width="w-5" height="h-5" color="#ffffff"></icon>
            <span class="text-white">Cancel</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Icon from "../../../views/Icon.vue";

export default {
  name: "ViewManagementCommitteeMembers",
  components: {Icon},
  props: ["viewManagementCommitteeMembers", "managementCommitteeMembers"],
  data() {
    return {
      viewCommitteeMemberKey: 1
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>