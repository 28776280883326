<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab" color="rgba(63, 81, 181, 1)" right show-arrows>
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon :color="tab.color" class="me-3" size="20">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <GeneralSettings/>
            </v-tab-item>
            <v-tab-item>
              <MeetingSetting/>
            </v-tab-item>
            <v-tab-item>
              <Preferences/>
            </v-tab-item>

            <v-tab-item>
              <InstitutionSettings/>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Preferences from "./Preferences.vue";
import MeetingSetting from "./MeetingSetting.vue";
import GeneralSettings from "./GeneralSettings.vue";
import InstitutionSettings from "./InstitutionSettings.vue";

export default {
  components: {MeetingSetting, Preferences, InstitutionSettings, GeneralSettings},

  data() {
    return {
      tab: true,
      tabs: [
        {title: "General Setting", icon: "mdi-cog-box", color: "orange"},
        {title: "Meeting Setting", icon: "mdi-cogs", color: "orange"},
        {
          title: "Export Preferences",
          icon: "mdi-palette-outline",
          color: "success",
        },
        {title: "Institution Setting", icon: "mdi-factory", color: "success"},
      ],
    };
  },

  async mounted() {
    await this.$store.dispatch("SettingsStore/getBoardMeetingSettings", {root: true});
  },
};
</script>

<style>
</style>
