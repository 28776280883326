<template>
  <v-row justify="center">
    <v-dialog v-model="createFolderDialog" max-width="50%">
      <v-card>
        <v-card-title class="text-h7">
          <v-icon style="margin-right: 10px">mdi-folder-plus-outline</v-icon>
          Add Folder
        </v-card-title>

        <v-card-text>{{folderName}}
            Parent Folder: <strong>{{parentFolder}}</strong>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="folderName"
            dense
            label="Folder Name"
            outlined
            clearable
          ></v-text-field>
          <v-radio-group v-model="selectedOption">
            <v-row class="px-10">
              <v-radio label="For Board Member Only" value="boardOnly" @change="addCommittees=false; disabled=false"></v-radio>
              <v-spacer></v-spacer>
              <v-radio label="For Committees Only" value="boardCommetees" @change="addCommittees =! addCommittees; disabled=true;"></v-radio>
            </v-row>
          </v-radio-group>
          <v-select
              v-if="addCommittees === true"
              outlined
              v-model="committeeUniqueId"
              :items="boardCommitees"
              item-text="title"
              item-value="key"
              label="Committee Name"
          ></v-select>
          <v-layout justify-end>
            <v-btn
              color="green darken-1"
              text
              small
              @click="$emit('CloseCreateFolderDialog')"
            >
              Cancel
            </v-btn>

            <v-btn
              @click="createNewFolderRepository"
              color="green darken-1"
              text
              small
            >
              Add Folder
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["createFolderDialog", "parentFolder", "parentFolderKey", "isRoot"],
  data() {
    return {
      folderName: "New Folder",
      boardOnly:false,
      boardCommetees:false,
      committeeUniqueId: "",
      selectedOption: "",
      addCommittees: false,
      disabled: true
    };
  },
  methods: {
    async createNewFolderRepository() {
      this.$store.dispatch("showLoadingSpinner", true);

      if (this.parentFolderKey === "") {
        await this.$store.dispatch('createNewFolderRepository',{
          parentId: "",
          folderIsRoot: this.isRoot,
          folderName: this.folderName,
          boardOnly: this.selectedOption === "boardOnly",
          boardCommetees: this.selectedOption === "boardCommetees",
          committeeUniqueId: this.selectedOption === "boardCommetees" ? this.committeeUniqueId : "",

        })
      } else {
        await this.$store.dispatch('createSubFolderRepository',{
          parentId: this.parentFolderKey,
          folderIsRoot: this.isRoot,
          folderName: this.folderName,
          boardOnly: this.selectedOption === "boardOnly",
          boardCommetees: this.selectedOption === "boardCommetees",
          committeeUniqueId: this.selectedOption === "boardCommetees" ? this.committeeUniqueId : "",
        })
      }

      this.folderName='New Folder'
      this.$emit('CloseCreateFolderDialog')
      this.$emit('loadContents')
      this.$store.dispatch("showLoadingSpinner", false);
    },
  },

  computed: {
    boardCommitees() {
      return this.$store.getters.getBoardCommitees;
    },
  },

  mounted() {
    this.$store.dispatch('loadBoardCommittees')
  }
};
</script>
