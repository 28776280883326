<template>
  <v-container fluid>
    <v-row>
      <v-col md="12">
        <v-layout justify-space-between wrap>
          <v-card
            style="margin: 5px; padding: 5px"
            class="mx-auto intro-x"
            max-width="30%"
            outlined
          >
            <v-list-item three-line>
              <div class="flex flex-col lg:flex-row items-center p-2">
                <div class=" mr-3 w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                  <img
                    v-if="memberStructure.managementChair.memberPhoto"
                    :alt="memberStructure.managementChair.memberFirstname"
                    class="rounded-full"
                    :src="MEDIA_SERVER + memberStructure.managementChair.memberPhoto"
                  />

                  <vue-initials-img
                    v-bind="attrs"
                    v-on="on"
                    class="rounded-full"
                    v-else
                    :name="memberStructure.managementChair.memberFirstname+' '+memberStructure.managementChair.memberLastname"
                  />
                </div>
              <v-list-item-content>
                <div class="mb-4" style="color: green">
                  <v-chip small class="btn success py-1 px-2 mr-2">

                  {{ memberStructure.managementChair.memberPosition }}
                  </v-chip>
                </div>
                <v-list-item-title class="text-h7 mb-1" style="color: #3f51b5">
                  <strong
                    >{{ memberStructure.managementChair.memberFirstname }}
                    {{ memberStructure.managementChair.memberLastname }}</strong
                  >
                </v-list-item-title>
                <v-list-item-subtitle style="color: #3f51b5"
                  >{{ memberStructure.managementChair.memberEmail }}
                </v-list-item-subtitle>
              </v-list-item-content>


              </div>
            </v-list-item>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-layout justify-space-between wrap>
          <v-card
            v-for="director in memberStructure.managementDirectors"
            :key="director"
            class="-intro-y p-1"
            max-width="20%"
            outlined
          >
            <v-list-item three-line>
              <div class="flex flex-col lg:flex-row items-center p-2">
                <div class=" mr-3 w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                  <img
                    v-if="director.memberPhoto"
                    :alt="director.memberFirstname"
                    class="rounded-full"
                    :src="MEDIA_SERVER + director.memberPhoto"
                  />

                  <vue-initials-img
                    v-bind="attrs"
                    v-on="on"
                    class="rounded-full"
                    v-else
                    :name="director.memberFirstname+' '+director.memberLastname"
                  />
                </div>
                <v-list-item-content>
                  <div class="mb-4" style="color: green">
                  <span  class="btn h-7 rounded-full white--text bg-success py-1 px-2 mr-2">

                  {{ director.memberPosition }}
                  </span>
                  </div>
                  <v-list-item-title
                    class="text-h7 mb-1"
                    style="color: #3f51b5"
                  >
                    <strong
                      >{{ director.memberFirstname }}
                      {{ director.memberLastname }}</strong
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle style="color: #3f51b5">{{
                    director.memberEmail
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12">
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div
            class="intro-y col-span-12 md:col-span-6"
            v-for="member in memberStructure.managementManagers"
            :key="member.memberEmail"
          >
            <div class="box">
              <div class="flex flex-col lg:flex-row items-center p-5">
                <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                  <img
                    v-if="member.memberPhoto"
                    :alt="member.memberFirstname"
                    class="rounded-full"
                    :src="MEDIA_SERVER + member.memberPhoto"
                  />

                  <vue-initials-img
                    v-bind="attrs"
                    v-on="on"
                    class="rounded-full"
                    v-else
                    :name="member.memberFirstname"
                  />
                </div>
                <div
                  class="w-30 lg:ml-2 lg:mr-auto text-left lg:text-left mt-3 lg:mt-0"
                >
                  <span class="font-medium info--text"
                    >{{ member.memberFirstname }}
                    {{ member.memberLastname }}</span
                  >
                  <div class="text-slate-500 text-xs mt-0.5">
                    {{ member.memberEmail }}
                  </div>
                </div>
                <div class="w-30 flex-none flex items-center mr-5 mt-4 lg:mt-0">
                  <div
                    v-if="member.memberPosition"
                    class="btn btn-primary py-1 px-2 mr-2"
                  >
                    {{ member.memberPosition }}
                  </div>
                  <button
                    v-if="member.memberIsActive"
                    class="btn btn-outline-success py-1 px-2"
                  >
                    Active
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),

  computed: {
    memberStructure() {
      var chairman = this.$store.state.MyboardStore.managementStructure;
      return chairman;
    },
  },

  async mounted() {
    await this.$store.dispatch("loadManagementStructure");
  },
};
</script>

<style></style>
