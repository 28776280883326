import MeeitingList from './management-meetings/MeetingList.vue'
import MeetingDetails from './management-meetings/MeetingDetails.vue'
import MyActionsList from './management-actions/ManagementActionsList.vue'
import MyDashboard from './management_myboard/MyDashboard.vue'
import VottingList from './management-votting/VottingList.vue'
import DecisionRegister from './management-decisions/DecisionRegister.vue'
import GovernanceRepository from './management-repository/GovernanceRepository.vue'
import GovernanceRepositoryFolderContents from './management-repository/GovernanceRepositoryFolderContents.vue'
import ReportDrafts from "./management_reports_builder/ReportDrafts.vue";
import BuilderPanel from "./management_reports_builder/BuilderPanel.vue";
import WeeklyReporting from "./management_reports_builder/WeeklyReporting.vue";
import ManagementActionCategories
    from "@/modules/management_meeting_management/management-actions/ManagementActionCategories";
import MyBoardActionsList from "@/modules/management_meeting_management/management-actions/ManagementBoardActionsList";
import MyProfile from './management_myboard/MyProfile.vue'
import ManagementStructure from './management_myboard/ManagementStructure.vue'
import WeeklyReportsActionsList from './management-actions/ManagementWeeklyReportsActionsList.vue';
import ReportTypeDashboard
    from "@/modules/management_meeting_management/management_reports_builder/ReportTypeDashboard.vue";
import MonthlyReports from "@/modules/management_meeting_management/management_reports_builder/MonthlyReports.vue";
import QuarterlyReports from "@/modules/management_meeting_management/management_reports_builder/QuarterlyReports.vue";
import AnnuallyReports from "@/modules/management_meeting_management/management_reports_builder/AnnuallyReports.vue";
import ManagementReports from "@/modules/management_meeting_management/management_reports/ManagementReports.vue";
import ManagementMeetingReport from "@/modules/management_meeting_management/management_reports/ManagementMeetingReport.vue";
import ManagementMeetingDirectivesReport
    from "@/modules/management_meeting_management/management_reports/ManagementMeetingDirectivesReport.vue";
import ManagementMeetingDecisionsReport
    from "@/modules/management_meeting_management/management_reports/ManagementMeetingDecisionsReport.vue";
import ManagementCommitteeReport
    from "@/modules/management_meeting_management/management_reports/ManagementCommitteeReport.vue";

export default [
    {
        path: 'management_account/:management_uuid/:board_name',
        name: 'ManagementBoardAccount',
        component: MeeitingList,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_meeting_details/:meeting_uuid',
        name: 'ManagementMeetingDetails',
        component: MeetingDetails,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_directives',
        name: 'ManagementActions',
        component: MyActionsList,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_board_directives',
        name: 'MyBoardActionsList',
        component: MyBoardActionsList,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_reports_directives',
        name: 'MyBoardActionsList',
        component: WeeklyReportsActionsList,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_directives_categories',
        name: 'ManagementActionCategories',
        component: ManagementActionCategories,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_my_dashboard',
        name: 'ManagementDashboard',
        component: MyDashboard,
        meta: {requiresAuth: true},
    },

    {
        path: 'management_my_profile',
        name: 'Managementprofile',
        component: MyProfile,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_votting',
        name: 'ManagementVoting',
        component: VottingList,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_decisions',
        name: 'ManagementDecisions',
        component: DecisionRegister,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_meeting_repository',
        name: 'ManagementRepository',
        component: GovernanceRepository,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_governance_repository/:unique_id/:id/:folderName',
        name: 'ManagementRepository',
        component: GovernanceRepositoryFolderContents,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_reports_builder',
        name: 'WeeklyReporting',
        component: WeeklyReporting,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_reports_dashboard',
        name: 'ManagementReportDashboard',
        component: ReportTypeDashboard,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_monthly_reports',
        name: 'ManagementMonthlyReports',
        component: MonthlyReports,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_quarterly_reports',
        name: 'ManagementQuarterlyReports',
        component: QuarterlyReports,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_week_reports/:weekId',
        name: 'ManagementReportBuilder',
        component: ReportDrafts,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_week_reports/:weekId/:templateUniqueId',
        name: 'ManagementReportBuilderPanel',
        component: BuilderPanel,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_monthly_reports/:weekId/:templateUniqueId',
        name: 'ManagementReportBuilderPanel',
        component: BuilderPanel,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_quarterly_reports/:weekId/:templateUniqueId',
        name: 'ManagementReportBuilderPanel',
        component: BuilderPanel,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_annually_reports',
        name: 'AnnuallyReports',
        component: AnnuallyReports,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_annually_reports/:weekId/:templateUniqueId',
        name: 'ManagementReportBuilderPanel',
        component: BuilderPanel,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_structure',
        name: 'managemntStructure',
        component: ManagementStructure,
        meta: {requiresAuth: true},
    },
    {
        path: 'management_reports',
        name: "ManagementReports",
        component: ManagementReports,
        meta: { requiresAuth: true },
    },
    {
        path: 'management_reports/management_meetings',
        name: "ManagementMeetingReport",
        component: ManagementMeetingReport,
        meta: { requiresAuth: true },
    },
    {
        path: 'management_reports/management_meetings_directives',
        name: "ManagementMeetingDirectivesReport",
        component: ManagementMeetingDirectivesReport,
        meta: { requiresAuth: true },
    },
    {
        path: 'management_reports/management_meetings_decisions',
        name: "ManagementMeetingDecisionsReport",
        component: ManagementMeetingDecisionsReport,
        meta: { requiresAuth: true },
    },
    {
        path: 'management_reports/management_committee',
        name: "ManagementCommitteeReport",
        component: ManagementCommitteeReport,
        meta: { requiresAuth: true },
    },

]