<template>
  <v-container fluid>

    <v-row>
      <v-col sm="12">
        <v-card elevation="6">
          <v-tabs
              v-model="tab"
              show-arrows
          >
            <v-tab
                v-for="tab in tabs"
                :key="tab.icon"
            >
              <v-icon
                  size="20"
                  class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <memberStructure/>
            </v-tab-item>
            <v-tab-item>
              <ManagementCommitteeStructure />
            </v-tab-item>


          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>
import memberStructure from './memberStructure.vue'

import allBoardUsers from '../../../graphql/eboard-boards-graphql/allBoardUsers.gql'
import ManagementCommitteeStructure
  from "@/modules/management_meeting_management/management_myboard/ManagementCommitteeStructure.vue";

export default {
  components: {
    ManagementCommitteeStructure,
    memberStructure,
  },
  inject: {
    theme: {
      default: {isDark: false},
    },
  },

  data: () => ({
    roleKey: 0,
    userKey: 1,
    memberDialogKey: 2,
    message: '',
    disabled: true,
    progressShown: false,
    invitationBtn: true,
    inviteMemberList: [],
    searchResults: 'Eg. firstname.lastname@doamin.xx',
    loading: false,
    tab: '',
    inviteMember: false,
    memberDialog: false,
    OpenNewUserForm: false,
    OpenNewAccessForm: false,
    items: [
      {header: 'Board Members'},
      {divider: true, inset: true},
    ],
    nonitems: [

      {header: 'Non-Board Members'},
      {divider: true, inset: true},
    ],
    tabs: [
      {title: 'Management Members', icon: 'mdi-account-star-outline'},
      {title: 'Management Committees', icon: 'mdi-email-fast-outline'},

    ],
  }),
  methods: {
    clickMe() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.invitationBtn = false
        this.inviteMemberList.push({'name': "jaha.mvulla@ega.go.tztcra.go.tz", 'checked': true})
        this.inviteMemberList.push({'name': "jackson.bakari@eganet.go.tz", 'checked': false})
        this.searchResults = `Your search results`
      }, 2000)
    },
    OpenNewUserFormWindow() {
      setTimeout(() => {
        this.OpenNewUserForm = true;
        this.memberDialogKey = 90000;
        this.memberDialog = false;
        this.progressShown = false;
      }, 1000)
    }
  },

  created() {
    this.$apollo.query({
      query: allBoardUsers,
      variables: {
        boardUniqueId: localStorage.getItem('my-boardUniqueId'),
        pageNumber: 1,
      }
    }).then((response) => {
      response.data.boardUsers.forEach(boarduser => {
        if (boarduser.isBoardMember) {
          console.log(boarduser);
          this.items.push({
            avatar: boarduser.userProfilePhoto,
            fullname: boarduser.userProfileTitle + " " + boarduser.firstName + " " + boarduser.lastName + " (" + boarduser.userProfileSuffix + ")",
            position: boarduser.userProfilePosition,
            subtitle: boarduser.email,
            active: boarduser.isVerified,
            activeMessage: boarduser.userProfilePhoto,
            role: boarduser.userRole,
            verified: boarduser.isVerified
          })
          this.items.push({divider: true, inset: true})
        } else {
          console.log(boarduser);
          this.nonitems.push({
            avatar: boarduser.userProfilePhoto,
            fullname: boarduser.firstName + " " + boarduser.lastName,
            position: boarduser.userProfilePosition,
            subtitle: boarduser.email,
            active: boarduser.isVerified,
            activeMessage: boarduser.userProfilePhoto,
            role: boarduser.userRole,
            verified: boarduser.isVerified
          })
          this.nonitems.push({divider: true, inset: true})
        }
      });
    })
  }


}
</script>

<style>

</style>