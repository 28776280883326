import {apolloClient} from '@/vue-apollo'
import allManagementMeetingRepository
  from '../../graphql/management-repository-graphql/allManagementMeetingRepository.gql'
import uploadFile from "@/graphql/eboard-documents-graphql/uploadFile.gql";
import uploadManagementRepositoryDocument from "../../graphql/management-repository-graphql/uploadManagementRepositoryDocument.graphql";
import deleteManagementRepositoryDocument
  from "../../graphql/management-repository-graphql/deleteManagementRepositoryDocument.gql"
import {errorAlert, successAlert} from "@/services/notificationService";


export default {
  state: {
    managementRepository: [],
    managementUploadedRepositoryDocuments: []
  },

  mutations: {
    cleanManagementRepository(state) {
      state.managementRepository = []
    },
    addManagementRepository(state, repository) {
      state.managementRepository = repository
    },
    addManagementUploadedRepositoryDocuments(state, SupportingDocument) {
      state.managementUploadedRepositoryDocuments.push(SupportingDocument)
    },
    clearManagementUploadedRepositoryDocuments(state) {
      state.managementUploadedRepositoryDocuments = []
    },
  },

  actions: {
    async loadAllManagementMeetingRepository(context, input = null) {
      await context.dispatch("showLoadingSpinner", true);
      context.rootState.ResolutionStore.progressReportDocs = []
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allManagementMeetingRepository,
        variables: {
          managementUniqueId: localStorage.getItem("my-managementUniqueId"),
          ...input
        }
      }).then(async (result) => {
        const {response, page, data} = result.data.allManagementMeetingRepository;

        if (response.status) {
          context.commit('addManagementRepository', {data: data, page: page});
        } else {
          await errorAlert(response.message);
        }
        await context.dispatch("showLoadingSpinner", false);
      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async managementRepositorySubmitFile(context, sendData) {
      let SavedFile = null

      await apolloClient.mutate({
        mutation: uploadFile, loading: this.value,
        variables: {
          file: sendData.file,
        },
        update: (store, {data: {uploadFile}}) => {
          SavedFile = {'fileName': uploadFile.fileName, 'fullFileName': uploadFile.fullFileName}

          apolloClient.mutate({
            mutation: uploadManagementRepositoryDocument,
            variables: {
               meetingUniqueId: sendData.meetingUniqueId,
              documentPath: SavedFile.fullFileName,
              documentName: SavedFile.fileName,
            },
          }).then((response) => {
            if (response.data.uploadManagementRepositoryDocument.newDocument.primaryKey)
              successAlert("File Uploaded successfully");
            else
              errorAlert("Failed to upload document");
          }).catch(async (error) => {
            await context.dispatch("showLoadingSpinner", false);
            console.error(error.messages);
          });

          context.commit('addManagementUploadedRepositoryDocuments', SavedFile);
        },
      }).then((response) => {
        console.log(sendData);
        console.log(response);
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },

    async deleteManagementRepositoryDocument(context, documentUniqueId) {
      await context.dispatch("showLoadingSpinner", true);
      await apolloClient.mutate({
        mutation: deleteManagementRepositoryDocument,
        variables: {
          documentUniqueId: documentUniqueId,
        },
      }).then(async(response) => {
        console.log(response);
        await context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },
  }
}

