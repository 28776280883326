import {  apolloClient } from '@/vue-apollo'

import  getManagementCommitteesStructures from '../../graphql/management-committee-structure-graphql/getManagementCommitteesStructures.graphql'
import {errorAlert} from "@/services/notificationService";

export default {
    state: {
        managementCommitteeStructures:[],
    },

    mutations:{
        clearManagementCommitteeStructures(state){
            state.managementCommitteeStructures=[]
        },
        saveManagementCommitteeStructures(state, structures){
            state.managementCommitteeStructures=structures
        },
    },
    getters:{
        getManagementCommitteeStructures(state){
            return state.managementCommitteeStructures
        }
    },
    actions: {
        async loadManagementCommitteeMeetingStructures(context) {
            context.commit('clearManagementCommitteeStructures');
            await context.dispatch("showLoadingSpinner", true);

            let managementUniqueId = localStorage.getItem('my-managementUniqueId')

            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getManagementCommitteesStructures,
                variables: {
                    managementUniqueId
                }
            }).then(async (response) => {
                let result = response.data.getManagementCommitteesStructures
                if (result.response.code === 9000) {
                    context.commit('saveManagementCommitteeStructures', result.data);
                } else {
                    await errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })
        },
    }
}