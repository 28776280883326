<template>
  <div >
    <ManagementCommitteeToolBar />
    <div style="padding:20px">
      <router-view/>
    </div>
  </div>
</template>

<script>
import ManagementCommitteeToolBar
  from "@/modules/management_committee/management_committee_shared/navigations/ManagementCommitteeToolBar.vue";

export default {
  name: "ManagementCommitteeHome",
  components: {
    ManagementCommitteeToolBar
  }
}
</script>

<style scoped>

</style>