
import { apolloClient } from '@/vue-apollo'
import meetingDashboard from '../../graphql/eboard-dashboard-graphql/meetingDashboard.gql'
import directivesDashboard from '../../graphql/eboard-dashboard-graphql/directivesDashboard.gql'
import resolutionsDashboard from '../../graphql/eboard-dashboard-graphql/resolutionsDashboard.gql'
import attendanceDashboard from '../../graphql/eboard-dashboard-graphql/attendanceDashboard.gql'
import boardDashboard from '../../graphql/eboard-dashboard-graphql/boardDashboard.gql'


export default {
    state: {
        meetingDashboardData: null,
        directivesDashboardData: null,
        resolutionsDashboardData: null,
        attendanceDashboardData: null,
        boardDashboardData: null
    },

    mutations: {
        addMeetingDashboard(state, structureObj) {
            state.meetingDashboardData = structureObj
        },
        addDirectivesDashboard(state, structureObj) {
            state.directivesDashboardData = structureObj
        },
        addResolutionsDashboard(state, structureObj) {
            state.resolutionsDashboardData = structureObj
        },
        addAttendanceDashboard(state, structureObj) {
            state.attendanceDashboardData = structureObj
        },
        addBoardDashboard(state, structureObj) {
            state.boardDashboardData = structureObj
        },
    },
    getters: {
        getMeetingDashboard:state=>state.meetingDashboardData,
        getDirectivesDashboard:state=>state.directivesDashboardData,
        getResolutionsDashboard:state=>state.resolutionsDashboardData,
        getAttendanceDashboard:state=>state.attendanceDashboardData,
        getBoardDashboard:state=>state.boardDashboardData,
    },
    actions: {
        async loadAllDashboard(context) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingDashboard,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const structures = response.data.meetingDashboard
                context.commit('addMeetingDashboard', structures)
            }).then(() => {
                apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: directivesDashboard,
                    variables: {
                        boardUniqueId: localStorage.getItem("my-boardUniqueId")

                    }
                }).then((directiveresponse) => {
                    const structures = directiveresponse.data.directivesDashboard
                    context.commit('addDirectivesDashboard', structures)
                }).then(() => {
                    apolloClient.query({
                        fetchPolicy: 'no-cache',
                        query: resolutionsDashboard,
                        variables: {
                            boardUniqueId: localStorage.getItem("my-boardUniqueId")

                        }
                    }).then((resolutionresponse) => {
                        const structures = resolutionresponse.data.resolutionsDashboard
                        context.commit('addResolutionsDashboard', structures)
                    })
                }).then(() => {
                    apolloClient.query({
                        fetchPolicy: 'no-cache',
                        query: attendanceDashboard,
                        variables: {
                            boardUniqueId: localStorage.getItem("my-boardUniqueId")

                        }
                    }).then((attendanceresponse) => {
                        const structures = attendanceresponse.data.attendanceDashboard
                        context.commit('addAttendanceDashboard', structures)
                    })
                }).then(() => {
                    apolloClient.query({
                        fetchPolicy: 'no-cache',
                        query: boardDashboard,
                        variables: {
                            boardUniqueId: localStorage.getItem("my-boardUniqueId")

                        }
                    }).then((boardresponse) => {
                        const structures = boardresponse.data.boardDashboard
                        context.commit('addBoardDashboard', structures)
                    })
                })
            })
        }

    }

}
