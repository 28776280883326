<template>
  <v-container fluid>
    <v-card v-if="!hasHandoverDuties()">
      <v-tabs right>
        <v-tab style="text-transform: capitalize">
          <v-icon left>mdi-view-dashboard-outline</v-icon>
          Directives Dashboard
        </v-tab>
        <v-tab style="text-transform: capitalize">
          <v-icon left>mdi-react</v-icon>
          Reports & Meetings Dashboard
        </v-tab>
        <v-tab style="text-transform: capitalize">
          <v-icon left>mdi-checkbox-multiple-outline</v-icon>Attendance &
          Management Users
        </v-tab>

        <v-tab-item>

          <v-container fluid>
            <v-row>
              <v-col class="intro-x" cols="12" sm="12" xs="12" md="12">
                <v-radio-group class="ml-15" v-model="directiveFilter" @change="updateOtherQuerySearch" row>
                  <v-radio
                    label="Weekly Reports Directives"
                    value="Weekly"
                  ></v-radio>
                  <v-radio
                    label="Management Meetings Directives"
                    value="Management"
                  ></v-radio>
                  <v-radio
                    label="Board Meetings Directives"
                    value="Board"
                  ></v-radio>
                </v-radio-group>
                <v-autocomplete
                  v-if="directiveFilter == 'Weekly'"
                  class="ml-15"
                  style="width: 90%"
                  multiple
                  :items="WeeksForReportsList"
                  chips
                  small-chips
                  label="Select Week('s)"
                  item-text="weekName"
                  item-value="weekUniqueId"
                  v-model="weeksTobeFiltered"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      text
                      color="indigo"
                      outlined
                      fab
                      small
                      @click="updateQuerySearch"
                      ><v-icon>mdi-magnify</v-icon></v-btn
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col  cols="7" sm="12" xs="12" md="7">
                <DirectiveByDesignations
                class="-intro-x"
                  :directivesByDesignations="DirectivesDashboard"
                />
              </v-col>
              <v-col cols="5" sm="12" xs="12" md="5">
                <div class="font-weight-bold ml-0 mb-3">
                  <h3 style="color: rgba(132, 84, 29, 0.8)">
                    Directives Summary
                  </h3>
                </div>
                <v-row justify="start">
                  <v-card
                    :color="colors[index]"
                    class="ma-1 -intro-y"
                    flat
                    outlined
                    width="100%"
                    v-for="(dirStatus, index) in directives_status"
                    :key="dirStatus"
                  >
                    <v-list-item three-line>
                      <v-list-item-avatar
                        tile
                        size="60"
                        color="rgba(141, 153, 244, 0)"
                      >
                        <v-img src="@/assets/directions.png"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div class="text-overline mb-4">
                          {{ dirStatus.from }}
                        </div>
                        <v-list-item-title class="text-h5 mb-1">
                          {{ dirStatus.actions }}
                        </v-list-item-title>
                        <v-list-item-subtitle></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-row>
              </v-col>

              <v-col cols="5" sm="12" xs="12" md="12">
                <v-card flat width="100%">
                  <v-card-text>
                    <div class="font-weight-bold ml-0 mb-2">
                      <h3 style="color: rgba(132, 84, 29, 0.8)">
                        Up-comming directives deadlines
                      </h3>
                    </div>
                    <ActionsInDeadLine
                      :upcomingDeadlines="DirectivesDashboard.upcomingDeadlines"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-card class="ma-1" flat outlined width="48%">
                <v-card-text>
                  <h3>
                    <strong style="color: rgba(132, 84, 29, 0.8)"
                      >Management Meetings Summaries.</strong
                    >
                  </h3>
                </v-card-text>
                <v-col cols="12" md="12" sm="12">
                  <v-layout justify-start wrap>
                    <v-card
                      class="ma-1"
                      width="100%"
                      color="rgba(39, 178, 245, 0.16)"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">Scheduled</div>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ MeetingDashboard.scheduleMeetings }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >All of meetings schedude this
                            year</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                          <v-img src="@/assets/schedule.png"></v-img
                        ></v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                    <v-card
                      class="ma-1"
                      width="100%"
                      color="rgba(39, 245, 84, 0.10)"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">Conducted</div>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ MeetingDashboard.conductedMeetings }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Total of meetings conducted this
                            year</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar tile size="80">
                          <v-img src="@/assets/calendar.png"></v-img
                        ></v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                    <v-card
                      class="ma-1"
                      width="100%"
                      color="rgba(245, 174, 39, 0.16)"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">Awaiting</div>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ MeetingDashboard.awaitngMeetings }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Total of meetings awaiting this
                            year</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar tile size="70">
                          <v-img src="@/assets/time.png"></v-img
                        ></v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                  </v-layout>
                </v-col>
                <v-row>
                  <v-col cols="12" md="12">
                    <MeetingsByMonthes :dataObject="MeetingDashboard" />
                  </v-col>
                  <v-col cols="12" md="12">
                    <MeetingCustomGraph
                      :graphTitle="'Meetings per categories'"
                      :dataObject="MeetingDashboard.meetingsByCategories"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="ma-1" flat outlined width="48%">
                <v-card-text>
                  <strong style="color: rgba(132, 84, 29, 0.8)"
                    >Weekly Reports Summaries.</strong
                  >
                </v-card-text>
                <v-card-text>
                  <v-autocomplete
                    v-model="selectedWeeks"
                    class="ml-0"
                    outlined
                    style="width: 100%"
                    multiple
                    dense
                    :items="WeeksForReportsList"
                    chips
                    small-chips
                    label="Filter by week's"
                    item-text="weekName"
                    item-value="weekUniqueId"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        text
                        color="indigo"
                        outlined
                        fab
                        small
                        @click="updateQuerySearch"
                        ><v-icon>mdi-magnify</v-icon></v-btn
                      >
                    </template>
                  </v-autocomplete>
                </v-card-text>

                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong>Selected Weeks</strong></v-list-item-title
                        >
                        <v-list-item-subtitle
                          v-for="week in [
                            ' Week Start from 22-Aug-2022 to 28-Aug-2022',
                            'Week Start from 09-May-2022 to 15-May-2022',
                          ]"
                          :key="week"
                        >
                          <v-icon>mdi-calendar-week</v-icon>{{ week }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-layout justify-start wrap>
                      <v-card
                        class="ma-1"
                        width="100%"
                        color="rgba(135, 138, 229, 0.2)"
                        outlined
                      >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="text-overline mb-4">
                              Submited Reports
                            </div>
                            <v-list-item-title class="text-h5 mb-1">
                              {{ MeetingDashboard.scheduleMeetings }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-avatar tile size="60">
                            <v-img src="@/assets/submited.png"></v-img
                          ></v-list-item-avatar>
                        </v-list-item>
                      </v-card>
                      <v-card
                        class="ma-1"
                        width="100%"
                        color="rgba(168, 227, 186, 0.2)"
                        outlined
                      >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="text-overline mb-4">
                              Attended Reports
                            </div>
                            <v-list-item-title class="text-h5 mb-1">
                              {{ MeetingDashboard.scheduleMeetings }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-avatar tile size="60">
                            <v-img src="@/assets/approval.png"></v-img
                          ></v-list-item-avatar>
                        </v-list-item>
                      </v-card>
                      <v-card
                        class="ma-1"
                        width="100%"
                        color="rgba(227, 168, 168, 0.2)"
                        outlined
                      >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="text-overline mb-4">
                              Not Attended Reports
                            </div>
                            <v-list-item-title class="text-h5 mb-1">
                              {{ MeetingDashboard.scheduleMeetings }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-avatar tile size="60">
                            <v-img src="@/assets/rejected.png"></v-img
                          ></v-list-item-avatar>
                        </v-list-item>
                      </v-card>
                      <v-btn
                        block
                        color="deep-purple lighten-2"
                        class="text-capitalize"
                        width="80%"
                        small
                        text
                        @click="reserve"
                      >
                        View More . . .
                      </v-btn>
                    </v-layout>
                    <v-card-text>
                      <div class="font-weight-bold ml-0 mb-2">
                        <h3 style="color: rgba(132, 84, 29, 0.8)">
                          Three (3) late submissions (Report submited after last
                          Sunday midnight).
                        </h3>
                      </div>
                      <LateReportSubmissions
                        :upcomingDeadlines="[]"
                      />
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="6" sm="12" xs="12" md="6">
                <AttendanceChart :dataObject="AttendanceDashboard" />
              </v-col>

              <v-col cols="6" sm="12" xs="12" md="6">
                <v-card
                  class="mx-auto ma-2"
                  max-width="100%"
                  color="rgba(126, 154, 211, 0.24)"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        Total Management Members
                      </div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ BoardDashboard.numberOfManagementsMembers }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar tile size="60">
                      <v-img src="@/assets/people.png"></v-img
                    ></v-list-item-avatar>
                  </v-list-item>
                </v-card>
                  <div class="mt-5">
                    <strong style="color: rgba(132, 84, 29, 0.8);"
                    >Top 3 most absentees from management meetings</strong>
                  </div>
                  <div v-for="(item, index) in topAbsentees" :key="index"
                       class="border rounded-md p-3 my-3 intro-y d-flex flex-align-items-center">
                    <div class="avatar"></div>
                    <div class="ml-5">
                      <div class="font-bold text-primary">
                        {{ item.user.firstName }} {{ item.user.lastName }}
                      </div>
                      <div class="">
                        {{ item.number }} <span class="text-gray-500">Meetings</span>
                      </div>
                    </div>

                  </div>
              </v-col>

              <v-col cols="6" sm="12" xs="12" md="6">
                <v-card-text>
                  <strong style="color: rgba(132, 84, 29, 0.8)"
                    >Top 3 meeting with large number of absentees</strong
                  >
                </v-card-text>
                <v-card flat outlined width="100%" class="mx-auto">
                  <v-list three-line>
                    <template v-for="(item, index) in itemsmeeting">
                      <v-subheader
                        v-if="item.header"
                        :key="item.header"
                        v-text="'Management Meeting on 2nd May,2022'"
                      ></v-subheader>

                      <v-divider
                        v-else-if="item.divider"
                        :key="index"
                        :inset="item.inset"
                      ></v-divider>

                      <v-list-item v-else :key="item.title">
                        <v-list-item-avatar size="50">
                          <v-img src="@/assets/meeting1.png"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            v-html="item.title"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="item.subtitle"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <div
        class="pa-10 mt-10 border border-dotted border-slate-400 flex flex-col items-center justify-center"
        v-else
    >
      <h1 style="font-size: 30px" class="pa-10 mt-10 text-xl">
        You have hand over your duties on this management please return to office
        first.
      </h1>
      <v-btn @click="returnToOffice()" outlined color="primary"
      >Return to Office</v-btn
      >
    </div>
  </v-container>
</template>

<script>
require('vue2-animate/dist/vue2-animate.min.css')

import AttendanceChart from "./AttendanceChart";
import ActionsInDeadLine from "./ActionsInDeadLine";
import MeetingsByMonthes from "./MeetingsByMonthes";
import MeetingCustomGraph from "./MeetingCustomGraph";
import DirectiveByDesignations from "./DirectiveByDesignations";
import LateReportSubmissions from "./LateReportSubmissions";
import { mapGetters } from "vuex";

export default {
  components: {
    AttendanceChart,
    ActionsInDeadLine,
    MeetingsByMonthes,
    MeetingCustomGraph,
    DirectiveByDesignations,
    LateReportSubmissions,
  },

  data: () => ({
    directiveFilter: "Weekly",
    weeksTobeFiltered: [],
    startVideoCallDialog: false,
    model: 0,
    colors: [
      "rgba(159, 134, 20, 0.34)",
      "rgba(74, 205, 114, 0.2)",
      "rgba(141, 153, 244, 0.34)",
      "rgba(156, 28, 28, 0.17)",
      "orange",
    ],
    items: [
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Den William",
        subtitle: `No. of missing Meetings: 3`,
      },
      { divider: true, inset: true },
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: `No. of missing Meetings: 2`,
      },
    ],
    itemsmeeting: [
      {
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Management Meeting on 2nd May,2022",
        subtitle: `No. of absentees: 3 out of 6`,
      },
      // { divider: true, inset: true },
    ],
  }),

  computed: {
    ...mapGetters({
      MeetingDashboard: "getManagementMeetingDashboard",
      DirectivesDashboard: "getManagementDirectivesDashboard",
      AttendanceDashboard: "getManagementAttendanceDashboard",
      BoardDashboard: "getManagementBoardDashboard",
      WeeksForReportsList: "getManagementWeeklyReportsList",
      topAbsentees: "getBoardManagementTopAbsentees"
    }),
    directives_status() {
      return [
        {
          from: "Issued Directives",
          actions: this.DirectivesDashboard.issuedDirectives,
          color: "deep-purple lighten-0",
        },
        {
          from: "Completed Directives",
          actions: this.DirectivesDashboard.completedDirectives,
          color: "success",
        },
        {
          from: "In Progress Directives",
          actions: this.DirectivesDashboard.inProgressDirectives,
          color: "primary lighten-1",
        },
        {
          from: "Not-Started",
          actions: this.DirectivesDashboard.notStartedDirectives,
          color: "primary lighten-1",
        },
      ];
    },
  },

  methods: {
    callVideoControlDialog() {
      this.startVideoCallDialog = true;
    },
    async updateOtherQuerySearch() {
      if (this.filterType!="Weekly") {
        await this.$store.dispatch("showLoadingSpinner", true);
        await this.$store.dispatch("loadAllManagementDashboard", {
          filterType: this.directiveFilter,
        });
        await this.$store.dispatch("showLoadingSpinner", false);
      }
    },
    async updateQuerySearch() {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadAllManagementDashboard", {
        filterType: this.directiveFilter,
        weeksTobeFiltered: this.weeksTobeFiltered,
      });
      await this.$store.dispatch("showLoadingSpinner", false);
    },
    async returnToOffice() {
      await this.$store.dispatch("showLoadingSpinner", true);

      var handingOverUser=this.$store.getters["AuthStore/user"].username
      await this.$store.dispatch("RolesStore/returnToOffice",handingOverUser,{
        root: true,
      });
      await this.$store.dispatch("RolesStore/loadUserPermissionsByBoard", {
        root: true,
      });

      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },

  async mounted() {
    await this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("RolesStore/loadUserPermissionsByBoard", {
      root: true,
    });
    await this.$store.dispatch("loadAllManagementDashboard", {
      filterType: this.directiveFilter,
      weeksTobeFiltered: [],
    });
    await this.$store.dispatch("getManagementsWeeklyReports");
    await this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>

<style scoped>

.avatar {
  width: 50px;
  height: 50px;
  background-image: url("../../../assets/images/avatars/user_profile.png");
  background-size: cover;
  background-position: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
