import {  apolloClient } from '@/vue-apollo'

import getBoardCommitteesStructures from '../../graphql/eboard-committee-structure-graphql/getBoardCommitteesStructures.graphql'
import {errorAlert} from "@/services/notificationService";

export default {
    state: {
        boardCommitteeStructures:[],
    },

    mutations:{
        clearBoardCommitteeStructures(state){
            state.boardCommitteeStructures=[]
        },
        saveBoardCommitteeStructures(state, structures){
            state.boardCommitteeStructures=structures
        },
    },
    getters:{
        getBoardCommitteeStructures(state){
            return state.boardCommitteeStructures
        }
    },
    actions: {
        async loadBoardCommitteeStructures(context) {
            context.commit('clearBoardCommitteeStructures');
            await context.dispatch("showLoadingSpinner", true);

            let boardUniqueId = localStorage.getItem("my-boardUniqueId")

            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getBoardCommitteesStructures,
                variables: {
                    boardUniqueId
                }
            }).then(async (response) => {
                let result = response.data.getBoardCommitteesStructures
                if (result.response.code === 9000) {
                    context.commit('saveBoardCommitteeStructures', result.data);
                } else {
                    await errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })
        },
    }
}