<template>
    <v-container fluid>
        <v-row>
            <v-col sm='12' cols="12">
                <v-chip style="width:100%" label outlined >
                    <h4>Build your board report from the best available report templates</h4>
                </v-chip>
            </v-col>
      </v-row>
        <v-row>
            <report-card v-for="pack in meetinglist" :key="pack.TemplateName" @openBuilderDialogy="createNewReportFromTemaplate" :reportdata="pack"></report-card>
        </v-row>
    <v-dialog
        v-model="reportBuilderDialog"
        max-width="90%"
        persistent
        :key="panelKey"
      >
        <builder-panel @updatingSectionEmit="panelKey++" @closeReportBuilderPanel="reportBuilderDialog=false" :templateUniqueId="templateUniqueId"></builder-panel>
    </v-dialog>

    <v-overlay
      :z-index="zIndex"
      :value="overlay"
    >

        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        ></v-progress-circular>


    </v-overlay>

    </v-container>
</template>

<script>
import ReportCard from './ReportCard.vue'
import BuilderPanel from './BuilderPanel.vue'
import newReport from '../../../graphql/eboard-report-builder-graphql/newReport.gql'

export default {
    components:{ReportCard,BuilderPanel},
    data(){
        return{
            zIndex:0,
            panelKey:0,
            message: '',
            overlay:false,
            reportBuilderDialog:false,
            loading: false,
            templateUniqueId:"",
            meetinglist:[
                {
                    'TemplateName':'- DG Quarterly Performance Report',
                    'AboutTemplate':"This template used as base line for board perfomance reports. It containing some important sections that you can customize reegardsing your needs."
                },
                {
                    'TemplateName':'- Departmental Quarterly Performance Report',
                    'AboutTemplate':"This template used as base line for board perfomance reports. It containing some important sections that you can customize reegardsing your needs."
                },
            ],
        }
    },
    methods: {
      clickMe () {
        this.loading = true
        this.message = 'Wait for it...'
        setTimeout(() => {
          this.loading = false
          this.message = `You've clicked me!`
        }, 2000)
      },

      createNewReportFromTemaplate(){
          this.overlay=true
          this.$apollo.mutate({
              mutation:newReport,
              variables:{
                templateBoard:1,
                templateCreatedby:1
              }
          }).then((response)=>{
              console.log(response.data.newReport.reportTemplate.templateUniqueId);
             setTimeout(() => (
                 this.overlay=false,
                 this.templateUniqueId=response.data.newReport.reportTemplate.templateUniqueId,
                 this.reportBuilderDialog=true
            ), 2000)
          })
      }
    },

}
</script>

<style>

</style>


