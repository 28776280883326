import {apolloClient} from '@/vue-apollo'
import getManagementCommitteeRecommendations
    from '../../graphql/management-committee-agenda-recommendation-graphql/getManagementCommitteeRecommendations.gql'

import getAllManagementCommitteeRecommendations
    from '../../graphql/management-committee-agenda-recommendation-graphql/getAllManagementCommitteeRecommendations.gql'

import createManagementCommitteeRecommendationMutation
    from '../../graphql/management-committee-agenda-recommendation-graphql/createManagementCommitteeRecommendationMutation.gql'
import updateManagementCommitteeRecommendationMutation
    from '../../graphql/management-committee-agenda-recommendation-graphql/updateManagementCommitteeRecommendationMutation.gql'

import {errorAlert, successAlert} from "@/services/notificationService";


export default {
    state: {
        managementCommitteeAgendaRecommendation: null,
        noteRecommendation: false,
        committeeRecommendationList: []
    },
    getters: {
        getManagementCommitteeAgendaRecommendation(state) {
            return state.managementCommitteeAgendaRecommendation
        },
        getManagementCommitteeAgendaRecommendationStatus(state) {
            return state.noteRecommendation
        },
        getManagementCommitteeAgendaRecommendationList(state) {
            return state.committeeRecommendationList
        },
    },
    mutations: {
        clearManagementCommitteeAgendaRecommendation(state) {
            state.managementCommitteeAgendaRecommendation = {
                id: null,
                recommendationDescriptions: null,
                recommendationTitle: null
            }
            state.noteRecommendation = false
        },
        clearManagementCommitteeRecommendationList(state) {
            state.committeeRecommendationList = []
        },
        saveManagementCommitteeRecommendationList(state, recommendations) {
            state.committeeRecommendationList = recommendations
        },
        saveManagementCommitteeAgendaRecommendation(state, recommendation) {
            state.managementCommitteeAgendaRecommendation = recommendation
        },
        setManagementCommitteeAgendaRecommendationStatus(state, status) {
            state.noteRecommendation = status
        },
        updateManagementCommitteeAgendaRecommendation(state, editedRecommendation) {
            state.managementCommitteeAgendaRecommendation = editedRecommendation
            state.noteRecommendation = true
        },
    },
    actions: {
        async loadManagementCommitteeAgendaRecommendation(context, agendaUniqueId) {
            context.commit('clearManagementCommitteeAgendaRecommendation')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getManagementCommitteeRecommendations,
                variables: {
                    agendaUniqueId
                }
            }).then(async (response) => {
                let result = response.data.getManagementCommitteeRecommendations
                if (result.response.code === 9000) {
                    if (result.data) {
                        context.commit('saveManagementCommitteeAgendaRecommendation', result.data)
                        context.commit('setManagementCommitteeAgendaRecommendationStatus', true)
                    } else context.commit('saveManagementCommitteeAgendaRecommendation', {
                        id: null,
                        recommendationTitle: null,
                        recommendationDescriptions: null
                    })
                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async loadAllManagementCommitteeAgendaRecommendation(context, filterObj) {
            context.commit('clearManagementCommitteeRecommendationList');
            await context.dispatch("showLoadingSpinner", true);

            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getAllManagementCommitteeRecommendations,
                variables: {
                    committeeUniqueId: filterObj.committeeUniqueId,
                    pageNumber: filterObj.pageNumber,
                    meetingUniqueId: filterObj.meetingUniqueId
                }
            }).then(async (response) => {
                let result = response.data.getAllManagementCommitteeRecommendations;

                if (result.response.code === 9000) {
                    context.commit('saveManagementCommitteeRecommendationList', {data: result.data, page: result.page})
                } else {
                    await errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        //     async deleteManagementCommitteeAgendaNote(context, input) {
        //         await context.dispatch("showLoadingSpinner", true);
        //         await apolloClient.mutate({
        //             fetchPolicy: 'no-cache',
        //             mutation: deleteManagementCommitteeNotesMutation,
        //             variables: {
        //                 input
        //             }
        //         }).then(async (response) => {
        //             let result = response.data.deleteManagementCommitteeNotesMutation
        //             if (result.response.code === 9000) {
        //                 context.commit('clearManagementCommitteeAgendaRecommendation')
        //                 await successAlert(result.response.message)
        //             } else {
        //                 errorAlert(result.response.message)
        //             }
        //         }).finally(async () => {
        //             await context.dispatch("showLoadingSpinner", false);
        //         })
        //
        //     },
        async createManagementCommitteeAgendaRecommendation(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createManagementCommitteeRecommendationMutation,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createManagementCommitteeRecommendationMutation
                if (result.response.code === 9000) {
                    context.commit('updateManagementCommitteeAgendaRecommendation', result.data)
                    await successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async editManagementCommitteeAgendaRecommendation(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateManagementCommitteeRecommendationMutation,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateManagementCommitteeRecommendationMutation
                if (result.response.code === 9000) {
                    context.commit('updateManagementCommitteeAgendaRecommendation', result.data)
                    await successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },
    },
}
