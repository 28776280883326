<template>

<v-container fluid>
    <v-row>
        <v-col
            class="d-flex"
            cols="12"
            sm="12"
        >
            <p>All drafts reports</p>
        </v-col>
        <v-col
            class="d-flex"
            cols="12"
            sm="3"
        >
      </v-col>
        <v-spacer></v-spacer>
        <v-col
            class="d-flex"
            cols="12"
            sm="5"        
        >
        <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="From"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="date"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                >
                    OK
                </v-btn>
                </v-date-picker>
        </v-menu>
        <v-menu
                ref="menuTo"
                v-model="menuTo"
                :close-on-content-click="false"
                :return-value.sync="dateTo"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateTo"
                    label="To"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="dateTo"
                no-title
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menuTo = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menuTo.save(dateTo)"
                >
                    OK
                </v-btn>
                </v-date-picker>
        </v-menu>            
        </v-col>
    </v-row>
    <v-row>  
        <v-col sm="12">
            <div style="padding:10px"  v-for="draft in dreftReports" :key="draft.primaryKey">
                <v-card class="mx-auto" max-width="100%" outlined >
                <v-card-title>
                   {{draft.templateReportName}}
                    <v-spacer></v-spacer>
                    <v-menu z-index="1" offset-y app>
                        <template v-slot:activator="{ on, attrs }">
                                <v-icon  @click="opened=!opened"  v-bind="attrs" v-on="on" slot="activator"  >mdi-dots-vertical</v-icon>
                        </template>
                    <v-list dense>
                        <v-list-item link to="">
                            <v-list-item-title @click="OpenReportBuliderPanel(draft.templateUniqueId)"><v-icon color="success" style="height: 16px;font-size: 16px;">mdi-file-restore-outline</v-icon> Resume Reports</v-list-item-title></v-list-item>
                        <v-list-item link to="">
                            <v-list-item-title @click="OpenNoticeDialog=true"><v-icon color="error" style="height: 16px;font-size: 16px;">mdi-trash-can-outline</v-icon> Delete this draft</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    </v-menu> 
                </v-card-title>

                <v-card-text>
                <v-row
                    align="center"
                    class="mx-0"
                >
                    <v-rating
                    :value="0"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                    ></v-rating>
                    <v-chip
                    close
                    close-icon="mdi-trash-can-outline"
                    small
                    outlined
                    
                    >In draft</v-chip>
                </v-row>
                <div class="my-4 text-subtitle-1">
                    <strong>Number:</strong> {{draft.templateNumber}}
                </div>
                <p><strong>Created by:</strong> {{draft.templateCreatedby}}</p>
                <p><strong>Created Date:</strong> {{draft.templateCreateddate |formatDate}}</p>
                </v-card-text>                  
                </v-card>
            </div>
        </v-col>
    </v-row>
    <v-dialog
        v-model="reportBuilderDialog"
        max-width="90%"
        persistent
        :key="panelKey"
      >
        <builder-panel @closeReportBuilderPanel="reportBuilderDialog=false;panelKey++" :templateUniqueId="templateUniqueId"></builder-panel>
    </v-dialog>

    <v-overlay
      :z-index="zIndex"
      :value="overlay"
    >

        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        ></v-progress-circular>


    </v-overlay>
</v-container>
  
</template>

<script>
import allDraftReports from '../../../graphql/eboard-report-builder-graphql/allDraftReports.gql'
import BuilderPanel from './BuilderPanel.vue'

export default {
    components:{BuilderPanel},

    data(){
        return{
        zIndex:0,
        panelKey:0,
        overlay:false,
        templateUniqueId:null,
        reportBuilderDialog:false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        modalTo: false,
        menuTo: false,
        tab:true,
        dreftReports:[]
        }
    },
    created(){
        this.$apollo.query({
            query:allDraftReports,
            variables:{
                boardUniqueId:localStorage.getItem('my-boardUniqueId'),
                templateIsDraft:true
            }
        }).then((response)=>{
            const drafts=response.data.filterReportTemplate.edges
            drafts.forEach(draft => {
                this.dreftReports.push({
                    "primaryKey":draft.node.primaryKey,
                    "templateUniqueId":draft.node.templateUniqueId,
                    "templateReportName":draft.node.templateReportName,
                    "templateTitle":draft.node.templateTitle,
                    "templateNumber":draft.node.templateNumber,
                    "templateSubtitle":draft.node.templateSubtitle,
                    "templateCreatedby":draft.node.templateCreatedby.firstName +" "+draft.node.templateCreatedby.lastName,
                    "templateCreateddate":draft.node.templateCreateddate,
                })
            }
            );

            if(this.$route.params.reload){
                this.$router.go(0)
                this.$route.params.reload=false
            }
        })
    },


    methods: {
        OpenReportBuliderPanel(templateUniqueId){
            this.overlay=false
            this.templateUniqueId=templateUniqueId
            this.reportBuilderDialog=true
        }
    }

}
</script>

<style>

</style>