<template>
  <v-container fluid>
    <quill-editor
        :content="content"
        :options="editorOption" style="height:70%"
        @change="onEditorChange($event)"
    />
  </v-container>

</template>

<script>
import {quillEditor} from 'vue-quill-editor'

export default {
  name: "NoteBookPageContentEditor",
  props: ['content', 'editorOption'],
  components: {
    quillEditor
  },
  data: () => ({}),
  methods: {
    onEditorChange(event) {
      this.$emit('onEditorChange', event.html)
    }
  }
}
</script>