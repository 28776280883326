<template>
  <v-container fluid class="my-5" app>
    <v-dialog persistent v-model="openPublishedDialog" max-width="80%">
      <v-card>
        <v-card-title class="text-h6 indigo lighten-5">
          <v-icon size="30" class="mx-2">mdi-book-open-outline</v-icon>
          Published News
          <v-spacer></v-spacer>
        </v-card-title>

        <v-row class="flex">
          <div class="m-2 w-full">
            <table class="table p-3 w-100 table-hover bg-white">
              <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">News Title</th>
                <th scope="col">News Description</th>
                <th scope="col">Expire Date</th>
                <th scope="col">Expire Time</th>
                <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="news in boardPublishedUpdates" :key="news.newsUniqueId">
                <th scope="row">
                  <v-avatar tile size="40">
                    <img
                        :aspect-ratio="3 / 3"
                        :key="news.value"
                        src="@/assets/published.png"
                        alt="draft"
                    />
                  </v-avatar>
                </th>
                <td>{{ news.newsTitle }}</td>
                <td class="text-truncate" style="max-width: 20px;">{{ news.newsDescriptions }}</td>
                <td>{{ news.newsExpireDate }}</td>
                <td>{{ news.newsExpireTime }}</td>
                <td>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                          v-on="on"
                          v-bind="attrs"
                          @click="unPublishNews(news)"
                          class="btn btn-primary btn-sm mr-2"
                          type="button"
                      >
                        <icon name="news" width="w-5" height="w-5" color="#ffffff"></icon>
                      </div>
                    </template>
                    <span>UnPublish News</span>
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            medium
            color="pink"
            @click="
              openPublishedDialog = !openPublishedDialog;
              $emit('closePublishedDialog');
            "
          >
            <v-icon class="white--text">mdi-close</v-icon>
            <strong style="color: white"> CLOSE </strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import Icon from "@/views/Icon.vue";

export default {
  props: ["openPublishedDialog"],
  components: {
    Icon
  },
  data() {
    return {
      supportingDocs: [],
      newsIsPublished: true,
      newsUniqueId: "",
      filePreviewDialog: false,
      OpenfileDialog: false,
      suppotingDocs: [],
      docValue: null,
      path: "",
      title: "",
    };
  },
  methods: {
    async unPublishNews(news) {
      let documents_list = [];
      this.supportingDocs.forEach((document) => {
        documents_list.push({
          documentName: document.fileName,
          filePath: document.fullFileName,
        });
      });

      let input =  {
        newsIsDraft: false,
        newsIsPublished: false,
        newsUniqueId: news.newsUniqueId
      }

      await this.$store.dispatch("unPublishBoardNewsAndUpdates", input)
      await this.$store.dispatch("loadBoardPublishedNewsUpdates");
      await this.$store.dispatch("loadBoardUnpublishedNewsUpdates");
      this.$emit('closePublishedDialog')

    },
  },
  computed: {
    boardPublishedUpdates() {
      return this.$store.state.NewsUpdatesStore.boardPulishedNewsUpdatesList;
    },
  },
  async mounted() {
    await this.$store.dispatch("loadBoardPublishedNewsUpdates");
  },
};
</script>

<style>
</style>