<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p>Documents generated during Management Committee Meetings</p>
      </v-col>
      <v-col class="d-flex" cols="12" sm="4">
        <v-text-field label="Search" append-icon="mdi-magnify" v-model="filterObj.search"
                      @input="loadManagementCommitteeMeetingRepository"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">

        <div class="intro-y" style="padding: 10px" v-for="(repository, index) in managementRepository.data" :key="index">
          <v-card class="mx-auto intro-y" max-width="100%" outlined>
            <v-row>
              <v-col md="12" sm="12">
                <v-card-title>
                  <v-icon size="35" color="rgba(227, 150, 0, 0.56)" class="mr-1">mdi-folder</v-icon>
                  {{ repository.meetingTitle }}
                  <v-spacer></v-spacer>

                  <v-tooltip bottom color="primary"
                             v-if="repository.meetingAgendaDocumentPath || repository.meetingAgendaDocumentPath !=''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn style="padding: 25px" icon
                             @click="openDocumentViewer(repository.meetingAgendaDocumentPath)">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-note-multiple-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Agenda</span>
                  </v-tooltip>

                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn style="padding: 25px" icon @click="previewMinutes(repository.id)">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-file-document-multiple
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Minutes</span>
                  </v-tooltip>
                </v-card-title>

                <v-card-text>
                  <v-row align="center" class="mx-">
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <span><v-icon>mdi-paperclip</v-icon> {{ repository.meetingDocumentsCount }} Other Documents</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          style="rgba(38, 27, 216, 0.11)"
                          v-if="repository.meetingAgendaSupportingDocuments.length > 0"
                        >
                          <v-list subheader>
                            <v-list-item v-for="(doc, index) in repository.meetingAgendaSupportingDocuments"
                                         :key="index">
                              <v-list-item-avatar>
                                <v-img
                                  style="width: 90%"
                                  src="/pdf.png"
                                ></v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>
                                  <span link
                                  >{{ doc.documentName }}</span
                                  ></v-list-item-title
                                >
                              </v-list-item-content>

                              <v-list-item-icon>
                                <v-btn icon @click="openDocumentViewer(doc.documentPath)" class="ma-1">
                                  <v-icon color="indigo">mdi-file-eye</v-icon>
                                </v-btn>
                                <!--                                <v-btn icon @click="deleteRepositoryDocument(doc.documentUniqueId)" class="ma-1">-->
                                <!--                                  <v-icon color="error">mdi-delete</v-icon>-->
                                <!--                                </v-btn>-->
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>

          <v-card-actions>
            <v-btn
              @click="paginateManagementCommitteeAgendaRecommendation(managementRepository.page.previousPageNumber)"
              v-if="managementRepository.page.hasPreviousPage" outlined small
            >
              <v-icon left>mdi-arrow-left</v-icon>
              Previous Page ({{ managementRepository.page.previousPageNumber }})
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="paginateManagementCommitteeAgendaRecommendation(managementRepository.page.nextPageNumber)"
              v-if="managementRepository.page.hasNextPage" outlined small
            >
              Next Page ({{ managementRepository.page.nextPageNumber }})
              <v-icon right>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
    </v-row>

    <DocumentViewer v-if="OpenfileDialog===true" :OpenfileDialog="OpenfileDialog" :docValue="docValue" :title="title"
                    @CloseDocumentViwer="OpenfileDialog=false"/>


  </v-container>
</template>

<script>
import previewManagementMeetingDirectives
  from '../../../graphql/management-actions-graphql/previewManagementMeetingDirectives.graphql'
import DocumentViewer from '@/modules/management_meeting_management/management-documents/DocumentViwer.vue'
import {apolloClient} from "@/vue-apollo";
import previewManagementCommitteeMinutesMutation
  from "@/graphql/management-committee-meeting-graphql/previewManagementCommitteeMinutesMutation.gql";
import {errorAlert} from "@/services/notificationService";


export default {
  name: "ManagementCommitteeRepository",
  components: {DocumentViewer},
  data() {
    return {
      documentName: "",
      OpenfileDialog: false,
      docValue: "",
      title: "",
      supportDoc: false,
      meetingUniqueId: "",
      documentUniqueId: "",
      isDeleteDocument: false,
      filterObj: {
        committeeUniqueId: localStorage.getItem('my-managementCommitteeUniqueId'),
        pageNumber: 1,
        search: null,
      }
    };
  },

  methods: {
    async previewMinutes(meetingUniqueId) {
      await this.$store.dispatch('showLoadingSpinner', true)
      await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: previewManagementCommitteeMinutesMutation,
        variables: {
          input: {
            meetingUniqueId
          }
        }
      }).then(async (response) => {
        let result = response.data.previewManagementCommitteeMinutesMutation
        if (result.response.code === 9000) {
          this.docValue = result.data
          this.OpenfileDialog = true
        } else {
          await errorAlert(result.response.message)
        }
      }).finally(async () => {
        await this.$store.dispatch('showLoadingSpinner', false)
      })
    },

    previewDirectives(meetingUniqueId) {
      this.previewLoading = true
      this.$apollo.mutate({
        mutation: previewManagementMeetingDirectives,
        variables: {
          meetingUniqueId: meetingUniqueId,
        }
      }).then((response) => {
        this.previewLoading = false
        this.docValue = "/" + response.data.previewManagementMeetingDirectives.directiveDocumentPath
        this.$emit('closeGreatModel');
        this.OpenfileDialog = true
      })
    },

    openDocumentViewer(meetingAgendaDocumentPath) {
      this.previewLoading = true
      this.docValue = meetingAgendaDocumentPath;
      this.OpenfileDialog = true
    },

    async deleteRepositoryDocument(documentUniqueId) {
      await this.$store.dispatch('deleteManagementRepositoryDocument', documentUniqueId);
      await this.$store.dispatch("loadAllManagementMeetingRepository");
    },

    async loadManagementCommitteeMeetingRepository() {
      await this.$store.dispatch("loadManagementCommitteeMeetingRepository", this.filterObj);
    }
  },

  computed: {
    managementRepository() {
      return this.$store.getters.getManagementCommitteeRepository
    },
  },

  async mounted() {
    await this.loadManagementCommitteeMeetingRepository();
  },
};
</script>

<style>
</style>
