import {apolloClient} from '@/vue-apollo'
import allRepositoryFolders from '../../graphql/eboard-repository-graphql/allRepositoryFolders.gql'
import createNewFolder from '../../graphql/eboard-repository-graphql/createNewFolder.gql'
import uploadFileToRepository from '../../graphql/eboard-repository-graphql/uploadFileToRepository.gql'
import allFolderContentsByUniqueId from '../../graphql/eboard-repository-graphql/allFolderContentsByUniqueId.gql'
import allSubFolderContentsByUniqueId
    from '../../graphql/eboard-repository-graphql/allSubFolderContentsByUniqueId.graphql'
import createDocumentAnnotation from '../../graphql/eboard-repository-graphql/createDocumentAnnotation.gql'
import deleteFoldersAndContents from '../../graphql/eboard-repository-graphql/deleteFoldersAndContents.gql'
import annotatedRepositoryDocuments from '../../graphql/eboard-repository-graphql/annotatedRepositoryDocuments.gql'
import allMeetingRepository from "../../graphql/eboard-repository-graphql/allMeetingRepository.graphql";
import uploadFile from '../../graphql/eboard-documents-graphql/uploadFile.gql'
import swal from 'sweetalert2'
import createSubFolder from '../../graphql/eboard-repository-graphql/createSubFolder.graphql'
import grantFolderAccess from '../../graphql/eboard-repository-graphql/grantFolderAccess.gql'
import peopleWithFolderAccess from '../../graphql/eboard-repository-graphql/peopleWithFolderAccess.gql'
import removeFolderAccess from '../../graphql/eboard-repository-graphql/removeFolderAccess.gql'
import updateFolderAccess from '../../graphql/eboard-repository-graphql/updateFolderAccess.gql'
import allRepositoryDocuments from '../../graphql/eboard-repository-graphql/allRepositoryDocuments.graphql'
import searchInsideFolderContents from '../../graphql/eboard-repository-graphql/searchInsideFolderContents.gql'
import uploadBoardRepositoryDocument
    from "@/graphql/eboard-repository-graphql/uploadBoardRepositoryDocument.gql";
import {errorAlert, successAlert} from "@/services/notificationService";
import deleteBoardRepositoryDocument
    from "@/graphql/eboard-repository-graphql/deleteBoardRepositoryDocument.gql";


export default {
    state: {
        governanceRepositoryFolders: [],
        governanceRepositoryFiles: [],
        meetingRepository: [],
        meetingUploadedRepositoryDocuments: [],
        annotedRepositoryDocuments: [],
        governanceRepositorySubFolders: [],
        RepositoryDocuments: [],
        folderWriteAccess: false,
        peopleWithFolderAccess: [],
        searchedDocumentsContents: [],
    },

    mutations: {
        cleanRepositoryDocuments(state) {
            state.RepositoryDocuments = []
        },
        cleanBoardMeetingRepository(state) {
            state.meetingRepository = []
        },
        cleanAddAnnotedRepositoryDocuments(state) {
            state.annotedRepositoryDocuments = []
        },
        addMeetingUploadedRepositoryDocuments(state, repositoryObject) {
            state.meetingRepository.push(repositoryObject)
        },
        cleanGovernanceRepositoryFolders(state) {
            state.governanceRepositoryFolders = []
            state.governanceRepositoryFiles = []
            state.governanceRepositorySubFolders = []
        },
        cleanGovernanceRepositorySubFolders(state) {
            state.governanceRepositorySubFolders = []
        },
        addGovernanceRepositoryFolders(state, folderobject) {
            state.governanceRepositoryFolders.push(folderobject)
            state.governanceRepositoryFiles = folderobject.listOfFiles
            state.governanceRepositorySubFolders = folderobject.listOfSubfloders
            state.folderWriteAccess = folderobject.writeAccess
        },
        appendGovernanceRepositorySubFolders(state, folderobject) {
            state.governanceRepositorySubFolders.push(folderobject)
        },
        appendGovernanceRepositoryFolders(state, folderobject) {
            state.governanceRepositoryFolders.push(folderobject)
        },
        addAnnotedRepositoryDocuments(state, documents) {
            state.annotedRepositoryDocuments = documents
        },
        addRepositoryDocuments(state, documents) {
            state.RepositoryDocuments = documents
        },
        cleanPeopleWithFolderAccess(state) {
            state.peopleWithFolderAccess = []
        },
        addPeopleWithFolderAccess(state, grantees) {
            state.peopleWithFolderAccess = grantees
        },
        addBoardUploadedRepositoryDocuments(state, SupportingDocument) {
            state.meetingUploadedRepositoryDocuments.push(SupportingDocument)
        },
        saveSearchedContents(state, searchedContents) {
            state.searchedDocumentsContents = searchedContents
        },
    },
    getters: {
        folderAccessGrentees(state) {
            return state.peopleWithFolderAccess
        }
    },

    actions: {
        async loadAllMeetingsRepository(context) {
            await context.dispatch("showLoadingSpinner", true);
            context.commit('cleanBoardMeetingRepository')
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMeetingRepository,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }
            }).then((response) => {
                const repository = response.data.allMeetingRepository
                repository.forEach(item => {
                    context.commit('addMeetingUploadedRepositoryDocuments', item)
                });
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async allRepositoryDocuments(context) {
            context.commit('cleanRepositoryDocuments')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allRepositoryDocuments,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }
            }).then((response) => {
                const RepositoryDocuments = response.data.allRepositoryDocuments
                context.commit('addRepositoryDocuments', RepositoryDocuments)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadRootDocumentsFromRepository(context) {
            context.commit('cleanGovernanceRepositoryFolders')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allRepositoryFolders,
                variables: {
                    parentFolder: '',
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }
            }).then((response) => {
                const folders = response.data.allRepositoryFolders
                folders.forEach(folder => {
                    context.commit('addGovernanceRepositoryFolders', folder)
                });
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })

        },


        async boardRepositorySubmitFile(context, sendData) {
            let SavedFile = null

            await apolloClient.mutate({
                mutation: uploadFile, loading: this.value,
                variables: {
                    file: sendData.file,
                },
                update: (store, {data: {uploadFile}}) => {
                    SavedFile = {'fileName': uploadFile.fileName, 'fullFileName': uploadFile.fullFileName}

                    apolloClient.mutate({
                        mutation: uploadBoardRepositoryDocument,
                        variables: {
                            meetingUniqueId: sendData.meetingUniqueId,
                            documentPath: SavedFile.fullFileName,
                            documentName: SavedFile.fileName,
                        },
                    }).then((response) => {
                        if (response.data.uploadBoardRepositoryDocument.newDocument.primaryKey)
                            successAlert("File Uploaded successfully");
                        else
                            errorAlert("Failed to upload document");
                    }).catch(async (error) => {
                        await context.dispatch("showLoadingSpinner", false);
                        console.error(error.messages);
                    });

                    context.commit('addBoardUploadedRepositoryDocuments', SavedFile);
                },
            }).then((response) => {
                console.log(sendData);
                console.log(response);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },



        async deleteBoardRepositoryDocument(context, documentUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deleteBoardRepositoryDocument,
                variables: {
                    documentUniqueId: documentUniqueId,
                },
            }).then(async() => {
                await context.dispatch("showLoadingSpinner", false);
            }).catch(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })
        },

        async createNewFolderRepository(context, folder) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: createNewFolder,
                variables: {
                    folderBoardId: localStorage.getItem("my-boardUniqueId"),
                    ...folder
                }
            }).then((response) => {
                console.log(response.data.createNewFolder);
                if (response.data.createNewFolder.newFolder.folderIsRoot) {
                    var newFolder = {
                        "primaryKey": response.data.createNewFolder.newFolder.primaryKey,
                        "folderUniqueId": response.data.createNewFolder.newFolder.folderUniqueId,
                        "folderName": response.data.createNewFolder.newFolder.folderName,
                        "folderDate": response.data.createNewFolder.newFolder.folderDate,
                        "folderIsRoot": response.data.createNewFolder.newFolder.folderIsRoot,
                        "listOfSubfloders": [],
                        "listOfFiles": []
                    }
                    context.commit('appendGovernanceRepositoryFolders', newFolder)
                    context.dispatch("showLoadingSpinner", false);
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async createSubFolderRepository(context, folder) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: createSubFolder,
                variables: {
                    folderBoardId: localStorage.getItem("my-boardUniqueId"),
                    parentId: folder.parentId,
                    folderIsRoot: folder.folderIsRoot,
                    folderName: folder.folderName,
                }
            }).then((response) => {
                console.log(response.data.createSubFolder);
                if (response.data.createSubFolder.success) {
                    context.dispatch('loadRootDocumentsFromRepository')
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllFolderContentsByUniqueId(context, folder) {
            context.commit('cleanGovernanceRepositoryFolders')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allFolderContentsByUniqueId,
                variables: {
                    folderUniqueId: folder,
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }
            }).then((response) => {
                const folders = response.data.allFolderContentsByUniqueId

                folders.forEach(folder => {
                    context.commit('addGovernanceRepositoryFolders', folder)
                });
                context.dispatch("showLoadingSpinner", false);

            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllSubFolderContentsByUniqueId(context, folder) {
            context.commit('cleanGovernanceRepositorySubFolders')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                query: allSubFolderContentsByUniqueId,
                fetchPolicy: 'no-cache',
                variables: {
                    folderUniqueId: folder,
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }
            }).then((response) => {
                const folders = response.data.allSubFolderContentsByUniqueId

                folders.forEach(folder => {
                    context.commit('appendGovernanceRepositorySubFolders', folder)
                });
                context.dispatch("showLoadingSpinner", false);

            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async createDocumentAnnotation(context, folder) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: createDocumentAnnotation,
                variables: {
                    documentUniqueId: folder,
                }
            }).then(() => {
                context.dispatch("showLoadingSpinner", false);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Annotation created  successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async governanceRepositorySubmitFile(context, newFile) {
            await context.dispatch("showLoadingSpinner", true);
            var SavedFile = null
            await apolloClient.mutate({
                mutation: uploadFile, loading: this.value,
                variables: {
                    file: newFile.file,
                },
                update: (store, {data: {uploadFile}}) => {
                    SavedFile = {'fileName': uploadFile.fileName, 'fullFileName': uploadFile.fullFileName}
                },
            }).then(() => {
                context.dispatch("showLoadingSpinner", false);

                apolloClient.mutate({
                    mutation: uploadFileToRepository,
                    variables: {
                        documentPath: SavedFile.fullFileName,
                        documentName: SavedFile.fileName,
                        documentFolder: newFile.folderKey,
                    },
                }).then(() => {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'File Uploaded  successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAnnotatedRepositoryDocuments(context, folderUniqueId) {
            context.commit('cleanAddAnnotedRepositoryDocuments')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: annotatedRepositoryDocuments,
                variables: {
                    folderUniqueId: folderUniqueId,
                }
            }).then((response) => {
                if (response.data.annotatedRepositoryDocuments.length > 0) {
                    context.commit('addAnnotedRepositoryDocuments', response.data.annotatedRepositoryDocuments)
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteFoldersAndContents(context, folder) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deleteFoldersAndContents,
                variables: {
                    docKey: folder.docKey,
                    docType: folder.docType,
                }
            }).then(() => {
                context.dispatch("showLoadingSpinner", false);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: folder.docType + ' deleted successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async grantFolderAccesstoUsers(context, folder) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: grantFolderAccess,
                variables: {
                    accessFolder: folder.accessFolder,
                    accessUsers: folder.accessUsers,
                }
            }).then((response) => {
                context.dispatch("showLoadingSpinner", false);
                if (response.data.grantFolderAccess.accessGranted) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: ' Folder access granted successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Fail to grant access',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadPeopleWithFolderAccess(context, folderUniqueId) {
            context.commit('cleanPeopleWithFolderAccess')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: peopleWithFolderAccess,
                variables: {
                    folderUniqueId: folderUniqueId,
                }
            }).then((response) => {
                context.commit('addPeopleWithFolderAccess', response.data.peopleWithFolderAccess)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })

        },

        async removeUserAccessFromFolder(context, accessUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: removeFolderAccess,
                variables: {
                    accessUniqueId: accessUniqueId,
                }
            }).then((response) => {
                context.dispatch("showLoadingSpinner", false);
                if (response.data.removeFolderAccess.accessRemoved) {
                    swal.fire({
                        toast: true,
                        icon: "success",
                        title: "Access removed successfully",
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    });
                } else {
                    swal.fire({
                        toast: true,
                        icon: "error",
                        title: "Fail to remove access",
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    });
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateUserFolderAccessControl(context, updatedData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: updateFolderAccess,
                variables: {
                    accessType: updatedData.access,
                    accessUniqueId: updatedData.accessId
                }
            }).then((response) => {
                console.log(response.data.updateFolderAccess.accessUpdated);
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async searchThroughDocuments(context, searchData) {
            context.commit('saveSearchedContents', [])
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: searchInsideFolderContents,
                variables: {
                    searchData: searchData,
                }
            }).then((response) => {
                context.commit('saveSearchedContents', response.data.searchInsideFolderContents.searchResults)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


    }
}

