<template>
  <div class="h-screen flex justify-center items-center">
    <div class="border border-2 p-5 rounded-md bg-white col-md-5">
      <div class="flex items-center">
        <button class="bg-primary p-2 rounded-md" @click="$router.go(-1)" >
          <v-icon color="white">mdi-arrow-left</v-icon>
        </button>
        <span class="font-bold text-xl ml-2">
          Change Password
        </span>
      </div>

      <div class="mt-6">

        <div>
          <v-text-field
              v-model="oldPassword"
              :rules="passwordRules"
              outlined
              label="Old Password *"
              type="password"
              required
          ></v-text-field>
        </div>
        <div>
          <v-text-field
              v-model="newPassword"
              :rules="passwordRules"
              outlined
              label="New Password *"
              type="password"
              required
          ></v-text-field>
        </div>
        <div>
          <v-text-field
              v-model="confirmPassword"
              :rules="[[v => !!v || 'Password is required'], (this.newPassword === this.confirmPassword) || 'Password must match']"
              outlined
              label="Confirm New Password *"
              type="password"
              required
          ></v-text-field>
        </div>

        <div class="flex justify-end">
          <v-btn color="#1E3A8A" class="text-capitalize"
          @click="changeUserPassword">
            <icon name="save" width="w-5" height="h-5" color="#fff"></icon>
            <span class="text-white">Save</span>
          </v-btn>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {apolloClient} from "@/vue-apollo";
import passwordChange from '@/graphql/eboard-settings-graphql/passwordChange.graphql'
import Icon from "@/views/Icon.vue";
import {successAlert} from "@/services/notificationService";

export default {
  name: "ChangePassword",
  components: {Icon},
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordRules: [
        v => !!v || 'Password is required'
      ]
    }
  },
  methods: {
    async changeUserPassword() {
      await this.$store.dispatch("showLoadingSpinner", true);
      apolloClient.mutate({
        mutation: passwordChange
        , loading: this.value,
        fetchPolicy: 'no-cache',
        variables: {
          oldPassword: this.oldPassword,
          newPassword1: this.newPassword,
          newPassword2: this.confirmPassword,
        }
      }).then( async (response) => {
        if(response.data.passwordChange.success) {
          await successAlert('Password changed successfully!')
          await this.$store.dispatch("showLoadingSpinner", false);
          this.callLogoutMethod()
        }
      }).catch(async () => {
        await this.$store.dispatch("showLoadingSpinner", false);
      })
    },
    callLogoutMethod() {
      localStorage.removeItem("vuex");
      localStorage.removeItem("my-boardUniqueId");
      localStorage.removeItem("my-boardKey");
      localStorage.removeItem("apollo-token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("my-managementUniqueId");
      localStorage.removeItem("my-managementCommitteeUniqueId");
      localStorage.removeItem("my-managementCommitteeName");
      this.$router.go("/landing_page");
    },
  }
}
</script>

<style scoped>

</style>