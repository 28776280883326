export const IconService = {
    management_committee: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="m17.529 7.77c-0.07-0.01-0.14-0.01-0.21 0-1.55-0.05-2.78-1.32-2.78-2.88 0-1.59 1.29-2.89 2.89-2.89 1.59 0 2.89 1.29 2.89 2.89-0.01 1.56-1.24 2.83-2.79 2.88z" fill="#292D32"/>
<path d="m20.792 14.7c-1.12 0.75-2.69 1.03-4.14 0.84 0.38-0.82 0.58-1.73 0.59-2.69 0-1-0.22-1.95-0.64-2.78 1.48-0.2 3.05 0.08 4.18 0.83 1.58 1.04 1.58 2.75 0.01 3.8z" fill="#292D32"/>
<path d="m6.4402 7.77c0.07-0.01 0.14-0.01 0.21 0 1.55-0.05 2.78-1.32 2.78-2.88 0-1.6-1.29-2.89-2.89-2.89-1.59 0-2.88 1.29-2.88 2.89 0 1.56 1.23 2.83 2.78 2.88z" fill="#292D32"/>
<path d="m6.5511 12.851c0 0.97 0.21 1.89 0.59 2.72-1.41 0.15-2.88-0.15-3.96-0.86-1.58-1.05-1.58-2.76 0-3.81 1.07-0.72 2.58-1.01 4-0.85-0.41 0.84-0.63 1.79-0.63 2.8z" fill="#292D32"/>
<path d="m12.121 15.87c-0.08-0.01-0.17-0.01-0.26 0-1.84-0.06-3.31-1.57-3.31-3.43 0.01-1.9 1.54-3.44 3.45-3.44 1.9 0 3.44 1.54 3.44 3.44-0.01 1.86-1.47 3.37-3.32 3.43z" fill="#292D32"/>
<path d="m8.8708 17.941c-1.51 1.01-1.51 2.67 0 3.67 1.72 1.15 4.54 1.15 6.26 0 1.51-1.01 1.51-2.67 0-3.67-1.71-1.15-4.53-1.15-6.26 0z" fill="#292D32"/>
</svg>`,
    save: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="m17 20.75h-10c-0.72935 0-1.4288-0.2897-1.9445-0.8055-0.51573-0.5157-0.80546-1.2152-0.80546-1.9445v-12c0-0.72935 0.28973-1.4288 0.80546-1.9445 0.51572-0.51573 1.2152-0.80546 1.9445-0.80546h7.5c0.1988 1.8e-4 0.3895 0.07931 0.53 0.22l4.5 4.53c0.1407 0.14052 0.2198 0.33115 0.22 0.53v9.47c0 0.7293-0.2897 1.4288-0.8055 1.9445-0.5157 0.5158-1.2152 0.8055-1.9445 0.8055zm-10-16c-0.33152 0-0.64946 0.1317-0.88388 0.36612s-0.36612 0.55236-0.36612 0.88388v12c0 0.3315 0.1317 0.6495 0.36612 0.8839s0.55236 0.3661 0.88388 0.3661h10c0.3315 0 0.6495-0.1317 0.8839-0.3661s0.3661-0.5524 0.3661-0.8839v-9.19l-4.06-4.06h-7.19z"/>
<path d="m16.75 20h-1.5v-6.25h-6.5v6.25h-1.5v-6.5c0-0.3315 0.1317-0.6495 0.36612-0.8839s0.55236-0.3661 0.88388-0.3661h7c0.3315 0 0.6495 0.1317 0.8839 0.3661s0.3661 0.5524 0.3661 0.8839v6.5z"/>
<path d="m12.47 8.75h-3.94c-0.16941-0.00131-0.3369-0.03597-0.49291-0.10201s-0.29748-0.16217-0.41634-0.28288c-0.11886-0.12072-0.21278-0.26367-0.27639-0.42068-0.06362-0.15702-0.09568-0.32502-0.09436-0.49443v-3.45h1.5v3.25h3.5v-3.25h1.5v3.45c0.0013 0.16941-0.0307 0.33741-0.0943 0.49443-0.0637 0.15701-0.1576 0.29996-0.2764 0.42068-0.1189 0.12071-0.2604 0.21684-0.4164 0.28288s-0.3235 0.1007-0.4929 0.10201z"/>
</svg>
`,
    close: `<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="m5.2929 5.2929c0.39053-0.39052 1.0237-0.39052 1.4142 0l5.2929 5.2929 5.2929-5.2929c0.3905-0.39052 1.0237-0.39052 1.4142 0 0.3905 0.39053 0.3905 1.0237 0 1.4142l-5.2929 5.2929 5.2929 5.2929c0.3905 0.3905 0.3905 1.0237 0 1.4142s-1.0237 0.3905-1.4142 0l-5.2929-5.2929-5.2929 5.2929c-0.39053 0.3905-1.0237 0.3905-1.4142 0-0.39052-0.3905-0.39052-1.0237 0-1.4142l5.2929-5.2929-5.2929-5.2929c-0.39052-0.39053-0.39052-1.0237 0-1.4142z" clip-rule="evenodd" fill="#0F1729" fill-rule="evenodd"/>
</svg>
`,
    meeting_add: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="m16.75 3.56v-1.56c0-0.41-0.34-0.75-0.75-0.75s-0.75 0.34-0.75 0.75v1.5h-6.5v-1.5c0-0.41-0.34-0.75-0.75-0.75s-0.75 0.34-0.75 0.75v1.56c-2.7 0.25-4.01 1.86-4.21 4.25-0.02 0.29 0.22 0.53 0.5 0.53h16.92c0.29 0 0.53-0.25 0.5-0.53-0.2-2.39-1.51-4-4.21-4.25z" fill="#292D32"/>
<path d="m20 9.8398h-16c-0.55 0-1 0.44996-1 0.99996v6.16c0 3 1.5 5 5 5h4.93c0.69 0 1.17-0.67 0.95-1.32-0.2-0.58-0.37-1.22-0.37-1.68 0-3.03 2.47-5.5 5.5-5.5 0.29 0 0.58 0.02 0.86 0.07 0.6 0.09 1.14-0.38 1.14-0.98v-1.74c-0.01-0.56-0.46-1.01-1.01-1.01zm-10.79 7.87c-0.1 0.09-0.21 0.16-0.33 0.21s-0.25 0.08-0.38 0.08-0.26-0.03-0.38-0.08-0.23-0.12-0.33-0.21c-0.18-0.19-0.29-0.45-0.29-0.71 0-0.06 0.01-0.13 0.02-0.19 0.01-0.07 0.03-0.13 0.06-0.19 0.02-0.06 0.05-0.12 0.09-0.18 0.03-0.05 0.08-0.1 0.12-0.15 0.1-0.09 0.21-0.16 0.33-0.21 0.24-0.1 0.52-0.1 0.76 0 0.12 0.05 0.23 0.12 0.33 0.21 0.04 0.05 0.09 0.1 0.12 0.15 0.04 0.06 0.07 0.12 0.09 0.18 0.03 0.06 0.05 0.12 0.06 0.19 0.01 0.06 0.02 0.13 0.02 0.19 0 0.26-0.11 0.52-0.29 0.71zm0-3.5c-0.1 0.09-0.21 0.16-0.33 0.21s-0.25 0.08-0.38 0.08-0.26-0.03-0.38-0.08c-0.13-0.05-0.23-0.12-0.33-0.21-0.18-0.19-0.29-0.45-0.29-0.71s0.11-0.52 0.29-0.71c0.1-0.09 0.21-0.16 0.33-0.21 0.24-0.1 0.52-0.1 0.76 0 0.12 0.05 0.23 0.12 0.33 0.21 0.04 0.05 0.09 0.1 0.12 0.15 0.04 0.06 0.07 0.12 0.09 0.18 0.03 0.06 0.05 0.12 0.06 0.18 0.01 0.07 0.02 0.14 0.02 0.2 0 0.26-0.11 0.52-0.29 0.71zm3.5 0c-0.19 0.18-0.44 0.29-0.71 0.29-0.13 0-0.26-0.03-0.38-0.08-0.13-0.05-0.23-0.12-0.33-0.21-0.18-0.19-0.29-0.45-0.29-0.71 0-0.06 0.01-0.13 0.02-0.2 0.01-0.06 0.03-0.12 0.06-0.18 0.02-0.06 0.05-0.12 0.09-0.18l0.12-0.15c0.37-0.37 1.04-0.37 1.42 0l0.12 0.15c0.04 0.06 0.07 0.12 0.09 0.18 0.03 0.06 0.05 0.12 0.06 0.18 0.01 0.07 0.02 0.14 0.02 0.2 0 0.26-0.11 0.52-0.29 0.71z" fill="#292D32"/>
<path d="m21.83 16.17c-1.56-1.56-4.1-1.56-5.66 0s-1.56 4.1 0 5.66 4.1 1.56 5.66 0 1.56-4.1 0-5.66zm-0.76 3.39c-0.13 0.14-0.32 0.22-0.53 0.22h-0.74v0.78c0 0.21-0.08 0.39-0.22 0.53s-0.32 0.22-0.53 0.22c-0.41 0-0.75-0.34-0.75-0.75v-0.78h-0.75c-0.41 0-0.75-0.33-0.75-0.75 0-0.41 0.34-0.75 0.75-0.75h0.75v-0.71c0-0.41 0.33-0.75 0.75-0.75 0.41 0 0.75 0.34 0.75 0.75v0.71h0.74c0.42 0 0.75 0.34 0.75 0.75 0 0.21-0.08 0.4-0.22 0.53z" fill="#292D32"/>
</svg>
`,
    institution: `<svg fill="#000000" version="1.1" viewBox="0 0 490 490" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">

\t<polygon points="474.42 143.93 245.38 0 15.583 143.71 31.865 169.72 245.35 36.246 458.07 169.92"/>
\t<rect x="376.3" y="183.07" width="30.691" height="240.45"/>
\t<rect x="229.65" y="183.07" width="30.691" height="240.45"/>
\t<rect x="83.012" y="183.07" width="30.691" height="240.45"/>
\t<rect x="25.021" y="459.31" width="439.94" height="30.691"/>

</svg>`,

    user: `<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="m17.5 21h-11c-1.3807 0-2.5-1.1193-2.5-2.5 0-4.0807 6-4 8-4s8-0.0807 8 4c0 1.3807-1.1193 2.5-2.5 2.5z" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
<path d="m12 11c2.2091 0 4-1.7909 4-4s-1.7909-4-4-4c-2.2091 0-4 1.7909-4 4s1.7909 4 4 4z" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
</svg>`,

    delete: `<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
<path d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"/>
</svg>`,

    edit: `<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
<path d="M832 512a32 32 0 1 1 64 0v352a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V160a32 32 0 0 1 32-32h352a32 32 0 0 1 0 64H192v640h640V512z"/>
<path d="m469.95 554.24 52.8-7.552 324.35-324.29a32 32 0 1 0-45.248-45.248l-324.42 324.29-7.552 52.8zm422.4-422.4a96 96 0 0 1 0 135.81l-331.84 331.84a32 32 0 0 1-18.112 9.088l-105.6 15.104a32 32 0 0 1-36.224-36.224l15.104-105.6a32 32 0 0 1 9.024-18.112l331.9-331.84a96 96 0 0 1 135.74 0z"/>
</svg>`,

    view: `<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
<path d="m512 160c320 0 512 352 512 352s-192 352-512 352-512-352-512-352 192-352 512-352zm0 64c-225.28 0-384.13 208.06-436.8 288 52.608 79.872 211.46 288 436.8 288 225.28 0 384.13-208.06 436.8-288-52.608-79.872-211.46-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448zm0 64a160.19 160.19 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z"/>
</svg>`,
    role: `<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
<g data-name="Layer 2">
<g data-name="invisible box">
<rect width="48" height="48" fill="none"/>
</g>
<path d="M3,30.5l-1,.6V39a2,2,0,0,0,2,2H24V37H6V33.4A21.7,21.7,0,0,1,16,31a21.5,21.5,0,0,1,8,1.5V28.3A24.4,24.4,0,0,0,16,27,25.6,25.6,0,0,0,3,30.5Z"/>
<path d="m16 5a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 14a5 5 0 1 1 5-5 5 5 0 0 1-5 5z"/>
<path d="m44 28h-1v-3a6 6 0 0 0-12 0v3h-1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-11a2 2 0 0 0-2-2zm-9-3a2 2 0 0 1 4 0v3h-4zm7 14h-10v-7h10z"/>
</g>
</svg>`,

    admin: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0h24v24H0z" fill="none"/>
<path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm9 6h1v5h-8v-5h1v-1a3 3 0 0 1 6 0v1zm-2 0v-1a1 1 0 0 0-2 0v1h2z"/>
</svg>`,

    news: `<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
<path d="m9.069 2.672v14.928h-6.397v8.718s1.983 3.01 3.452 3.01h20.006c1.616 0 3.199-1.572 3.199-3.199v-23.457h-20.259zm-2.945 25.59c-0.664 0-2.385-0.349-2.385-1.944v-7.652h5.331v7.192c0 0.714-0.933 2.404-2.404 2.404h-0.542zm22.138-2.133c0 1.036-1.096 2.133-2.133 2.133h-17.113c0.718-0.748 1.119-1.731 1.119-2.404v-22.12h18.126v22.391z"></path>
<path d="m12.268 5.871h13.861v1.066h-13.861v-1.066z"></path>
<path d="m12.268 20.265h13.861v1.066h-13.861v-1.066z"></path>
<path d="m12.268 23.997h13.861v1.066h-13.861v-1.066z"></path>
<path d="m26.129 9.602h-13.861v7.997h13.861v-7.997zm-1.066 6.931h-11.729v-5.864h11.729v5.864z"></path>
</svg>`,

    eye: `<svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="m22 12s-3.6364 7-10 7c-6.3636 0-10-7-10-7s3.6364-7 10-7c2.8779 0 5.198 1.4316 6.8762 3m-9.8762 4c0 1.6569 1.3431 3 3 3s3-1.3431 3-3-1.3431-3-3-3" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
</svg>`,

    deactivate: `<svg fill="#000000" version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
<path d="m16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zm0-23c-5.522 0-10 4.478-10 10s4.478 10 10 10c5.523 0 10-4.478 10-10s-4.477-10-10-10zm4.537 13.535-1.014 1.014c-0.186 0.186-0.488 0.186-0.675 0l-2.87-2.87-2.87 2.87c-0.187 0.186-0.488 0.186-0.675 0l-1.014-1.014c-0.186-0.186-0.186-0.488 0-0.675l2.871-2.869-2.871-2.87c-0.186-0.187-0.186-0.489 0-0.676l1.014-1.013c0.187-0.187 0.488-0.187 0.675 0l2.87 2.87 2.87-2.87c0.187-0.187 0.489-0.187 0.675 0l1.014 1.013c0.186 0.187 0.186 0.489 0 0.676l-2.871 2.87 2.871 2.869c0.186 0.187 0.186 0.49 0 0.675z"/>
</svg>`,
}
