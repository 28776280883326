<template>
  <v-container>
    <v-row class="mt-5 intro-x">
      <v-col>
        <div class="p-5 bg-warning flex shadow-sm w-80 items-center rounded-md">
          <v-row class="flex">
            <v-col cols="9">
              <icon name="institution" width="w-20" height="w-20" color="#ffffff"></icon>
            </v-col>
            <v-col cols="3">
              <p class="text-white text-lg">Institutions</p>
              <h3 class="text-white text-5xl">{{getInstitutions.length}}</h3>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col>
        <div class="p-5 bg-success flex shadow-sm w-100 items-center rounded-md">
          <v-row class="flex">
            <v-col cols="9" md="8">
              <icon name="user" width="w-20" height="w-20" color="#ffffff"></icon>
            </v-col>
            <v-col cols="3" md="4">
              <p class="text-white text-lg justify-start">System Admins</p>
              <h3 class="text-white text-5xl justify-end align-end">{{getSystemAdminList.length}}</h3>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Icon from "@/views/Icon.vue";

export default {
  name: "AdminPanelDashboard",
  components: {Icon},

    computed: {
      getInstitutions (){
        return this.$store.getters.getInstitutionList;
      },

      getSystemAdminList(){
        return this.$store.getters.getSystemAdminList;
      }
    },

  mounted() {
    this.$store.dispatch('loadInstitutionLists')
    this.$store.dispatch('loadSystemAdmins')
  },

  methods: {

  }
}
</script>

<style scoped>

</style>