<template>
	<div class="container" style="height: 100vh; width: 100%;"></div>
</template>
<script>

import PSPDFKit from 'pspdfkit';

// Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
const baseUrl = `${window.location.protocol}//${window.location.host}/`;

/**
 * PSPDFKit for Web example component
 */
export default {
	name: 'PSPDFKit',
	/**
	 * The component receives these parameters:
	 * @documentUrl: string — URL of the document to be loaded.
	 * @baseUrl: string — URL from which the PSPDFKit library should get its payload and artifacts
	 */
	props: ["documentUrl","document"],
	_instance: null,
	/**
	* We wait until the template has been rendered to load the document into the library.
	*/
	mounted: function mounted() {
		this.load();
	},
	/**
	 * Our component has these two methods — one to trigger document loading, and the other to unload and clean up
	 * so the component is ready to load another document.
	 */
	methods: {
		load: function load() {
				PSPDFKit.load({
					baseUrl: baseUrl,
					document: this.documentUrl,
					container: ".container",
                    autoSaveMode: PSPDFKit.AutoSaveMode.IMMEDIATE,
				}).then(async instance => {
                    (window).instance = instance;

                    instance.addEventListener("document.saveStateChange", async (event) => {
                        const arrayBuffer = await instance.exportPDF();
                        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                        const formData = new FormData();
                        formData.append("file",  blob);
                        formData.append("path", this.document);

                        
                        await fetch(this.MEDIA_SERVER+"/upload", {
                            method: "POST",
                            body: formData
                        }).then((response)=>{
							console.log(response);
						});

                        console.log(`Save state changed: ${event}`);
                        
                    });
                    
                });
		},
		unload: function unload() {
				if (this._instance) {
					PSPDFKit.unload(this._instance || ".container");
					this._instance = null;
				}
		}
	},
	/**
	 * We watch for `documentUrl` prop changes and trigger unloading and loading when there's a new document to load.
	 */
	watch: {
		documentUrl: function documentUrl() {
				this.unload();
				this.load();
		}
	},
	/**
	 * Clean up when the component is unmounted (not needed in this example).
	 */
	beforeDestroy: function beforeDestroy() {
		this.unload();
	}
}
</script>