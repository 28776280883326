var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-simple-table',{staticStyle:{"width":"100%"}},[[(!_vm.selectedAgenda)?_c('v-overlay',{attrs:{"absolute":_vm.absolute,"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"width":"10%"}}),_c('th',{staticClass:"text-left",staticStyle:{"width":"80%"}})])]),_c('tbody',[(_vm.boardSetting().showAgendaPurpose)?_c('tr',[_c('td',[_c('strong',[_vm._v("Purpose :")])]),(_vm.hasAccess('can_schedule_meeting'))?_c('td',[(_vm.isEditItemPurpose && _vm.isEditItemPurposeKey === _vm.selectedAgenda.itemDetails.itemKey)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemPurpose),expression:"itemPurpose"}],staticStyle:{"width":"80%"},attrs:{"autofocus":true,"placeholder":_vm.selectedAgenda.itemDetails.itemPuporse || ''},domProps:{"value":(_vm.itemPurpose)},on:{"change":function (event) { return _vm.onPurposeInput(event, _vm.selectedAgenda.itemDetails.itemKey, 'Purpose'); },"input":function($event){if($event.target.composing){ return; }_vm.itemPurpose=$event.target.value}}}):_c('span',[_vm._v(" "+_vm._s(_vm.selectedAgenda.itemDetails.itemPuporse)+" ")]),(_vm.isEditItemPurpose && _vm.isEditItemPurposeKey === _vm.selectedAgenda.itemDetails.itemKey)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.clearIsEditItemPurpose}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,1743445493)},[_c('span',[_vm._v("Click to close Edit")])]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.setIsEditItemPurpose}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,3097051406)},[_c('span',[_vm._v("Click to Edit Content")])])],1):_c('td',[_c('p',[_vm._v(" "+_vm._s(_vm.selectedAgenda.itemDetails.itemPuporse)+" ")])])]):_vm._e(),(_vm.boardSetting().showAgendaPresenter)?_c('tr',[_c('td',[_c('strong',[_vm._v("Presenter :")])]),(_vm.hasAccess('can_schedule_meeting'))?_c('td',[_c('v-menu',{attrs:{"app":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"activator","color":"indigo","small":"","text":""},slot:"activator"},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.presenter)+" ")])]}}],null,false,3684483123)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getBoardMembers),function(member){return _c('v-list-item',{key:member.username,attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.changePresenter(_vm.selectedAgenda.itemKey, member)}}},[_vm._v(" "+_vm._s(member.firstName)+" "+_vm._s(member.lastName)+" ")])],1)}),1)],1)],1):_c('td',[_c('v-btn',_vm._g(_vm._b({staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"activator","color":"indigo","small":"","text":""},slot:"activator"},'v-btn',_vm.attrs,false),_vm.on),[_vm._v(_vm._s(_vm.presenter)+" ")])],1)]):_vm._e(),(_vm.boardSetting().showAgendaDuration)?_c('tr',[_c('td',[_c('strong',[_vm._v("Duration :")])]),(_vm.hasAccess('can_schedule_meeting'))?_c('td',{staticClass:"Editable"},[(_vm.isEditItemDuration && _vm.isEditItemPurposeKey === _vm.selectedAgenda.itemDetails.itemKey)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemPurpose),expression:"itemPurpose"}],attrs:{"autofocus":true,"placeholder":_vm.selectedAgenda.itemDetails.itemDuration || '0'},domProps:{"value":(_vm.itemPurpose)},on:{"change":function (event) { return _vm.onPurposeInput(event, _vm.selectedAgenda.itemDetails.itemKey, 'Time'); },"input":function($event){if($event.target.composing){ return; }_vm.itemPurpose=$event.target.value}}}):_c('span',[_vm._v(_vm._s(_vm.selectedAgenda.itemDetails.itemDuration))]),_vm._v(" Min "),(_vm.isEditItemDuration && _vm.isEditItemPurposeKey === _vm.selectedAgenda.itemDetails.itemKey)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.clearIsEditItemDuration}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,2448432181)},[_c('span',[_vm._v("Click to close Edit")])]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.setIsEditItemDuration}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,527038542)},[_c('span',[_vm._v("Click to Edit Content")])])],1):_c('td',[_c('span',[_vm._v(_vm._s(_vm.selectedAgenda.itemDetails.itemDuration))]),_vm._v(" Min ")])]):_vm._e(),_c('tr')])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }