<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p>Meeting Recommendations in Management Committee Meetings</p>
      </v-col>
      <v-spacer></v-spacer>

      <v-col
        class="d-flex"
        cols="12"
        sm="4"
      >
        <v-select
          outlined
          v-model="filterObj.meetingUniqueId"
          :items="managementCommitteeMeetings"
          dense
          item-text="title"
          item-value="id"
          label="Filter by Meeting"
          @change="loadAllManagementCommitteeAgendaRecommendation"
        ></v-select>
      </v-col>
    </v-row>

    <!--    <v-row>-->
    <v-card>
      <v-card-text>
        <v-expansion-panels popout :value="openPanels" multiple>
          <v-expansion-panel
            v-for="(recommendation, index) of meetingRecommendations.data"
            :key="index"
          >
            <v-expansion-panel-header>
              <h2 class="font-bold">{{ recommendation.recommendationTitle }}</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center" class="mx-1 my-1">
                <div class="flex">
                  <h2 class="font-medium mr-2">Meeting Title: </h2>
                  <span>{{ recommendation.recommendationMeeting }}</span>
                </div>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row align="center" class="mx-1 my-1">
                <div>
                  <h2 class="font-medium">Description</h2>
                  <div v-html="recommendation.recommendationDescriptions"></div>
                </div>
              </v-row>
              <v-divider class="my-3"></v-divider>
              <v-row align="center" class="mx-1 my-1">
                <div class="flex items-center">
                  <span class="mr-2">Recommended On: </span>
                  <span class="font-medium px-2 rounded-full text-white bg-primary"
                        style="font-size: 10px;">{{ recommendation.recommendationCreatedDate }}</span>
                </div>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="paginateManagementCommitteeAgendaRecommendation(meetingRecommendations.page.previousPageNumber)"
          v-if="meetingRecommendations.page.hasPreviousPage" outlined small
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Previous Page ({{ meetingRecommendations.page.previousPageNumber }})
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="paginateManagementCommitteeAgendaRecommendation(meetingRecommendations.page.nextPageNumber)"
          v-if="meetingRecommendations.page.hasNextPage" outlined small
        >
          Next Page ({{ meetingRecommendations.page.nextPageNumber }})
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--    </v-row>-->
  </v-container>
</template>

<script>
export default {
  name: "ManagementCommitteeRecommendation",
  data() {
    return {
      filterObj: {
        committeeUniqueId: localStorage.getItem('my-managementCommitteeUniqueId'),
        pageNumber: 1,
        meetingUniqueId: null
      },
      openPanels: []
    }
  },
  watch: {
    meetingRecommendations() {
      this.openPanels = [];
      for (let index=0; index<this.meetingRecommendations.data.length; index++) {
        this.openPanels.push(index);
      }
    }
  },
  methods: {
    async loadAllManagementCommitteeAgendaRecommendation() {
      await this.$store.dispatch('loadAllManagementCommitteeAgendaRecommendation', this.filterObj);
    },
    async paginateManagementCommitteeAgendaRecommendation(pageNumber) {
      this.filterObj.pageNumber = pageNumber;
      await this.$store.dispatch('loadAllManagementCommitteeAgendaRecommendation', this.filterObj);
    }
  },
  async mounted() {
    await this.loadAllManagementCommitteeAgendaRecommendation();
    await this.$store.dispatch('loadManagementCommitteeMeetings');
  },
  computed: {
    managementCommitteeMeetings() {
      let temp = [];
      let data = [];

      temp = this.$store.getters.getManagementCommitteeMeetings;

      temp.forEach(meeting => {
        data.push({ id: meeting.id, title: meeting.meetingTitle });
      });

      data.push({ id: null, title: "All Meetings" });

      return data;
    },
    meetingRecommendations() {
      return this.$store.getters.getManagementCommitteeAgendaRecommendationList
    },
  }
}
</script>

<style scoped>

</style>