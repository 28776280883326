import { apolloClient } from '@/vue-apollo'
import allManagementCommittees from '../../graphql/management-committee-graphql/getAllManagementCommittees.gql'
import createManagementCommitteeMutation from '../../graphql/management-committee-graphql/createManagementCommitteeMutation.gql'
import updateManagementCommitteeMutation from '../../graphql/management-committee-graphql/updateManagementCommitteeMutation.gql'
import deactivateManagementCommitteeMutation from '../../graphql/management-committee-graphql/deactivateManagementCommitteeMutation.gql'
import activateManagementCommitteeMutation from '../../graphql/management-committee-graphql/activateManagementCommitteeMutation.gql'
import getMyManagementCommittees from '../../graphql/management-committee-graphql/getMyManagementCommittees.gql'
import {errorAlert, successAlert} from "../../services/notificationService";


export default {
    state: {
        managementCommittees: [],
        myManagementCommittees: [],
    },
    getters: {
        getManagementCommittees(state) {
            return state.managementCommittees
        },
        getMyManagementCommittees(state) {
            return state.myManagementCommittees
        }
    },
    mutations: {
        clearMyManagementCommittees(state) {
            state.myManagementCommittees = []
        },
        clearManagementCommittees(state) {
            state.managementCommittees = []
        },
        saveMyManagementCommittees(state, committees) {
            state.myManagementCommittees = [...committees]
        },
        saveManagementCommittees(state, committees) {
            state.managementCommittees = [...committees]
        },
        addManagementCommittee(state, committee) {
            state.managementCommittees.push(committee)
        },
        updateManagementCommittee(state, committee) {
            state.managementCommittees = state.managementCommittees.map((managementCommittee) => {
                return managementCommittee.id == committee.id ? committee : managementCommittee
            })
        }
    },
    actions: {
        async loadManagementCommittees(context) {
            context.commit('clearManagementCommittees')
            let myBoardUniqueId = localStorage.getItem('my-boardUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (myBoardUniqueId !== "") {
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: allManagementCommittees,
                    variables: {
                        boardUniqueId: myBoardUniqueId || ""
                    }
                }).then( async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.getAllManagementCommittees
                    if (result.response.code == 9000) {
                        context.commit('saveManagementCommittees', result.data)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },
        async createManagementCommittee(context, input) {
            let boardUniqueId = localStorage.getItem('my-boardUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (boardUniqueId !== "") {
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: createManagementCommitteeMutation,
                    variables: {
                        input: {
                            ...input,
                            boardUniqueId
                        }
                    }
                }).then( async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.createManagementCommitteeMutation
                    if (result.response.code == 9000) {
                        context.commit('addManagementCommittee', result.data)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },
        async editManagementCommittee(context, input) {
            let boardUniqueId = localStorage.getItem('my-boardUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (boardUniqueId !== "") {
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: updateManagementCommitteeMutation,
                    variables: {
                        input: {
                            ...input,
                            boardUniqueId
                        }
                    }
                }).then( async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.updateManagementCommitteeMutation
                    if (result.response.code == 9000) {
                        context.commit('updateManagementCommittee', result.data)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },
        async deactivateManagementCommitteeMutation(context, input) {
            let boardUniqueId = localStorage.getItem('my-boardUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (boardUniqueId !== "") {
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: deactivateManagementCommitteeMutation,
                    variables: {
                        input: {
                            ...input,
                            boardUniqueId
                        }
                    }
                }).then( async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.deactivateManagementCommitteeMutation
                    if (result.response.code == 9000) {
                        context.commit('updateManagementCommittee', result.data)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },
        async activateManagementCommitteeMutation(context, input) {
            let boardUniqueId = localStorage.getItem('my-boardUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (boardUniqueId !== "") {
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: activateManagementCommitteeMutation,
                    variables: {
                        input: {
                            ...input,
                            boardUniqueId
                        }
                    }
                }).then( async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.activateManagementCommitteeMutation
                    if (result.response.code == 9000) {
                        context.commit('updateManagementCommittee', result.data)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },
        async loadMyManagementCommittees(context) {
            let boardUniqueId = localStorage.getItem('my-boardUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (boardUniqueId !== "") {
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: getMyManagementCommittees
                }).then( async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.getMyManagementCommittees
                    if (result.response.code == 9000) {
                        context.commit('saveMyManagementCommittees', result.data)
                    } else {
                        errorAlert(result.response.message)
                    }
                })

                    .finally( async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
            }
        },


    },


}



