var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard rounded-md",staticStyle:{"background":"rgba(39, 24, 245, 0.06)"}},[_c('v-container',{staticClass:"my-1"},[(_vm.egandaItems.length > 0)?_c('div',{staticClass:"mt-5 relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-2"},_vm._l((_vm.egandaItems),function(item){return _c('div',{key:'__k' + item.itemKey,staticClass:"intro-x relative flex items-center mb-3"},[_c('div',{staticClass:"before:block before:absolute before:w-20 before:h-px before:bg-slate-200 before:dark:bg-darkmode-400 before:mt-5 before:ml-3"},[_c('div',{staticClass:"w-10 h-10 flex-none image-fit rounded-full overflow-hidden"},[_c('img',{attrs:{"alt":"Agenda","src":require("@/assets/img/agenda.png")}})])]),_c('div',{staticClass:"box px-5 py-3 ml-2 flex-1 zoom-in",on:{"click":function($event){_vm.getAgendaItemDetails(_vm.agendaId + '.' + item.itemNumber);_vm.updateSelectedAgendaItem(item);}}},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(item.itemNumber)+" . "+_vm._s(item.itemName)+" ")]),_c('div',{staticClass:"text-xs text-slate-500 ml-auto"},[(_vm.hasAccess('can_schedule_meeting') && !_vm.HasPublishedAgenda)?_c('v-menu',{attrs:{"app":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"slot":"activator","color":"indigo","icon":""},on:{"click":_vm.onClickButton},slot:"activator"},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.updateSelectedAgendaItem(item)}}},[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-square-edit-outline")]),_vm._v(" Edit Agenda Item ")],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.moveAgendaItem(item.itemKey, 'UP')}}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-arrow-up")]),_vm._v(" Move it Up ")],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.moveAgendaItem(item.itemKey, 'DOWN')}}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-arrow-down")]),_vm._v(" Move it Down ")],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteAgendaItem(
                        _vm.agendaKey,
                        item.itemKey,
                        _vm.currentMeeting
                      )}}},[_c('v-list-item-title',[_c('v-icon',{staticStyle:{"height":"16px","font-size":"16px"}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1)],1)],1)],1):_c('span',[_c('small',{staticClass:"success--text"},[_vm._v("Published")])])],1)])])])}),0):_c('p',[_vm._v(" There are no Agenda Items for this Section. Select an option bellow and add one ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }