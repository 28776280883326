<template>
  <v-row justify="center">
    <v-dialog persistent v-model="isOpenPackViewersDialog" max-width="30%">
      <v-card>
        <v-card-title class="text-h6"> List of people who see this pack </v-card-title>

        <v-card-text>
          <center v-if="packViewersList.length === 0">
            <span>No any viewers yet.</span>
          </center>
          <v-list-item two-line v-for="viewer in packViewersList" :key="viewer">
            <v-list-item-content>
              <v-list-item-title><strong>Name:</strong> {{ viewer.fullName }}</v-list-item-title>
              <v-list-item-subtitle><strong>Date:</strong> {{ viewer.date|formatFull }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('closePackViewersDialog')"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: [
    "closePackViewersDialog",
    "isOpenPackViewersDialog",
    "packViewersList",
  ],
};
</script>

<style>
</style>