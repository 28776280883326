var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"text-lg font-weight-medium"},[_vm._v(" List of administrators")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"right":"","small":"","outlined":"","color":"primary white--text"},on:{"click":function($event){_vm.newSystemAdministratorDialog=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-lock-open-outline")]),_vm._v(" Create System Administrator ")],1)]}}])},[_c('span',[_vm._v("Create System Administrator")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"right":"","small":"","outlined":"","color":"success white--text"},on:{"click":function($event){_vm.newSystemAdministratorRoleDialog=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-plus")]),_vm._v(" Create System Admin Roles ")],1)]}}])},[_c('span',[_vm._v("Create System Admin Roles")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12 intro-x"}},[_c('v-card',{staticClass:"shadow-sm"},[_c('v-tabs',{attrs:{"color":"#3f51b5","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.icon},[_c('v-icon',{staticClass:"me-3",attrs:{"color":tab.color,"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"rounded-md mb-15",staticStyle:{"background":"rgba(39, 24, 245, 0.04)"}},[_c('v-row',[_c('div',{staticClass:" mt-3 w-full"},[_c('table',{staticClass:"p-3 w-100 table table-hover bg-white"},[_c('thead',[_c('tr',{staticClass:"bg-primary text-white"},[_c('th',{attrs:{"scope":"col"}},[_vm._v("No.")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Username/Email")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("First Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Last Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("System Admin Role")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.getSystemAdminList),function(admin,index){return _c('tr',{key:admin.id},[_c('td',[_vm._v(_vm._s(index+1))]),_c('td',[_vm._v(_vm._s(admin.userName))]),_c('td',[_vm._v(_vm._s(admin.firstName))]),_c('td',[_vm._v(_vm._s(admin.lastName))]),_c('td',[_vm._v(_vm._s((admin.adminRole||{}).roleName))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-warning btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.editSystemAdmin(admin)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"edit","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit Admin")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteSystemAdmin(admin.userProfileUniqueId)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"delete","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Delete Admin")])])],1)])}),0)])])])],1),_c('v-tab-item',{staticClass:"rounded-md mb-15",staticStyle:{"background":"rgba(39, 24, 245, 0.04)"}},[_c('v-row',[_c('div',{staticClass:"mt-3 w-full"},[_c('table',{staticClass:"p-3 w-100 table table-hover bg-white"},[_c('thead',[_c('tr',{staticClass:"bg-primary text-white"},[_c('th',{attrs:{"scope":"col"}},[_vm._v("No.")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Role Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Role Description")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.getSystemAdminRoleList),function(role,index){return _c('tr',{key:role.id},[_c('td',[_vm._v(_vm._s(index+1))]),_c('td',[_vm._v(_vm._s(role.roleName))]),_c('td',[_vm._v(_vm._s(role.roleDescription))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-primary btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.viewAdminRole(role)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"view","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("View Role")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-warning btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.editSystemAdminRole(role)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"edit","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit Role")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteSystemAdminRole(role.roleUniqueId)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"delete","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Delete Role")])])],1)])}),0)])])])],1)],1)],1)],1)],1),_c('add-new-system-administrator',{attrs:{"new-system-administrator-dialog":_vm.newSystemAdministratorDialog},on:{"close-dialog":function($event){_vm.newSystemAdministratorDialog=false}}}),_c('add-new-system-administrator-role',{attrs:{"new-system-administrator-role-dialog":_vm.newSystemAdministratorRoleDialog},on:{"close-role-dialog":function($event){_vm.newSystemAdministratorRoleDialog=false}}}),_c('edit-system-administrator-role',{attrs:{"edit-system-admin-role-dialog":_vm.editSystemAdminRoleDialog,"role-unique-id":_vm.roleUniqueId,"role-name":_vm.roleName,"role-description":_vm.roleDescription,"role-permissions":_vm.rolePermissions,"permissionsList":_vm.permissionsList},on:{"close-dialog":function($event){_vm.editSystemAdminRoleDialog=false}}}),_c('edit-system-administrator',{attrs:{"edit-system-administrator-dialog":_vm.editSystemAdministratorDialog,"user-profile-unique-id":_vm.userProfileUniqueId,"role-unique-id":_vm.roleUniqueId,"role-name":_vm.roleName,"first-name":_vm.firstName,"last-name":_vm.lastName,"email":_vm.email},on:{"close-dialog":function($event){_vm.editSystemAdministratorDialog=false}}}),_c('view-system-administrator-role',{attrs:{"view-system-admin-role-dialog":_vm.viewSystemAdminRoleDialog,"role-unique-id":_vm.roleUniqueId,"role-name":_vm.roleName,"role-description":_vm.roleDescription,"role-permissions":_vm.rolePermissions},on:{"close-dialog":function($event){_vm.viewSystemAdminRoleDialog=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }