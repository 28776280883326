<template>
  <v-row justify="center">
    <v-dialog v-model="OpenNewAccessForm" persistent max-width="80%">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-seal</v-icon> Add New Board Role
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="roleName"
                  :rules="nameRules"
                  autofocus
                  :counter="100"
                  label="Role Name *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>

                <v-checkbox
                  color="success"
                  v-model="is_clonned"
                  :label="`Clone from the Existing Roles`"
                >
                </v-checkbox>

                <div
                  v-if="is_clonned"
                  style="
                    width: 100%;
                    padding: 20px;
                    background-color: rgba(15, 98, 201, 0.11);
                  "
                >
                  <v-select
                    v-if="is_clonned"
                    v-model="existedRoles"
                    :items="roles"
                    item-text="roleName"
                    item-value="primaryKey"
                    attach
                    chips
                    label="Existing Roles"
                    multiple
                    :menu-props="{ left: true, offsetY: true }"
                    dense
                  ></v-select>
                </div>
              </v-col>

              <div v-if="!is_clonned" class="w-full">
                <div
                  class="accordion  rounded-md"
                  id="accordionExample"
                >
                  <div
                    class="accordion-item"
                    v-for="(
                      systemPermissionGroup, groupIndex
                    ) of permissiongroups"
                    :key="groupIndex"
                  >
                    <h2
                      class="accordion-header bg-primary/10 p-4 border-b pa-2"
                      :id="`heading${groupIndex}`"
                    >
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="`#collapse${groupIndex}`"
                        aria-expanded="true"
                        :aria-controls="`collapse${groupIndex}`"
                      >
                        {{ systemPermissionGroup.name }}
                      </button>
                    </h2>
                    <div
                      :id="`collapse${groupIndex}`"
                      class="accordion-collapse collapse show px-4 py-2"
                      :aria-labelledby="`heading${groupIndex}`"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div class="grid gap-4 grid-cols-3 grid-rows-3">
                          <div
                            class="flex items-center"
                            v-for="permission of systemPermissionGroup.children"
                            :key="permission.id+'permission'"
                          >
                          <v-checkbox
                            color="primary"
                              @change="
                                permissionChange(
                                  $event,
                                  permission
                                )
                              "
                              :id="`permission${permission.id}`"
                              :label="permission.name"
                            >

                          </v-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            outlined
            small
            class="white--text"
            @click="$emit('close')"
          >
            <!--            <v-icon>mdi-close</v-icon>-->
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            small
            class="white--text"
            :loading="loading"
            @click="creaNewRole"
          >
            <!--            <v-icon>mdi-check-all</v-icon>-->
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :z-index="zIndex" :value="overlay">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import allPermisionGroups from "../../../graphql/eboard-auth-graphql/allPermisionGroups.gql";
import allRolesPerBoard from "../../../graphql/eboard-auth-graphql/allRolesPerBoard.gql";

export default {
  components: {},

  data: () => ({
    roleName: "",
    loading: false,
    zIndex: 1,
    overlay: false,
    boardUniqueId: localStorage.getItem("my-boardUniqueId") || "",
    boardKey: localStorage.getItem("my-boardKey") || "",
    existedRoles: "",
    permissions: "",
    is_clonned: false,
    openLocationDialog: false,
    time: null,
    menu4: false,
    menu3: false,
    modal2: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    Tittle: "",
    nameRules: [
      (v) => !!v || "Role Name is required",
      (v) => v.length <= 100 || "Name must be less than 10 characters",
    ],
    roles: [],
    permissiongroups: [],
    rolePermissions:[]
  }),

  props: ["OpenNewAccessForm"],

  created() {
    this.$apollo
      .query({
        query: allRolesPerBoard,
        variables: {
          roleBoard: this.boardUniqueId,
        },
      })
      .then((response) => {
        this.roles = [...response.data.rolesPerBoard];
      });

    this.$apollo
      .query({
        query: allPermisionGroups,
      })
      .then((response) => {
        var groups_response = response.data.userPermissionsGroups.edges;
        groups_response.forEach((group) => {
          var group_id = group.node.permissionGroupUniqueId;
          var group_name = group.node.permissionGroupName;
          var group_children = [];

          group.node.permissionGroup.edges.forEach((children) => {
            group_children.push({
              id: children.node.primaryKey,
              name: children.node.permissionName,
            });
          });

          this.permissiongroups.push({
            id: group_id,
            name: group_name,
            children: group_children,
          });
        });
      });
  },

  methods: {
    permissionChange(checked, permission) {
      console.log(checked, permission.id);
      if (checked) {
        this.rolePermissions.push(permission.id);
      } else {
        this.rolePermissions.splice(this.rolePermissions.indexOf(permission.id), 1);
      }
      this.changed = true
    },



    async creaNewRole() {
      this.overlay = true;
      this.loading = true;
      var rolesDetails = {
        rolePermissions: this.rolePermissions,
        isCloned: this.is_clonned,
        roleName: this.roleName,
        roleBoard: localStorage.getItem("my-boardUniqueId"),
        roleClonedRoles: this.existedRoles,
        roleDescription: "",
      };
      await this.$store.dispatch("RolesStore/addNewRoles", rolesDetails, {
        root: true,
      });
      this.$emit("close");
    },
  },
};
</script>
