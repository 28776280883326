<template>
  <v-container>
    <v-dialog v-model="supportDoc" persistent width="60%">
      <v-card>
        <v-card-title class="text-h7 grey lighten-2">
          Select Document to Upload
        </v-card-title>

        <v-card-text>
          <BoardSupportingDocs :type="'directiveReport'" :meetingUniqueId="meetingUniqueId" @close="submitRepositoryDocuments"/>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              @click="submitRepositoryDocuments"
              color="primary"
              small
              text
              outlined
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BoardSupportingDocs from "./BoardSupportingDocs.vue";

export default {
  props: ["supportDoc", "meetingUniqueId"],
  components: { BoardSupportingDocs },

  data() {
    return {
      content: "",
      editorOption: {},
    };
  },
  methods: {
    async submitRepositoryDocuments() {
      this.$emit("closeSupportDocumentUploader");
      this.$store.commit('clearManagementUploadedRepositoryDocuments');
    },
  },
};
</script>

<style>
</style>
