<template>
  <v-container>
    <v-dialog v-model="directiveresponcedialog" persistent width="60%">
      <v-card>
        <v-card-title class="text-h7 grey lighten-2">
          Report Implementation on directive as {{ status }}
        </v-card-title>
        <v-card-text class="mt-4">
          <p><strong>Directive:</strong> {{ directive.directiveTitle }}</p>
        </v-card-text>
        <v-card-text>
          <Editor
            :content="content"
            :editorOption="editorOption"
            @onEditorChange="updateNoticeMessage($event)"
            style="height: 180px"
          />
        </v-card-text>
        <v-card-text>
          <CircularResolutionSupportingDocs :type="'directiveReport'" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            small
            text
            outlined
            @click="$emit('CloseActionProgress')"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="submitDirectiveProgress"
            color="primary"
            small
            text
            outlined
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Editor from "../../management_meeting_management/management_shared/fields/Editor.vue";
import CircularResolutionSupportingDocs from "../management-flyingminutes/CircularResolutionSupportingDocs.vue";

export default {
  props: ["directiveresponcedialog", "status", "directive", "from"],
  components: { Editor, CircularResolutionSupportingDocs },
  data() {
    return {
      content: "",
      editorOption: {},
    };
  },
  methods: {
    updateNoticeMessage(new_value) {
      this.content = new_value;
    },

    async submitDirectiveProgress() {
      await this.$store.dispatch("showLoadingSpinner", true);
      const docs = this.$store.state.ResolutionStore.progressReportDocs;
      const docs_list = [];

      docs.forEach((doc) => {
        docs_list.push({
          attachmentName: doc.fileName,
          attachmentPath: doc.fullFileName,
        });
      });

      const progress = {
        progressDirective: this.directive.directiveUniqueId,
        progressDescriptions: this.content,
        progressType: this.status,
        progressAttachment: docs_list,
        from: this.from
      };

      await this.$store.dispatch("reportManagementDirectivesProgress", progress);
      this.content = "";
      this.$emit("CloseActionProgress");
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>

<style>
</style>
