<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <div class="col-span-12 lg:col-span-3 2xl:col-span-2">
          <h2 class="intro-y text-lg font-medium mr-auto mt-2">
            Resolution Register
          </h2>
        </div>
        <v-spacer></v-spacer>
      <v-col class="d-flex" cols="6" sm="3">
        <v-text-field outlined dense v-model="searchQuery" append-icon="mdi-magnify" label="Search"></v-text-field>
      </v-col>
      <v-col class="d-flex" cols="6" sm="3">
        <v-autocomplete
        outlined
          v-model="boardResolution"
          :items="closedPublishedBoardResolutions"
          item-text="resolutionTitle"
          item-value="resolutionUniqueId"
          dense
          label="Filter by Resolution "
          @change="filterDecisions('resolution',$event)"
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="6" sm="3">
        <v-autocomplete
        outlined
          v-model="filterMeeting"
          :items="meetingsList"
          item-text="meetingTitle"
          item-value="primaryKey"
          dense
          label="Filter by Meeting"
          @change="filterDecisions('meeting',$event)"
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="6" sm="1">
        <v-btn @click="clearFilters()" outlined color="pink" icon><v-icon>mdi-close</v-icon></v-btn>
      </v-col>
      </v-col>
    </v-row>

    <div class="row">
      <div class="col-md-12 d-flex flex-justify-content-end">
        <v-btn outlined
               small rounded color="primary"
               @click="exportResolutionRegister">
          <v-icon color="indigo" class="mr-1">mdi-file-pdf-box</v-icon>
          Export
        </v-btn>
      </div>
    </div>
    <v-row>
      <v-col sm="12">
        <div
            v-for="decision in boardDecisions.data"
            :key="decision.primaryKey"
            style="padding: 10px"
        >
          <v-card class="mx-auto" max-width="100%" outlined>
            <v-card-title>
              {{ decision.decisionTitle }}
              <v-spacer></v-spacer>
              <v-chip>
                Source: {{ decision.decisionSource }}
              </v-chip>
            </v-card-title>

            <v-card-text>

              <div class="my-2" v-html="decision.decisionDescriptions"></div>

              <div class="my-4 text-subtitle-1">
                <strong>Meeting/Resolution: </strong>{{ decision.decisionMeeting }}
              </div>
            </v-card-text>

            <v-divider class="mx-1"></v-divider>

            <v-card-text>
              <v-card elevation="0" tile>
                <v-row>
                  <v-col sm="8">
                    <v-card-subtitle style="font-size: 16px">
                      <p v-if="decision.decisionOutcome === 'Approved' || decision.decisionOutcome === 'APR'">
                        <strong>Outcome: </strong>
                        <v-icon
                            color="success"
                        >
                          mdi-check-circle-outline
                        </v-icon
                        >
                        Approved
                      </p>
                      <p v-else>
                        <strong>Outcome: </strong>
                        <v-icon color="error">mdi-close-circle-outline
                        </v-icon
                        >
                        Not Approved
                      </p>
                      <p>
                        <strong>Decision Date: </strong
                        > {{ decision.decisionCreateddate|formatDate }}
                      </p>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </div>


        <v-card-actions v-if="boardDecisions.page">
          <v-btn
              @click="paginateDecisionRegister(boardDecisions.page.previousPageNumber)"
              v-if="boardDecisions.page.hasPreviousPage" outlined small
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Previous Page ({{ boardDecisions.page.previousPageNumber }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              @click="paginateDecisionRegister(boardDecisions.page.nextPageNumber)"
              v-if="boardDecisions.page.hasNextPage" outlined small
          >
            Next Page ({{ boardDecisions.page.nextPageNumber }})
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <DocumentViewer
        v-if="openFileDialog === true"
        :docValue="decisionUrl"
        :OpenfileDialog="openFileDialog"
        title="Decision Register"
        @CloseDocumentViwer="openFileDialog = false"
    />

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentViewer from "@/modules/meeting_management/eboard-documents/DocumentViewer.vue";

export default {
  components: {DocumentViewer},

  data() {
    return {
      openFileDialog: false,
      pageNumber: 1,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      menu: false,
      modal: false,
      modalTo: false,
      menuTo: false,
      filterType: null,
      tab: true,
      searchQuery:"All",
      boardResolution:'',
      filterMeeting:'',
      selectedMeeting: {primaryKey: "ALL", meetingTitle: "ALL"},
      selectedStatus: {id: 'ALL', name: 'ALL'},
      decisionStatus: [
        {id: 'ALL', name: 'ALL'}, {id: 'APR', name: 'APPROVED'}, {id: 'NOT', name: 'NOT APPROVED'}
      ],
    };
  },

  computed: {
    decisionUrl() {
      return `/${this.$store.state.DecisionStore.exportedBoardDecisions?.attachmentPath}`
    },
    closedPublishedBoardResolutions() {
      return this.$store.getters.closedCircularResolution;
    },
    boardDecisions() {
      var {data, page}=this.$store.state.DecisionStore.assignedDecisions;
      if (this.searchQuery=="All") {
        return {data, page}
      } else {
        if (!data) {
          return {data: [], page: {}}
        }
        let filteredData =  data.filter(decision => {
          return decision.decisionMeeting.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
        return {data: filteredData, page}
      }
    },
    ...mapGetters({
      meetingsList: "meetingsList",
    }),
    boardMeetingList() {
      return [{primaryKey: "ALL", meetingTitle: "ALL"}, ...this.$store.getters.meetingsList]
    }
  },

  methods: {
    async paginateDecisionRegister(pageNumber) {
      this.pageNumber = pageNumber
      await this.$store.dispatch("loadAssignedDecisions",{meetingUniqueId:null,resolutionUniqueId:null, pageNumber: this.pageNumber});
    },
    async exportResolutionRegister() {
      let input = {
        meetingUniqueId: this.selectedMeeting.primaryKey || this.selectedMeeting === 'ALL' ? null : this.selectedMeeting,
        decisionOutcome: this.selectedStatus.id || this.selectedStatus === 'ALL' ? null : this.selectedStatus
      }
      await this.$store.dispatch("loadExportedBoardDecisions",{...input, pageNumber: this.pageNumber});
      this.openFileDialog = true
    },
    async onFilterChange() {
      let input = {
        meetingUniqueId: this.selectedMeeting.primaryKey || this.selectedMeeting === 'ALL' ? null : this.selectedMeeting,
        decisionOutcome: this.selectedStatus.id || this.selectedStatus === 'ALL' ? null : this.selectedStatus
      }
      await this.$store.dispatch("loadAssignedDecisions", {...input, pageNumber: this.pageNumber});
    },
    async filterDecisions(filterType,event){
    this.$store.dispatch("showLoadingSpinner", true);
    this.filterType = filterType
    if (filterType === "meeting") {
      await this.$store.dispatch("loadAssignedDecisions",{meetingUniqueId:event,resolutionUniqueId:null, pageNumber: this.pageNumber});
    }else{
      await this.$store.dispatch("loadAssignedDecisions",{meetingUniqueId:null,resolutionUniqueId:event, pageNumber: this.pageNumber});
    }
    this.$store.dispatch("showLoadingSpinner", false);
    },

    async clearFilters(){
    this.$store.dispatch("showLoadingSpinner", true);
    this.searchQuery=''
    this.boardResolution=''
    this.filterMeeting=''
    await this.$store.dispatch("loadAssignedDecisions",{meetingUniqueId:null,resolutionUniqueId:null, pageNumber: this.pageNumber});
    this.$store.dispatch("showLoadingSpinner", false);
    }
  },
  async mounted() {
    this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("loadAssignedDecisions",{meetingUniqueId:null,resolutionUniqueId:null, pageNumber: 1});
    await this.$store.dispatch("loadBoardResolutions");
    await this.$store.dispatch("loadMeetingByBoardUniqueId");
    this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>

<style>
</style>
