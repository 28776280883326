<template>
  <div class="text-center">
    <v-dialog v-model="updatePositionDialog" width="500">
      <form class="validate-form" @submit.prevent="updateUserPosistions()" onsubmit="return false">
      <div class="box pa-5">
        <div v-if="updateType == 'board'">
          <v-radio-group v-model="boardMemberLevel">
            <template v-slot:label>
              <div
                style="font-size: 20px"
                class="flex items-center justify-center"
              >
              <div>Select New Position for {{ data.fullname }}</div>
              </div>
            </template>

            <v-radio :value="`CHR`">
              <template v-slot:label>
                <div>Board Chair</div>
              </template>
            </v-radio>
            <v-radio :value="`VICE`">
              <template v-slot:label>
                <div>Vice Chairperson</div>
              </template>
            </v-radio>
            <v-radio :value="`SEC`">
              <template v-slot:label>
                <div>Board Secretary</div>
              </template>
            </v-radio>
            <v-radio :value="`MEB`">
              <template v-slot:label>
                <div>Board Member</div>
              </template>
            </v-radio>
            <v-radio :value="`SECRT`">
              <template v-slot:label>
                <div>Board Secretariat / Officer</div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <div v-else>
          <v-radio-group v-model="managementMemberLevel">
            <template v-slot:label>
              <div>Select New Position for {{ data.fullname }}</div>
            </template>

            <v-radio :value="`CHR`">
              <template v-slot:label>
                <div>Chairperson</div>
              </template>
            </v-radio>
            <v-radio :value="`DIR`">
              <template v-slot:label>
                <div>Director</div>
              </template>
            </v-radio>
            <v-radio :value="`MAN`">
              <template v-slot:label>
                <div>Manager</div>
              </template>
            </v-radio>
            <v-radio :value="`OFF`">
              <template v-slot:label>
                <div>Officer</div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <div class="flex justify-end">
          <v-btn
            @click="updatePositionDialog = !updatePositionDialog"
            small
            color="pink"
            class="ml-1"
            style="text-transform: capitalize"
            outlined
            >Close</v-btn
          >
          <v-btn
            small
            color="primary"
            class="ml-1"
            style="text-transform: capitalize"
            outlined
            type="submit"
            >Save</v-btn
          >
        </div>
      </div>
      </form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["data", "updatePositionDialog", "updateType"],
  data(){return{
      boardMemberLevel:null,
      managementMemberLevel:null,
  }},
  methods:{
    async updateUserPosistions(){
      this.$store.dispatch("showLoadingSpinner", true);
      var position_type = null
      if (this.updateType=='board'){
        position_type = this.boardMemberLevel
      }
      else{
        position_type = this.managementMemberLevel
      }

      var input = {
          "input": {
            "userId": this.data.id,
            "newPostion": position_type,
            "positionType": this.updateType,
            "boardUniqueId": localStorage.getItem("my-boardUniqueId")
          }
        }
      await this.$store.dispatch('updateUserPostion',input)
      await this.$store.dispatch('allBoardUsers')
      this.updatePositionDialog = false
      this.$store.dispatch("showLoadingSpinner", false);
    }
  }
};
</script>

<style></style>
