<template>
  <v-row>
    <v-dialog
        v-model="viewDefaultRoleDialog"
        max-width="70%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <icon class="pt-1 mr-3" name="role" width="w-5" height="h-5" color="#000"></icon><span class="text-h6">Role Details</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                      outlined
                      disabled
                      v-model="roleName"
                      :counter="100"
                      label="Role Name *"
                      prepend-icon="mdi-text-short"
                      required >
                  </v-text-field>

                  <v-text-field
                      outlined
                      disabled
                      v-model="roleDescription"
                      :counter="100"
                      label="Role Name *"
                      prepend-icon="mdi-text-short"
                      required >
                  </v-text-field>

                  <v-checkbox
                      disabled
                      v-model="roleIsAdmin"
                      :label="`Is Admin`"
                      color="success"
                      class="mr-2"
                  ></v-checkbox>

                  <v-col md="12" cols="12">
                    <v-expansion-panels multiple class="mb-0">
                      <v-expansion-panel>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          Assigned Permissions List
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-container fluid>
                            <v-row>
                              <v-col
                                  v-for="permission in rolePermissions"
                                  :key="permission.id"
                                  cols="12"
                                  sm="3"
                                  md="3"
                                  lg="6"
                              >
                                <v-checkbox
                                    disabled
                                    :label="permission.permissionName"
                                    color="info"
                                    :input-value="permission.permissionName"
                                    hide-details
                                    @change="changeCurrentPermission(permission.id)"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                    </v-expansion-panels>
                  </v-col>

                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="$emit('close-dialog')"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Close</span>
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ViewDefaultRole",

  props: [
      "roleUniqueId",
      "roleName",
      "roleDescription",
      "rolePermissions",
      "permissiongroups",
      "roleIsAdmin",

      "viewDefaultRoleDialog"
  ]
}
</script>

<style scoped>

</style>