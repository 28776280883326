<template>
  <v-container fluid>
    <div class="p-5">
      <div class="text-base font-medium truncate">
        {{ invitation.invitationGreetings }},
        {{ invitation.inviteeName }}
      </div>
      <div class="text-slate-400 mt-1">
        {{ invitation.invitationDate | formatFull }}
      </div>
      <div class="text-slate-500 text-justify mt-1">
        <span v-html="invitation.invitationBody"></span>
      </div>
      <div class="font-medium flex mt-5">
        <button
          type="button"
          @click="dialog = true"
          class="btn btn-danger py-1 px-2"
        >
          Apology for Invitation
        </button>
        <button
          type="button"
          @click="submitApologyResponce(true)"
          class="btn btn-success py-1 text-white cursor-pointer px-2 ml-auto ml-auto"
        >
          Accept Invitation
        </button>
      </div>
    </div>



    <v-dialog v-model="dialog" persistent width="700">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Send an Apology Response
        </v-card-title>

        <v-card-text>
          <Editor
            :content="content"
            :editorOption="editorOption"
            @onEditorChange="onEditorChange($event)"
            style="height: 180px"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text outlined @click="dialog = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            outlined
            @click="submitApologyResponce(false)"
            :loading="submitting"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Editor from "../e_board_shared/fields/Editor.vue";

export default {
  props: ["invitation"],

  components: { Editor },

  data() {
    return {
      dialog: false,
      content: "",
      editorOption: "Reason for Apology",
      submiting: false,
    };
  },

  methods: {
    onEditorChange(new_values) {
      console.log(new_values);
      this.content = new_values;
    },
    async submitApologyResponce(noticeAccepted) {
      await this.$store.dispatch("showLoadingSpinner", true);
      const data = {
        noticeAccepted: noticeAccepted,
        noticeMeeting: this.invitation.invitationMeeting,
        noticeReplyExplanations: this.content,
      };
      await this.$store.dispatch("replyMeetingNotice", data);
      this.dialog = false;
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>
