<template>
  <div class="text-center">
    <v-dialog v-model="endVoteDialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          <v-icon color="info">mdi-vote</v-icon> Vote Outcome
        </v-card-title>

        <v-card-text v-if="results.voteOutcome === 'Approved'">
          <v-layout justify-center>
            <v-card-title
              style="color: #46a36b; font-weight: bold"
              class="text-h5"
            >
              <v-btn icon color="success" outlined
                ><v-icon>mdi-check-decagram</v-icon></v-btn
              >
              <span> {{ results.voteOutcome }}</span>
            </v-card-title>
          </v-layout>
        </v-card-text>

        <v-card-text v-if="results.voteOutcome === 'Not Approved'">
          <v-layout justify-center>
            <v-card-title
              style="color: #c2446b; font-weight: bold"
              class="text-h5"
            >
              <v-btn icon color="pink" outlined
                ><v-icon>mdi-check-decagram</v-icon></v-btn
              >
              <span> {{ results.voteOutcome }}</span>
            </v-card-title>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-layout justify-center> The vote has not been passed </v-layout>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn small text @click="setVoteManually(agendaItem)">
            Manually enter the outcome
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small outlined color="error" @click="$emit('CloseVoteResults')">
            Close
          </v-btn>
          <v-btn small outlined color="success" @click="acceptVoteResults">
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["endVoteDialog", "results", "selectedAgenda"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    async acceptVoteResults() {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("saveDecisionsMinutes", {
        decisionContents: this.results.voteBody,
        decisionDate: "",
        decisionOutcome: this.results.voteOutcome,
        decisionTitle: this.results.voteTitle,
        decisionItemKey: this.selectedAgenda.itemKey,
      });
      this.content = "";
      this.dateFormatted = "";
      this.decisionOutcome = "";
      this.decisionTittle = "";
      this.$emit("CloseVoteResults");
      this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>
