<template>
  <v-expansion-panels>
    <v-expansion-panel    v-for="direDeadline in upcomingDeadlines" :key="direDeadline">
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="4">
            {{direDeadline.title}}
          </v-col>
          <v-col
            cols="8"
            class="text--secondary"
          >
            <v-fade-transition leave-absolute>
              <span v-html="direDeadline.descriptions" v-if="open">
              </span>
              <v-row
                v-else
                no-gutters
                style="width: 100%"
              >
                <v-spacer></v-spacer>
                <v-col cols="6">
                  Due date:  <strong>{{ direDeadline.dueDate |formatFull }}</strong>
                </v-col>
              </v-row>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row
          justify="space-around"
          no-gutters
        >

          <v-col cols="3">
              <!-- <v-menu offset-y app>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn  text small style="text-transform: capitalize; font-size:14px" v-bind="attrs" v-on="on" slot="activator"   color="indigo"  >
                          <v-icon small left>mdi-pencil-remove-outline</v-icon>
                          Report Implementation
                          </v-btn>
                  </template>
                  <v-list dense>
                      <v-list-item link v-for="(status, index) in action_status_list" :key="index"  >
                          <v-list-item-title @click="addAtendees(status,index)"><v-icon :color="status.iconColor" small left>{{status.icon}}</v-icon> {{status.status}}</v-list-item-title>
                      </v-list-item  >
                  </v-list>
              </v-menu>  -->
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  export default {
    props:['upcomingDeadlines'],
    data: () => ({
        openvalue:false,
        switch1:'',
        readColor:'success',
        date: null,
        trip: {
            name: '',
            location: null,
            start: null,
            end: null,
        },
        locations: ['Australia', 'Barbados', 'Chile', 'Denmark', 'Ecuador', 'France'],
        action_status_list:[
          {iconColor:'red',icon:'mdi-circle-outline',status:'Not Started'},
          {iconColor:'primary',icon:'mdi-sync',status:'In Progress'},
          {iconColor:'orange',icon:'mdi-pause-circle-outline',status:'On Hold'},
          {iconColor:'success',icon:'mdi-check',status:'Done'},
        ]        
    }),
  }
</script>

<style>

</style>