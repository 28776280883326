<template>
  <v-banner outlined type="">
    <v-chart class="chart" :option="option" />
  </v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: ["dataObject"],
  name: "HelloWorld",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {};
  },
  computed: {
    option() {
      var labes = [];
      var data = [];
      var coloPillet = [
        "rgba(201, 176, 94, 0.93)",
        "rgba(104, 94, 201, 0.93)",
        "rgba(98, 146, 204, 0.93)",
        "rgba(204, 98, 183, 0.93)",
      ];
      this.dataObject.meetingsByLocations.forEach((dataPck, index) => {
        labes.push(dataPck.name);
        data.push({
          value: dataPck.value,
          itemStyle: {
            color: coloPillet[index],
            borderWidth: 3,
          },
        });
      });
      return {
        title: {
          text: 'Meeting per Locations',
          top: "left",
        },
        xAxis: {
          type: "category",
          data: labes,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: data,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(58, 39, 245, 0.16)",
            },
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>



