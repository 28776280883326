var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',[_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","md1":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.hasAccess('can_add_timetable'))?_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"right":"","fab":"","small":"","color":"primary white--text"},on:{"click":function($event){_vm.showContextMenu = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Add Board Event")])]),_c('AddNewEvent',{attrs:{"showContextMenu":_vm.showContextMenu},on:{"closeNewEventDialog":function($event){_vm.showContextMenu = !_vm.showContextMenu}}}),_c('EditEvent',{attrs:{"showEditEvent":_vm.showEditEvent,"event-unique-id":_vm.eventUniqueId,"board-unique-id":_vm.boardUniqueId,"event-name":_vm.eventName,"event-description":_vm.eventDescription,"event-start-date":_vm.eventStartDate,"event-end-date":_vm.eventEndDate,"event-end-time":_vm.eventEndTime,"event-start-time":_vm.eventStartTime,"send-reminder":_vm.sendReminder,"event-color":_vm.eventColor.color,"reminder-message":_vm.reminderMessage},on:{"close-dialog":function($event){_vm.showEditEvent=false}}})],1)],1),_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"primary darken-2"},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-spacer'),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"primary darken-2"},on:{"click":_vm.next}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_vm._v(" Filter by: "),_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Day")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Week")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Month")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-system-bar',{attrs:{"dark":"","color":"primary","height":"50"}},[_c('h1',{staticClass:"white--text fonts-20"},[_vm._v("Event Details")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"fab":"","small":"","outlined":""},on:{"click":function($event){return _vm.editEventBoard(_vm.selectedEvent)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-calendar-edit")])],1)]}}])},[_c('span',[_vm._v("Edit Calendar Event")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"white","link":"","to":'/eboard_calender_event_activities/' +
                      _vm.selectedEvent.eventUniqueId,"fab":"","small":"","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":""}},[_vm._v("mdi-calendar-check")])],1)]}}])},[_c('span',[_vm._v("Add Board Activity")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"white","fab":"","small":"","outlined":""},on:{"click":function($event){return _vm.deleteEventBoard(_vm.selectedEvent.eventUniqueId)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1)]}}])},[_c('span',[_vm._v("Delete Calendar Event")])])],1),_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"500px","flat":""}},[_c('div',{staticClass:"intro-y box p-5 mt-1 sm:mt-1"},[_c('p',[_c('strong',[_vm._v("Name:")]),_vm._v(" "+_vm._s(_vm.selectedEvent.name))]),_c('p',[_c('strong',[_vm._v("Descriptions:")]),_vm._v(" "+_vm._s(_vm.selectedEvent.eventDescription)+" ")]),_c('p',[_c('strong',[_vm._v("Start Date:")]),_vm._v(" "+_vm._s(_vm._f("formatFull")(_vm.selectedEvent.start))+" ")]),_c('p',[_c('strong',[_vm._v("End Date:")]),_vm._v(" "+_vm._s(_vm._f("formatFull")(_vm.selectedEvent.end))+" ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }