import SettingsDashboard from './eboard_clients/ClientDashboard.vue'
import UserManagement from './UserManagement.vue'
import ClientsList from './eboard_clients/ClientsList'
import SystemMain from './SystemMain.vue'
import MainSetting from './eboard_settings/MainSetting.vue'
import BoardMeetingCategory from './eboard_settings/BoardMeetingCategory.vue'
import BoardMeetingLocation from './eboard_settings/BoardMeetingLocation.vue'
import BoardMemberDesignation from './eboard_settings/BoardMemberDesignation.vue'
import Committees from '../meeting_management/eboard-committees/CommitteesDashboard.vue'
import Members from '../meeting_management/eboard-boards/MembersList.vue'
import EditUserProfile from "@/modules/system_management/eboard_auth/EditUserProfile";
import ManagementCommittee from "../management_committee/management_committee_committee/ManagementCommittee.vue";




export default [
    {
        path: '/settings',
        name:'SystemMain',
        component: SystemMain,
        meta:{requiresAuth:true},
        children: [
            {
                path: 'dashboard',
                name:'SettingsDashboardMain',
                component: SettingsDashboard
            },
            {
                path: 'user_management',
                name:'UserManagementMain',
                component: UserManagement
            },
            {
                path: 'eboard_clients',
                name:'ClientsList',
                component: ClientsList
            },
            {
                path: 'eboard_system_settings',
                name:'MainSetting',
                component: MainSetting
            },

            {
                path: 'eboard_committees',
                name:'Committees',
                component: Committees
            },
            {
                path: 'management_committees',
                name:'ManagementCommittees',
                component: ManagementCommittee
            },

            {
                path: 'eboard_meetings_category',
                name:'MeetingCategory',
                component: BoardMeetingCategory
            },
            {
                path: 'eboard_meetings_location',
                name:'MeetingLocation',
                component: BoardMeetingLocation
            },
            {
                path: 'eboard_meetings_designations',
                name:'MeetingDesignations',
                component: BoardMemberDesignation
            },

            {
                path: 'eboard_members',
                name:'Members',
                component: Members
            },

            {
                path: 'user_profile/:user_id',
                name: 'EditUserProfile',
                component: EditUserProfile,
                meta:{requiresAuth:true},
            },

        ]
    }
]

