import { apolloClient } from '@/vue-apollo'
import startVideoCalling from '../../graphql/eboard-videochattin-graphql/startVideoCalling.gql'
import saveVideoMeetingDetails from '../../graphql/eboard-videochattin-graphql/saveVideoMeetingDetails.gql'
import shareMeetingVideoUrl from '../../graphql/eboard-videochattin-graphql/shareMeetingVideoUrl.gql'
import getMeetingVideoCallDetails from '../../graphql/eboard-videochattin-graphql/getMeetingVideoCallDetails.gql'
import swal from "sweetalert2";


export default {
    state: {
        callInvitations: [],
        callLogs: null,
        meetingVideoDetails: null,
    },

    mutations: {
        cleanVotes(state) {
            state.activeVotes = []
        },
        addVotes(state, vote) {
            state.activeVotes.push(vote)
        },
        saveVideoDetails(state, details) {
            state.meetingVideoDetails=details
        },
    },

    getters: {
        getMyCallLogs(state) {
            return state.activeVotes
        },
        getMyCallInvitation(state) {
            return state.enumsResponding
        },
        getMeetingVideoDetails(state) {
            return state.meetingVideoDetails
        }
    },

    actions: {
        async loadActiveCalls(context) {
            context.commit('cleanVotes')
            // await apolloClient.query({
            //     fetchPolicy: 'no-cache',
            //     query: boardVotes,
            //     variables: {
            //         boardUniqueId: localStorage.getItem("my-boardUniqueId"),
            //     }
            // }).then((response) => {
            //     const votes = response.data.boardVotes
            //     votes.forEach(vote => {
            //         context.commit('addVotes', vote)

            //     });
            // })
        },
        

        async shareVideoLinktoInvitees(context,videoData) {
            await apolloClient.mutate({
                mutation: shareMeetingVideoUrl,
                variables: {
                    input: videoData,
                }
            }).then((response) => {
                if (response.data.shareMeetingVideoUrl.response.status) {
                    swal.fire({
                      toast: true,
                      icon: "success",
                      title: "Video Meeting shared successfully",
                      animation: false,
                      position: "top-right",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                } else {
                    swal.fire({
                      toast: true,
                      icon: "error",
                      title: "Fail to share video link to invitees",
                      animation: false,
                      position: "top-right",
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                }

            })
        },

        async saveVideoMeetingDetails(context,videoData) {
            await apolloClient.mutate({
                mutation: saveVideoMeetingDetails,
                variables: {
                    meetingUniqueId: videoData.meetingUniqueId,
                    videoCallModeratorUrl: videoData.videoCallModeratorUrl,
                    videoCallParticipantToken: videoData.videoCallParticipantToken,
                    videoCallType: videoData.videoCallType,
                }
            }).then((response) => {
                const details = response.data.saveVideoMeetingDetails.videoDetails
                context.commit('saveVideoDetails', details)
            })
        },
        async getVideoMeetingDetails(context,meetingUniqueId) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getMeetingVideoCallDetails,
                variables: {
                    meetingUniqueId: meetingUniqueId,
                }
            }).then((response) => {
                const details = response.data.getMeetingVideoCallDetails
                context.commit('saveVideoDetails', details)
            })
        },

        async startVideoCalling(context, data) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: startVideoCalling,
                variables: {
                    newVideoCall: data,
                }
            }).then((response) => {
                if (response.data.startVideoCalling.videoStarted) {
                    let height = window.outerHeight;
                    var actualSize = height * (90 / 100);
                    window.open(
                        response.data.startVideoCalling.redirectedVideoUrl,
                        "e-Board Call",
                        "top=200,bottom=300,left=500,width=" + actualSize + ",height=600"
                    );

                }else{
                    console.log('Nothing');
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },
    }
}

