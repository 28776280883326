import {apolloClient} from '@/vue-apollo'
import createAgendaFromTemplate from '../../graphql/management-agendas-graphql/createAgendaFromTemplate.gql'
import meetingAgendas from '../../graphql/management-agendas-graphql/meetingAgendas.gql'
import updateManagementAgendaItemsDetails from '../../graphql/management-agendas-graphql/updateAgendaItemsDetails.gql'
import switchAgendaItemPresenter from '../../graphql/management-agendas-graphql/switchAgendaItemPresenter.gql'
import updateAgendaItem from '../../graphql/management-agendas-graphql/updateAgendaItem.gql'
import updateAgenda from '../../graphql/management-agendas-graphql/updateAgenda.gql'
import addAgendaItem from '../../graphql/management-agendas-graphql/addAgendaItem.gql'
import addAgenda from '../../graphql/management-agendas-graphql/addAgenda.gql'
import publishMeetingAgendas from '../../graphql/management-agendas-graphql/publishMeetingAgendas.gql'
import deleteAgenda from '../../graphql/management-agendas-graphql/deleteAgenda.gql'
import deleteManagementAgendaItem from '../../graphql/management-agendas-graphql/deleteAgendaItem.graphql'
import allMeetingsCombinedAgendas from '../../graphql/management-agendas-graphql/allMeetingsCombinedAgendas.gql'
import getMeetingsVottingAgendasItems from '../../graphql/management-agendas-graphql/getMeetingsVottingAgendasItems.gql'
import addVottingAgendaItem from '../../graphql/management-agendas-graphql/addVottingAgendaItem.gql'
import addConfirmMinutesAgendaItem from '../../graphql/management-agendas-graphql/addConfirmMinutesAgendaItem.gql'
import createAgendaCloneMeeting from '../../graphql/management-agendas-graphql/createAgendaCloneMeeting.gql'
import confirmMinutesAgendas from '../../graphql/management-agendas-graphql/confirmMinutesAgendas.graphql'
import moveManagementMeetingAgenda from '../../graphql/management-agendas-graphql/moveManagementMeetingAgenda.gql'
import addManagementAgendaItemToSpecificPosition
  from '../../graphql/management-agendas-graphql/addManagementAgendaItemToSpecificPosition.gql'
import moveManagementMeetingAgendaItem
  from '../../graphql/management-agendas-graphql/moveManagementMeetingAgendaItem.gql'
import unPublishManagementMeetingAgendas from "../../graphql/management-agendas-graphql/UnPublishManagementMeetingAgenda.graphql";

import swal from 'sweetalert2'
import {errorAlert, successAlert} from "@/services/notificationService";


export default {
  state: {
    listOfManagementAgendas: [],
    selectedManagementAgenda: null,
    combinedManagementAgendas: ''
  },

  mutations: {
    cleanManagementAgendas(state) {
      state.listOfManagementAgendas = []
    },

    addManagementAgendas(state, agenda) {
      state.listOfManagementAgendas.push(agenda)
    },

    firstupdateManagementAgendas(state) {
      state.selectedManagementAgenda = state.listOfManagementAgendas[0].AgendaItems[0]
    },

    updateManagementAgendas(state, item) {
      state.selectedManagementAgenda = item
    },

    updateManagementAgendaWithSelected(state, item) {
      state.listOfManagementAgendas[0].AgendaItems.push(item)
      state.selectedManagementAgenda = item
    },

    updateManagementSelectedItem(state, details) {
      state.listOfManagementAgendas.forEach(agenda => {
        agenda.AgendaItems.forEach(item => {
          if (item.itemKey === details.itemUniqueId) {
            item.itemNumber = details.itemNumber
            item.itemKey = details.itemUniqueId
            item.itemName = details.itemTitle
            item.status = details.itemStatus
          }
        });

      });
    },

    updateManagementSelectedItemDetails(state, details) {
      state.listOfManagementAgendas.forEach(agenda => {
        agenda.AgendaItems.forEach(item => {
          if (item.itemDetails.itemKey === details.detailsUniqueId) {
            state.selectedManagementAgenda = {
              itemPuporse: details.detailsPuporse,
              itemDuration: details.detailsDuration,
              itemKey: details.detailsUniqueId
            }
          }
        });
      });
    },

    updateSelectedManagementAgenda(state, details) {
      state.listOfManagementAgendas.forEach(agenda => {
        if (agenda.agendaKey === details.agendaUniqueId) {
          agenda.Agenda = details.agendaTitle
        }
      });
    },

    addNewManagementAgendaItem(state, itemObject) {
      state.listOfManagementAgendas.forEach(agenda => {
        if (agenda.primaryKey === itemObject.agendaKey) {
          agenda.AgendaItems.push(itemObject.item)
          state.selectedManagementAgenda = itemObject.item
        }
      });
    },

    cleanSelectedManagementAgendas(state) {
      state.selectedManagementAgenda = null
    },

    setCombinedManagementAgendas(state, path) {
      state.combinedManagementAgendas = path
    },

    setSelectedManagementAgenda(state, data) {
      state.selectedManagementAgenda = data.selectedManagementAgenda;
      state.listOfManagementAgendas[0].AgendaItems = data.AgendaItems;
    }
  },

  getters: {
    getManagementMeetingAgendas(state) {
      return state.listOfManagementAgendas
    },

    getManagementSelectedAgenda(state) {
      return state.selectedManagementAgenda
    },
  },

  actions: {
    async creatManagementMeetingAgendaFromTemplate(context, currentMeeting) {
      context.commit('cleanSelectedManagementAgendas')
      await apolloClient.mutate({
        mutation: createAgendaFromTemplate,
        variables: {
          meetingUniqueId: currentMeeting
        }
      }).then(() => {
        context.dispatch('loadManagementBestPracticeAgendaTemplates', currentMeeting)
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async createManagementMeetingCloneAgendas(context, meetingObj) {
      context.commit('cleanSelectedManagementAgendas')
      await apolloClient.mutate({
        mutation: createAgendaCloneMeeting,
        variables: {
          meetingUniqueId: meetingObj.currentMeeting,
          cloneMeetingUniqueid: meetingObj.cloneMeeting
        }
      }).then(() => {
        context.dispatch('loadManagementBestPracticeAgendaTemplates', meetingObj.currentMeeting)
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async loadManagementBestPracticeAgendaTemplates(context, currentMeeting) {
      context.commit('cleanManagementAgendas')
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: meetingAgendas,
        variables: {
          meetingUniqueId: currentMeeting
        }
      }).then((response) => {
        const agendas = response.data.allManagementMeetingsAgendas.edges

        agendas.forEach(agenda => {
          var agendanode = agenda.node
          var agendaItem = []

          agendanode.managementAgendaItem.edges.forEach(item => {
            var agendaItemnode = item.node
            var agendaItemDetails = agendaItemnode.managementItemDetails.edges[0].node
            var documentDetailsList = []
            agendaItemDetails.managementDocumentDetails.edges.forEach(file => {
              documentDetailsList.push(
                {'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
              )
            });
            agendaItem.push(
              {
                "itemNumber": agendaItemnode.itemNumber,
                "itemKey": agendaItemnode.itemUniqueId,
                "itemName": agendaItemnode.itemTitle,
                "status": agendaItemnode.itemStatus,
                "itemType": agendaItemnode.itemType,
                "itemDetails": {
                  "itemKey": agendaItemDetails.detailsUniqueId,
                  "itemName": agendaItemnode.itemTitle,
                  "itemPuporse": agendaItemDetails.detailsPuporse,
                  "itemDuration": agendaItemDetails.detailsDuration,
                  "itemPresenter": agendaItemnode.itemPresenter ? agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName : "No Presenter",
                  'documentDetails': documentDetailsList
                }
              }
            )
          })

          var agendaObj = {
            "AgendaNumber": agendanode.agendaNumber,
            "primaryKey": agendanode.primaryKey,
            "agendaKey": agendanode.agendaUniqueId,
            "Agenda": agendanode.agendaTitle,
            "AgendaItems": agendaItem
          }

          context.commit('addManagementAgendas', agendaObj)
        });
        context.commit('firstupdateManagementAgendas')
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async updateManagementSelectedAgendaItem(context, item) {
      context.commit('cleanSelectedManagementAgendas')
      if (item.itemType === "ConfirmMinutes") {
        await apolloClient.query({
          fetchPolicy: 'no-cache',
          query: confirmMinutesAgendas,
          variables: {
            agendaItemUniqueId: item.itemKey
          }
        }).then((response) => {
          var agendaItemnode = response.data.allManagementMeetingConfirmMinutesAgendas

          var documentDetailsList = []
          agendaItemnode.managementItemDetails.edges[0].node.managementDocumentDetails.edges.forEach(file => {
            documentDetailsList.push(
              {'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
            )
          });
          var itemObject = {
            "itemNumber": agendaItemnode.itemNumber,
            "itemKey": agendaItemnode.itemUniqueId,
            "itemName": agendaItemnode.itemTitle,
            "status": agendaItemnode.itemStatus,
            "itemType": agendaItemnode.itemType,
            "itemMinutesDetails": agendaItemnode.managementCombinedMinutesDocumentItem,
            "itemDetails": {
              "itemKey": agendaItemnode.managementItemDetails.edges[0].node.detailsUniqueId,
              "itemName": agendaItemnode.itemTitle,
              "itemPuporse": agendaItemnode.managementItemDetails.edges[0].node.detailsPuporse,
              "itemDuration": agendaItemnode.managementItemDetails.edges[0].node.detailsDuration,
              "itemPresenter": agendaItemnode.itemPresenter ? agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName : "No Presenter",
              'documentDetails': documentDetailsList
            }
          }
          context.commit('updateManagementAgendas', itemObject)
        }).catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.log(error);
        })
      } else if (item.itemType === "Vote") {
        await apolloClient.query({
          fetchPolicy: 'no-cache',
          query: getMeetingsVottingAgendasItems,
          variables: {
            uniqueId: item.itemKey
          }
        }).then((response) => {
          const voteItem = response.data.getManagementMeetingsVottingAgendasItems;

          const documentDetailsList = [];
          voteItem.managementItemDetails.edges[0].node.managementDocumentDetails.edges.forEach(file => {
            documentDetailsList.push(
              {'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
            )
          });
          var itemObject = {
            "itemNumber": voteItem.itemNumber,
            "itemKey": voteItem.itemUniqueId,
            "itemName": voteItem.itemTitle,
            "status": voteItem.itemStatus,
            "itemType": voteItem.itemType,
            "itemVoteDetails": voteItem.managementVoteAgendaItem,
            "itemDetails": {
              "itemKey": voteItem.managementItemDetails.edges[0].node.detailsUniqueId,
              "itemName": voteItem.itemTitle,
              "itemPuporse": voteItem.managementItemDetails.edges[0].node.detailsPuporse,
              "itemDuration": voteItem.managementItemDetails.edges[0].node.detailsDuration,
              "itemPresenter": voteItem.itemPresenter.firstName + " " + voteItem.itemPresenter.lastName || "No Presenter",
              'documentDetails': documentDetailsList
            }
          }
          context.commit('updateManagementAgendas', itemObject)
        }).catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.log(error);
        })

      } else {
        context.commit('updateManagementAgendas', item)
      }
    },

    async updateManagementAgendaItem(context, item) {
      context.commit('cleanSelectedManagementAgendas')
      await apolloClient.mutate({
        mutation: updateAgendaItem,
        variables: {
          itemKey: item.itemKey,
          updatePlace: item.updatePlace,
          newItem: item.newItem
        }
      }).then((response) => {
        context.commit('updateManagementSelectedItem', response.data.updateManagementAgendaItem.agendaItem[0])
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async updateManagementAgendaItemsDetails(context, itemDetails) {
      context.commit('cleanSelectedManagementAgendas')
      await apolloClient.mutate({
        mutation: updateManagementAgendaItemsDetails,
        variables: {
          newPurpose: itemDetails.newPurpose,
          detailKey: itemDetails.detailKey,
          updatePlace: itemDetails.updatePlace
        }
      }).then(async (result) => {
        let {response, data} = result.data.updateManagementAgendaItemsDetails;

        if (response.status) {
          let agendaItem = []
          let selectedItem = {};

          data.managementAgendaItem.edges.forEach(item => {
            const agendaItemnode = item.node;
            const agendaItemDetails = agendaItemnode.managementItemDetails.edges[0].node;
            let documentDetailsList = [];
            agendaItemDetails.managementDocumentDetails.edges.forEach(file => {
              documentDetailsList.push(
                {'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
              )
            });

            if (agendaItemnode.itemUniqueId === itemDetails.detailKey) {
              selectedItem = {
                "itemNumber": agendaItemnode.itemNumber,
                "itemKey": agendaItemnode.itemUniqueId,
                "itemName": agendaItemnode.itemTitle,
                "status": agendaItemnode.itemStatus,
                "itemType": agendaItemnode.itemType,
                "itemDetails": {
                  "itemKey": agendaItemDetails.detailsUniqueId,
                  "itemName": agendaItemnode.itemTitle,
                  "itemPuporse": agendaItemDetails.detailsPuporse,
                  "itemDuration": agendaItemDetails.detailsDuration,
                  "itemPresenter": agendaItemnode.itemPresenter ? agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName : "No Presenter",
                  'documentDetails': documentDetailsList
                }
              }
            }

            agendaItem.push({
              "itemNumber": agendaItemnode.itemNumber,
              "itemKey": agendaItemnode.itemUniqueId,
              "itemName": agendaItemnode.itemTitle,
              "status": agendaItemnode.itemStatus,
              "itemType": agendaItemnode.itemType,
              "itemDetails": {
                "itemKey": agendaItemDetails.detailsUniqueId,
                "itemName": agendaItemnode.itemTitle,
                "itemPuporse": agendaItemDetails.detailsPuporse,
                "itemDuration": agendaItemDetails.detailsDuration,
                "itemPresenter": agendaItemnode.itemPresenter ? agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName : "No Presenter",
                'documentDetails': documentDetailsList
              }
            })

            context.commit("setSelectedManagementAgenda", {
              selectedManagementAgenda: selectedItem,
              AgendaItems: agendaItem,
            });
          })
        }
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async moveManagementMeetingAgenda(context, agenda) {
      await apolloClient.mutate({
        mutation: moveManagementMeetingAgenda,
        variables: {
          agendaUniqueId: agenda.agendaUniqueId,
          position: agenda.position,
        }
      }).then((response) => {
        if (response.data.moveManagementMeetingAgenda.success) {
          swal.fire({
            toast: true,
            icon: 'success',
            title: 'Agenda moved successfully',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        } else {
          swal.fire({
            toast: true,
            icon: 'error',
            title: ' Failed to move Agenda',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        }
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async switchMAnagementAgendaItemsPresenter(context, member) {
      await apolloClient.mutate({
        mutation: switchAgendaItemPresenter,
        variables: {
          itemKey: member.itemKey,
          itemPresenter: member.member,
        }
      })
      console.log(context);
    },

    async updateManagementAgenda(context, agenda) {
      await apolloClient.mutate({
        mutation: updateAgenda,
        variables: {
          agendaTitle: agenda.newItem,
          agendaUniqueId: agenda.agendaKey
        }
      }).then((response) => {
        context.commit('updateSelectedManagementAgenda', response.data.updateAgenda.meetingAgenda[0])
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async addManagementAgendaItem(context, agenda) {
      context.commit('cleanSelectedManagementAgendas')
      await apolloClient.mutate({
        mutation: addAgendaItem,
        variables: {
          agendaKey: agenda.agendaKey,
          itemLastPosition: agenda.Position
        }
      }).then((response) => {
        var agendaItemnode = response.data.addManagementAgendaItem.agendaItem
        var itemObject = {
          "itemNumber": agendaItemnode.itemNumber,
          "itemKey": agendaItemnode.itemUniqueId,
          "itemName": agendaItemnode.itemTitle,
          "status": agendaItemnode.itemStatus,
          "itemType": agendaItemnode.itemType,
          "itemDetails": {
            "itemKey": agendaItemnode.managementItemDetails.edges[0].node.detailsUniqueId,
            "itemName": agendaItemnode.itemTitle,
            "itemPuporse": agendaItemnode.managementItemDetails.edges[0].node.detailsPuporse,
            "itemDuration": agendaItemnode.managementItemDetails.edges[0].node.detailsDuration,
            "itemPresenter": "No Presenter",
          }
        }
        context.commit('addNewManagementAgendaItem', {item: itemObject, agendaKey: agenda.agendaKey})
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })

    },

    async addManagementNewVotingAgendaItem(context, agenda) {
      context.commit('cleanSelectedManagementAgendas')
      await apolloClient.mutate({
        mutation: addVottingAgendaItem,
        variables: {
          agendaKey: agenda.agendaKey,
          itemLastPosition: agenda.Position
        }
      }).then((response) => {
        var voteItem = response.data.addManagementVottingAgendaItem.agendaItem

        var documentDetailsList = []
        voteItem.managementItemDetails.edges[0].node.managementDocumentDetails.edges.forEach(file => {
          documentDetailsList.push(
            {'fileName': file.node.documentName, 'fullFileName': file.node.documentPath}
          )
        });

        var itemObject = {
          "itemNumber": voteItem.itemNumber,
          "itemKey": voteItem.itemUniqueId,
          "itemName": voteItem.itemTitle,
          "status": voteItem.itemStatus,
          "itemType": voteItem.itemType,
          "itemVoteDetails": voteItem.managementVoteAgendaItem,
          "itemDetails": {
            "itemKey": voteItem.managementItemDetails.edges[0].node.detailsUniqueId,
            "itemName": voteItem.itemTitle,
            "itemPuporse": voteItem.managementItemDetails.edges[0].node.detailsPuporse,
            "itemDuration": voteItem.managementItemDetails.edges[0].node.detailsDuration,
            "itemPresenter": voteItem.itemPresenter.firstName + " " + voteItem.itemPresenter.lastName || "No Presenter",
            'documentDetails': documentDetailsList
          }
        }
        context.commit('addNewManagementAgendaItem', {item: itemObject, agendaKey: agenda.agendaKey})
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })

    },

    async addManagementAgenda(context, agenda) {
      context.commit('cleanSelectedManagementAgendas')
      await apolloClient.mutate({
        mutation: addAgenda,
        variables: {
          meetingKey: agenda.meetingKey,
          agendaLastPosition: agenda.Position
        }
      }).then((response) => {
        var agendanode = response.data.addManagementAgenda.agenda
        var agendaObj = {
          "AgendaNumber": agendanode.agendaNumber,
          "primaryKey": agendanode.primaryKey,
          "agendaKey": agendanode.agendaUniqueId,
          "Agenda": agendanode.agendaTitle,
          "AgendaItems": []
        }
        context.dispatch('addManagementAgendas', agendaObj)
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async publishManagementAgendas(context, agenda) {
      await apolloClient.mutate({
        mutation: publishMeetingAgendas,
        variables: {
          meetingKey: agenda.meetingKey,
        }
      }).then((result) => {
        let {response, data} = result.data.publishManagementMeetingAgendas;

        if (response.status) {
          context.commit('setCombinedManagementAgendas', data);
          context.commit("setPublishMeetingAgenda");
          successAlert('Agenda published successfully');
        } else {
          errorAlert(response.message)
        }
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async deleteManagementAgendas(context, agenda) {
      await apolloClient.mutate({
        mutation: deleteAgenda,
        variables: {
          meetingKey: agenda.meetingKey,
          agendaKey: agenda.agendaKey,
        }
      }).then(() => {
        swal.fire({
          toast: true,
          icon: 'success',
          title: 'Agenda deleted successfully',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async deleteManagementAgendaItem(context, agenda) {
      await apolloClient.mutate({
        mutation: deleteManagementAgendaItem,
        variables: {
          agendaKey: agenda.agendaKey,
          agendaItemUniqueId: agenda.agendaItemKey,
        }
      }).then(() => {
        swal.fire({
          toast: true,
          icon: 'success',
          title: 'Agenda Item deleted successfully',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async previewManagementAgendas(context, meetingKey) {
      await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allMeetingsCombinedAgendas,
        variables: {
          meetingUniqueId: meetingKey
        }
      }).then((response) => {
        context.commit('setCombinedManagementAgendas', response.data.allManagementMeetingsCombinedAgendas.combinedPath)
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async addManagementUnConfirmMeetingsMinutesAgendaItem(context, agenda) {
      context.commit('cleanSelectedManagementAgendas')
      await apolloClient.mutate({
        mutation: addConfirmMinutesAgendaItem,
        variables: {
          selectedAgenda: agenda.selectedAgenda,
          lastMeeting: agenda.lastMeeting
        }
      }).then((response) => {
        var agendaItemnode = response.data.addManagementConfirmMinutesAgendaItem.agendaItem
        var itemObject = {
          "itemNumber": agendaItemnode.itemNumber,
          "itemKey": agendaItemnode.itemUniqueId,
          "itemName": agendaItemnode.itemTitle,
          "status": agendaItemnode.itemStatus,
          "itemType": agendaItemnode.itemType,
          "itemMinutesDetails": agendaItemnode.managementCombinedMinutesDocumentItem,
          "itemDetails": {
            "itemKey": agendaItemnode.managementItemDetails.edges[0].node.detailsUniqueId,
            "itemName": agendaItemnode.itemTitle,
            "itemPuporse": agendaItemnode.managementItemDetails.edges[0].node.detailsPuporse,
            "itemDuration": agendaItemnode.managementItemDetails.edges[0].node.detailsDuration,
            "itemPresenter": agendaItemnode.itemPresenter ? agendaItemnode.itemPresenter.firstName + " " + agendaItemnode.itemPresenter.lastName : "No Presenter",
          }
        }
        context.commit('updateManagementAgendaWithSelected', itemObject)
        // context.rootState.ManagementMeetingsStore.selectedManagementMeetingDetails.meetingHasConfirmMinutes = true
        // context.commit('addNewManagementAgendaItem', {item: itemObject, agendaKey: agenda.selectedAgenda})
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async addManagementAgendaItemToSpecificPosition(context, agenda) {
      await apolloClient.mutate({
        mutation: addManagementAgendaItemToSpecificPosition,
        variables: {
          itemUniqueId: agenda.itemUniqueId,
        }
      }).then((response) => {
        if (response.data.addManagementAgendaItemToSpecificPosition.success) {
          swal.fire({
            toast: true,
            icon: 'success',
            title: 'Agenda Item added successfully',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        } else {
          swal.fire({
            toast: true,
            icon: 'error',
            title: ' Failed to add Agenda Item',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        }
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async moveManagementMeetingAgendaItem(context, agenda) {
      await apolloClient.mutate({
        mutation: moveManagementMeetingAgendaItem,
        variables: {
          itemUniqueId: agenda.itemUniqueId,
          position: agenda.position,
        }
      }).then((response) => {
        if (response.data.moveManagementMeetingAgendaItem.success) {
          swal.fire({
            toast: true,
            icon: 'success',
            title: 'Agenda Item moved successfully',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        } else {
          swal.fire({
            toast: true,
            icon: 'error',
            title: ' Failed to move Agenda Item',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
        }
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

    async unPublishManagementMeetingAgendas(context, meetingUniqueId) {
      await context.dispatch("showLoadingSpinner", true);
      await apolloClient.mutate({
        mutation: unPublishManagementMeetingAgendas,
        variables: {
          meetingUniqueId: meetingUniqueId,
        }
      }).then((result) => {
        if (result.data.unPublishManagementMeetingAgendas.response.status) {
          context.commit("setUnPublishMeetingAgenda");
          successAlert("Meeting Agenda un published successfully");
        } else {
          errorAlert(result.data.unPublishManagementMeetingAgendas.response.message);
        }
        context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.log(error);
      })
    },

  }
}


