import {apolloClient} from '@/vue-apollo'
import getAllManagementCommitteeMeetings
    from '../../graphql/management-committee-meeting-graphql/getAllManagementCommitteeMeetings.gql'
import createManagementCommitteeMeetingMutation
    from '../../graphql/management-committee-meeting-graphql/createManagementCommitteeMeetingMutation.gql'
import closeManagementCommitteeMeetingMutation
    from '../../graphql/management-committee-meeting-graphql/closeManagementCommitteeMeetingMutation.graphql'
import updateManagementCommitteeMeetingMutation
    from '../../graphql/management-committee-meeting-graphql/updateManagementCommitteeMeetingMutation.gql'

import publishManagementCommitteeAgendaMutation
    from '../../graphql/management-committee-meeting-graphql/publishManagementCommitteeAgendaMutation.gql'
import previewManagementCommitteeMinutesMutation
    from '../../graphql/management-committee-meeting-graphql/previewManagementCommitteeMinutesMutation.gql'

import unPublishManagementCommitteeAgendaMutation
    from '../../graphql/management-committee-meeting-graphql/unPublishManagementCommitteeAgendaMutation.gql'

import {errorAlert, successAlert} from "../../services/notificationService";


export default {
    state: {
        managementCommitteeMeetings: [],
        selectedManagementCommitteeMeeting: {},
        selectedManagementCommitteeMeetingMinutes: '',
    },
    getters: {
        getManagementCommitteeMeetings(state) {
            return state.managementCommitteeMeetings
        },
        getSelectedManagementCommitteeMeetings(state) {
            return state.selectedManagementCommitteeMeeting
        },
        getSelectedManagementCommitteeMeetingMinutes(state) {
            return state.selectedManagementCommitteeMeetingMinutes
        },
    },
    mutations: {
        clearManagementCommitteeMeetings(state) {
            state.managementCommitteeMeetings = []
        },
        saveManagementCommitteeMeetings(state, meetings) {
            state.managementCommitteeMeetings = [...meetings]
        },
        setSelectedManagementCommitteeMeetings(state, meeting) {
            state.selectedManagementCommitteeMeeting = meeting[0]
        },
        setSelectedManagementCommitteeMeetingMinutes(state, minutes) {
            state.selectedManagementCommitteeMeetingMinutes = minutes
        },
        addManagementCommitteeMeeting(state, meeting) {
            state.managementCommitteeMeetings.push(meeting)
        },
        updateManagementCommitteeMeeting(state, meeting) {
            state.managementCommitteeMeetings = state.managementCommitteeMeetings.map((managementMeeting) => {
                return managementMeeting.id === meeting.id ? meeting : managementMeeting
            })
            state.selectedManagementCommitteeMeeting = meeting
        },
        closeManagementCommitteeMeeting(state, closedMeeting) {
            state.selectedManagementCommitteeMeeting = {}
            let closedMeetingIndex = state.managementCommitteeMeetings.findIndex((meeting) => meeting.id === closedMeeting.meetingUniqueid)
            if (closedMeetingIndex >= 0) state.managementCommitteeMeetings.splice(closedMeetingIndex, 1)
        },
        publishUnpublishManagementCommitteeMeeting(state, input) {
            state.selectedManagementCommitteeMeeting = {
                ...state.selectedManagementCommitteeMeeting,
                meetingHasPublishedAgenda: input.publishStatus,
                meetingDocumentPath: input.publishStatus ? input.doc : null
            }
        }
    },
    actions: {
        clearManagementCommitteeMeetings(context) {
            context.commit('clearManagementCommitteeMeetings')
        },
        async loadManagementCommitteeMeetings(context, input = null) {
            context.commit('clearManagementCommitteeMeetings')
            console.log(input?.meetingUniqueId)
            let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (managementCommitteeUniqueId !== "") {
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: getAllManagementCommitteeMeetings,
                    variables: {
                        committeeUniqueId: input ? (input.meetingUniqueId ? null : managementCommitteeUniqueId) : managementCommitteeUniqueId,
                        meetingUniqueId: input?.meetingUniqueId,
                        meetingAgendaPublished: input ? input.meetingAgendaPublished : null
                    }
                }).then(async (response) => {
                    let result = response.data.getAllManagementCommitteeMeetings
                    if (result.response.code === 9000) {
                        input?.meetingUniqueId ? context.commit('setSelectedManagementCommitteeMeetings', result.data) : context.commit('saveManagementCommitteeMeetings', result.data)
                    } else {
                        await errorAlert(result.response.message)
                    }
                }).finally(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
            }
        },
        async previewManagementCommitteeMinutes(context, meetingUniqueId = null) {
            let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (managementCommitteeUniqueId !== "") {
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: previewManagementCommitteeMinutesMutation,
                    variables: {
                        input: {
                            meetingUniqueId
                        }
                    }
                }).then(async (response) => {
                    let result = response.data.previewManagementCommitteeMinutesMutation
                    if (result.response.code === 9000) {
                        context.commit('setSelectedManagementCommitteeMeetingMinutes', result.data)
                    } else {
                        errorAlert(result.response.message)
                    }
                }).finally(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
            }
        },
        async createManagementCommitteeMeeting(context, input) {
            let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (managementCommitteeUniqueId !== "") {
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: createManagementCommitteeMeetingMutation,
                    variables: {
                        input: {
                            ...input,
                            meetingCommittee: managementCommitteeUniqueId
                        }
                    }
                }).then(async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.createManagementCommitteeMeetingMutation
                    if (result.response.code === 9000) {
                        context.commit('addManagementCommitteeMeeting', result.data)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },
        async editManagementCommitteeMeeting(context, input) {
            let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (managementCommitteeUniqueId !== "") {
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: updateManagementCommitteeMeetingMutation,
                    variables: {
                        input: {
                            ...input,
                            meetingCommittee: managementCommitteeUniqueId
                        }
                    }
                }).then(async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.updateManagementCommitteeMeetingMutation
                    if (result.response.code === 9000) {
                        console.log(result.data)
                        context.commit('updateManagementCommitteeMeeting', result.data)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },
        async closeManagementCommitteeMeeting(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: closeManagementCommitteeMeetingMutation,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.closeManagementCommitteeMeetingMutation
                if (result.response.code === 9000) {
                    context.commit('closeManagementCommitteeMeeting', input)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async publishManagementCommitteeMeetingAgendas(context, meetingUniqueId) {
            let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (managementCommitteeUniqueId !== "") {
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: publishManagementCommitteeAgendaMutation,
                    variables: {
                        input: {
                            agendaMeeting: meetingUniqueId
                        }
                    }
                }).then(async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.publishManagementCommitteeAgendaMutation
                    if (result.response.code === 9000) {
                        context.commit('publishUnpublishManagementCommitteeMeeting', {
                            meetingUniqueId,
                            doc: result.data,
                            publishStatus: true
                        })
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },
        async unPublishManagementCommitteeMeetingAgendas(context, meetingUniqueId) {
            let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (managementCommitteeUniqueId !== "") {
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: unPublishManagementCommitteeAgendaMutation,
                    variables: {
                        input: {
                            agendaMeeting: meetingUniqueId
                        }
                    }
                }).then(async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.unPublishManagementCommitteeAgendaMutation
                    if (result.response.code === 9000) {
                        context.commit('publishUnpublishManagementCommitteeMeeting', {
                            meetingUniqueId,
                            doc: result.data,
                            publishStatus: false
                        })
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                })
            }
        },

    },


}


