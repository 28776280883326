import {apolloClient} from '@/vue-apollo'
import meetingsCategories from '../../graphql/eboard-meetings-graphql/meetingsCategories.gql'
import createMeetingCategory from '../../graphql/eboard-settings-graphql/createMeetingCategory.gql'
import updateMeetingCategory from "../../graphql/eboard-settings-graphql/updateMeetingCategory.gql"
import deleteMeetingCategory from "../../graphql/eboard-settings-graphql/deleteMeetingCategory.gql"
import swal from "sweetalert2";


export default {
    state: {
        boardMeetingCategories: [],
    },

    mutations: {
        cleanCategories(state) {
            state.boardMeetingCategories = []
        },
        addCategories(state, categoryObj) {
            state.boardMeetingCategories.push(categoryObj)
        }
    },

    actions: {
        async loadBoardMeetingCategories(context) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingsCategories,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const categories = response.data.boardMeetingsCategories
                context.commit('cleanCategories')

                let count = 1;
                categories.forEach(category => {
                    // if (category.categoryType === "Board Meetings") {
                        const categoryObj = {
                            sn: count,
                            key: category.primaryKey,
                            title: category.categoryTittle,
                            type: category.categoryType,
                            uniqueId: category.categoryUniqueId,
                            date: category.categoryDate,
                            active: category.categoryIsActive,
                            special: category.categoryIsSpecial,
                            is_editable: category.categoryIsEditable,
                        };

                        context.commit('addCategories', categoryObj)
                        count++
                    // }
                });
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async addNewMeetingCategory(context, category) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: createMeetingCategory,
                variables: {
                    category: category
                }
            }).then((response) => {
                const categories = response.data.createMeetingCategory
                context.commit('addCategories', categories)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateMeetingCategory(context, category) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: updateMeetingCategory,
                variables: {
                    categoryName: category.categoryName,
                    categoryType: category.categoryType,
                    categoryBoard: category.categoryBoard,
                    categoryManagement: category.categoryManagement,
                    isSpecialCommittee: category.isSpecialCommittee,
                    categoryUniqueId: category.categoryUniqueId,
                }
            }).then((response) => {
                const saved = response.data.updateMeetingCategory.response.saved
                context.dispatch("showLoadingSpinner", false);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteMeetingCategory(context, categoryKey) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deleteMeetingCategory,
                variables: {
                    categoryUniqueId: categoryKey
                }
            }).then((response) => {
                const saved = response.data.deleteMeetingCategory.response.saved
                context.dispatch("showLoadingSpinner", false);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        }
    }
}
