<template>
  <v-row justify="center">
    <v-dialog v-model="showContextMenu" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-account-group-outline</v-icon> Add a meeting</span
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="meetingTittle"
                  :rules="nameRules"
                  :counter="100"
                  label="Title *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>

                <v-select
                  v-model="meetingCategory"
                  :items="meetingCategories"
                  @change="meetingCategorySelected"
                  item-text="title"
                  item-value="key"
                  label="Meeting Category *"
                  required
                  prepend-icon="mdi-shape-outline"
                >
                </v-select>

              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="meetingDate"
                      label="Date *"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="meetingDate"
                    no-title
                    scrollable
                    :min="date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-dialog
                  ref="dialog1"
                  v-model="modal1"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startTime"
                      label="Start Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-time-picker v-if="modal1" v-model="startTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-dialog ref="dialog" v-model="modal2" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endTime"
                      label="End Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="modal2"
                    v-model="endTime"
                    :min="startTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                  return-object
                  :items="locations"
                  item-text="location"
                  label="Location *"
                  required
                  prepend-icon="mdi-map-marker"
                  v-model="defaultSelected"
                  :hint="timezone"
                  persistent-hint
                  @change="getSelectedTimeZone"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip text-color="" small>{{ item.location }}</v-chip>
                  </template>
                </v-select>
              </v-col>

              <v-btn
                x-small
                color="primary"
                text
                @click="openLocationDialog = !openLocationDialog"
                v-show="!hidden"
                ><v-icon>mdi-plus</v-icon> Add a Meeting Location</v-btn
              >
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            @click="$emit('closeNewMeeitngDialog')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="blue darken-1"
            fab
            small
            class="white--text"
            @click="createNewBoardMeeting"
            :disabled="loading"
            :loading="loading"
          >
            <v-icon>mdi-send-check-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <LocationSettings
      :title="`Add New Location`"
      :action="`Add`"
      :openLocationDialog="openLocationDialog"
      @closeNewLocationDialog="openLocationDialog = !openLocationDialog"
    />

    <v-overlay :z-index="zIndex" :value="overlay">
      <v-card color="indigo" dark width="300">
        <v-card-text>
          We are Setting Up Meeting Please wait . . .
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>
  </v-row>
</template>


<script>
import LocationSettings from "../../system_management/eboard_settings/LocationSettings.vue";

export default {
  props: ["showContextMenu", "isCommittees"],
  components: { LocationSettings },
  data: () => ({
    meetingCommittees: [],
    overlay: false,
    zIndex: 0,
    hidden: false,
    checkbox: false,
    loading: false,
    openLocationDialog: false,
    timezone: "",
    defaultSelected: "",
    selectedLocations: [],
    time: null,
    menu4: false,
    menu3: false,
    modal2: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    dialog1: false,
    modal1: false,
    meetingDate: "",
    startTime: "",
    meetingCategory: "",
    endTime: "",
    meetingTittle: "",
    nameRules: [
      (v) => !!v || "Meeting title is required",
      (v) => v.length <= 100 || "Title must be less than 100 characters",
    ],
    createdMeeting: "",
  }),

  async mounted() {
    await this.$store.dispatch("SettingsStore/loadBoardMeetingLocations", {
      root: true,
    });
    await this.$store.dispatch("loadBoardMeetingCategories");
    await this.$store.dispatch("activeBoardCommittees");
  },
  computed: {
    boardCommitees() {
      return this.$store.state.CommitteeStore.boardCommiteesOnly;
    },
    meetingCategories() {
      console.log(this.$store.state.CategoriesStore.boardMeetingCategories)
      return this.$store.state.CategoriesStore.boardMeetingCategories.filter(c => c.special === this.isCommittees);
    },
    locations() {
      return this.$store.state.SettingsStore.boardLocations;
    },
    boardCommitteeUniqueId() {
      return localStorage.getItem("my-boardCommitteeUniqueId")
    }
  },
  methods: {
    getSelectedTimeZone() {
      this.timezone = this.defaultSelected.locationTimezone;
      console.log(this.timezone);
    },

    async createNewBoardMeeting() {
      await this.$store.dispatch("showLoadingSpinner", true);
      const meeting = {
        meetingTitle: this.meetingTittle,
        meetingDate: this.meetingDate,
        meetingStartTime: this.startTime,
        meetingEndTime: this.endTime,
        meetingLocation: this.defaultSelected.id,
        meetingHasInvitations: false,
        meetingCategory: this.meetingCategory,
        meetingCommittees: this.isCommittees ? [this.boardCommitteeUniqueId] : [],
      };
      await this.$store.dispatch("createBoardMeeting", meeting);
      await this.$store.dispatch("loadBoardMeetingsList");
      await this.$store.dispatch("loadBoardCommitteesMeetingsList");
      this.meetingTittle = null;
      this.meetingDate = null;
      this.startTime = null;
      this.endTime = null;
      this.meetingCategory = null;
      this.defaultSelected = null;
      this.meetingCommittees = null;
      this.$emit("closeNewMeeitngDialog");
      await this.$store.dispatch("showLoadingSpinner", false);
    },
    async meetingCategorySelected() {
      const meetingCategories = await this.$store.state.CategoriesStore
        .boardMeetingCategories;

      if (meetingCategories.length > 0) {
        meetingCategories.forEach((category) => {
          if (category.key === this.meetingCategory) {
            this.isCommittees = category.special;
          }
        });
      }
    },
  },
};
</script>
