<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12">
        <v-chip label outlined style="width: 100%">
          <h4>Select Category from which you want to view directives.</h4>
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" md="4" sm="6">
        <v-card
          class="mx-auto my-12"
          shaped
          outlined
          link
          max-width="300"
          to="/management/management_directives"
        >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <center>
            <v-img width="80%" src="/directive.png"></v-img>
            <h4 style="margin-bottom:10px;font-size:15px"><strong>Management Directives</strong></h4>
          </center>
        </v-card>
      </v-col>

      <v-col cols="3" md="4" sm="6">
        <v-card
          class="mx-auto my-12"
          shaped
          outlined
          link
          max-width="300"
          to="/management/management_board_directives"
        >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <center>
            <v-img width="80%" src="/directive.png"></v-img>
            <h4 style="margin-bottom:10px;font-size:15px"><strong>Board Directives</strong></h4>
          </center>
        </v-card>
      </v-col>

      <v-col cols="3" md="4" sm="6">
        <v-card
          class="mx-auto my-12"
          shaped
          outlined
          link
          max-width="300"
          to="/management/management_reports_directives"
        >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <center>
            <v-img width="80%" src="/directive.png"></v-img>
            <h4 style="margin-bottom:10px;font-size:15px"><strong>Reports Directives</strong></h4>
          </center>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    if (localStorage.getItem("refresh") === "true") {
      localStorage.setItem("refresh", "false");
      this.$router.go();
    }
  },
};
</script>

<style></style>
