<template>
  <v-container fluid app>
    <v-row  class="mt-5">
      <v-col class="text-lg font-weight-medium"> News and Updates Dashboard</v-col>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="openNewsUpdateDialog=true"
              v-bind="attrs"
              v-on="on"
              color="indigo"
              class="mx-2"
              dark
              outlined
              small
          >
            <v-icon small>mdi-plus</v-icon> &nbsp; Create News</v-btn
          >
        </template>
        <span>Created News are saved as draft by default</span>
      </v-tooltip>
      <AddnewUpdate
          :openNewsUpdateDialog="openNewsUpdateDialog"
          @closeNewUpdateDialog="openNewsUpdateDialog = !openNewsUpdateDialog"
      />
    </v-row>
    <v-layout>
      <v-container fluid>
        <v-row>
          <v-col md="4" sm="4">
            <v-card elevation="3" link @click="openPublishedDialog = true">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 ">
                    PUBLISHED : <strong> {{ getTotalPublishedNews.length }} </strong>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar tile size="80">
                  <img src="@/assets/news.png" alt="upload"
                /></v-list-item-avatar>
              </v-list-item>
            </v-card>
            <ViewPublished
              :openPublishedDialog="openPublishedDialog"
              @closePublishedDialog="openPublishedDialog = !openPublishedDialog"
            />
          </v-col>

          <v-col md="4" sm="4">
            <v-card elevation="3" link @click="openDraftDialog = true">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 mb-1">
                    DRAFTS : <strong> {{ getTotalDraftNews.length }} </strong>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar tile size="80">
                  <img src="@/assets/news.png" alt="upload"
                /></v-list-item-avatar>
              </v-list-item>
            </v-card>
            <ViewDraft
              :open-draft-dialog="openDraftDialog"
              @closeDraftDialog="openDraftDialog=false"
            />
          </v-col>

          <v-col md="4" sm="4">
            <v-card elevation="3" link @click="openUnpublishedDialog = true">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 mb-1">
                    UN-PUBLISHED : <strong> {{getTotalUnPublishedNews.length}} </strong>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar tile size="80">
                  <img src="@/assets/news.png" alt="unpublish"
                /></v-list-item-avatar>

              </v-list-item>
            </v-card>
            <ViewUnpublished
              :openUnpublishedDialog="openUnpublishedDialog"
              @closeUnpublishedDialog="openUnpublishedDialog = false"
              @click="viewUnpublished"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
    <v-container fluid>
    </v-container>
  </v-container>
</template>

<script>
import AddnewUpdate from "./AddNewUpdate.vue";
import ViewPublished from "./ViewPublished.vue";
import ViewDraft from "./ViewDraft.vue";
import ViewUnpublished from "./ViewUnpublished.vue";

export default {
  components: {
    AddnewUpdate,
    ViewPublished,
    ViewDraft,
    ViewUnpublished,
  },
  data() {
    return {
      openNewsUpdateDialog: false,
      openPublishedDialog: false,
      openDraftDialog: false,
      openUnpublishedDialog: false,
    };
  },

  methods: {
    async viewUnpublished(){
      await this.$store.dispatch('loadBoardUnpublishedNewsUpdates')
      this.openUnpublishedDialog = true
    }
  },

  computed:{
    getTotalDraftNews(){
      return this.$store.getters.boardNewsDraftedList
    },

    getTotalPublishedNews(){
      return this.$store.getters.boardNewsPublishedList
    },

    getTotalUnPublishedNews(){
      return this.$store.getters.boardNewsUnPublishedList
    }
  },

  mounted() {
    this.$store.dispatch('loadBoardPublishedNewsUpdates')
    this.$store.dispatch('loadBoardDraftNewsUpdates')
    this.$store.dispatch('loadBoardUnpublishedNewsUpdates')
  }
};
</script>

<style>
img {
    position: relative;
    display: block;
    float: left;
    width: 260px;
    height: 200px;
}
</style>