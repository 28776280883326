<template>
  <div class="text-center">
    <v-dialog
      v-model="OpenTableEditor"
      persistent
      width="80%"
    >
      <v-card>
        <v-card-title class="text-h6  lighten-3">
        <v-icon left >mdi-table-large-plus</v-icon>  {{ActionTitle}} 
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
          <ReportTableEditor           
            :tableBodyData="tableBodyData"
            :editorOption="editorOption" 
            @tableContentChanges="onEditorChange"
            style="height: 580px"
          />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseTableEditor')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn
            color="success"
            outlined
            small
            @click="createTable"
          >
            <v-icon small>mdi-floppy</v-icon> Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>




</template>

<script>
import ReportTableEditor from './ReportTableEditor.vue'
import createSection from '../../../graphql/eboard-report-builder-graphql/createSection.gql'
// import updateSection from '../../../graphql/eboard-report-builder-graphql/updateSection.gql'



export default {
  props:['OpenTableEditor','tableHeaderData','tableBodyData', 'ActionTitle','componentTemplateId'],
  components: {
    ReportTableEditor
  },
  data: () => ({
        content: '',
        tableTitle:'',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: '',
        menu1: false,
        editorOption:{
        placeholder: 'Write Contents here . . . ',
        theme: 'snow',
        toolbar:"minimal"
        }
  }),
      watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },
    },

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
  mounted(){
    this.tableTitle=this.tableHeaderData
    this.content=this.tableBodyData
  },

  methods:{
    onEditorChange(event){
      console.log(event);
      this.content=event
    },
    formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day,month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    createTable(){
      this.submiting=true
      this.$apollo.mutate({
        mutation:createSection,
        variables:{
          componentTitle:'',
          componentBody:this.content,
          componentTemplateId:this.componentTemplateId,
          componentIsTable:true,
        }
      }).then((response)=>{
        console.log(response);
        setTimeout(() => {
          this.$emit('afterCreateSection',response)
        }, 2000);
      })
    },

  },


}
</script>