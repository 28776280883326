import {apolloClient} from '@/vue-apollo'
import managementMeetingByManagementUniqueId from '../../graphql/management-meetings-graphql/managementMeetingByManagementUniqueId.gql'
import creatingMeeting from '../../graphql/management-meetings-graphql/creatingMeeting.gql'
import meetingDetails from '../../graphql/management-meetings-graphql/meetingDetails.gql'
import unConfirmMeetingByInstitution from '../../graphql/management-meetings-graphql/unConfirmMeetingByInstitution.gql'
import managementMeetingAttendees from '../../graphql/management-attendees-graphql/meetingAttendees.gql'
import switchingAttendees from '../../graphql/management-attendees-graphql/switchingAttendees.gql'
import addMeetingAttandees from "@/graphql/management-attendees-graphql/addMeetingAttandees.gql";
// import updateManagementMeeting from "../../graphql/management-meetings-graphql/updateManagementMeeting.gql";
import updateManagementMeeting from "../../graphql/management-meetings-graphql/updateManagementMeeting.graphql";
import addManagementMeetingUrl from "../../graphql/management-meetings-graphql/addManagementMeetingUrl.graphql";
import shareManagementMeetingUrl from "../../graphql/management-meetings-graphql/shareManagementMeetingUrl.graphql";
import updateManagementAttendeesMeetingPositionMutation from "../../graphql/management-meetings-graphql/updateManagementAttendeesMeetingPositionMutation.graphql";
import {errorAlert, successAlert} from "@/services/notificationService";
import swal from 'sweetalert2'

export default {
  state: {
    managementMeetingsList: [],
    selectedManagementMeetingDetails: {},
    managementMeetingAttendees: [],
    unConfirmManagementMeetings: [],
  },

  mutations: {
    cleanManagementMeetings(state) {
      state.managementMeetingsList = []
    },
    addManagementMeetings(state, meetingObj) {
      state.managementMeetingsList.push(meetingObj)
    },
    setManagementMeetings(state, data) {
      state.managementMeetingsList = [];
      state.managementMeetingsList = data;
    },
    cleanSelectedMeetingDetails(state) {
      state.selectedManagementMeetingDetails = null
    },
    setSelectedManagementMeetingDetails(state, meetingDetail) {
      state.selectedManagementMeetingDetails = meetingDetail
    },
    cleanManagementMeetingAttendees(state) {
      state.managementMeetingAttendees = []
    },
    setManagementMeetingAttendees(state, attendees) {
      state.managementMeetingAttendees.push(attendees)
    },
    switchManagementMeetingAttendees(state, attendees) {
      var attendee = state.managementMeetingAttendees.find(attend => {
        return attend.id === attendees.data
      })
      attendee.attendanceAcceptInvitation = attendees.side
    },
    addManagementUnConfirmMeetings(state, meetingObject) {
      state.unConfirmManagementMeetings.push(meetingObject)
    },
    cleanManagementUnConfirmMeetings(state) {
      state.unConfirmManagementMeetings = []
    },
    setPublishMeetingAgenda(state) {
      state.selectedManagementMeetingDetails.meetingHasPublishedAgenda = true;
    },
    setUnPublishMeetingAgenda(state) {
      state.selectedManagementMeetingDetails.meetingHasPublishedAgenda = false;
    }
  },

  getters: {
    managementMeetingsList(state) {
      return state.managementMeetingsList
    },
    getManagementMeetingDetails(state) {
      return state.selectedManagementMeetingDetails
    },
    getManagementMeetingAttendees(state) {
      return state.managementMeetingAttendees.filter(attendee => {
        return attendee.attendanceAcceptInvitation
      })
    },
    getManagementMeetingApologiesAttendees(state) {
      return state.managementMeetingAttendees.filter(attendee => {
        return !attendee.attendanceAcceptInvitation
      })
    },
    getManagementUnconfirmedMeetings(state) {
      return state.unConfirmManagementMeetings;
    }
  },

  actions: {
    async loadManagementMeetingsList(context, filterObj) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: managementMeetingByManagementUniqueId,
        variables: {
          managementUniqueId: filterObj.managementUniqueId,
          meetingHasConducted: filterObj.meetingHasConducted,
        }
      }).then(async(result) => {
        const {response , data} = result.data.managementMeetingByManagementUniqueId;

        if (response.status) {
          context.commit('setManagementMeetings', data.reverse());
        } else {
          await errorAlert(response.message)
        }
        await context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })

    },

    async createManagementMeeting(context, input) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient.mutate({
        mutation: creatingMeeting,
        variables: {input}
      }).then(async (result) => {
        const {response, data} = result.data.createManagementMeeting;

        if (response.status) {
          context.commit("addManagementMeetings", data);
          await successAlert("Meeting has been created successfully");
        } else
          await errorAlert(response.message);

        await context.dispatch("showLoadingSpinner", false);

      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },

    async updateManagementMeeting(context, input) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient.mutate({
        mutation: updateManagementMeeting,
        variables: {input}
      }).then(async (result) => {
        const {response, data} = result.data.updateManagementMeeting;

        if (response.status) {
          context.commit("setSelectedManagementMeetingDetails", data);
          await successAlert("Meeting has been updated successfully");
        } else
          await errorAlert(response.message);

        await context.dispatch("showLoadingSpinner", false);

      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },

    async addManagementMeetingUrl(context, input) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient.mutate({
        mutation: addManagementMeetingUrl,
        variables: {
          input
        }
      }).then(async (result) => {
        const {response, data} = result.data.addManagementMeetingUrl;

        if (response.status) {
          context.commit("setSelectedManagementMeetingDetails", data);
          await successAlert(response.message);
        } else
          await errorAlert(response.message);

        await context.dispatch("showLoadingSpinner", false);

      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },

    async shareManagementMeetingUrlToParticipants(context, input) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient.mutate({
        mutation: shareManagementMeetingUrl,
        variables: {
          input
        }
      }).then(async (result) => {
        const {response} = result.data.shareManagementMeetingUrl;
        if (response.status) {
          await successAlert(response.message);
        } else
          await errorAlert(response.message);

        await context.dispatch("showLoadingSpinner", false);

      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async fetchingManagementMeetingDetails(context, currentMeeting) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: meetingDetails,
        variables: {
          meetingUniqueId: currentMeeting
        }
      }).then(async(result) => {
        const {response, data} = result.data.getManagementMeetingDetailsByUniqueId;

        if (response.status)
           context.commit('setSelectedManagementMeetingDetails', data[0]);
        else
          await errorAlert(response.message);

        await context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },

    async fetchingManagementMeetingAttendees(context, currentMeeting) {
      context.commit('cleanManagementMeetingAttendees')
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: managementMeetingAttendees,
        variables: {
          meetingUniqueId: currentMeeting
        }
      }).then((response) => {
        const attendees = response.data.managementMeetingAttendees.edges
        attendees.forEach(attendee => {
          var atDef = {
            id: attendee.node.primaryKey,
            userId: attendee.node.attendanceUser.username,
            name: attendee.node.attendanceUser.firstName + " " + attendee.node.attendanceUser.lastName,
            attendanceAcceptInvitation: attendee.node.attendanceAcceptInvitation,
            attendancePosition: attendee.node.attendancePosition,
          }
          context.commit('setManagementMeetingAttendees', atDef)
        });
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })

    },

    async addManagementMeetingAttandees(context, attendee) {
      await apolloClient.mutate({
        mutation: addMeetingAttandees,
        variables: {
          attendanceMeeting: attendee.attendanceMeeting,
          attendanceUser: attendee.attendanceUser
        }
      }).then((response) => {
        const attendee = response.data.addManagementMeetingAttendees.attendance
        var atDef = {
          id: attendee.primaryKey,
          name: attendee.attendanceUser.firstName + " " + attendee.attendanceUser.lastName,
          attendanceAcceptInvitation: attendee.attendanceAcceptInvitation,
          userId: attendee.attendanceUser.username
        }
        context.commit('setManagementMeetingAttendees', atDef)
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },
    async switchingManagementMeetingAttendees(context, data) {
      await apolloClient.mutate({
        mutation: switchingAttendees,
        variables: {
          attendenceKey: data.data,
          acceptInvitation: data.side
        }
      })
      context.commit('switchManagementMeetingAttendees', data)

    },

    async updateManagementAttendeesMeetingPosition(context, data) {
      await apolloClient.mutate({
        mutation: updateManagementAttendeesMeetingPositionMutation,
        variables: {
          user: data.user,
          position: data.position
        }
      }).then((response) => {
        console.log(response);
        swal.fire({
          toast: true,
          icon: 'success',
          title: 'Updated',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      })
    },

    async loadManagementUnConfirmMeetings(context) {
      context.commit('cleanManagementUnConfirmMeetings')
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: unConfirmMeetingByInstitution,
        variables: {
          managementUniqueId: localStorage.getItem('my-managementUniqueId'),
          meetingMinutesConfirmed: false,
          meetingHasPublishedAgenda: true,
        }
      }).then((result) => {
        const {response, data} = result.data.managementMeetingByManagementUniqueId;

        if (response.status) {
          data.forEach(meeting => {
            if (context.getters.getManagementMeetingDetails.primaryKey !== meeting.primaryKey) {
              context.commit('addManagementUnConfirmMeetings', {
                meetingTitle: meeting.meetingTitle + " [ " + meeting.meetingDate + " ]",
                primaryKey: meeting.primaryKey
              });
            }
          });
        } else
          errorAlert("Failed to query unconfirmed meetings")

      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },

    // async updateManagementMeeting(context, Meeting) {
    //   apolloClient.mutate({
    //     mutation: updateManagementMeeting,
    //     variables: {
    //       meetingUniqueId: Meeting.meetingUniqueId,
    //       updateType: Meeting.updateType,
    //       updateValue: Meeting.updateValue,
    //       isDate: Meeting.isDate
    //     }
    //   }).then((response) => {
    //     const saved = response.data.updateManagementMeeting.message;
    //     const success = response.data.updateManagementMeeting.success;
    //     console.log(saved, success)
    //     context.dispatch('fetchingManagementMeetingDetails', Meeting.meetingUniqueId)
    //   }).catch(async (error) => {
    //     await context.dispatch("showLoadingSpinner", false);
    //     console.error(error.messages);
    //   })
    // },
  }
}
