<template>
  <v-container fluid>
    <v-row class="mt-5 mx-3">
      <v-col md="12">
        <v-card>
          <AddNewClient
            :OpenNewClientForm="OpenNewClientForm"
            @CloseNewClientForm="OpenNewClientForm = false"
          />
          <v-toolbar color="#d4d6d9" dark flat tile dense>
            <v-toolbar-title style="color: black"
              >List of Clients (Institutions) using e-Board
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <template v-if="selected.length > 1">
              <div class="pa-2">
                <v-btn
                  title="Activate Institution"
                  right
                  fab
                  x-small
                  color="success white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-autorenew</v-icon></v-btn
                >
                <v-btn
                  title="De-Activate Institution"
                  right
                  fab
                  x-small
                  color="orange white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-sync-off</v-icon></v-btn
                >
                <v-btn
                  title="Delete Institution"
                  right
                  fab
                  x-small
                  color="pink white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                >
              </div>
            </template>
            <v-btn
              v-show="!hidden"
              color="primary"
              fab
              small
              top
              right
              @click="OpenNewClientForm = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>

          <v-list two-line>
            <v-list-item-group
              v-model="selected"
              active-class="blue--text"
              multiple
            >
              <template v-for="(item, index) in items">
                <v-list-item :key="item.title">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title
                        ><v-icon style="font-size: 14px" color="success"
                          >mdi-circle</v-icon
                        >
                        {{ item.title }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.headline"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        style="font-weight: bold"
                        v-text="item.subtitle"
                      >
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        <v-menu v-if="selected.length < 2" offset-y app>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              slot="activator"
                              icon
                              color="indigo"
                              ><v-icon small>mdi-dots-vertical</v-icon></v-btn
                            >
                          </template>
                          <v-list dense>
                            <v-list-item link to="">
                              <v-list-item-title
                                ><v-icon style="height: 16px; font-size: 16px"
                                  >mdi-pencil-outline</v-icon
                                >
                                Edit Instituion</v-list-item-title
                              ></v-list-item
                            >
                            <v-list-item link to="">
                              <v-list-item-title
                                ><v-icon style="height: 16px; font-size: 16px"
                                  >mdi-bank</v-icon
                                >
                                Instituion Profile</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item link to="">
                              <v-list-item-title
                                ><v-icon style="height: 16px; font-size: 16px"
                                  >mdi-sync-off</v-icon
                                >
                                Deactivate</v-list-item-title
                              >
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item link to="">
                              <v-list-item-title
                                ><v-icon
                                  style="
                                    height: 16px;
                                    font-size: 16px;
                                    color: red;
                                  "
                                  >mdi-trash-can-outline</v-icon
                                >
                                Delete</v-list-item-title
                              >
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action-text>

                      <v-icon v-if="!active" color="grey lighten-1">
                        mdi-star-outline
                      </v-icon>

                      <v-icon v-else color="yellow darken-3"> mdi-star </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="15"
        :total-visible="7"
        circle
      ></v-pagination>
    </div>
  </v-container>
</template>
<script>
import AddNewClient from "./AddNewClient.vue";
export default {
  components: {
    AddNewClient,
  },
  data: () => ({
    OpenNewClientForm: false,
    page: 1,
    selected: [],
    items: [
      {
        action: "15 min",
        headline: "Universal Communications Service Access Fund",
        subtitle: `Client since: 12 Jan 2021`,
        title: "UCSAF",
      },
      {
        action: "2 hr",
        headline: "Tanzania Postal Bank",
        subtitle: `Client since: 12 Jan 2021`,
        title: "TPB",
      },
      {
        action: "6 hr",
        headline: "Fair Competition Commission",
        subtitle: "Client since: 12 Jan 2021",
        title: "FCC",
      },
      {
        action: "12 hr",
        headline: "e-Government Authority",
        subtitle: "Client since: 12 Jan 2021",
        title: "eGA",
      },
      {
        action: "18hr",
        headline: "Bodi ya Bandari Tanzania",
        subtitle: "Client since: 12 Jan 2021",
        title: "BANDARI",
      },
      {
        action: "18hr",
        headline: " Energy and Water Utilities Regulatory Authority ",
        subtitle: "Client since: 12 Jan 2021",
        title: "EWURA",
      },
    ],
  }),
};
</script>

<style>
</style>