import {apolloClient} from '@/vue-apollo'
import managementCommitteeBestPractiseAgendaMutation
    from '../../graphql/management-committee-agenda-graphql/managementCommitteeBestPractiseAgendaMutation.gql'

import managementCommitteeCloneAgendaMutation
    from '../../graphql/management-committee-agenda-graphql/managementCommitteeCloneAgendaMutation.graphql'

import getManagementCommitteeAgendas
    from '../../graphql/management-committee-agenda-graphql/getManagementCommitteeAgendas.gql'
import moveManagementCommitteeAgendaPosition
    from '../../graphql/management-committee-agenda-graphql/moveManagementCommitteeAgendaPosition.gql'
import createManagementCommitteeStandardAgenda
    from '../../graphql/management-committee-agenda-graphql/createManagementCommitteeStandardAgenda.gql'
import deleteManagementCommitteeStandardAgendaMutation
    from '../../graphql/management-committee-agenda-graphql/deleteManagementCommitteeStandardAgendaMutation.gql'

import updateManagementCommitteeStandardAgendaMutation
    from '../../graphql/management-committee-agenda-graphql/updateManagementCommitteeStandardAgendaMutation.gql'

import {errorAlert, successAlert} from "../../services/notificationService";


export default {
    state: {
        managementCommitteeAgendas: [],
        selectedManagementCommitteeAgenda: {}
    },
    getters: {
        getManagementCommitteeAgendas(state) {
            return state.managementCommitteeAgendas
        },
        getSelectedManagementCommitteeAgenda(state) {
            return state.selectedManagementCommitteeAgenda
        },
    },
    mutations: {
        clearManagementCommitteeAgendas(state) {
            state.managementCommitteeAgendas = []
        },
        setSelectedManagementCommitteeAgenda(state, agenda) {
            state.selectedManagementCommitteeAgenda = agenda
        },
        saveManagementCommitteeAgenda(state, agendas) {
            state.managementCommitteeAgendas = agendas
        },
        swapManagementCommitteeAgenda(state, input) {
            let agendaIndex = state.managementCommitteeAgendas.findIndex(agenda => agenda.id === input.agendaUniqueId)
            let tempAgenda = state.managementCommitteeAgendas[agendaIndex]
            if (input.agendaPosition === 'UP') {
                state.managementCommitteeAgendas[agendaIndex] = state.managementCommitteeAgendas[agendaIndex-1]
                state.managementCommitteeAgendas[agendaIndex-1] = tempAgenda
            } else {
                state.managementCommitteeAgendas[agendaIndex] = state.managementCommitteeAgendas[agendaIndex+1]
                state.managementCommitteeAgendas[agendaIndex+1] = tempAgenda
            }
        },
        addManagementCommitteeAgenda(state, agenda) {
            state.managementCommitteeAgendas.push(agenda)
        },
        deleteManagementCommitteeAgenda(state, agendaUniqueId) {
            let agendaIndex = state.managementCommitteeAgendas.findIndex(agenda => agenda.agendaUniqueId === agendaUniqueId)
            state.managementCommitteeAgendas.splice(agendaIndex, 1)
        },
        updateManagementCommitteeAgenda(state, editedAgenda) {
            state.managementCommitteeAgendas = state.managementCommitteeAgendas.map((agenda) => {
                return agenda.id == editedAgenda.id ? editedAgenda : agenda
            })
        },
        updateSelectedManagementCommitteeAgenda(state, selectedAgenda) {
            state.selectedManagementCommitteeAgenda = selectedAgenda
        },
        addSelectedAgendaSupportingDocument(state, data) {
            state.selectedManagementCommitteeAgenda.agendaSupportingDocuments.push(data);
        },
        deleteSelectedAgendaSupportingDocument(state, documentUniqueId) {
            const tempIndex = state.selectedManagementCommitteeAgenda.agendaSupportingDocuments.findIndex((document) => document.id === documentUniqueId);
            if (tempIndex !== -1)
                state.selectedManagementCommitteeAgenda.agendaSupportingDocuments.splice(tempIndex, 1);
        }
    },
    actions: {
        async loadManagementCommitteeAgendas(context, meetingUniqueId = null) {
            context.commit('clearManagementCommitteeAgendas')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getManagementCommitteeAgendas,
                variables: {
                    meetingUniqueId
                }
            }).then(async (response) => {
                let result = response.data.getManagementCommitteeAgendas
                if (result.response.code === 9000) {
                    context.commit('saveManagementCommitteeAgenda', result.data)
                    context.commit('setSelectedManagementCommitteeAgenda', result.data[0])
                } else {
                    await errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async deleteManagementCommitteeStandardAgenda(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: deleteManagementCommitteeStandardAgendaMutation,
                variables: {
                    input
                }
            }).then(async (response) => {
                let result = response.data.deleteManagementCommitteeStandardAgendaMutation
                if (result.response.code === 9000) {
                    context.commit('deleteManagementCommitteeAgenda', input.agendaUniqueId)
                } else {
                    await errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async moveManagementCommitteeAgendaPosition(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: moveManagementCommitteeAgendaPosition,
                variables: {
                    input
                }
            }).then(async (response) => {
                let result = response.data.moveManagementCommitteeAgendaPositionMutation
                console.log('Input: ',input)
                if (result.response.code === 9000) {
                    context.commit('swapManagementCommitteeAgenda', input)

                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async managementCommitteeBestPractiseAgenda(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: managementCommitteeBestPractiseAgendaMutation,
                variables: {
                    input: input
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.managementCommitteeBestPractiseAgendaMutation
                if (result.response.code === 9000) {
                    context.commit('addManagementCommitteeAgenda', result.data)
                    await successAlert(result.response.message)
                } else {
                    await errorAlert(result.response.message)
                }
            })

        },
        async managementCommitteeCloneMeetingAgenda(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: managementCommitteeCloneAgendaMutation,
                variables: {
                    input: input
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.managementCommitteeCloneAgendaMutation
                if (result.response.code === 9000) {
                    context.commit('addManagementCommitteeAgenda', result.data)
                    await successAlert(result.response.message)
                } else {
                    await errorAlert(result.response.message)
                }
            })

        },
        async createManagementCommitteeStandardAgenda(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createManagementCommitteeStandardAgenda,
                variables: {
                    input: input
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createManagementCommitteeStandardAgendaMutation
                if (result.response.code == 9000) {
                    context.commit('addManagementCommitteeAgenda', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async editManagementCommitteeAgenda(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateManagementCommitteeStandardAgendaMutation,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateManagementCommitteeStandardAgendaMutation
                if (result.response.code == 9000) {
                    context.commit('updateManagementCommitteeAgenda', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async changeSelectedManagementCommitteeAgenda(context, agenda) {
            context.commit('updateSelectedManagementCommitteeAgenda', agenda)
        },

    },
}
