<template>
  <v-container app class="my-3" fluid>
    <v-layout class="mb-0">
      <v-flex md10></v-flex>
      <v-flex md2 xs12>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="hasAccess('can_add_management_meeting')"
              color="primary white--text"
              outlined
              rounded
              right
              small
              style="margin: 5px"
              v-bind="attrs"
              @click="showContextMenu = !showContextMenu"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
              Create Meeting
            </v-btn>
          </template>
          <span>Create New Meeting</span>
        </v-tooltip>
        <AddNewMeeting
          :showContextMenu="showContextMenu"
          @closeNewMeetingDialog="showContextMenu = !showContextMenu"
          @loadManagementMeetingsList="loadManagementMeetingsList"
        />
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex md12 xs12>
        <div style="display: flex; justify-content: center; flex-wrap: wrap">
          <div
            v-for="meeting in managementMeetingsList"
            :key="meeting.meetingUniqueId"
            :to="details + meeting.meetingUniqueId"
            class="padding intro-x"
            link
            style="display: flex; justify-content: center; margin-top: 40px"
          >
            <v-card
              class="meeting-info-card"
              elevation="3"
              style="border-radius: 7px"
              @click="moveToAnotherPage(details + meeting.meetingUniqueId)"
            >
              <div
                class="flex flex-justify-content-start"
                style="
                  width: 100%;
                  padding-left: 20px;
                  padding-top: 10px;
                  border-radius: 3px 3px 0 0;
                "
              >
                <p>
                  {{ meeting.meetingManagement.managementName }}
                </p>
              </div>
              <div
                class="flex flex-justify-content-start"
                style="
                  width: 100%;
                  background-color: #ccc;
                  border-radius: 3px 3px 0 0;
                "
              >
                <span
                  style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 1px 0 0;
                    border-radius: 3px 0 0 0;
                  "
                ></span>
                <span
                  style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 1px;
                  "
                ></span>
                <span
                  style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 1px;
                  "
                ></span>
                <span
                  style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 1px;
                  "
                ></span>
                <span
                  style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 0 0 1px;
                    border-radius: 0 3px 0 0px;
                  "
                ></span>
              </div>
              <li
                :to="details + meeting.meetingUniqueId"
                class="hand-cursor"
                link
              >
                <div class="flex flex-justify-content-start py px">
                  <div
                    :to="details + meeting.meetingUniqueId"
                    link
                    style="margin-left: 20px"
                  >
                    <span class="date-container">
                      <span class="date-month">{{ meeting.meetingMonth }}</span>
                      <span class="date-date">{{ meeting.meetingDay }}</span>
                    </span>
                    <span class="flex-column" style="margin-left: 0px">
                      <span class="date-day center">{{
                        meeting.meetingWeekday
                      }}</span>
                      <span class="date-time center" style="font-size: 14px">{{
                        meeting.meetingStartTime
                      }}</span>
                      <span class="date-timezone center"
                        >({{ meeting.meetingLocation.locationTimezone }})</span
                      >
                    </span>
                  </div>
                  <v-divider
                    style="height: 100px; margin-right: 25px; margin-left: 10px"
                    vertical
                  ></v-divider>
                  <div
                    :to="details + meeting.meetingUniqueId"
                    class="flex-column left"
                    link
                  >
                    <h4>{{ meeting.meetingTitle }}</h4>
                    <span
                      class="flex flex-align-items-start"
                      style="padding-top: 10px"
                    >
                      <v-icon>mdi-map-marker</v-icon>
                      <span>
                        <b>{{ meeting.meetingLocation.locationName }}</b>
                        <br />
                        <span>{{
                          meeting.meetingLocation.locationAddress
                        }}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            </v-card>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AddNewMeeting from "./AddnewMeeting.vue";

export default {
  data() {
    return {
      showContextMenu: false,
      details: "/management/management_meeting_details/",
      filterObj: {
        managementUniqueId: localStorage.getItem('my-managementUniqueId'),
        meetingHasConducted: false
      }
    };
  },

  components: {
    AddNewMeeting,
  },

  methods: {
    moveToAnotherPage(pageRoute) {
      this.$router.push(pageRoute);
    },

    async loadManagementMeetingsList() {
      await this.$store.dispatch("loadManagementMeetingsList");
    },
  },

  computed: {
    managementMeetingsList() {
      return this.$store.getters.managementMeetingsList;
    },
  },

  async mounted() {
    await this.$store.dispatch("loadManagementMeetingsList", this.filterObj);
  },
};
</script>
