<template>
  <v-card
      id="agendaPanels"
      class="scroll"
      height="600px"
      style="width: 97%; padding-top: 10px"
  >
    <v-card-text>
      <div v-if="agendaList.length < 1" class="text-center">
        <v-sheet
            class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
            color="blue-grey darken-3"
            dark
        >
          <div class="grey--text text--lighten-1 text-body-2 mb-4">
            If your meeting agenda does not appear in this panel please click
            the button bellow to synchronize and open again. . . !
          </div>

          <v-btn
              :loading="loading"
              class="ma-1"
              color="white"
              plain
              outlined
              @click="reloadAgendas"
          >
            Reload agenda
          </v-btn>
        </v-sheet>
      </div>
      <v-card
          v-else
          style="margin: 5px"
          elevation="0"
      >
        <v-card-subtitle>
          <v-layout
              class="rounded-md" style="background-color: #edf4ff; padding: 12px">
            <v-flex xs10 md11 max-width="100%">
              <strong
                  style="
                  font-size: 16px;
                  font-weight: bold;
                  padding-left: 5px;
                  padding-top: 5px;
                "
              >Meeting Agenda</strong>

            </v-flex>

            <v-flex xs2 md1 max-width="100%">
              <v-menu v-if="hasAccess('can_schedule_meeting')" offset-y app>
                <v-list dense>
                  <v-list-item  link @click="testFunction(agenda.agendaKey)">
                    <v-list-item-title
                    >
                      <v-icon style="height: 16px; font-size: 16px"
                      >mdi-square-edit-outline
                      </v-icon
                      >
                      Edit Agenda
                    </v-list-item-title
                    >
                  </v-list-item
                  >
                  <v-list-item link @click="moveAgenda(agenda.primaryKey, 'UP')">
                    <v-list-item-title
                    >
                      <v-icon style="height: 16px; font-size: 16px"
                      >mdi-arrow-up
                      </v-icon
                      >
                      Move it Up
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="moveAgenda(agenda.primaryKey, 'DOWN')">
                    <v-list-item-title
                    >
                      <v-icon style="height: 16px; font-size: 16px"
                      >mdi-arrow-down
                      </v-icon
                      >
                      Move it Down
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="deleteAgendas(agenda.agendaKey)">
                    <v-list-item-title
                    >
                      <v-icon style="height: 16px; font-size: 16px"
                      >mdi-delete
                      </v-icon
                      >
                      Delete
                    </v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-subtitle>

        <v-card-text>
            <ManagementCommitteeAgenda
                @lockScroll="lockScrolling"
                @getAgendaItemDetails="getAgendaItemDetails"
                :currentMeeting="currentMeeting"
                :HasPublishedAgenda="meetingHasPublishedAgenda"
            />
        </v-card-text>

        <v-card-actions v-if="hasAccess('can_schedule_meeting') && !meetingHasPublishedAgenda">
          <v-spacer></v-spacer>
          <v-btn
              v-bind="attrs"
              v-on="on"
              slot="activator"
              text
              x-small
              color="indigo"
              @click="addNewAgenda()"
          >
            <v-icon small>mdi-plus</v-icon>
            Add Agenda
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import ManagementCommitteeAgenda from "./ManagementCommitteeAgenda.vue";

export default {
  name: "ManagementCommitteeAgendaSidePanel",
  props: ["AgendaBuilderWindow", "currentMeeting", 'meetingHasPublishedAgenda'],
  components: {
    ManagementCommitteeAgenda,
  },
  data() {
    return {
      selectedAgenda: null,
      loading: false,
    };
  },
  methods: {
    testFunction(agendaKey) {
      this.isEditAgendaKey = agendaKey;
      this.autofocus=true
    },

    lockScrolling() {
      const body = document.getElementById("agendaPanels");
      body.style.overflow = "hidden";
      body.style.overflow = "auto";
    },

    getAgendaItemDetails(ItemId) {
      console.log(ItemId);
    },

    closeGreatModel() {
      this.$emit("close");
    },

    async reloadAgendas() {
      this.loading = true;
      await new Promise((resolve) =>
          setTimeout(resolve, this.$router.go(), 3000)
      );
      this.$store.dispatch(
          "loadBestPracticeAgendaTemplates",
          this.currentMeeting
      );
    },

    async onPurposeInput(event, selectedAgenda) {
      var details = {
        newItem: this.agendaName,
        agendaKey: selectedAgenda,
      };
      await this.$store.dispatch("updateAgenda", details);
      this.agendaName = "";
      this.isEditAgendaKey = "";
      this.autofocus = false
    },

    async addNewAgenda() {
      await this.$store.dispatch('createManagementCommitteeStandardAgenda', { agendaMeeting: this.currentMeeting })
      // await this.$store.dispatch('loadManagementCommitteeAgendas')
    },

    async addVotingNewAgendaItem(agendaKey, Position) {
      await this.$store.dispatch("addNewVotingAgendaItem", {
        agendaKey: agendaKey,
        Position: Position,
      });
    },

    async deleteAgendas(agendaKey) {
      await this.$store.dispatch("deleteAgendas", {
        meetingKey: this.$store.state.CurrentMeetingStore.currentMeeting,
        agendaKey: agendaKey,
      });
      await this.$store.dispatch(
          "loadBestPracticeAgendaTemplates",
          this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },

    async moveAgenda(agendaUniqueId, position) {
      await this.$store.dispatch("moveMeetingAgenda", {
        agendaUniqueId: agendaUniqueId,
        position: position,
      });
      await this.$store.dispatch(
          "loadBestPracticeAgendaTemplates",
          this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },
  },
  computed: {
    // lastMeetings() {
    //   return this.$store.state.ManagementMeetingsStore.unConfirmManagementMeetings;
    // },
    // meetingHasConfirmMinutes() {
    //   return this.$store.state.ManagementMeetingsStore.selectedManagementMeetingDetails.meetingHasConfirmMinutes;
    // },
    // meetingHasInterestRegister() {
    //   return this.$store.state.ManagementMeetingsStore.selectedManagementMeetingDetails
    //       .meetingHasInterestRegister;
    // },
    agendaList() {
      return this.$store.getters.getManagementCommitteeAgendas
    },
  },
  async mounted() {
    await this.$store.dispatch("loadManagementUnConfirmMeetings");
  },
};
</script>


<style scoped>
.cencontent {
  align-content: center;
}
</style>


<style>
.fixed-position {
  position: fixed;
}

.transparent {
  background-color: white !important;
  opacity: 0.3;
  border-color: transparent !important;
}

.scroll {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>
