import {apolloClient} from '@/vue-apollo'
import getAllManagementCommitteeMembers
    from '../../graphql/management-committee-members-graphql/getAllManagementCommitteeMembers.gql'

import {errorAlert} from "../../services/notificationService";


export default {
    state: {
        managementCommitteeMembers: [],
    },
    getters: {
        getManagementCommitteeMembers(state) {
            return state.managementCommitteeMembers
        }
    },
    mutations: {
        clearManagementCommitteeMembers(state) {
            state.managementCommitteeMembers = []
        },
        saveManagementCommitteeMembers(state, meetings) {
            state.managementCommitteeMembers = [...meetings]
        }
    },
    actions: {
        async loadManagementCommitteeMembers(context) {
            context.commit('clearManagementCommitteeMembers')
            let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
            await context.dispatch("showLoadingSpinner", true);
            if (managementCommitteeUniqueId !== "") {
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: getAllManagementCommitteeMembers,
                    variables: {
                        committeeUniqueId: managementCommitteeUniqueId || ""
                    }
                }).then(async (response) => {
                    let result = response.data.getAllManagementCommitteeMembers
                    if (result.response.code == 9000) {
                        context.commit('saveManagementCommitteeMembers', result.data)
                    } else {
                        errorAlert(result.response.message)
                    }
                }).finally(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
            }
        },
    },


}


