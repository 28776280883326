import {apolloClient} from "@/vue-apollo";
import getSystemDefaultRoles from '@/graphql/admin-panel-graphql/user-roles-graphql/getSystemDefaultRoles.graphql'
import deleteDefaultUserRole from '@/graphql/admin-panel-graphql/user-roles-graphql/deleteDefaultUserRole.gql.graphql'
import {errorAlert, successAlert} from "@/services/notificationService";
import createDefaultUserRole from "@/graphql/admin-panel-graphql/user-roles-graphql/createDefaultUserRole.graphql";
import updateDefaultUserRole from  "@/graphql/admin-panel-graphql/user-roles-graphql/updateDefaultUserRole.gql.graphql"

export default {
    state: {
        defaulfUserRoleList: []
    },

    mutations: {
        clearUserRoles(state){
            state.defaulfUserRoleList = []
        },

        createDefaultUserRole(state, userRoleObj) {
            state.defaulfUserRoleList.push(userRoleObj)
        },

        deleteDefaultUserRole(state, roleUniqueId){
            var temp_list = []
            state.defaulfUserRoleList.forEach(role=>{
                console.log(role);
                if (role.roleUniqueId !== roleUniqueId){
                    temp_list.push(role)
                }
            })
        },

        saveUserRole(state, roles) {
            state.defaulfUserRoleList = roles
        },

        updateDefaultUserRole(state, roleObj){
            state.defaulfUserRoleList = state.defaulfUserRoleList.map((role) => {
                return role.roleUniqueId == roleObj.roleUniqueId ? roleObj : role
            })
        }
    },

    getters: {
        getDefaultUserRoleList(state){
            return state.defaulfUserRoleList
        }
    },

    actions: {
        async loadDefaultUserRoles(context) {
            await context.dispatch('showLoadingSpinner', true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getSystemDefaultRoles
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                const results = response.data.getSystemDefaultRoles
                if (results.response.code == 9000) {
                    context.commit('saveUserRole', results.data)
                } else {
                    errorAlert(results.response.message)
                }
            })
        },

        async createUserRoles(context, input){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createDefaultUserRole,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createDefaultUserRole
                console.log(result)
                if (result.response.code == 9000) {
                    context.commit('createDefaultUserRole', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

        async editDefaultUserRole(context, input) {
            // context.dispatch('loadDefaultUserRoles')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateDefaultUserRole,
                variables: {
                    input: {
                        ...input,
                    }
                }
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateDefaultUserRole
                if (result.response.code == 9000) {
                    context.commit('updateDefaultUserRole', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

        async deleteDefaultRole(context, roleUniqueId){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteDefaultUserRole,
                variables: {
                    roleUniqueId: roleUniqueId
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.deleteDefaultUserRole
                if (result.response.code == 9000) {
                    context.commit('deleteDefaultUserRole', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

    }
}