<template>
  <v-row justify="center">
    <v-dialog
        v-model="OpenNewUserForm"
        max-width="80%"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-account-plus-outline</v-icon> Add New Person to Board</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-text-field
                    v-model="FirstName"
                    :counter="10"
                    :rules="nameRules"
                    label="First Name *"
                    prepend-icon="mdi-text-recognition"
                    required
                >
                </v-text-field>
                <v-text-field
                    v-model="LastName"
                    :counter="10"
                    :rules="nameRules"
                    label="Last Name *"
                    prepend-icon="mdi-text-recognition"
                    required
                >
                </v-text-field>
                <v-checkbox
                    v-model="has_tiltle"
                    :label="`This person has a Title/Suffix`"
                    color="success"
                ></v-checkbox>
                <div v-if="has_tiltle" style="width:100%; padding:20px; background-color:rgba(15, 98, 201, 0.11)">
                  <v-text-field
                      v-model="title"
                      :counter="30"
                      hint="Eg: Prof. Mr. Ms. Dr. etc"
                      label="Title"
                      persistent-hint
                  ></v-text-field>
                  <v-text-field
                      v-model="suffix"
                      :counter="30"
                      hint="Eg: Ph.D M.D. Est. etc"
                      label="Suffix"
                      persistent-hint
                  ></v-text-field>

                </div>

                <v-text-field
                    v-model="email"
                    :counter="10"
                    :rules="nameRules"
                    label="Email Address*"
                    prepend-icon="mdi-text-recognition"
                    required
                >
                </v-text-field>
                <v-text-field
                    v-model="position"
                    :counter="10"
                    :rules="nameRules"
                    label="Position Title"
                    prepend-icon="mdi-text-recognition"
                    required
                >
                </v-text-field>
                <v-checkbox
                    v-model="checkboxMember"
                    :label="`Board Member`"
                ></v-checkbox>
                <v-radio-group v-model="userrole">
                  <template v-slot:label>
                    <div>Select <strong>Access Level</strong>
                      <v-btn color="dark" icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary white--text" elevation="1" fab right
                                 style="margin:5px" v-bind="attrs" x-small @click="showContextMenu =! showContextMenu" v-on="on">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add New Access Level</span>
                      </v-tooltip>
                    </div>

                  </template>


                  <v-radio v-for="role in roles" :key="role.primaryKey" :value="role.primaryKey">
                    <template v-slot:label>
                      <div>{{ role.roleName }}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="white--text"
              color="pink"
              fab
              small

              @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
              class="white--text"
              color="blue darken-1"
              fab
              small
              @click="addNewUser"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-row>
</template>


<script>
import allRolesPerBoard from '../../../graphql/eboard-auth-graphql/allRolesPerBoard.gql'
import {mapActions} from 'vuex'

export default {
  components: {},
  data: () => ({
    loading: false,
    zIndex: -1,
    overlay: true,
    FirstName: '',
    LastName: '',
    has_tiltle: false,
    title: '',
    suffix: '',
    email: '',
    position: '',
    checkboxMember: '',
    userrole: '',
    boardKey: localStorage.getItem('my-boardKey') || "",
    checkbox: false,
    openLocationDialog: false,
    boardUniqueId: localStorage.getItem('my-boardUniqueId') || "",
    time: null,
    menu4: false,
    menu3: false,
    modal2: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    Tittle: '',
    nameRules: [
      v => !!v || 'This field is required',
      v => v.length <= 10 || 'Name must be less than 10 characters',
    ],
    roles: [],
    membersDetails: {}
  }),
  props: ["OpenNewUserForm"],
  created() {
    this.$apollo.query({
      query: allRolesPerBoard,
      variables: {
        roleBoard: this.boardUniqueId
      }
    }).then((response) => {
      this.roles = [...response.data.rolesPerBoard];
    })
  },
  methods: {
    ...mapActions({
      registerNewMembers: 'MembersStore/registerNewMembers'
    }),
    addNewUser() {
      this.membersDetails = {
        email: this.email,
        lastName: this.LastName,
        boardKey: this.boardKey,
        checkboxMember: this.checkboxMember,
        hasTiltle: this.has_tiltle,
        firstName: this.FirstName,
        position: this.position,
        suffix: this.suffix,
        title: this.title,
        userRole: this.userrole
      }
      this.registerNewMembers(this.membersDetails)
      this.$emit('close')
    }
  }

}
</script>