import SettingsDashboard from './SettingsDashboard.vue'
import UserManagement from './UserManagement.vue'
import SettingsHome from './SettingsHome.vue'

export default [
    {
        path: '/settings',
        name:'SettingsHome',
        component: SettingsHome,
        meta:{requiresAuth:true},
        children: [
            {
                path: 'dashboard',
                name:'SettingsDashboard',
                component: SettingsDashboard
            },
            {
                path: 'user_management',
                name:'UserManagement',
                component: UserManagement
            }
        ]
    }
]
