<template>
      <v-card>
        <v-card-title>
          <span class="text-h6"> <v-icon color="indigo">mdi-cards-heart</v-icon> Add new Interest</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                v-model="interestUser"
                :items="loadedMembers"
                item-text="name"
                item-value="id"
                label="Select User from list of board Members"
                dense
                
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                 Add new Interest  v-model="interestOrganisation"
                  label="Organisation*"
                  hint=""
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="interestNature"
                  label="Nature of Interest*"
                  hint=""
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            class="white--text"
            small
            fab
            @click="closeNewInterestFormDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="indigo"
            class="white--text"
            small
            fab
            :loading="interestLoading"
            @click="registerNewInterest"
          >
            <v-icon>mdi-floppy</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
export default {
    data(){
        return{
            interestLoading:false,
            interestUser:null,
            interestOrganisation:null,
            interestNature:null,
        }
    },
    computed:{
        loadedMembers(){
            var board_members= this.$store.state.MembersStore.boardMembers
            var temp_members=[]
            board_members.forEach(member => {
              temp_members.push({
                id:member.id,
                name:member.firstName+" "+member.lastName
              })
            });
            return temp_members
        }
    },
    watch: { 
        loadedMembers: function() {
            
            var childrens=[]
            this.loadedMembers.forEach(members => {
                childrens.push(
                    { id: members.id, name: members.firstName+" "+members.lastName}
                )
            });
            this.boadMemberList.push(
                {
                  id: 1,
                  name: 'Board Members('+this.loadedMembers.length+")",
                  children: childrens,
                },
            )
            this.overlay=false
        },
        receivers: function(){
            console.log(this.selectedReceivers);
            this.selectedReceivers=this.receivers.length
            console.log(this.selectedReceivers);
        }
    },
    mounted(){
         this.$store.dispatch("loadBoardMambers")
    },
    methods:{
      registerNewInterest(){
        var data={
          interestBoardId:localStorage.getItem("my-boardUniqueId"),
          interestNature:this.interestNature,
          interestUserId:this.interestUser,
          interestOrganisation:this.interestOrganisation
        }
        this.$store.dispatch("createBoardInterests",data)
      },
      closeNewInterestFormDialog(){
        this.$emit('closeNewInterestFormDialog')
      }
    }
}
</script>