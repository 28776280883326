<template>
  <div class="text-center">
    <v-dialog v-model="OpenDecisionEditor" persistent width="60%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="pink">mdi-arrow-all</v-icon>
          {{ decisionName }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="decisionTittle"
                    :counter="100"
                    label="Tittle"
                    prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6" sm="4" md="4">
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateFormatted"
                        label="Resolution Date *"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      no-title
                      @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="4" md="4">
                <v-layout>
                  <v-flex sm10>
                    <v-select
                        v-model="decisionOutcome"
                        :items="[
                        { name: 'Approved', value: 'APR' },
                        { name: 'Not Approved', value: 'NOT' },
                      ]"
                        item-value="value"
                        item-text="name"
                        label="Outcome"
                        required
                        prepend-icon="mdi-star"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>

            <Editor
                :content="content"
                :editorOption="editorOption"
                @onEditorChange="onEditorChange($event)"
                style="height: 180px"
            />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              text
              outlined
              small
              @click="$emit('CloseDecisionEditor')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn color="success" outlined small @click="saveMinuteDecisions"  v-if="decisionName === 'Add Resolution'">
            <v-icon small>mdi-floppy</v-icon> Save
          </v-btn>
          <v-btn color="success" outlined small @click="editMinuteDecisions"  v-if="decisionName === 'Edit Resolution'">
            <v-icon small>mdi-floppy</v-icon> Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Editor from "../management_shared/fields/Editor.vue";

export default {
  props: ["OpenDecisionEditor", "selectedAgenda","decisionName", "selectedDecision"],

  components: {
    Editor,
  },

  data: () => ({
    content: "",
    decisionTittle: "",
    decisionOutcome: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    dateFormatted: "",
    menu1: false,
    editorOption: {
      placeholder: "Write Resolution here . . . ",
      theme: "snow",
    },
  }),

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    computedSelectedDecision() {
      this.decisionTittle = this.selectedDecision?.decisionTitle ? this.selectedDecision.decisionTitle : "";
      this.dateFormatted = this.selectedDecision?.decisionDate ? this.selectedDecision.decisionDate : "";
      this.decisionOutcome = this.selectedDecision?.decisionOutcome ? this.selectedDecision.decisionOutcome : "";
      this.content = this.selectedDecision?.decisionDescriptions ? this.selectedDecision.decisionDescriptions : "";
    },
  },

  computed: {
    computedSelectedDecision() {
      return this.selectedDecision
    },
  },

  methods: {
    onEditorChange(event) {
      this.content = event;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async saveMinuteDecisions() {
      // await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("saveManagementDecisionsMinutes", {
        decisionContents: this.content,
        decisionDate: this.dateFormatted,
        decisionOutcome: this.decisionOutcome,
        decisionTitle: this.decisionTittle,
        decisionItemKey: this.selectedAgenda.itemKey,
      });
      await this.$store.dispatch('loadAllManagementDirectiveMinutes', this.selectedAgenda.itemKey);
      this.content = "";
      this.dateFormatted = "";
      this.decisionOutcome = "";
      this.decisionTittle = "";
      this.$emit("CloseDecisionEditor");
      // await this.$store.dispatch("showLoadingSpinner", false);
    },

    async editMinuteDecisions() {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("editManagementDecisionsMinutes", {
        decisionContents: this.content,
        decisionDate: this.dateFormatted,
        decisionOutcome: this.decisionOutcome,
        decisionTitle: this.decisionTittle,
        decisionItemKey: this.selectedAgenda.itemKey,
        decisionUniqueId: this.selectedDecision.decisionUniqueId
      });
      await this.$store.dispatch('loadAllManagementDecisionMinutes', this.selectedAgenda.itemKey)
      this.content = "";
      this.dateFormatted = "";
      this.decisionOutcome = "";
      this.decisionTittle = "";
      this.$emit("CloseDecisionEditor");
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>
