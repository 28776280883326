<template>
  <v-row justify="center">
    <v-dialog
        v-model="meetingInviteeStatus"
        max-width="1000px"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-account-group-outline</v-icon> {{ editMeetingInvitees ? 'Edit' : 'Add' }} Meeting Invitees</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row v-for="(invitee, index) of meetingInvitees"
                   :key="index">

              <v-col cols="4" class="flex items-center">
                <div class="w-full">
                  <v-text-field
                      v-model="invitee.inviteeFullName"
                      :counter="100"
                      :rules="nameRules"
                      label="Full Name *"
                      prepend-icon="mdi-text-recognition"
                      required
                  >
                  </v-text-field>
                </div>
              </v-col>

              <v-col cols="4" class="flex items-center">
                <div class="w-full">
                  <v-text-field
                      v-model="invitee.inviteeInstitution"
                      :counter="100"
                      :rules="nameRules"
                      label="Institution *"
                      prepend-icon="mdi-text-recognition"
                      required
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col cols="4" class="flex items-center">
                <div class="w-full">
                  <v-text-field
                      v-model="invitee.inviteeEmail"
                      :counter="100"
                      :rules="emailRules"
                      type="email"
                      label="Email *"
                      prepend-icon="mdi-envelope"
                      required
                  >
                  </v-text-field>
                </div>
                <div class="flex">
                  <v-btn icon class="ml-2"
                         v-if="index !== 0"
                         @click="removeInvitee(index)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                  <v-btn icon
                         v-if="index === meetingInvitees.length - 1"
                         @click="addInvitee">
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="white--text"
              color="pink"
              small
              @click="$emit('closeNewInviteeDialog')"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>

          <v-btn
              v-if="editMeetingInvitees"
              class="white--text"
              color="blue darken-1"
              small
              @click="editInvitee"
          >
            <v-icon>mdi-floppy</v-icon>
            Save Changes
          </v-btn>

          <v-btn
              v-else
              class="white--text"
              color="blue darken-1"
              small
              @click="createNewInvitee"
          >
            <v-icon>mdi-floppy</v-icon>
            Save
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


  </v-row>
</template>


<script>

export default {
  name: "AddNewInvitee",
  data: () => ({
    newMeetingInvitees: true,
    overlay: false,
    zIndex: 0,
    hidden: false,
    checkbox: false,
    loading: false,
    openLocationDialog: false,
    timezone: '',
    defaultSelected: '',
    selectedLocations: [],
    time: null,
    menu: false,
    menu4: false,
    menu3: false,
    modal2: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    modal: false,
    menu2: false,
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    dialog1: false,
    modal1: false,
    meetingDate: '',
    startTime: '',
    meetingCategory: '',
    endTime: '',
    meetingTittle: '',
    nameRules: [
      v => !!v || 'Full name is required',
      v => v.length <= 100 || 'Full name must be less than 100 characters',
    ],
    emailRules: [ v => /.+@.+/.test(v) || 'Invalid Email address' ],
    createdMeeting: '',
    meetingInvitees: [{inviteeFullName: '', inviteeInstitution: '', inviteeEmail: ''}]
  }),
  props: ["meetingInviteeStatus", 'meetingUniqueId', 'editMeetingInvitees'],

  watch: {
    meetingInviteeStatus() {
      if (this.meetingInviteeStatus && this.editMeetingInvitees) {
        if (this.$store.getters.getManagementCommitteeMeetingInvitees.length !== 0) {
          this.meetingInvitees = this.$store.getters.getManagementCommitteeMeetingInvitees.map((invitee) => {
            return {
              inviteeFullName: invitee.inviteeFullName,
              inviteeInstitution: invitee.inviteeInstitution,
              inviteeEmail: invitee.inviteeEmail
            }
          })
        }
      }
    }
  },

  methods: {
    addInvitee() {
      this.meetingInvitees.push({inviteeFullName: '', inviteeInstitution: '', inviteeEmail: ''})
    },
    removeInvitee(index) {
      this.meetingInvitees.splice(index, 1)
    },
    createNewInvitee() {
      let input = {
        meetingUniqueId: this.meetingUniqueId,
        invitees: this.meetingInvitees
      }
      this.$store.dispatch('createManagementCommitteeMeetingInvitee', input)
      this.$emit('closeNewInviteeDialog')
      this.meetingInvitees = [{inviteeFullName: '', inviteeEmail: ''}]
    },
    editInvitee() {
      let input = {
        meetingUniqueId: this.meetingUniqueId,
        invitees: this.meetingInvitees
      }
      this.$store.dispatch('editManagementMeetingInvitees', input)
      this.$emit('closeNewInviteeDialog')
      this.meetingInvitees = [{inviteeFullName: '', inviteeEmail: ''}]
    }

  },
}
</script>
