<template>
    <v-container fluid class="my-5" app>
        <v-row justify="center">
            <v-dialog
            v-model="TakeminutesWindow"
            persistent
            max-width="100%"
            :scrollable="scrollable"
            >
            <v-layout  style=" padding-top:10px" justify-space-around>
                <v-flex  xs12 md3 max-width="100%">
                    <AgendaSidepanel :listOfagendas="listOfagendas" :currentMeeting="currentMeeting" />
                </v-flex>
                <v-flex xs12 md9>
                    <AgendaMinutes @closeGreatModel="closeGreatModel" @reload="reloadPage = true" style="width:100%" :currentMeeting="currentMeeting" />
                </v-flex>
            </v-layout>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import AgendaMinutes from './AgendaMinutes.vue'
import AgendaSidepanel from '../management-agendas/AgendaSidepanel.vue'

export default {
    props:['TakeminutesWindow','agendaMode','listOfagendas','currentMeeting'],
    components:{
        AgendaMinutes,
        AgendaSidepanel
    },
    data () {
        return {
            scrollable:false,
            dialog2: false,
            dialog3: false,
          reloadPage: false,
          notifications: false,
            sound: true,
            widgets: false,
        }
    },

    methods:{
        lockScrolling(){
            const body = document.getElementById("agendaPanels")
            body.style.overflow = 'hidden'

            body.style.overflow = 'auto'
        },
        getAgendaItemDetails(ItemId){
            console.log(ItemId);
        },
        closeGreatModel(){
          if (this.reloadPage) this.$emit('reload')
          this.$emit('close')
        }
    }
}
</script>

<style>
 .fixed-position {
    position: fixed;
 }

.transparent {
   background-color: white!important;
   opacity: 0.3;
   border-color: transparent!important;
 }
 .scroll {
   overflow-y: scroll;

}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>
