<template>
  <div class="text-center">
    <v-dialog v-model="grantBoardAccessDialog" width="500">
      <div class="box pa-5">
          <form
            class="validate-form"
            @submit.prevent="grantBoardAccess()"
            onsubmit="return false"
          >
          <v-radio-group v-model="boardMemberLevel">
            <template v-slot:label>
              <div
                style="font-size: 20px"
                class="flex items-center justify-center"
              >
                <div>Select New Position for {{ data.fullname }}</div>
              </div>
            </template>

            <v-radio :value="`CHR`">
              <template v-slot:label>
                <div>Board Chair</div>
              </template>
            </v-radio>
            <v-radio :value="`SEC`">
              <template v-slot:label>
                <div>Board Secretary</div>
              </template>
            </v-radio>
            <v-radio :value="`MEB`">
              <template v-slot:label>
                <div>Board Member</div>
              </template>
            </v-radio>
            <v-radio :value="`SECRT`">
              <template v-slot:label>
                <div>Board Secretariat / Officer</div>
              </template>
            </v-radio>
          </v-radio-group>
          <div class="flex justify-end">
            <v-btn
              @click="grantBoardAccessDialog = !grantBoardAccessDialog"
              small
              color="pink"
              class="ml-1"
              style="text-transform: capitalize"
              outlined
              >Close</v-btn
            >
            <v-btn
              small
              color="primary"
              class="ml-1"
              style="text-transform: capitalize"
              outlined
              type="submit"
              >Save</v-btn
            >
          </div>
        </form>
        </div>
      </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["data", "grantBoardAccessDialog"],
  data() {
    return {
      boardMemberLevel: null,
    };
  },
  methods: {
    async grantBoardAccess() {
      this.$store.dispatch("showLoadingSpinner", true);
      var input = {
        input: {
          userId: this.data.id,
          newPostion: this.boardMemberLevel,
          boardUniqueId: localStorage.getItem("my-boardUniqueId"),
        },
      };
      await this.$store.dispatch("grantUserAccessToBoard", input);
      await this.$store.dispatch('allBoardUsers')
      this.$store.dispatch("showLoadingSpinner", false);
      this.grantBoardAccessDialog = false;
    },
  },
};
</script>

<style></style>
