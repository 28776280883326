<template>
  <v-row justify="center">
    <v-dialog v-model="editMeetingDialog"
              :key="editKey"
              persistent max-width="50%">
      <v-card>
        <v-card-title>
        <span class="text-h6 flex items-center"
        ><icon name="meeting_add" width="w-5" height="h-5" class="mr-2"></icon> Edit Meeting </span
        >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-text-field
                    v-model="meetingTitle"
                    :counter="100"
                    :rules="nameRules"
                    label="Title *"
                    prepend-icon="mdi-text-recognition"
                    required
                >
                </v-text-field>
                <v-select
                    v-model="meetingCategory"
                    :items="meetingCategories"
                    item-text='title'
                    item-value='uniqueId'
                    label="Meeting Category *"
                    prepend-icon="mdi-shape-outline"
                    required
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <div class="flex justify-end text-white">
            <v-btn color="red"
                   class="text-capitalize mr-2"
                   right
                   small
                   @click="$emit('closeEditMeeting')"
            >
              <icon name="close" width="w-5" height="h-5" color="#ffffff"></icon>
              <span class="text-white">Cancel</span>
            </v-btn>
            <v-btn color="primary"
                   class="text-capitalize"
                   right
                   small
                   @click="editManagementCommitteeMeeting"
            >
              <icon name="save" width="w-5" height="h-5" color="#ffffff"></icon>
              Save
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LocationSettings
        :openLocationDialog="openLocationDialog"
        @closeNewLocationDialog="openLocationDialog = !openLocationDialog"
    />
  </v-row>
</template>

<script>
import LocationSettings from "@/modules/system_management/eboard_settings/LocationSettings.vue";
import Icon from "@/views/Icon.vue";

export default {
  name: "EditManagementCommitteeMeeting",
  props: ["editMeetingDialog", "currentMeetingDetails", "meetingUniqueId"],
  components: {Icon, LocationSettings },

  data: () => ({
    openLocationDialog: false,
    meetingDate: "",
    startTime: "",
    meetingCategory: "",
    endTime: "",
    meetingTitle: "",
    editKey: 23,
    nameRules: [
      (v) => !!v || "Meeting title is required",
    ],
  }),

  watch: {
    meetingStartTime() {
      this.startTime = this.currentMeetingDetails.meetingStartTime
    },
    meetingEndTime() {
      this.endTime = this.currentMeetingDetails.meetingEndTime
    },
    meetingDatee() {
      this.meetingDate = this.currentMeetingDetails.meetingDate
    },
    async editMeetingDialog() {
      if (this.editMeetingDialog) {
        await this.$store.dispatch("loadBoardMeetingLocations");
        this.meetingTitle = this.currentMeetingDetails.meetingTitle
        this.meetingCategory = this.currentMeetingDetails.meetingCategory.id
      }
    }
  },

  computed: {
    locations() {
      return this.$store.state.SettingsStore.boardLocations;
    },
    meetingCategories() {
      return this.$store.state.ManagementCategoriesStore.managementMeetingCategories
    },
  },

  methods: {

    editManagementCommitteeMeeting() {

      const meeting = {
        meetingDate: this.currentMeetingDetails.meetingDate,
        meetingStartTime: this.currentMeetingDetails.meetingStartTime,
        meetingEndTime: this.currentMeetingDetails.meetingEndTime,
        meetingLocation: this.currentMeetingDetails.meetingLocation.id,
        meetingUniqueId: this.currentMeetingDetails.id,
        meetingCategory: this.meetingCategory,
        meetingTitle: this.meetingTitle
      };

      this.$store.dispatch("editManagementCommitteeMeeting", meeting);

      this.meetingDate = null;
      this.startTime = null;
      this.endTime = null;

      this.$emit("closeEditMeeting");

    },
  },
}
</script>

<style scoped>

</style>