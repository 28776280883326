<template>
  <v-banner outlined  type="">
    <v-chart class="chart" :option="option" />
  </v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props:['dataObject','graphTitle'],
  name: "HelloWorld",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {
    };
  },
  computed:{
    option(){
      return {
        title: {
          text: this.graphTitle,
          top: "left",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/> <strong> {a} : {c} <strong/><br/> <strong>Meeting by (%) : <strong/> {d} ",
        },
        legend: {
          orient: "horizontal",
          bottom: "left",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            name: "Number of meeting",
            type: "pie",
            radius: ["0%", "70%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 2,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.dataObject.length >0 ? this.dataObject: [],
          },
        ],
      }
    }
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>




