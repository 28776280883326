<template>
  <v-container fluid>
    <v-row class="pt-3">
      <v-col class="d-flex align-content-center" cols="12" sm="12">
        <v-btn
            :to="'/management/management_directives_categories'"
            class="mr-3"
            color="primary white--text"
            link
            outlined
            right
            rounded
            small
        >
          <v-icon>mdi-arrow-left</v-icon>
          Back
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p>List of Directives agreed from Board Meetings</p>
      </v-col>


      <v-col class="d-flex" cols="12" sm="3">
        <v-autocomplete
            v-model="directiveObj.designationUniqueId"
            :items="boardDesignationsList"
            item-text="designationName"
            item-value="designationUniqueId"
            dense
            label="Filter by Designation *"
            @change="directiveObj.meetingUniqueId=null; loadBoardDirectives();"
        ></v-autocomplete>
      </v-col>

      <v-col class="d-flex" cols="12" sm="3">
        <v-autocomplete
            v-model="directiveObj.meetingUniqueId"
            :items="meetingsList"
            item-text="name"
            item-value="id"
            dense
            label="Filter by Meeting *"
            @change="directiveObj.designationUniqueId=null; loadBoardDirectives();"
        ></v-autocomplete>
      </v-col>

      <v-spacer></v-spacer>
      <v-btn color="primary" outlined rounded small @click="openDownloadDirectivesModal">
        <v-icon> mdi-tray-arrow-down</v-icon>
        Download Directives
      </v-btn>
    </v-row>

    <v-row>
      <v-col sm="12">
        <v-card elevation="6">
          <v-tabs v-model="tab" color="#3f51b5" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon :color="tab.color" class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <ActionCard
                  v-for="directive in boardDirectives.data"
                  :key="directive.directiveUniqueId"
                  :directive="directive"
              />
              <v-card-actions>
                <v-btn
                    @click="paginateBoardDirectives(boardDirectives.page.previousPageNumber)"
                    v-if="boardDirectives.page.hasPreviousPage" outlined small
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  Previous Page ({{ boardDirectives.page.previousPageNumber }})
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    @click="paginateBoardDirectives(boardDirectives.page.nextPageNumber)"
                    v-if="boardDirectives.page.hasNextPage" outlined small
                >
                  Next Page ({{ boardDirectives.page.nextPageNumber }})
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-tab-item>

            <v-tab-item>
              <ActionCard
                  v-for="directive in completedBoardDirectives.data"
                  :key="directive.directiveUniqueId"
                  :directive="directive"
              />
              <v-card-actions>
                <v-btn
                    @click="paginateBoardCompletedDirectives(completedBoardDirectives.page.previousPageNumber)"
                    v-if="completedBoardDirectives.page.hasPreviousPage" outlined small
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  Previous Page ({{ completedBoardDirectives.page.previousPageNumber }})
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    @click="paginateBoardCompletedDirectives(completedBoardDirectives.page.nextPageNumber)"
                    v-if="completedBoardDirectives.page.hasNextPage" outlined small
                >
                  Next Page ({{ completedBoardDirectives.page.nextPageNumber }})
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <DownloadDirectivesDocumentModal
        :showContextMenu="showContextMenu"
        @closeDownloadDirectiveModal="showContextMenu=false"
        :filterObj="filterObj"
    />
  </v-container>
</template>

<script>

import ActionCard from "./ManagementBoardActionCard.vue";
import DownloadDirectivesDocumentModal from "@/modules/meeting_management/eboard-actions/DownloadDirectivesDocumentModal";

export default {
  components: {ActionCard, DownloadDirectivesDocumentModal},

  data() {
    return {
      tab: true,
      isLoading: false,
      tabs: [
        {title: "Current Actions", icon: "mdi-pin-outline", color: "orange"},
        {
          title: "Completed Actions",
          icon: "mdi-check-decagram-outline",
          color: "success",
        },
      ],
      filterMeeting: null,
      boardDesignation: '',
      filterOwner: null,
      filterBy: '',
      filterObj: { directiveAssignee: '' },
      showContextMenu: false,
      directiveObj: {
        boardUniqueId: localStorage.getItem("my-boardUniqueId"),
        designationUniqueId: null,
        meetingUniqueId: null,
        pageNumber: 1,
      }
    };
  },

  computed: {
    meetingsList() {
      const temp_list = [];
      const all_meetings = this.$store.state.MeetingsStore.boardMeetingsList;

      all_meetings.forEach((meeting) => {
        temp_list.push({
          name: meeting.meetingTitle + ' - ' + new Date(meeting.meetingDate).toLocaleDateString(),
          id: meeting.primaryKey,
        });
      });

      temp_list.push({name: "All Meetings", id: "all"});
      return temp_list;
    },

    boardDirectives() {
      return this.$store.getters.getBoardAssignedDirectivesList;
    },

    completedBoardDirectives() {
      return this.$store.getters.getBoardCompletedDirectivesList;
    },

    boardDesignationsList() {
      return this.$store.state.SettingsStore.boardDesignationsList;
    }
  },

  methods: {
    async filterDirectiveByMember(value) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadAssignedDirectives", {filterKey: value, filterBy: "Owner"});
      this.filterMeeting = '';
      this.boardDesignation = '';
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async filterDirectiveByDesignation(value) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadAssignedDirectives", {filterKey: value, filterBy: "Designation"});
      this.filterOwner = null;
      this.filterMeeting = null;
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async filterDirectiveByMeeting(value) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadAssignedDirectives", {filterKey: value, filterBy: "Meeting"});
      this.filterOwner = null;
      this.boardDesignation = null;
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    openDownloadDirectivesModal() {

      if (!this.directiveObj.designationUniqueId && this.directiveObj.meetingUniqueId) {
        this.filterBy = 'Meeting'
        this.filterObj.designationUniqueId =  ''
        this.filterObj.meetingUniqueId =  this.directiveObj.meetingUniqueId
      }
      else if (this.directiveObj.designationUniqueId && !this.directiveObj.meetingUniqueId) {
        this.filterBy = 'Designation'
        this.filterObj.meetingUniqueId =  ''
        this.filterObj.designationUniqueId =  this.directiveObj.designationUniqueId
      }
      else {
        this.filterBy = ''
        this.filterObj.meetingUniqueId =  ''
        this.filterObj.designationUniqueId =  ''
      }

      this.filterObj.managementUniqueId = localStorage.getItem("my-boardUniqueId") ;
      this.filterObj.weeklyReportDirective = false;
      this.filterObj.showAssignee = false;
      this.filterObj.filterBy = this.filterBy

      this.showContextMenu = true;
    },

    async paginateBoardDirectives(pageNumber) {
      this.directiveObj.pageNumber = pageNumber;
      await this.$store.dispatch("loadBoardAssignedDirectives", this.directiveObj);
    },

    async paginateBoardCompletedDirectives(pageNumber) {
      this.directiveObj.pageNumber = pageNumber;
      await this.$store.dispatch("loadBoardCompletedDirectives", this.directiveObj);
    },

    async loadBoardDirectives() {
      if (this.directiveObj.meetingUniqueId === 'all') this.directiveObj.meetingUniqueId = null
      await this.$store.dispatch("loadBoardAssignedDirectives", this.directiveObj);
      await this.$store.dispatch("loadBoardCompletedDirectives", this.directiveObj);
      // await this.$store.dispatch("loadManagementCompletedDirectives", this.directiveObj);
    },
  },

  async mounted() {

    await this.loadBoardDirectives()

    // await this.$store.dispatch("loadAssignedDirectives", {filterKey: "None", filterBy: "Owner"});
    await this.$store.dispatch("loadBoardMambers");
    await this.$store.dispatch("loadMeetingByBoardUniqueId");
  },
};
</script>

<style>
</style>
