import {apolloClient} from '@/vue-apollo'

import replyMeetingNotice from '../../graphql/eboard-invitations-graphql/replyMeetingNotice.gql'
import allMeetingNotice from '../../graphql/eboard-invitations-graphql/allMeetingNotice.gql'
import createMeetingNotice from '../../graphql/eboard-invitations-graphql/createMeetingNotice.gql'
import newBoardMemberInvitation from '../../graphql/eboard-invitations-graphql/boardMemberInvitation.gql'
import replyToBoardMemberInvitation from '../../graphql/eboard-invitations-graphql/replyToBoardMemberInvitation.gql'
import verifyAccount from '../../graphql/eboard-auth-graphql/verifyAccount.gql'
import sendPasswordResetEmail from '../../graphql/eboard-auth-graphql/sendPasswordResetEmail.gql'
import passwordReset from '../../graphql/eboard-auth-graphql/passwordReset.gql'
import meetingAbsenteesWithApology from '../../graphql/eboard-meetings-graphql/meetingAbsenteesWithApology.gql'


import swal from 'sweetalert2'


export default {
    state: {
        invitationList: [],
        BoardMemberswithApologiesList: [],

    },
    getters: {
        getMeetingNotifications: state => state.invitationList,
    },
    mutations: {
        addAbsenteeswithApology(state, apologyObj) {
            state.BoardMemberswithApologiesList = apologyObj
        },
        cleanInvitation(state) {
            state.invitationList = []
        },
        addInvitattion(state, invatationObj) {
            state.invitationList = invatationObj
        },
        updateInvitation(state, meeting) {
            state.invitationList.forEach(invitation => {
                if (invitation.invitationMeeting == meeting) {
                    invitation.invitationIsActive = false
                }
            })
        }
    },

    actions: {
        async loadBoardMemberswithApologies(context, currentMeeting) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingAbsenteesWithApology,
                variables: {
                    meetingUniqueId: currentMeeting
                }
            }).then((response) => {
                const apologies = response.data.meetingAbsenteesWithApology

                context.commit('addAbsenteeswithApology', apologies)

            }).catch(async (error) => {
                console.error(error.messages);
            })

        },

        async loadBoardInvitations(context) {
            context.commit('cleanInvitation')
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMeetingNotice,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const invitations = response.data.allMeetingNotice
                context.commit('addInvitattion', invitations)
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async replyMeetingNotice(context, data) {
            await apolloClient.mutate({
                mutation: replyMeetingNotice,
                variables: {
                    noticeAccepted: data.noticeAccepted,
                    noticeMeeting: data.noticeMeeting,
                    noticeReplyExplanations: data.noticeReplyExplanations,
                }
            }).then(() => {
                context.commit('updateInvitation', data.noticeMeeting)
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Meeting invitation response sent successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },

        async sendMeetingNotice({dispatch}, notice) {
            await apolloClient.mutate({
                mutation: createMeetingNotice,
                variables: {
                    notice: notice.notice
                }
            }).then((response) => {
                console.log(response.data.createMeetingNotice.meetingNotice);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Meeting notice sent successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                if (notice.notice.generateBoardPack === true) {
                    dispatch('createBoardMeetingPacks', notice.meeting)
                }
            })
        },

        async activateUserAccount(context, ActivationToken) {
            try {
                const {data} = await apolloClient.mutate({
                    mutation: verifyAccount,
                    variables: {
                        token: ActivationToken
                    }
                })
                if (data.verifyAccount.success) {
                    await swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Congratulations . . . ! \nYour Account has been activated. you will be redirected to set your password shortly.",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    await swal.fire({
                        toast: true,
                        icon: 'error',
                        title: "Sorry - that didn't work. The provided link was already used.\n Please contact your institution administrator.",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
        async sendPasswordResetEmail(context, email) {
            try {
                const {data} = await apolloClient.mutate({
                    mutation: sendPasswordResetEmail,
                    variables: {
                        email: email
                    }
                })
                if (data.sendPasswordResetEmail.success) {
                    await swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Success . . . ! \nThe email has been sent to your account successfully.",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    await swal.fire({
                        toast: true,
                        icon: 'error',
                        title: "Sorry - that didn't work. The provided email was not valid.\n Please contact your institution administrator.",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                await this.$router.push('/login')
            } catch (e) {
                console.log(e)
            }
        },
        async passwordReset(context, passwordData) {
            try {
                const {data} = await apolloClient.mutate({
                    mutation: passwordReset,
                    variables: {
                        newPassword1: passwordData.newPassword1,
                        newPassword2: passwordData.newPassword2,
                        token: passwordData.token
                    }
                })
                if (data.passwordReset.success) {
                    await swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "Success . . . ! \nThe password has been changed successfuly.",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    await swal.fire({
                        toast: true,
                        icon: 'error',
                        title: "Sorry - that didn't work. The provided email was not valid.\n Please contact your institution administrator.",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                await this.$router.push('/login')
            } catch (e) {
                console.log(e)
            }
        },


        async sendBoardInvitations(context, invitationData) {
            await apolloClient.mutate({
                mutation: newBoardMemberInvitation,
                variables: {
                    invitation: invitationData
                }
            }).then(() => {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Board invitation mail sent successfully',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.log(error);
            })


        },
        async replyBoardInvitations(context, invitationToken) {
            await apolloClient.mutate({
                mutation: replyToBoardMemberInvitation,
                variables: {
                    invitationToken: invitationToken
                }
            }).then((response) => {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: response.data.replyToBoardMemberInvitation.invitationReply.saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                console.error(error.messages);
            })
        }

    }
}
