<template>
  <div class="text-center">
    <v-dialog v-model="OpenActionEditor" persistent width="60%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="success">mdi-checkbox-marked-outline</v-icon>
          {{ actionName }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="actionTittle"
                  :counter="100"
                  label="Directive"
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6"
                >
                <v-select
                v-model="asignee"
                  :items="designationsList()"
                  chips
                  clearable
                  item-text="designationShortForm"
                  item-value="designationUniqueId"
                  label="Directive Asignee*"
                  required
                  prepend-icon="mdi-account-star-outline"
                >
                </v-select>
              </v-col>
              <v-spacer></v-spacer>

              <v-col cols="12" sm="6" md="4">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Due Date *"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                    :min="today"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <Editor
              :content="content"
              :editorOption="editorOption"
              @onEditorChange="onEditorChange($event)"
              style="height: 180px"
            />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseActionEditor')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn color="success" outlined small @click="saveMinutesDirective" v-if="actionName === 'Add New Directive'">
            <v-icon small>mdi-floppy</v-icon> Save
          </v-btn>
          <v-btn color="success" outlined small @click="editMinutesDirective" v-if="actionName === 'Edit Directive'">
            <v-icon small>mdi-floppy</v-icon> Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Editor from "../e_board_shared/fields/Editor.vue";

export default {
  props: ["OpenActionEditor", "actionName", "selectedAgenda", "selectedDirective"],

  components: {
    Editor,
  },

  data: () => ({
    asignee: null,
    content: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: "",
    actionTittle: "",
    menu1: false,
    editorOption: {
      placeholder: "Describle about directive here . . . ",
      theme: "snow",
    },
  }),

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },

    computedSelectedDirective() {
      this.asignee = this.selectedDirective.directiveOwner.id;
      this.actionTittle = this.selectedDirective.directiveTitle;
      this.dateFormatted = this.selectedDirective.directiveDuedate;
      this.content = this.selectedDirective.directiveDescriptions;
    },
  },

  computed: {
    boardDesignations(){
      return this.$store.state.SettingsStore.boardDesignationsList
    },

    today() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
    },

    computedSelectedDirective() {
      return this.selectedDirective.primaryKey
    },

    currentMeeting() {
      return this.$store.state.MeetingsStore.selectedMeetingDetails
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    listBoardMembers() {
      var usersList=[]
      var members=this.$store.state.MembersStore.boardMembers;
      var managements=this.$store.state.ManagementsStore.managementsMembers;

      managements.forEach(management => {
        usersList.push({
          id:management.id,
          name: management.firstName+" "+management.lastName
        })
      });

      members.forEach(member => {
        usersList.push({
          id:member.id,
          name: member.firstName+" "+member.lastName
        })
      });

      const ids = usersList.map(user => user.id)
      const filtered = usersList.filter(({id}, index) => !ids.includes(id, index + 1))

      return filtered;
    },
  },

  methods: {
    onEditorChange(event) {
      this.content = event;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async saveMinutesDirective(){
      await this.$store.dispatch("showLoadingSpinner", true);
      const dirData = {
        directiveAssingee: this.asignee,
        decisionContents: this.content,
        directiveDueDate: this.dateFormatted,
        decisionItemKey: this.selectedAgenda.itemKey,
        directiveTitle: this.actionTittle
      };
      await this.$store.dispatch('saveDirectivesMinutes',dirData)
      this.content = "";
      this.dateFormatted = "";
      this.asignee = "";
      this.actionTittle = "";
      this.$emit("CloseActionEditor");
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async editMinutesDirective(){
      await this.$store.dispatch("showLoadingSpinner", true);
      const dirData = {
        directiveAssingee: this.asignee,
        directiveContents: this.content,
        directiveDueDate: this.dateFormatted,
        directiveItemKey: this.selectedAgenda.itemKey,
        directiveTitle: this.actionTittle,
        directiveUniqueId: this.selectedDirective.directiveUniqueId
      };
      // console.log(dirData);
      await this.$store.dispatch('editDirectivesMinutes',dirData);
      await this.$store.dispatch('loadAllDirectiveMinutes', this.selectedAgenda.itemKey);
      this.content = "";
      this.dateFormatted = "";
      this.asignee = "";
      this.actionTittle = "";
      this.$emit("CloseActionEditor");
      await this.$store.dispatch("showLoadingSpinner", false);
    }
  },
};
</script>
