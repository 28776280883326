<template>
  <v-container fluid>
    <div
      style="padding-top: 30px"
      v-for="vote in votes"
      :key="vote"
    >
      <v-card class="mx-auto" max-width="90%" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              <v-chip
                v-if="!vote.voteIsActive"
                color="pink"
                outlined
                small
                style="text-transform: capitalize"
                >Closed</v-chip
              >
              <v-chip
                v-else
                color="success"
                outlined
                small
                style="text-transform: capitalize"
                >In Progress</v-chip
              >
            </div>
            <v-list-item-title class="text-wrap mb-1" >
              <strong>{{vote.voteMotion}}</strong>
            </v-list-item-title>
          </v-list-item-content>

          <div class="text-overline mb-3">
            <strong>My Vote </strong>
            <span
            v-if="!vote.voterIsVoted"
              style="text-transform: capitalize; color: rgba(216, 82, 69, 0.95)"
            >
          Vote Awaiting . . .
            </span>
            <v-list v-else subheader two-line>
              <v-list-item>

                <v-list-item-avatar v-if="vote.voterSelection=='AB'">
                  <v-icon class="success lighten-1" dark> mdi-thumbs-up-down </v-icon>
                </v-list-item-avatar>

                    <v-list-item-avatar v-if="vote.voterSelection=='SP'">
                  <v-icon class="success lighten-1" dark> mdi-thumb-up </v-icon>
                </v-list-item-avatar>

                    <v-list-item-avatar v-if="vote.voterSelection=='OP'">
                  <v-icon class="success lighten-1" dark> mdi-thumb-down </v-icon>
                </v-list-item-avatar>

                <v-list-item-content >
                  <v-list-item-title>
                    {{vote.voterSelection==="AB"?"ABSTAIN":(vote.voterSelection==="SP"?"SUPPORT":(vote.voterSelection==="OP"?"OPPOSE":""))}}

                  </v-list-item-title>

                  <!-- <v-list-item-subtitle>
                    You approve this motion</v-list-item-subtitle
                  > -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <v-alert dense  v-if="vote.voterIsVoted"
            ><v-icon color="success">mdi-check-circle-outline</v-icon> Approved
            | You Supported
            <p>Vote has been passed</p></v-alert
          >

          <v-list-item-avatar tile size="50" color="white"
            ><v-img src="/vote.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-card-actions  v-if="!vote.voterIsVoted">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                right
                top
                absolute
                rounded
                color="primary"
                small
                @click="voteDialog = true"
              >
                <v-icon>mdi-vote</v-icon>
              </v-btn>
            </template>
            <span>Cast Your Vote</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </div>

    <v-dialog v-model="voteDialog" max-width="60%">
      <v-card>
        <v-card-title><v-icon>mdi-vote-outline</v-icon> Vote</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="radios">
            <template v-slot:label>
              <div>
                Your vote is recorded as <strong>anonymous</strong> in the
                minutes
              </div>
            </template>
            <v-radio value="Support">
              <template v-slot:label>
                <div>
                  <v-icon color="success">mdi-thumb-up</v-icon
                  ><strong class="success--text">Support</strong>
                </div>
              </template>
            </v-radio>
            <v-radio value="Oppose">
              <template v-slot:label>
                <div>
                  <v-icon color="warning">mdi-thumb-down</v-icon>
                  <strong class="warning--text">Oppose</strong>
                </div>
              </template>
            </v-radio>
            <v-radio value="Abstain">
              <template v-slot:label>
                <div>
                  <v-icon color="primary">mdi-minus</v-icon>
                  <strong class="primary--text">Abstain</strong>
                </div>
              </template>
            </v-radio>

            <template>
              <div>
                Vote carefully your vote <strong>can not be edited</strong>
              </div>
            </template>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink darken-1"
            class="white--text"
            small
            @click="voteDialog = false"
          >
            Cancelddddddddddddd
          </v-btn>
          <v-btn
            color="blue darken-1"
            small
            class="white--text"
            @click="voteDialog = false"
          >
            Vote
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog v-model="confirmationDialog" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure of your Vote?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="voteDialog = false">No</v-btn>
              <v-btn color="blue darken-1" text @click="voteDialog = false">Yes</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      voteDialog: false,
    };
  },

  computed: {
    votes() {
      return this.$store.state.VotesStore.activeVotes;
    }, 
      enums() {
      return this.state.enumsResponding;
    },
     usersuffix() {
     var suffix = this.$store.state.AuthStore.user.profileUser.edges[0].node.userProfileTitle
  return suffix
   },
  },

  async mounted() {
    await this.$store.dispatch("loadActiveVotes");
  },
};
</script>

<style>
</style>