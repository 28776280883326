<template>
<v-dialog  v-model="OpenAnnotationDialog" persistent max-width="100%" >
<v-card>
    <v-card-text >
        <v-row>
        <v-spacer></v-spacer>
        <v-card-actions class="m-3">
          <v-btn
            color="blue darken-1"
            text
            @click="closeAnnotedPack"
          >
            Close
          </v-btn>
    </v-card-actions>
        </v-row>

      <WebViewerAnnotate
          :initialDoc="MEDIA_SERVER+document"
          :document="document"
      />
<!--        <PSPDFKit :document="document" :documentUrl="MEDIA_SERVER+document"/>-->
        <!-- <div id="appp">
            <WebViewer :path="`${publicPath}lib`"/>
        </div> -->
    </v-card-text>
        <v-card-title style="font-size:16px">

    </v-card-title>

</v-card>
</v-dialog>

</template>

<script>
// import PSPDFKit from '../e_board_annotations/pspdfkit.vue'
// import WebViewer from '../e_board_annotations/WebViewer.vue'
import WebViewerAnnotate from "@/modules/meeting_management/eboard-boardpacks/viewer/WebViewerAnnotate.vue";

export default {
    // props:['OpenAnnotationDialog'],
    data(){
        return{
            backtrackUrl:'/eboard_packs_annotations',
            publicPath: process.env.BASE_URL,
            OpenAnnotationDialog:true
        }
    },
    components: {
      WebViewerAnnotate,
        // PSPDFKit,
        // WebViewer
    },
    methods:{
        closeAnnotedPack(){
            this.OpenAnnotationDialog=false
            this.$router.push(this.backtrackUrl);
        }
    },
    computed:{
        document(){
            return this.$store.state.BoardPacksStore.opendPack
        }
    }
}
</script>


<style>
#appp {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

