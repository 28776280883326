var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background":"white"}},[_c('v-card-title',{staticClass:"text-h6 grey lighten-2"},[_vm._v(" Board Members Comments "),_c('v-spacer'),_c('div',{staticClass:"form-check form-switch w-full sm:w-auto mt-3 sm:mt-0"},[_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){return _vm.$emit('closeCommentsDialog')}}},[_c('v-icon',{attrs:{"large":"","color":"pink"}},[_vm._v("mdi-close-circle")])],1)],1)],1),_c('div',{staticClass:"intro-y col-span-12 lg:col-span-8 2xl:col-span-9 mr-2"},[_vm._l((_vm.resolutionComments),function(comment){return [_c('div',{key:comment.commentsUniqueId},[(
            (comment.commentsCreatedby || {}).email != _vm.userprofile.username
          )?_c('div',{staticClass:"overflow-y-scroll scrollbar-hidden px-5 pt-5 flex-1 w-full"},[_c('div',{staticClass:"chat__box__text-box flex items-end float-left mb-4"},[_c('div',{staticClass:"w-10 h-10 hidden sm:block flex-none image-fit relative mr-5"},[(comment.commentsCreatedby.profileUser.edges.length)?_c('img',{staticClass:"rounded-full",attrs:{"alt":"User","src":_vm.MEDIA_SERVER +
                  comment.commentsCreatedby.profileUser.edges[0].node
                    .userProfilePhoto}}):_vm._e()]),_c('div',{staticClass:"bg-slate-100 dark:bg-darkmode-400 px-4 py-3 text-slate-500 rounded-r-md rounded-t-md"},[_vm._v(" "+_vm._s(comment.commentsDescriptions)+" "),_c('div',{staticClass:"mt-1 text-xs text-slate-500"},[_c('small',[_vm._v(_vm._s(_vm._f("formatDate")(comment.commentsCreateddate))+" by ")]),_c('small',[_c('strong',[_vm._v(_vm._s(comment.commentsCreatedby.firstName)+" "+_vm._s(comment.commentsCreatedby.lastName))])])])])])]):_vm._e(),_c('div',{staticClass:"clear-both"}),(
            (comment.commentsCreatedby || {}).email == _vm.userprofile.username
          )?_c('div',{staticClass:"chat__box__text-box flex items-end float-right mb-4 px-5 pt-5 flex-1"},[_c('div',{staticClass:"bg-primary px-4 py-3 text-white rounded-l-md rounded-t-md"},[_vm._v(" "+_vm._s(comment.commentsDescriptions)+" "),_c('div',{staticClass:"mt-1 text-xs text-white text-opacity-80"},[_c('small',[_vm._v(_vm._s(_vm._f("formatDate")(comment.commentsCreateddate))+" by ")]),_c('small',[_c('strong',[_vm._v(_vm._s(comment.commentsCreatedby.firstName)+" "+_vm._s(comment.commentsCreatedby.lastName))])])])]),_c('div',{staticClass:"w-10 h-10 hidden sm:block flex-none image-fit relative ml-5"},[(comment.commentsCreatedby.profileUser.edges.length)?_c('img',{staticClass:"rounded-full",attrs:{"alt":"User","src":_vm.MEDIA_SERVER +
                comment.commentsCreatedby.profileUser.edges[0].node
                  .userProfilePhoto}}):_vm._e()])]):_vm._e()])]}),_c('div',{staticClass:"clear-both"}),_c('div',{staticClass:"pt-4 pb-10 sm:py-4 flex items-center border-t border-slate-200/60 dark:border-darkmode-400"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.commentsDescriptions),expression:"commentsDescriptions"}],staticClass:"ma-2 form-control dark:bg-darkmode-600 h-16 resize-none border-transparent px-5 py-3 shadow-none focus:border-transparent focus:ring-0",attrs:{"rows":"1","placeholder":"Type your message or comments..."},domProps:{"value":(_vm.commentsDescriptions)},on:{"input":function($event){if($event.target.composing){ return; }_vm.commentsDescriptions=$event.target.value}}}),_c('v-btn',{staticClass:"w-8 h-8 sm:w-10 sm:h-10 block bg-primary text-white rounded-full flex-none flex items-center justify-center mr-5",attrs:{"text":"","fab":"","small":""},on:{"click":_vm.sendResoutionComments}},[_c('v-icon',{staticClass:"white--text"},[_vm._v("mdi-send")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }