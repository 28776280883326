import {  apolloClient } from '@/vue-apollo'
import swal from 'sweetalert2'
import  uploadFile from '../../graphql/management-documents-graphql/uploadFile.gql'
import  addItemSupportingDocument from '../../graphql/management-documents-graphql/addItemSupportingDocument.gql'

export default {
    state: {
        managementItemAgendaSupportingDocs:[],
        managementItemMinutesSupportingDocs:[],
    },

    mutations:{
        cleanManagementAgendaDocuments(state){
            state.managementItemAgendaSupportingDocs=[]
        },
        addManagementSupportingAgendaDocuments(state,file){
            state.managementItemAgendaSupportingDocs.push(file)
        },
        addManagementSupportingMinutesDocuments(state,file){
            state.itemMinutesSupportingDocs.push(file)
        }
    },

    getters:{
        getManagementMeetingAgendaDocuments(state){
            return state.managementItemAgendaSupportingDocs
        },
        getManagementSelecteAgendaDocuments(state){
            return state.managementItemAgendaSupportingDocs
        }
    },

    actions: {
        async submitManagementFile(context,sendData){
            var SavedFile=null
            await apolloClient.mutate({
                mutation: uploadFile,
                loading: this.value,
                variables: {
                    file: sendData.file,
                },
                update: (store, { data: { uploadFile } }) => {
                     SavedFile={'fileName':uploadFile.fileName,'fullFileName':uploadFile.fullFileName}
                    if(sendData.type==='Agenda'){
                        context.commit('addManagementSupportingAgendaDocuments',SavedFile)
                    }else if(sendData.type==='Minutes'){
                        context.commit('itemMinutesSupportingDocs',SavedFile)       
                    }   
                },
            }).then(()=>{
                apolloClient.mutate({
                    mutation: addItemSupportingDocument,
                    variables: {
                        itemKey:sendData.data.itemDetails.itemKey,
                        documentName:SavedFile.fileName,
                        filePath:SavedFile.fullFileName,
                    },
                }).then((response)=>{
                        context.rootState.ManagementAgendasStore.selectedManagementAgenda.itemDetails.documentDetails.push({
                            fileName: response.data.addManagementItemSupportingDocument.newDocument.documentName,
                            fullFileName: response.data.addManagementItemSupportingDocument.newDocument.documentPath
                        });

                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'File Uploaded  successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },
        async addVottingDocuments(context,sendData){
            var SavedFile=null
            await apolloClient.mutate({
                mutation: uploadFile, loading: this.value,
                variables: {
                    file: sendData.file,
                },
                update: (store, { data: { uploadFile } }) => {
                     SavedFile={'fileName':uploadFile.fileName,'fullFileName':uploadFile.fullFileName}
                    if(sendData.type=='Agenda'){
                        context.commit('addManagementSupportingAgendaDocuments',SavedFile)
                    }else if(sendData.type=='Minutes'){
                        context.commit('itemMinutesSupportingDocs',SavedFile)       
                    }   
                },
            }).then(()=>{
                apolloClient.mutate({
                    mutation: addItemSupportingDocument,
                    variables: {
                        itemKey:sendData.data.itemDetails.itemKey,
                        documentName:SavedFile.fileName,
                        filePath:SavedFile.fullFileName,
                    },
                }).then((response)=>{
                        context.rootState.AgendasStore.selecteAgenda.itemDetails.documentDetails.push({
                            fileName: response.data.addItemSupportingDocument.newDocument.documentName,
                            fullFileName: response.data.addItemSupportingDocument.newDocument.documentPath
                        });
                }).then(()=>{
                    apolloClient.mutate({
                        mutation: addItemSupportingDocument,
                        variables: {
                            itemKey:sendData.data.itemDetails.itemKey,
                            documentName:SavedFile.fileName,
                            filePath:SavedFile.fullFileName,
                        },
                    }).then((response)=>{
                            context.rootState.AgendasStore.selecteAgenda.itemDetails.documentDetails.push({
                                fileName: response.data.addItemSupportingDocument.newDocument.documentName,
                                fullFileName: response.data.addItemSupportingDocument.newDocument.documentPath
                            });
    
                        swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'File Uploaded  successfully',
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }).catch(async (error) => {
                        await context.dispatch("showLoadingSpinner", false);
                        console.error(error.messages);
                        await swal.fire({
                            toast: true,
                            icon: 'error',
                            title: "Sorry, we encounter some issues when processing your request. Please try again or contact your adminstrator . . . !",
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: false,
                        })
                    })
                }).catch(async (error) => {
                    await context.dispatch("showLoadingSpinner", false);
                    console.error(error.messages);
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

    }
}