<template>
  <v-row justify="center">
    <v-dialog
      v-model="OpenEditAccessForm"
      persistent
      max-width="80%"
      :key="OpenEditAccessFormKey"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-seal</v-icon> Edit User Role</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="roleItem.name"
                  :rules="nameRules"
                  autofocus
                  :counter="100"
                  label="Role Name *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-expansion-panels multiple class="mb-0">
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      Assigned Permissions List
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container fluid>
                        <v-row>
                          <v-col
                            v-for="permission in roleItem.children"
                            :key="permission.id"
                            cols="12"
                            sm="3"
                            md="3"
                          >
                            <v-checkbox
                              :label="permission.name"
                              color="info"
                              :input-value="permission.name"
                              hide-details
                              @change="changeCurrentPermission(permission.id)"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      Permissions List
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container fluid>
                        <v-row>
                          <v-col
                            v-for="permission in permissionsList"
                            :key="permission.primaryKey"
                            cols="12"
                            sm="3"
                            md="3"
                          >
                            <v-checkbox
                              :label="permission.permission_name"
                              color="info"
                              :input-value="permission.checked"
                              hide-details
                              @change="
                                changeRemainedPermission(
                                  permission.permission_id
                                )
                              "
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            @click="$emit('CloseEditAccessForm')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="blue darken-1"
            fab
            small
            class="white--text"
            @click="editRolePermissions"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import swal from "sweetalert2";

export default {
  data: () => ({
    roleName: "",
    roleDescription: "",
    existedRoles: "",
    permissions: [],
    is_clonned: false,
    dialog: false,
    Tittle: "",
    OpenEditAccessFormKey: "first",
    nameRules: [
      (v) => !!v || "Role Name is required",
      (v) => v.length <= 100 || "Name must be less than 10 characters",
    ],
    roles: [],
  }),

  props: [
    "OpenEditAccessForm",
    "roleItem",
    "permissionsList",
    "rolePermissions",
  ],

  methods: {
    changeCurrentPermission(key) {
      this.$emit("changePermission", key);
    },

    changeRemainedPermission(key) {
      this.$emit("changeRemainedPermission", key);
    },

    async editRolePermissions() {
      await this.$store.dispatch("showLoadingSpinner", true);

      let permissions = [];
      let temp = this.permissionsList.filter((item) => item.checked === true);

      let temp1 = this.rolePermissions.filter((item) => item.checked === true);
      temp.forEach((item) => {
        permissions.push(item.permission_id);
      });

      temp1.forEach((item) => {
        permissions.push(item.permission_id);
      });

      var rolePermObj = {
        permissions: permissions,
        roleUniqueId: this.roleItem.id,
        roleName: this.roleItem.name,
      };

      await this.$store.dispatch("editUserRole", rolePermObj);
      await swal.fire({
        toast: true,
        icon: "success",
        title: "Role edited successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: false,
      });
      await this.$store.dispatch("showLoadingSpinner", false);
      this.$emit("CloseEditAccessForm");
    },
  },
};
</script>
