import {apolloClient} from '@/vue-apollo';

import getAllInstitutions from '@/graphql/admin-panel-graphql/institutions-graphql/getAllInstitutions.gql'
import createInstitutions from '@/graphql/admin-panel-graphql/institutions-graphql/createInstitutions.gql'
import {errorAlert, successAlert} from "@/services/notificationService";
import updateInstitutions
    from "@/graphql/admin-panel-graphql/institutions-graphql/updateInstitutions.gql.graphql";
import institutionLogoUploading from "@/graphql/admin-panel-graphql/institutions-graphql/institutionLogoUploading.gql.graphql";
import getAllInstitutionsCategory from '@/graphql/admin-panel-graphql/institutions-graphql/getAllInstitutionsCategory.gql'
import createInstitutionCategory from '@/graphql/admin-panel-graphql/institutions-graphql/createInstitutionCategory.gql'
import updateInstitutionCategory from '@/graphql/admin-panel-graphql/institutions-graphql/updateInstitutionCategory.gql'
import shiftInstitutionToNewCategory from '@/graphql/admin-panel-graphql/institutions-graphql/shiftInstitutionToNewCategory.gql'
import updateInstitutionAdministrator from '@/graphql/admin-panel-graphql/institutions-graphql/updateInstitutionAdministrator.gql'
export default {
    state: {
        institutionCategoryList: [],
        institutionList: [],
        logoFullName: ""
    },

    mutations: {
        addLogoFullName(state, name){
            state.logoFullName = name
        },

        clearLogoFullName(state) {
            state.logoFullName = ""
        },

        clearInstitutions(state) {
            state.institutionList = []
        },

        addInstitution(state, institutionObj){
            state.institutionList = institutionObj
        },

        addInstitutionCategory(state, categoryObj){
            state.institutionCategoryList = categoryObj
        },


        saveInstitutes(state, institutes) {
            state.institutionList.push(institutes)
            state.logoFullName = ""
        },

        saveInstitutionCategory(state, categories){
          state.institutionCategoryList.push(categories)
        },

        updateInstitutions(state, instituteObj) {
            state.institutionList = state.institutionList.map((institute) => {
                return institute.id == instituteObj.id ? instituteObj : institute
            })
        },

        updateInstitutionCategory(state, categoryObj){
            state.institutionCategoryList = state.institutionCategoryList.map((category)=> {
                return category.id == categoryObj.id ? categoryObj : category
            })
        }

    },

    getters: {
        getInstitutionList(state) {
            return state.institutionList
        },

        getInstitutionCategoryList(state){
            return state.institutionCategoryList
        },

        getLogoFullName(state){
            return state.logoFullName
        }
    },

    actions: {

        clearLogoFullName(context){
            context.commit('clearLogoFullName')
        },

        async loadInstitutionLists(context) {
            await context.dispatch('clearInstitutions')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getAllInstitutions
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                const results = response.data.getAllInstitutions
                if (results.response.code == 9000) {
                    context.commit('addInstitution', results.data)
                } else {
                    errorAlert(results.response.message)
                }
            })
        },

        async loadInstitutionCategoryLists(context) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getAllInstitutionsCategory
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                const results = response.data.getAllInstitutionsCategory
                if (results.response.code == 9000) {
                    context.commit('addInstitutionCategory', results.data)
                } else {
                    errorAlert(results.response.message)
                }
            })
        },

        async createInstitutionCategory(context, input){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createInstitutionCategory,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createInstitutionCategory
                if (result.response.code == 9000) {
                    context.commit('saveInstitutionCategory', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },


        async editInstitutionCategory(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateInstitutionCategory,
                variables: {
                    input: {
                        ...input,
                    }
                }
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateInstitutionCategory
                console.log(result)
                if (result.response.code == 9000) {
                    context.commit('updateInstitutionCategory', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },



        async createInstitutions(context, input){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createInstitutions,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createInstitutions
                if (result.response.code == 9000) {
                    context.commit('saveInstitutes', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

        async editInstitution(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateInstitutions,
                variables: {
                    input: {
                        ...input,
                    }
                }
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateInstitutions
                console.log(result)
                if (result.response.code == 9000) {
                    context.commit('updateInstitutions', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

        async uploadInstitutionLogo(context, file) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient
                .mutate({
                    mutation: institutionLogoUploading,
                    variables: {
                        file: file.file,
                    },
                })
                .then(async (response) => {
                    console.log("Full File Name")
                    console.log(response.data.institutionLogoUploading.fullFileName)
                    await context.commit("addLogoFullName", response.data.institutionLogoUploading.fullFileName);
                })
                .catch(async (error) => {

                    console.error(error.messages);
                }).finally(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
        },

        async shiftInstitutionToNewCategory(context, input){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: shiftInstitutionToNewCategory,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.shiftInstitutionToNewCategory
                if (result.response.code == 9000) {
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

        async updateInstitutionAdmin(context, input){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateInstitutionAdministrator,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateInstitutionAdministrator
                if (result.response.code == 9000) {
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },


    }
}