<template>
  <div class="text-center">
    <v-dialog v-model="openSearchFromRepository" persistent width="60%">
      <v-card>
        <v-card-title class="lighten-3"> Search from Repository </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-radio-group v-model="search_type" row>
            <v-radio label="Search by Document Name" value="name"></v-radio>
            <v-radio
              label="Search by Document Contents"
              value="contents"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-text v-if="search_type == 'name'">
          <v-autocomplete
            v-model="model"
            :items="RepositoryDocuments"
            :loading="isLoading"
            :search-input.sync="search"
            color="white"
            hide-selected
            item-text="fileName"
            item-value="primaryKey"
            label="Governance Repository by Document Name"
            placeholder="Start typing to Search"
            prepend-icon="mdi-folder-search-outline"
            return-object
          ></v-autocomplete>
        </v-card-text>
        <v-card-text v-if="search_type == 'contents'">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="11" md="11">
                <v-text-field
                  v-model="searchKey"
                  label="Search Keyword"
                  dense
                  placeholder="Word that can be searched"
                  outlined
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-btn
                  :disabled="isSearching"
                  :loading="searchLoading"
                  @click="searchDocumentsByKeyWord"
                  color="blue white--text"
                  small
                  fab
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text v-if="search_type == 'contents'">
          <v-chip
            class="mb-3"
            v-if="searchedContents.length > 0 && model"
            @click:close="model = null"
            close
            close-icon="mdi-close"
            color="green"
            outlined
          >
            {{ model.fileName }}</v-chip
          >

          <v-list style="background: rgba(84, 178, 255, 0.08)" subheader dense>
            <v-list-item
              v-for="file in searchedContents"
              :key="file.primaryKey"
            >
              <v-list-item-avatar tile size="200">
                <v-img
                  width="100px"
                  :src="MEDIA_SERVER + file.fileThumbnail"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="file.fileName"></v-list-item-title>

                <v-list-item-subtitle
                  >Uploaded: {{ file.fileDate }}</v-list-item-subtitle
                >
                <v-list-item-subtitle>{{ file.subtitle }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-actions>
                <v-btn
                  class="ma-1"
                  fab
                  small
                  color="primary white--text"
                  @click="previewFileContents(file)"
                >
                  <v-icon>mdi-file-eye-outline</v-icon>
                </v-btn>
                <v-btn
                  class="ma-1"
                  title="Select this Document"
                  v-if="model == null"
                  fab
                  small
                  color="success white--text"
                  @click="model = file"
                >
                  <v-icon>mdi-cursor-pointer</v-icon>
                </v-btn>
              </v-list-item-actions>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              model = null;
              $store.state.RepositoryStore.searchedDocumentsContents = [];
              $emit('closeLoadFromRepositorySearch');
            "
            class="ma-2"
            color="pink"
            outlined
            small
          >
            <v-icon small>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
            color="info"
            @click="selectDocumentFromRepository"
            class="ma-2"
            outlined
            small
          >
            Upload Document
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DocumentViwer
      v-if="OpenfileDialog == true"
      :docValue="docValue"
      :OpenfileDialog="OpenfileDialog"
      :title="documentTitle"
      @CloseDocumentViwer="OpenfileDialog = false"
    />
  </div>
</template>

<script>
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";
export default {
  components: {
    DocumentViwer,
  },

  props: [
    "openSearchFromRepository",
    "openLoadFromRepositorySearch",
    "closeLoadFromRepositorySearch",
    "data",
    "type",
  ],

  data: () => ({
    docValue: "",
    documentTitle: "",
    OpenfileDialog: false,
    search_type: "name",
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    searchKey: "",
    isSearching: false,
    searchLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    valid: true,
  }),

  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    RepositoryDocuments() {
      return this.$store.state.RepositoryStore.RepositoryDocuments;
    },
    searchedContents: {
      get() {
        return this.$store.state.RepositoryStore.searchedDocumentsContents;
      },
      set(newValue) {
        return newValue;
      },
    },
  },

  methods: {
    previewFileContents(file) {
      this.docValue = file.filePath;
      this.documentTitle = file.filename;
      this.OpenfileDialog = true;
    },
    async selectDocumentFromRepository() {
      if (this.type == "Circular") {

        this.$store.state.ResolutionStore.circularResolutionSupportingDocument.push(
          {
            fileName: this.model.fileName,
            fullFileName: this.model.filePath,
          }
        );
      } else {
        const sendData = {
          documentName: this.model.fileName,
          filePath: this.model.filePath,
          itemKey: this.data.itemDetails.itemKey,
          type: this.type,
          fileSource:"Repository"
        };

        await this.$store.dispatch("addItemSupportingDocument", sendData);
      }

      this.model = null;
      this.search = null;
      this.searchKey = "";
      this.$store.state.RepositoryStore.searchedDocumentsContents = [];

      this.$emit("closeLoadFromRepositorySearch");
    },

    async searchDocumentsByKeyWord() {
      var valid_data = true;
      valid_data = this.$refs.form.validate();
      if (valid_data) {
        this.isSearching = true;
        this.searchLoading = true;
        var tempdocumentList = [];
        this.RepositoryDocuments.forEach((document) => {
          tempdocumentList.push({
            primaryKey: document.primaryKey,
            fileName: document.fileName,
            filePath: document.filePath,
            fileDate: document.fileDate,
          });
        });

        const sendData = {
          searchKey: this.searchKey,
          documentList: tempdocumentList,
        };

        await this.$store.dispatch("searchThroughDocuments", sendData);
        this.isSearching = false;
        this.searchLoading = false;
      } else {
        return;
      }
    },
  },
  watch: {
    search_type: function () {
      if (this.search_type == "name") {
        this.searchKey = "";
        this.$store.state.RepositoryStore.searchedDocumentsContents = [];
      }
      if (this.search_type == "contents") {
        this.model = null;
      }
    },
  },

  async mounted() {
    await this.$store.dispatch("allRepositoryDocuments");
  },
};
</script>
