<template>
  <v-row justify="center">
    <v-dialog v-model="showContextMenu" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6"
          ><v-icon>mdi-download-outline</v-icon> Confirm to Download Directives</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-checkbox
                    v-model="isAssigneeColumn"
                    :label="`Show Responsible(Assignee)`"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              small
              class="white--text"
              @click="$emit('closeDownloadDirectiveModal')"
              outlined
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              small
              class="white--text"
              @click="downloadDirectivesDocument"
              outlined
          >
            <v-icon>mdi-download-outline</v-icon>
            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  props: ["showContextMenu", "filterObj"],

  data: () => ({
    isAssigneeColumn: false,
  }),

  methods: {
    async downloadDirectivesDocument() {
      this.filterObj.showAssignee = this.isAssigneeColumn;
      await  this.$store.dispatch("downloadManagementMeetingDirectives", this.filterObj);
      this.$emit('closeDownloadDirectiveModal');
    }
  }
};
</script>
