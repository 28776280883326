<template>
  <div class="text-center">
    <v-dialog v-model="OpenActionEditor" persistent width="60%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="success">mdi-checkbox-marked-outline</v-icon>
          {{ actionName }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                    v-model="actionTittle"
                    :counter="100"
                    label="Directive *"
                    prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6"
              >
                <v-select
                    v-model="asignee"
                    :items="designationsList()"
                    chips
                    clearable
                    item-text="designationShortForm"
                    item-value="designationUniqueId"
                    label="Owner*"
                    multiple
                    prepend-icon="mdi-account-star-outline"
                    required
                >
                </v-select>
              </v-col>
              <v-spacer></v-spacer>

              <v-col cols="12" md="4" sm="6">
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290px"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateFormatted"
                        hint="YYYY/MM/DD format"
                        label="Due Date *"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      :min="(currentMeeting||{}).meetingDate"
                      no-title
                      @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <Editor
                :content="content"
                :editorOption="editorOption"
                style="height: 180px"
                @onEditorChange="onEditorChange($event)"
            />

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              outlined
              small
              text
              @click="$emit('CloseActionEditor')"
          >
            <v-icon small>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn v-if="actionName === 'Add New Directive'" color="success" outlined small @click="saveMinutesDirective">
            <v-icon small>mdi-floppy</v-icon>
            Save
          </v-btn>
          <v-btn v-if="actionName === 'Edit Directive'" color="success" outlined small @click="editMinutesDirective">
            <v-icon small>mdi-floppy</v-icon>
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Editor from "../management_shared/fields/Editor.vue";

export default {
  props: ["OpenActionEditor", "actionName", "selectedAgenda", "selectedDirective"],

  components: {
    Editor,
  },

  data: () => ({
    asignee: null,
    content: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    dateFormatted: "",
    actionTittle: "",
    menu1: false,
    editorOption: {
      placeholder: "Write action here . . . ",
      theme: "snow",
    },
  }),

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },

    computedSelectedDirective() {
      let directiveOwner = [];
      this.selectedDirective?.directiveOwner.forEach(item => {
        directiveOwner.push(item.id);
      });
      this.asignee = directiveOwner;
      this.actionTittle = this.selectedDirective?.directiveTitle ? this.selectedDirective.directiveTitle : "";
      this.dateFormatted = this.selectedDirective?.directiveDuedate ? this.selectedDirective.directiveDuedate : "";
      this.content = this.selectedDirective?.directiveDescriptions ? this.selectedDirective.directiveDescriptions : "";
    },
  },

  computed: {
    listBoardMembers() {
      const usersList = [];
      const members = this.$store.state.MembersStore.boardMembers;
      const managements = this.$store.state.ManagementsStore.managementsMembers;

      managements.forEach((management) => {
        usersList.push({
          id: management.id,
          name: management.firstName + " " + management.lastName,
        });
      });

      members.forEach((member) => {
        usersList.push({
          id: member.id,
          name: member.firstName + " " + member.lastName,
        });
      });

      const ids = usersList.map((user) => user.id);
      return usersList.filter(
          ({id}, index) => !ids.includes(id, index + 1)
      );
    },

    computedSelectedDirective() {
      return this.selectedDirective
    },

    currentMeeting() {
      return this.$store.state.ManagementMeetingsStore.selectedManagementMeetingDetails
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    listManagementMembers() {
      const usersList = [];
      const management_members = this.$store.state.ManagementMembersStore.managementMembers;

      management_members.forEach(member => {
        if (!member.isLeader) {
          usersList.push({
            id: member.id,
            name: member.firstName + " " + member.lastName
          })
        }
      });
      return usersList.concat({id: "0", name: "All Management Members"});
    },
  },

  methods: {
    onEditorChange(event) {
      this.content = event;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      console.log(date)

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async saveMinutesDirective() {
      await this.$store.dispatch("showLoadingSpinner", true);
      const dirData = {
        directiveAssingee: this.asignee,
        decisionContents: this.content,
        directiveDueDate: this.dateFormatted,
        decisionItemKey: this.selectedAgenda.itemKey,
        directiveTitle: this.actionTittle
      };
      await this.$store.dispatch('saveManagementDirectivesMinutes', dirData)
      await this.$store.dispatch('loadAllManagementDirectiveMinutes', this.selectedAgenda.itemKey);
      this.content = "";
      this.dateFormatted = "";
      this.asignee = "";
      this.actionTittle = "";
      this.$emit("CloseActionEditor");
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async editMinutesDirective() {
      await this.$store.dispatch("showLoadingSpinner", true);
      const dirData = {
        directiveAssingee: this.asignee,
        directiveContents: this.content,
        directiveDueDate: this.dateFormatted,
        directiveItemKey: this.selectedAgenda.itemKey,
        directiveTitle: this.actionTittle,
        directiveUniqueId: this.selectedDirective.directiveUniqueId
      };
      await this.$store.dispatch('editManagementDirectivesMinutes', dirData);
      await this.$store.dispatch('loadAllManagementDirectiveMinutes', this.selectedAgenda.itemKey);
      this.content = "";
      this.dateFormatted = "";
      this.asignee = "";
      this.actionTittle = "";
      this.$emit("CloseActionEditor");
      await this.$store.dispatch("showLoadingSpinner", false);
    }
  },
};
</script>
