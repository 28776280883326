<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="8" md="8" class="mt-6">
        <v-card class="mx-auto" max-width="80%" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                <span class="text-h7">
                  <v-icon color="indigo">mdi-draw</v-icon>Digital Signature
                </span>
              </div>
              <v-list-item-subtitle class="ma-2">
                <strong>Signer: </strong> <span style="color:rgba(33, 53, 10, 0.8)">jackson.bakari@eganet.go.tz</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="ma-2">
                <strong>Signatory Title: </strong> <span style="color:rgba(42, 28, 174, 0.8)">ICTO</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="ma-2">
                <strong>Contact Info: </strong> <span style="color:rgba(174, 121, 28, 0.8)">+255755985162</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile size="130" color="grey">
              <v-img
                src="@/assets/sign.png"
                max-width="100%"
                aspect-ratio="1.0"
              ></v-img
            ></v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!setSignature"
              @click="setSignature = !setSignature"
              outlined
              small
              color="primary"
              class="mr-0"
            >
              <v-icon small>mdi-cog-sync</v-icon>
              Set Your Signature
            </v-btn>
            <v-btn
              v-if="setSignature"
              @click="setSignature = !setSignature"
              outlined
              small
              color="pink"
              class="mr-0"
            >
              <v-icon small>mdi-cog-sync</v-icon>
              Cancel
            </v-btn>
            <v-btn outlined small color="pink" class="mr-0">
              <v-icon small>mdi-cog-sync</v-icon>
              Revoke Signature
            </v-btn>

            <v-btn outlined class="ma-2" small color="success">
              <v-icon small>mdi-check-decagram-outline</v-icon>
              Confirm and Use
            </v-btn>
          </v-card-actions>
          <v-container v-if="setSignature">
            <v-row>
              <v-col cols="12" sm="12">
                <v-file-input
                  prepend-icon="mdi-file-upload-outline"
                  counter
                  truncate-length="22"
                  label="Select Your Scanned Signature"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="12">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="content"
                    label="Signer"
                    outlined
                    clearable
                    dense
                    :rules="nameRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="content"
                    label="Signatory Title"
                    outlined
                    clearable
                    dense
                    :rules="nameRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="content"
                    label="Contact Info"
                    outlined
                    clearable
                    dense
                    :rules="nameRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="content"
                    label="State your signature parafraise"
                    outlined
                    clearable
                    type="password"
                    dense
                    :rules="nameRules"
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
                <v-btn
                  class="blue--text mr-2"
                  fab
                  x-small
                  @click="submitApologyResponce(selectedNotice, false)"
                >
                  <v-icon>mdi-share-all-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      setSignature: false,
      nameRules: [(v) => !!v || "This is required"],
    };
  },

  mounted() {},
};
</script>

<style>
</style>