<template>
  <v-row justify="center">
    <v-dialog v-model="showContextMenu" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-calender </v-icon> Add Timetable
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="eventTittle"
                  :rules="nameRules"
                  :counter="100"
                  label="Activity Description *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-dialog ref="dialog1" v-model="modal1" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startTime"
                      label="Start Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="modal1" v-model="startTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-dialog ref="dialog" v-model="modal2" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endTime"
                      label="End Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="modal2"
                    v-model="endTime"
                    :min="startTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="responsiblePersonel"
                  :rules="nameRules"
                  :counter="100"
                  label="Responsible Personnel*"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-btn
            color="blue darken-1"
            fab
            small
            class="white--text"
            @click="createNewEventActivity"
          >
            <v-icon>mdi-send-check-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["showContextMenu"],
  data: () => ({
    eventTittle: "",
    modal1: "",
    startTime: "",
    modal2: "",
    endTime: "",
    responsiblePersonel: "",
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length <= 100 || "Contentx must be less than 100 characters",
    ],
  }),

  methods: {
    async closeDialog(){
      this.eventTittle = ""
      this.responsiblePersonel = ""
      this.startTime = ""
      this.endTime = ""
      this.modal1 = ""
      this.modal2 = ""

      await this.$emit("closeNewEventActivityDialog")
    },

    async createNewEventActivity() {
      this.$store.dispatch("showLoadingSpinner", true);
      var activity = {
        activityEventUniqueId: this.$route.params.event_id,
        activityDescription: this.eventTittle,
        activityStartTime: this.startTime,
        activityEndTime: this.endTime,
        activityResponsiblePersonel: this.responsiblePersonel,
      };
      await this.$store.dispatch("createNewEventActivity", activity);
      await this.$store.dispatch(
        "loadEventActivities",
        this.$route.params.event_id
      );

      this.$emit("closeNewEventActivityDialog");
      this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>

<style>
</style>
