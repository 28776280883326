<template>
  <v-container fluid>
    <v-row class="mt-2">
      <v-spacer></v-spacer>

      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="memberDialog = !memberDialog"
            v-bind="attrs"
            v-on="on"
            right
            outlined
            small
            color="primary white--text"
            style="margin: 5px"
          >
            <v-icon>mdi-account-plus</v-icon>
            Create User
          </v-btn>
        </template>
        <span>Create New User</span>
      </v-tooltip>

      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="OpenNewAccessForm = !OpenNewAccessForm"
            v-bind="attrs"
            v-on="on"
            right
            small
            outlined
            color="success white--text"
            style="margin: 5px"
          >
            <v-icon>mdi-lock-plus</v-icon>
            Create Access Level
          </v-btn>
        </template>
        <span>Create New Access Level</span>
      </v-tooltip>

      <v-tooltip bottom color="orange">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="isViewRoles = true"
            v-bind="attrs"
            v-on="on"
            right
            small
            outlined
            color="orange white--text"
            style="margin: 5px"
          >
            <v-icon>mdi-lock</v-icon>
            View Access Levels
          </v-btn>
        </template>
        <span>View Access Levels</span>
      </v-tooltip>
    </v-row>

    <v-row>
      <v-col sm="12">
          <!-- tabs -->
          <v-tabs v-model="tab" show-arrows>
            <v-tab class="p-2 shadow-lg bg-lime" v-for="tab in tabs" :key="tab.icon">
              <v-icon size="20" class="me-3">
                {{ tab.icon }}
              </v-icon>
              <span >{{ tab.title }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <Table :listType="'board'" :tableData="boardMembers" @openRevokeAccessModal="openRevokeAccessModal"/>
            </v-tab-item>

            <v-tab-item>
              <Table :listType="'management'" :tableData="nonBoardMembers" @openRevokeAccessModal="openRevokeAccessModal"/>
            </v-tab-item>
          </v-tabs-items>
      </v-col>
    </v-row>

    <AddNewUser
      :OpenNewUserForm="OpenNewUserForm"
      @close="
        OpenNewUserForm = !OpenNewUserForm;
        userKey++;
        memberDialogKey++; loadUsers()
      "
      :key="userKey"
    />

    <RevokeAccessModal
      :isRevokeAccess="isRevokeAccess"
      :revokeAccessUser="revokeAccessUser"
      @closeRevokeAccessModal="
        isRevokeAccess = false;
        revokeAccessUser = '';
      "
    />

    <ViewRoles
      :isViewRoles="isViewRoles"
      @closeViewRoles="isViewRoles = false"
    />

    <AddNewRole
      :OpenNewAccessForm="OpenNewAccessForm"
      @close="
        OpenNewAccessForm = !OpenNewAccessForm;
        roleKey++;
      "
      :key="roleKey"
    />

    <v-dialog
      v-model="memberDialog"
      max-width="600px"
      persistent
      :key="memberDialogKey"
    >
      <v-card>
        <v-card-title>
          <v-icon>mdi-account-plus-outline</v-icon>
          <span> Add Board Members / Users</span>
        </v-card-title>
        <v-card-text>
          <span
            >You can create new member or invite the member that already using
            e-Board
          </span>
          <v-radio-group v-model="row" row>
            <v-col cols="6" sm="6" md="6">
              <v-alert elevation="4" outlined>
                <v-radio
                  label="Invite Member to Board"
                  value="radio-1"
                  @change="
                    inviteMember = !inviteMember;
                    disabled = true;
                  "
                ></v-radio>
              </v-alert>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-alert elevation="4" outlined>
                <v-radio
                  label="Create New User"
                  value="radio-2"
                  @change="
                    inviteMember = false;
                    disabled = false;
                  "
                ></v-radio>
              </v-alert>
            </v-col>
          </v-radio-group>

          <v-text-field
            v-model="searchedEmail"
            clearable
            label="Search member email to invite to Board"
            type="text"
            :hint="searchResults"
            v-if="inviteMember === true"
          >
            <template v-slot:prepend>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                Type Member Email, Click search button to find if user exist in
                e-Board database
              </v-tooltip>
            </template>
            <template v-slot:append>
              <v-fade-transition leave-absolute>
                <v-progress-circular
                  v-if="loading"
                  size="24"
                  color="info"
                  indeterminate
                ></v-progress-circular>
                <v-icon v-else>mdi-email-sync</v-icon>
              </v-fade-transition>
            </template>
            <template v-slot:append-outer>
              <v-btn
                v-bind="attrs"
                v-on="on"
                flat
                icon
                rounded
                color="primary"
                @click="searchBoardMambers"
              >
                <v-icon> mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div v-if="inviteMember === true">
            <v-checkbox
              v-for="member in inviteMemberList"
              :key="member.primaryKey"
              :true-value="member.primaryKey"
              @change="addInvitee($event, member.primaryKey)"
              :label="
                `${member.memberFullName}` +
                '' +
                ` ( ${member.memberEmail}` +
                ' - ' +
                `${member.memberInstitutionShortform} )`
              "
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-progress-circular
            indeterminate
            color="primary"
            :width="2"
            style="padding-right: 10px"
            v-if="progressShown"
          >
          </v-progress-circular>
          <v-btn
            color="pink"
            text
            right
            small
            outlined
            @click="memberDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            :disabled="invitationBtn"
            right
            small
            v-if="inviteMember"
            @click="sendBoardInvitations()"
          >
            Send Invitation
            <v-icon>mdi-email-fast-outline</v-icon>
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="disabled"
            right
            small
            @click="
              progressShown = true;
              OpenNewUserFormWindow();
            "
          >
            Add User
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AddNewUser from "./AddNewUser.vue";
import AddNewRole from "../eboard_access/AddNewRole.vue";
import ViewRoles from "../eboard_access/ViewRoles.vue";
import RevokeAccessModal from "./RevokeAccessModal.vue";
import Table from './Table.vue'

export default {
  components: {
    Table,
    AddNewUser,
    AddNewRole,
    RevokeAccessModal,
    ViewRoles,
  },

  inject: {
    theme: {
      default: { isDark: false },
    },
  },

  data: () => ({
    isViewRoles: false,
    isRevokeAccess: false,
    revokeAccessUser: "",
    searchedEmail: null,
    roleKey: 0,
    userKey: 1,
    memberDialogKey: 2,
    message: "",
    disabled: true,
    progressShown: false,
    searchResults: "Eg. firstname.lastname@doamin.xx",
    loading: false,
    tab: "",
    inviteMember: false,
    memberDialog: false,
    OpenNewUserForm: false,
    OpenNewAccessForm: false,
    items: [{ header: "Board Members" }, { divider: true, inset: true }],
    nonitems: [{ header: "Management Members" }, { divider: true, inset: true }],
    tabs: [
      { title: "Board Members", icon: "mdi-account-star-outline" },
      { title: "Management Members", icon: "mdi-account-outline" },
    ],
    inviteesList: [],
  }),

  methods: {
    async loadUsers() {
      await this.$store.dispatch('allBoardUsers');
    },
    openRevokeAccessModal(revokeAccessUser) {
      this.revokeAccessUser = revokeAccessUser;
      this.isRevokeAccess = true;
    },

    OpenNewUserFormWindow() {
      setTimeout(() => {
        this.OpenNewUserForm = true;
        this.memberDialogKey = 90000;
        this.memberDialog = false;
        this.progressShown = false;
      }, 1000);
    },

    async searchBoardMambers() {
      this.loading = true;
      this.invitationBtn = false;
      await this.$store.dispatch("searchBoardMambers", this.searchedEmail);
      this.searchResults = `Your search results`;
      this.loading = false;
    },

    addInvitee(event, value) {
      let filteredArray = [];
      if (event) {
        this.inviteesList.push(value);
      } else {
        filteredArray = this.inviteesList.filter(function (e) {
          return e !== value;
        });
        this.inviteesList = filteredArray;
      }
    },

    async sendBoardInvitations() {
      this.progressShown = true;
      await this.$store.dispatch("showLoadingSpinner", true);
      const actualInvitees = [];
      this.inviteesList.forEach((invitee) => {
        actualInvitees.push({
          userId: invitee,
        });
      });

      const invitation = {
        invitationBoardUniqueId: localStorage.getItem("my-boardUniqueId"),
        invitationReceivers: actualInvitees,
      };

      await this.$store.dispatch("sendBoardInvitations", invitation);
      this.inviteesList = []
      this.progressShown = false;
      this.$store.commit('emptySearchBoardMembers', []);
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },



  computed: {
    boardInvitations() {
      return this.$store.state.MembersStore.boardInvitations;
    },
    nonBoardMembers() {
      return this.$store.state.MembersStore.nonBoardUsers;
    },
    boardMembers() {
      return this.$store.state.MembersStore.boardUsers;
    },
    inviteMemberList() {
      return this.$store.state.MembersStore.searchBoardMembers;
    },

    invitationBtn() {
      return this.inviteesList.length <= 0;
    },
  },

  async mounted() {
    await this.$store.dispatch("allBoardUsers");
  },
};
</script>

<style>
</style>
