import {apolloClient} from '@/vue-apollo'
import createCalendarEvent from '../../graphql/eboard-calendar-graphql/createCalendarEvent.gql'
import swal from 'sweetalert2'
import createEventActivities from '../../graphql/eboard-calendar-graphql/createEventActivities.gql'
import allBoardCalendarEvent from '../../graphql/eboard-calendar-graphql/allBoardCalendarEvent.gql'
import allBoardEventActivities from '../../graphql/eboard-calendar-graphql/allBoardEventActivities.gql'
import updateCalendarEvent from '@/graphql/eboard-calendar-graphql/updateCalendarEvent.gql'
import deleteCalendarEvent from '@/graphql/eboard-calendar-graphql/deleteCalendarEvent.gql.graphql'
import updateEventActivities from '@/graphql/eboard-calendar-graphql/updateEventActivities.gql.graphql'
import deleteEventActivities from '@/graphql/eboard-calendar-graphql/deleteEventActivities.gql.graphql'

export default {
    state: {
        boardEventList: [],
        boardActivity: [],
    },

    mutations: {
        updateCalendarEvent(state, eventObj){
            state.boardEventList = state.boardEventList.map((event) => {
                return event.primaryKey == eventObj.primaryKey ? eventObj : event
            })
        },

        addEvents(state, eventObj) {
            state.boardEventList = eventObj
        },

        addActivity(state, activitiesObj) {
            state.boardActivity = activitiesObj
        },

    },

    getters: {
        eventList(state) {
            return state.boardEventList
        },
    },

    actions: {
        async createBoardEvent(context, eventBody) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: createCalendarEvent,
                variables: {
                    eventObject: eventBody
                }
            }).then((response) => {
                const event = response.data.createCalendarEvent.eventCreated;
                if (event === true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Event registered successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Fail to create event please try again',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async createNewEventActivity(context, activityObject) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: createEventActivities,
                variables: {
                    newActivity: activityObject
                }
            }).then((response) => {
                const activityAdded = response.data.createEventActivities.activityAdded
                if (activityAdded === true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Activity registered successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Fail to create activity please try again',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateEventActivity(context, updatedActivity){
            await context.dispatch("loadBoardEventList", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateEventActivities,
                variables: {
                    updatedActivity: updatedActivity
                }
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                const activityUpdated = response.data.updateEventActivities.activityUpdated
                if (activityUpdated === true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Calendar Event activity updated successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Fail to update calendar event activity, please try again',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);
            })
        },

        async deleteEventActivity(context, uniqueId){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteEventActivities,
                variables: {
                    uniqueId: uniqueId
                }
            }).then( async (response)=>{
                const deleted = response.data.deleteEventActivities.deleted
                if (deleted === true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Calendar Event deleted successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Fail to delete calendar event, please try again',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadEventActivities(context, eventEventUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allBoardEventActivities,
                variables: {
                    activityUniqueId: eventEventUniqueId
                }
            }).then((response) => {
                const activities = response.data.allBoardEventActivities;
                context.commit('addActivity', activities)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadBoardEventList(context) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allBoardCalendarEvent,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const events = response.data.allBoardCalendarEvent
                context.commit('addEvents', events)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateBoardEvent(context, eventObject){
            await context.dispatch("loadBoardEventList", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateCalendarEvent,
                variables: {
                    eventObject: eventObject
                }
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                const eventCreated = response.data.updateCalendarEvent.eventCreated
                if (eventCreated === true) {
                    context.commit('updateCalendarEvent', eventObject)
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Calendar Event updated successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Fail to update calendar event, please try again',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);
            })
        },

        async deleteBoardEvent(context, uniqueId){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteCalendarEvent,
                variables: {
                    uniqueId: uniqueId
                }
            }).then( async (response)=>{
                const deleted = response.data.deleteCalendarEvent.deleted
                if (deleted === true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Calendar Event deleted successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Fail to delete calendar event, please try again',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


    }
}
