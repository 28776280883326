<template>
  <v-col md="4" cols="4" sm="12" xs="12">
      <v-card  class="mx-auto" max-width="100%" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div  class="text-overline mb-4">Archive</div>
            <v-list-item-title class="text-h6 mb-1">
              {{ packdata.MeetingName }}
            </v-list-item-title>
            <v-list-item-subtitle
              >Archived documents with more than one year</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-avatar tile size="80" color="white"> <v-img src="/pack.png" aspect-ratio="3" contain></v-img></v-list-item-avatar>
        </v-list-item>
      </v-card>
  </v-col>
</template>

<script>
export default {
  props: ["packdata"],
  data() {
    return {};
  },
};
</script>

<style>
</style>









