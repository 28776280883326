<template>
  <v-dialog v-model="openMeetingInterestsWindow" max-width="70%" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          <v-icon color="indigo">mdi-playlist-star</v-icon> Board Members with Conflict of
          Interest for this Meeting</span
        > 
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-simple-table>
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <strong>Member</strong>
                      </th>
                      <th  v-if="(boardSetting()||{}).showConflictedOrganization" class="text-left">
                        <strong>Organization</strong>
                      </th>
                      <th class="text-left">
                        <strong>Interest</strong>
                      </th>
                      <th class="text-left">
                        <strong>Conflicted Agenda</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="interest in listOfInterest"
                      :key="interest.primaryKey"
                    >
                      <td>
                        <strong>{{ interest.member }}</strong>
                      </td>
                      <td  v-if="(boardSetting()||{}).showConflictedOrganization">
                        {{ interest.interestOrganisation }}
                      </td>
                      <td>
                        {{ interest.interestNature}}
                      </td>
                      <td>
                        <div v-for="(item,index) in interest.conflictedAgendaItems" :key="item" class="ma-2">
                          <span class="mr-2">{{index+1}}. {{item.itemName}}</span>
                          <v-btn @click="deleteConflictOfInterest(item.declarationUniqueId)" small icon><v-icon  color="pink" >mdi-delete</v-icon></v-btn>
                          <hr>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="pink"
          class="white--text"
          small
          fab
          @click="closeNewInterestFormDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props: ["openMeetingInterestsWindow", "meetingUniqueId", "agendaItems"],
  data() {
    return {
    };
  },

  methods: {
    closeNewInterestFormDialog() {
      this.$emit("closeNewInterestFormDialog");
    },

    async deleteConflictOfInterest(declarationUniqueId){
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch('deleteInterestRegisterAgendaItem',declarationUniqueId)
      await this.$store.dispatch('loadMeetingConflictsOfInterest',this.meetingUniqueId)
      await this.$store.dispatch("showLoadingSpinner", false);
    }
  },
  computed:{
    ...mapGetters({
      listOfInterest:'getMeetingConflictsOfInterest'
    })
  }
};
</script>