<template>
  <v-container fluid>
    <div
      class="relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-5"
    >
      <div class="intro-x relative flex items-center mb-1">
        <div
          class="before:block before:absolute before:w-20 before:h-px before:bg-slate-200 before:dark:bg-darkmode-400 before:mt-5 before:ml-5"
        >
          <div
            class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden"
          >
            <img
              alt="Midone - HTML Admin Template"
              src="@/assets/img/minutes.png"
            />
          </div>
        </div>
        <div class="box px-5 py-3 ml-4 flex-1">
          <div class="flex items-center">
            <div class="font-medium">{{ directive.directiveTitle }}</div>
            <div class="text-xs text-slate-500 ml-auto">
              <v-chip small
              ><strong>Due-Date: </strong
              >{{ directive.directiveCreateddate | formatDate }}
              </v-chip
              >
            </div>
          </div>
          <hr class="mt-2"/>
          <div class="text-slate-500 mt-1">
            <span v-html="directive.directiveDescriptions"></span>
          </div>
          <hr class="mt-2"/>
          <div class="flex items-center">
            <v-col>
              <v-card-subtitle style="font-size:16px">
                <p><strong>Assignees: </strong>
                  <v-chip>{{ directive.directiveOwner[0].firstName }}</v-chip>
                </p>
              </v-card-subtitle>
            </v-col>
            <div class="text-xs text-slate-500 ml-auto">
              <v-menu
                offset-y
                app
                v-if="!directive.directiveStatus && directiveOwner"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="btn rounded-full text-xs bg-success white--text cursor-pointer font-medium"
                    text
                    small
                    style="text-transform: capitalize; font-size: 14px"
                    v-bind="attrs"
                    v-on="on"
                    slot="activator"
                  >
                    <v-icon small left>mdi-pencil-remove-outline</v-icon>
                    Report Implementation
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    link
                    v-for="(status, index) in action_status_list"
                    :key="index"
                  >
                    <v-list-item-title
                      @click="openProgressForm(directive, status.status)"
                    >
                      <v-icon :color="status.iconColor" small left>{{
                          status.icon
                        }}
                      </v-icon>
                      {{ status.status }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                @click="openMyActionCard"
                outlined
                class="ma-1 rounded-full"
                small
              >
                <v-icon>mdi-progress-check</v-icon>
                View Implementation
              </v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <ActionProgress
        @CloseActionProgress="directiveresponcedialog = false"
        :directive="directive"
        :status="status"
        :directiveresponcedialog="directiveresponcedialog"
    />
    <ViewMyActionCard
        @CloseActionCard="openAction=false"
        :directive="directive"
        from="board"
        :openAction="openAction"/>
  </v-container>
</template>

<script>
import ActionProgress from "./ActionProgress.vue";
import ViewMyActionCard from './ViewMyActionCard.vue'
import swal from "sweetalert2";

export default {
  props: ["nonBoardMembers", "directive"],
  components: {
    ActionProgress,
    ViewMyActionCard,
  },
  data() {
    return {
      directiveDueDate:null,
      directiveUniqueId:null,
      OpenReminderDialog:false,
      openAction: false,
      status: null,
      reporteddirective: null,
      OpenActionEditor: false,
      directiveresponcedialog: false,
      OpenNoticeDialog: false,
      opened: true,
      people_list: ["Jeremeia Gasppal", "Babu Seya"],
      action_status_list: [
        { iconColor: "red", icon: "mdi-circle-outline", status: "Not Started" },
        { iconColor: "primary", icon: "mdi-sync", status: "In Progress" },
        {
          iconColor: "orange",
          icon: "mdi-pause-circle-outline",
          status: "On Hold",
        },
        { iconColor: "success", icon: "mdi-check", status: "Done" },
      ],
    };
  },

  methods: {
    openReminderDialogCard(directive) {
      this.directiveDueDate = directive.directiveDuedate;
      this.directiveUniqueId = directive.directiveUniqueId;
      this.OpenReminderDialog = true;
    },
    openMyActionCard() {
      this.$store.dispatch(
          "loadDirectiveImplementationProgress",
          this.directive.directiveUniqueId
      );
      this.openAction = true;
    },
    openProgressForm(directive, status) {
      this.reporteddirective = directive;
      this.status = status;
      this.directiveresponcedialog = true;
    },
    swalcaller() {
      this.directiveresponcedialog = false;
      swal.fire({
        toast: true,
        icon: "success",
        title: "Directive Implementation saved successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.AuthStore.user.ownerUser.edges[0].node.ownerDesignation.designationUniqueId;
    },
    directiveOwner() {
      return this.directive.directiveOwner[0].id === this.currentUser;
    },
  },
};
</script>

<style>
</style>