<template>
  <v-row justify="center">
    <v-dialog
      v-model="OpenNewClientForm"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-bank-plus</v-icon> Add New Institution</span>
        </v-card-title>
        <v-divider></v-divider>
            <v-card-text>
                    <v-container>
                        <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="Shortform"
                        :rules="nameRules"
                        :counter="15"
                        label="Institution Shortform *"
                        required
                        prepend-icon="mdi-shape-outline"
                    >
                    </v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="Descriptions"
                        :rules="nameRules"
                        :counter="100"
                        label="Instituion Descriptions"
                        required
                        prepend-icon="mdi-text"
                    >
                    </v-text-field>
                        </v-col>

                        <v-col cols="12">
                                <v-checkbox
                                    v-model="checkbox"
                                    color="success"
                                    :label="`Activate Now`"
                                    ></v-checkbox>
                        </v-col>

                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            
           @click="$emit('CloseNewClientForm'); OpenNewClientForm=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            color="blue darken-1"
            fab
            small
            class="white--text"
           @click="$emit('CloseNewClientForm')"
          >
            <v-icon>mdi-send-check-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <LocationSettings :openLocationDialog="openLocationDialog" @close="openLocationDialog =! openLocationDialog"/> -->
  </v-row>
</template>


<script>
// import LocationSettings from '../eboard-settings/LocationSettings.vue'
  export default {
    components:{
        // LocationSettings
    },
    data: () => ({
        openLocationDialog:false,
        time: null,
        menu4:false,
        menu3: false,
        modal2: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        timeCounter:0,
        picker: new Date().toISOString().substr(0, 7),
        dialog: false,   
        Tittle:'',   
        nameRules: [
            v => !!v || 'This field is required',
            v => v.length >= 2 || 'Name must be less than 10 characters',
        ],
    }),
    props: ["OpenNewClientForm"],
  }
</script>