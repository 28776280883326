<template>
  <v-container fluid class="fill-height" style="height: 100% !important;">
    <v-row class="mt-4">
      <v-col class="d-flex justify-center align-center flex-wrap" style="height: 100% !important; margin-top: 90px;">
        <div link @click="gotoRoute(groupType.link)" class="report-group intro-y" v-for="groupType in reportTypes" :key="groupType.id">
          <v-img width="80" :src="groupType.icon"/>
          <div >{{ groupType.name }}</div>
        </div>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
export default {
  name: "ReportTypeDashboard",
  data() {
    return {
      reportTypes: [
        { id: "WEEKLY", name: "Weekly Reports", link: "/management/management_reports_builder", icon: require('@/assets/monthly-calendar.png')},
        { id: "MONTHLY", name: "Monthly Reports", link: "/management/management_monthly_reports", icon: require('@/assets/monly.png')},
        { id: "QUARTERLY", name: "Quarterly Reports", link: "/management/management_quarterly_reports", icon: require('@/assets/quaterly.jpg')},
        { id: "ANNUALLY", name: "Annual Reports", link: "/management/management_annually_reports", icon: require('@/assets/annual.jpg')},
        // { id: "MISCELLANEOUS ", name: "Miscellaneous Reports", link: "/management/management_annually_reports"},
      ]
    }
  },
  methods:{
    gotoRoute(route){
      this.$router.push(route);
    }
  }
}
</script>

<style scoped>
.report-group{
  padding: 10px;
  margin: 10px;
  border: 1px solid rgba(48, 55, 117, 0.493);
  border-style: dashed;
  background: white;
  border-radius: 5px;
  font-weight: 900;
  font-size: 16px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
</style>