<template>
  <v-flex>
    <v-simple-table style="width:100%">
      <template>
        <v-overlay v-if="!selectedAgenda" :absolute="absolute" :value="overlay">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
        <thead>
        <tr>
          <th class="text-left" style="width:10%">
          </th>
          <th class="text-left" style="width:80%">
          </th>
        </tr>
        </thead>

        <tbody>
        <tr>
          <td>
            <strong>Motion :</strong>
          </td>
          <td>
            <input
                v-if="isEditItemMotion && isEditItemMotionKey === selectedAgenda.itemDetails.itemKey"
                v-model="itemPurpose"
                :autofocus="true"
                :placeholder="selectedAgenda.itemDetails.itemPuporse || ''"
                @change="
                  (event) => onPurposeInput(event, selectedAgenda.itemDetails.itemKey, 'Purpose')
                "
                style="width: 80%"
            />

            <span v-else>
              {{ selectedAgenda.itemDetails.itemPuporse }}
            </span>

            <v-tooltip
                right
                v-if="isEditItemMotion && isEditItemMotionKey === selectedAgenda.itemDetails.itemKey"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="clearIsEditItemMotion"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to close Edit</span>
            </v-tooltip>

            <v-tooltip
                right
                v-else
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="setIsEditItemMotion"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to Edit Content</span>
            </v-tooltip>
          </td>
        </tr>

        <tr>
          <td>
            <strong>Initiator:</strong>
          </td>
          <td>

            <v-chip small> {{ selectedAgenda.itemVoteDetails.edges[0].node.voteSponsor.firstName }}
              {{ selectedAgenda.itemVoteDetails.edges[0].node.voteSponsor.lastName }}
            </v-chip>
          </td>
        </tr>

        <tr v-if="boardSetting().showAgendaDuration">
          <td>
            <strong>Duration :</strong>
          </td>
          <td class="Editable">
            <input
                v-if="isEditItemDuration && isEditItemMotionKey === selectedAgenda.itemDetails.itemKey"
                v-model="itemPurpose"
                :autofocus="true"
                :placeholder="selectedAgenda.itemDetails.itemDuration || '0'"
                @change="
                  (event) => onPurposeInput(event, selectedAgenda.itemDetails.itemKey, 'Time')
                "
            />

            <span v-else>
              {{selectedAgenda.itemDetails.itemDuration}}
            </span> Min

            <v-tooltip
                right
                v-if="isEditItemDuration && isEditItemMotionKey === selectedAgenda.itemDetails.itemKey"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="clearIsEditItemDuration"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to close Edit</span>
            </v-tooltip>

            <v-tooltip
                right
                v-else
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="setIsEditItemDuration"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to Edit Content</span>
            </v-tooltip>
          </td>
        </tr>

        <tr>
          <td>
            <strong>Votters :</strong>
          </td>
          <td class="Editable">
            <v-layout>
              <v-flex md12 xs12>
                <v-chip-group
                    v-model="votters"
                    active-class="activeVoter"
                    column
                    multiple
                >
                  <v-chip
                      v-for="voter in selectedAgenda.itemVoteDetails.edges[0].node.voterVote.edges"

                      :key="voter.node.voterVotedUser.id"
                      :value="voter.node.voterVotedUser.id"
                      outlined
                  >
                    {{ voter.node.voterVotedUser.firstName }} {{ voter.node.voterVotedUser.lastName }}
                  </v-chip>
                </v-chip-group>
                <small style="color:#727578">* Board members without access won’t receive email and will need your help
                  to cast their votes.</small>
              </v-flex>
            </v-layout>
          </td>
        </tr>

        <tr>
          <td>
            <strong>Vote Results :</strong><br>
          </td>
          <td>
            <v-checkbox
                v-model="voteIsAnonymous"
                color="#3f51b5"
                hide-details
                label="Anonymous Voting"
                @change="voteIsAnonymousChanged($event, selectedAgenda.itemVoteDetails.edges[0].node.voteUniqueId)"
            ></v-checkbox>
            <em style="color:#727578">No names are shown, only the number of votes</em>
          </td>
        </tr>

        <tr></tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-flex>
</template>

<script>
import swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      votters: null,
      absolute: true,
      overlay: true,
      file: '',
      loading3: false,
      dragging: false,
      duration: 0,
      Purpose: '',

      isEditItemMotion: false,
      isEditItemMotionKey: '',
      itemPurpose: '',
      autofocus: false,
      isEditItemDuration: false,
    }
  },

  methods: {
    setIsEditItemMotion() {
      this.autofocus = true;
      this.isEditItemMotion = true;
      this.isEditItemMotionKey = this.selectedAgenda.itemDetails.itemKey;
    },

    clearIsEditItemMotion() {
      this.autofocus = false;
      this.isEditItemMotion = false;
      this.isEditItemMotionKey = "";
      this.itemPurpose = "";
    },

    setIsEditItemDuration() {
      this.autofocus = true;
      this.isEditItemDuration = true;
      this.isEditItemMotionKey = this.selectedAgenda.itemDetails.itemKey;
    },

    clearIsEditItemDuration() {
      this.autofocus = false;
      this.isEditItemDuration = false;
      this.isEditItemMotionKey = "";
      this.itemPurpose = "";
    },

    async voteIsAnonymousChanged(event, itemVoteKey) {
      var voteObj = {
        voteIsAnonymous: event,
        voteUniqueId: itemVoteKey
      }

      await this.$store.dispatch('changeAgendaVoteStatus', voteObj);

      this.clearIsEditItemMotion();

      await this.$store.dispatch(
          "loadBestPracticeAgendaTemplates",
          this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },

    createFile(file) {
      if (!file.type.match("pdf.*")) {
        alert("please select PDF file");
        this.dragging = false;
        return;
      }

      if (file.size > 100000000) {
        alert("please check file size no over 5 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      console.log(this.file);
      this.dragging = false;
    },

    removeFile() {
      this.file = "";
    },

    onInput(event) {
      var cleared = event.target.innerText.replace(/[^\d.-]/g, '');
      this.duration = cleared

    },

    clearAllUnwantedChar(event) {
      event.target.innerText = this.duration
    },

    async onPurposeInput(event, selectedAgenda, updatePlace) {
      console.log('^^^^^^^')
      var details = {
        newPurpose: this.itemPurpose,
        newItem: selectedAgenda,
        updatePlace: updatePlace
      }

      await this.$store.dispatch('updateVottingAgendaItemsDetails', details)

      await this.clearIsEditItemMotion();
      await this.clearIsEditItemDuration();

      console.log('***********')

      await this.$store.dispatch(
          "loadBestPracticeAgendaTemplates",
          this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },

    publishAgendas() {
      this.loading3 = true
      swal.fire({
        toast: true,
        icon: "success",
        title: "Posted successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        // didOpen: (toast) => {
        //     toast.addEventListener('mouseenter', swal.stopTimer)
        //     toast.addEventListener('mouseleave', swal.resumeTimer)
        // }
      })

      setTimeout(() => (this.loading3 = false), 3000)
    },

    changePresenter(itemKey, member) {
      this.presenter = member.firstName + " " + member.lastName
      this.$store.dispatch('switchAgendaItemsPresenter', {itemKey: itemKey, member: member.id})
    
    },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
    voteIsAnonymous: {
      get() {
        return this.selectedAgenda.itemVoteDetails.edges[0].node.voteIsAnonymous
      },
      set(newName) {
        return newName
      }
    },

    presenter: {
      get: function () {
        return this.selectedAgenda.itemDetails.itemPresenter
      },
      set: function (newValue) {
        this.selectedAgenda.itemDetails.itemPresenter = newValue
      }
    },

    ...mapGetters({
      getBoardMembers: "getBoardMembers"
    }),

    selectedAgenda() {
      return this.$store.state.AgendasStore.selecteAgenda
    }

  },
}

</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}

.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>