<template>
  <div class="text-center">
    <v-dialog v-model="OpenCustomMinuteEditor" persistent width="70%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="indigo">mdi-file-document</v-icon>
          {{ actionTitle }}
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="saveloading"
            indeterminate
            color="success"
            size="20"
          ></v-progress-circular>
          <span v-if="saveloading" style="margin-left: 10px">{{
            bntLabel
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <CustomEditor
              :content="currentValue"
              :editorOption="editorOption"
              @onEditorChange="onEditorChange($event)"
              style="height: 480px"
            />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          Autosave after {{ numberOfTyping }} clicks
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseSectionForm')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn
            color="success"
            outlined
            small
            :loading="saveloading"
            @click="saveCustomMinuteSectionsOnTyping"
          >
            <v-icon small>mdi-floppy</v-icon> {{ bntLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomEditor from "../e_board_shared/fields/CustomEditor.vue";

export default {
  props: [
    "OpenCustomMinuteEditor",
    "selectedAgenda",
    "actionTitle",
    "sectionUniqueId",
  ],

  components: {
    CustomEditor,
  },

  data: () => ({
    content: "",
    bntLabel: "Save",
    numberOfTyping: 200,
    saveloading: false,
    editorOption: {
      placeholder: "Start to take note . . . ",
      theme: "snow",
    },
  }),

  methods: {
    onEditorChange(event) {
      this.content = event;
      if (this.numberOfTyping < 1) {
        this.autoSaveCustomMinuteSectionsOnTyping(event);
        this.numberOfTyping = 200;
      }
      this.numberOfTyping -= 1;
    },

    async autoSaveCustomMinuteSectionsOnTyping(content) {
      this.saveloading = true;
      this.bntLabel = "Auto Saving . . .";
      await this.$store.dispatch("updateCustomMinutesSectionsOnTyping", {
        sectionAgendaItem: this.selectedAgenda.itemKey,
        sectionUniqueId: this.sectionUniqueId,
        sectionValues: content,
      });
      this.saveloading = false;
      this.bntLabel = "Save";
    },

    async saveCustomMinuteSectionsOnTyping() {
      this.saveloading = true;
      this.bntLabel = "Saving . . .";
      await this.$store.dispatch("updateCustomMinutesSectionsOnTyping", {
        sectionAgendaItem: this.selectedAgenda.itemKey,
        sectionUniqueId: this.sectionUniqueId,
        sectionValues: this.content,
      });
      await this.$store.dispatch(
        "loadAllCustomMinuteSections",
        this.selectedAgenda.itemKey
      );
      this.saveloading = false;
      this.bntLabel = "Save";
      this.$emit("CloseSectionForm");
    },
  },

  computed: {
    currentValue() {
      var sections=this.$store.state.MinutesStore.agendaItemCustomMinuteSections;
      var section = sections.find(sect => sect.sectionButtonUniqueId === this.sectionUniqueId);
      if (section) {
        return section.sectionValue;
      } else {
        return ''
      }
    },
  },
};
</script>