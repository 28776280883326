<template>
  <v-container fluid>
    <div class="row pt-3">
      <div class="flex items-center">
        <v-btn
          :to="'/management/management_reports_dashboard'"
          class="mr-3"
          color="primary white--text"
          link
          outlined
          right
          rounded
          small
        >
          <v-icon>mdi-arrow-left</v-icon>
          Back
        </v-btn>
        <h2 class="font-medium text-primary">Weekly Reports</h2>
      </div>
    </div>

    <div class="row">
      <div class="col flex justify-end">
        <v-tooltip v-if="hasAccess('can_generate_report')" bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary white--text"
              outlined
              right
              rounded
              small
              v-bind="attrs"
              @click="showWeeklyReportModal = !showWeeklyReportModal"
              v-on="on"
            >
              <v-icon>mdi-file-plus-outline</v-icon>
              Generate New Report
            </v-btn>
          </template>
          <span>Generate New Report</span>
        </v-tooltip>
      </div>

    </div>

    <NewWeeklyReportModal
      :showWeeklyReportModal="showWeeklyReportModal"
      :templateUniqueId="templateUniqueId"
      @closeNewReportModal="closeNewReportModal"
      @openReportsBuilderDialog="openReportsBuilderDialog"
      @showOverlay="overlay = true"
      :weekUniqueId="null"
      :reportType="`WEEKLY`"
    />

    <v-row>
      <v-layout justify-start wrap>
        <v-card
          v-for="week in listOfWeeklyReport"
          :key="week.weekUniqueId"
          class="mx-auto -intro-y shadow-sm"
          max-width="344"
          outlined
          style="margin-top: 20px"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                Submited Reports: {{ week.weekReports }}
              </div>
              <v-list-item-title class="text-h5 mb-1">
                {{ week.weekEnd }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ week.weekName }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="80" tile>
              <v-img src="@/assets/weekly-calendar.png"/>
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom color="pink">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="!week.weekIsActive"
                  color="pink"
                  outlined
                  rounded
                  small
                  text
                  v-bind="attrs"
                  @click="loadThisWeekReports(week.weekUniqueId)"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                  add Report
                </v-btn>
              </template>
              <span
              >Report submission for this week has end, so if you need to
                submit report anyway click 'Add Report' but your report will be
                mark as delayed</span
              >
            </v-tooltip>
            <v-btn
              outlined
              rounded
              small
              text
              @click="loadThisWeekReports(week.weekUniqueId)"
            >
              Reports
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-layout>
    </v-row>

    <v-dialog
      :key="panelKey"
      v-model="reportBuilderDialog"
      max-width="90%"
      persistent
    >
      <builder-panel
        :reportIsPublished="false"
        :template="template"
        :templateUniqueId="templateUniqueId"
        :weekId="weekId"
        @closeReportBuilderPanel="
          reportBuilderDialog = false;panelKey++;"
      ></builder-panel>
    </v-dialog>


  </v-container>
</template>

<script>
import BuilderPanel from "./BuilderPanel.vue";
import NewWeeklyReportModal from "./NewWeeklyReportModal.vue";

export default {
  components: {
    BuilderPanel,
    NewWeeklyReportModal,
  },

  data() {
    return {
      zIndex: 0,
      template: null,
      panelKey: 0,
      overlay: false,
      templateUniqueId: null,
      reportBuilderDialog: false,
      showWeeklyReportModal: false,
      weekId: '',
      allowedDates: [3, 6, 9, 12]
    };
  },
  computed: {
    listOfWeeklyReport() {
      return this.$store.state.ManagementReportsStore.managementWeeklyReportsList;
    },
  },

  methods: {
    allowedMonths(val) {
      const months = [3, 6, 9, 12];
      const temp = months.findIndex((month) => month === parseInt(val.split('-')[1], 10));

       if (temp !== -1)
         return months[temp];
    },

    loadThisWeekReports(weekId) {
      this.$router.push("/management/management_week_reports/" + weekId);
    },

    async openReportsBuilderDialog(draft) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("getManagementsWeeklyReports");
      this.templateUniqueId = draft.templateUniqueId;
      this.template = draft;
      this.showWeeklyReportModal = false;
      this.overlay = false;
      this.reportBuilderDialog = true;
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    closeNewReportModal() {
      this.showWeeklyReportModal = false;
      this.getManagementsWeeklyReports();
    },

    async getManagementsWeeklyReports() {
      await this.$store.dispatch("getManagementsWeeklyReports");
    },
  },


  mounted() {
    this.getManagementsWeeklyReports();
  },
};
</script>

<style></style>
