<template>
  <v-dialog persistent v-model="OpenRepliesDialog" max-width="45%">
    <v-card >
      <v-card-title style="font-size: 20px">
        <span
          ><v-icon color="indigo">mdi-hands-pray</v-icon>Absentees Details
        </span>
      </v-card-title>
      <div class="col-span-12 2xl:col-span-3 ma-3 pa-3 bg-slate-100" >
        <div class="2xl:border-l -mb-10 pb-10">
          <div class="2xl:pl-6 grid grid-cols-12 gap-x-12 2xl:gap-x-0 gap-y-6">
            <div
              class="col-span-12 md:col-span-12 xl:col-span-10 2xl:col-span-12 mt-3 2xl:mt-8"
            >
              <div class="mt-5" v-if="boardMemberswithApologiesheaders.length > 0">
                <div class="intro-x" v-for="member in boardMemberswithApologiesheaders" :key="member.fullName">
                  <div class="box px-5 py-3 mb-3 flex items-center zoom-in">
                    <div
                      class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden"
                    >
                      <img
                        alt="Midone - HTML Admin Template"
                        src="@/assets/images/avatars/user_profile.png"
                      />
                    </div>
                    <div class="ml-4 mr-auto">
                      <div class="font-medium">{{member.fullName}}</div>
                      <div class="text-slate-500 text-xs mt-0.5">
                        {{member.apologyReason}}
                      </div>
                    </div>
                    <div class="text-success">
                      <v-icon></v-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center" v-else>
                No Absentees for this meeting
              </div>
            </div>
          </div>
        </div>
      </div>


      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn
          small
          outlined
          color="pink"
          @click="
            OpenRepliesDialog = !OpenRepliesDialog;
            $emit('CloseRepliesDialog');
          "
        >
          <v-icon class="pink--text">mdi-close</v-icon> Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["OpenRepliesDialog"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Name",

          value: "fullName",
          sortable: true,
        },
        { text: "Apology Reasons", value: "apologyReason" },
      ],
    };
  },
  computed: {
    boardMemberswithApologies() {
      return this.$store.state.InvitationStore.BoardMemberswithApologiesList;
    },
    boardMemberswithApologiesheaders() {
      var Tableheaders = this.boardMemberswithApologies;
      var HeadersList = [];
      Tableheaders.forEach((Tableheader) => {
        HeadersList.push({
          fullName: Tableheader.fullName,
          apologyReason: Tableheader.apologyReason,
        });
      });

      return HeadersList;
    },
  },

  async mounted() {
    await this.$store.dispatch(
      "loadBoardMemberswithApologies",
      this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
    );
  },
};
</script>

<style></style>
