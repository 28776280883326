<template>
  <v-row justify="center">
    <v-dialog
      :key="newCommitteeKey"
      v-model="AddNewCommitteesDialog"
      max-width="70%"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-account-multiple-plus-outline</v-icon> Add New Board
            Committee</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="committeename"
                  :counter="10"
                  :rules="nameRules"
                  label="Committee Name *"
                  prepend-icon="mdi-alpha-n"
                  required
                >
                </v-text-field>

                <v-text-field
                  v-model="descriptions"
                  :counter="10"
                  :rules="nameRules"
                  label="Descriptions / Responsibilities *"
                  prepend-icon="mdi-text-long"
                  required
                >
                </v-text-field>

                <v-checkbox
                  v-model="addmemberNow"
                  :label="`Add Members Now`"
                  color="success"
                ></v-checkbox>

                <v-select
                  v-if="addmemberNow"
                  v-model="committeeMembersList"
                  :items="boardMembers"
                  :menu-props="{ left: true, offsetY: true }"
                  attach
                  chips
                  item-text="name"
                  item-value="id"
                  label="Select Committee Members"
                  multiple
                  @change="updateLeaderselector"
                ></v-select>

                <v-radio-group v-if="addmemberNow" v-model="committeeLeaderId">
                  <template v-slot:label>
                    <div>Committee <strong>Leader</strong></div>
                  </template>
                  <v-radio
                    v-for="member in leadersMembersList"
                    :key="member.id"
                    :label="`Radio ${member}`"
                    :value="member.id"
                  >
                    <template v-slot:label>
                      <div>{{ member.name }}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="pink"
            fab
            small
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            :loading="loadoncreate"
            class="white--text"
            color="blue darken-1"
            fab
            small
            @click="createCommittee"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import addCommitee from "../../../graphql/eboard-committees-graphql/addCommitee.gql";
import allBoardUsers from "../../../graphql/eboard-boards-graphql/allBoardUsers.gql";
import swal from "sweetalert2";

export default {
  components: {},
  data: () => ({
    committeeStartDate: "",
    committeeEndDate: "",
    isCommitteeStartDate: false,
    isCommitteeEndDate: false,
    loadoncreate: false,
    newCommitteeKey: 0,
    openLocationDialog: false,
    committeename: "",
    descriptions: "",
    addmemberNow: false,
    committeeMembersList: "",
    committeeLeaderId: "",
    dialog: false,
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length <= 100 || "Name must be less than 100 characters",
    ],
    boardMembers: [],
    leadersMembersList: [],
  }),
  props: ["AddNewCommitteesDialog"],
  watch: {
    addmemberNow: function () {
      if (this.addmemberNow === false) {
        this.committeeMembersList = "";
        this.leadersMembersList = [];
      }
    },
  },
  methods: {
    async createCommittee() {
      this.loadoncreate = true;
      await this.$store.dispatch("showLoadingSpinner", true);

      this.$apollo
        .mutate({
          mutation: addCommitee,
          variables: {
            committeeBoard: localStorage.getItem("my-boardKey"),
            committeeName: this.committeename,
            committeeDescriptions: this.descriptions,
            committeeMembersList: this.committeeMembersList,
            committeeLeaderId: this.committeeLeaderId,
            committeeHasMembers: this.addmemberNow,
          },
        }).then(async (response) => {
          if (response.data.createCommittee.success) {
            this.leadersMembersList=[]
            this.committeename = null
            this.descriptions = null
            this.committeeMembersList = null
            this.committeeLeaderId = null
            this.addmemberNow = null

            const Committee = response.data.createCommittee.newCommittee;
            const CommitteeLeader =
                response.data.createCommittee.newCommittee.committeeMember.edges[0]
                    .node.memberUser;
            const countMember = [
              ...response.data.createCommittee.newCommittee.committeeMember.edges,
            ].length;

            await this.$store.dispatch("loadBoardCommittees");

            setTimeout(async () => {
              this.loadoncreate = false;
              this.$store.state.CommitteeStore.boardCommitees.push({
                divider: true,
                inset: true,
              });

              this.$store.state.CommitteeStore.boardCommitees.push({
                avatar: "/committee.jpeg",
                key: Committee.primaryKey,
                title: Committee.committeeName,
                subtitle: CommitteeLeader.username,
                active: Committee.committeeIsActive,
                description: Committee.committeeDescriptions,
                members: countMember,
                activeMessage: "Member is Active",
                meeting: false,
              });

              await this.$store.dispatch("showLoadingSpinner", false);
              this.$emit("close");
              await swal.fire({
                toast: true,
                icon: "success",
                title: response.data.createCommittee.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }, 2000);
          } else {
            await swal.fire({
              toast: true,
              icon: "error",
              title: response.data.createCommittee.message,
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        }).catch((error) => {
          console.error("addCommitee ==>", error)
      });
    },

    updateLeaderselector() {
      this.leadersMembersList = [];
      this.committeeMembersList.forEach((selected) => {
        this.boardMembers.forEach((member) => {
          if (selected === member.id) this.leadersMembersList.push(member);
        });
      });
    },

    loadBoardMambers() {
      this.$apollo
        .query({
          query: allBoardUsers,
          variables: {
            boardUniqueId: localStorage.getItem("my-boardUniqueId"),
            pageNumber: 1,
          },
        }).then((response) => {
          var tem_users = [];
          tem_users = [...response.data.boardUsers.boardUsers];
          tem_users.push(...response.data.boardUsers.managementUsers);
          tem_users.forEach((user) => {
            var member={
              id: user.id,
              name: user.firstName + " " + user.lastName,
            };
            const foundUser = this.boardMembers.find(user => user.id === member.id)
            if (!foundUser) {
              this.boardMembers.push({
                id: user.id,
                name: user.firstName + " " + user.lastName,
              });
            }

          });
        });
    },
  },
  created() {
    this.loadBoardMambers();
  },
};
</script>
