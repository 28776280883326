import {apolloClient} from '@/vue-apollo'
import allMyAsignedDirectives from '../../graphql/management-actions-graphql/allMyAsignedDirectives.gql'
import reportDirectiveProgress from '../../graphql/management-actions-graphql/reportDirectiveProgress.gql'
import managementDirectiveProgress from '../../graphql/management-actions-graphql/directiveProgress.graphql'
import allMyManagementWeeklyReportsDirectives
  from "../../graphql/management-actions-graphql/allMyManagementWeeklyReportsDirectives.gql"
import reportBoardManagementDirectiveProgress
  from "../../graphql/management-actions-graphql/reportBoardManagementDirectiveProgress.graphql"

import downloadManagementMeetingDirectives
  from "@/graphql/management-actions-graphql/downloadManagementMeetingDirectives.gql";
import downloadManagementBoardMeetingDirectives
  from "../../graphql/eboard-actions-graphql/downloadDirectivesDocuments.gql"
import allManagementBoardAssignedDirectives
  from "../../graphql/management-actions-graphql/allManagementBoardAssignedDirectives.graphql"
import {errorAlert, successAlert} from "@/services/notificationService";


export default {

  state: {
    managementCompletedDirectivesList: {},
    managementAssignedDirectivesList: {},
    managementProgressReportDocs: [],
    managementDirectiveProgress: [],

    boardAssignedDirectivesList: [],
    boardCompletedDirectivesList: [],
    boardDirectiveProgress: [],

    managementWeeklyAssignedDirectivesList: [],
    managementWeeklyCompletedDirectivesList: [],
  },

  getters: {
    getManagementAssignedDirectivesList: (state) => state.managementAssignedDirectivesList,
    getManagementCompletedDirectivesList: (state) => state.managementCompletedDirectivesList,
    getBoardCompletedDirectivesList: (state) => state.boardCompletedDirectivesList,
    getBoardAssignedDirectivesList: (state) => state.boardAssignedDirectivesList,
    getManagementWeeklyCompletedDirectivesList: (state) => state.managementWeeklyCompletedDirectivesList,
    getManagementWeeklyAssignedDirectivesList: (state) => state.managementWeeklyAssignedDirectivesList,
    getManagementDirectiveProgress: (state) => state.managementDirectiveProgress,
  },

  mutations: {
    cleanManagementDirectiveProgress(state) {
      state.managementDirectiveProgress = []
    },
    updateManagementDirectives(state, progress) {
      let progressDirective = progress.progressDirective
      let progressType = progress.progressType
      let directiveIndex
      state.managementAssignedDirectivesList.data = state.managementAssignedDirectivesList.data.map((directive) => {
        let directiveImplementation = directive.directiveImplementation
        let directiveStatus = directive.directiveStatus
        if (progressDirective === directive.directiveUniqueId) {
          if (progressType === "Done") {
            directiveImplementation = "DONE"
            directiveStatus = true
            state.managementCompletedDirectivesList.data.push({
              ...directive,
              directiveStatus,
              directiveImplementation
            })
          }
          if (progressType === "In Progress") {
            directiveImplementation = "INPROG"
          }
          if (progressType === "On Hold") {
            directiveImplementation = "ONHOLD"
          }
          if (progressType === "Not Started") {
            directiveImplementation = "NOTSTARTED"
          }
        }
        return {
          ...directive,
          directiveStatus,
          directiveImplementation
        }
      });

      if (progressType === "Done") state.managementAssignedDirectivesList.data.splice(directiveIndex, 1)
    },
    updateManagementReportDirectives(state, progress) {
      let progressDirective = progress.progressDirective
      let progressType = progress.progressType
      let directiveIndex
      state.managementWeeklyAssignedDirectivesList.data = state.managementWeeklyAssignedDirectivesList.data.map((directive) => {
        let directiveImplementation = directive.directiveImplementation
        let directiveStatus = directive.directiveStatus
        if (progressDirective === directive.directiveUniqueId) {
          if (progressType === "Done") {
            directiveImplementation = "DONE"
            directiveStatus = true
            state.managementWeeklyCompletedDirectivesList.data.push({
              ...directive,
              directiveStatus,
              directiveImplementation
            })
          }
          if (progressType === "In Progress") {
            directiveImplementation = "INPROG"
          }
          if (progressType === "On Hold") {
            directiveImplementation = "ONHOLD"
          }
          if (progressType === "Not Started") {
            directiveImplementation = "NOTSTARTED"
          }
        }
        return {
          ...directive,
          directiveStatus,
          directiveImplementation
        }
      });

      if (progressType === "Done") state.managementWeeklyAssignedDirectivesList.data.splice(directiveIndex, 1)
    },
    addManagementDirectives(state, directive) {
      state.managementAssignedDirectivesList = directive
    },
    addBoardDirectives(state, directive) {
      state.boardAssignedDirectivesList = directive
    },
    addManagementWeeklyDirectives(state, directive) {
      state.managementWeeklyAssignedDirectivesList = directive
    },
    addManagementCompletedWeeklyDirectives(state, directive) {
      state.managementWeeklyCompletedDirectivesList = directive
    },
    addCompletedBoardDirectives(state, directive) {
      state.boardCompletedDirectivesList = directive
    },
    updateBoardDirective(state, progress) {
      let progressDirective = progress.progressDirective
      let progressType = progress.progressType
      let directiveIndex
      state.boardAssignedDirectivesList.data = state.boardAssignedDirectivesList.data.map((directive) => {
        let directiveImplementation = directive.directiveImplementation
        let directiveStatus = directive.directiveStatus
        if (progressDirective === directive.directiveUniqueId) {
          if (progressType === "Done") {
            directiveImplementation = "DONE"
            directiveStatus = true
            state.boardCompletedDirectivesList.data.push({
              ...directive,
              directiveStatus,
              directiveImplementation
            })
          }
          if (progressType === "In Progress") {
            directiveImplementation = "INPROG"
          }
          if (progressType === "On Hold") {
            directiveImplementation = "ONHOLD"
          }
          if (progressType === "Not Started") {
            directiveImplementation = "NOTSTARTED"
          }
        }
        return {
          ...directive,
          directiveStatus,
          directiveImplementation
        }
      });

      if (progressType === "Done") state.boardAssignedDirectivesList.data.splice(directiveIndex, 1)

    },
    addCompletedManagementDirectives(state, directive) {
      state.managementCompletedDirectivesList = directive
    },
    addManagementDirectiveProgress(state, directiveProgress) {
      state.managementDirectiveProgress.push(directiveProgress)
    },
  },

  actions: {
    async loadManagementAssignedDirectives(context, filterObj) {
      await context.dispatch("showLoadingSpinner", true);
      context.rootState.ResolutionStore.progressReportDocs = []
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allMyAsignedDirectives,
        variables: {
          managementUniqueId: filterObj.managementUniqueId,
          designationUniqueId: filterObj.designationUniqueId,
          meetingUniqueId: filterObj.meetingUniqueId,
          pageNumber: filterObj.pageNumber,
          directiveStatus: false,
        }
      }).then(async (result) => {
        const {response, page, data} = result.data.allMyManagementAssignedDirectives;

        if (response.status) {
          context.commit('addManagementDirectives', {data: data, page: page});
        } else {
          await errorAlert(response.message);
        }

        await context.dispatch("showLoadingSpinner", false);
      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async loadManagementCompletedDirectives(context, filterObj) {
      await context.dispatch("showLoadingSpinner", true);
      context.rootState.ResolutionStore.progressReportDocs = []
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allMyAsignedDirectives,
        variables: {
          managementUniqueId: filterObj.managementUniqueId,
          designationUniqueId: filterObj.designationUniqueId,
          meetingUniqueId: filterObj.meetingUniqueId,
          pageNumber: filterObj.pageNumber,
          directiveStatus: true,
        }
      }).then(async (result) => {
        const {response, page, data} = result.data.allMyManagementAssignedDirectives;

        if (response.status) {
          context.commit('addCompletedManagementDirectives', {data: data, page: page});
        } else {
          await errorAlert(response.message);
        }

        await context.dispatch("showLoadingSpinner", false);
      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async loadBoardAssignedDirectives(context, filterObj) {
      await context.dispatch("showLoadingSpinner", true);
      context.rootState.ResolutionStore.progressReportDocs = []
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allManagementBoardAssignedDirectives,
        variables: {
          boardUniqueId: filterObj.boardUniqueId,
          designationUniqueId: filterObj.designationUniqueId,
          meetingUniqueId: filterObj.meetingUniqueId,
          pageNumber: filterObj.pageNumber,
          directiveStatus: false,
        }
      }).then(async (result) => {
        const {response, page, data} = result.data.allManagementBoardAssignedDirectives;

        if (response.status) {
          context.commit('addBoardDirectives', {data: data, page: page});
        } else {
          await errorAlert(response.message);
        }

        await context.dispatch("showLoadingSpinner", false);
      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async reportBoardDirectiveProgress(context, progress) {
      await context.dispatch("showLoadingSpinner", true);
      apolloClient.mutate({
        mutation: reportBoardManagementDirectiveProgress,
        variables: {
          progress: progress
        }
      }).then(async (response) => {
        const result = response.data.reportBoardManagementDirectiveProgress.response
        context.rootState.ResolutionStore.progressReportDocs = []
        if (result.status) {
          await successAlert(result.message)
          context.commit('updateBoardDirective', progress)
        } else {
          await errorAlert(result.message)
        }
        await context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        await context.dispatch("showLoadingSpinner", false);
        console.error(error.messages);
      })
    },

    async loadBoardCompletedDirectives(context, filterObj) {
      await context.dispatch("showLoadingSpinner", true);
      context.rootState.ResolutionStore.progressReportDocs = []
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allManagementBoardAssignedDirectives,
        variables: {
          boardUniqueId: filterObj.boardUniqueId,
          designationUniqueId: filterObj.designationUniqueId,
          meetingUniqueId: filterObj.meetingUniqueId,
          pageNumber: filterObj.pageNumber,
          directiveStatus: true,
        }
      }).then(async (result) => {
        const {response, page, data} = result.data.allManagementBoardAssignedDirectives;

        if (response.status) {
          context.commit('addCompletedBoardDirectives', {data: data, page: page});
        } else {
          await errorAlert(response.message);
        }

        await context.dispatch("showLoadingSpinner", false);
      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async loadManagementCompletedWeeklyReportDirectives(context, filterObj) {
      await context.dispatch("showLoadingSpinner", true);
      context.rootState.ResolutionStore.progressReportDocs = []
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allMyManagementWeeklyReportsDirectives,
        variables: {
          managementUniqueId: filterObj.managementUniqueId,
          designationUniqueId: filterObj.designationUniqueId,
          reportType: filterObj.reportType,
          pageNumber: filterObj.pageNumber,
          directiveStatus: true,
        }
      }).then(async (result) => {
        const {response, page, data} = result.data.allMyManagementWeeklyReportsDirectives;

        if (response.status) {
          context.commit('addManagementCompletedWeeklyDirectives', {data: data, page: page});
        } else {
          await errorAlert(response.message);
        }
        await context.dispatch("showLoadingSpinner", false);
      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async loadAssignedManagementWeeklyReportDirectives(context, filterObj) {
      await context.dispatch("showLoadingSpinner", true);
      context.rootState.ResolutionStore.progressReportDocs = []
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: allMyManagementWeeklyReportsDirectives,
        variables: {
          managementUniqueId: filterObj.managementUniqueId,
          designationUniqueId: filterObj.designationUniqueId,
          reportType: filterObj.reportType,
          pageNumber: filterObj.pageNumber,
          directiveStatus: false,
        }
      }).then(async (result) => {
        const {response, page, data} = result.data.allMyManagementWeeklyReportsDirectives;

        if (response.status) {
          context.commit('addManagementWeeklyDirectives', {data: data, page: page});
        } else {
          await errorAlert(response.message);
        }
        await context.dispatch("showLoadingSpinner", false);
      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async reportManagementDirectivesProgress(context, progress) {
      await apolloClient.mutate({
        mutation: reportDirectiveProgress,
        variables: {
          progress: {
            progressDirective: progress.progressDirective,
            progressDescriptions: progress.progressDescriptions,
            progressType: progress.progressType,
            progressAttachment: progress.progressAttachment,
          }
        }
      }).then(async (response) => {
        const result = response.data.reportManagementDirectiveProgress.response
        context.rootState.ResolutionStore.progressReportDocs = []
        if (result.status) {
          await successAlert(result.message)
          progress.from === 'report' ? context.commit('updateManagementReportDirectives', progress)
            : context.commit('updateManagementDirectives', progress)
        } else {
          await errorAlert(result.message)
        }

      }).catch(async () => {
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async loadManagementDirectiveImplementationProgress(context, directiveUniqueId) {
      context.commit('cleanManagementDirectiveProgress')
      context.rootState.ResolutionStore.progressReportDocs = []
      apolloClient.query({
        fetchPolicy: 'no-cache',
        query: managementDirectiveProgress,
        variables: {
          directiveUniqueId: directiveUniqueId,
        }
      }).then((response) => {
        var directives_progress = response.data.managementDirectiveProgress.managementProgressDirective.edges
        directives_progress.forEach(item => {
          context.commit('addManagementDirectiveProgress', item.node)
        })
      }).catch(async () => {
      })
    },

    async downloadManagementMeetingDirectives(context, input) {
      await context.dispatch("showLoadingSpinner", true);
      await apolloClient.mutate({
        mutation: downloadManagementMeetingDirectives,
        variables: {input},
      }).then(async (result) => {
        let {response, data} = result.data.downloadManagementMeetingDirectives;
        if (response.status)
          window.open(`${process.env.VUE_APP_MEDIA_SERVER}/${data}`, "_blank");
        else {
          await errorAlert(response.message);
        }

        await context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        console.error("downloadManagementMeetingDirectives ==>", error);
        await context.dispatch("showLoadingSpinner", false);
      })
    },

    async downloadManagementBoardMeetingDirectives(context, input) {
      await context.dispatch("showLoadingSpinner", true);
      await apolloClient.mutate({
        mutation: downloadManagementBoardMeetingDirectives,
        variables: {input: input},
      }).then(async (result) => {
        let {response, data} = result.data.downloadManagementBoardMeetingDirectives;
        if (response.status)
          window.open(`${process.env.VUE_APP_MEDIA_SERVER}/${data}`, "_blank");
        else {
          await errorAlert(response.message);
        }

        await context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        console.error("downloadManagementBoardMeetingDirectives ==>", error);
        await context.dispatch("showLoadingSpinner", false);
      })
    },
  },
}
