import {apolloClient} from '@/vue-apollo'
import boardVotes from '../../graphql/eboard-votting-graphql/boardVotes.gql'
import userCastVote from '../../graphql/eboard-votting-graphql/userCastVote.gql'
import agendaVoteResults from '../../graphql/eboard-votting-graphql/agendaVoteResults.gql'
import closeVotting from '../../graphql/eboard-votting-graphql/closeVotting.gql'
import unanonymousVoteDetails from '../../graphql/eboard-votting-graphql/unanonymousVoteDetails.graphql'


export default {
    state: {
        activeVotes: [],
        enumsResponding: null,
        selectedVoteResults: null,
        voteEndResults: null,
        unanonymousVotes: null
    },

    mutations: {
        cleanVotes(state) {
            state.activeVotes = []
        },
        addVotes(state, vote) {
            state.activeVotes.push(vote)
        },
        updateVote(state, voteObj) {
            state.activeVotes.forEach(vote => {
                if (vote.primaryKey == voteObj.primaryKey) {
                    vote.voterIsVoted = voteObj.voterIsVoted
                    state.voterSelection = voteObj.voterSelection
                }
            });
        },
        updateResultsVotes(state, vote) {
            state.selectedVoteResults = vote
        },
        clearResultsVotes(state) {
            state.selectedVoteResults = null
        },
        setEndVoteResults(state, results) {
            state.voteEndResults = results
        },
        clearEndVoteResults(state) {
            state.voteEndResults = null
        },
        setUnanonymousVoteResults(state, results) {
            state.unanonymousVotes = results
        }
    },

    getters: {
        votingList(state) {
            return state.activeVotes
        },
        enumsList(state) {
            return state.enumsResponding
        }
    },

    actions: {
        async loadActiveVotes(context) {
            context.commit('cleanVotes')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: boardVotes,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }
            }).then((response) => {
                const votes = response.data.boardVotes
                votes.forEach(vote => {
                    context.commit('addVotes', vote)
                });
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async unanonymousVoteDetails(context, voteUniqueId) {
            context.commit('cleanVotes')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: unanonymousVoteDetails,
                variables: {
                    voteUniqueId: voteUniqueId,
                }
            }).then((response) => {
                const results = response.data.unanonymousVoteDetails
                context.commit('setUnanonymousVoteResults', results)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async castUserVote(context, data) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: userCastVote,
                variables: {
                    castedSelection: data.castedSelection,
                    userCastVote: data.userCastVote,
                }
            }).then((response) => {
                console.log(response)
                context.dispatch('loadActiveVotes')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async fetchVoteResultsFromServer(context, vote) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: agendaVoteResults,
                variables: {
                    voteUniqueId: vote,
                }
            }).then((response) => {
                context.commit('clearResultsVotes')
                const results = response.data.agendaVoteResults
                context.commit('updateResultsVotes', results)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },
        async closeVottingSession(context, vote) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: closeVotting,
                variables: {
                    voteUniqueId: vote,
                }
            }).then((response) => {
                context.commit('clearEndVoteResults')
                context.rootState.AgendasStore.selecteAgenda.itemVoteDetails.edges[0].node.voteIsActive = false
                const results = response.data.closeVotting.voteResults
                context.commit('setEndVoteResults', results)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        }
    }
}

