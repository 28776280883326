<template>
  <v-container fluid class="my-5" app>
    <v-dialog persistent v-model="openUnpublishedDialog" max-width="90%">
      <v-card>
        <v-card-title class="text-h6 indigo lighten-5">
          <v-icon>mdi-web</v-icon>
          Un-Published News
          <v-spacer></v-spacer>
        </v-card-title>

        <v-row class="flex">
          <div class="m-2 w-full">
            <table class="table p-3 w-100 table-hover bg-white">
              <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">News Title</th>
                <th scope="col">News Description</th>
                <th scope="col">Expire Date</th>
                <th scope="col">Expire Time</th>
                <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="news in boardUnPublishedUpdates" :key="news.newsUniqueId">
                <th scope="row">
                  <v-avatar tile size="40">
                    <img
                        :aspect-ratio="3 / 3"
                        :key="news.value"
                        src="@/assets/pending.png"
                        alt="draft"
                    />
                  </v-avatar>
                </th>
                <td>{{ news.newsTitle }}</td>
                <td class="text-truncate" style="max-width: 20px;">{{ news.newsDescriptions }}</td>
                <td>{{ news.newsExpireDate }}</td>
                <td>{{ news.newsExpireTime }}</td>
                <td>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                          v-on="on"
                          v-bind="attrs"
                          @click="rePublishNews(news)"
                          class="btn btn-primary btn-sm mr-2"
                          type="button"
                      >
                        <icon name="news" width="w-5" height="w-5" color="#ffffff"></icon>
                      </div>
                    </template>
                    <span>Republish News</span>
                  </v-tooltip>
                  <v-tooltip bottom color="warning">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                          v-on="on"
                          v-bind="attrs"
                          @click="editUnPublishedNews(news)"
                          class="btn btn-warning btn-sm mr-2"
                          type="button"
                      >
                        <icon name="edit" width="w-5" height="w-5" color="#ffffff"></icon>
                      </div>
                    </template>
                    <span>Edit News</span>
                  </v-tooltip>
                  <v-tooltip bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                          v-on="on"
                          v-bind="attrs"
                          @click="deleteDraftNews(news.newsUniqueId)"
                          class="btn btn-danger btn-sm mr-2"
                          type="button"
                      >
                        <icon name="delete" width="w-5" height="w-5" color="#ffffff"></icon>
                      </div>
                    </template>
                    <span>Delete News</span>
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            medium
            color="pink"
            @click="
              openUnpublishedDialog = !openUnpublishedDialog;
              $emit('closeUnpublishedDialog');
            "
          >
            <v-icon class="white--text">mdi-close</v-icon>
            <strong style="color: white"> CLOSE </strong>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-news
      @close-dialog="editDraftNewsDialog=false"
      :edit-draft-news-dialog="editDraftNewsDialog"
      :news-title="newsTitle"
      :news-descriptions="newsDescriptions"
      :news-expire-date="newsExpireDate"
      :news-expire-time="newsExpireTime"
      :news-show-delivery="newsShowDelivery"
      :news-documents="supportingDocs"
    >
    </edit-news>
  </v-container>
</template>

<script>


import Icon from "@/views/Icon.vue";
import EditNews from "@/modules/meeting_management/eboard_news_updates/EditNews.vue";

export default {
  props: ["openUnpublishedDialog", "editDraftNewsDialog"],
  components: {
    Icon,
    EditNews
  },
  data() {
    return {
      supportingDocs: [],
      newsUniqueId: "",
      newsTitle: "",
      newsDescriptions: "",
      newsExpireDate: "",
      newsExpireTime: "",
      newsExpire: "",
      expireTime: "",
      newsShowDelivery: false,
    };
  },
  methods: {
    async rePublishNews(news) {
      let documents_list = [];
      this.supportingDocs.forEach((document) => {
        documents_list.push({
          documentName: document.fileName,
          filePath: document.fullFileName,
        });
      });

      let input =  {
        newsIsDraft: false,
        newsIsPublished: true,
        newsUniqueId: news.newsUniqueId
      }

      await this.$store.dispatch("unPublishBoardNewsAndUpdates", input)
      await this.$store.dispatch("loadBoardPublishedNewsUpdates");
      this.$emit('closePublishedDialog')

    },

    // Delete News
    async deleteDraftNews(newsUniqueId){
      await this.$store.dispatch('deleteDraftNews', newsUniqueId)
      await this.$store.dispatch('loadBoardUnpublishedNewsUpdates')
    },
    // Edit News
    async editUnPublishedNews(news){
      this.newsTitle = news.newsTitle
      this.newsDescriptions = news.newsDescriptions
      this.newsExpireDate = news.newsExpireDate
      this.newsExpireTime = news.newsExpireTime
      this.newsDocuments = news.newsDocuments

      this.editDraftNewsDialog=true
    },
  },
  computed: {
    boardUnPublishedUpdates() {
      return this.$store.state.NewsUpdatesStore.boardUnpublishedNewsUpdatesList;
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardUnpublishedNewsUpdates");
  },
};
</script>

<style>
</style>