<template>
  <v-container fluid class="my-5" app>
    <v-dialog persistent v-model="filePreviewDialog" max-width="80%">
  <v-card>
    <v-card-title class="text-h6 grey lighten-2">
      Documents - {{newsTitle}}
    </v-card-title>
    <v-card-text>
      <v-layout v-if="listOfPublishedDocuments.length < 1" style="padding-top: 10px">
        <v-alert dense text style="width: 100%">
          <v-flex xs12 md12>
            <div class="no-documents ng-star-inserted">
              <span>News has no documents uploaded.</span>
            </div>
          </v-flex>
        </v-alert>
      </v-layout>
      <v-list subheader two-line>
        <v-divider inset></v-divider>
          <v-list-item
              v-for="file in listOfPublishedDocuments"
              :key="file.primaryKey"
              @click="OpenfileDialogFunc(file.documentPath)"
              style="border-bottom: 1px solid gray"
          >
            <v-list-item-avatar size="50">
              <v-img src="@/assets/docs.png" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="file.documentName"></v-list-item-title>
              <v-list-item-subtitle v-text="file.documentName"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="OpenfileDialogFunc(file.documentPath)">
                <v-icon color="indigo lighten-1">mdi-file-eye-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
      </v-list>
    </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closefileDialog" outlined>
          Close
        </v-btn>
      </v-card-actions>
  </v-card>
  </v-dialog>
    <DocumentViwer
        v-if="OpenfileDialog == true"
        :docValue="docValue"
        :OpenfileDialog="OpenfileDialog"
        :title="`View Attachment`"
        @CloseDocumentViwer="OpenfileDialog = false"
    />
  </v-container>
</template>

<script>
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";
export default {
  components: {DocumentViwer},
  props: [
    "documentList",
    "filePreviewDialog",
    "newsTitle",
    "newsDescriptions",
    "newsUniqueId"
  ],
  data() {
    return {
      file: "",
      show: true,
      docValue: "",
      docType: "office",
      OpenfileDialog: false,
      files: [],
      publishedNewsDocument: []
    };
  },
  computed: {
    listOfPublishedDocuments (){
      return this.$store.getters.publishedNewsDocument
    }
  },
  methods: {
    closefileDialog() {
      this.files = [];
      this.$emit("close-dialog");
    },
    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      this.OpenfileDialog = true;
    },

    async deleteSupportingDocument(documentPeth,deletedFrom) {
      await this.$store.dispatch('deleteUploadedDocument',{
        "deletedDocument":documentPeth,
        "deletedFrom":deletedFrom
      })
      // .findIndex(item => item.fullFileName === documentPeth);
      const index = this.$store.state.NewsUpdatesStore.actions.clearnewsandUpdateSuppotingDocuments()
      this.$store.state.NewsUpdatesStore.circularResolutionSupportingDocument.splice(index,1);

      await this.$store.dispatch('loadBoardDraftNewsUpdates')
    },
  },
};
</script>


<style>
</style>
