import {apolloClient} from '@/vue-apollo'
import registerUser from '../../graphql/eboard-auth-graphql/registerUser.gql'
import createBoardUser from '../../graphql/eboard-boards-graphql/createBoardUser.gql'
import createBoardManagementMember from '../../graphql/eboard-boards-graphql/createManagementUser.gql'
import allBoardUsers from '../../graphql/eboard-boards-graphql/allBoardUsers.gql'
import searchBoardMemberByEmail from '../../graphql/eboard-users-graphql/searchBoardMemberByEmail.gql'
import revokeUserAccessLevel from '../../graphql/eboard-auth-graphql/revokeUserAccessLevel.graphql'
import editUserRole from '../../graphql/eboard-users-graphql/editUserRole.graphql'
import deleteUserRole from '../../graphql/eboard-users-graphql/deleteUserRole.graphql'
import userProfileDetails from "../../graphql/eboard-users-graphql/userProfileDetails.gql"
import updateUserProfileDetails from "../../graphql/eboard-users-graphql/updateUserProfileDetails.gql"
import updateUserProfilePosition from "../../graphql/eboard-boards-graphql/updateUserProfilePosition.gql"
import grantUserAccessToBoard from "../../graphql/eboard-boards-graphql/grantUserAccessToBoard.gql"
import blockUserBoardAccess from "../../graphql/eboard-boards-graphql/blockBoardAccess.gql"

import swal from 'sweetalert2'



export default {
    state: {
        boardMembers: [],
        boardUsers: [],
        boardUsersPage: null,
        nonBoardUsers: [],
        nonBoardUsersPage: null,
        searchBoardMembers: [],
        boardInvitations: [],
        userProfileDetails: {}
    },

    getters: {
        getBoardMembers(state) {
            return state.boardMembers
        },
        userProfileDetails: state => state.userProfileDetails
    },

    mutations: {
        emptySearchBoardMembers(state) {
            state.searchBoardMembers = [];
        },
        SET_TOKEN(state, token) {
            state.token = token
        },

        addBoardInvitations(state, boardInvitations) {
            state.boardInvitations.push(boardInvitations)
        },

        LOGIN_USER(state, user) {
            state.user = user
            localStorage.setItem('my-boardUniqueId', user.profileUser.edges[0].node.userProfileBoard.boardUniqueId || "")
        },

        LOGOUT_USER(state) {
            state.authStatus = ''
            state.token = '' && localStorage.removeItem('apollo-token')
            sessionStorage.clear();
        },

        clearBoardMembers(state) {
            state.boardMembers = []
        },

        saveBoardMembers(state, members) {
            state.boardMembers = [...members.boardUsers]
        },

        clearBoardSearchedMembers(state) {
            state.searchBoardMembers = []
        },

        saveBoardSearchedMembers(state, members) {
            state.searchBoardMembers = members
        },

        addBoardUsers(state, member) {
            state.boardUsers.push(member)
        },

        addBoardUsersPage(state, page) {
            state.boardUsersPage = page
        },

        addNonBoardUsers(state, member) {
            state.nonBoardUsers.push(member)
        },

        addNonBoardUsersPage(state, page) {
            state.nonBoardUsersPage = page
        },

        clearBoardUsers(state) {
            state.boardUsers = [];
            state.nonBoardUsers = [];
            state.boardInvitations = []
        },

        SET_USER_PROFILE_DETAILS(state, userProfileDetails) {
            state.userProfileDetails = userProfileDetails;
        },

        CLEAR_USER_PROFILE_DETAILS(state) {
            state.userProfileDetails = {};
        },


    },

    actions: {
        async registerNewMembers(_, membersDetails) {
            try {
                const randomWords = require('random-words');
                const pasw1 = randomWords() + "2LatraDT@";

                const {data} = await apolloClient.mutate({
                    mutation: registerUser,
                    variables: {
                        username: membersDetails.email,
                        email: membersDetails.email,
                        password1: pasw1,
                        password2: pasw1,
                    }
                });

                if (data.register.success) {
                    const {data} = await apolloClient.mutate({
                        mutation: createBoardUser,
                        variables: {...membersDetails}
                    })

                    if (data.createBoardMember.success) {
                        if (membersDetails.checkboxManagementMember) { // if management member
                            const {data} = await apolloClient.mutate({
                                mutation: createBoardManagementMember,
                                variables: {
                                    managementMember: membersDetails.email,
                                    memberIsLeader: (membersDetails.managementMemberLevel === 'CHR'),
                                    memberIsDirector: (membersDetails.managementMemberLevel === 'DIR'),
                                    boardKey: membersDetails.boardKey,
                                    position: membersDetails.position,
                                    suffix: membersDetails.suffix,
                                    title: membersDetails.title,
                                    userRole: membersDetails.userRole,
                                    lastName: membersDetails.lastName,
                                    firstName: membersDetails.firstName
                                }
                            })
                            console.log(data);
                        }

                        await swal.fire({
                            toast: true,
                            icon: 'success',
                            title: data.createBoardMember.message,
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        })

                    } else {
                        await swal.fire({
                            toast: true,
                            icon: 'error',
                            title: data?.createBoardMember?.message,
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        })
                    }
                } else {
                    let errors = data?.register?.errors;
                    let error = errors.email || errors.username || errors.password1 || errors.password2;

                    await swal.fire({
                        toast: true,
                        icon: 'error',
                        title: error[0].message,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },

        async registerBoardMember(_, membersDetails) {
            try {
                const randomWords = require('random-words');
                const pasw1 = randomWords() + "2LatraDT@";

                const {data} = await apolloClient.mutate({
                    mutation: registerUser,
                    variables: {
                        username: membersDetails.email,
                        email: membersDetails.email,
                        password1: pasw1,
                        password2: pasw1,
                    }
                });

                if (data.register.success) {
                    const {data} = await apolloClient.mutate({
                        mutation: createBoardUser,
                        variables: {...membersDetails}
                    })

                    if (data.createBoardMember.success) {
                        await swal.fire({
                            toast: true,
                            icon: 'success',
                            title: data.createBoardMember.message,
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        })

                    } else {
                        await swal.fire({
                            toast: true,
                            icon: 'error',
                            title: data?.createBoardMember?.message,
                            animation: false,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        })
                    }
                } else {
                    let errors = data?.register?.errors;
                    let error = errors.email || errors.username || errors.password1 || errors.password2;

                    await swal.fire({
                        toast: true,
                        icon: 'error',
                        title: error[0].message,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },

        async registerManagementMembers(_, membersDetails) {
            try {
                const randomWords = require('random-words');
                const pasw1 = randomWords() + "2LatraDT@";

                const {data} = await apolloClient.mutate({
                    mutation: registerUser,
                    variables: {
                        username: membersDetails.email,
                        email: membersDetails.email,
                        password1: pasw1,
                        password2: pasw1,
                    }
                });

                if (data.register.success) {
                    await apolloClient.mutate({
                        mutation: createBoardManagementMember,
                        variables: {
                            managementMember: membersDetails.email,
                            memberIsLeader: (membersDetails.managementMemberLevel === 'CHR'),
                            memberIsDirector: (membersDetails.managementMemberLevel === 'DIR'),
                            boardKey: membersDetails.boardKey,
                            position: membersDetails.position,
                            suffix: membersDetails.suffix,
                            title: membersDetails.title,
                            userRole: membersDetails.userRole,
                            lastName: membersDetails.lastName,
                            firstName: membersDetails.firstName
                        }
                    }).then((response) => {
                        if (response.data.createBoardManagementMember.success) {
                            swal.fire({
                                toast: true,
                                icon: 'success',
                                title: response.data?.createBoardManagementMember?.message,
                                animation: false,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            })
                        } else {
                            swal.fire({
                                toast: true,
                                icon: 'error',
                                title: response.data?.createBoardManagementMember?.message,
                                animation: false,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            })
                        }
                    })
                } else {
                    let errors = data?.register?.errors;
                    let error = errors.email || errors.username || errors.password1 || errors.password2;

                    await swal.fire({
                        toast: true,
                        icon: 'error',
                        title: error[0].message,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },

        async allBoardUsers(context, pageNumber = 1) {
            context.commit('clearBoardUsers');
            await context.dispatch("showLoadingSpinner", true);

            await apolloClient.query({
                query: allBoardUsers,
                fetchPolicy: 'no-cache',
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    pageNumber: pageNumber,
                },
            }).then((response) => {
                response.data.boardUsers.boardUsers.forEach((boarduser) => {
                    let sufix = '';
                    if (boarduser.userProfileSuffix !== "") {
                        sufix = ' ( ' + boarduser.userProfileSuffix + ' )'
                    }
                    context.commit('addBoardUsers', {
                        id: boarduser.id,
                        avatar: boarduser.userProfilePhoto,
                        fullname:
                            boarduser.userProfileTitle +
                            " " +
                            boarduser.firstName +
                            " " +
                            boarduser.lastName + sufix,
                        position: boarduser.userProfilePosition,
                        subtitle: boarduser.email,
                        active: boarduser.isVerified,
                        activeMessage: boarduser.userProfilePhoto,
                        role: boarduser.userRole,
                        verified: boarduser.isVerified,
                    });
                    context.commit('addBoardUsers', {divider: true, inset: true});
                });

                response.data.boardUsers.managementUsers.forEach((boarduser) => {
                    let sufix = '';
                    if (boarduser.userProfileSuffix !== "") {
                        sufix = ' ( ' + boarduser.userProfileSuffix + ' )'
                    }
                    context.commit('addNonBoardUsers', {
                        id: boarduser.id,
                        avatar: boarduser.userProfilePhoto,
                        fullname:
                            boarduser.userProfileTitle +
                            " " +
                            boarduser.firstName +
                            " " +
                            boarduser.lastName + sufix,
                        position: boarduser.userProfilePosition,
                        subtitle: boarduser.email,
                        active: boarduser.isVerified,
                        activeMessage: boarduser.userProfilePhoto,
                        role: boarduser.userRole,
                        verified: boarduser.isVerified,
                    });
                    context.commit('addNonBoardUsers', {divider: true, inset: true});
                });


                context.commit('addBoardUsersPage', response.data.boardUsers.boardUsersPage);
                context.commit('addNonBoardUsersPage', response.data.boardUsers.managementUsersPage);

                context.dispatch("showLoadingSpinner", false);

            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.log(error);
            });
        },

        async loadBoardMambers(context, pageNumber = 1) {
            context.commit('clearBoardMembers')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allBoardUsers,
                variables: {
                    boardUniqueId: localStorage.getItem('my-boardUniqueId'),
                    pageNumber: pageNumber,
                }
            }).then((response) => {
                context.commit('saveBoardMembers', response.data.boardUsers)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async searchBoardMambers(context, emailSearch) {
            context.commit('clearBoardSearchedMembers')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: searchBoardMemberByEmail,
                variables: {
                    emailSearch: emailSearch
                }
            }).then((response) => {
                context.commit('saveBoardSearchedMembers', response.data.searchBoardMemberByEmail)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.log(error);
            })
        },

        async revokeUserAccessLevel(context, membersDetails) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: revokeUserAccessLevel,
                variables: {
                    userRole: membersDetails.userRole,
                    userId: membersDetails.userId,
                    boardUniqueId: localStorage.getItem('my-boardUniqueId')
                }
            }).then((response) => {
                context.dispatch("showLoadingSpinner", false);
                console.log(response.data.revokeUserAccessLevel)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async editUserRole(context, rolePermObj) {
            await apolloClient.mutate({
                mutation: editUserRole,
                variables: {
                    permissions: rolePermObj.permissions,
                    roleUniqueId: rolePermObj.roleUniqueId,
                    roleName: rolePermObj.roleName,
                }
            }).then((response) => {
                console.log(response.data.editUserRole.success)
            })
        },

        async deleteUserRole(context, roleUniqueId) {
            await apolloClient.mutate({
                mutation: deleteUserRole,
                variables: {
                    roleUniqueId: roleUniqueId,
                }
            })
        },

        async userProfileDetails(context, userId) {
            context.commit('CLEAR_USER_PROFILE_DETAILS')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: userProfileDetails,
                variables: {
                    boardUniqueId: localStorage.getItem('my-boardUniqueId'),
                    userId: userId
                }
            }).then((response) => {
                context.commit('SET_USER_PROFILE_DETAILS', response.data.userProfileDetails)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateUserProfileDetails(context, sendData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: updateUserProfileDetails,
                variables: {
                    firstName: sendData.firstName,
                    lastName: sendData.lastName,
                    email: sendData.email,
                    suffix: sendData.suffix,
                    title: sendData.title,
                    phoneNumber: sendData.phoneNumber,
                    password1: sendData.password1,
                    password2: sendData.password2,
                    userId: sendData.userId,
                    nidaNumber: sendData.nidaNumber,
                    boardUniqueId: localStorage.getItem('my-boardUniqueId'),
                }
            }).then((response) => {
                context.dispatch("showLoadingSpinner", false);
                console.log(response.data.updateUserProfileDetails.success);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: "Successfully updated user Profile",
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateUserPostion(context, sendData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: updateUserProfilePosition,
                variables: sendData
            }).then((response) => {
                context.dispatch("showLoadingSpinner", false);
                console.log(response.data.updateUserProfilePosition.success);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: "Successfully updated user Profile",
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.log(error);
            })
        },

        async grantUserAccessToBoard(context, sendData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: grantUserAccessToBoard,
                variables: sendData
            }).then((response) => {
                console.log(response.data.grantUserAccessToBoard.success);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: "Successfully updated user Profile",
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.log(error);
            })
        },

        async blockUserAccesstoBoard(context, sendData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: blockUserBoardAccess,
                variables: sendData
            }).then((response) => {
                console.log(response.data.blockUserBoardAccess.success);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: "Successfully updated user Profile",
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.log(error);
            })
        },

    },

}

