<template>
    <v-container fluid class="my-5 content" app>
        <v-row  justify="center">
            <v-dialog
            v-model="AgendaBuilderWindow"
            persistent
            max-width="100%"
            :scrollable="scrollable"
            
            >
            <v-layout  style=" padding-top:10px" justify-space-around>
                <v-flex xs12 md5 max-width="100%">
                    <AgendaSidepanel  :meetingHasPublishedAgenda="HasPublishedAgenda" :listOfagendas="listOfagendas" :currentMeeting="currentMeeting" />
                </v-flex>
                <v-flex xs12 md7 max-width="100%">
                    <AgendaItemDetails :meetingHasPublishedAgenda="HasPublishedAgenda" :currentMeeting="currentMeeting"  @closeGreatModel="closeGreatModel" style="width:100%"/>
                </v-flex>
            </v-layout>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import AgendaItemDetails from './AgendaItemDetails.vue'
import AgendaSidepanel from './AgendaSidepanel.vue'

export default {
    props:['AgendaBuilderWindow','agendaMode','listOfagendas','currentMeeting'],
    components:{
        AgendaItemDetails,
        AgendaSidepanel
    },
    data () {
        return {
            scrollable:false,
            dialog2: false,
            dialog3: false,
            notifications: false,
            sound: true,
            widgets: false,
            items: [
            {
                title: 'Click Me',
            },
            {
                title: 'Click Me',
            },
            {
                title: 'Click Me',
            },
            {
                title: 'Click Me 2',
            },
            ],
            select: [
                { text: 'State 1' },
                { text: 'State 2' },
                { text: 'State 3' },
                { text: 'State 4' },
                { text: 'State 5' },
                { text: 'State 6' },
                { text: 'State 7' },
            ],
        }
    },
    watch:{
        agendaMode(){
            if(this.agendaMode=="template"){
                console.log("agendaModeagendaModeagendaModeagendaModeagendaModeagendaModeagendaModeagendaModeagendaModeagendaModeagendaModeagendaMode");
            }
        }
    },

    methods:{
        lockScrolling(){
            const body = document.getElementById("agendaPanels")
            body.style.overflow = 'hidden'
            body.style.overflow = 'auto'
        },
        getAgendaItemDetails(ItemId){
            console.log(ItemId);
        },
        closeGreatModel(){
            this.$emit('close')
        },
    },
    computed:{
      HasPublishedAgenda(){
          return (this.$store.state.MeetingsStore.selectedMeetingDetails||{}).meetingHasPublishedAgenda || false
      },
      
    }

}
</script>

<style>
 .fixed-position {
    position: fixed;
 }

.transparent {
   background-color: white!important;
   opacity: 0.3;
   border-color: transparent!important;
 }
 .scroll {
   overflow-y: scroll;
   
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5; 
}
</style>