<template>
  <v-row justify="center">
    <v-dialog v-model="showEditEvent" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h6"
          ><v-icon>mdi-calender </v-icon> Edit Board Event
          </span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="eventName"
                    :rules="nameRules"
                    :counter="100"
                    label="Event Name *"
                    required
                    prepend-icon="mdi-calendar-star"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="eventDescription"
                    :rules="nameRules"
                    :counter="100"
                    label="Event Descriptions *"
                    required
                    prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="eventStartDate"
                        label="Start Date *"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                      v-model="eventStartDate"
                      no-title
                      scrollable
                      :min="date"
                  >
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="startDateMenu = false">
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>


              <v-col cols="12" sm="6" md="6" lg="6">
                <v-dialog ref="dialog1" v-model="startTimeModal" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="eventStartTime"
                        label="Start Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="startTimeModal"
                      v-model="eventStartTime"
                      full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startTimeModal = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="eventEndDate"
                        label="End Date *"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                      v-model="eventEndDate"
                      no-title
                      scrollable
                      :min="eventStartDate"
                  >
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="endDateMenu = false">
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6">
                <v-dialog ref="dialog" v-model="endTimeModal" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="eventEndTime"
                        label="End Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="endTimeModal"
                      v-model="eventEndTime"
                      :min="eventStartTime"
                      full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endTimeModal = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                    :rules="colorRules"
                    dense
                    v-model="eventColor"
                    :filter="filter"
                    :items="items"
                    re
                    label="Select event color"
                    small-chips
                    outlined

                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <span class="subheading">Create</span>
                      <v-chip
                          :color="`${colors[nonce - 1]} lighten-3`"
                          label
                          small
                      >
                        {{ search }}
                      </v-chip>
                    </v-list-item>
                  </template>
                  <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                    >
                      <span class="pr-2">
                        {{ item.text }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>

                  <template v-slot:item="{ index, item }">
                    <v-text-field
                        v-if="editing === item"
                        v-model="eventColor"
                        autofocus
                        text
                        dense
                        clearable
                        outlined
                        @keyup.enter="edit(index, item)"
                    ></v-text-field>
                    <v-chip
                        v-else
                        :color="`${item.color} lighten-3`"
                        dark
                        label
                        small
                    >
                      {{ item.text }}
                    </v-chip>
                    <v-spacer></v-spacer>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-switch v-model="sendReminder" inset color="primary">
                  <template v-slot:label> Send Reminder </template>
                </v-switch>
              </v-col>
              <v-col v-if="sendReminder" cols="12" sm="12">
                <v-text-field
                    dense
                    v-model="reminderMessage"
                    label="Reminder Message"
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="closeDialog"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     :disabled="saveDisabled"
                     class="text-capitalize"
                     right
                     small
                     @click="editBoardEvent"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: [
      "showEditEvent",
      "eventUniqueId",
      "boardUniqueId",
      "eventName",
      "eventDescription",
      "eventStartDate",
      "eventEndDate",
      "eventStartTime",
      "eventEndTime",
      "eventColor",
      "sendReminder",
      "reminderMessage",

  ],
  data: () => ({

    startTimeModal: false,
    endTimeModal: false,
    loading: false,
    startDateMenu: false,
    endDateMenu: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length <= 100 || "Must be less than 100 characters",
    ],

    colorRules: [
      (v) => !!v || "Provide event color",
    ],

    activator: null,
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],

    editing: null,
    editingIndex: -1,
    items: [
      { header: "Select event color" },
      {
        text: "Blue",
        color: "blue",
      },
      {
        text: "Red",
        color: "red",
      },

      {
        text: "Purple",
        color: "purple",
      },

      {
        text: "Indigo",
        color: "indigo",
      },

      {
        text: "Orange",
        color: "orange",
      },
    ],

    sendReminder: false,
    reminderMessage: "",
    eventColor: "",
  }),

  computed: {
    saveDisabled() {
      return !this.eventColor
    }
  },

  methods: {
    closeDialog(){
      this.$emit('close-dialog')
    },

    async editBoardEvent() {
      let eventObject = {
        uniqueId: this.eventUniqueId,
        boardUniqueId: localStorage.getItem("my-boardUniqueId"),
        eventName: this.eventName,
        eventDescription: this.eventDescription,
        eventStartDate: this.eventStartDate,
        eventEndDate: this.eventEndDate,
        eventStartTime: this.eventStartTime,
        eventEndTime: this.eventEndTime,
        eventColor: this.eventColor.color,
        sendReminder: this.sendReminder,
        reminderMessage: this.reminderMessage,
      }

      console.log(eventObject)
      await this.$store.dispatch("updateBoardEvent", eventObject)
      await this.$store.dispatch('loadBoardEventList')
      await this.$emit("close-dialog")
    },


    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = this.eventColor;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : this.eventColor);

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
          text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
          -1
      );
    },

  },
};
</script>

<style>
</style>
