<template>
  <div>
    <div style="display: flex; justify-content: center; flex-wrap: wrap">
      <div
        class="padding intro-x"
        v-for="meeting in meetingByInstituionUniqueId"
        :key="meeting.meetingUniqueId"
        link
        :to="details + meeting.meetingUniqueId"
        style="display: flex; justify-content: center; margin-top: 40px"
      >
        <v-card
          @click="moveToAnotherPage(details + meeting.meetingUniqueId)"
          elevation="3"
          class="meeting-info-card"
          style="border-radius: 7px"
        >
          <div
            style="
              width: 100%;
              padding-left: 20px;
              padding-top: 10px;
              border-radius: 3px 3px 0 0;
            "
            class="flex flex-justify-content-start"
          >
            <p v-if="meeting.meetingCommitteeMeeting.edges.length > 0">
              {{meeting.meetingCommitteeMeeting.edges[0].node.meetingCommittee.committeeName}}
              ({{ meeting.meetingBoard.boardInstitution.institutionShortform }} )
            </p>
          </div>
          <div
            style="
              width: 100%;
              background-color: #ccc;
              border-radius: 3px 3px 0 0;
            "
            class="flex flex-justify-content-start"
          >
            <span
              style="
                background-color: #6267ff;
                height: 2px;
                width: 20%;
                margin: 0 1px 0 0;
                border-radius: 3px 0 0 0;
              "
            ></span>
            <span
              style="
                background-color: #6267ff;
                height: 2px;
                width: 20%;
                margin: 0 1px;
              "
            ></span>
            <span
              style="
                background-color: #6267ff;
                height: 2px;
                width: 20%;
                margin: 0 1px;
              "
            ></span>
            <span
              style="
                background-color: #6267ff;
                height: 2px;
                width: 20%;
                margin: 0 1px;
              "
            ></span>
            <span
              style="
                background-color: #6267ff;
                height: 2px;
                width: 20%;
                margin: 0 0 0 1px;
                border-radius: 0 3px 0 0px;
              "
            ></span>
          </div>
          <li class="hand-cursor" link :to="details + meeting.meetingUniqueId">
            <div class="flex flex-justify-content-start py px">
              <div
                style="margin-left: 20px"
                link
                :to="details + meeting.meetingUniqueId"
              >
                <span class="date-container">
                  <span class="date-month">{{ meeting.meetingMonth }}</span>
                  <span class="date-date">{{ meeting.meetingDay }}</span>
                </span>
                <span style="margin-left: 0px" class="flex-column">
                  <span class="date-day center">{{
                    meeting.meetingWeekday
                  }}</span>
                  <span class="date-time center" style="font-size: 14px">{{
                    meeting.meetingStartTime
                  }}</span>
                  <span class="date-timezone center"
                    >({{ meeting.meetingLocation.locationTimezone }})</span
                  >
                </span>
              </div>
              <v-divider
                vertical
                style="height: 100px; margin-right: 25px; margin-left: 10px"
              ></v-divider>
              <div
                link
                :to="details + meeting.meetingUniqueId"
                class="flex-column left"
              >
                <h4>{{ meeting.meetingTitle }}</h4>
                <span
                  style="padding-top: 10px"
                  class="flex flex-align-items-start"
                >
                  <v-icon>mdi-map-marker</v-icon>
                  <small>
                    <b>{{ meeting.meetingLocation.locationName }}</b>
                    <br />
                    <span>{{ meeting.meetingLocation.locationAddress }}</span>
                  </small>
                </span>
              </div>
            </div>
          </li>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  watch: {
    $route(to, from) {
      console.log(from.params);
      this.$store.dispatch(
        "updateCurrentStateForMyBoard",
        to.params.board_uuid
      );
    },
  },

  computed: {
    instituionUniqueId: {
      get: function () {
        return this.$store.state.MyboardStore.myboardUniqueId;
      },
      set: function (newValue) {
        return localStorage.setItem("my-boardUniqueId", newValue);
      },
    },
    ...mapGetters({
      meetingByInstituionUniqueId: "committeeMeetingsList",
    }),
  },
  data() {
    return {
      meetings: [],
      error: "",
      loading: "",
      details: "/board-committee/meeting-schedules/",
      agendas: "agendas/",
      notes: "notes/",
      agendaspdf: "agendaspdf/",
      detailedagenda: "detailedagenda/",
      boardpack: "boardpack/",
      minutes: "minutes/",
      privateClients: "privateClients",
    };
  },
  methods: {
    moveToAnotherPage(pageRoute) {
      this.$router.push(pageRoute);
    },
  },
};
</script>




<style>
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.padding {
  padding: 0px 10px;
}
.date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #6267ff;
  border-radius: 5px;
  width: 60px;
  height: 70px;
}

.date-month {
  background-color: #6267ff;
  color: white;
  width: 100%;
  text-align: center;
}

.date-date {
  font-size: 30px;
  font-weight: bold;
  height: 50px;
  margin-top: 0px;
}

.date-day {
  font-size: small;
  color: #999;
  /* height: 10px; */
}

.date-time {
  font-size: normal;
  height: 14px;
}

.date-timezone {
  font-size: xx-small;
  color: #999;
  padding-top: 5px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.center {
  align-items: center;
}
.flex-end {
  align-items: flex-end;
}
.flex-start {
  align-items: flex-start;
}
.flex {
  display: flex;
}
.flex-justify-content-between {
  justify-content: space-between;
}
.flex-justify-content-start {
  justify-content: start;
}
.flex-align-items-center {
  align-items: center;
}
.flex-align-items-start {
  align-items: flex-start;
}
.py {
  padding-top: 20px;
  padding-bottom: 20px;
}
.meeting-info-card {
  width: 350px;
  max-width: 400px;
  padding: 0;
}
.meeting-info-card > * {
  word-wrap: break-word;
}
.px {
  padding-left: 20px;
  padding-right: 20px;
}
.hand-cursor {
  cursor: pointer;
  list-style: none;
}
</style>









