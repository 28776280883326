<template>
  <v-container fluid>
    <div
      class="relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-5"
    >
      <div class="intro-x relative flex items-center mb-1">
        <div
          class="before:block before:absolute before:w-20 before:h-px before:bg-slate-200 before:dark:bg-darkmode-400 before:mt-5 before:ml-5"
        >
          <div
            class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden"
          >
            <img
              alt="Midone - HTML Admin Template"
              src="@/assets/img/minutes.png"
            />
          </div>
        </div>
        <div class="box px-5 py-3 ml-4 flex-1">
          <div class="flex items-center">
            <div class="font-medium">{{ directive.directiveTitle }}</div>
            <div class="text-xs text-slate-500 ml-auto">
              <v-chip small
              ><strong>Due-Date: </strong
              >{{ directive.directiveCreateddate | formatDate }}
              </v-chip
              >
            </div>
          </div>
          <hr class="mt-2"/>
          <div class="text-slate-500 mt-1">
            <span v-html="directive.directiveDescriptions"></span>
          </div>
          <hr class="mt-2"/>
          <div class="flex items-center">
            <v-col>
              <v-card-subtitle style="font-size:16px">
                <p v-if="directive.directiveCategory === 'ALL'"><strong>Assignees: </strong>
                  <v-chip> ALL MANAGEMENT MEMBERS</v-chip>
                </p>
                <p v-else>
                  <v-chip><strong>Assignees:</strong></v-chip>
                    <span v-for="(assignee, index) in directive.directiveOwner" v-bind:key="index" class="px-3">
                      {{ index+1 }}. {{ assignee.firstName }}
                      ({{ assignee.lastName }})
                    </span>
                </p>
              </v-card-subtitle>
            </v-col>

            <div class="text-xs text-slate-500 ml-auto">
              <v-menu
                offset-y
                app
                v-if="!directive.directiveStatus && directiveOwner"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="btn rounded-full text-xs bg-success white--text cursor-pointer font-medium"
                    text
                    small
                    style="text-transform: capitalize; font-size: 14px"
                    v-bind="attrs"
                    v-on="on"
                    slot="activator"
                  >
                    <v-icon small left>mdi-pencil-remove-outline</v-icon>
                    Report Implementation
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    link
                    v-for="(status, index) in action_status_list"
                    :key="index"
                  >
                    <v-list-item-title
                      @click="openProgressForm(directive, status.status)"
                    >
                      <v-icon :color="status.iconColor" small left>{{
                          status.icon
                        }}
                      </v-icon>
                      {{ status.status }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                @click="openMyActionCard"
                outlined
                class="ma-1 rounded-full"
                small
              >
                <v-icon>mdi-progress-check</v-icon>
                View Implementation
              </v-btn
              >
<!--              <v-btn-->
<!--                v-if="!directive.directiveStatus"-->
<!--                @click="openReminderDialogCard(directive)"-->
<!--                text-->
<!--                class="ma-1 bg-indigo"-->
<!--                small-->
<!--                fab-->
<!--              >-->
<!--                <v-icon>mdi-alarm-plus</v-icon>-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                @click="openReminderDialogCard(directive)"-->
<!--                text-->
<!--                class="ma-1 bg-indigo"-->
<!--                small-->
<!--                fab-->
<!--              >-->
<!--                <v-icon>mdi-printer</v-icon>-->
<!--              </v-btn>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <ActionProgress
        from="management"
        @CloseActionProgress="directiveresponcedialog=false" :directive="directive" :status="status"
                    :directiveresponcedialog="directiveresponcedialog"/>
    <ViewMyActionCard
        from="management"
        @CloseActionCard="openAction=false" :directive="directive" :openAction="openAction"/>
  </v-container>
</template>

<script>
import ActionProgress from './ManagementActionProgress.vue'
import ViewMyActionCard from './ViewMyActionCard.vue'
import swal from 'sweetalert2'

export default {
  props: ['nonBoardMembers', "directive"],
  components: {ActionProgress, ViewMyActionCard},
  data() {
    return {
      openAction: false,
      status: null,
      reporteddirective: null,
      directiveresponcedialog: false,
      OpenNoticeDialog: false,
      action_status_list: [
        {iconColor: 'red', icon: 'mdi-circle-outline', status: 'Not Started'},
        {iconColor: 'primary', icon: 'mdi-sync', status: 'In Progress'},
        {iconColor: 'orange', icon: 'mdi-pause-circle-outline', status: 'On Hold'},
        {iconColor: 'success', icon: 'mdi-check', status: 'Done'},
      ]
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.AuthStore.user.ownerUser.edges[0].node.ownerDesignation.designationShortForm;
    },
    directiveOwner() {
      return this.directive.directiveOwner.some(owner => owner.lastName === this.currentUser)
    },
    directiveOwnersList() {
      let directiveOwners = ""
      this.directive.directiveOwner.forEach(owner => {
        directiveOwners += owner.firstName + ' ' + owner.lastName + ', '
      })
      return directiveOwners.slice(0, -2)
    }
  },
  methods: {
    openMyActionCard() {
      this.$store.dispatch("loadManagementDirectiveImplementationProgress", this.directive.directiveUniqueId);
      this.openAction = true
    },

    openProgressForm(directive, status) {
      this.reporteddirective = directive
      this.status = status
      this.directiveresponcedialog = true
    },

    swalcaller() {
      this.directiveresponcedialog = false
      swal.fire({
        toast: true,
        icon: 'success',
        title: 'Directive Implementation saved successfully',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      })
    }
  }
}
</script>

<style>

</style>
