<template>
  <v-container fluid class="my-5" app>
    <v-dialog v-model="openDesignationDialog" persistent max-width="60%">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          <v-icon class="mr-2">mdi-star-plus</v-icon>
          {{ title }}
        </v-card-title>

        <v-form class="px-3">
          <v-card-text>
            <v-text-field
              v-model="Shortform"
              autofocus
              label="Designation Short Form*"
            ></v-text-field>

            <v-text-field
              v-model="Longform"
              label="Description*"
            ></v-text-field>

            <v-select
              v-model="asignee"
              :items="listBoardMembers"
              chips
              clearable
              item-text="name"
              item-value="id"
              label=" Designation Owner*"
              required
              prepend-icon="mdi-account-star-outline"
              multiple
            >
            </v-select>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn
            small
            outlined
            color="pink"
            @click="
              openDesignationDialog = !openDesignationDialog;
              $emit('closeNewDesignationDialog');
              Shortform= null;
              Longform= null;
              asignee= null;
            "
          >
            Close
          </v-btn>

          <v-btn @click="saveNewDesignation" small outlined color="primary">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: ["openDesignationDialog", "title", "designation", "action"],
  data: () => ({
    Shortform: null,
    Longform: null,
    asignee: [],
  }),

  watch: {
    designationObj() {
      this.Shortform = this.designation.designationShortForm;
      this.Longform = this.designation.designationName;

      this.designation.designation_owners.forEach(item => {
        this.asignee.push({
          id: item.primaryKey,
          name: item.designationOwner
        });
      })
    }
  },

  computed: {
    listBoardMembers() {
      const usersList = [];
      const managements = this.$store.state.ManagementsStore.managementsMembers;

      managements.forEach((management) => {
        usersList.push({
          id: management.id,
          name: management.firstName + " " + management.lastName,
        });
      });

      const ids = usersList.map((user) => user.id);
      return usersList.filter(
          ({id}, index) => !ids.includes(id, index + 1)
      );
    },

    designationObj() {
      return this.designation
    }
  },

  methods: {
    async saveNewDesignation() {
      if (this.action===`Add`) {
        const desgnData = {
          designationShortForm: this.Shortform,
          designationDescriptions: this.Longform,
          designationOwner: this.asignee,
        };
        await this.$store.dispatch("SettingsStore/addNewDesignation", desgnData);
      } else {
        const desgnData = {
          designationShortForm: this.Shortform,
          designationDescriptions: this.Longform,
          designationOwner: this.asignee,
          designationUniqueId: this.designation.designationUniqueId
        };
        await this.$store.dispatch("SettingsStore/updateDesignation", desgnData);
      }

      await this.$store.dispatch("SettingsStore/getBoardMeetingSettings", {
        root: true,
      });

      this.Shortform = null;
      this.Longform = null;
      this.asignee = null;

      this.$emit("closeNewDesignationDialog");
    },
  },
};
</script>

<style>
</style>
