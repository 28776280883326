import { render, staticRenderFns } from "./ManagementCommitteeMeetingDetails.vue?vue&type=template&id=9d144bc8&"
import script from "./ManagementCommitteeMeetingDetails.vue?vue&type=script&lang=js&"
export * from "./ManagementCommitteeMeetingDetails.vue?vue&type=script&lang=js&"
import style0 from "./ManagementCommitteeMeetingDetails.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ManagementCommitteeMeetingDetails.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VChip,VContainer,VFlex,VIcon,VLayout,VList,VListItem,VListItemTitle,VMenu,VSimpleTable,VSubheader,VTooltip})
