<template>
    <v-container fluid>

        <ckeditor v-if="tableBodyDataTemp" @change="onEditorChange($event)" :editor="editor" v-model="tableBodyDataTemp" :config="editorConfig"></ckeditor>
        <ckeditor v-else-if="!tableBodyData" @change="onEditorChange($event)" :editor="editor" v-model="content" :config="editorConfig"></ckeditor>
<!--      {{tableBodyDataTemp}}-->
    </v-container>

</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  props:['editorOption','tableBodyData'],
  data(){
      return{
      tableBodyDataTemp:null,
      content: `<p><strong>2.0 CHALLENGE AND REMEDIAL ACTION</strong></p><figure class="table"><table><thead><tr><th rowspan="2"><strong>S/No.</strong></th><th rowspan="2"><strong>Challenges Encountered</strong></th><th colspan="2"><strong>Quarterly Actual Expenditure&nbsp;</strong></th><th colspan="2" rowspan="2"><strong>Remedial Action&nbsp;</strong></th></tr><tr><th><strong>Quarter</strong></th><th><strong>Cumulative</strong></th></tr></thead><tbody><tr><td>1.0</td><td>Uncertainty of sustainable funding from central Government and difficulties in collecting debts from customers who are Public Institutions depending on Government subvention.</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">Strengthening efforts of finding other sources of &nbsp; revenue and strengthening mechanisms of collecting debts from customers especially by using technology.</td></tr><tr><td colspan="6"><strong>RECURRENT EXPENDITURE</strong></td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">&nbsp;</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">&nbsp;</td></tr><tr><td colspan="5"><strong>No. of Employees as at the end of the Quarter</strong></td><td>191</td></tr></tbody></table></figure><p><strong>i. ANNEXURES</strong></p>`,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
            items: [        
                'fontfamily', 'fontsize', '|',
                'alignment', '|',
                'fontColor', 'fontBackgroundColor', '|',
                'insertTable', '|',
                'outdent', 'indent', '|',
                'undo', 'redo'
            ],
            shouldNotGroupWhenFull: true
        }
 }}
 },
  
  watch:{
    content: function(){
      console.log("content"+this.content)
      this.$emit("tableContentChanges",this.content)
    },
    tableBodyDataTemp: function(){
      if(this.tableBodyDataTemp){
        console.log("tableBodyDataTemp"+this.tableBodyDataTemp)
        this.$emit("tableContentChanges",this.tableBodyDataTemp)
      }
    },
    tableBodyData:function (new_value) {
      console.log("This is new value",new_value)
      this.tableBodyDataTemp=new_value
    }
  },
  mounted(){
    this.tableBodyDataTemp=this.tableBodyData
    console.log(this.tableBodyDataTemp)

  },
  methods:{
    // onEditorChange(event){
    //   this.$emit("tableContentChanges",event)
    // },
  },
  computed: {
    hello() {
      return this.tableBodyData
    }
  }
}
</script>

