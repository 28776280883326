import swal from "sweetalert2";


export const successAlert = (title) => swal.fire({
    toast: true,
    icon: 'success',
    title: title,
    animation: false,
    position: 'top-right',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
})

export const errorAlert = (title) => swal.fire({
    toast: true,
    icon: 'error',
    title: title,
    animation: false,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
})

export const confirmAlert = (message, buttonTxt) => swal.fire({
    title: `<span style="font-size: 25px;">${message}</span>`,
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: buttonTxt,
    confirmButtonColor: '#d33'
})