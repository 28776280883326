<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p>Access to Documents generated during Meetings</p>
      </v-col>
      <v-col class="d-flex" cols="12" sm="4">
        <div class="relative">
          <input v-model="searchQuery" type="text" class="form-control py-3 px-4 w-full lg:w-64 box pr-8" placeholder="Search here...">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              icon-name="search"
              class="lucide lucide-search w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              data-lucide="search"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>
      </v-col>


      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" sm="5"></v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" sm="1">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary white--text"
                fab
                icon
                outlined
                right
                small
                style="margin: 5px"
                v-bind="attrs"
                @click="callToCreateFolders('Root/', true, '')"
                v-on="on"
            >
              <v-icon>mdi-folder-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Add New Folder</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12">
        <v-layout justify-start wrap>
          <v-card
              v-for="folder in folderAndFilesList"
              :key="folder.primaryKey"
              class="intro-y"
              link
              max-width="344"
              outlined
              style="margin: 5px"
          >
            <v-list-item three-line>
              <v-list-item-avatar size="90" tile>
                <v-img
                    lazy-src="@/assets/folder_lazy.png"
                    max-height="100%"
                    max-width="100%"
                    src="@/assets/folder.png"
                >
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="text">{{ folder.folderName }}</div>
                <v-list-item-subtitle>
                  <strong>Files:</strong> {{ folder.listOfFiles.length }} <br/>
                  <strong>Created:</strong> {{ folder.folderDate | formatDate }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-menu offset-x right top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                      :to="
                      '/eboard_governance_repository/' +
                      folder.folderUniqueId +
                      '/' +
                      folder.primaryKey +
                      '/' +
                      folder.folderName
                    "
                      dense
                      link
                  >
                    <v-item-text>
                      <v-icon>mdi-folder-open</v-icon>
                      View Contents
                    </v-item-text>
                  </v-list-item>
<!--                  <v-list-item-->
<!--                      v-if="folder.writeAccess || folder.folderOwner"-->
<!--                      dense-->
<!--                      link-->
<!--                      @click="-->
<!--                      callToCreateFolders(-->
<!--                        folder.folderName + '/',-->
<!--                        false,-->
<!--                        folder.folderUniqueId-->
<!--                      )-->
<!--                    "-->
<!--                  >-->
<!--                    <v-item-text>-->
<!--                      <v-icon>mdi-folder-plus-outline</v-icon>-->
<!--                      Create Folder-->
<!--                    </v-item-text>-->
<!--                  </v-list-item>-->
                  <v-list-item
                      v-if="folder.folderOwner"
                      dense
                      link
                      @click="loadPeopleWithFolderAccess(folder.folderUniqueId)"
                  >
                    <v-item-text>
                      <v-icon>mdi-lock</v-icon>
                      Access Control Matrix
                    </v-item-text>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                      v-if="folder.writeAccess || folder.folderOwner"
                      dense
                      link
                      @click="deleteRepositoryFolder(folder.folderUniqueId)"
                  >
                    <v-item-text>
                      <v-icon>mdi-delete</v-icon>
                      Delete Folder
                    </v-item-text>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-card>
        </v-layout>
        <div
            v-for="(repository, index) in meetingRepository"
            :key="index"
            class="intro-y"
            style="padding: 10px"
        >
          <v-card class="mx-auto" max-width="100%" outlined>
            <v-row>
              <v-col md="12" sm="12">
                <v-card-title>
                  <v-icon class="mr-1" color="rgba(227, 150, 0, 0.56)" size="35"
                  >mdi-folder
                  </v-icon
                  >
                  {{ repository.meetingTitle }}
                  <v-spacer></v-spacer>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          style="padding: 25px"
                          @click="
                          openDocumentViewer(
                            repository.meetingAgendaDocumentPath
                          )
                        "
                      >
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-note-multiple-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Agenda</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          style="padding: 25px"
                          @click="
                          openDocumentViewer(
                            repository.meetingBoardPackDocumentPath
                          )
                        "
                      >
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-book-multiple
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Board Packs</span>
                  </v-tooltip>

                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          style="padding: 25px"
                          @click="
                          previewMeetingMinutesMinutes(repository.meetingUniqueId)
                        "
                      >
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-file-document-multiple
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Minutes</span>
                  </v-tooltip>

                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          style="padding: 25px"
                          @click="previewDirectives(repository.meetingUniqueId)"
                      >
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-file-delimited-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Directives</span>
                  </v-tooltip>

                  <v-tooltip bottom color="success">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn rounded style="padding: 25px" icon @click="uploadDocument(repository.meetingUniqueId)"
                             v-if="hasAccess('can_add_management_meeting')">
                        <v-icon color="success" dark v-bind="attrs" v-on="on">
                          mdi-file-upload-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Upload Document</span>
                  </v-tooltip>

<!--                  <v-menu app offset-y z-index="1">-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                      <v-icon-->
<!--                          slot="activator"-->
<!--                          v-bind="attrs"-->
<!--                          @click="opened = !opened"-->
<!--                          v-on="on"-->
<!--                      >mdi-dots-vertical-->
<!--                      </v-icon-->
<!--                      >-->
<!--                    </template>-->
<!--                    <v-list dense></v-list>-->
<!--                  </v-menu>-->
                </v-card-title>

                <v-card-text>
                  <v-row align="center" class="mx-">
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <span
                          ><v-icon>mdi-paperclip</v-icon>
                            {{ repository.meetingDocumentsCount }} Agenda
                            Documents</span
                          >
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            v-if="
                            repository.meetingSupportingDocuments.length > 0
                          "
                            style="rgba(38, 27, 216, 0.11)"
                        >
                          <v-list subheader>
                            <v-list-item
                                v-for="(
                                doc, index
                              ) in repository.meetingSupportingDocuments"
                                :key="index"
                            >
                              <v-list-item-avatar>
                                <v-img
                                    src="/pdf.png"
                                    style="width: 90%"
                                ></v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>
                                  <span link>{{
                                      doc.documentName
                                    }}</span></v-list-item-title
                                >
                              </v-list-item-content>

                              <v-list-item-icon>
                                <v-btn
                                    icon
                                    @click="openDocumentViewer(doc.documentPath)"
                                >
                                  <v-icon color="indigo">mdi-file-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="doc.documentIsUploaded" icon @click="deleteRepositoryDocument(doc.documentUniqueId)" class="ma-1">
                                  <v-icon color="error">mdi-delete</v-icon>
                                </v-btn>
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <DocumentViewer
        v-if="OpenfileDialog === true"
        :OpenfileDialog="OpenfileDialog"
        :docValue="docValue"
        :title="title"
        @CloseDocumentViwer="OpenfileDialog = false"
    />

    <CreateNewFolder
        :createFolderDialog="createFolderDialog"
        :isRoot="isRoot"
        :parentFolder="parentFolderName"
        :parentFolderKey="parentFolderKey"
        @CloseCreateFolderDialog="createFolderDialog = false"
        @loadContents="loadRootDocumentsFromRepository"
    />

    <FolderAccessControl
        :folderAccessMatrixDialog="folderAccessMatrixDialog"
        :folderUniqueId="folderUniqueId"
        @closeFolderAccessMatrixDialog="folderAccessMatrixDialog = false"
    />


    <BoardDocumentUploader v-if="supportDoc===true" :supportDoc="supportDoc" @closeSupportDocumentUploader="closeSupportDocumentUploader"
                      :meetingUniqueId="meetingUniqueId"/>
  </v-container>
</template>

<script>
import CreateNewFolder from "./CreateNewFolder.vue";
import FolderAccessControl from "./FolderAccessControl.vue";
import DocumentViewer from "../eboard-documents/DocumentViewer.vue";
import previewMeetingDirectives from "../../../graphql/eboard-actions-graphql/previewMeetingDirectives.graphql";
import BoardDocumentUploader from "@/modules/meeting_management/eboard-repository/BoardDocumentUploader.vue";
import {confirmAlert} from "@/services/notificationService";

export default {
  components: {
    BoardDocumentUploader,
    CreateNewFolder,
    DocumentViewer,
    FolderAccessControl,
  },
  data() {
    return {
      folderUniqueId: null,
      folderAccessMatrixDialog: false,
      createFolderDialog: false,
      parentFolderName: "",
      parentFolderKey: "",
      isRoot: true,
      OpenfileDialog: false,
      docValue: "",
      supportDoc: false,
      meetingUniqueId: "",
      title: "",
      searchQuery:""
    };
  },
  methods: {
    uploadDocument(meetingUniqueId) {
      this.meetingUniqueId = meetingUniqueId;
      this.supportDoc = true
    },


    async closeSupportDocumentUploader() {
      this.supportDoc = false;
      await this.$store.dispatch("loadAllMeetingsRepository");
    },

    async deleteRepositoryDocument(documentUniqueId) {
      confirmAlert("Are you sure?", "Delete").then(async (data) => {
        if (data.isConfirmed) {
          await this.$store.dispatch('deleteBoardRepositoryDocument', documentUniqueId);
          await this.$store.dispatch("loadAllMeetingsRepository");
        }
      })
    },

    async loadPeopleWithFolderAccess(folderUniqueId) {
      await this.$store.dispatch('loadPeopleWithFolderAccess', folderUniqueId)
      this.folderUniqueId = folderUniqueId
      this.folderAccessMatrixDialog = true;
    },

    previewDirectives(meetingUniqueId) {
      this.previewLoading = true;
      this.$apollo
          .mutate({
            mutation: previewMeetingDirectives,
            variables: {
              meetingUniqueId: meetingUniqueId,
            },
          })
          .then((response) => {
            this.previewLoading = false;
            this.docValue =
                "/" + response.data.previewMeetingDirectives.directiveDocumentPath;
            this.$emit("closeGreatModel");
            this.OpenfileDialog = true;
          });
    },

    openDocumentViewer(meetingAgendaDocumentPath) {
      this.previewLoading = true;
      this.docValue = meetingAgendaDocumentPath;
      this.OpenfileDialog = true;
    },

    async previewMeetingMinutesMinutes(meetingUniqueId) {
      await this.$store.dispatch('previewMeetingMinutesMinutes', meetingUniqueId);
      this.docValue = "/" + this.$store.state.MinutesStore.priviewMinutes.attachmentPath;
      this.OpenfileDialog = true;
    },

    callToCreateFolders(parentFolderName, isRoot, parentFolderKey) {
      this.parentFolderName = parentFolderName;
      this.parentFolderKey = parentFolderKey;
      this.isRoot = isRoot;
      this.createFolderDialog = !this.createFolderDialog;
    },

    async deleteRepositoryFolder(folder) {
      // await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("deleteFoldersAndContents", {
        docKey: folder,
        docType: "Folder",
      });
      await this.$store.dispatch("loadRootDocumentsFromRepository");
      // await this.$store.dispatch("showLoadingSpinner", false);
    },

    async loadRootDocumentsFromRepository() {
      await this.$store.dispatch("loadRootDocumentsFromRepository");
    },
  },
  computed: {
    folderWriteAccess() {
      return this.$store.state.RepositoryStore.folderWriteAccess;
    },
    folderAndFilesList() {
      var forlders=this.$store.state.RepositoryStore.governanceRepositoryFolders;
      if (this.searchQuery=="All") {
        return forlders
      }else{
        return forlders.filter(folder => {
          return folder.folderName.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }
    },
    meetingRepository() {
      var meetings=this.$store.state.RepositoryStore.meetingRepository;
      if (this.searchQuery=="All") {
        return meetings
      }else{
        return meetings.filter(meeting => {
          return meeting.meetingTitle.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }
    },
  },
  async mounted() {
    this.$store.dispatch("showLoadingSpinner", true);
    this.$store.dispatch("loadBoardMambers")
    await this.$store.dispatch("loadRootDocumentsFromRepository");
    await this.$store.dispatch("loadAllMeetingsRepository");
    this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>

<style>
</style>
