<template>
  <v-container fluid>
    <v-row class="mt-5 mx-3">
      <v-spacer></v-spacer>
      <button
        class="btn btn-primary shadow-lg mr-2 white--text"
        @click="
          openDesignationDialogFunction(`Add New Designation`, null, `Add`)
        "
      >
        <v-icon class="white--text"> mdi-star-plus</v-icon>
        New Designation
      </button>
    </v-row>

    <div>
      <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
        <div class="intro-y inbox box mt-5">
          <div
            class="p-5 flex flex-col-reverse sm:flex-row text-slate-500 border-b border-slate-200/60"
          >
            <div class="flex items-center sm:ml-auto">
              <div class="" style="font-size:20px" >Management / Board Users Designation</div>
            </div>
          </div>
          <div>
            <div class="overflow-x-auto ma-5">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap">Short Form</th>
                  <th class="whitespace-nowrap">Name</th>
                  <th class="whitespace-nowrap">Status</th>
                  <th class="whitespace-nowrap">Owners</th>
                  <th class="whitespace-nowrap">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="data in boardDesignations"
                    :key="data.designationUniqueId"
                    class="intro-y"
                  >
                    <td>{{ data.designationShortForm }}</td>
                    <td>
                      {{ data.designationName }}
                    </td>
                    <td>
                      <div
                        v-if="data.designationIsActive"
                        class="flex items-center justify-center text-success"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide w-4 h-4 mr-2"
                        >
                          <polyline points="9 11 12 14 22 4"></polyline>
                          <path
                            d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                          ></path>
                        </svg>
                        Active
                      </div>
                      <div
                        v-else
                        class="flex items-center justify-center text-danger"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="pink"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-x-circle mr-2"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="15" y1="9" x2="9" y2="15"></line>
                          <line x1="9" y1="9" x2="15" y2="15"></line>
                        </svg>
                        In Active
                      </div>
                    </td>
                    <td class="flex">
                      <div
                        v-for="owner in data.designation_owners"
                        :key="owner.ownerUniqueId"
                        class="w-10 h-10 image-fit zoom-in -ml-1"
                      >
                        <v-tooltip color="info" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              v-bind="attrs"
                              v-on="on"
                              v-if="owner.designationProfile !== ''"
                              class="rounded-full"
                              :src="MEDIA_SERVER + owner.designationProfile"
                            />
                            <vue-initials-img
                              v-bind="attrs"
                              v-on="on"
                              class="rounded-full"
                              v-else
                              :name="owner.designationOwner"
                            />
                          </template>
                          <span>{{ owner.designationOwner }}</span>
                        </v-tooltip>
                      </div>
                    </td>
                    <td>
                      <div
                        v-if="hasAccess('can_manage_users')"
                        class="flex justify-start items-start"
                      >
                        <button
                          @click="
                            openDesignationDialogFunction(
                              `Edit Designation`,
                              data,
                              `Edit`
                            )
                          "
                          class="flex items-center info--text"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-edit w-4 h-4 mr-1"
                          >
                            <path
                              d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                            ></path>
                            <path
                              d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                            ></path>
                          </svg>

                          Edit
                        </button>
                        <button
                          v-if="data.designationIsActive"
                          @click="
                            deactivateDesignation(
                              data.designationUniqueId,
                              false
                            )
                          "
                          class="flex items-center text-danger"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="lucide w-4 h-4 mr-1"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                              d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"
                            ></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                          Delete
                        </button>
                        <button
                          v-else
                          @click="
                            deactivateDesignation(data.designationUniqueId)
                          "
                          class="flex items-center text-success"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-check-circle w-4 h-4 mr-1"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                          Activate
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="p-5 flex flex-col sm:flex-row items-center text-center sm:text-left text-slate-500"
          >
            <div class="sm:ml-auto mt-2 sm:mt-0"></div>
          </div>
        </div>
      </div>
    </div>

    <DesignationSetting
      :action="action"
      :designation="designation"
      :openDesignationDialog="openDesignationDialog"
      :title="title"
      @closeNewDesignationDialog="
        openDesignationDialog = !openDesignationDialog;
        designation = ''; loadDesignations()
      "
    />
  </v-container>
</template>

<script>
import DesignationSetting from "./DesignationSettings.vue";

export default {
  components: { DesignationSetting },

  data() {
    return {
      openDesignationDialog: false,
      search: "",
      headers: [
        {
          text: "Designation Shortform",
          align: "start",
          value: "designationShortForm",
        },
        { text: "Descriptions", value: "designationName" },
        { text: "Assigned User", value: "designation_owner" },
        { text: "Actions", value: "actions" },
      ],
      title: "",
      designation: "",
      action: "",
    };
  },

  methods: {
    loadDesignations() {
      this.$store.dispatch('loadBoardDesignations')
    },
    openDesignationDialogFunction(title, designation, action) {
      this.title = title;
      this.action = action;
      this.designation = designation;
      this.openDesignationDialog = true;
    },

    async deactivateDesignation(designationUniqueId) {
      await this.$store.dispatch("showLoadingSpinner", true);
      const desgnData = {
        designationUniqueId: designationUniqueId,
      };
      await this.$store.dispatch(
        "SettingsStore/deactivateDesignation",
        desgnData
      );
      await this.$store.dispatch("SettingsStore/getBoardMeetingSettings", {
        root: true,
      });
      this.Shortform = null;
      this.Longform = null;
      this.asignee = null;
      await this.$store.dispatch("showLoadingSpinner", false);
      this.$emit("closeNewDesignationDialog");
    },
  },

  computed: {
    boardDesignations() {
      return this.$store.state.SettingsStore.boardDesignationsList;
    },
  },

  async mounted() {
    await this.$store.dispatch("loadManagementsMambers");
  },
};
</script>

<style></style>
