<template>
  <v-dialog
    v-model="confimMinutesDialog"
    max-width="50%"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5;cencontent">
        Confirm Minutes
      </v-card-title>

      <v-card-text class="cencontent">
        Select one of the last un-confirmed minutes from previous meetings
      </v-card-text>

      <v-card-text>
        <v-autocomplete v-model="lastMeeting" :items="lastMeetings" item-text="meetingTitle" item-value="primaryKey"
                        label="Select Previous Meetings*" required></v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          small
          text
          @click="CloseConfirmMinutesDialog"
        >
          Cancel
        </v-btn>

        <v-btn
          color="green darken-1"
          small
          text
          @click="addUnConfirmMinutes"
        >
          Select
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['confimMinutesDialog', "selectedAgenda"],

  data() {
    return {
      lastMeeting: null,
    }
  },

  watch: {
    confimMinutesDialog(value) {
      if (value) {
        this.loadUnconfirmedManagementMeetings();
      }
    }
  },

  computed: {
    lastMeetings() {
      return this.$store.getters.getManagementUnconfirmedMeetings;
    },
    selectedMeeting() {
      return this.$store.getters.getManagementMeetingDetails;
    }
  },

  methods: {
    CloseConfirmMinutesDialog() {
      this.lastMeeting = null;
      this.$emit("CloseConfimMinutesDialog");
    },

    async loadUnconfirmedManagementMeetings() {
      await this.$store.dispatch("loadManagementUnConfirmMeetings");
    },

    async addUnConfirmMinutes() {
      await this.$store.dispatch('showLoadingSpinner', true);

      await this.$store.dispatch('addManagementUnConfirmMeetingsMinutesAgendaItem', {
        selectedAgenda: this.selectedAgenda,
        lastMeeting: this.lastMeeting,
      });

      this.CloseConfirmMinutesDialog();
      await this.$store.dispatch('showLoadingSpinner', false)
    }
  }
}
</script>
