<template>
  <div style="background: rgba(39, 24, 245, 0.06)" class="dashboard rounded-md">
    <v-container class="my-1">
      <div
          v-if="agendaList.length > 0"
          class="mt-5 relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-2"
      >
        <div
            v-for="(agenda, index) in agendaList"
            :key="index"
            class="intro-x relative flex items-center mb-3"
        >
          <div
              class="before:block before:absolute before:w-20 before:h-px before:bg-slate-200 before:dark:bg-darkmode-400 before:mt-5 before:ml-3"
          >
            <div
                class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden"
            >
              <img alt="Agenda" src="@/assets/img/agenda.png"/>
            </div>
          </div>
          <div
              @click="updateSelectedAgenda(agenda);"
              class="box px-5 py-3 ml-2 flex-1 zoom-in"
          >
            <div class="flex items-center">
              <div class="font-medium">
                {{ index + 1 }} . {{ agenda.agendaTitle }}
              </div>
              <div class="text-xs text-slate-500 ml-auto">
                <v-menu app offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        slot="activator"
                        color="indigo"
                        icon
                        v-bind="attrs"
                        @click="onClickButton"
                        v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                        link
                        @click="moveAgenda(agenda.id, 'UP')"
                    >
                      <v-list-item-title>
                        <v-icon style="height: 16px; font-size: 16px"
                        >mdi-arrow-up
                        </v-icon
                        >
                        Move it Up
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="moveAgenda(agenda.id, 'DOWN')"
                    >
                      <v-list-item-title>
                        <v-icon style="height: 16px; font-size: 16px"
                        >mdi-arrow-down
                        </v-icon
                        >
                        Move it Down
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="
                        deleteAgenda(
                          agenda.id
                        )
                      "
                    >
                      <v-list-item-title>
                        <v-icon style="height: 16px; font-size: 16px"
                        >mdi-delete
                        </v-icon
                        >
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--                <span v-else>-->
                <!--                  <small class="success&#45;&#45;text">Published</small>-->

                <!--                </span>-->
              </div>
            </div>
            <!--            <div class="text-slate-500 mt-1"><small>{{ agenda.itemDetails.itemPuporse }}</small></div>-->
          </div>
        </div>
      </div>


      <p v-else>
        There are no Agenda Items for this Section. Select an option bellow and
        add one
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ManagementCommitteeAgenda",
  props: ["currentMeeting"],

  data() {
    return {
      expanded: [0],
      moveCount: 0
    };
  },

  methods: {
    async moveAgenda(agendaUniqueId, agendaPosition) {
      await this.$store.dispatch("moveManagementCommitteeAgendaPosition", {
        agendaUniqueId,
        agendaPosition,
      })
      this.moveCount += 1
    },

    deleteAgenda(agendaUniqueId) {
      this.$store.dispatch("deleteManagementCommitteeStandardAgenda", {agendaUniqueId});
    },

    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },

    onClickButton() {
      this.$emit("lockScroll", "someValue");
    },

    async updateSelectedAgenda(agenda) {
      // this.$store.dispatch("showLoadingSpinner", true);
      // await this.$store.dispatch("loadAllDecisionMinutes", agenda.itemKey);
      // await this.$store.dispatch("loadAllDirectiveMinutes", agenda.itemKey);
      await this.$store.dispatch("loadManagementCommitteeAgendaNotes", agenda.id);
      await this.$store.dispatch("loadManagementCommitteeAgendaRecommendation", agenda.id);
      // await this.$store.dispatch("loadAllCustomMinuteSections", agenda.itemKey);
      // await this.$store.dispatch("updateSelectedAgendaItem", agenda);
      // await this.$store.dispatch("loadMembersInterests");

      await this.$store.dispatch("changeSelectedManagementCommitteeAgenda", agenda);

      // if (agenda.itemType === "Vote") {
      //   const new_item = this.$store.state.AgendasStore.selecteAgenda;
      //   await this.$store.dispatch("fetchVoteResultsFromServer",new_item.itemVoteDetails.edges[0].node.voteUniqueId );
      // }
      // this.$store.dispatch("showLoadingSpinner", false);
    },
  },
  computed: {
    agendaList() {
      this.moveCount
      return this.$store.getters.getManagementCommitteeAgendas
    }
  }
};
</script>

<style>
.agenda.complete {
  border-left: 4px solid #3cd1c2;
}

.agenda.ongoing {
  border-left: 4px solid #ffaa2c;
}

.agenda.blocked {
  border-left: 4px solid #3e73f8;
}

.agenda.overdue {
  border-left: 4px solid #f83e70;
}

.v-chip.complete {
  background: #3cd1c2;
}

.v-chip.ongoing {
  background: #ffaa2c;
}

.v-chip.overdue {
  background: #f83e70;
}
</style>
