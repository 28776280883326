import { apolloClient } from '@/vue-apollo'
import allMinuteNotes from '../../graphql/management-minutes-graphql/allMinuteNotes.gql'
import saveMinuteNotes from '../../graphql/management-minutes-graphql/saveMinuteNotes.gql'
import saveMinuteDecision from '../../graphql/management-minutes-graphql/saveMinuteDecision.gql'
import allMinuteDecisions from '../../graphql/management-minutes-graphql/allMinuteDecisions.gql'
import allMinuteDirectives from '../../graphql/management-minutes-graphql/allMinuteDirectives.gql'
import saveMinuteDirective from '../../graphql/management-minutes-graphql/saveMinuteDirective.gql'
import managementConfirmMeetingMinutes from "../../graphql/management-minutes-graphql/managementConfirmMeetingMinutes.gql";
import editManagementMinuteDirective from '../../graphql/management-minutes-graphql/editManagementMinuteDirective.gql';
import deleteManagementMinuteDirective from '../../graphql/management-minutes-graphql/deleteManagementMinuteDirective.gql';
import editManagementMinuteDecision from '../../graphql/management-minutes-graphql/editManagementMinuteDecision.gql';
import deleteManagementMinuteDecision from '../../graphql/management-minutes-graphql/deleteManagementMinuteDecision.gql';

import swal from "sweetalert2";
import saveManagementCustomMinutesSections from "@/graphql/management-minutes-graphql/saveManagementCustomMinutesSections.gql";
import allManagementCustomMinuteSections from "@/graphql/management-minutes-graphql/allManagementCustomMinuteSections.gql";


export default {
    state: {
        managementAgendaItemMinuteNote:null,
        managementAgendaItemMinuteDecision:[],
        managementAgendaItemMinuteDirective:[],
        managementAgendaItemCustomMinuteSections:[],
    },

    getters: {
        getManagementCustomMinuteSections(state) {
            return state.managementAgendaItemCustomMinuteSections
        },
    },

    mutations: {
        cleanManagementNotes(state) {
            state.managementAgendaItemMinuteNote = {}
        },
        cleanManagementDecisions(state) {
            state.managementAgendaItemMinuteDecision = []
        },
        cleanManagementDirectives(state) {
            state.managementAgendaItemMinuteDirective = []
        },
        addManagementMinuteNotes(state, note) {
            state.managementAgendaItemMinuteNote = note
        },
        addManagementMinuteDecisions(state, decisions) {
            state.managementAgendaItemMinuteDecision = decisions
        },
        addManagementCustomMinuteSections(state, sections) {
            state.managementAgendaItemCustomMinuteSections = sections
        },
        addManagementMinuteDirectives(state, directive) {
            state.managementAgendaItemMinuteDirective = directive
        },
    },

    actions: {
        async managementConfirmMeetingMinutes(context, itemKey){
            apolloClient.mutate({
                mutation: managementConfirmMeetingMinutes,
                variables: {
                    itemUniqueId: itemKey
                }
            }).then((response)=>{
                if (response.data.managementConfirmMeetingMinutes.success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Meeting minutes has been confirmed successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                    })
                }else{
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'There is problem occur when confirming meeting minutes. Please try again.',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllManagementMinuteNotes(context, itemUniqueId) {
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMinuteNotes,
                variables: {
                    itemUniqueId: itemUniqueId
                }
            }).then((response) => {
                context.commit('cleanManagementNotes')
                context.commit('addManagementMinuteNotes', response.data.allManagementMinuteNotes)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateManagementMinutesNotesOnTyping(context, noteData){
            apolloClient.mutate({
                mutation: saveMinuteNotes,
                variables: {
                    notesContents: noteData.note,
                    notesItemKey: noteData.itemKey
                }
            }).then((response) => {
                context.commit('cleanManagementNotes')
                context.commit('addManagementMinuteNotes', response.data.saveManagementMinuteNotes.itemNotes)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllManagementDecisionMinutes(context, itemUniqueId) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMinuteDecisions,
                variables: {
                    itemUniqueId: itemUniqueId
                }
            }).then((response) => {
                context.commit('cleanManagementDecisions')
                context.commit('addManagementMinuteDecisions', response.data.allManagementMinuteDecisions)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async saveManagementDecisionsMinutes(context, decisionData){
            await apolloClient.mutate({
                mutation: saveMinuteDecision,
                variables: {
                    decisionContents:decisionData.decisionContents,
                    decisionDate:decisionData.decisionDate,
                    decisionOutcome:decisionData.decisionOutcome,
                    decisionTitle:decisionData.decisionTitle,
                    decisionItemKey:decisionData.decisionItemKey
                }
            }).then((response)=>{
                context.commit('addManagementMinuteDecisions',response.data.saveManagementMinuteDecision.decision)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async editManagementDecisionsMinutes(context, decisionData){
            await apolloClient.mutate({
                mutation: editManagementMinuteDecision,
                variables: {
                    decisionContents:decisionData.decisionContents,
                    decisionDate:decisionData.decisionDate,
                    decisionOutcome:decisionData.decisionOutcome,
                    decisionTitle:decisionData.decisionTitle,
                    decisionItemKey:decisionData.decisionItemKey,
                    decisionUniqueId:decisionData.decisionUniqueId
                }
            }).then(()=>{
                // context.commit('addManagementMinuteDecisions',response.data.editManagementMinuteDecision.decision)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteManagementDecisionsMinutes(context, decisionData){
            await apolloClient.mutate({
                mutation: deleteManagementMinuteDecision,
                variables: {
                    decisionUniqueId:decisionData.decisionUniqueId
                }
            }).then(()=>{
                // context.commit('addManagementMinuteDecisions',response.data.editManagementMinuteDecision.decision)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllManagementDirectiveMinutes(context, itemUniqueId) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMinuteDirectives,
                variables: {
                    itemUniqueId: itemUniqueId
                }
            }).then((response) => {
                context.commit('cleanManagementDirectives')
                context.commit('addManagementMinuteDirectives', response.data.allManagementMinuteDirectives)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


        async loadAllManagementCustomMinuteSections(context, itemUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allManagementCustomMinuteSections,
                variables: {
                    itemUniqueId: itemUniqueId
                }
            }).then((response) => {
                const sections = response.data.allManagementCustomMinuteSections
                context.commit('addManagementCustomMinuteSections', sections)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


        async updateManagementCustomMinutesSectionsOnTyping(context, customData) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: saveManagementCustomMinutesSections,
                variables: {
                    sectionAgendaItem: customData.sectionAgendaItem,
                    sectionUniqueId: customData.sectionUniqueId,
                    sectionValues: customData.sectionValues,
                }
            }).then(async () => {
                await context.dispatch("loadAllManagementCustomMinuteSections", customData.sectionAgendaItem)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })
        },

        async saveManagementDirectivesMinutes(context, directiveData){
            await apolloClient.mutate({
                mutation: saveMinuteDirective,
                variables: {
                    directiveAssingee:directiveData.directiveAssingee,
                    directiveContents:directiveData.decisionContents,
                    directiveDueDate:directiveData.directiveDueDate,
                    directiveItemKey:directiveData.decisionItemKey,
                    directiveTitle:directiveData.directiveTitle,
                }
            }).then(()=>{
                // context.commit('addManagementMinuteDirectives',response.data.saveManagementMinuteDirective.directive)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async editManagementDirectivesMinutes(context, directiveData){
            await apolloClient.mutate({
                mutation: editManagementMinuteDirective,
                variables: {
                    directiveAssingee:directiveData.directiveAssingee,
                    directiveContents:directiveData.directiveContents,
                    directiveDueDate:directiveData.directiveDueDate,
                    directiveItemKey:directiveData.directiveItemKey,
                    directiveTitle:directiveData.directiveTitle,
                    directiveUniqueId:directiveData.directiveUniqueId
                }
            }).then(()=>{
                // context.commit('loadAllManagementDirectiveMinutes', directiveData.decisionItemKey)
                // context.commit('addManagementMinuteDirectives',response.data.editManagementMinuteDirective.directive)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteManagementDirectivesMinutes(context, directiveData){
            await apolloClient.mutate({
                mutation: deleteManagementMinuteDirective,
                variables: {
                    directiveItemKey:directiveData.directiveItemKey,
                    directiveUniqueId:directiveData.directiveUniqueId
                }
            }).then(()=>{
                // context.commit('loadAllManagementDirectiveMinutes', directiveData.decisionItemKey)
                // context.commit('addManagementMinuteDirectives',response.data.deleteManagementMinuteDirective.directive)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        }
    }
}

