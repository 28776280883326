<template>
  <v-dialog persistent v-model="OpenNoticeDialog"  max-width="60%" >
    <v-card>
      <v-card-title style="font-size:20px">
        <span><v-icon color="indigo">mdi-calendar-check</v-icon> Action Notice</span>
      </v-card-title>
      <v-card-subtitle>
        <p><strong>{{meetingData}}</strong></p>
        <span>Send an email notice of this action item to the following item owner:</span>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text >
        <template>
          <v-container>
            <v-row>
              <v-col sm="12">
                <v-text-field
                    value="John Doe"
                    label="Send to:"
                    disabled
                    prepend-icon="mdi-account-tie-outline"
                ></v-text-field>
              </v-col>

              <v-col
                  cols="4"
                  sm="4"
                  md="4"
              >
                <v-text-field
                    v-model="Greetings"
                    label="Greetings"
                    prepend-icon="mdi-human-greeting"
                >
                </v-text-field>
              </v-col>
              <v-col
                  cols="8"
                  sm="8"
              >
                <v-select
                    v-model="value"
                    :items="['Title', 'First Name', 'Last Name', 'Suffix', 'Reset']"
                    attach
                    chips
                    label="Name"
                    multiple
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>

            <Editor
                :content="content"
                :editorOption="editorOption"
                style="height: 180px"
            />
          </v-container>




        </template>
      </v-card-text>
      <v-card-text><v-icon color="pink">mdi-alert</v-icon>  e-Board automatically sends 2 reminders of this action to the owner: 7 days before the due date and then on the due date.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseNoticeDialog')"
        >
          <v-icon small>mdi-close</v-icon> Close
        </v-btn>
        <v-btn
            color="primary"
            outlined
            small
            @click="dialog = false"
        >
          <v-icon small>mdi-floppy</v-icon> Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import Editor from '../management_shared/fields/Editor.vue'
export default {
  props:['OpenNoticeDialog','meetingData'],
  components: {
    Editor
  },
  data (){
    return{
      value:["First Name"],
      Greetings:'Hi',
      editorOption:{
        placeholder: 'You can add an optional comment which will appear after the greeting',
        theme: 'snow'
      } ,
      checkbox:true,
      members: [
        {
          id: 1,
          name: 'Board Members(0/2)',
          children: [
            { id: 2, name: 'Mrisha Ally' },
            { id: 3, name: 'Prisca Bahi' },
          ],
        },
      ],
      nonmembers: [
        {
          id: 1,
          name: 'Non-Board Members (0/3)',
          children: [
            { id: 2, name: 'Jackson bakari' },
            { id: 3, name: 'Eliud Musa' },
            { id: 4, name: 'Eliya Tuma' },
          ],
        },
      ]
    }
  }
}
</script>

<style>

</style>