<template>
  <v-row justify="center">
    <v-dialog v-model="showContextMenu" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-calender </v-icon> Add New Event
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="eventTitle"
                  :rules="nameRules"
                  :counter="100"
                  label="Event Name *"
                  required
                  prepend-icon="mdi-calendar-star"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="eventDescriptions"
                  :rules="nameRules"
                  :counter="100"
                  label="Event Descriptions *"
                  required
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                  ref="startDateMenu"
                  v-model="startDateMenu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eventStartDate"
                      label="Start Date *"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="eventStartDate"
                    no-title
                    scrollable
                    :min="date"
                  >
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="startDateMenu = false">
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6">
                <v-dialog ref="dialog1" v-model="startTimeModal" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eventStartTime"
                      label="Start Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="startTimeModal"
                    v-model="eventStartTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startTimeModal = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-menu
                  ref="endDateMenu"
                  v-model="endDateMenu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eventEndDate"
                      label="End Date *"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="eventEndDate"
                    no-title
                    scrollable
                    :min="eventStartDate"
                  >
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="endDateMenu = false">
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="6">
                <v-dialog ref="dialog" v-model="endTimeModal" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eventEndTime"
                      label="End Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="endTimeModal"
                    v-model="eventEndTime"
                    full-width
                    :min="endTime"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endTimeModal = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-combobox
                  dense
                  v-model="eventColor"
                  :filter="filter"
                  :hide-no-data="!search"
                  :items="items"
                  :search-input.sync="search"
                  hide-selected
                  re
                  label="Select event color"
                  small-chips
                  outlined
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <span class="subheading">Create</span>
                      <v-chip
                        :color="`${colors[nonce - 1]} lighten-3`"
                        label
                        small
                      >
                        {{ search }}
                      </v-chip>
                    </v-list-item>
                  </template>
                  <template
                    v-slot:selection="{ attrs, item, parent, selected }"
                  >
                    <v-chip
                      v-if="item === Object(item)"
                      v-bind="attrs"
                      :color="`${item.color} lighten-3`"
                      :input-value="selected"
                      label
                      small
                    >
                      <span class="pr-2">
                        {{ item.text }}
                      </span>
                      <v-icon small @click="parent.selectItem(item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ index, item }">
                    <v-text-field
                      v-if="editing === item"
                      v-model="eventColor"
                      autofocus
                      text
                      dense
                      clearable
                      background-color="transparent"
                      outlined
                      @keyup.enter="edit(index, item)"
                    ></v-text-field>
                    <v-chip
                      v-else
                      :color="`${item.color} lighten-3`"
                      dark
                      label
                      small
                    >
                      {{ item.text }}
                    </v-chip>
                    <v-spacer></v-spacer>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6">
                <v-switch v-model="sendReminder" inset color="primary">
                  <template v-slot:label> Send Reminder </template>
                </v-switch>
              </v-col>
              <v-col v-if="sendReminder" cols="12" sm="12">
                <v-text-field
                  dense
                  v-model="reminderMessage"
                  label="Reminder Message"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            @click="$emit('closeNewEventDialog')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-btn
            color="blue darken-1"
            fab
            small
            class="white--text"
            @click="createNewBoardEvent"
            :disabled="loading"
            :loading="loading"
          >
            <v-icon>mdi-send-check-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["showContextMenu"],
  data: () => ({
    eventTitle: "",
    eventDescriptions: "",
    eventStartDate: "",
    eventEndDate: "",
    eventStartTime: "",
    eventEndTime: "",
    eventColor: "",
    sendReminder: false,
    reminderMessage: "",

    startTimeModal: false,
    endTimeModal: false,
    loading: false,
    startDateMenu: false,
    endDateMenu: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => v.length <= 100 || "Must be less than 100 characters",
    ],

    activator: null,
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],

    editing: null,
    editingIndex: -1,
    items: [
      { header: "Select event color" },
      {
        text: "Blue",
        color: "blue",
      },
      {
        text: "Red",
        color: "red",
      },

      {
        text: "Purple",
        color: "purple",
      },

      {
        text: "Indigo",
        color: "indigo",
      },

      {
        text: "Orange",
        color: "orange",
      },
    ],
  }),

  methods: {
    async createNewBoardEvent() {
      this.$store.dispatch("showLoadingSpinner", true);
      var eventObject = {
        eventName: this.eventTitle,
        eventDescription: this.eventDescriptions,
        eventStartDate: this.eventStartDate,
        eventEndDate: this.eventEndDate,
        eventStartTime: this.eventStartTime,
        eventEndTime: this.eventEndTime,
        eventColor: this.eventColor.color,
        boardUniqueId: localStorage.getItem("my-boardUniqueId"),
        sendReminder: this.sendReminder,
        reminderMessage: this.reminderMessage,
      };
      await this.$store.dispatch("createBoardEvent", eventObject);
      await this.$store.dispatch("loadBoardEventList");
      this.eventTitle = "";
      this.eventDescriptions = "";
      this.eventStartDate = "";
      this.eventEndDate = "";
      this.eventStartTime = "";
      this.eventEndTime = "";
      this.eventColor = "";
      this.sendReminder = false;
      this.reminderMessage = "";

      this.$emit("closeNewEventDialog");
      this.$store.dispatch("showLoadingSpinner", false);
    },

    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
  computed:{
    endTime(){
      if (this.eventStartDate===this.eventEndDate) {
        return this.eventStartTime
      } else {
        return ""
      }
    }
  }
};
</script>

<style>
</style>
