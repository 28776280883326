<template>
  <v-container fluid class="my-5" app>
    <v-dialog v-model="editDraftNewsDialog" max-width="60%">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          <v-icon class="mr-2">mdi-newspaper</v-icon>
          Edit News/Update
        </v-card-title>
        <v-form class="px-3">
          <v-card-text>
            <v-text-field
                outlined
                prepend-icon="mdi-text-short"
                v-model="newsTitle"
                autofocus
                label="News Title*"
            ></v-text-field>

            <v-textarea
                outlined
                prepend-icon="mdi-text-long"
                v-model="newsDescriptions"
                label="News Description*"
                required
            ></v-textarea>

            <v-row>
<!--              <v-col sm="12">-->
<!--                <UpdateSupportingDocuments/>-->
<!--              </v-col>-->
              <v-col cols="12" sm="6" md="6">
                <v-menu
                    ref="newsExpireDate"

                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="newsExpireDate"
                        label="News will Expire on: *"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="newsExpireDate"
                      no-title
                      scrollable
                      :min="date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDate = true">
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-dialog ref="dialog1" v-model="modal1" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="newsExpireTime"
                        label="News Expirey Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="modal1" v-model="newsExpireTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <v-switch
                v-model="newsShowDelivery"
                inset
                color="#E65100"
                class="pl-5"
                :label="`Show Delivery Status`"
            ></v-switch>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              small
              fab
              color="pink"
              @click="closeDialog"
          >
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
          <v-btn small fab color="primary" @click="editDraftNews">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  name: "EditNews",

  data: () => ({
    dialog: false,
    dialog1: false,
    modal1: false,
    docValue: "",

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

    startDate: "",
    expireTime: "",

  }),

  props: [
    "newsTitle",
    "newsUniqueId",
    "newsDescriptions",
    "newsExpireDate",
    "newsExpireTime",
    "newsDocuments",
    "newsShowDelivery",
    "newsExpire",
    "expireTime",

    "editDraftNewsDialog",
  ],

  methods: {
    async closeDialog() {
      await this.$emit('close-dialog')
    },

    async editDraftNews(){
      let input = {
        newsUniqueId: this.newsUniqueId,
        newsTitle: this.newsTitle,
        newsDescriptions: this.newsDescriptions,
        newsExpireDate: this.newsExpire,
        newsExpireTime: this.expireTime,
        newsShowDelivery: this.newsShowDelivery,
        newsDocuments: []
      }

      await this.$store.dispatch('editDraftNewsAndUpdates', input)
      await this.$store.dispatch("loadBoardDraftNewsUpdates");
      await this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>

</style>