<template>
  <v-container fluid>
    <v-row class="pt-3">
      <v-col class="d-flex align-content-center" cols="12" sm="12">
        <v-btn
          :to="'/management/management_directives_categories'"
          class="mr-3"
          color="primary white--text"
          link
          outlined
          right
          rounded
          small
        >
          <v-icon>mdi-arrow-left</v-icon>
          Back
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p>List of Directives agreed during the Specified Meetings</p>
      </v-col>

      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          v-model="directiveObj.designationUniqueId"
          :items="designationsList()"
          dense
          item-text="designationShortForm"
          item-value="designationUniqueId"
          label="Designation *"
          @change="directiveObj.meetingUniqueId=null; loadManagementDirectives();"
        ></v-select>
      </v-col>

      <v-col class="d-flex" cols="12" sm="3">
        <v-autocomplete
          v-model="directiveObj.meetingUniqueId"
          :items="managementMeetingsList"
          dense
          item-text="name"
          item-value="id"
          label="Filter by Meeting *"
          @change="directiveObj.designationUniqueId=null; loadManagementDirectives();"
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined rounded small @click="openDownloadDirectivesModal">
        <v-icon> mdi-tray-arrow-down</v-icon>
        Download Directives
      </v-btn>
    </v-row>

    <v-row>
      <v-col sm="12">
        <v-card elevation="6">
          <v-tabs v-model="tab" color="#3f51b5" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon :color="tab.color" class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <ActionCard
                v-for="directive in managementDirectives.data"
                :key="directive.directiveUniqueId"
                :directive="directive"
              />

              <v-card-actions>
                <v-btn
                  @click="paginateManagementDirectives(managementDirectives.page.previousPageNumber)"
                  v-if="managementDirectives.page.hasPreviousPage" outlined small
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  Previous Page ({{ managementDirectives.page.previousPageNumber }})
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  @click="paginateManagementDirectives(managementDirectives.page.nextPageNumber)"
                  v-if="managementDirectives.page.hasNextPage" outlined small
                >
                  Next Page ({{ managementDirectives.page.nextPageNumber }})
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-tab-item>

            <v-tab-item>
              <ActionCard
                class="intro-y mt-2"
                v-for="directive in completedManagementDirectives.data"
                :key="directive.directiveUniqueId"
                :directive="directive"
              />

              <v-card-actions>
                <v-btn
                  @click="paginateManagementCompletedDirectives(completedManagementDirectives.page.previousPageNumber)"
                  v-if="completedManagementDirectives.page.hasPreviousPage" outlined small
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  Previous Page ({{ completedManagementDirectives.page.previousPageNumber }})
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  @click="paginateManagementCompletedDirectives(completedManagementDirectives.page.nextPageNumber)"
                  v-if="completedManagementDirectives.page.hasNextPage" outlined small
                >
                  Next Page ({{ completedManagementDirectives.page.nextPageNumber }})
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <DownloadManagementDirectiveDocumentModal
      :filterObj="filterObj"
      :showContextMenu="showContextMenu"
      @closeDownloadDirectiveModal="showContextMenu=false"
    />
  </v-container>
</template>

<script>
import ActionCard from "./ManagementActionsCard.vue";
import DownloadManagementDirectiveDocumentModal from "./DownloadManagementDirectiveDocumentModal.vue";

export default {
  components: {ActionCard, DownloadManagementDirectiveDocumentModal},

  data() {
    return {
      tab: true,
      tabs: [
        {title: "Current Directives", icon: "mdi-pin-outline", color: "orange"},
        {
          title: "Completed Directives",
          icon: "mdi-check-decagram-outline",
          color: "success",
        },
      ],
      filterMeeting: null,
      filterOwner: null,
      showContextMenu: false,
      filterBy: '',
      filterObj: {},
      directiveObj: {
        managementUniqueId: localStorage.getItem("my-managementUniqueId"),
        designationUniqueId: null,
        meetingUniqueId: null,
        pageNumber: 1,
      }
    };
  },

  computed: {
    managementDirectives() {
      return this.$store.getters.getManagementAssignedDirectivesList;
    },

    completedManagementDirectives() {
      return this.$store.getters.getManagementCompletedDirectivesList;
    },

    managementMeetingsList() {
      const temp_list = [];
      const all_meetings = this.$store.state.ManagementMeetingsStore.managementMeetingsList;

      all_meetings.forEach((meeting) => {
        temp_list.push({
          name: meeting.meetingTitle + ' - ' + new Date(meeting.meetingDate).toLocaleDateString(),
          id: meeting.primaryKey,
        });
      });

      temp_list.push({name: "All Meetings", id: null});
      return temp_list;
    },
  },

  methods: {
    openDownloadDirectivesModal() {
      this.filterObj.managementUniqueId = localStorage.getItem("my-managementUniqueId");
      this.filterObj.weeklyReportDirective = false;
      this.filterObj.showAssignee = false;
      this.filterObj.designationUniqueId = this.directiveObj.designationUniqueId;
      this.filterObj.meetingUniqueId = this.directiveObj.meetingUniqueId;
      this.showContextMenu = true;
    },

    async loadManagementDirectives() {
      await this.$store.dispatch("loadManagementAssignedDirectives", this.directiveObj);
      await this.$store.dispatch("loadManagementCompletedDirectives", this.directiveObj);
    },

    async paginateManagementDirectives(pageNumber) {
      this.directiveObj.pageNumber = pageNumber;
      await this.$store.dispatch("loadManagementAssignedDirectives", this.directiveObj);
    },

    async paginateManagementCompletedDirectives(pageNumber) {
      this.directiveObj.pageNumber = pageNumber;
      await this.$store.dispatch("loadManagementCompletedDirectives", this.directiveObj);
    }
  },

  async mounted() {
    await this.loadManagementDirectives();
    await this.$store.dispatch('loadManagementMeetingsList', {
      managementUniqueId: localStorage.getItem("my-managementUniqueId")
    });
  },
};
</script>

<style>
</style>
