<template>
  <div class="text-center">
    <v-dialog v-model="OpenTableEditor" persistent width="80%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon left>mdi-table-large-plus</v-icon> {{ ActionTitle }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container fluid>
            <ReportTableEditor
              :tableBodyData="tableBodyDataTemp"
              :editorOption="editorOption"
              @tableContentChanges="onEditorChange"
              style="height: 580px"
            />
          </v-container>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="pink"
                text
                outlined
                small
                @click="$emit('CloseTableEditor')"
            >
              <v-icon small>mdi-close</v-icon> Close
            </v-btn>
            <v-btn
                color="success"
                outlined
                small
                @click="updateTable"
                v-if="editAction"
                :loading="submiting"
            >
              <v-icon small>mdi-floppy</v-icon> Save Changes
            </v-btn>
            <v-btn
                color="success"
                outlined
                small
                @click="createTable"
                v-else
                :loading="submiting"
            >
              <v-icon small>mdi-floppy</v-icon> Save
            </v-btn>
          </v-card-actions>
        </v-card-text>

        <v-divider></v-divider>


      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReportTableEditor from "./ReportTableEditor.vue";
export default {
  props: [
    "OpenTableEditor",
    "tableHeaderData",
    "ActionTitle",
    "componentTemplateId",
    "componentObj",
    "tableBodyData",
    "editAction",
  ],
  components: {
    ReportTableEditor,
  },
  data: () => ({
    content: `<p><strong>2.0 CHALLENGE AND REMEDIAL ACTION</strong></p><figure class="table"><table><thead><tr><th rowspan="2"><strong>S/No.</strong></th><th rowspan="2"><strong>Challenges Encountered</strong></th><th colspan="2"><strong>Quarterly Actual Expenditure&nbsp;</strong></th><th colspan="2" rowspan="2"><strong>Remedial Action&nbsp;</strong></th></tr><tr><th><strong>Quarter</strong></th><th><strong>Cumulative</strong></th></tr></thead><tbody><tr><td>1.0</td><td>Uncertainty of sustainable funding from central Government and difficulties in collecting debts from customers who are Public Institutions depending on Government subvention.</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">Strengthening efforts of finding other sources of &nbsp; revenue and strengthening mechanisms of collecting debts from customers especially by using technology.</td></tr><tr><td colspan="6"><strong>RECURRENT EXPENDITURE</strong></td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">&nbsp;</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">&nbsp;</td></tr><tr><td colspan="5"><strong>No. of Employees as at the end of the Quarter</strong></td><td>191</td></tr></tbody></table></figure><p><strong>i. ANNEXURES</strong></p>`,
    tableTitle: "",
    dateFormatted: "",
    menu1: false,
    editorOption: {
      theme: "snow",
      toolbar: "minimal",
    },
    deleteSubmiting: false,
    submiting: false,
  }),
  computed: {
    tableBodyDataTemp() {
      return this.editAction ? this.componentObj.componentBody : ""
    }
  },
  watch: {
    tableBodyData(new_value) {
      this.tableBodyDataTemp = new_value;
    },
  },

  methods: {
    onEditorChange(event) {
      this.content = event;
    },

    createTable() {
      this.$store.dispatch('createReportTemplateComponent', {
        componentTitle: "",
        componentBody: this.content,
        componentTemplateId: this.componentTemplateId,
        componentIsTable: true,
      })
      this.sectionHeaderData = ""
      this.content = ""
      this.$emit("CloseTableEditor");
    },

    updateTable() {
      this.submiting = true;
      this.$store.dispatch('editReportTemplateComponent', {
        componentId: this.componentObj.primaryKey.toString(),
        componentTitle: '',
        componentBody: this.content
      })
      this.sectionHeaderData = ""
      this.content = ""
      this.$emit("CloseTableEditor");
    },

    deleteSection() {
      this.deleteSubmiting = true;
    },
  },
};
</script>
