<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="direDeadline in []"
      :key="direDeadline"
    >
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="4">
            {{ direDeadline.title }}
          </v-col>
          <v-col cols="8" class="text--secondary">
            <v-fade-transition leave-absolute>
              <v-card flat v-if="open">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">15-May-2022</div>
                    <v-list-item-title class="text-h6 mb-1">
                      Week Start from 09-May-2022 to 15-May-2022
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Select your favorite social network and share our icons
                      with your contacts or friends, if you do not have these
                      social networks copy the link and paste it in the one you
                      use. For more information read the or download the
                      license. Select your favorite social network and share our
                      icons with your contacts or friends, if you do not have
                      these social networks copy the link and paste it in the
                      one you use. For more information read the or download the
                      license. Select your favorite social network and share our
                      icons with your contacts or friends, if you do not have
                      these social networks copy the link and paste it in the
                      one you use. For more information read the or download the
                      license. Select your favorite social network and share our
                      icons with your contacts or friends, if you do not have
                      these social networks copy the link and paste it in the
                      one you use. For more information read the or download the
                      license. Select your favorite social network and share our
                      icons with your contacts or friends, if you do not have
                      these social networks copy the link and paste it in the
                      one you use. For more information read the or download the
                      license.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <v-row v-else no-gutters style="width: 100%">
                <v-spacer></v-spacer>
                <v-col cols="6">
                  Date and time:
                  <strong>{{ direDeadline.dueDate | formatFull }}</strong>
                </v-col>
              </v-row>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row justify="space-around" no-gutters>
          <v-col cols="3">
            <v-menu offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  small
                  style="font-size: 12px"
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  color="indigo"
                >
                  <v-icon small left>mdi-comment-outline</v-icon>
                  Attend  Report
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  link
                  v-for="(status, index) in action_status_list"
                  :key="index"
                >
                  <v-list-item-title @click="addAtendees(status, index)"
                    ><v-icon :color="status.iconColor" small left>{{
                      status.icon
                    }}</v-icon>
                    {{ status.status }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: ["upcomingDeadlines"],
  data: () => ({
    openvalue: false,
    switch1: "",
    readColor: "success",
    date: null,
    trip: {
      name: "",
      location: null,
      start: null,
      end: null,
    },
    action_status_list: [
      { iconColor: "indigo", icon: "mdi-comment-eye", status: "View Full Report" },
      { iconColor: "primary", icon: "mdi-comment-text", status: "Comment on Report" },
    ],
  }),
};
</script>

<style></style>
