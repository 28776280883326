<template>
  <v-row justify="center">
    <v-dialog
      v-model="showContextMenu"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-account-group-outline</v-icon> Edit Meeting Details</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
                sm="12"
              >
                <v-text-field
                  v-model="meetingObj.meetingTitle"
                  :counter="100"
                  label="Title *"
                  prepend-icon="mdi-text-recognition"
                  required
                >
                </v-text-field>
                <v-select
                  v-model="meetingObj.meetingCategory"
                  :items="meetingCategories"
                  item-text='categoryTittle'
                  item-value='id'
                  label="Meeting Category *"
                  prepend-icon="mdi-shape-outline"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="pink"
            small
            outlined
            @click="closeNewMeetingDialog"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>

          <v-btn
            :disabled="loading"
            :loading="loading"
            class="white--text"
            color="blue darken-1"
            outlined
            small
            @click="updateManagementMeeting"
          >
            <v-icon>mdi-send-check-outline</v-icon>
            Save
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>

export default {
  data: () => ({
    loading: false,
    timezone: null,
    defaultSelected: '',
    time: null,
    menu: false,
    modal2: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    dialog1: false,
    modal1: false,
    meetingObj: {
      meetingManagement: localStorage.getItem('my-managementUniqueId'),
      meetingUniqueId: null,
      meetingTitle: null,
      meetingDate: null,
      meetingStartTime: null,
      meetingEndTime: null,
      meetingLocation: null,
      meetingCategory: null,
    }
  }),

  props: ["showContextMenu"],

  watch: {
    showContextMenu(value) {
      if (value) {
        this.loadMeetingCategories();
        this.meetingObj.meetingUniqueId = this.selectedMeetingDetails.meetingUniqueId;
        this.meetingObj.meetingTitle = this.selectedMeetingDetails.meetingTitle;
        this.meetingObj.meetingDate = this.selectedMeetingDetails.meetingDate;
        this.meetingObj.meetingStartTime = this.selectedMeetingDetails.meetingStartTime;
        this.meetingObj.meetingEndTime = this.selectedMeetingDetails.meetingEndTime;
        this.meetingObj.meetingLocation = this.selectedMeetingDetails.meetingLocation;
        this.meetingObj.meetingCategory = this.selectedMeetingDetails.meetingCategory;
      }
    }
  },

  computed: {
    meetingCategories() {
      let temp = [];
      let data = [];
      temp = this.$store.state.ManagementCategoriesStore.managementMeetingCategories;

      temp.forEach(item => {
        data.push({
          id: item.key,
          categoryTittle: item.title,
        })
      });

      return data;
    },

    selectedMeetingDetails() {
      return this.$store.getters.getManagementMeetingDetails;
    }
  },

  methods: {
    closeNewMeetingDialog() {
      this.meetingObj = {
        meetingManagement: null,
        meetingTitle: null,
        meetingDate: null,
        meetingStartTime: null,
        meetingEndTime: null,
        meetingLocation: null,
        meetingCategory: null,
      };
      this.timezone = null;
      this.$emit("closeNewMeetingDialog");
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    getSelectedTimeZone() {
      this.timezone = this.meetingObj.meetingLocation.locationTimezone;
    },

    async updateManagementMeeting() {
      this.meetingObj.meetingLocation = this.meetingObj.meetingLocation.id;
      this.meetingObj.meetingCategory = this.meetingObj.meetingCategory?.id || this.meetingObj.meetingCategory;
      await this.$store.dispatch('updateManagementMeeting', this.meetingObj);
      this.closeNewMeetingDialog();
    },

    async loadMeetingCategories() {
      await this.$store.dispatch("loadManagementMeetingCategories");
    }
  },
}
</script>
