import {apolloClient} from '@/vue-apollo'
import getBoardStructure from '../../graphql/eboard-board-structure-graphql/getBoardStructure.gql'
import getManagementStructure from '../../graphql/managment-member-structure-graphql/getManagementStructure.gql'


export default {
    state: {
        myboardUniqueId: localStorage.getItem("my-boardUniqueId") || '',
        currentBoard: 'My Board',
        boardStructure: null,
        managementStructure: null,
    },

    mutations: {
        updateMyboardName(state, boardName) {
            state.currentBoard = boardName
        },
        updateMyboardUniqueId(state, boardUniqueId) {
            state.myboardUniqueId = boardUniqueId
        },
        cleanboardStructure(state) {
            state.boardStructure = null
        },

        cleanmanagementStructure(state) {
            state.managementStructure = null
        },

        addBoardstructure(state, structureObj) {
            state.boardStructure = structureObj
        },

        addManagementstructure(state, structureObj2) {
            state.managementStructure = structureObj2
        }
    },

    actions: {
        async updateCurrentStateForMyBoard(context, boardUniqueId) {
            context.commit('updateMyboardUniqueId', boardUniqueId)
        },
        async updateCurrentMyboardName(context, new_name) {
            context.commit('updateMyboardName', new_name)
        },
        async loadBoardStructure(context) {
            context.commit('cleanboardStructure')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                query: getBoardStructure,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")

                }
            }).then((response) => {
                const structures = response.data.getBoardStructure
                context.commit('addBoardstructure', structures)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadManagementStructure(context) {
            context.commit('cleanmanagementStructure')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                query: getManagementStructure,
                variables: {
                    managementUniqueId: localStorage.getItem("my-managementUniqueId")

                }
            }).then((response) => {
                const structures2 = response.data.getManagementStructure
                context.commit('addManagementstructure', structures2)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        }
    }
}


