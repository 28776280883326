<template>
<div >
  <ToolBar/>
  <div style="padding:20px">
    <router-view/>
  </div>
  <!-- <PSPDFKit documentUrl="document.pdf"/> -->
</div>
</template>

<script>
  import ToolBar from './settings-navigations/Toolbar.vue'

  export default {
    name: 'SettingsHome',

    components: {
      ToolBar,
    },
  }
</script>


