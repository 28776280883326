var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{key:_vm.newCommitteeMeetingKey,attrs:{"max-width":"50%","persistent":""},model:{value:(_vm.newManagementCommitteeMeeting),callback:function ($$v) {_vm.newManagementCommitteeMeeting=$$v},expression:"newManagementCommitteeMeeting"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6 flex items-center"},[_c('icon',{staticClass:"mr-2",attrs:{"name":"meeting_add","width":"w-5","height":"h-5"}}),_vm._v(" New Committee Meeting")],1)]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"counter":100,"rules":_vm.nameRules,"label":"Title *","prepend-icon":"mdi-text-recognition","required":""},model:{value:(_vm.meetingTitle),callback:function ($$v) {_vm.meetingTitle=$$v},expression:"meetingTitle"}}),_c('v-select',{attrs:{"items":_vm.meetingCategories,"item-text":"title","item-value":"uniqueId","label":"Meeting Category *","prepend-icon":"mdi-shape-outline","required":""},model:{value:(_vm.meetingCategory),callback:function ($$v) {_vm.meetingCategory=$$v},expression:"meetingCategory"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date *","persistent-hint":"","prepend-icon":"mdi-calendar"},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.meetingDate)}},model:{value:(_vm.meetingDate),callback:function ($$v) {_vm.meetingDate=$$v},expression:"meetingDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":_vm.date,"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.meetingDate),callback:function ($$v) {_vm.meetingDate=$$v},expression:"meetingDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-dialog',{ref:"dialog1",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Time *","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[(_vm.modal1)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Time *","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c('v-time-picker',{attrs:{"min":_vm.startTime,"full-width":""},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"hint":_vm.timezone,"items":_vm.locations,"item-text":"location","item-id":"locationUniqueId","label":"Location *","persistent-hint":"","prepend-icon":"mdi-map-marker","required":"","return-object":""},on:{"change":_vm.getSelectedTimeZone},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","text-color":""}},[_vm._v(_vm._s(item.location))])]}}]),model:{value:(_vm.defaultSelected),callback:function ($$v) {_vm.defaultSelected=$$v},expression:"defaultSelected"}})],1)],1),_c('div',{staticClass:"flex justify-end text-white"},[_c('v-btn',{staticClass:"text-capitalize mr-2",attrs:{"color":"red","right":"","small":""},on:{"click":_vm.closeDialog}},[_c('icon',{attrs:{"name":"close","width":"w-5","height":"h-5","color":"#ffffff"}}),_c('span',{staticClass:"text-white"},[_vm._v("Cancel")])],1),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","right":"","small":""},on:{"click":_vm.saveManagementCommitteeMeeting}},[_c('icon',{attrs:{"name":"save","width":"w-5","height":"h-5","color":"#ffffff"}}),_vm._v(" Add Meeting ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }