<template>
  <v-card
    id="agendaPanels"
    class="scroll"
    height="600px"
    style="width: 97%; padding-top: 10px"
  >
    <!--    <center style="font-size: 24px; padding-left: 10px; font-weight: bold">-->
    <!--      Agenda of the Meeting-->
    <!--    </center>-->
    <!--    <v-divider></v-divider>-->
    <v-card-text>
      <v-card
        v-for="agenda in listOfagendas"
        :key="agenda.AgendaUniqueId"
        elevation="0"
        style="margin: 5px"
      >
        <v-card-subtitle>
          <v-layout class="rounded-md" style="background-color: #edf4ff; padding: 12px">
            <v-flex contenteditable="true" max-width="100%" md11 xs10>
              <strong
                v-if="!hasAccess('can_schedule_meeting')"
                style="
                  font-size: 16px;
                  font-weight: bold;
                  padding-left: 5px;
                  padding-top: 5px;
                "
              >{{ agenda.Agenda }}</strong>

              <span
                v-else-if="hasAccess('can_schedule_meeting') && isEditAgendaKey !== agenda.agendaKey"
                @blur="
                  (event) => onPurposeInput(event, agenda.agendaKey, 'Title')
                "
                style="
                  font-size: 22px;
                  font-weight: bold;
                  padding-left: 5px;
                  padding-top: 5px;
                "
              >
                <strong>{{ agenda.Agenda }}</strong>
              </span>

              <span
                v-else-if="hasAccess('can_schedule_meeting') && isEditAgendaKey === agenda.agendaKey"
                style="
                  font-size: 16px;
                  font-weight: bold;
                  padding-left: 5px;
                  padding-top: 5px;
                "
              >
                <input
                  @change="
                    (event) => onPurposeInput(event, agenda.agendaKey, 'Title')
                  "
                  :placeholder="agenda.Agenda"
                  v-model="agendaName"
                  :key="agenda.agendaKey"
                  :autofocus="autofocus"
                />
              </span>
            </v-flex>
            <v-flex max-width="100%" md1 xs2>
              <!--              <v-menu app offset-y>-->
              <!--                <template v-slot:activator="{ on, attrs }">-->
              <!--                  <v-btn-->
              <!--                      slot="activator"-->
              <!--                      color="indigo"-->
              <!--                      icon-->
              <!--                      v-bind="attrs"-->
              <!--                      v-on="on"-->
              <!--                  >-->
              <!--                    <v-icon>mdi-dots-vertical</v-icon>-->
              <!--                  </v-btn>-->
              <!--                </template>-->
              <!--                <v-list dense>-->
              <!--                  <v-list-item v-if="!meetingHasPublishedAgenda" link>-->
              <!--                    <v-list-item-title-->
              <!--                    >-->
              <!--                      <v-icon style="height: 16px; font-size: 16px"-->
              <!--                      >mdi-square-edit-outline-->
              <!--                      </v-icon-->
              <!--                      >-->
              <!--                      Edit Agenda-->
              <!--                    </v-list-item-title-->
              <!--                    >-->
              <!--                  </v-list-item-->
              <!--                  >-->
              <!--                  <v-list-item v-if="!meetingHasPublishedAgenda" link @click="moveAgenda(agenda.primaryKey, 'UP')">-->
              <!--                    <v-list-item-title-->
              <!--                    >-->
              <!--                      <v-icon style="height: 16px; font-size: 16px"-->
              <!--                      >mdi-arrow-up-->
              <!--                      </v-icon-->
              <!--                      >-->
              <!--                      Move it Up-->
              <!--                    </v-list-item-title-->
              <!--                    >-->
              <!--                  </v-list-item>-->
              <!--                  <v-list-item v-if="!meetingHasPublishedAgenda" link-->
              <!--                               @click="moveAgenda(agenda.primaryKey, 'DOWN')">-->
              <!--                    <v-list-item-title-->
              <!--                    >-->
              <!--                      <v-icon style="height: 16px; font-size: 16px"-->
              <!--                      >mdi-arrow-down-->
              <!--                      </v-icon-->
              <!--                      >-->
              <!--                      Move it Down-->
              <!--                    </v-list-item-title-->
              <!--                    >-->
              <!--                  </v-list-item>-->
              <!--                  <v-divider></v-divider>-->
              <!--                  <v-list-item link v-if="!meetingHasPublishedAgenda" @click="deleteAgendas(agenda.agendaKey)">-->
              <!--                    <v-list-item-title-->
              <!--                    >-->
              <!--                      <v-icon style="height: 16px; font-size: 16px"-->
              <!--                      >mdi-delete-->
              <!--                      </v-icon-->
              <!--                      >-->
              <!--                      Delete-->
              <!--                    </v-list-item-title-->
              <!--                    >-->
              <!--                  </v-list-item>-->
              <!--                </v-list>-->
              <!--              </v-menu>-->
            </v-flex>
          </v-layout>
        </v-card-subtitle>

        <v-card-text>
          <AgendaItems
            :agendaId="agenda.AgendaNumber"
            :agendaKey="agenda.agendaKey"
            :egandaItems="agenda.AgendaItems"
            @lockScroll="lockScrolling"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-menu app offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!meetingHasPublishedAgenda"
                slot="activator"
                color="indigo"
                text
                v-bind="attrs"
                x-small
                v-on="on"
              >
                <v-icon small>mdi-plus</v-icon>
                Agenda
              </v-btn
              >
            </template>

            <v-list dense>
              <v-list-item @click="addNewAgendaItem(agenda.primaryKey, 'Last')">
                <v-list-item-title
                >
                  <v-icon style="height: 16px; font-size: 16px"
                  >mdi-air-filter
                  </v-icon
                  >
                  Standard Agenda
                </v-list-item-title
                >
              </v-list-item
              >

              <v-list-item
                v-if="!meetingHasConfirmMinutes"
                @click="
                  confimMinutesDialog = true;
                  selectedAgenda = agenda.primaryKey;
                "
              >
                <v-list-item-title
                >
                  <v-icon style="height: 16px; font-size: 16px"
                  >mdi-lock-check
                  </v-icon
                  >
                  Confirm minutes
                </v-list-item-title
                >
              </v-list-item>

<!--              <v-list-item-->
<!--                @click="-->
<!--                  confimMinutesDialog = true;-->
<!--                  selectedAgenda = agenda.primaryKey;-->
<!--                "-->
<!--              >-->
<!--                <v-list-item-title-->
<!--                >-->
<!--                  <v-icon style="height: 16px; font-size: 16px"-->
<!--                  >mdi-package-variant-->
<!--                  </v-icon-->
<!--                  >-->
<!--                  Committee meetings-->
<!--                </v-list-item-title-->
<!--                >-->
<!--              </v-list-item>-->
            </v-list>
          </v-menu>

          <!--          <v-spacer></v-spacer>-->

          <!--          <v-btn-->
          <!--              v-if="!meetingHasPublishedAgenda"-->
          <!--              color="indigo"-->
          <!--              text-->
          <!--              x-small-->
          <!--              @click="addNewAgenda(currentMeeting, agenda.primaryKey)"-->
          <!--          >-->
          <!--            <v-icon small>mdi-plus</v-icon>-->
          <!--            Section-->
          <!--          </v-btn-->
          <!--          >-->
        </v-card-actions>
      </v-card>
    </v-card-text>

    <ConfimMinutesDialog
      :confimMinutesDialog="confimMinutesDialog"
      :selectedAgenda="selectedAgenda"
      @CloseConfimMinutesDialog="confimMinutesDialog = false"
    />

    <InterestRegisterAgendaDialog
      :interestRegisterDialog="interestRegisterDialog"
      :lastMeetings="currentMeeting"
      :selectedAgenda="selectedAgenda"
      @CloseInterestRegisterDialog="interestRegisterDialog=false"
    />
  </v-card>
</template>

<script>
import AgendaItems from "./AgendaItems.vue";
import ConfimMinutesDialog from "./ConfimMinutesDialog.vue";
import InterestRegisterAgendaDialog from "./InterestRegisterAgendaDialog.vue";

export default {
  props: ["AgendaBuilderWindow", "currentMeeting", "listOfagendas"],
  components: {
    AgendaItems,
    ConfimMinutesDialog,
    InterestRegisterAgendaDialog,
  },
  data() {
    return {
      selectedAgenda: null,
      interestRegisterDialog: false,
      confimMinutesDialog: false,
      loading: false,
      scrollable: false,
      dialog2: false,
      dialog3: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
  methods: {
    lockScrolling() {
      const body = document.getElementById("agendaPanels");
      body.style.overflow = "hidden";
      body.style.overflow = "auto";
    },

    closeGreatModel() {
      this.$emit("close");
    },

    async reloadAgendas() {
      this.loading = true;
      await new Promise((resolve) =>
        setTimeout(resolve, this.$router.go(), 3000)
      );
      await this.$store.dispatch(
        "loadManagementBestPracticeAgendaTemplates",
        this.currentMeeting
      );
    },

    async onPurposeInput(event, selectedAgenda) {
      this.Purpose = event.target.innerText;
      var details = {
        newItem: this.Purpose,
        agendaKey: selectedAgenda,
      };
      await this.$store.dispatch("updateManagementAgenda", details);
    },

    async addNewAgendaItem(agendaKey, Position) {
      await this.$store.dispatch("addManagementAgendaItem", {
        agendaKey: agendaKey,
        Position: Position,
      });
    },

    async addVotingNewAgendaItem(agendaKey, Position) {
      await this.$store.dispatch("addManagementNewVotingAgendaItem", {
        agendaKey: agendaKey,
        Position: Position,
      });
    },

    async addNewAgenda(meetingKey, Position) {
      await this.$store.dispatch("addManagementAgenda", {
        meetingKey: meetingKey,
        Position: Position,
      });
      await this.$store.dispatch('loadManagementBestPracticeAgendaTemplates', this.$store.state.ManagementCurrentMeetingStore.currentManagementMeeting)
    },

    async deleteAgendas(agendaKey) {
      await this.$store.dispatch("deleteManagementAgendas", {
        meetingKey: this.$store.state.ManagementCurrentMeetingStore.currentManagementMeeting,
        agendaKey: agendaKey,
      });
      await this.$store.dispatch('loadManagementBestPracticeAgendaTemplates', this.$store.state.ManagementCurrentMeetingStore.currentManagementMeeting)
    },

    async moveAgenda(agendaUniqueId, position) {
      await this.$store.dispatch("moveManagementMeetingAgenda", {
        agendaUniqueId: agendaUniqueId,
        position: position,
      });
      await this.$store.dispatch(
        "loadManagementBestPracticeAgendaTemplates",
        this.$store.state.ManagementMeetingsStore.selectedManagementMeetingDetails.meetingUniqueId
      );
    },
  },
  computed: {
    meetingHasPublishedAgenda() {
      return this.$store.getters.getManagementMeetingDetails.meetingHasPublishedAgenda
    },
    // lastMeetings() {
    //   return this.$store.state.ManagementMeetingsStore.unConfirmManagementMeetings;
    // },
    meetingHasConfirmMinutes() {
      return this.$store.state.ManagementMeetingsStore.selectedManagementMeetingDetails.meetingHasConfirmMinutes;
    },
  },
  // async mounted() {
  //   await this.$store.dispatch("loadManagementUnConfirmMeetings");
  // },
};
</script>


<style scoped>
.cencontent {
  align-content: center;
}
</style>


<style>
.fixed-position {
  position: fixed;
}

.transparent {
  background-color: white !important;
  opacity: 0.3;
  border-color: transparent !important;
}

.scroll {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>
