
import { apolloClient } from '@/vue-apollo'
import meetingDashboard from '../../graphql/management-dashboard-graphql/meetingDashboard.gql'
import directivesDashboard from '../../graphql/management-dashboard-graphql/directivesDashboard.gql'
import attendanceDashboard from '../../graphql/management-dashboard-graphql/attendanceDashboard.gql'
import managementDashboard from '../../graphql/management-dashboard-graphql/managementDashboard.gql'
import getTopManagementMeetingAbsentees from '../../graphql/management-dashboard-graphql/getTopManagementMeetingAbsentees.gql'


export default {
    state: {
        meetingDashboardData: null,
        directivesDashboardData: null,
        attendanceDashboardData: null,
        boardDashboardData: null,
        boardManagementTopAbsentees: []
    },

    mutations: {
        addManagementMeetingDashboard(state, structureObj) {
            state.meetingDashboardData = structureObj
        },
        addManagementDirectivesDashboard(state, structureObj) {
            state.directivesDashboardData = structureObj
        },
        addManagementAttendanceDashboard(state, structureObj) {
            state.attendanceDashboardData = structureObj
        },
        addManagementBoardDashboard(state, structureObj) {
            state.boardDashboardData = structureObj
        },
        addBoardManagementTopAbsentees(state, data) {
            state.boardManagementTopAbsentees = data
        },
    },

    getters: {
        getManagementMeetingDashboard:state=>state.meetingDashboardData,
        getManagementDirectivesDashboard:state=>state.directivesDashboardData,
        getManagementAttendanceDashboard:state=>state.attendanceDashboardData,
        getManagementBoardDashboard:state=>state.boardDashboardData,
        getBoardManagementTopAbsentees:state=>state.boardManagementTopAbsentees,
    },

    actions: {
        async loadAllManagementDashboard(context,data) {
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingDashboard,
                variables: {
                    managementUniqueId: localStorage.getItem("my-managementUniqueId")

                }
            }).then((response) => {
                const structures = response.data.managementMeetingDashboard
                context.commit('addManagementMeetingDashboard', structures)
            }).then(() => {
                apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: directivesDashboard,
                    variables: {
                        managementUniqueId: localStorage.getItem("my-managementUniqueId"),
                        filterType:data.filterType,
                        weeksTobeFiltered:data.weeksTobeFiltered
                    }
                }).then((directiveResponse) => {
                    const structures = directiveResponse.data.managementDirectivesDashboard
                    context.commit('addManagementDirectivesDashboard', structures)
                }).then(() => {
                    apolloClient.query({
                        fetchPolicy: 'no-cache',
                        query: attendanceDashboard,
                        variables: {
                            managementUniqueId: localStorage.getItem("my-managementUniqueId")
                        }
                    }).then((attendanceResponse) => {
                        const structures = attendanceResponse.data.managementAttendanceDashboard
                        context.commit('addManagementAttendanceDashboard', structures)
                    })
                }).then(() => {
                    apolloClient.query({
                        fetchPolicy: 'no-cache',
                        query: managementDashboard,
                        variables: {
                            managementUniqueId: localStorage.getItem("my-managementUniqueId")
                        }
                    }).then((boardResponse) => {
                        const structures = boardResponse.data.managementBoardDashboard
                        context.commit('addManagementBoardDashboard', structures)
                    })
                }).then(() => {
                    apolloClient.query({
                        fetchPolicy: 'no-cache',
                        query: getTopManagementMeetingAbsentees,
                        variables: {
                            managementUniqueId: localStorage.getItem("my-managementUniqueId")
                        }
                    }).then((boardResponse) => {
                        const { data } = boardResponse.data.getTopManagementMeetingAbsentees
                        context.commit('addBoardManagementTopAbsentees', data)
                    })
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        }

    }

}
