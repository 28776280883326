<template>
  <v-container>
    <div class="content">
      <h2 class="intro-y text-lg font-medium mt-10">Management Reports</h2>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div
            class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
          <div class="hidden md:block mx-auto text-slate-500"></div>
<!--          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">-->
<!--            <div class="w-56 relative text-slate-500">-->
<!--              <input-->
<!--                  type="text"-->
<!--                  class="form-control w-56 box pr-10"-->
<!--                  placeholder="Search..."-->
<!--              />-->
<!--              <svg-->
<!--                  xmlns="http://www.w3.org/2000/svg"-->
<!--                  width="24"-->
<!--                  height="24"-->
<!--                  viewBox="0 0 24 24"-->
<!--                  fill="none"-->
<!--                  stroke="currentColor"-->
<!--                  stroke-width="2"-->
<!--                  stroke-linecap="round"-->
<!--                  stroke-linejoin="round"-->
<!--                  icon-name="search"-->
<!--                  class="lucide lucide-search w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"-->
<!--                  data-lucide="search"-->
<!--              >-->
<!--                <circle cx="11" cy="11" r="8"></circle>-->
<!--                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>-->
<!--              </svg>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <!-- BEGIN: Users Layout -->
        <div
            @click="navigateToNewPage('/management/management_reports/management_meetings')"
            class="intro-y col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 report-box zoom-in"
        >
          <div class="box">
            <div class="p-5">
              <div class="h-40 2xl:h-4056">
                <img
                    alt="Reports Image"
                    class="rounded-md image-fit overflow-hidden"
                    src="@/assets/report.png"
                />
              </div>
              <div class="text-slate-600 dark:text-slate-500 mt-5 text-center">
                <v-btn
                    outlined
                    class="text-xl w-full font-bold mt-1 text-capitalize"
                >
                  Management Meetings
                  <v-icon small right>mdi-arrow-right</v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </div>

        <div
            @click="navigateToNewPage('/management/management_reports/management_meetings_directives')"
            class="intro-y col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 report-box zoom-in"
        >
          <div class="box">
            <div class="p-5">
              <div class="h-40 2xl:h-4056">
                <img
                    alt="Reports Image"
                    class="rounded-md image-fit overflow-hidden"
                    src="@/assets/report.png"
                />
              </div>
              <div class="text-slate-600 dark:text-slate-500 mt-5 text-center">
                <v-btn
                    outlined
                    class="text-xl w-full font-bold mt-1 text-capitalize"
                >
                  Management Directives
                  <v-icon small right>mdi-arrow-right</v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </div>


        <div
            @click="navigateToNewPage('/management/management_reports/management_meetings_decisions')"
            class="intro-y col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 report-box zoom-in"
        >
          <div class="box">
            <div class="p-5">
              <div class="h-40 2xl:h-4056">
                <img
                    alt="Reports Image"
                    class="rounded-md image-fit overflow-hidden"
                    src="@/assets/report.png"
                />
              </div>
              <div class="text-slate-600 dark:text-slate-500 mt-5 text-center">
                <v-btn
                    outlined
                    class="text-xl w-full font-bold mt-1 text-capitalize"
                >
                  Management Resolutions
                  <v-icon small right>mdi-arrow-right</v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </div>

        <div
            @click="navigateToNewPage('/management/management_reports/management_committee')"
            class="intro-y col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 report-box zoom-in"
        >
          <div class="box">
            <div class="p-5">
              <div class="h-40 2xl:h-4056">
                <img
                    alt="Reports Image"
                    class="rounded-md image-fit overflow-hidden"
                    src="@/assets/report.png"
                />
              </div>
              <div class="text-slate-600 dark:text-slate-500 mt-5 text-center">
                <v-btn
                    outlined
                    class="text-xl w-full font-bold mt-1 text-capitalize"
                >
                  Management Committee
                  <v-icon small right>mdi-arrow-right</v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </div>


        <!-- END: Users Layout -->
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  methods: {
    navigateToNewPage(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style></style>
