<template>
  <v-container id="user-profile" fluid tag="section">
    <div class="flex justify-content-center padding">
        <v-layout row wrap>
          <v-flex md6 style="padding: 10px" xs12>
            <v-btn
                :to="`/settings/eboard_members`"
                color="primary"
                link
                rounded
                outlined
                small
            >
              <span class="flex center"
              ><v-icon>mdi-arrow-left</v-icon> Users
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
    </div>

    <v-row justify="center">
      <v-col cols="12" md="12" sm="12" lg="12" class="mt-2">
        <v-card class="pl-5 pr-5" >
          <v-card-title>
            <span class="text-h6">
              <v-icon color="indigo" class="mr-2">mdi-account-edit</v-icon> Edit User Profile</span
            >
          </v-card-title>

          <v-divider></v-divider>

          <v-form>
            <v-container class="mt-2">
              <v-row>
                <v-col cols="12" md="4" sm="12" lg="4">
                  <v-text-field
                      label="First Name"
                      class="purple-input"
                      v-model="firstName"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="12" lg="4">
                  <v-text-field
                      label="Last Name"
                      class="purple-input"
                      v-model="lastName"
                  />
                </v-col>

                <v-col cols="12" md="4" sm="12" lg="4">
                  <v-text-field
                      label="Email Address"
                      class="purple-input"
                      v-model="email"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4" sm="12" lg="4">
                  <v-text-field
                      label="Suffix"
                      v-model="suffix"
                  />
                </v-col>

                <v-col cols="12" md="4" sm="12" lg="4">
                  <v-select
                      v-model="title"
                      :items="titlesList"
                      :menu-props="{ left: true, offsetY: true }"
                      :counter="20"
                      label="Title"
                      persistent-hint
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" sm="12" lg="4">
                  <v-text-field
                      class="purple-input"
                      label="Nida Number"
                      v-model="nidaNumber"
                      :rules="nidaNumberRules"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" md="6" sm="6" lg="6">
                  <v-text-field
                      class="purple-input"
                      label="Position / Designation"
                      v-model="position"
                      :disabled="true"
                  />
                </v-col>
                <v-col cols="6" md="6" sm="6" lg="6">
                  <v-text-field
                      class="purple-input"
                      label="Institution"
                      v-model="institution"
                      :disabled="true"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="8" sm="12" lg="8">
                  <v-row>
                    <v-col cols="12" md="6" sm="12" lg="6">
                      <v-text-field
                          label="Phone Number"
                        v-model="phoneNumber"
                      />
                    </v-col>

                    <v-col cols="12" md="6" sm="12" lg="6">
                      <v-text-field
                          label="Access Level"
                          class="purple-input"
                          :disabled="true"
                          v-model="accessLevel"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                </v-col>

                <v-col cols="4" md="4" sm="12" lg="4">
                  <center>
                    <v-badge
                        bottom
                        color="deep-purple accent-4"
                        offset-x="10"
                        offset-y="10"
                        dot
                    >
                      <v-avatar size="80">
                        <v-img :src="`${profilePhoto}`"></v-img>
                      </v-avatar>
                      <v-file-input
                          prepend-icon="mdi-camera"
                          counter
                          hide-input
                          truncate-length="22"
                          v-model="profilePhoto"
                          @change="onChange"
                          accept="image/*"
                      ></v-file-input>
                    </v-badge>
                  </center>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn
                      outlined
                      small
                      color="primary"
                      class="mr-0"
                      @click="updateUserProfileDetails"
                  >
                    Update Profile
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import swal from "sweetalert2";

export default {
  data() {
    return {
      titlesList: ["Prof", "Dr", "Mr", "Mrs", "Ms", "Madam"],
      firstName: "",
      lastName: "",
      email: "",
      suffix: "",
      title: "",
      position: "",
      nidaNumber: "",
      institution: "",
      phoneNumber: "",
      accessLevel: "",
      profilePhoto: "",
      nidaNumberRules: [
        (v) => v.length === 20 || "Nida number have 20 numbers",
      ]
    };
  },

  methods: {
    async onChange() {
      let file = this.profilePhoto;

      const sendData = {
        'file': file,
        'type': file.type,
        'data': file.size,
        'userId': this.$route.params.user_id
      };

      await this.$store.dispatch('uploadProfilePicture', sendData);
      await this.$store.dispatch('userProfileDetails', sendData.userId);
    },

    async updateUserProfileDetails() {
      if (this.password1 !== "" && this.password2 !== "") {
        if (this.password1 !== this.password2) {
          await swal.fire({
            toast: true,
            icon: 'error',
            title: "Password 1 and Password 2 do not Match",
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return;
        }
      }

      const sendData = {
        'firstName': this.firstName,
        'lastName': this.lastName,
        'email': this.email,
        'suffix': this.suffix,
        'title': this.title,
        'phoneNumber': this.phoneNumber,
        'password1': this.password1,
        'password2': this.password2,
        'nidaNumber': this.nidaNumber,
        'userId': this.$route.params.user_id
      };

      await this.$store.dispatch('updateUserProfileDetails', sendData);
      await this.$store.dispatch('userProfileDetails', sendData.userId);
    },
  },

  computed: {
    userObj() {
      return this.$store.state.MembersStore.userProfileDetails
    }
  },

  watch: {
    userObj() {
      const envPath = process.env.VUE_APP_MEDIA_SERVER;

      this.firstName =  this.userObj.firstName;
      this.lastName =  this.userObj.lastName;
      this.email =  this.userObj.email;
      this.suffix =  this.userObj.suffix;
      this.title =  this.userObj.title;
      this.position =  this.userObj.designation;
      this.institution =  this.userObj.institution;
      this.phoneNumber =  this.userObj.phoneNumber;
      this.accessLevel =  this.userObj.accessLevel;
      this.nidaNumber =  this.userObj.nidaNumber;
      this.password1 =  "";
      this.password2 =  "";
      this.profilePhoto = `${envPath}${this.userObj.profilePicture}`;
    }
  },

  async mounted() {
    await this.$store.dispatch('userProfileDetails', this.$route.params.user_id);
  },
};
</script>

<style>
</style>
