<template>
  <v-app v-bind:style="{ backgroundColor: color }">
    <v-main>
      <Spinners v-if="showLoading" />
      <AutoLogout />
      <router-view />
    </v-main>
    <v-dialog
      @networkloadingstart="overlay = true"
      @networkloadingend="overlay = false"
      v-model="overlay"
      fullscreen
      full-width
    >
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Spinners from "./modules/meeting_management/e_board_shared/partials/Spinners.vue";
import AutoLogout from "./modules/system_management/eboard_auth/AutoLogout.vue";

export default {
  name: "App",
  components: { Spinners, AutoLogout },
  data: () => ({
    overlay: false,
    color: "",
  }),
  computed: {
    ...mapState({
      showLoading: (state) => state.showLoading,
    }),

    token() {
      return localStorage.getItem("apollo-token") || null;
    },
  },
  async mounted() {
    //check user authentications
    await this.$store.dispatch("RolesStore/checkMyAuthenticationStatus", {
      root: true,
    });

    if (!this.token && this.$route.name != "LandingPage" && this.$route.name != "PasswordResetAccount") {

      this.$router.push("/landing_page");
      
    } else if (this.$route.name === "PasswordResetAccount") {
      this.$router.push({ name: "PasswordResetAccount" });
    }

  },
};
</script>

<style>
#app {
  background-color: rgba(161, 173, 230, 0.123);
}
</style> 
