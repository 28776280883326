<template>
  <v-dialog v-model="interestRegisterDialog" max-width="80%" persistent>
    <v-card>
      <v-card-title class="text-h5;cencontent"> Interest Register </v-card-title>

      <v-card-text class="cencontent">

          <v-list width="100%" dense>
            <v-list-item-group color="primary">
              <v-layout width="100%" style="padding-top: 10px; width: 100%">
                <v-alert dense text style="width: 100%">
                  <v-flex xs12 md12>
                    <div class="">
                      <span
                        >List of interests of directors and officers including
                        actual, potential or perceived conflicts please review and confirm to use.</span
                      >
                    </div>
                  </v-flex>
                </v-alert>
              </v-layout>
              <v-list-item>
                <v-card elevation="0" width="100%">
                  <v-tabs style="content-align: left" vertical>
                    <v-tab
                      v-for="member in activeInterestList"
                      :key="member.primaryKey"
                    >
                      {{ member.firstName }} {{ member.lastName }}
                    </v-tab>

                    <v-tab-item
                      v-for="member in activeInterestList"
                      :key="member.interestKey"
                    >
                      <v-card>
                        <v-card-text>
                          <v-simple-table>
                            <template>
                              <thead>
                                <tr>
                                  <th style="width: 30%" class="text-left">
                                    <strong>Organization</strong>
                                  </th>
                                  <th style="width: 40%" class="text-left">
                                    <strong>Interest</strong>
                                  </th>
                                  <th style="width: 20%" class="text-left">
                                    <strong>Notified Date</strong>
                                  </th>
                                  <th style="width: 20%" class="text-left">
                                    <strong>Action</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>

                                <tr
                                  v-for="interest in member.listOfInterest"
                                  :key="interest.primaryKey"
                                >
                                  <td>
                                    <strong>{{
                                      interest.interestOrganisation
                                    }}</strong>
                                  </td>
                                  <td>
                                    {{ interest.interestNature }}
                                  </td>
                                  <td>
                                    {{
                                      interest.interestNotificationDate
                                        | formatDate
                                    }}
                                  </td>
                                  <td>
                                    <v-btn
                                    @click="deactivateMemberInterest(member.primaryKey,interest.primaryKey)"
                                    color="pink"
                                    fab
                                    icon
                                    x-small
                                    text
                                    rounded
                                    ><v-icon>mdi-delete</v-icon></v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </v-list-item>
            </v-list-item-group>
          </v-list>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          small
          color="pink darken-1"
          text
          @click="interestRegisterDialog=false"
        >
          Cancel
        </v-btn>
        <v-btn small color="green darken-1" text @click="addInterestRegisterAgenda">
          confirm to use
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["interestRegisterDialog", "lastMeetings", "selectedAgenda"],
  data() {
    return {
      lastMeeting: null,
    };
  },
  methods: {
    async addInterestRegisterAgenda() {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("addInterestRegisterAgendaItem", {
        selectedAgenda: this.selectedAgenda,
        lastMeeting: this.lastMeetings,
      });
      this.$emit("CloseInterestRegisterDialog");
      this.lastMeeting = null;
      this.$store.dispatch("showLoadingSpinner", false);
    },
    async deactivateMemberInterest(member,interest){
      await this.$store.dispatch("deactivateMemberInterest", {
        member: member,
        interest: interest,
      });
    }
  },
  computed:{
    activeInterestList() {
      return this.$store.state.InterestStore.activeInterestList;
    },
  }
};
</script>
