<template>

  <v-container fluid>
    <v-row>
      <v-col
          class="d-flex"
          cols="12"
          sm="12"
      >
        <p>For any Approved Resolution Made by Management</p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
          class="d-flex"
          cols="12"
          sm="4"
      >
        <v-select
            outlined
            v-model="selectedMeeting"
            :items="managementMeetingsList"
            dense
            item-text="meetingTitle"
            item-value="primaryKey"
            label="Filter by Meeting"
            @change="onFilterChange"
        ></v-select>
      </v-col>
      <v-col
          class="d-flex"
          cols="12"
          sm="4"
      >
        <v-select
            outlined
            v-model="selectedStatus"
            :items="decisionStatus"
            @change="onFilterChange"
            dense
            item-text="name"
            item-value="id"
            label="Filter by Status"
        ></v-select>
      </v-col>


    </v-row>
    <v-row>
      <v-col sm="12">
        <div style="padding:10px" v-for="decision in managementDecisions.data" :key="decision.primaryKey">
          <v-card class="mx-auto" max-width="100%" outlined>
            <v-card-title>
              {{ decision.decisionTitle }}
              <v-spacer></v-spacer>
              <v-chip>
                Source: {{ decision.decisionSource }}
              </v-chip>
<!--              <v-menu z-index="1" offset-y app>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-icon @click="opened=!opened" v-bind="attrs" v-on="on" slot="activator">mdi-dots-vertical</v-icon>-->
<!--                </template>-->
<!--                <v-list dense>-->
<!--                  <v-list-item link to="">-->
<!--                    <v-list-item-title @click="OpenActionEditor=true">-->
<!--                      <v-icon style="height: 16px;font-size: 16px;">mdi-pencil-box-multiple-outline</v-icon>-->
<!--                      Edit Action-->
<!--                    </v-list-item-title>-->
<!--                  </v-list-item>-->
<!--                  <v-list-item link to="">-->
<!--                    <v-list-item-title @click="OpenNoticeDialog=true">-->
<!--                      <v-icon style="height: 16px;font-size: 16px;">mdi-bell-ring-outline</v-icon>-->
<!--                      Set Email Reminder-->
<!--                    </v-list-item-title>-->
<!--                  </v-list-item>-->
<!--                </v-list>-->
<!--              </v-menu>-->
            </v-card-title>

            <v-card-text>

              <div class="my-2" v-html="decision.decisionDescriptions"></div>


              <div class="my-4 text-subtitle-1">
                <strong>Meeting: </strong> {{ decision.decisionMeeting }}
              </div>
            </v-card-text>

            <v-divider class="mx-1"></v-divider>

            <v-card-text>
              <v-card
                  tile
                  elevation="0"
              >
                <v-row>
                  <v-col sm="8">
                    <v-card-subtitle style="font-size:16px">
                      <p><strong>Outcome:</strong>
                        <v-icon color="success">mdi-check-circle-outline</v-icon>
                        {{ decision.decisionOutcome === 'APR' ? 'APPROVED' : 'NOT APROVED' }}
                      </p>
                      <p><strong>Resolution Date: </strong> {{ new Date(decision.decisionCreateddate).toDateString() }}
                      </p>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </div>
        <v-card-actions>
          <v-btn
              @click="paginateManagementDecisions(managementDecisions.page.previousPageNumber)"
              v-if="managementDecisions.page.hasPreviousPage" outlined small
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Previous Page ({{ managementDecisions.page.previousPageNumber }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              @click="paginateManagementDecisions(managementDecisions.page.nextPageNumber)"
              v-if="managementDecisions.page.hasNextPage" outlined small
          >
            Next Page ({{ managementDecisions.page.nextPageNumber }})
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>

export default {
  components: {},

  data() {
    return {
      decisionStatus: [
        {id: 'ALL', name: 'ALL'}, {id: 'APR', name: 'APPROVED'}, {id: 'NOT', name: 'NOT APPROVED'}
      ],
      selectedMeeting: {primaryKey: "ALL", meetingTitle: "ALL"},
      selectedStatus: {id: 'ALL', name: 'ALL'},
      decisionObj: {
        meetingUniqueId: null,
        decisionOutcome: null,
        pageNumber: 1,
      }
    }
  },

  methods: {
    async paginateManagementDecisions(pageNumber) {
      this.decisionObj.pageNumber = pageNumber;
      await this.$store.dispatch("loadManagementAssignedDecisions", this.decisionObj)
    },
    async onFilterChange() {

      this.decisionObj = {
        meetingUniqueId: this.selectedMeeting.primaryKey || this.selectedMeeting === 'ALL' ? null : this.selectedMeeting,
        decisionOutcome: this.selectedStatus.id || this.selectedStatus === 'ALL' ? null : this.selectedStatus,
        pageNumber: 1
      }
      await this.$store.dispatch("loadManagementAssignedDecisions", this.decisionObj)
    }
  },

  computed: {
    managementDecisions() {
      return this.$store.state.ManagementDecisionStore.managementAssignedDecisionList;
    },
    managementMeetingsList() {
      return [
        {primaryKey: "ALL", meetingTitle: "ALL"},
        ...this.$store.getters.managementMeetingsList
      ]
    },
  },

  async mounted() {
    await this.$store.dispatch("loadManagementAssignedDecisions");
    await this.$store.dispatch("loadManagementMeetingsList", { managementUniqueId: localStorage.getItem("my-managementUniqueId") });
  },

};
</script>

<style>

</style>
