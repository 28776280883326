<template>
  <v-dialog v-model="OpenNoticeDialog" max-width="70%" persistent>
    <v-card>
      <v-card-title style="font-size: 20px">
        <span
          ><v-icon color="indigo">mdi-calendar-check</v-icon> Meeting
          Notice</span
        >
      </v-card-title>
      <v-card-subtitle
        ><br />
        {{ (meetingData||{}).meetingTitle }} -
        {{ (meetingData||{}).meetingDate | formatDate }} <br />
        <small
          ><span style="color: #4d6dbf"
            >Send an email notice of this meeting, with calendar attachment, to
            the following attendees:</span
          ></small
        >
      </v-card-subtitle>
      <v-divider></v-divider>

      <v-card-text>
        <template>
          <v-row>
            <v-col sm="12">
              <v-treeview
                v-model="selectedManagements"
                :items="managementsMemberList"
                :open-all="false"
                selectable
              ></v-treeview>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="4" md="4" sm="4">
                <v-text-field
                  v-model="Greetings"
                  label="Greetings"
                  prepend-icon="mdi-human-greeting"
                >
                </v-text-field>
              </v-col>
              <v-col cols="8" sm="8">
                <v-select
                  v-model="salutationTitles"
                  :items="['Title', 'First Name', 'Last Name', 'Suffix']"
                  attach
                  chips
                  label="Name"
                  multiple
                ></v-select>
              </v-col>
              
            <Editor
              :content="content"
              :editorOption="editorOption"
              style="height: 180px"
              @onEditorChange="updateNoticeMessage($event)"
            />
            </v-row>


            <v-row>
              <v-col sm="12">
                <v-checkbox
                  v-model="isGenerateBoardPack"
                  :label="`Generate Board Pack`"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="pink"
          outlined
          small
          text
          @click="$emit('CloseNoticeDialog')"
        >
          <v-icon small>mdi-close</v-icon>
          Close
        </v-btn>
        <v-btn color="primary" outlined small @click="sendMeetingNotice">
          <v-icon small>mdi-floppy</v-icon>
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "../e_board_shared/fields/Editor.vue";

export default {
  props: ["OpenNoticeDialog", "meetingData"],

  components: {
    Editor,
  },

  data() {
    return {
      Greetings: "Dear",
      value: "",
      salutationTitles: [],
      actualSalutationTitles: [],
      content: "",
      editorOption: {
        placeholder:
          "You can add an optional comment which will appear after the greeting",
        theme: "snow",
      },
      checkbox: true,
      isGenerateBoardPack: false,
      selectedmembers: [],
      selectedManagements: [],
      selectedCommittees: [],
      actualMembersList: [],
      actualManagementsList: [],
      actualList: [],
      boadMemberList: [],
      managementsMemberList: [],
    };
  },

  computed: {
    loadedMemebrs() {
      return this.$store.state.MembersStore.boardMembers;
    },
    loadManagements() {
      return this.$store.state.ManagementsStore.managementsMembers;
    },
    loadCommittees() {
      let committees = [];
      let all_committess =
        this.$store.state.MeetingsStore.selectedMeetingDetails
          .meetingCommitteeMeeting.edges;

      if (all_committess.length > 0) {
        all_committess.forEach((committee) => {
          let commitee_members = [];
          committee.node.meetingCommittee.committeeMember.edges.forEach(
            (member) => {
              commitee_members.push({
                id: `${member.node.memberUniqueId}*${committee.node.meetingCommittee.committeeUniqueId}`,
                name:
                  member.node.memberUser.firstName +
                  " " +
                  member.node.memberUser.lastName,
              });
            }
          );
          committees.push({
            id: committee.node.meetingCommittee.committeeUniqueId,
            name: committee.node.meetingCommittee.committeeName,
            children: commitee_members,
          });
        });
      }
      return committees;
    },
  },

  watch: {
    salutationTitles: function () {
      this.actualSalutationTitles = [];
      this.salutationTitles.forEach((title) => {
        this.actualSalutationTitles.push({ titleName: title });
      });
    },
    loadedMemebrs: function () {
      this.boadMemberList = [];
      var childrens = [];
      this.loadedMemebrs.forEach((members) => {
        childrens.push({
          id: members.id,
          name: members.firstName + " " + members.lastName,
        });
      });
      this.boadMemberList.push({
        id: 0,
        name: "Board Members(" + this.loadedMemebrs.length + ")",
        children: childrens,
      });
      this.overlay = false;
    },
    selectedmembers: function () {
      this.actualMembersList = [];
      this.selectedmembers.forEach((member) => {
        this.actualMembersList.push({ memberId: member });
      });
    },
    loadManagements: function () {
      this.managementsMemberList = [];
      var childrens = [];
      this.loadManagements.forEach((management) => {
        childrens.push({
          id: management.id,
          name: management.firstName + " " + management.lastName,
        });
      });
      this.managementsMemberList.push({
        id: 0,
        name: "Managements Members(" + this.loadManagements.length + ")",
        children: childrens,
      });
    },
    selectedManagements: function () {
      this.actualManagementsList = [];
      this.selectedManagements.forEach((member) => {
        this.actualManagementsList.push({ managementsId: member });
      });
    },
  },

  methods: {
    updateNoticeMessage(new_value) {
      this.content = new_value;
    },

    async sendMeetingNotice() {
      await this.$store.dispatch("showLoadingSpinner", true);
      const notice = {
        noticeGreetings: this.Greetings,
        noticeBody: this.content,
        noticeMeeting: this.meetingData.primaryKey,
        noticeReceivers: this.actualMembersList,
        noticeManagements: this.actualManagementsList,
        noticeTitles: this.actualSalutationTitles,
        noticeCommittees: this.selectedCommittees,
        generateBoardPack: this.isGenerateBoardPack,
      };

      await this.$store.dispatch("sendMeetingNotice", {
        notice: notice,
        meeting: this.meetingData.meetingUniqueId,
      });

      this.Greetings = "Dear";
      this.content = null;
      this.salutationTitles = [];
      this.selectedmembers = [];
      this.selectedManagements = [];

      this.$emit("CloseNoticeDialog");
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardMambers");
    await this.$store.dispatch("loadManagementsMambers");
  },
};
</script>

<style>
</style>
