import {apolloClient} from '@/vue-apollo'
import allBoardCommitees from '../../graphql/eboard-committees-graphql/allBoardCommitees.gql'
import deactivateCommitteeMember from "../../graphql/eboard-committees-graphql/deactivateCommitteeMember.graphql";
import activateCommitteeMember from "../../graphql/eboard-committees-graphql/activateCommitteeMember.graphql";
import addCommitteeMember from "../../graphql/eboard-committees-graphql/addCommitteeMember.graphql";
import deactivateCommittee from "../../graphql/eboard-committees-graphql/deactivateCommittee.graphql";
import deleteCommittee from "../../graphql/eboard-committees-graphql/deleteCommittee.graphql";
import activeBoardCommittees from "../../graphql/eboard-committees-graphql/activeBoardCommittees.graphql";
import makeMemberAsLeader from "../../graphql/eboard-committees-graphql/makeMemberAsLeader.gql";


export default {
    state: {
        boardCommitees: [{header: 'Board Committees'},],
        boardCommiteesOnly: [],
    },

    mutations: {
        cleanCommittee(state) {
            state.boardCommitees = []
            state.boardCommiteesOnly = []
        },
        addCommittee(state, commiteeObj) {
            state.boardCommitees.push(commiteeObj)
        },
        addCommitteeOnly(state, commiteeObj) {
            state.boardCommiteesOnly.push(commiteeObj)
        }
    },

    getters: {
        getBoardCommitees(state){
            return state.boardCommiteesOnly
        }
    },

    actions: {
        async loadBoardCommittees(context) {
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allBoardCommitees,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const Committees = response.data.boardCommittees
                context.commit('cleanCommittee')

                Committees.forEach(Committee => {
                    const CommitteeLeader = Committee.committeeMembersList.find(user => {
                        return user.memberIsLeader === true
                    })

                    var object01 = {divider: true, inset: true}
                    var object02 = {
                        avatar: '/committee.jpeg',
                        key: Committee.committeeUniqueId,
                        title: Committee.committeeName,
                        subtitle: CommitteeLeader?.memberFirstName + ' ' + CommitteeLeader?.memberLastName,
                        active: Committee.committeeIsActive,
                        description: Committee.committeeDescription,
                        members: Committee.committeeMembersCount,
                        delete: Committee.committeeIsDeleted,
                        deactivated_date: Committee.committeeDeactivatedDate,
                        created_date: Committee.committeeCreatedDate,
                        activeMessage: "Member is Active",
                        meeting: false,
                        active_days: Committee.committeeActiveDays,
                        membersList: Committee.committeeMembersList
                    }

                    context.commit('addCommitteeOnly', object02)
                    context.commit('addCommittee', object01)
                    context.commit('addCommittee', object02)
                    context.dispatch("showLoadingSpinner", false);

                });
            }).catch(async (error) => {
                console.error(error.messages);
            })

        },

        async activeBoardCommittees(context) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: activeBoardCommittees,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const Committees = response.data.activeBoardCommittees
                context.commit('cleanCommittee')

                Committees.forEach(Committee => {
                    const CommitteeLeader = Committee.committeeMembersList.find(user => {
                        return user.memberIsLeader === true
                    })

                    var object01 = {divider: true, inset: true}
                    var object02 = {
                        avatar: '/committee.jpeg',
                        key: Committee.committeeUniqueId,
                        title: Committee.committeeName,
                        subtitle: CommitteeLeader?.memberFirstName + ' ' + CommitteeLeader?.memberLastName,
                        active: Committee.committeeIsActive,
                        description: Committee.committeeDescription,
                        members: Committee.committeeMembersCount,
                        delete: Committee.committeeIsDeleted,
                        deactivated_date: Committee.committeeDeactivatedDate,
                        created_date: Committee.committeeCreatedDate,
                        activeMessage: "Member is Active",
                        meeting: false,
                        active_days: Committee.committeeActiveDays,
                        membersList: Committee.committeeMembersList
                    }

                    context.commit('addCommitteeOnly', object02)
                    context.commit('addCommittee', object01)
                    context.commit('addCommittee', object02)
                    context.dispatch("showLoadingSpinner", false);

                });
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })

        },

        async deactivateCommitteeMember(context, committee) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deactivateCommitteeMember,
                variables: {
                    committeeUniqueId: committee.committeeUniqueId,
                    committeeMemberId: committee.memberUserId
                }
            }).then((response) => {
                console.log(response.data.deactivateCommitteeMember.success)
                context.dispatch('loadBoardCommittees')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async activateCommitteeMember(context, committee) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: activateCommitteeMember,
                variables: {
                    committeeUniqueId: committee.committeeUniqueId,
                    committeeMemberId: committee.memberUserId
                }
            }).then((response) => {
                console.log(response.data.activateCommitteeMember.success)
                context.dispatch('loadBoardCommittees')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async addCommitteeMember(context, committee) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: addCommitteeMember,
                variables: {
                    committeeUniqueId: committee.committeeUniqueId,
                    committeeMembersList: committee.committeeMembersList
                }
            }).then((response) => {
                console.log(response.data.addCommitteeMember.committee)
                context.dispatch('loadBoardCommittees')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deactivateCommittee(context, committeeUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deactivateCommittee,
                variables: {
                    committeeUniqueId: committeeUniqueId,
                }
            }).then((response) => {
                console.log(response.data.deactivateCommittee.success)
                context.dispatch('loadBoardCommittees')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteCommittee(context, committeeUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deleteCommittee,
                variables: {
                    committeeUniqueId: committeeUniqueId,
                }
            }).then((response) => {
                console.log(response.data.deleteCommittee.success)
                context.dispatch('loadBoardCommittees')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async makeMemberAsLeader(context, memberData) {
            await apolloClient.mutate({
                mutation: makeMemberAsLeader,
                variables: {
                    committeeMemberId: memberData.memberUserId,
                    committeeUniqueId: memberData.committeeUniqueId
                }
            }).then((response) => {
                console.log(response.data.makeMemberAsLeader.success)
            }).catch(async (error) => {
                console.error(error.messages);
            })
        },
    }
}
