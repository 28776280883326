import {apolloClient} from '@/vue-apollo'
import addManagementMeetingInvitees
    from '../../graphql/management-meeting-invittee-graphql/addManagementMeetingInvitees.graphql'

import getManagementMeetingInvitees
    from '../../graphql/management-meeting-invittee-graphql/getManagementMeetingInvitees.graphql'
import updateManagementMeetingInvitees
    from '../../graphql/management-meeting-invittee-graphql/updateManagementMeetingInvitees.graphql'

import {errorAlert, successAlert} from "@/services/notificationService";


export default {
    state: {
        managementCommitteeMeetingInvitees: [],
    },
    getters: {
        getManagementCommitteeMeetingInvitees(state) {
            return state.managementCommitteeMeetingInvitees
        },
    },
    mutations: {
        clearManagementCommitteeMeetingInvitees(state) {
            state.managementCommitteeMeetingInvitees = []
        },
        saveManagementCommitteeMeetingInvitees(state, invitees) {
            state.managementCommitteeMeetingInvitees = [...invitees]
        },
        addManagementCommitteeMeetingInvitee(state, invitees) {
            for (const invitee of invitees) {
                state.managementCommitteeMeetingInvitees.push(invitee)
            }
        },
    },
    actions: {
        async loadManagementCommitteeMeetingInvitees(context, meetingUniqueId = null) {
            context.commit('clearManagementCommitteeMeetingInvitees')
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getManagementMeetingInvitees,
                variables: {
                    meetingUniqueId
                }
            }).then(async (response) => {
                let invitees = response.data.getManagementMeetingInvitees
                context.commit('saveManagementCommitteeMeetingInvitees', invitees)
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async createManagementCommitteeMeetingInvitee(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: addManagementMeetingInvitees,
                variables: {
                    input
                }
            }).then(async (response) => {
                let result = response.data.addManagementMeetingInvitees
                if (result.response.code === 9000) {
                    context.commit('addManagementCommitteeMeetingInvitee', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })
        },
        async editManagementMeetingInvitees(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateManagementMeetingInvitees,
                variables: {
                    input
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateManagementMeetingInvitees
                if (result.response.code === 9000) {
                    context.commit('clearManagementCommitteeMeetingInvitees')
                    context.commit('saveManagementCommitteeMeetingInvitees', result.data)
                    await successAlert(result.response.message)
                } else {
                    await errorAlert(result.response.message)
                }
            })

        },
    },

}