<template>
  <v-row>
    <v-dialog
      v-model="newSystemAdministratorDialog"
      max-width="70%"
      persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <span class="text-h6"><v-icon>mdi-shield-account-outline</v-icon>Add System Adminstrator</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                      outlined
                      v-model="firstName"
                      :counter="50"
                      label="First Name*"
                      prepend-icon="mdi-form-textbox"
                      required >
                  </v-text-field>

                  <v-text-field
                      outlined
                      v-model="lastName"
                      :counter="100"
                      label="Last Name *"
                      prepend-icon="mdi-form-textbox"
                      required
                  >
                  </v-text-field>

                  <v-text-field
                      outlined
                      v-model="email"
                      :counter="100"
                      label="Email Address *"
                      prepend-icon="mdi-email-outline"
                      required
                  >
                  </v-text-field>

                  <v-select
                      outlined
                      v-model="roleUniqueId"
                      :items="getSystemAdminRoleList"
                      item-text="roleName"
                      item-value="roleUniqueId"
                      :rules="[v => !!v || 'Item is required']"
                      label="Role Name"
                      required
                      prepend-icon="mdi-shield-account-outline"
                  ></v-select>

                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="$emit('close-dialog')"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="createSystemAdmin"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import Icon from "@/views/Icon.vue";

export default {
  name: "AddNewSystemAdministrator",
  components: {Icon},

  props: [ 'newSystemAdministratorDialog' ],

  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    roleUniqueId: "",

    dialog: false
  }),


  computed: {
    getSystemAdminRoleList (){
      return this.$store.getters.getSystemAdminRoleList
    },
  },

  methods: {

    createSystemAdmin(){
      let input = {
        roleUniqueId: this.roleUniqueId,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email
      }
      this.$store.dispatch('createSystemAdmins', input)
      this.firstName = ""
      this.lastName = ""
      this.email = ""
      this.roleUniqueId = ""
      this.$emit('close-dialog')
    },
  }
}
</script>

<style scoped>

</style>