<template>
  <div>
    <v-switch v-model="reverse" label="Toggle reverse"></v-switch>

    <v-timeline :reverse="reverse" dense>
      <v-btn
        v-if="hasAccess('can_add_interest')"
        color="indigo"
        @click="newInterestFormDialog = true"
        small
        class="white--text"
        fab
        right
        top
        absolute
        ><v-icon>mdi-plus</v-icon></v-btn
      >
      <v-timeline-item
      class="intro-y"
        v-for="interest in boardInterestList"
        :key="interest.key"
      >
        <template v-slot:icon>
          <v-avatar>
            <v-img :src="MEDIA_SERVER + interest.avatar"></v-img>
          </v-avatar>
        </template>
        <span slot="opposite">Tus eu perfecto</span>
        <v-card class="elevation-2">
          <v-card-title class="text-h5">
            {{ interest.fullname }}
            <v-spacer></v-spacer>
            <v-btn v-if="hasAccess('can_add_interest')" icon
              ><v-icon>mdi-pen</v-icon></v-btn
            >
            <v-btn v-if="hasAccess('can_add_interest')" icon
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            Nature of Interest: <strong>{{ interest.nature }}</strong>
          </v-card-text>
          <v-card-text>
            Organization: <strong>{{ interest.organisation }}</strong>
          </v-card-text>
          <v-card-text>
            Notified Date: <strong>{{ interest.notified | formatDate }}</strong>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <v-dialog
      v-model="newInterestFormDialog"
      width="70%"
      :key="newInterestFormKey"
    >
      <NewInterestForm
        @closeNewInterestFormDialog="newInterestFormDialog = false"
      />
    </v-dialog>

    <v-overlay :z-index="zIndex" :value="overlay">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import NewInterestForm from "./NewInterestForm.vue";


export default {
  components: { NewInterestForm },
  data() {
    return {
      newInterestFormKey: 2,
      zIndex: 0,
      overlay: true,
      newInterestFormDialog: false,
      reverse: null,
    };
  },
  setup() {},
  computed: {
    boardInterestList() {
      return this.$store.state.InterestStore.interestList;
    },
  },
  watch: {
    boardInterestList: function () {
      this.overlay = false;
      this.newInterestFormKey++;
      this.newInterestFormDialog = false;
    },
  },
  async mounted() {
    this.$store.dispatch("showLoadingSpinner", true);

    await this.$store.dispatch("loadBoardInterests");
    this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>
