<template>
  <v-container fluid>
    <v-row class="mt-5 mx-3">
      <v-spacer></v-spacer>
      <button
        color="indigo"
        class="btn btn-primary shadow-lg mr-2 white--text"
        outlined
        @click="openLocationDialogFunction(`Add Meeting Location`, null, `Add`)"
      >
        <v-icon class="white--text">mdi-map-marker-plus-outline</v-icon> New
        Location
      </button>
    </v-row>

    <div>
      <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
        <div class="intro-y inbox box mt-5">
          <div
            class="p-5 flex flex-col-reverse sm:flex-row text-slate-500 border-b border-slate-200/60"
          >
            <div class="flex items-center sm:ml-auto">
              <div class="" style="font-size: 20px">
                Meeting Venues / Locations
              </div>
            </div>
          </div>
          <div class="overflow-x-hidden sm:overflow-x-hidden">
            <div class="overflow-x-auto ma-7">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th class="whitespace-nowrap">#</th>
                    <th class="whitespace-nowrap">Name</th>
                    <th class="whitespace-nowrap">Physical Address</th>
                    <th class="whitespace-nowrap">Time Zone</th>
                    <th class="whitespace-nowrap">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in boardMeetingLocation"
                    :key="data.id"
                    class="intro-y"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.address }}</td>
                    <td>{{ data.timezone }}</td>
                    <td>
                      <div
                        v-if="hasAccess('can_manage_settings')"
                        class="flex"
                      >
                        <v-btn 
                        
                        class="ma-1"
                        color="info"
                        icon
                        small


                          @click="
                            openLocationDialogFunction(
                              `Edit Meeting Location`,
                              data,
                              `Edit`
                            )
                          "
                        >
                          <v-icon>mdi-square-edit-outline</v-icon>
                        </v-btn>
                        <v-btn 
                        
                        class="ma-1"
                        color="pink"
                        icon
                        small

                         @click="deleteLocation(data.id)" >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </div>
                      <div v-else>No Edit or Delete</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="p-5 flex flex-col sm:flex-row items-center text-center sm:text-left text-slate-500"
          >
            <div class="sm:ml-auto mt-2 sm:mt-0"></div>
          </div>
        </div>
      </div>
    </div>

    <LocationSettings
      :action="action"
      :title="title"
      :location="location"
      :openLocationDialog="openLocationDialog"
      @closeNewLocationDialog="openLocationDialog = !openLocationDialog"
    />
  </v-container>
</template>

<script>
import LocationSettings from "./LocationSettings.vue";
export default {
  components: { LocationSettings },

  data() {
    return {
      categoryname: null,
      meetingtype: null,
      openLocationDialog: false,
      items: [
        { name: "Board Meeting", value: "board" },
        { name: "Management Meeting", value: "management" },
      ],
      createCategoryDialog: false,
      createBoard: false,
      search: "",
      headers: [
        {
          text: "S/N",
          align: "start",
          sortable: true,
          value: "serial_number",
          filterable: false,
        },
        {
          text: "Location Name",
          align: "start",
          value: "name",
        },
        { text: "Address", value: "address" },
        { text: "Timezone", value: "timezone" },
        { text: "Created", value: "date" },
        { text: "Actions", value: "actions" },
      ],
      title: "",
      location: "",
      action: "",
    };
  },

  computed: {
    boardMeetingLocation() {
      const locations = this.$store.state.SettingsStore.boardLocationsList;
      const locations_list = [];
      let count = 1;
      locations.forEach((location) => {
        locations_list.push({
          serial_number: count,
          name: location.location,
          address: location.locationAddress,
          timezone: location.locationTimezone,
          default: location.locationTimezone,
          actions: location.key,
          date: location.locationCreateddate,
          id: location.id,
        });
        count++;
      });
      return locations_list;
    },
  },

  methods: {
    async deleteCategory(categoryKey) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("deleteMeetingCategory", categoryKey);
      await this.$store.dispatch("loadBoardMeetingCategories");
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    openLocationDialogFunction(title, location, action) {
      this.title = title;
      this.location = location;
      this.openLocationDialog = true;
      this.action = action;
    },

    async deleteLocation(locationUniqueId) {
      const location = {
        locationIsActive: false,
        locationUniqueId: locationUniqueId,
      };
      await this.$store.dispatch("SettingsStore/deleteLocation", location);
      await this.$store.dispatch("SettingsStore/loadBoardMeetingLocations", {
        root: true,
      });
    },
  },

  async mounted() {
    await this.$store.dispatch("SettingsStore/loadBoardMeetingLocations", {
      root: true,
    });
  },
};
</script>

<style></style>
