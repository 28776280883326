<template>
  <v-container fluid>
    <ToolBar />

    <v-row class="mt-5 mx-3">
      <v-spacer></v-spacer>
      <v-btn
        color="indigo"
        class="mx-2 text-capitalize"
        dark
        elevation="2"
        @click="createUser = true"
      >
        <v-icon small>mdi-account-plus</v-icon> &nbsp; Create User</v-btn
      >
      <v-spacer></v-spacer>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-dialog v-model="createUser" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Create User</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="First name*" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Middle name"
                    hint="This field is optional"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Last name*" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Email*" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password*"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="createUser = false">
              Close
            </v-btn>
            <v-btn color="#3f51b5" @click="createUser = false" dark>
              Create User
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="mt-5 mx-2">
      <v-spacer></v-spacer>
      <v-col sm="12" md="4" lg="3">
        <v-alert
          class="rounded-lg pa-5"
          elevation="2"
          border="left"
          icon="mdi-clipboard"
          prominent
          colored-border
          color="success"
        >
          <v-row class="text-h3 d-flex justify-center"> 230 </v-row>
          <v-row class="d-flex justify-center"> Active users </v-row>
        </v-alert>
      </v-col>
      <v-col sm="12" md="4" lg="3">
        <v-alert
          class="rounded-lg pa-5"
          elevation="2"
          border="left"
          icon="mdi-account-group"
          prominent
          colored-border
          color="#aaa"
        >
          <v-row class="text-h3 d-flex justify-center"> 23 </v-row>
          <v-row class="d-flex justify-center"> Inactive Users </v-row>
        </v-alert>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="px-5">
      <v-spacer></v-spacer>
      <v-col md="10">
        <template>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :items-per-page="5"
            class="elevation-1"
          ></v-data-table>
        </template>
      </v-col>

      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import ToolBar from "./settings-navigations/Toolbar.vue";

export default {
  data() {
    return {
      createUser: false,
      createBoard: false,
      e6: [],
      e7: [],
      names: [
        "Fredrick Chami",
        "Jackson Bakari",
        "Germanus Xavier",
        "Martin Blasi",
      ],
      headers: [
        {
          text: "S/N",
          align: "start",
          sortable: true,
          value: "serial_number",
        },
        {
          text: "Full Name",
          align: "start",
          sortable: false,
          value: "name",
        },
      ],
      desserts: [
        {
          serial_number: 1,
          name: "Fredrick Chami",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          serial_number: 2,
          name: "John Smith",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
        {
          serial_number: 12,
          name: "Somebody Else",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          serial_number: 13,
          name: "Another Person",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          serial_number: 14,
          name: "Rachel Samwel",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          serial_number: 15,
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          serial_number: 16,
          name: "Lollipop Pop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
        {
          serial_number: 17,
          name: "Honey Comb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%",
        },
        {
          serial_number: 18,
          name: "Donut Burger",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%",
        },
        {
          serial_number: 19,
          name: "KitKat Nougat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%",
        },
      ],
    };
  },
  components: {
    ToolBar,
  },
};
</script>

<style>
</style>






