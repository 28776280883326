<template>
  <v-row justify="center">
    <v-dialog v-model="CommitteeMembersDialog" persistent max-width="70%">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-account-multiple-outline</v-icon> Committee
            Members</span
          >
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <template v-for="item in membersList">
            <v-list-item :key="'member' + item.memberUserId">
              <v-list-item-content>
                <v-list-item-title
                  v-html="item.memberFirstName + '  ' + item.memberLastName"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>
                  <v-btn
                    color="rgba(36, 70, 191, 0.66)"
                    depressed
                    rounded
                    x-small
                    class="ma-3"
                    v-if="!item.memberIsLeader"
                    @click="makeMemberAsLeader(item)"
                  >
                    <v-icon x-small color="white">mdi-account-star</v-icon>
                    <span class="text-capitalize white--text ml-1"
                      >Assign as leader</span
                    >
                  </v-btn>
                  <v-btn
                    v-else
                    color="success"
                    depressed
                    rounded
                    x-small
                    class="ma-3"
                    >Is Leader</v-btn
                  >

                  <v-btn
                    color="rgba(15, 201, 98, 0.11)"
                    depressed
                    rounded
                    x-small
                    class="ma-3"
                    v-if="item.memberIsActive"
                  >
                    <v-icon x-small color="success">mdi-check</v-icon>
                    <span class="text-capitalize success--text">Active</span>
                  </v-btn>

                  <v-btn
                    color="error"
                    depressed
                    rounded
                    x-small
                    class="ma-3"
                    v-else
                  >
                    <v-icon x-small color="">mdi-close</v-icon>
                    <span class="text-capitalize">Inactive</span>
                  </v-btn>

                  <v-btn
                    depressed
                    icon
                    @click="activateCommitteeMember(item.memberUserId)"
                    v-if="!item.memberIsActive"
                  >
                    <v-icon small color="success">mdi-check</v-icon>
                  </v-btn>

                  <v-btn
                    depressed
                    icon
                    @click="deactivateCommitteeMember(item.memberUserId)"
                    v-else
                  >
                    <v-icon small color="error">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="item.memberUserId"></v-divider>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            fab
            small
            class="white--text"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["CommitteeMembersDialog", "membersList", "committeeUniqueId"],
  data: () => ({}),

  methods: {
    async deactivateCommitteeMember(memberUserId) {
      const committeeObj = {
        memberUserId: memberUserId,
        committeeUniqueId: this.committeeUniqueId,
      };
      await this.$store.dispatch("deactivateCommitteeMember", committeeObj);
      var member = this.membersList.find(
        (memb) => memb.memberUserId === memberUserId
      );
      member.memberIsActive = false;
    },

    async activateCommitteeMember(memberUserId) {
      const committeeObj = {
        memberUserId: memberUserId,
        committeeUniqueId: this.committeeUniqueId,
      };

      await this.$store.dispatch("activateCommitteeMember", committeeObj);
      var member = this.membersList.find(
        (memb) => memb.memberUserId === memberUserId
      );
      member.memberIsActive = true;
    },
  async makeMemberAsLeader(member) {
    this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("makeMemberAsLeader",{memberUserId:member.memberUserId,committeeUniqueId:this.committeeUniqueId});

    const memberLeaderFound = this.membersList.find(member=>member.memberIsLeader === true)
    if(memberLeaderFound){
      memberLeaderFound.memberIsLeader=false
    }

    const memberFound = this.membersList.find(member=>member.memberUserId === member.memberUserId)
    if(memberFound){
      memberFound.memberIsLeader=true
    }
    
    this.$store.dispatch("showLoadingSpinner", false);
  },
  },

};
</script>
