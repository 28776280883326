<template>
  <div >
    <AdminPanelToolBar/>
    <div style="padding:20px">
      <router-view/>
    </div>
  </div>
</template>

<script>

import AdminPanelToolBar from "@/modules/administration-panel/admin-panel-shared/AdminPanelToolBar.vue";

export default {
  name: 'AdminPanelHome',
  components: {
    AdminPanelToolBar,
  },
}
</script>