<template>
  <v-app id="inspire">

    <v-parallax dark src="@/assets/img/loginback.png" height="893">

    <v-content  class="intro-y">
      <v-container class="intro-x fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="8">
            <v-card class="elevation-12">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row>
                    <v-col  cols="12" md="4" style="background:#3f51b5;">
                      <v-card-text class="white--text mt-12" >
                        <v-img
                           max-width="100%"
                           src="/loginback.png"
                        ></v-img>
                        <h1 class="text-center display-1" style="color:white">Welcome Back!</h1>
                        <h5
                          class="text-center"
                        >Voting in e-Board helps you to actively engage and provides focus for important decision-making.</h5>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn rounded outlined dark to="/landing_page">Home Page</v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-card-text class="mt-12">
                        <h1
                          class="text-center display-2 indigo--text text--accent-3"
                        >Sign in to e-Board</h1>
                        <div class="text-center mt-4">
                          <v-btn class="mx-2" fab color="black" outlined>
                            <v-icon>mdi-gmail</v-icon>
                          </v-btn>

                          <v-btn class="mx-2" fab color="black" outlined>
                            <v-icon>mdi-email-fast</v-icon>
                          </v-btn>
                          <v-btn class="mx-2" fab color="black" outlined>
                            <v-icon>mdi-account-box-outline</v-icon>
                          </v-btn>
                        </div>
                        <h4 class="text-center mt-4">Login with your provided credentials</h4>
                        {{this.$router.params}}
                        <v-form>
                          <v-text-field
                            label="Email"
                            name="Email"
                            v-model="username"
                            prepend-icon="mdi-email"
                            type="email"
                            color="#3f51b5"
                          />

                          <v-text-field
                            id="password"
                            v-model="password"
                            label="Password"
                            name="password"
                            prepend-icon="mdi-lock"
                            type="password"
                            color="#3f51b5"
                            @keypress.enter="userLogin"
                          />
                        </v-form>
                        <h3 style="cursor:pointer"  @click="step++" class="text-center mt-4">Forgot your password ?</h3>
                      </v-card-text>
                      <div class="text-center mt-3 mb-2">
                        <v-btn @click="userLogin" rounded color="#3f51b5" dark>SIGN IN</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :value="2">
                  <v-row class="fill-height">
                    <v-col cols="12" md="4" style="background:#3f51b5;">
                      <v-card-text class="white--text mt-12">
                        <v-img
                           max-width="100%"
                           src="/boardpacks.png"
                        ></v-img>
                        <h1 class="text-center display-1" style="color:white">Board Packs</h1>
                        <h5
                          class="text-center"
                        >Automatically combine your meeting papers into an easy to read board pack using e-Board board pack builder.</h5>
                      </v-card-text>
                      <div class="text-center mb-4">
                        <v-btn rounded outlined dark @click="step--">Sign in</v-btn>
                      </div>
                    </v-col>

                    <v-col cols="12" md="8">
                      <v-card-text class="mt-12">
                        <h1 class="text-center display-2 indigo--text text--accent-3">Reset Password</h1>
                        <div class="text-center mt-4">
                          <v-btn class="mx-2" fab color="black" outlined>
                            <v-icon>mdi-gmail</v-icon>
                          </v-btn>

                          <v-btn class="mx-2" fab color="black" outlined>
                            <v-icon>mdi-email-fast</v-icon>
                          </v-btn>
                          <v-btn class="mx-2" fab color="black" outlined>
                            <v-icon>mdi-email</v-icon>
                          </v-btn>
                        </div>
                        <h4 class="text-center mt-4">We'll send you a reset link to your email</h4>
                        <v-form>
                          <v-text-field
                            label="Email"
                            name="Email"
                            v-model="email"
                            prepend-icon="mdi-email"
                            type="text"
                            color="#3f51b5"
                          />
                        </v-form>
                      </v-card-text>
                      <div class="text-center mt-n5 mb-4">
                        <v-btn @click="sendPasswordResetEmail" rounded color="#3f51b5" dark>Send Link</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    </v-parallax>
   <v-overlay
      :z-index="zIndex"
      :value="overlay"
    >

        <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        ></v-progress-circular>


    </v-overlay>
  </v-app>
</template>



<script>
import { mapActions } from 'vuex'

export default {
   name: 'Login',
   props: {
      source: String,
   },
   data(){
      return{
         step: 1,
         zIndex: 0,
         overlay:false,
         username:'',
         password:'',
         authDetails:'',
         email: null,
      }
   },

   methods:{
      ...mapActions({
        authenticateUser:'AuthStore/authenticateUser'
      }),
       userLogin: async function () {
         this.$store.dispatch('showLoadingSpinner',true)
         this.authDetails={
            'username':this.username,
            'password':this.password
         }
         await this.authenticateUser(this.authDetails)
         this.$store.dispatch('showLoadingSpinner',false)
      },

      async sendPasswordResetEmail() {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("sendPasswordResetEmail", this.email);
      this.email=null
      this.$store.dispatch("showLoadingSpinner", false);

    },

   },
   mounted(){
     var token=localStorage.getItem('apollo-token') || null
     if(token){
        this.$router.push('/')
     }
   }
};
</script>

<style></style>
