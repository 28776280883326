<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col class="text-lg font-weight-medium">Institution Categories</v-col>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="newInstitutionCategoryDialog=true"
              v-bind="attrs"
              v-on="on"
              color="indigo"
              class="mx-2"
              dark
              outlined
              small
          >
            <v-icon small>mdi-plus</v-icon> &nbsp; New Category</v-btn
          >

        </template>
        <span>Institution Category</span>
      </v-tooltip>
    </v-row>

    <v-row>
      <div class="mt-3 w-full">
        <table class="p-3 w-100 table table-hover bg-white">
          <thead>
          <tr class="text-white bg-primary">
            <th class="whitespace-nowrap">#</th>
            <th class="whitespace-nowrap">Category Name</th>
            <th class="whitespace-nowrap">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(category, index) in getInstitionCategories"
              :key="category.id + index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ category.categoryName }}</td>
            <td>
              <v-tooltip bottom color="warning">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" @click="editInstitutionCategory(category)" class="btn btn-warning btn-sm mr-2" type="button">
                    <icon name="edit" width="w-5" height="w-5" color="#ffffff"></icon>
                  </div>
                </template>
                <span>Edit Category</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </v-row>
    <add-institution-category
      @close-dialog="newInstitutionCategoryDialog=false"
      :new-institution-category-dialog="newInstitutionCategoryDialog"
    >
    </add-institution-category>

    <edit-institution-category
      @close-dialog="editInstitutionCategoryDialog=false"
      :edit-institution-category-dialog="editInstitutionCategoryDialog"
      :category-name="categoryName"
      :category-unique-id="categoryUniqueId"
    >
    </edit-institution-category>
  </v-container>
</template>

<script>
import EditInstitutionCategory
  from "@/modules/administration-panel/admin-panel-institution-category/EditInstitutionCategory.vue";
import AddInstitutionCategory
  from "@/modules/administration-panel/admin-panel-institution-category/AddInstitutionCategory.vue";
import Icon from "@/views/Icon.vue";

export default {
  name: "AdminPanelInstitutionCategory",
  components: {Icon, AddInstitutionCategory, EditInstitutionCategory},

  props: [
      "categoryName",
      "categoryUniqueId",
      "newInstitutionCategoryDialog",
      "editInstitutionCategoryDialog"
  ],

  data: () => ({
    categoryName: "",
    categoryUniqueId: ""
  }),

  computed: {
    getInstitionCategories() {
      return this.$store.getters.getInstitutionCategoryList
    }
  },

  methods: {
    editInstitutionCategory(category) {
      this.categoryUniqueId = category.categoryUniqueId
      this.categoryName = category.categoryName

      this.editInstitutionCategoryDialog = true
    },
  },

  async mounted(){
    await this.$store.dispatch('loadInstitutionCategoryLists')
  },

}
</script>

<style scoped>

</style>