<template>
  <v-container fluid>
    <v-row justify="center">
      <v-expansion-panels accordion>
        <v-expansion-panel
            v-for="(committeeStructure,index) in boardCommitteeStructure"
            :key="index"
        >
          <v-expansion-panel-header>
            <div class="flex">
              <span>Committee: </span>
              <span class="font-medium ml-2">
                {{ committeeStructure.committeeName }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="12" v-if="committeeStructure.committeeChairperson">
                <v-layout justify-space-between wrap>
                  <v-card
                      style="margin: 5px; padding: 5px"
                      class="mx-auto intro-x"
                      max-width="30%"
                      outlined
                  >
                    <v-list-item three-line>
                      <div class="flex flex-col lg:flex-row items-center p-2">
                        <div class=" mr-3 w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                          <img
                              v-if="committeeStructure.committeeChairperson.memberPhoto"
                              :alt="committeeStructure.committeeChairperson.memberFirstName"
                              class="rounded-full"
                              :src="MEDIA_SERVER + committeeStructure.committeeChairperson.memberPhoto"
                          />

                          <vue-initials-img
                              v-bind="attrs"
                              v-on="on"
                              class="rounded-full"
                              v-else
                              :name="committeeStructure.committeeChairperson.memberFirstName+' '+committeeStructure.committeeChairperson.memberLastName"
                          />
                        </div>
                        <v-list-item-content>
                          <div class="mb-4" style="color: green">
                            <v-chip small class="btn success py-1 px-2 mr-2"
                            v-if="committeeStructure.committeeChairperson.memberIsLeader">

                              CHAIRPERSON
                            </v-chip>
                          </div>
                          <v-list-item-title class="text-h7 mb-1" style="color: #3f51b5">
                            <strong
                            >{{ committeeStructure.committeeChairperson.memberFirstName }}
                              {{ committeeStructure.committeeChairperson.memberLastName }}</strong
                            >
                          </v-list-item-title>
                          <v-list-item-subtitle style="color: #3f51b5"
                          >{{ committeeStructure.committeeChairperson.memberEmail }}
                          </v-list-item-subtitle>
                        </v-list-item-content>


                      </div>
                    </v-list-item>
                  </v-card>
                </v-layout>
              </v-col>
            </v-row>


            <v-row>
              <v-col md="12">
                <div class="grid grid-cols-12 gap-6 mt-5">
                  <div
                      class="intro-y col-span-12 md:col-span-6"
                      v-for="(member, index) in committeeStructure.committeeMembers"
                      :key="index"
                  >
                    <div class="box">
                      <div class="flex flex-col lg:flex-row items-center p-5">
                        <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                          <img
                              v-if="member.memberPhoto"
                              :alt="member.memberFirstName"
                              class="rounded-full"
                              :src="MEDIA_SERVER + member.memberPhoto"
                          />

                          <vue-initials-img
                              v-bind="attrs"
                              v-on="on"
                              class="rounded-full"
                              v-else
                              :name="member.memberFirstName"
                          />
                        </div>
                        <div
                            class="w-30 lg:ml-2 lg:mr-auto text-left lg:text-left mt-3 lg:mt-0"
                        >
                  <span class="font-medium info--text"
                  >{{ member.memberFirstName }}
                    {{ member.memberLastName }}</span
                  >
                          <div class="text-slate-500 text-xs mt-0.5">
                            {{ member.memberEmail }}
                          </div>
                        </div>
                        <div class="w-30 flex-none flex items-center mr-5 mt-4 lg:mt-0">
                          <div
                              v-if="!member.memberIsLeader"
                              class="btn btn-primary py-1 px-2 mr-2"
                          >
                            MEMBER
                          </div>
                          <button
                              v-if="!member.memberIsDeactivated"
                              class="btn btn-outline-success py-1 px-2"
                          >
                            Active
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>


  </v-container>
</template>

<script>
export default {
  name: "BoardCommitteeStructure",

  data: () => ({}),

  computed: {
    boardCommitteeStructure() {
      return this.$store.getters.getBoardCommitteeStructures;
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardCommitteeStructures");
  },
};
</script>

<style></style>
