<template>
  <v-row justify="center">
    <v-dialog v-model="isPostponeMeeting" persistent max-width="1000px">
      <v-card>
        <v-card-title>
        <span class="text-h6"
        ><v-icon>mdi-account-group-outline</v-icon> Postpone/Reschedule Meeting </span
        >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="meetingObj.meetingDate"
                      label="Date *"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(meetingDate)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="meetingObj.meetingDate"
                    :min="date"
                    no-title
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-dialog
                  ref="dialog1"
                  v-model="modal1"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="meetingObj.meetingStartTime"
                      label="Start Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker  v-if="modal1" v-model="meetingObj.meetingStartTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col
                cols="12"
                md="4"
                sm="6"
              >
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="meetingObj.meetingEndTime"
                      label="End Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="modal2"
                    v-model="meetingObj.meetingEndTime"
                    :min="meetingObj.meetingStartTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="meetingObj.meetingLocation"
                  :hint="timezone"
                  :items="locations"
                  item-value="id"
                  item-text='location'
                  label="Location *"
                  persistent-hint
                  prepend-icon="mdi-map-marker"
                  required
                  return-object
                  @change="getSelectedTimeZone"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip small text-color="">{{ item.location }}</v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              outlined
              small
              class="white--text"
              @click="closePostponeMeeting"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              outlined
              small
              class="white--text"
              :disabled="loading"
              :loading="loading"
              @click="postponeBoardMeeting"
          >
            <v-icon>mdi-send-check-outline</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: ["isPostponeMeeting"],

  data: () => ({
    loading: false,
    timezone: null,
    defaultSelected: '',
    time: null,
    menu: false,
    modal2: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    dialog1: false,
    modal1: false,
    meetingObj: {
      meetingManagement: localStorage.getItem('my-managementUniqueId'),
      meetingUniqueId: null,
      meetingTitle: null,
      meetingDate: null,
      meetingStartTime: null,
      meetingEndTime: null,
      meetingLocation: null,
      meetingCategory: null,
    }
  }),

  async mounted() {
    await this.$store.dispatch("loadBoardMeetingLocations");
  },

  watch: {
    isPostponeMeeting(value) {
      if (value) {
        this.meetingObj.meetingUniqueId = this.selectedMeetingDetails.meetingUniqueId;
        this.meetingObj.meetingTitle = this.selectedMeetingDetails.meetingTitle;
        this.meetingObj.meetingDate = this.selectedMeetingDetails.meetingDate;
        this.meetingObj.meetingStartTime = this.selectedMeetingDetails.meetingStartTime;
        this.meetingObj.meetingEndTime = this.selectedMeetingDetails.meetingEndTime;
        this.meetingObj.meetingLocation = this.selectedMeetingDetails.meetingLocation;
        this.meetingObj.meetingCategory = this.selectedMeetingDetails.meetingCategory;
      }
    }
  },

  computed: {
    locations() {
      return this.$store.state.SettingsStore.boardLocations;
    },
    selectedMeetingDetails() {
      return this.$store.getters.getManagementMeetingDetails;
    }
  },

  methods: {
    closePostponeMeeting() {
      this.meetingObj = {
        meetingManagement: null,
        meetingUniqueId: null,
        meetingTitle: null,
        meetingDate: null,
        meetingStartTime: null,
        meetingEndTime: null,
        meetingLocation: null,
        meetingCategory: null,
      };
      this.timezone = null;
      this.$emit("closePostponeMeeting");
    },
    
    getSelectedTimeZone() {
      this.timezone = this.meetingObj.meetingLocation.locationTimezone;
    },

    async postponeBoardMeeting() {
      this.meetingObj.meetingCategory = this.meetingObj.meetingCategory?.id;
      this.meetingObj.meetingLocation = this.meetingObj.meetingLocation?.id || this.meetingObj.meetingLocation;
      await this.$store.dispatch('updateManagementMeeting', this.meetingObj);
      this.closePostponeMeeting();
    },
  },
};
</script>

<style scoped>

</style>
