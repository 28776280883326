<template>
  <v-container app class="my-5" fluid>
    <div class="flex justify-content-center padding">
      <div class="flex justify-content-between full-width">

        <v-layout justify-space-around row wrap>
          <v-btn
            :to="'/management/management_account'+'/'+this.$store.state.CurrentMeetingStore.currentMeeting+'/Board'"
            color="primary" link outlined rounded small
          >
            <span class="flex center"><v-icon>mdi-arrow-left</v-icon> Back </span>
          </v-btn>

          <v-flex></v-flex>

          <v-menu v-if="listOfagendas.length >0" app offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn slot="activator" class="mr-1" color="primary" outlined rounded small v-bind="attrs" v-on="on"><span
                class="flex center">
                    <v-icon>mdi-file-table-outline</v-icon>
                        &nbsp; Agenda &nbsp;<v-icon>mdi-chevron-down</v-icon>
                </span>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item v-if="hasAccess('can_add_management_meeting')" @click="AgendaBuilderWindow = true">
                <v-list-item-title>
                  <v-icon>mdi-archive-eye-outline</v-icon>
                  Open
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="this.$store.state.ManagementMeetingsStore.selectedManagementMeetingDetails.meetingHasPublishedAgenda"
                @click="previewAgendas">
                <v-list-item-title>
                  <v-icon>mdi-open-in-new</v-icon>
                  Preview
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu v-if="meetingDetails.meetingHasPublishedAgenda && (this.$store.state.MeetingsStore.selectedMeetingDetails || {}).meetingMinutesStarted" app offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn slot="activator" class="mr-1" color="primary" outlined rounded small v-bind="attrs" v-on="on"><span
                class="flex center"><v-icon style="height: 18px;font-size: 18px;">mdi-note-multiple</v-icon>
                                              &nbsp; minutes &nbsp;<v-icon>mdi-chevron-down</v-icon>
                                      </span>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="previewMinutes">
                <v-list-item-title>
                  <v-icon style="height: 18px;font-size: 18px;">mdi-open-in-new</v-icon>
                  Preview
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tooltip bottom color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="hasAccess('can_add_management_meeting') && !meetingDetails.meetingHasPublishedAgenda" @click="postPoneMeeting" class="mr-1"
                     color="warning white--text" outlined right
                     rounded
                     small v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-calendar-weekend</v-icon>
              </v-btn>
            </template>
            <span>Postpone or Re-Schedule Meeting</span>
          </v-tooltip>

          <v-tooltip bottom color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="hasAccess('can_add_management_meeting') && !meetingDetails.meetingHasPublishedAgenda" @click="showContextMenu=true" class="mr-1"
                     color="info white--text" outlined right
                     rounded
                     small v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit meeting details</span>
          </v-tooltip>

        </v-layout>

      </div>
    </div>

    <div class="flex justify-content-center padding">
      <div class="flex full-width" style="padding-top: 10px; padding-bottom: 10px">
        <v-layout align-content-center row wrap>
          <v-flex>
            <h2 style="font-size: x-large">{{ meetingDetails.meetingTitle }}</h2>
          </v-flex>

          <v-flex></v-flex>

          <div v-if="hasAccess('can_add_management_meeting')">
            <div class="flex flex-align-items-center">
              <v-tooltip v-if="listOfagendas.length <1" bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="white--text"
                    color="primary white--text"
                    outlined
                    right rounded small style="margin:5px" title="" v-bind="attrs"
                    @click="builderModeDialogy = true"
                    v-on="on">
                    <v-icon>mdi-file-table-outline</v-icon>
                    Build Agenda
                  </v-btn>
                </template>
                <span>Build Agenda</span>
              </v-tooltip>

              <v-tooltip v-if="meetingDetails.meetingHasPublishedAgenda" bottom color="blue">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="blue white--text" outlined right rounded small style="margin:5px" title=""
                         v-bind="attrs"
                         @click="TakeminutesWindow = true" v-on="on">
                    <v-icon>mdi-script-text</v-icon>
                    Take Minutes
                  </v-btn>
                </template>
                <span>Take Meeting Minutes</span>
              </v-tooltip>
            </div>
          </div>
        </v-layout>
      </div>
    </div>

    <div>
      <v-layout justify-space-around row wrap>
        <v-card class="full-width">
          <v-simple-table>
            <template>
              <thead>
              <tr>
                <th class="text-left" style="width:10%">

                </th>
                <th class="text-left" style="width:80%">

                </th>
              </tr>
              </thead>
              <tbody>

              <tr>
                <td><strong left>Date:</strong></td>
                <td>{{ new Date(meetingDetails.meetingDate).toDateString() }}</td>
              </tr>

              <tr>
                <td><strong left>Time:</strong></td>
                <td>
                  <span> {{ meetingDetails.meetingStartTime }} -</span>
                  <span> {{ meetingDetails.meetingEndTime }}</span>
                </td>
              </tr>

              <tr>
                <td><strong left>Location:</strong></td>
                <td>
                  <span>
                      {{  meetingDetails.meetingLocation.locationName }}
                      ({{ meetingDetails.meetingLocation.locationAddress }})
                  </span>
                </td>
              </tr>

              <tr>
                <td><strong>Meeting Administrator:&nbsp;</strong></td>
                <td>
                  <span>{{ meetingDetails.meetingCreatedBy }}</span>
                </td>
              </tr>

              <tr>
                <td><strong>Video Url:&nbsp;</strong></td>
                <td>
                  <div class="flex">
                    <v-btn
                        v-if="hasAccess('can_add_management_meeting') && !meetingDetails.hasMeetingUrl"
                        color="primary"
                        text
                        x-small
                        @click="addVideoMeeting = !addVideoMeeting"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Add Meeting URL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-capitalize mr-1"
                        @click="
                          sendLinktoInvitees
                        "
                        v-if="meetingDetails.hasMeetingUrl"
                        color="success"
                        outlined
                        rounded
                    >
                      Send Video link to Meeting Invitees
                      <v-icon>mdi-link-variant</v-icon>
                    </v-btn>
                    <v-btn
                        class="text-capitalize"
                        @click="
                          openVideoCallScreen
                        "
                        v-if="meetingDetails.hasMeetingUrl && meetingDetails.meetingUrlIsToken"
                        color="indigo"
                        outlined
                        elevation="0"
                    >
                      Join e-Mikutano Meeting <v-icon>mdi-video</v-icon>
                    </v-btn>
                    <v-btn
                        class="text-capitalize"
                        @click="
                          openVideoCallScreen
                        "
                        v-else-if="meetingDetails.hasMeetingUrl && !meetingDetails.meetingUrlIsToken"
                        color="indigo"
                        outlined
                        elevation="0"
                    >
                      Join Meeting <v-icon>mdi-video</v-icon>
                    </v-btn>
                  </div>
                </td>
              </tr>

              <tr v-if="hasAccess('can_add_management_meeting')">
                <td>
                  <strong>Attendees/Apologies: &nbsp;</strong>
                  <span
                  ><small color="orange"
                  >NOTE: You can drag and move people between Attendees or
                        Apologies.</small
                  ></span
                  >
                </td>
                <td>
                  <v-row>
                      <v-col
                        md="12"
                        xs12
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                      >
                        <v-menu
                          v-if="hasAccess('can_add_management_meeting')"
                          app
                          offset-y
                          style="padding-bottom:2px;"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="unConfirmMembers.length > 0"
                              slot="activator"
                              color="indigo"
                              small
                              rounded
                              style="text-transform: capitalize; border-radius: 5px; padding:2px; border: 1px solid #9da6a68a; border-style: dotted;"
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon style="padding: 2px;" small>mdi-account-plus-outline</v-icon>
                              Add from Management List
                            </v-btn>
                            <v-btn
                              v-else
                              color="indigo"
                              small
                              style="text-transform: capitalize"
                              text
                              @click="addPeopleDialog = true"
                            >
                              <v-icon small>mdi-account-plus-outline</v-icon>
                              Add Members
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item
                              v-for="(people, index) in unConfirmMembers"
                              :key="index.id"
                              link
                            >
                              <v-list-item-title @click="addAtendees(people)"
                                >{{ people.userProfileTitle }}.
                                {{ people.firstName }}
                                {{ people.lastName }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>

                    <v-col
                        md="6"
                        xs12
                        @drop="onDrop($event, true)"
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                      >
                      <div style="border-radius: 5px; padding:2px; border: 1px solid #9da6a6b6; border-style: dotted;width: 100%;height: 100%;background-color:rgba(217, 229, 219, 0.54)">
                        <v-subheader color="primary">
                          <strong>Attendees:</strong>
                        </v-subheader>
                        <draggable
                          v-model="atendees"
                          :options="{ group: 'people' }"
                          style="min-height: 10px"
                        >
                          <template v-for="item in meetingAtendees">
                            <v-chip
                              :id="item.id"
                              :key="item.id"
                              :draggable="draggable"
                              class="ma-2"
                              height="31"
                              width="88"
                              @dragstart="startDrag($event, item)"
                              @mouseleave="c_index = -1"
                              @mouseover="c_index = index"
                              @drag.prevent="drag"
                            >
                              {{ item.name }}
                              <v-icon style="margin-left: 10px;"  right
                                >mdi-drag-vertical
                              </v-icon>
                            </v-chip>
                          </template>
                        </draggable>
                        </div>
                      </v-col>

                      <v-col
                        md="6"
                        xs12
                        @drop="onDrop($event, false)"
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                      >
                      <div style="border-radius: 5px; padding:2px; border: 1px solid #9da6a68a; border-style: dotted;width: 100%;height: 100%;background-color:rgba(229, 221, 217, 0.54)">
                        <v-subheader>
                          <strong>Apologies: </strong>
                        </v-subheader>
                        <draggable style="min-height: 10px;">
                          <template v-for="item in meetingApologies">
                            <v-chip
                              :id="item.id"
                              :key="item.id"
                              :draggable="draggable"
                              class="ma-2"
                              height="31"
                              width="88"
                              @dragstart="startDrag($event, item)"
                              @mouseleave="c_index = -1"
                              @mouseover="c_index = index"
                              @drag.prevent="drag"
                            >
                              {{ item.name }}
                              <v-icon style="margin-left: 10px;" right
                                >mdi-drag-vertical
                              </v-icon>
                            </v-chip>
                          </template>
                        </draggable>

                      </div>
                      </v-col>

                  </v-row>
                </td>
              </tr>

              <tr>
                <td><strong>Meeting Management:&nbsp;</strong></td>
                <td>
                  <div class="row my-2">
                    <div class="col-md-4 bg-slate-400 border-r">
                      <div class="text-gray-600 font-medium">Chair Person</div>
                      <div class="flex justify-content-between">
                        <template v-if="chairPersonPositionViewType === 'VIEW'">
                          <div class="text-primary font-bold py-2">
                            {{ chairPerson.name}}
                          </div>
                          <div class="text-gray-700 py-2 cursor-pointer border rounded-md pl-2 pr-3 edit-btn"
                               v-if="hasAccess('can_add_management_meeting')"
                               @click="chairPersonPositionViewType = 'ADD'">
                            <v-icon style="font-size: 15px;">mdi-pencil</v-icon>
                            Edit
                          </div>
                        </template>
                        <v-select v-else
                                  class="mt-2"
                            :items="meetingAtendees"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="Select Chair Person"
                            outlined
                            dense
                            v-model="chairPositionedUser"
                            @change="updateMeetingPositions(chairPositionedUser,'Chair Person')"
                        ></v-select>
                      </div>
                    </div>

                    <div class="col-md-4 bg-slate-400 border-r">
                      <div class="text-gray-600 font-medium">Secretary</div>
                      <div class="flex justify-content-between">
                        <template v-if="secretaryPositionViewType === 'VIEW'">
                          <div class="text-primary font-bold py-2">
                            {{ secretary.name}}
                          </div>
                          <div class="text-gray-700 py-2 cursor-pointer border rounded-md pl-2 pr-3 edit-btn"
                               v-if="hasAccess('can_add_management_meeting')"
                               @click="secretaryPositionViewType = 'ADD'">
                            <v-icon style="font-size: 15px;">mdi-pencil</v-icon>
                            Edit
                          </div>
                        </template>
                        <v-select v-else
                                  class="mt-2"
                            :items="meetingAtendees"
                            :item-text="'name'"
                            :item-value="'id'"
                            label="Select Secretary"
                            outlined
                            dense
                            v-model="secretaryPositionedUser"
                            @change="updateMeetingPositions(secretaryPositionedUser,'Secretary')"
                        ></v-select>
                      </div>
                    </div>

                    <div class="col-md-4 bg-slate-400">
                      <div class="text-gray-600 font-medium">Secretariat</div>
                      <div class="flex justify-content-between">
                        <template v-if="secretariatPositionViewType === 'VIEW'">
                          <div class="text-primary font-bold py-2">
                            {{ secretariat.name}}
                          </div>
                          <div class="text-gray-700 py-2 cursor-pointer border rounded-md pl-2 pr-3 edit-btn"
                               v-if="hasAccess('can_add_management_meeting')"
                               @click="secretariatPositionViewType = 'ADD'">
                            <v-icon style="font-size: 15px;">mdi-pencil</v-icon>
                            Edit
                          </div>
                        </template>
                        <v-select v-else
                                  class="mt-2"
                                  :items="meetingAtendees"
                                  :item-text="'name'"
                                  :item-value="'id'"
                                  label="Select Secretariat"
                                  outlined
                                  dense
                                  v-model="secretariatPositionedUser"
                                  @change="updateMeetingPositions(secretariatPositionedUser,'Secretariat')"
                        ></v-select>
                      </div>
                    </div>
                  </div>

                </td>
              </tr>


              </tbody>
            </template>
          </v-simple-table>

          <v-divider></v-divider>
          <div class="row mx-2">
            <div class="col-md-2 font-medium flex items-center">
              Invitees:
            </div>
            <div class="col-md-10">
              <div class="flex mt-2 justify-end">
                <v-tooltip small left color="indigo"
                           v-if="meetingInvitees.length !== 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn

                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        icon
                        outlined
                        class="!rounded-md mr-2"
                        @click="editInvitee"
                    >
                      <v-icon>mdi-pen</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Attendees</span>
                </v-tooltip>
                <v-tooltip small left color="indigo"
                v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="hasAccess('can_schedule_meeting')"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        icon
                        outlined
                        class="!rounded-md"
                        @click="addInvitee"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Attendees</span>
                </v-tooltip>

              </div>
              <v-chip-group class="">
                <v-chip
                    v-for="(invitee, index) of meetingInvitees"
                    :key="index"
                >
                  {{ invitee.inviteeFullName }} ({{ invitee.inviteeInstitution }}) - {{ invitee.inviteeEmail }}
                </v-chip>

              </v-chip-group>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="row mx-2">
            <div class="col-md-2 font-medium flex items-center">
              Representatives:
            </div>
            <div class="col-md-10">
              <div class="flex mt-2 justify-end">
                <v-tooltip small left color="indigo"
                           v-if="meetingRepresentatives.length !== 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        icon
                        outlined
                        class="!rounded-md mr-2"
                        @click="editRepresentative"
                    >
                      <v-icon>mdi-pen</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Representatives</span>
                </v-tooltip>
                <v-tooltip small left color="indigo"
                v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="hasAccess('can_schedule_meeting')"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        icon
                        outlined
                        class="!rounded-md"
                        @click="addRepresentative"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Representatives</span>
                </v-tooltip>

              </div>
              <v-chip-group class="">
                <v-chip
                    v-for="(representative, index) of meetingRepresentatives"
                    :key="index">
                  {{ representative.representativeFullName }} - {{ representative.representativeDesignation }}
                </v-chip>

              </v-chip-group>
            </div>
          </div>

          <v-dialog v-model="addVideoMeeting" max-width="800px">
            <v-card>
              <v-card-title class="text-h6 grey lighten-2">
                <v-icon>mdi-plus</v-icon>
                Add Video Meeting
              </v-card-title>
              <v-form class="px-3">
                <v-card-text>
                  <v-checkbox
                      v-model="useMikutano"
                      :label="`Use e-Mikutano Instead`"
                  ></v-checkbox>
                </v-card-text>
                <v-card-text v-if="useMikutano">
                  <v-text-field
                      v-model="mikutanoToken"
                      autofocus
                      :counter="10"
                      maxlength="10"
                      label="e-Mikutano Participants Token"
                      prepend-icon="mdi-video-wireless-outline"
                  ></v-text-field>
                </v-card-text>
                <v-card-text v-else>
                  <v-text-field
                      v-model="videoURL"
                      autofocus
                      label="Meeting URL"
                      prepend-icon="mdi-link-plus"
                  ></v-text-field>
                </v-card-text>
              </v-form>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="pink"
                    fab
                    small
                    @click="addVideoMeeting = !addVideoMeeting"
                >
                  <v-icon class="white--text">mdi-close</v-icon>
                </v-btn>

                <v-btn
                    @click="updateMeetingUrl"
                    color="primary"
                    fab
                    small
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="questionadd" max-width="800px">
            <v-card>
              <v-card-title class="text-h6 grey lighten-2">
                <v-icon>mdi-plus</v-icon>
                Add Video Meeting
              </v-card-title>
              <v-form class="px-3">
                <v-card-text>
                  <v-text-field autofocus label="Meeting URL" prepend-icon="mdi-link-plus"></v-text-field>
                  <small class="grey--text">* Invalid Video Meeting URL</small>
                </v-card-text>
              </v-form>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="pink"
                  fab
                  small
                  @click="questionadd = !questionadd"
                >
                  <v-icon class="white--text">mdi-close</v-icon>

                </v-btn>

                <v-btn
                  color="primary"
                  fab
                  small
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-layout>
    </div>

    <NewAgendaBuilder v-if="hasAccess('can_add_management_meeting')" :AgendaBuilderWindow='AgendaBuilderWindow'
                      :agendaMode="bestPractise" :currentMeeting="currentMeeting"
                      :listOfagendas="listOfagendas"
                      @close="AgendaBuilderWindow =! AgendaBuilderWindow"/>

    <NewMinutes :TakeminutesWindow='TakeminutesWindow'
                :agendaMode="bestPractise"
                :currentMeeting="currentMeeting"
                @reload="loadMeetingDetails"
                :listOfagendas="listOfagendas" @close="TakeminutesWindow =! TakeminutesWindow"/>

    <v-dialog
      v-model="Agendadialog"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading . . .
          <v-progress-linear
            class="mb-0"
            color="white"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="addPeopleDialog"
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <v-icon>mdi-creation</v-icon>
          <span>Everyone is here</span>
        </v-card-title>
        <v-card-text>
          <span>Everyone on the “People List” page is already listed in this meetingDetails.</span>
          <v-radio-group
            v-model="ListP"
            row
          >
            <v-col
              cols="6"
              md="6"
              sm="6"
            >
              <v-btn
                outlined
                @click="focusOnGuestList"
              >
                Add People to Management List
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              md="6"
              sm="6"
            >
              <v-btn
                outlined
                @click="OpenNewUserForm=true;addPeopleDialog=false"
              >
                Go Back and Add Guest
              </v-btn>
            </v-col>
          </v-radio-group>
        </v-card-text>

      </v-card>
    </v-dialog>

    <DocumentViewer v-if="OpenfileDialog===true" :OpenfileDialog="OpenfileDialog" :docValue="docValue" :title="title"
                    @CloseDocumentViwer="OpenfileDialog=false"/>

    <AddNewUser :OpenNewUserForm="OpenNewUserForm" @close="OpenNewUserForm =! OpenNewUserForm"/>

    <AgendaBuildingMode :builderModeDialogy="builderModeDialogy" :currentMeeting="currentMeeting"
                        @closeBuilderModeDialogy="builderModeDialogy =!builderModeDialogy"/>

    <AddNewInvitee
        :meetingInviteeStatus="meetingInviteeStatus"
        :editMeetingInvitees="editMeetingInvitees"
        :meetingUniqueId="meetingUniqueId"
        @closeNewInviteeDialog="meetingInviteeStatus=false; editMeetingInvitees=false"
    ></AddNewInvitee>

    <AddNewRepresentative
        :meetingRepresentativeDialog="meetingRepresentativeDialog"
        :editMeetingRepresentative="editMeetingRepresentative"
        :meetingUniqueId="meetingUniqueId"
        @closeNewRepresentativeDialog="meetingRepresentativeDialog=false; editMeetingRepresentative=false"
    ></AddNewRepresentative>

    <PostponeMeeting
      :isPostponeMeeting="isPostponeMeeting"
      @closePostponeMeeting="isPostponeMeeting = !isPostponeMeeting"
    />

    <EditMeeting
      :showContextMenu="showContextMenu"
      @closeNewMeetingDialog="showContextMenu = !showContextMenu"
    />
  </v-container>
</template>

<script>
import AddNewUser from '../management-boards/AddNewUser.vue'
import AgendaBuildingMode from '../management-agendas/AgendaBuildingMode.vue'
import NewAgendaBuilder from '../management-agendas/NewAgendaBuilder.vue'
import NewMinutes from '../management-minutes/NewMinutes.vue'
import DocumentViewer from '../management-documents/DocumentViwer.vue'
import PostponeMeeting from './PostponeMeeting.vue'
import {mapGetters} from 'vuex'
import managementPreviewManagementMinutes from "@/graphql/management-agendas-graphql/previewMeetingMinutes.graphql";
import EditMeeting from "./EditMeeting.vue";

import AddNewInvitee from "./AddNewInvitee.vue";
import AddNewRepresentative from "@/modules/management_meeting_management/management-meetings/AddNewRepresentative.vue";

export default {
  components: {
    AddNewRepresentative,
    AddNewInvitee,
    AddNewUser,
    NewAgendaBuilder,
    NewMinutes,
    DocumentViewer,
    AgendaBuildingMode,
    PostponeMeeting,
    EditMeeting,
  },

  data() {
    return {
      builderModeDialogy: false,
      meetingInviteeStatus: false,
      meetingRepresentativeDialog: false,
      editMeetingRepresentative: false,
      editMeetingInvitees: false,
      ListP: '',
      draggable: true,
      bestPractise: null,
      index: null,
      value: null,
      OpenNewUserForm: false,
      addPeopleDialog: false,
      docValue: '',
      OpenfileDialog: false,
      addVideoMeeting: false,
      videoURL: "",
      mikutanoToken: "",
      useMikutano: false,
      Agendadialog: false,
      title: '',
      TakeminutesWindow: false,
      hover: true,
      minutesDialog: false,
      AgendaBuilderWindow: false,
      c_index: -1,
      primary: "",
      dialog2: false,
      questionadd: false,
      eboard_schedules: '/eboard_schedules/',
      meetingUniqueId: '',
      value1: 'value1',
      atendees: [],
      chairPersonPositionViewType: 'VIEW',
      secretaryPositionViewType: 'VIEW',
      secretariatPositionViewType: 'VIEW',
      switchValue: localStorage.getItem("dragable"),
      meetingTitle: '',
      isPostponeMeeting: false,
      currentMeetingDetails: {},
      showContextMenu: false,
      chairPositionedUser: null,
      secretaryPositionedUser: null,
      secretariatPositionedUser: null,
    }
  },

  methods: {
    async loadMeetingDetails() {
      await this.$store.dispatch('fetchingManagementMeetingDetails', this.meetingUniqueId);
    },
    async updateMeetingPositions(user,position){
      await this.$store.dispatch("updateManagementAttendeesMeetingPosition", {
        user: user,
        position: position,
      });
      switch (position) {
        case 'Chair Person':
          this.chairPersonPositionViewType = 'VIEW'
          break
        case 'Secretary':
          this.secretaryPositionViewType = 'VIEW'
          break
        case 'Secretariat':
          this.secretariatPositionViewType = 'VIEW'
          break
      }
      await this.$store.dispatch('fetchingManagementMeetingAttendees', this.meetingUniqueId);
    },
    async updateMeetingUrl() {
      let input = {
        meetingUrl: this.useMikutano ? this.mikutanoToken : this.videoURL,
        meetingUniqueId: this.meetingDetails.meetingUniqueId
      }

      await this.$store.dispatch("addManagementMeetingUrl", input);

      this.useMikutano = false;
      this.videoURL = "";
      this.mikutanoToken = "";
      this.addVideoMeeting = false;

    },
    async openVideoCallScreen() {
      let height = window.outerHeight;
      var actualSize = height * (100 / 100);
      if (this.meetingDetails.meetingUrlIsToken) {
        window.open(
            "https://mikutano.gov.go.tz/" + this.meetingDetails?.meetingUrl,
            "e-Board Call",
            "top=200,bottom=300,left=500,width=" + actualSize + ",height=400"
        );
      } else {
        window.open(
            this.meetingDetails?.meetingUrl,
            "e-Board Call",
            "top=200,bottom=300,left=500,width=" + actualSize + ",height=400"
        );
      }
    },

    async sendLinktoInvitees() {
      // var meeting_ULR = "";
      // if (this.meetingDetails.meetingUrlIsToken) {
      //   meeting_ULR =
      //       "https://mikutano.gov.go.tz/" +
      //       this.meetingDetails?.meetingUrl;
      // } else {
      //   meeting_ULR = this.meetingDetails?.meetingUrl;
      // }

      await this.$store.dispatch("shareManagementMeetingUrlToParticipants", {
        meetingUniqueId: this.meetingDetails.meetingUniqueId,
        meetingUrl: this.meetingDetails?.meetingUrl,
      });
    },

    addRepresentative() {
      this.meetingRepresentativeDialog = true
    },
    editRepresentative() {
      this.meetingRepresentativeDialog = true
      this.editMeetingRepresentative = true
    },
    addInvitee() {
      this.meetingInviteeStatus = true
    },
    editInvitee() {
      this.meetingInviteeStatus = true
      this.editMeetingInvitees = true
    },
    postPoneMeeting() {
      this.isPostponeMeeting = true;
      this.currentMeetingDetails = this.meetingDetails;
    },

    closePostponeMeeting() {
      this.isPostponeMeeting = false;
      this.currentMeetingDetails = {};
    },

    previewMinutes() {
      this.previewLoading = true
      this.$apollo.mutate({
        mutation: managementPreviewManagementMinutes,
        variables: {
          meetingUniqueId: this.$store.getters.getManagementMeetingDetails.meetingUniqueId,
        }
      }).then((response) => {
        this.previewLoading = false
        this.docValue = "/" + response.data.managementPreviewManagementMinutes.minutesDocumentPath
        this.$emit('closeGreatModel');
        this.OpenfileDialog = true
      })
    },

    previewAgendas() {
      this.docValue = this.$store.state.ManagementAgendasStore.combinedManagementAgendas
      this.OpenfileDialog = true
    },

    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
      localStorage.setItem("itemID", item.id);
    },

    async onDrop(evt, side) {
      const data = localStorage.getItem("itemID");
      await this.$store.dispatch("switchingManagementMeetingAttendees", {
        data: data,
        side: side,
      });
    },

    allowDrop: (ev) => {
      ev.preventDefault();
    },

    drag: (ev) => {
      localStorage.setItem("dragable", ev.target.id);
    },

    drop: (ev) => {
      ev.preventDefault();
      const data = localStorage.getItem("dragable");
      ev.target.appendChild(document.getElementById(data));
    },

    async addAtendees(newateendee) {
      const attendee = {
        attendanceMeeting: this.meetingDetails.primaryKey,
        attendanceUser: newateendee.id,
      };
      await this.$store.dispatch("addManagementMeetingAttandees", attendee);
    },

    focusOnGuestList() {
      this.addPeopleDialog = false
      const div = document.getElementById('guestList');
      div.focus()
    },

    async updateManagementMeetingContentEditable(editedPart, currentValue, isDate) {
      let Meeting = {
        meetingUniqueId: this.meetingUniqueId,
        updateType: editedPart,
        updateValue: currentValue.target.innerHTML,
        isDate: isDate
      }
      await this.$store.dispatch('updateManagementMeeting', Meeting);
    },

    async updateManagementMeeting(editedPart, currentValue, isDate) {
      let Meeting = {
        meetingUniqueId: this.meetingUniqueId,
        updateType: editedPart,
        updateValue: currentValue.target._value,
        isDate: isDate
      }
      await this.$store.dispatch('updateManagementMeeting', Meeting);
      await this.$store.dispatch('fetchingManagementMeetingDetails', this.meetingUniqueId)
    },

    async updateManagementMeetingLocation(editedPart, currentValue, isDate) {
      let Meeting = {
        meetingUniqueId: this.meetingUniqueId,
        updateType: editedPart,
        updateValue: currentValue,
        isDate: isDate
      }
      await this.$store.dispatch('updateManagementMeeting', Meeting);
      await this.$store.dispatch('fetchingManagementMeetingDetails', this.meetingUniqueId)
    },

  },

  watch: {
    switchValue(data) {
      this.$store.dispatch('switchingMeetingAttendees', data)
    },

    dialog(val) {
      if (!val) return

      setTimeout(() => (this.dialog = false, this.dialog2 = true), 1000)

    },

    Agendadialog(val) {
      if (!val) return
      setTimeout(() => (this.Agendadialog = false, this.openAgendaBuilderWindow()), 2000)

    },

    listOfagendas() {
      if (this.listOfagendas.length > 0 && !this.$store.state.ManagementMeetingsStore.selectedManagementMeetingDetails.meetingHasPublishedAgenda) {
        this.AgendaBuilderWindow = true
      }
    },
  },

  computed: {
    meetingRepresentatives() {
      return this.$store.getters.getManagementMeetingRepresentatives
    },

    meetingInvitees() {
      return this.$store.getters.getManagementCommitteeMeetingInvitees
    },

    HasPublishedAgenda() {
      return this.$store.getters.getManagementMeetingDetails.meetingHasPublishedAgenda
    },

    currentMeeting() {
      return this.$store.state.ManagementCurrentMeetingStore.currentManagementMeeting
    },

    unConfirmMembers() {
      let usersList = [];
      const combinedAttendance = this.meetingAtendees.concat(this.meetingApologies);

      this.managementMembers.forEach((member) => {
        usersList.push({
          id: member.id,
          userProfileTitle: member.userProfileTitle,
          firstName: member.firstName,
          lastName: member.lastName,
          userId: member.username,
        });
      });

      combinedAttendance.forEach((attendee) => {
        usersList = usersList.filter(
          (member) => member.userId !== attendee.userId
        );
      });
      return usersList;
    },

    locations() {
      return this.$store.state.SettingsStore.boardLocations
    },

    meetingLocation() {
      return {
        location: this.meetingDetails.meetingLocation.locationName,
        locationAddress: this.meetingDetails.meetingLocation.locationAddress,
        id: this.meetingDetails.meetingLocation.primaryKey,
        locationTimezone: this.meetingDetails.meetingLocation.locationTimezone,
        locationCreateddate: this.meetingDetails.meetingLocation.locationCreateddate
      }
    },

    ...mapGetters({
      meetingDetails: 'getManagementMeetingDetails',
      meetingAtendees: 'getManagementMeetingAttendees',
      meetingApologies: 'getManagementMeetingApologiesAttendees',
      listOfagendas: 'getManagementMeetingAgendas',
      managementMembers: "getManagementMembers",
    }),
    chairPerson() {
      let data = this.meetingAtendees?.find(a => a.attendancePosition === 'Chair Person')
      return data ? data : { name: "-" }
    },
    secretary() {
      let data = this.meetingAtendees?.find(a => a.attendancePosition === 'Secretary')
      return data ? data : { name: "-" }
    },
    secretariat() {
      let data = this.meetingAtendees?.find(a => a.attendancePosition === 'Secretariat')
      return data ? data : { name: "-" }
    }
  },

  async mounted() {
    this.meetingUniqueId = this.$route.params.meeting_uuid;
    await this.$store.dispatch('loadManagementMembers');
    await this.$store.dispatch('fetchingManagementMeetingDetails', this.meetingUniqueId);
    await this.$store.dispatch('setManagementCurrentMeeting', this.meetingUniqueId);
    await this.$store.dispatch('fetchingManagementMeetingAttendees', this.meetingUniqueId);
    await this.$store.dispatch('loadManagementBestPracticeAgendaTemplates', this.currentMeeting);
    await this.$store.dispatch('previewManagementAgendas', this.meetingUniqueId);
    await this.$store.dispatch('loadManagementCommitteeMeetingInvitees', this.meetingUniqueId);
  },
}
</script>


<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>

<style>

.edit-btn:hover {
  background-color: #f6f6f6;
}

.node {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 40px;
}

.events-banner {
  padding: 5px;
  font-size: small;
  display: block;
}

.events-banner .prefix {
  color: #858585;
}

.events-banner .content {
  font-weight: bold;
  color: #273080;
}

.events-banner .value {
  color: #3a5831;
  font-size: larger;
}

.events-banner-event {
  background-color: #f9f9f9;
  margin: 2px;
  padding: 10px;
  display: inline-block;
  clear: left;
  width: auto;
}

.vue-xeditable-value {
  cursor: pointer;
  color: #17c;
  border-bottom: 1px dashed #07c;
  line-height: 22px;
}

h1 {
  margin: 0;
  text-align: center;
  color: #42b983;
}

h4 {
  margin-bottom: 0;
  color: #404040;
}

a {
  color: #07c;
}

pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}

.highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre code, pre tt {
  background-color: transparent;
  border: none;
}
</style>


<style>
#div1, #div2 {
  float: left;
  width: 100px;
  height: 35px;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}
</style>
