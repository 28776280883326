var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.showContextMenu),callback:function ($$v) {_vm.showContextMenu=$$v},expression:"showContextMenu"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_c('v-icon',[_vm._v("mdi-account-group-outline")]),_vm._v(" Add New Meeting")],1)]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"counter":100,"label":"Title *","prepend-icon":"mdi-text-recognition","required":""},model:{value:(_vm.meetingObj.meetingTitle),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingTitle", $$v)},expression:"meetingObj.meetingTitle"}}),_c('v-select',{attrs:{"items":_vm.meetingCategories,"item-text":"categoryTittle","item-value":"id","label":"Meeting Category *","prepend-icon":"mdi-shape-outline","required":""},model:{value:(_vm.meetingObj.meetingCategory),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingCategory", $$v)},expression:"meetingObj.meetingCategory"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date *","persistent-hint":"","prepend-icon":"mdi-calendar"},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.meetingDate)}},model:{value:(_vm.meetingObj.meetingDate),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingDate", $$v)},expression:"meetingObj.meetingDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":_vm.date,"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.meetingObj.meetingDate),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingDate", $$v)},expression:"meetingObj.meetingDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-dialog',{ref:"dialog1",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Time *","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.meetingObj.meetingStartTime),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingStartTime", $$v)},expression:"meetingObj.meetingStartTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[(_vm.modal1)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.meetingObj.meetingStartTime),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingStartTime", $$v)},expression:"meetingObj.meetingStartTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Time *","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.meetingObj.meetingEndTime),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingEndTime", $$v)},expression:"meetingObj.meetingEndTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c('v-time-picker',{attrs:{"min":_vm.meetingObj.meetingStartTime,"full-width":""},model:{value:(_vm.meetingObj.meetingEndTime),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingEndTime", $$v)},expression:"meetingObj.meetingEndTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{attrs:{"hint":_vm.timezone,"items":_vm.locations,"item-value":"id","item-text":"location","label":"Location *","persistent-hint":"","prepend-icon":"mdi-map-marker","required":"","return-object":""},on:{"change":_vm.getSelectedTimeZone},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","text-color":""}},[_vm._v(_vm._s(item.location))])]}}]),model:{value:(_vm.meetingObj.meetingLocation),callback:function ($$v) {_vm.$set(_vm.meetingObj, "meetingLocation", $$v)},expression:"meetingObj.meetingLocation"}})],1)],1)],1),_c('v-divider')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"pink","small":"","outlined":""},on:{"click":_vm.closeNewMeetingDialog}},[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" Close ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"blue darken-1","outlined":"","small":""},on:{"click":_vm.createNewManagementMeeting}},[_c('v-icon',[_vm._v("mdi-send-check-outline")]),_vm._v(" Save ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }