import Vue from 'vue'
import Vuex from 'vuex'
import AuthStore from './eboard_auth_store/Auth.js'
import RolesStore from './eboard_auth_store/Roles.js'
import PermissionsStore from './eboard_permissions_store/Permissions.js'
import MembersStore from './eboard_members_store/Members.js'
import CommitteeStore from './eboard_committee_store/Commitee.js'
import ResolutionStore from './eboard_resolution_store/Resolutions.js'
import InterestStore from './eboard_interest_store/Interest.js'
import CategoriesStore from './eboard_meeting_store/Categories.js'
import MeetingsStore from './eboard_meeting_store/meetings.js'
import CurrentMeetingStore from './eboard_meeting_store/CurrentMeeting.js'
import SettingsStore from './eboard_settings_store/Settings.js'
import AgendasStore from './eboard_agenda_store/Agendas.js'
import MyboardStore from './eboard_myboard_store/Myboard.js'
import DocumentStore from './eboard_supporting_docs/DocumentStore.js'
import createPersistedState from "vuex-persistedstate";
import CalenderStore from './eboard_calender_store/eboard_calender_store'
import VotesStore from './eboard_votting_store/ActiveVotes.js'
import RepositoryStore from './eboard_repository_store/repository.js'
import ManagementsStore from './eboard_management_store/managements.js'
import InvitationStore from './eboard_invitation_store/Invitation.js'
import MinutesStore from './eboard_minutes_store/minutes.js'
import DirectiveStore from './eboard_directives_store/Directives.js'
import DecisionStore from './eboard_decisisons_store/Decision.js'
import VideoCallStore from './eboard_video_call/VideoCall.js'
import NewsUpdatesStore from './eBoard_news_updates/News&Updates.js'
import BoardPacksStore from './eboard_board_packs_store/packs_store.js'
import DashboardStore from './eboard_dashboard_store/dashboard.js'
import AuditTrailStore from './audit_trail_store/AuditTrail.js'
import ReportsStore from './eboard_reports_store/Reports.js'
import BoardCommitteeStructure from './eboard_committee_structure_store/eboardCommitteeStructure'


import InstitutionStore from './admin_panel_store/institution_store/institutions'
import DefaultUserRolesStore from './admin_panel_store/default_user_roles_store/defaultUserRole'
import SystemAdministratorStore from './admin_panel_store/system_administrator_store/systemAdministrator'
import SystemAdministratorRoleStore from './admin_panel_store/system_administrator_role_store/systemAdministratorRole'



import ManagementCategoriesStore from './management_meeting_store/Categories.js'
import ManagementMeetingsStore from './management_meeting_store/meetings.js'
import ManagementAgendasStore from './management_agenda_store/Agendas.js'
import ManagementCurrentMeetingStore from './management_meeting_store/CurrentMeeting.js'
import ManagementDocumentStore from './management_supporting_docs/DocumentStore.js'
import ManagementMembersStore from './management_members_store/Members.js'
import ManagementMinutesStore from './management_minutes_store/minutes.js'
import ManagementReportsStore from './management_reports_builder/ReportsStore.js'
import ManagementDirectiveStore from './management_directives_store/Directives.js'
import ManagementDecisionStore from './management_decisisons_store/Decision.js'
import ManagementRepositoryStore from './management_repository_store/repository.js'
import ManagementUserPermissionsStore from './management_permissions_store/managementUserPermissions.js'
import ManagementDashboardStore from './management_dashboard_store/dashboard.js'
import ManagementReports from './management_reports_store/management_reports'
import ManagementCommitteeStructure from './management-committee-structure-store/managementCommitteeStructure'


import ManagementCommitteeStore from './management_committee_store/managementCommittee'
import ManagementCommitteeMeetingStore from './management_committee_meeting_store/managementCommitteeMeeting'
import ManagementCommitteeMeetingAttendance from "@/store/management-committee-meeting-attendance-store/managementCommitteeMeetingAttendance";
import ManagementCommitteeMembers from "@/store/management_committee_members_store/managementCommitteeMembers";
import ManagementCommitteeAgenda from "@/store/management-committee-agenda-store/managementCommitteeAgenda";
import ManagementCommitteeSupportingDocs from "@/store/management-committee-supporting-agendas-store/managementCommitteeSupportingDocs";
import ManagementCommitteeAgendaNotesStore from "@/store/management-committee-agenda-notes-store/managementCommitteeAgendaNotes";
import ManagementCommitteeAgendaRecommendationsStore from "@/store/management-committee-agenda-recommendation-store/managementCommitteeAgendaRecommendation";
import ManagementCommitteeMeetingRepository from "@/store/management-committee-meeting-repository-store/managementCommitteeMeetingRepository";
import ManagementCommitteeMeetingInvitee from "@/store/management-meeting-invitee-store/ManagementMeetingInvitee";
import ManagementCommitteeMeetingRepresentative from "@/store/management-meeting-representative-store/ManagementMeetingRepresentative";
import eBoardNotebook from "@/store/eboard_notebook_store/eboardNotebook";
import eBoardNotebookPage from "@/store/eboard_notebook_page_store/eboardNotebookPage";


Vue.use(Vuex)


const store= new Vuex.Store({
    modules: {
        AuthStore,
        DirectiveStore,
        DecisionStore,
        RolesStore,
        InvitationStore,
        MembersStore,
        CommitteeStore,
        ResolutionStore,
        VotesStore,
        InterestStore,
        CategoriesStore,
        MeetingsStore,
        SettingsStore,
        AgendasStore,
        CurrentMeetingStore,
        MyboardStore,
        DocumentStore,
        CalenderStore,
        RepositoryStore,
        ManagementsStore,
        MinutesStore,
        BoardPacksStore,
        VideoCallStore,
        NewsUpdatesStore,
        DashboardStore,
        BoardCommitteeStructure,
        ManagementMeetingsStore,
        ManagementAgendasStore,
        ManagementCurrentMeetingStore,
        ManagementCategoriesStore,
        ManagementDocumentStore,
        ManagementMembersStore,
        ManagementMinutesStore,
        ManagementReportsStore,
        ManagementDirectiveStore,
        ManagementDecisionStore,
        ManagementRepositoryStore,
        ManagementUserPermissionsStore,
        PermissionsStore,
        ManagementDashboardStore,
        ManagementCommitteeStore,
        ManagementCommitteeMeetingStore,
        ManagementCommitteeMeetingAttendance,
        ManagementCommitteeMembers,
        ManagementCommitteeAgenda,
        ManagementCommitteeAgendaNotesStore,
        ManagementCommitteeAgendaRecommendationsStore,
        ManagementCommitteeMeetingRepository,
        ManagementCommitteeSupportingDocs,
        ManagementCommitteeMeetingInvitee,
        ManagementCommitteeMeetingRepresentative,
        ManagementCommitteeStructure,
        eBoardNotebook,
        eBoardNotebookPage,
        InstitutionStore,
        DefaultUserRolesStore,
        SystemAdministratorStore,
        SystemAdministratorRoleStore,
        AuditTrailStore,
        ReportsStore,
        ManagementReports
    },
    state(){
      return{
        showLoading:false,
        lastVisitedRoute: null,
      }
    },
    mutations:{
      changeLoadingPinner(state,status){
        state.showLoading=status
      },    
      setLastVisitedRoute(state, route) {
        state.lastVisitedRoute = route;
      },
    },
    actions: {
      showLoadingSpinner(context,status){
        context.commit('changeLoadingPinner',status)
      },
      setLastVisitedRoute({ commit }, route) {
        commit('setLastVisitedRoute', route);
      },
    },
    plugins: [createPersistedState()],
    namespaced:true
})

export default store
