<template>
  <v-card tile>
    <v-card-title>
      <strong
      ><span
          class="maincolor"
          contenteditable="true"
          style="padding-left: 10px"
      >{{ selectedAgenda.itemName }} </span
      ></strong
      >
    </v-card-title>

    <v-card-text v-if="selectedAgenda.itemType === 'Standard'">
      <v-container>
        <v-row justify-space-around>
          <AgendaTopDetails/>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text v-else-if="selectedAgenda.itemType === 'ConfirmMinutes'">
      <v-container>
        <v-row justify-space-around>
          <ConfirmMinutesAgendaTopDetails />
          <v-list dense width="100%">
            <v-list-item-group color="primary">
              <v-layout
                  style="padding-top: 10px; width: 100%"
                  width="100%"
              >
                <v-alert dense style="width: 100%" text>
                  <v-flex md12 xs12>
                    <div class="">
                      <span>Minutes to be Confirmed:</span>
                    </div>
                  </v-flex>
                </v-alert>
              </v-layout>
              <v-list-item
                  style="
                        border-top: 1px solid gray;
                        border-bottom: 1px solid gray;
                      "
                  @click="
                        OpenfileDialogFunc(
                          '/' +
                            selectedAgenda.itemMinutesDetails.edges[0].node
                              .documentPath
                        )
                      "
              >
                <v-list-item-avatar size="30">
                  <v-img src="@/assets/docs.png" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                      v-text="selectedAgenda.itemMinutesDetails.edges[0].node.documentName"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-row>
      </v-container>
    </v-card-text>

    <center><p style="padding-bottom: 0px"> Add a Minute... </p></center>

    <v-card-actions style="padding-top: 0px">
      <v-spacer></v-spacer>
      <v-btn
          v-for="button in MinutesCustomSections"
          :key="button.sectionUniqueId"
          class="ma-2"
          :color="button.sectionButtonColor"
          outlined
          small
          @click="
            loadPreviousCustomMinutesSections(
              button.sectionButtonLabel,
              button.sectionUniqueId
            )
          "
      >
        <v-icon small>mdi-file-document</v-icon>
        {{ button.sectionButtonLabel }}
      </v-btn>
      <v-btn
          class="ma-2"
          color="primary"
          outlined
          small
          @click="loadPreviousMeetingNotes('Add New Note'); $emit('reload')"
      >
        <v-icon small>mdi-file-document</v-icon>
        Note
      </v-btn>

      <v-btn
          class="ma-2"
          color="pink"
          outlined
          small
          @click="loadPreviousMeetingDecisions('Add Resolution'); $emit('reload')"
      >
        <v-icon small>mdi-arrow-all</v-icon>
        Resolution
      </v-btn>

      <v-btn
          color="success"
          outlined
          small
          @click="loadPreviousMeetingDirective('Add New Directive'); $emit('reload')"
      >
        <v-icon small>mdi-checkbox-marked-outline</v-icon>
        Directives
      </v-btn>

      <v-spacer></v-spacer>

      <MinuteNote
          :OpenMinuteNoteEditor="MinuteNoteEditor"
          :selectedAgenda="selectedAgenda"
          @CloseMinuteNoteEditor="MinuteNoteEditor = false"
          :noteTitle="noteTitle"
      />

      <NewAction
          :OpenActionEditor="ActionEditor"
          :actionName="actionName"
          :selectedAgenda="selectedAgenda"
          @CloseActionEditor="CloseActionEditor"
          :selectedDirective="selectedDirective"
      />

      <NewDecision
          :OpenDecisionEditor="DecisionEditor"
          :selectedAgenda="selectedAgenda"
          @CloseDecisionEditor="CloseActionEditor"
          :decisionName="decisionName"
          :selectedDecision="selectedDecision"
      />
    </v-card-actions>

    <v-card-text>
      <v-card
          v-for="section in selectedCustomMinutesSectionsAgenda"
          :key="section.sectionUniqueId"
          class="mx-auto my-2 pa-3 agenda blocked"
          max-width="100%"
          outlined
      >
        <template slot=""> </template>
        <v-card-title>
          {{ section.sectionName }}:
          <v-spacer></v-spacer>
          <v-btn
              @click="
              loadPreviousCustomMinutesSections(
                section.sectionName,
                section.sectionButtonUniqueId
              )
            "
              icon
              :color="section.sectionColor"
          >
            <v-icon> mdi-pencil-circle </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-html="section.sectionValue"></div>
        </v-card-text>
      </v-card>

      <v-card
          v-if="selectedAgendaNotes.primaryKey"
          class="mx-auto my-2 pa-3 agenda blocked"
          max-width="100%"
          outlined
      >
        <template slot="progress">
          <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title>
          Note:
          <v-spacer></v-spacer>
          <v-btn
            @click="loadPreviousMeetingNotes('Edit Note')"
            icon
            color="info"
          >
            <v-icon
            >
              mdi-pencil-circle
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-html="selectedAgendaNotes.notesDescriptions"></div>
        </v-card-text>
      </v-card>

      <v-card
          v-for="directive in selectedAgendaDirectives"
          :key="directive.directiveUniqueId"
          class="mx-auto my-2 pa-3 agenda complete"
          max-width="100%"
          outlined
      >
        <template slot="progress">
          <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title>
          Directive: {{ directive.directiveTitle }}
          <v-spacer></v-spacer>
          <v-btn
              @click="editAgendaItemDirective(directive, 'Edit Directive')"
              icon
              small
              color="info"
              class="mr-2"
          >
            <v-icon
            >
              mdi-pencil-circle
            </v-icon>
          </v-btn>
          <v-btn
              @click="deleteAgendaItemDirective(directive)"
              icon
              small
              color="error"
          >
            <v-icon
            >
              mdi-delete-circle
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div v-html="directive.directiveDescriptions"></div>
          <v-divider></v-divider>
          <p v-if="directive.directiveCategory === 'ALL'"><strong>Owner:</strong> ALL MANAGEMENT MEMBERS</p>
          <p v-else><strong>Owner: </strong>
            <span v-for="item in directive.directiveOwner" :key="item.username">{{ item.firstName }} ({{
                item.lastName
              }}), </span>
          </p>
          <v-divider></v-divider>
          <p><strong>Due Date:</strong> {{ new Date(directive.directiveDuedate).toDateString() }}</p>
        </v-card-text>
      </v-card>

      <v-card
          v-for="decision in selectedAgendaDecisions"
          :key="decision.decisionUniqueId"
          class="mx-auto my-2 pa-3 agenda overdue"
          max-width="100%"
          outlined
      >
        <template slot="progress">
          <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title>
          Decision: {{ decision.decisionTitle }}
          <v-spacer></v-spacer>
          <v-btn
              @click="editAgendaItemDecision(decision, 'Edit Decision')"
              icon
              color="info"
          >
            <v-icon
            >
              mdi-pencil-circle
            </v-icon>
          </v-btn>
          <v-btn
              @click="deleteAgendaItemDecision(decision)"
              icon
              small
              color="error"
          >
            <v-icon
            >
              mdi-delete-circle
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div v-html="decision.decisionDescriptions"></div>
          <v-divider></v-divider>
          <p><strong>Outcome:</strong> {{ decision.decisionOutcome === 'APR' ? 'APPROVED' : 'NOT APPROVED' }}</p>
          <v-divider></v-divider>
          <p><strong>Decision Date:</strong> {{ new Date(decision.decisionDate).toDateString() }}</p>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text>
      <AgendaSupportingDocs/>
      <AgendaSupportingDocs :data="selectedAgenda" type="Minutes"/>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="ma-2"
          color="pink"
          outlined
          small
          @click="
          AgendaBuilderWindow = false;
          $emit('closeGreatModel');
        "
      >
        <v-icon small>mdi-close</v-icon>
        Close
      </v-btn>
      <v-btn
          :loading="previewLoading"
          class="ma-2"
          color="accent"
          outlined
          small
          @click="previewMeetingMinutes"
      >
        <v-icon small>mdi-archive-eye-outline</v-icon>
        Preview
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>
    <BoardMinuteCustomSection
        :OpenCustomMinuteEditor="OpenCustomMinuteEditor"
        :actionTitle="actionTitle"
        :sectionUniqueId="sectionUniqueId"
        :selectedAgenda="selectedAgenda"
        @CloseSectionForm="OpenCustomMinuteEditor = false;"
    />
    <DocumentViewer v-if="OpenfileDialog===true" :OpenfileDialog="OpenfileDialog" :docValue="docValue" :title="title"
                    @CloseDocumentViwer="OpenfileDialog=false"/>
  </v-card>
</template>

<script>
import AgendaTopDetails from "../management-agendas/AgendaTopDetails.vue";
import AgendaSupportingDocs from "../management-agendas/AgendaSupportingDocs.vue";
import MinuteNote from "./MinuteNote.vue";
import NewAction from "../management-actions/NewAction.vue";
import NewDecision from "../management-decisions/NewDecision.vue";
import DocumentViewer from '../management-documents/DocumentViwer.vue'
import managementPreviewManagementMinutes from "../../../graphql/management-agendas-graphql/previewMeetingMinutes.graphql";
import BoardMinuteCustomSection
  from "@/modules/management_meeting_management/management-minutes/BoardMinuteCustomSection.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    BoardMinuteCustomSection,
    AgendaTopDetails,
    AgendaSupportingDocs,
    MinuteNote,
    NewAction,
    NewDecision,
    DocumentViewer
  },

  data() {
    return {
      previewLoading: false,
      loading3: false,
      publishAgendas: null,
      MinuteNoteEditor: false,
      ActionEditor: false,
      DecisionEditor: false,
      OpenfileDialog: false,
      docValue: '',
      title: '',
      noteTitle: '',
      selectedDirective: null,
      actionName: '',
      decisionName: '',
      OpenCustomMinuteEditor: false,
      actionTitle: null,
      sectionUniqueId: null,
      selectedDecision: null
    };
  },

  watch: {
    selectedAgenda() {
      this.loadCustomMinutes()
    }
  },

  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },

    selectedAgenda() {
      return this.$store.state.ManagementAgendasStore.selectedManagementAgenda;
    },

    selectedAgendaNotes() {
      return this.$store.state.ManagementMinutesStore.managementAgendaItemMinuteNote;
    },

    selectedAgendaDecisions() {
      return this.$store.state.ManagementMinutesStore.managementAgendaItemMinuteDecision;
    },

    selectedAgendaDirectives() {
      return this.$store.state.ManagementMinutesStore.managementAgendaItemMinuteDirective;
    },

    meetingDetails() {
      return this.$store.state.ManagementMeetingsStore.selectedManagementMeetingDetails;
    },

    hasNoNotes() {
      return this.isEmptyObject(this.selectedAgendaNotes);
    },
    MinutesCustomSections() {
      return this.$store.state.SettingsStore.boardCustomMinutesSectionsList;
    },
    ...mapGetters({
        selectedCustomMinutesSectionsAgenda: "getManagementCustomMinuteSections"
    })
  },

  methods: {
    async loadCustomMinutes() {
      await this.$store.dispatch(
          "loadAllManagementCustomMinuteSections",
          this.selectedAgenda.itemKey
      );
    },
    async loadPreviousCustomMinutesSections(actionLabel, sectionUniqueId) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
          "loadAllManagementCustomMinuteSections",
          this.selectedAgenda.itemKey
      );
      this.actionTitle = actionLabel;
      this.sectionUniqueId = sectionUniqueId;
      this.OpenCustomMinuteEditor = true;
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    CloseActionEditor() {
      this.ActionEditor = false;
      this.DecisionEditor = false;
      this.actionName = '';
      this.selectedDirective = null
      this.selectedDecision = null
    },

    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      console.log(this.docValue);
      this.OpenfileDialog = true;
    },

    editAgendaItemDecision(selectedDecision, decisionName) {
      this.selectedDecision = selectedDecision;
      this.decisionName = decisionName;
      this.DecisionEditor = true;
    },

    async deleteAgendaItemDecision(selectedDecision) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
          "deleteManagementDecisionsMinutes",
          {
            decisionUniqueId: selectedDecision.decisionUniqueId
          }
      );
      await this.$store.dispatch(
          "loadAllManagementDecisionMinutes",
          this.selectedAgenda.itemKey
      );
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async deleteAgendaItemDirective(selectedDirective) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
          "deleteManagementDirectivesMinutes",
          {
            directiveItemKey: this.selectedAgenda.itemKey,
            directiveUniqueId: selectedDirective.directiveUniqueId
          }
      );
      await this.$store.dispatch(
          "loadAllManagementDirectiveMinutes",
          this.selectedAgenda.itemKey
      );
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    editAgendaItemDirective(selectedDirective, actionName) {
      this.selectedDirective = selectedDirective;
      this.actionName = actionName;
      this.ActionEditor = true;
    },

    previewMeetingMinutes() {
      this.previewLoading = true
      this.$apollo.mutate({
        mutation: managementPreviewManagementMinutes,
        variables: {
          meetingUniqueId: this.$store.getters.getManagementMeetingDetails.meetingUniqueId,
        }
      }).then((response) => {
        this.previewLoading = false
        this.docValue = "/" + response.data.managementPreviewManagementMinutes.minutesDocumentPath
        this.$emit('closeGreatModel');
        this.OpenfileDialog = true
      })
    },

    isEmptyObject(obj) {
      for (const notesDescriptions in obj) {
        console.log(notesDescriptions)
        return false;
      }
      return true;
    },

    async loadPreviousMeetingDirective(actionName) {
      // await this.$store.dispatch("showLoadingSpinner", true);
      // await this.$store.dispatch(
      //     "loadAllDirectiveMinutes",
      //     this.selectedAgenda.itemKey
      // );
      this.selectedDirective = null;
      this.actionName = actionName;
      this.ActionEditor = true;
      // await this.$store.dispatch("showLoadingSpinner", false);
    },

    async loadPreviousMeetingDecisions(decisionName) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
          "loadAllDecisionMinutes",
          this.selectedAgenda.itemKey
      );
      this.decisionName = decisionName;
      this.DecisionEditor = true;
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async loadPreviousMeetingNotes(title) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
          "loadAllMinuteNotes",
          this.selectedAgenda.itemKey
      );
      this.noteTitle = title;
      this.MinuteNoteEditor = true;
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },

  async mounted() {
    await this.loadCustomMinutes()
    await this.$store.dispatch('loadAllManagementMinuteNotes', this.selectedAgenda.itemKey)
    await this.$store.dispatch('loadAllManagementDecisionMinutes', this.selectedAgenda.itemKey)
    await this.$store.dispatch('loadAllManagementDirectiveMinutes', this.selectedAgenda.itemKey)
  }
};
</script>

<style>
</style>
