<template>
  <v-row>
    <v-dialog
        v-model="newSystemAdministratorRoleDialog"
        max-width="70%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <icon class="pt-1 mr-3" name="role" width="w-5" height="h-5" color="#000"></icon><span class="text-h6">Add System Admin Role</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                  >
                    <v-text-field
                        v-model="roleName"
                        :counter="50"
                        label="Role Name *"
                        prepend-icon="mdi-account-lock-outline"
                        required
                        placeholder="Role Name"
                        outlined
                    ></v-text-field>
                  </v-col>

                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                  >
                    <v-text-field
                        v-model="roleDescription"
                        :counter="100"
                        label="Role Description *"
                        prepend-icon="mdi-account-details-outline"
                        required
                        placeholder="Role Description"
                        outlined
                    ></v-text-field>
                  </v-col>

                  <v-col md="9" cols="9">
                    <v-row class="mb-4 ml-4">
                      <v-icon class="mr-4">mdi-account-key-outline</v-icon>
                      <span>Role Permissions</span>
                    </v-row>
                    <v-treeview
                        selectable
                        :items="permissiongroups"
                        v-model="permissions"
                    ></v-treeview>
                  </v-col>
                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="closeRoleDialog"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="createSystemAdminRoles"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Icon from "@/views/Icon.vue";
import allPermisionGroups from "@/graphql/eboard-auth-graphql/allPermisionGroups.gql";
export default {
  name: "AddNewSystemAdministratorRole",
  components: {Icon},


  data: () => ({
    roleUniqueId: "",
    roleName: "",
    roleDescription: "",
    rolePermissions: [],
    dialog: false,
    newUserRoleKey: 0,

    permissions: "",
    permissiongroups: []
  }),

  props: ["newSystemAdministratorRoleDialog"],


  methods: {
    closeRoleDialog(){
      this.roleDescription = ""
      this.roleName = ""
      this.permissions = ""

      this.$emit('close-role-dialog')
    },

    createSystemAdminRoles(){
      let input = {
        roleName: this.roleName,
        roleDescription: this.roleDescription,
        rolePermissions: this.permissions
      }

      this.$store.dispatch('createSystemAdminRoles', input)
      this.roleName = ""
      this.roleDescription = ""
      this.rolePermissions = []
      this.permissions = ""
      this.$emit('close-role-dialog')
    },
  },

  created(){

    this.$apollo.query({
      query:allPermisionGroups
    }).then((response)=>{
      let groups_response=response.data.userPermissionsGroups.edges;
      groups_response.forEach(group => {
        let group_id=group.node.permissionGroupUniqueId
        let group_name=group.node.permissionGroupName
        let group_children=[]

        group.node.permissionGroup.edges.forEach(children => {
          group_children.push({
            id:children.node.permissionUniqueId,
            name:children.node.permissionName
          })
        });

        this.permissiongroups.push(
            {
              id:group_id,
              name:group_name,
              children:group_children
            }
        )
      });
    })
  },

}
</script>

<style scoped>

</style>