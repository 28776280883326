<template>
  <nav>
    <v-app-bar  dense  app style="background: #3f51b5;color: #fff;">
      <v-app-bar-nav-icon><v-icon  @click="mini = !mini" class="white--text" left>mdi-menu</v-icon></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text">
        <router-link to="/management-committee/my-dashboard" style="text-decoration: none">
          <span class="white--text">{{ currentBoardCommittee }}</span>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon>
        <router-link to="/" style="text-decoration: none">
          <v-icon class="white--text" color="white" left>mdi-home</v-icon>
        </router-link>
      </v-app-bar-nav-icon>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" left>mdi-help-circle-outline</v-icon>
        </template>
        <v-list>
          <v-list-item link >
            <v-list-item-content>
              <v-list-item-title><v-icon>mdi-note-text</v-icon> User Manual</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--          <v-list-item link >-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title><v-icon>mdi-information-variant</v-icon> About e-Board</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->
        </v-list>

      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" right>mdi-chevron-down</v-icon>
          <span style="padding-left:10px">{{logeduser.fullname}}</span>
        </template>
        <v-list>
          <v-list-item v-for="link in topLinks" :key="link.name" link :to="link.routinglink">
            <v-list-item-content>
              <v-list-item-title><v-icon>{{ link.icon }}</v-icon> {{ link.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="changePassword">
            <v-list-item-content>
              <v-list-item-title
              >
                <v-icon>mdi-lock</v-icon>
                Change Password
              </v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="callLogoutMethod" >
            <v-list-item-content>
              <v-list-item-title><v-icon>mdi-power</v-icon> Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-menu>
    </v-app-bar >

    <v-card >
      <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          v-if="!hasHandoverDuties()"
          permanent
          app
      >
        <v-list-item class="px-2 intro-y ">
          <v-list-item-avatar>
            <v-img :src="MEDIA_SERVER+currentBoardLogo"></v-img>
          </v-list-item-avatar>
          <v-spacer></v-spacer>

          <v-btn
              icon
              @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <template v-for="link in links">
            <!--            v-if="hasAccess(link.permision)" -->
            <v-list-item class="-intro-x" link :to="link.routinglink" :key="link.name" >
              <v-list-item-icon  class="iconclass">
                <v-icon style="color:rgba(63, 81, 181, 0.90)">{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </nav>
</template>

<script>
import allBoardsByUser from '@/graphql/eboard-boards-graphql/allBoardsByUser.gql'
import store from '@/store'

export default {
  data() {
    return {
      board_account:"/board_account/",
      drawer: true,
      mini:false,
      topLinks:[
        {
          name:'My Profile',
          icon:'mdi-account',
          routinglink:'/management/management_my_profile'
        },
        // {
        //   name:'Help',
        //   icon:'mdi-account',
        //   routinglink:'management_meetings/my-eboards'
        // },
      ],
      links:[
        {
          name:'Meetings Schedule',
          icon:'mdi-calendar',
          routinglink:'/board-committee/meeting-schedules',
          permision:'can_view_management_meeting'
        },
        // {
        //   name:'Meeting Repository',
        //   icon:'mdi-palette-swatch',
        //   routinglink:'/management-committee/meeting-repository',
        //   permision:'can_view_management_repository'
        // },
        // {
        //   name:'Meeting Recommendations',
        //   icon:'mdi-note',
        //   routinglink:'/management-committee/meeting-recommendations',
        //   permision:'can_view_management_repository'
        // },
      ],
      logeduser:{'fullname':""}
    }
  },
  apollo: {
    allBoardsByUser: allBoardsByUser,
  },

  computed:{
    currentBoardCommittee(){
      return localStorage.getItem('my-boardCommitteeName')
    },
    currentBoardLogo() {
      return localStorage.getItem('my-boardCommitteeLogo')
    }
  },

  methods:{
    callLogoutMethod(){
      localStorage.removeItem('vuex')
      localStorage.removeItem('my-boardUniqueId')
      localStorage.removeItem('my-boardKey')
      localStorage.removeItem('my-managementCommitteeName')
      localStorage.removeItem('my-managementCommitteeUniqueId')
      localStorage.removeItem('my-boardCommitteeName')
      localStorage.removeItem('my-boardCommitteeUniqueId')
      localStorage.removeItem('apollo-token')
      this.$router.go('/landing_page')
    },
    changePassword() {
      this.$router.push('/change-password')
    },
    changeSelectedBoard(board_uuid,board_name){
      this.$store.dispatch('updateCurrentMyboardName',board_name)
      localStorage.setItem('my-boardUniqueId',board_uuid)
      this.$router.go('')
    }
  },
  async mounted(){
    await this.$store.dispatch("SettingsStore/getBoardMeetingSettings", {
      root: true,
    });
    await this.$store.dispatch("RolesStore/loadUserPermissionsByBoard", {
      root: true,
    });
    const userDetails = store.getters['AuthStore/user']
    this.logeduser.fullname=userDetails.firstName +" "+userDetails.lastName
  }
}
</script>

