<template>
  <v-row justify="center">
    <v-dialog
        v-model="editEventActivityDialog"
        persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h6"
          ><v-icon>mdi-calender </v-icon> Edit Activity
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="activityDescription"
                    :rules="nameRules"
                    :counter="100"
                    required
                    label="Activity Description"
                    prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>

              <!-- Start Time -->
              <v-col cols="12" sm="6" md="6">
                <v-dialog ref="dialog1" v-model="modal" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="activityStartTime"
                        label="Start Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="modal" v-model="activityStartTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <!-- End Time -->
              <v-col cols="12" sm="6" md="6">
                <v-dialog ref="dialog" v-model="modal2" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="activityEndTime"
                        label="End Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="modal2"
                      v-model="activityEndTime"
                      :min="activityStartTime"
                      full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="activityResponsiblePersonel"
                    :rules="nameRules"
                    :counter="100"
                    label="Responsible Personnel*"
                    required
                    prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>

            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              fab
              small
              class="white--text"
              @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-btn
              color="blue darken-1"
              fab
              small
              class="white--text"
              @click="editBoardActivity"
          >
            <v-icon>mdi-send-check-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    <LocationSettings-->
<!--        :openLocationDialog="openLocationDialog"-->
<!--        @closeNewLOcationDialog="openLocationDialog = !openLocationDialog"-->
<!--    />-->
<!--    <v-overlay :z-index="zIndex" :value="overlay">-->
<!--      <v-card color="indigo" dark width="300">-->
<!--        <v-card-text>-->
<!--          We are Setting Up Activity Please wait . . .-->

<!--          <v-progress-linear indeterminate color="white" class="mb-2">-->
<!--          </v-progress-linear>-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--    </v-overlay>-->
  </v-row>
</template>

<script>

export default {
  props: [
    "editEventActivityDialog",

    "activityEventUniqueId",
    "activityUniqueId",
    "activityDescription",
    "activityStartTime",
    "activityEndTime",
    "activityResponsiblePersonel",
  ],


  data: () => ({
    overlay: false,
    zIndex: 0,
    hidden: false,
    loading: false,
    openLocationDialog: false,
    defaultSelected: "",
    timezone: "",
    selectedLocations: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    menu: false,
    menu2: false,
    modal: false,
    modal1: false,
    time: null,
    endTime: "",
    startTime: "",
    modal2: false,

    dialog: false,
    eventStartDate: "",
    eventEndDate: "",
    eventTittle: "",
    nameRules: [
      (v) => !!v || "Event title is required",
      (v) => v.length <= 100 || "Title must be less than 100 characters",
    ],
    createdActivity: "",

    activator: null,
    attach: null,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    editing: null,
    editingIndex: -1,
    items: [
      { header: 'Select event color' },
      {
        text: 'Blue',
        color: 'blue',
      },
      {
        text: 'Red',
        color: 'red',
      },

      {
        text: 'Purple',
        color: 'purple',
      },

      {
        text: 'Indigo',
        color: 'indigo',
      },

      {
        text: 'Orange',
        color: 'orange',
      },
    ],
    nonce: 1,
    menu3: false,
    model: [
      {
        text: 'Blue',
        color: 'blue',
      },
    ],
    x: 0,
    search: null,
    y: 0,
  }),

  watch: {
    model (val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          }

          this.items.push(v)

          this.nonce++
        }

        return v
      })
    },
  },


  methods: {
    async closeDialog(){
      this.activityDescription = ""
      this.activityStartTime = ""
      this.activityEndTime = ""
      this.activityResponsiblePersonel = ""

      await this.$emit('close-dialog')
    },

    getSelectedTimeZone() {
      this.timezone = this.defaultSelected.locationTimezone;
    },

    async editBoardActivity(){
      let updatedActivity = {
        activityUniqueId : this.activityUniqueId,
        activityEventUniqueId : this.activityEventUniqueId,
        activityDescription : this.activityDescription,
        activityStartTime : this.activityStartTime,
        activityEndTime : this.activityEndTime,
        activityResponsiblePersonel : this.activityResponsiblePersonel
      }
      await this.$store.dispatch('updateEventActivity', updatedActivity)
      this.activityUniqueId = ""
      this.activityEventUniqueId = ""
      this.activityDescription = ""
      this.activityStartTime = ""
      this.activityEndTime = ""
      this.activityResponsiblePersonel = ""

      this.$emit('close-dialog')
      this.$store.dispatch('loadEventActivities',this.$route.params.event_id)
    },

    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        this.editing = null
        this.editingIndex = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    },

  },
};
</script>

<style>
</style>
