<template>
  <v-container fluid app>
    <AddnewMeeting
        :showContextMenu="showContextMenu"
        :is-committees="true"
        @closeNewMeeitngDialog="showContextMenu = !showContextMenu; loadMeetings()"
    />

      <v-row>
        <v-toolbar  rounded elevation="0" dense>
          <v-app-bar-nav-icon><v-img src="@/assets/img/commitee.png" width="70px"/></v-app-bar-nav-icon>
          <v-toolbar-title style="color:rgba(28, 26, 64, 0.88)">Committees Meetings</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip
              v-if="hasAccess('can_schedule_meeting')"
              bottom
              color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="my-4"
                  color="primary white--text"
                  outlined
                  rounded
                  right
                  small
                  style="margin: 5px"
                  v-bind="attrs"
                  @click="showContextMenu = !showContextMenu"
                  v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
                Create Meeting
              </v-btn>
            </template>
            <span>Create New Meeting</span>
          </v-tooltip>
        </v-toolbar>
        <v-col cols="12" md="12" sm="12">
            <CommitteeMeetingCard />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import AddnewMeeting from "@/modules/meeting_management/eboard-meetings/AddnewMeeting.vue";
import CommitteeMeetingCard from "@/modules/meeting_management/eboard-meetings/CommitteeMeetingCard.vue";

export default {
  data() {
    return {
      showContextMenu: false,
    };
  },
  components: {
    CommitteeMeetingCard,
    AddnewMeeting,
  },

  methods: {
    async loadMeetings(){
      await this.$store.dispatch("loadBoardMeetingsList");
      await this.$store.dispatch("loadBoardCommitteesMeetingsList");
    }
  },

  async mounted() {
    this.loadMeetings()
  },
};
</script>



