<template>
  <v-row justify="center">
    <v-dialog
        v-model="meetingRepresentativeDialog"
        max-width="1000px"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-account-group-outline</v-icon> {{ editMeetingRepresentative ? 'Edit' : 'Add' }} Meeting Representatives</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row v-for="(representative, index) of meetingRepresentative"
                   :key="index">

              <v-col cols="6" class="flex items-center">
                <div class="w-full">
                  <v-text-field
                      v-model="representative.representativeFullName"
                      :counter="100"
                      :rules="nameRules"
                      label="Full Name *"
                      prepend-icon="mdi-text-recognition"
                      required
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col cols="6" class="flex items-center">
                <div class="w-full">
                  <v-text-field
                      v-model="representative.representativeDesignation"
                      :counter="100"
                      :rules="nameRules"
                      label="Designation *"
                      prepend-icon="mdi-text-recognition"
                      required
                  >
                  </v-text-field>
                </div>
                <div class="flex">
                  <v-btn icon class="ml-2"
                         v-if="index !== 0"
                         @click="removeRepresentative(index)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                  <v-btn icon
                         v-if="index === meetingRepresentative.length - 1"
                         @click="addRepresentative">
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="white--text"
              color="pink"
              small
              @click="$emit('closeNewRepresentativeDialog')"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>

          <v-btn
              v-if="!editMeetingRepresentative"
              class="white--text"
              color="blue darken-1"
              small
              @click="createNewRepresentative"
          >
            <v-icon>mdi-floppy</v-icon>
            Save
          </v-btn>

          <v-btn
              v-else
              class="white--text"
              color="blue darken-1"
              small
              @click="editNewRepresentative"
          >
            <v-icon>mdi-floppy</v-icon>
            Save Changes
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


  </v-row>
</template>


<script>

export default {
  name: "AddNewRepresentative",
  data: () => ({
    newMeetingInvitees: true,
    nameRules: [
      v => !!v || 'Full name is required',
      v => v.length <= 100 || 'Full name must be less than 100 characters',
    ],
    emailRules: [v => /.+@.+/.test(v) || 'Invalid Email address'],
    meetingRepresentative: [{representativeFullName: '', representativeDesignation: ''}]
  }),
  props: ["meetingRepresentativeDialog", 'meetingUniqueId', 'editMeetingRepresentative'],

  watch: {
    meetingRepresentativeDialog() {
      if (this.meetingRepresentativeDialog && this.editMeetingRepresentative) {
        if (this.$store.getters.getManagementMeetingRepresentatives.length !== 0)
          this.meetingRepresentative = this.$store.getters.getManagementMeetingRepresentatives.map((representative) => {
            return {
              representativeFullName: representative.representativeFullName,
              representativeDesignation: representative.representativeDesignation
            }
          })
      }
    }
  },

  methods: {
    addRepresentative() {
      this.meetingRepresentative.push({representativeFullName: '', representativeDesignation: ''})
    },
    removeRepresentative(index) {
      this.meetingRepresentative.splice(index, 1)
    },
    createNewRepresentative() {
      let input = {
        meetingUniqueId: this.meetingUniqueId,
        representatives: this.meetingRepresentative
      }
      this.$store.dispatch('createManagementMeetingRepresentative', input)

      this.$emit('closeNewRepresentativeDialog')
      this.meetingRepresentative = [{representativeFullName: '', representativeDesignation: ''}]
    },
    editNewRepresentative() {
      let input = {
        meetingUniqueId: this.meetingUniqueId,
        representatives: this.meetingRepresentative
      }
      this.$store.dispatch('editManagementMeetingRepresentatives', input)

      this.$emit('closeNewRepresentativeDialog')
      this.meetingRepresentative = [{representativeFullName: '', representativeDesignation: ''}]
    }

  },
}
</script>
