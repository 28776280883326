import {apolloClient} from '@/vue-apollo'
import getManagementCommitteeNotes
    from '../../graphql/management-committee-agenda-notes-graphql/getManagementCommitteeNotes.gql'

import updateManagementCommitteeNotesMutation
    from '../../graphql/management-committee-agenda-notes-graphql/updateManagementCommitteeNotesMutation.gql'
import createManagementCommitteeNotesMutation
    from '../../graphql/management-committee-agenda-notes-graphql/createManagementCommitteeNotesMutation.gql'

import deleteManagementCommitteeNotesMutation
    from '../../graphql/management-committee-agenda-notes-graphql/deleteManagementCommitteeNotesMutation.gql'

import {errorAlert, successAlert} from "@/services/notificationService";


export default {
    state: {
        managementCommitteeAgendaNotes: null,
        noteFound: false,
    },
    getters: {
        getManagementCommitteeAgendaNotes(state) {
            return state.managementCommitteeAgendaNotes
        },
        getManagementCommitteeAgendaNotesStatus(state) {
            return state.noteFound
        },
    },
    mutations: {
        clearManagementCommitteeAgendaNotes(state) {
            state.managementCommitteeAgendaNotes = {id: null, notesDescription: null}
            state.noteFound = false
        },
        saveManagementCommitteeAgendaNotes(state, notes) {
            state.managementCommitteeAgendaNotes = notes
        },
        setManagementCommitteeAgendaNotesStatus(state, status) {
            state.noteFound = status
        },
        updateManagementCommitteeAgendaNotes(state, editedNote) {
            state.managementCommitteeAgendaNotes = editedNote
            state.noteFound = true
        },
    },
    actions: {
        async loadManagementCommitteeAgendaNotes(context, agendaUniqueId) {
            context.commit('clearManagementCommitteeAgendaNotes')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getManagementCommitteeNotes,
                variables: {
                    agendaUniqueId
                }
            }).then(async (response) => {
                let result = response.data.getManagementCommitteeNotes
                if (result.response.code === 9000) {
                    if (result.data) {
                        context.commit('saveManagementCommitteeAgendaNotes', result.data)
                        context.commit('setManagementCommitteeAgendaNotesStatus', true)
                    } else context.commit('saveManagementCommitteeAgendaNotes', {id: null, notesDescription: null})
                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async deleteManagementCommitteeAgendaNote(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteManagementCommitteeNotesMutation,
                variables: {
                    input
                }
            }).then(async (response) => {
                let result = response.data.deleteManagementCommitteeNotesMutation
                if (result.response.code === 9000) {
                    context.commit('clearManagementCommitteeAgendaNotes')
                    await successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async createManagementCommitteeAgendaNote(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createManagementCommitteeNotesMutation,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createManagementCommitteeNotesMutation
                if (result.response.code === 9000) {
                    context.commit('updateManagementCommitteeAgendaNotes', result.data)
                    await successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async editManagementCommitteeAgendaNote(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateManagementCommitteeNotesMutation,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateManagementCommitteeNotesMutation
                if (result.response.code === 9000) {
                    context.commit('updateManagementCommitteeAgendaNotes', result.data)
                    await successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },


    },


}
