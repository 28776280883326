<template>
  <v-container fluid>
    <v-row>
      <div class="grid grid-cols-12 gap-12 mt-8 w-full">
        <div class="col-span-12">
          <div class="w-full d-flex justify-content-between">
            <h2 class="intro-y text-lg font-medium mr-auto mt-2">
              Circular Resolutions
            </h2>
            <div>
              <button
                  v-if="hasAccess('can_add_resolution')"
                  @click="OpenNoticeDialog = !OpenNoticeDialog"
                  class="btn btn-primary shadow-md mr-2 mt-1"
              >
                <v-icon color="white">mdi-clipboard-plus-outline</v-icon> Add
                Resolution
              </button>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-12 2xl:col-span-12">
          <div class="intro-y flex flex-col-reverse sm:flex-row items-center">
            <div class="w-full sm:w-auto flex">
<!--              <v-spacer></v-spacer>-->
<!--              <button-->
<!--                type="button"-->
<!--                class="btn text-slate-600 dark:text-slate-300 bg-white dark:bg-darkmode-300 border border-dotted border-slate-400 mt-1 mr-2"-->
<!--              >-->
<!--                <v-icon>mdi-video-plus-outline</v-icon> Video Call Meetings-->
<!--              </button>-->
<!--              <select v-model="filterDate"  class="sm:ml-auto mt-1 sm:mt-0 sm:w-auto form-select box border border-dotted border-slate-400">-->
<!--                <option value="yearly">Yearly</option>-->
<!--                <option value="weekly">Weekly</option>-->
<!--                <option value="monthly">Monthly</option>-->
<!--              </select>-->
            </div>
          </div>

          <div class="flex mt-5">
            <button
                v-if="hasAccess('can_add_resolution')"
                @click="openTab = 'draft'"
                :class="`flex ma-1 border px-3 py-2 mt-2 ${openTab === 'draft' ? 'active_tab shadow-sm' : 'border-dotted border-slate-400 rounded-md'}`"
            >
              <v-icon color="" class="pr-2">mdi-email-outline</v-icon> New / Draft
              Resolutions
            </button>
            <button
              @click="openTab = 'published'"
              :class="`flex ma-1 border px-3 py-2 mt-2 ${openTab === 'published' ? 'active_tab shadow-sm' : 'border-dotted border-slate-400 rounded-md'}`"
            >
              <v-icon color="" class="pr-2">mdi-email-open-outline</v-icon> Published
              Resolutions
            </button>
            <button
              @click="openTab = 'closed'"
              :class="`flex ma-1 border px-3 py-2 mt-2 ${openTab === 'closed' ? 'active_tab shadow-sm' : 'border-dotted border-slate-400 rounded-md'}`"
            >
              <v-icon color="" class="pr-2">mdi-email-check-outline</v-icon> Completed
              Resolutions
            </button>
          </div>

          <div v-if="openTab == 'published'" class="intro-y inbox mt-5">
            <h2 class="intro-y text-lg font-medium mr-auto mt-2">
              Active Circular Resolutions
            </h2>
            <div
              v-for="resolution in activePublishedBoardResolutions"
              :key="resolution.primaryKey"
              style="padding: 10px"
              class="intro-y"
            >
              <ResolutionCard :resolution="resolution" />
            </div>
          </div>
          <div v-if="openTab == 'closed'" class="intro-y inbox mt-5">
            <h2 class="intro-y text-lg font-medium mr-auto mt-2">
              Closed /Completed Circular Resolutions
            </h2>
            <div
              v-for="resolution in closedPublishedBoardResolutions"
              :key="resolution.primaryKey"
              style="padding: 10px"
              class="intro-y"
            >
              <ResolutionCard :resolution="resolution" />
            </div>
          </div>
          <div v-if="openTab == 'draft'" class="intro-y inbox mt-5">
            <h2 class="intro-y text-lg font-medium mr-auto mt-2">
              New / Draft Circular Resolutions
            </h2>
            <div
              v-for="resolution in unPublishedBoardResolutions"
              :key="resolution.primaryKey"
              style="padding: 10px"
              class="intro-y"
            >
              <ResolutionCard :resolution="resolution" />
            </div>
          </div>
        </div>
      </div>
    </v-row>

    <NewCircularResolution
      :OpenNoticeDialog="OpenNoticeDialog"
      @CloseNewCircularDialog="OpenNoticeDialog = false"
    />

    <v-dialog v-model="commentsPreviewDialog" width="50%">
      <ResolutionComments />
    </v-dialog>

    <DocumentViwer
      v-if="openFileDialog === true"
      :openFileDialog="openFileDialog"
      :docValue="docValue"
      :path="path"
      :title="title"
      @CloseDocumentViwer="openFileDialog = false"
    />
  </v-container>
</template>

<script>
import ResolutionComments from "./ResolutionComments.vue";
import NewCircularResolution from "./NewCircularResolution.vue";
import ResolutionCard from "./ResolutionCard.vue";
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";

export default {
  components: {
    DocumentViwer,
    ResolutionComments,
    NewCircularResolution,
    ResolutionCard,
  },

  data() {
    return {
      filterDate:"yearly",
      path: "",
      title: "",
      voteData: null,
      motion: null,
      docValue: null,
      openFileDialog: false,
      voteDialog: false,
      OpenNoticeDialog: false,
      filePreviewDialog: false,
      commentsPreviewDialog: false,
      suppotingDocs: [],
      primaryKey: "",
      resolution: "",
      openTab: "published",
    };
  },

  computed: {
    activePublishedBoardResolutions() {
      return this.$store.getters.publishedCircularResolution;
    },
    closedPublishedBoardResolutions() {
      return this.$store.getters.closedCircularResolution;
    },
    unPublishedBoardResolutions() {
      return this.$store.getters.unPublishedCircularResolution;
    },

    myUsername() {
      return this.$store.state.AuthStore.user.username;
    },
  },

  methods: {
    openSupportingDocument(docs) {
      this.suppotingDocs = [];
      this.suppotingDocs = docs;
      this.filePreviewDialog = true;
    },

    openAppealAttachment(docname, path) {
      this.title = docname;
      this.docValue = path;
      this.openFileDialog = true;
    },

    openVotingResultsPage(resolution, primaryKey, votes, motion) {
      this.resolution = resolution;
      this.motion = motion;
      this.voteData = votes;
      this.primaryKey = primaryKey;
      this.voteDialog = true;
    },
  },

  async mounted() {
    this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("loadBoardResolutions");
    await this.$store.dispatch("updateMyResolutionNotifications");
    this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>

<style>

.active_tab {
  background-color: white;
  border-bottom: 3px solid #1F3A8A;
  border-radius: 5px 5px 0 0;
}

</style>
