<template>
  <v-banner outlined type="">
    <v-chart
      v-if="this.graphData.source.length > 0"
      class="chart"
      :option="option"
    />
    <div v-else>
      <strong style="color: rgba(132, 84, 29, 0.8)"
        >No data to display for now.</strong
      >
      <br /><i
        ><small
          >You need to have some transactions in directives and implementations
          in your <strong>departments and units</strong> so as to have full
          visibility on this dashboard.</small
        ></i
      >
    </div>
  </v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import * as echarts from "echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

// data: this.directivesByDesignations.directivesByDesignations.length >0 ? this.directivesByDesignations.directivesByDesignations: [],
export default {
  props: ["directivesByDesignations"],
  name: "DireDesi",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {};
  },
  computed: {
    graphData() {
      var temp_data=[]
      this.directivesByDesignations.directivesImplementationByDesignations.forEach(designation => {
       designation=JSON.parse(designation);
        temp_data.push(
          {
            'designation': designation.designation,
            "Not-Started": designation.NotStarted,
            "On-Progress": designation.OnProgress,
            'Completed': designation.Completed,
          });
        
      });
      return {
        dimensions: ["designation", "Not-Started", "On-Progress", "Completed"],
        source: temp_data,
      };
    },
    option() {
      return {
        title: {
          text: "Directives implementations per designation",
          top: "left",
          textStyle: {
            color: "rgba(132, 84, 29, 0.8)",
          },
        },
        tooltip: {
          trigger: "axis",
          showContent: true,
        },
        legend: {
          orient: "horizontal",
          bottom: "left",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
            dataZoom: { show: true },
          },
        },
        dataset: {
          dimensions: this.graphData.dimensions,
          source: this.graphData.source,
          seriesLayoutBy: "row",
        },
        xAxis: {},
        yAxis: { type: "category", show: true },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          { type: "bar", symbolSize: 20 },
          { type: "bar", symbolSize: 20 },
          { type: "bar", symbolSize: 20 },
        ],
      };
    },
  },
  mounted() {
    var dom = document.getElementById("container");
    var myChart = echarts.init(dom, null, {
      renderer: "canvas",
      useDirtyRect: false,
    });
    if (this.option && typeof this.option === "object") {
      myChart.setOption(this.option);
    }
  },
};
</script>

<style scoped>
.chart {
  height: 500px;
}
</style>
