<template>
  <v-row justify="center">
    <v-dialog
        :key="newCommitteeMeetingKey"
        v-model="newManagementCommitteeMeeting"
        max-width="50%"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6 flex items-center"
          ><icon name="meeting_add" width="w-5" height="h-5" class="mr-2"></icon> New
            Committee Meeting</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
                cols="12"
                md="12"
                sm="12"
            >
              <v-text-field
                  v-model="meetingTitle"
                  :counter="100"
                  :rules="nameRules"
                  label="Title *"
                  prepend-icon="mdi-text-recognition"
                  required
              >
              </v-text-field>
              <v-select
                  v-model="meetingCategory"
                  :items="meetingCategories"
                  item-text='title'
                  item-value='uniqueId'
                  label="Meeting Category *"
                  prepend-icon="mdi-shape-outline"
                  required
              >
              </v-select>
            </v-col>

            <v-col
                cols="12"
                md="4"
                sm="6"
            >
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="meetingDate"
                      label="Date *"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(meetingDate)"
                      v-on="on"

                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="meetingDate"
                    :min="date"
                    no-title
                    @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col
                cols="12"
                md="4"
                sm="6"
            >
              <v-dialog
                  ref="dialog1"
                  v-model="modal1"
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="startTime"
                      label="Start Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="modal1" v-model="startTime" full-width>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal1 = false">
                    Cancel
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col
                cols="12"
                md="4"
                sm="6"
            >
              <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="endTime"
                      label="End Time *"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="modal2"
                    v-model="endTime"
                    :min="startTime"
                    full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">
                    Cancel
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col
                cols="12"
                sm="12"
            >
              <v-select
                  v-model="defaultSelected"
                  :hint="timezone"
                  :items="locations"
                  item-text='location'
                  item-id='locationUniqueId'
                  label="Location *"
                  persistent-hint
                  prepend-icon="mdi-map-marker"
                  required
                  return-object
                  @change="getSelectedTimeZone"
              >
                <template v-slot:selection="{ item }">
                  <v-chip small text-color="">{{ item.location }}</v-chip>
                </template>
              </v-select>
            </v-col>

          </v-row>

          <div class="flex justify-end text-white">
            <v-btn color="red"
                   class="text-capitalize mr-2"
                   right
                   small
                   @click="closeDialog"
            >
              <icon name="close" width="w-5" height="h-5" color="#ffffff"></icon>
              <span class="text-white">Cancel</span>
            </v-btn>
            <v-btn color="primary"
                   class="text-capitalize"
                   right
                   small
                   @click="saveManagementCommitteeMeeting"
            >
              <icon name="save" width="w-5" height="h-5" color="#ffffff"></icon>
              Add Meeting
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Icon from "@/views/Icon.vue";

export default {
  name: "AddNewManagementCommitteeMeeting",
  components: {Icon},
  props: ["newManagementCommitteeMeeting"],
  data() {
    return {
      overlay: false,
      newCommitteeMeetingKey: 0,
      zIndex: 0,
      hidden: false,
      checkbox: false,
      loading: false,
      openLocationDialog: false,
      timezone: '',
      defaultSelected: '',
      selectedLocations: [],
      time: null,
      menu: false,
      menu4: false,
      menu3: false,
      modal2: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,
      menu2: false,
      timeCounter: 0,
      picker: new Date().toISOString().substr(0, 7),
      dialog: false,
      dialog1: false,
      modal1: false,
      meetingDate: '',
      startTime: '',
      meetingCategory: '',
      endTime: '',
      meetingTitle: '',
      nameRules: [
        v => !!v || 'Meeting title is required',
        v => v.length <= 100 || 'Title must be less than 100 characters',
      ],
      createdMeeting: ''
    }
  },
  async mounted() {
    await this.$store.dispatch("SettingsStore/loadBoardMeetingLocations",{root:true});
    await this.$store.dispatch("loadManagementMeetingCategories", true);
  },
  computed: {
    meetingCategories() {
      return this.$store.state.ManagementCategoriesStore.managementMeetingCategories
    },
    locations() {
      return this.$store.state.SettingsStore.boardLocations
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeNewCommitteeMeetingDialog')
    },
    getSelectedTimeZone() {
      this.timezone = this.defaultSelected.locationTimezone
    },
    async saveManagementCommitteeMeeting() {
      let input = {
        meetingTitle: this.meetingTitle,
        meetingDate: this.meetingDate,
        meetingCategory: this.meetingCategory,
        meetingEndTime: this.endTime,
        meetingStartTime: this.startTime,
        meetingLocation: this.defaultSelected.locationUniqueId,
      }

      await this.$store.dispatch('createManagementCommitteeMeeting', input)
      this.closeDialog()
      this.meetingTittle = null
      this.meetingDate = null
      this.startTime = null
      this.endTime = null
      this.meetingCategory = null
      this.defaultSelected = ''
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  }
}
</script>

<style scoped>

</style>