<template>
  <v-container fluid>


<!--    <div class="bg-white p-3 rounded-r-md border-l-2 border-primary shadow-sm">-->
<!--      <div class="flex justify-content-between">-->
<!--        <div class="progress-card" style="color: #00bfa5">-->
<!--          In Progress-->
<!--        </div>-->
<!--        <div>-->
<!--          End Date-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->




    <div
      style="padding-top: 30px"
      v-for="vote in votes"
      :key="vote.voteUniqueId"
      class="intro-y"
    >
      <v-card class="mx-auto" max-width="90%" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-4">
              <v-chip
                v-if="!vote.voteIsActive"
                color="pink"
                outlined
                small
                style="text-transform: capitalize"
                >Closed</v-chip
              >
              <v-chip
                v-else
                color="success"
                outlined
                small
                style="text-transform: capitalize"
                >In Progress</v-chip
              >&nbsp;
              <small
                ><span>End Time: </span> {{ vote.voteEndDate | formatDate }}
                {{ vote.voteEndTime }}
              </small>
            </div>
            <small style="color: #533dd1"
              ><span>Vote Source: </span> {{ vote.voteSource }}</small
            >
            <v-list-item-title class="text-wrap mb-1">
              <strong>{{ vote.voteMotion }}</strong>
            </v-list-item-title>
          </v-list-item-content>

          <div class="text-overline mb-3">
            <strong>My Vote </strong>
            <span
              v-if="!vote.voterIsVoted"
              style="text-transform: capitalize; color: rgba(216, 82, 69, 0.95)"
            >
              Vote Awaiting . . .
            </span>
            <v-list v-else subheader two-line>
              <v-list-item>
                <v-list-item-avatar v-if="vote.voterSelection == 'AB'">
                  <v-icon class="blue lighten-1" dark>
                    mdi-thumbs-up-down
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-avatar v-else-if="vote.voterSelection == 'SP'">
                  <v-icon class="success lighten-1" dark> mdi-thumb-up </v-icon>
                </v-list-item-avatar>

                <v-list-item-avatar v-else-if="vote.voterSelection == 'OP'">
                  <v-icon class="red lighten-1" dark> mdi-thumb-down </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      vote.voterSelection === "AB"
                        ? "ABSTAIN"
                        : vote.voterSelection === "SP"
                        ? "SUPPORT"
                        : "OPPOSE"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <!-- {{vote}} -->
          <v-alert dense v-if="vote.voterIsVoted"
            ><v-icon color="success">mdi-check-circle-outline</v-icon> Approved
            | You Supported
            <p>Vote has been passed</p></v-alert
          >

          <v-list-item-avatar tile size="50" color="white"
            ><v-img src="/vote.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-card-actions v-if="!vote.voterIsVoted">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                right
                top
                absolute
                rounded
                color="primary"
                small
                @click="
                  voteDialog = true;
                  userCastVote = vote.primaryKey;
                "
              >
                <v-icon>mdi-vote</v-icon>
              </v-btn>
            </template>
            <span>Cast Your Vote</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </div>

    <v-dialog v-model="voteDialog" max-width="60%">
      <v-card>
        <v-card-title><v-icon>mdi-vote-outline</v-icon> Vote</v-card-title>
        <v-card-text>
          <v-expansion-panels
            v-model="panel"
            :popout="popout"
            :hover="hover"
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                >Cast Your Vote</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-radio-group v-model="castedSelection">
                  <template v-slot:label>
                    <div>
                      Your vote is recorded as <strong>anonymous</strong> in the
                      minutes
                    </div>
                  </template>
                  <v-radio value="SP">
                    <template v-slot:label>
                      <div>
                        <v-icon color="success">mdi-thumb-up</v-icon
                        ><strong class="success--text">Support</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="OP">
                    <template v-slot:label>
                      <div>
                        <v-icon color="warning">mdi-thumb-down</v-icon>
                        <strong class="warning--text">Oppose</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="AB">
                    <template v-slot:label>
                      <div>
                        <v-icon color="primary">mdi-minus</v-icon>
                        <strong class="primary--text">Abstain</strong>
                      </div>
                    </template>
                  </v-radio>

                  <template>
                    <div>
                      Vote carefully your vote
                      <strong>can not be edited</strong>
                    </div>
                  </template>
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink darken-1"
            class="white--text"
            small
            @click="voteDialog = false; castedSelection=null"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="castedSelection"
            color="blue darken-1"
            small
            class="white--text"
            @click="castUserVote()"
            :loading="submiting"
          >
            Vote
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "sweetalert2";
export default {
  data() {
    return {
      panel: [0],
      readonly: true,
      disabled: true,
      hover: false,
      popout: true,
      voteDialog: false,
      inset: true,
      submiting: false,
      castedSelection: null,
      userCastVote: null,
    };
  },

  computed: {
    votes() {
      return this.$store.state.VotesStore.activeVotes;
    },
    enums() {
      return this.state.enumsResponding;
    },
  },
  methods: {
    async castUserVote() {
      var vote_option = "ABSTAIN";
      if (this.castedSelection == "OP") {
        vote_option = "OPPOSE";
      } else if (this.castedSelection == "SP") {
        vote_option = "SUPPORT";
      }
      swal
        .fire({
          icon: "info",
          title:"Are you sure ?",
          text:"You want to submit your vote as - " + vote_option,

          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showDenyButton: true,
          confirmButtonText: "YES, Submit",
          confirmButtonColor: "#3cb081",
          denyButtonText: `NO, Cancel`,
          timer: 10000,
          timerProgressBar: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.sendCastedUserVote();
            swal.fire({
              toast: true,
              icon: "success",
              title: "Vote casted successfully",
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },
    async sendCastedUserVote() {
      this.$store.dispatch("showLoadingSpinner", true);
      var data = {
        castedSelection: this.castedSelection,
        userCastVote: this.userCastVote,
      };
      await this.$store.dispatch("castUserVote", data);
      this.$store.dispatch("showLoadingSpinner", false);
      this.voteDialog = false;
    },
  },

  async mounted() {
    this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("loadActiveVotes");
    this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>

<style scoped>
.progress-card {
  color: #4CAF4F !important;
  background-color: rgba(76, 175, 79, 0.40);
}
</style>
