<template>
  <v-dialog v-model="dialogVisible" max-width="500">
    <v-card>
      <v-card-title>Select User who will receive your duties</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="handOverMyDuties">
          <v-select
              v-model="selectedMember"
              :items="managementMembers"
              item-value="id"
              label="Select user"
              required
          >
            <template #selection="{ item }">
              {{ item.firstName }} {{ item.lastName }}
            </template>
            <template #item="{ item }">
              {{ item.firstName }} {{ item.lastName }}
            </template>
          </v-select>
          <v-card-actions>
            <v-btn @click="dialogVisible = false" outlined color="pink" small
            ><v-icon left>mdi-close</v-icon> Cancel</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" small type="submit">
              <v-icon left>mdi-check</v-icon> Submit</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import router from '../../../../router/index.js'
export default {
  name: "ManagementHandOverForm",
  props: {
    handoverDialog: Boolean,
  },
  data() {
    return {
      selectedMember: null,
    };
  },
  computed: {
    managementMembers() {
      return this.$store.getters.getManagementsMembers;
    },
    dialogVisible: {
      get() {
        return this.handoverDialog;
      },
      set(value) {
        this.$emit("update:handoverDialog", value);
      },
    },
  },
  methods: {
    async handOverMyDuties() {
      await this.$store.dispatch("showLoadingSpinner", true);
      var handover = {
        onBehalf: false,
        permissionsList: [],
        receiver: this.selectedMember,
        boardUniqueId: localStorage.getItem("my-boardUniqueId"),
      };
      await this.$store.dispatch("RolesStore/handoverMyOfficeDuties", handover, {root: true});
      this.selectedMember = null
      this.dialogVisible = false; // Close the dialog after submission
      await this.$store.dispatch("RolesStore/loadUserPermissionsByBoard", {
        root: true,
      });

      // eboard_my_dashboard
      await router.push('/management/management_my_dashboard')
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
  watch: {
    async handoverDialog() {
      if (this.handoverDialog) {
        await this.$store.dispatch("loadManagementsMambers");
      }
    }
  }
};
</script>
