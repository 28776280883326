var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-simple-table',{staticStyle:{"width":"100%"}},[[(!_vm.selectedAgenda)?_c('v-overlay',{attrs:{"absolute":_vm.absolute,"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"width":"10%"}}),_c('th',{staticClass:"text-left",staticStyle:{"width":"80%"}})])]),_c('tbody',[_c('tr',[_c('td',[_c('strong',[_vm._v("Motion :")])]),_c('td',[(_vm.isEditItemMotion && _vm.isEditItemMotionKey === _vm.selectedAgenda.itemDetails.itemKey)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemPurpose),expression:"itemPurpose"}],staticStyle:{"width":"80%"},attrs:{"autofocus":true,"placeholder":_vm.selectedAgenda.itemDetails.itemPuporse || ''},domProps:{"value":(_vm.itemPurpose)},on:{"change":function (event) { return _vm.onPurposeInput(event, _vm.selectedAgenda.itemDetails.itemKey, 'Purpose'); },"input":function($event){if($event.target.composing){ return; }_vm.itemPurpose=$event.target.value}}}):_c('span',[_vm._v(" "+_vm._s(_vm.selectedAgenda.itemDetails.itemPuporse)+" ")]),(_vm.isEditItemMotion && _vm.isEditItemMotionKey === _vm.selectedAgenda.itemDetails.itemKey)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.clearIsEditItemMotion}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,3225457621)},[_c('span',[_vm._v("Click to close Edit")])]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.setIsEditItemMotion}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}])},[_c('span',[_vm._v("Click to Edit Content")])])],1)]),_c('tr',[_c('td',[_c('strong',[_vm._v("Initiator:")])]),_c('td',[_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.selectedAgenda.itemVoteDetails.edges[0].node.voteSponsor.firstName)+" "+_vm._s(_vm.selectedAgenda.itemVoteDetails.edges[0].node.voteSponsor.lastName)+" ")])],1)]),(_vm.boardSetting().showAgendaDuration)?_c('tr',[_c('td',[_c('strong',[_vm._v("Duration :")])]),_c('td',{staticClass:"Editable"},[(_vm.isEditItemDuration && _vm.isEditItemMotionKey === _vm.selectedAgenda.itemDetails.itemKey)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemPurpose),expression:"itemPurpose"}],attrs:{"autofocus":true,"placeholder":_vm.selectedAgenda.itemDetails.itemDuration || '0'},domProps:{"value":(_vm.itemPurpose)},on:{"change":function (event) { return _vm.onPurposeInput(event, _vm.selectedAgenda.itemDetails.itemKey, 'Time'); },"input":function($event){if($event.target.composing){ return; }_vm.itemPurpose=$event.target.value}}}):_c('span',[_vm._v(" "+_vm._s(_vm.selectedAgenda.itemDetails.itemDuration)+" ")]),_vm._v(" Min "),(_vm.isEditItemDuration && _vm.isEditItemMotionKey === _vm.selectedAgenda.itemDetails.itemKey)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.clearIsEditItemDuration}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,2448432181)},[_c('span',[_vm._v("Click to close Edit")])]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""},on:{"click":_vm.setIsEditItemDuration}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,527038542)},[_c('span',[_vm._v("Click to Edit Content")])])],1)]):_vm._e(),_c('tr',[_c('td',[_c('strong',[_vm._v("Votters :")])]),_c('td',{staticClass:"Editable"},[_c('v-layout',[_c('v-flex',{attrs:{"md12":"","xs12":""}},[_c('v-chip-group',{attrs:{"active-class":"activeVoter","column":"","multiple":""},model:{value:(_vm.votters),callback:function ($$v) {_vm.votters=$$v},expression:"votters"}},_vm._l((_vm.selectedAgenda.itemVoteDetails.edges[0].node.voterVote.edges),function(voter){return _c('v-chip',{key:voter.node.voterVotedUser.id,attrs:{"value":voter.node.voterVotedUser.id,"outlined":""}},[_vm._v(" "+_vm._s(voter.node.voterVotedUser.firstName)+" "+_vm._s(voter.node.voterVotedUser.lastName)+" ")])}),1),_c('small',{staticStyle:{"color":"#727578"}},[_vm._v("* Board members without access won’t receive email and will need your help to cast their votes.")])],1)],1)],1)]),_c('tr',[_c('td',[_c('strong',[_vm._v("Vote Results :")]),_c('br')]),_c('td',[_c('v-checkbox',{attrs:{"color":"#3f51b5","hide-details":"","label":"Anonymous Voting"},on:{"change":function($event){return _vm.voteIsAnonymousChanged($event, _vm.selectedAgenda.itemVoteDetails.edges[0].node.voteUniqueId)}},model:{value:(_vm.voteIsAnonymous),callback:function ($$v) {_vm.voteIsAnonymous=$$v},expression:"voteIsAnonymous"}}),_c('em',{staticStyle:{"color":"#727578"}},[_vm._v("No names are shown, only the number of votes")])],1)]),_c('tr')])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }