<template>
  <v-container fluid>
    <v-row class="pt-3">
      <div class="flex items-center">
        <v-btn
          :to="'/management/management_reports_dashboard'"
          class="mr-3"
          color="primary white--text"
          link
          outlined
          right
          rounded
          small
        >
          <v-icon>mdi-arrow-left</v-icon>
          Back
        </v-btn>
        <h2 class="font-medium text-primary">Monthly Reports</h2>
      </div>
    </v-row>

    <v-row class="align-content-center">
      <v-col class="d-flex">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
          class="text-white"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Select Month *"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable
            @change="$refs.menu.save(date); formatFilterMonth()"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-spacer></v-spacer>

      <v-tooltip v-if="hasAccess('can_generate_report')" bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary white--text"
            outlined
            right
            rounded
            small
            style="margin: 5px"
            v-bind="attrs"
            @click="showWeeklyReportModal = !showWeeklyReportModal"
            v-on="on"
          >
            <v-icon>mdi-file-plus-outline</v-icon>
            Generate New Report
          </v-btn>
        </template>
        <span>Generate New Report</span>
      </v-tooltip>
    </v-row>

    <NewWeeklyReportModal
      :showWeeklyReportModal="showWeeklyReportModal"
      :template="template"
      :templateUniqueId="templateUniqueId"
      @closeNewReportModal="closeNewReportModal"
      @openReportsBuilderDialog="openReportsBuilderDialog"
      @showOverlay="overlay = true"
      :weekUniqueId="null"
      :reportType="`MONTHLY`"
    />

    <v-row>
      <v-col sm="12">
        <template>
          <v-row justify="center">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="draft in draftReports"
                :key="draft.primaryKey"
                class="intro-y"
              >
                <v-expansion-panel-header class="text-uppercase"
                ><strong style="font-size: 14px; color: #595e5d">{{
                    draft.templateReportName
                  }}</strong></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-card max-width="100%" outlined>
                    <v-card-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="
                          draft.templateIsPublished &&
                          hasAccess('can_respond_to_report')
                        "
                        class="ma-1"
                        color="primary"
                        fab
                        outlined
                        small
                        @click="
                          OpenReportCommentsDialog = true;
                          commentReport = draft.primaryKey;
                        "
                      >
                        <v-icon dark>mdi-comment-text</v-icon>
                      </v-btn>

                      <v-chip
                        v-if="
                          !draft.templateIsPublished &&
                          hasAccess('can_generate_report')
                        "
                        class="ma-1"
                        close
                        close-icon="mdi-draw"
                        outlined
                        small
                      >In draft
                      </v-chip>

                      <v-chip
                        v-if="draft.templateIsPublished"
                        close
                        close-icon="mdi-check-decagram-outline"
                        color="success"
                        outlined
                        small
                      >Submitted as Report
                      </v-chip>

                      <v-menu :close-on-content-click="closeOnClick" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            close
                            color="info"
                            dark
                            outlined
                            rounded
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            View Attachment
                            <v-icon small>mdi-paperclip</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(attachment, index) in draft.templateDocuments"
                            :key="index"
                          >
                            <v-list-item-title
                                class="cursor-pointer"
                                @click="openReportAttachment(attachment)">
                                <v-icon>mdi-eye</v-icon>
                              {{ attachment.documentName }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                      <div>
                        <v-btn
                          v-if="
                            !draft.templateIsPublished &&
                            hasAccess('can_generate_report')
                          "
                          class="ma-1"
                          color="success"
                          fab
                          outlined
                          x-small
                          @click="openReportBuilderPanel(draft)"
                        >
                          <v-icon dark> mdi-file-restore-outline</v-icon>
                        </v-btn>
                      </div>

                      <div>
                        <v-btn
                          v-if="
                            !draft.templateIsPublished &&
                            hasAccess('can_generate_report')
                          "
                          class="ma-1"
                          color="error"
                          fab
                          outlined
                          x-small
                          @click="
                            isDeleteReport = !isDeleteReport;
                            templateReportName = draft.templateReportName;
                            templateUniqueId = draft.templateUniqueId;
                          "
                        >
                          <v-icon dark> mdi-delete-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-card-title>

                    <v-card-text>
                      <div
                        v-for="report in draft.templateComponents"
                        :key="report.componentUniqueId"
                      >
                        <h3>{{ report.componentTitle }}</h3>
                        <p
                          style="padding-left: 30px"
                          v-html="report.componentBody"
                        ></p>
                      </div>
                    </v-card-text>

                    <v-card-text>
                      <p>
                        <strong>Reported by:</strong>
                        {{ draft.templateCreatedBy }}
                      </p>
                      <p>
                        <strong>Reported Date:</strong>
                        {{ new Date(draft.templateCreatedDate).toDateString() }}
                      </p>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-text
                      v-if="draft.templateComments.length > 0"
                      style="background-color: #8790cc29"
                    >
                      <v-row
                        v-for="comment in draft.templateComments"
                        :key="comment.commentUniqueId"
                      >
                        <v-col cols="6" md="6" sm="8">
                          <strong>COMMENTS / RESPONSES / DIRECTIVES</strong>

                          <div>
                            <p v-html="comment.commentDescriptions"></p>
                            <small
                            >Comment by:
                              <strong>{{ comment.commentCreatedBy }}</strong
                              ></small
                            >
                          </div>
                        </v-col>

                        <v-col cols="6" md="6" sm="8">
                          <strong>ASSIGNEES</strong>
                          <div
                            v-for="assignee in comment.commentAssignees"
                            :key="assignee"
                          >
                            <p>
                              {{ assignee.assigneeDesignation }}
                            </p>
                          </div>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </template>
      </v-col>
    </v-row>


    <ReportAssignmentForm
      :OpenReportCommentsDialog="OpenReportCommentsDialog"
      :commentReport="commentReport"
      @CloseReportCommentsDialog="OpenReportCommentsDialog = false; filterManagementReportTemplatesByPeriod()"
    />

    <DocumentViewer v-if="openFileDialog" :OpenfileDialog="openFileDialog" :docValue="docValue" :title="title"
                    @CloseDocumentViwer="openFileDialog=false"/>

    <v-dialog v-model="isDeleteReport" width="500">
      <v-card>
        <v-card-title class="text-h5 error lighten-2">
          Confirm to Delete?
        </v-card-title>

        <v-card-text class="my-3">
          You are about to delete <strong>{{ templateReportName }}</strong
        >. This report will be deleted permanently. Click "I ACCEPT" if you
          want to delete this report OR Click "CANCEL" to abort this operation.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="isDeleteReport = false">
            Cancel
          </v-btn>

          <v-btn color="primary" text @click="deleteManagementReport">
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import NewWeeklyReportModal from "./NewWeeklyReportModal.vue";
import ReportAssignmentForm from "./ReportAssignmentForm.vue";
import DocumentViewer from "@/modules/meeting_management/eboard-documents/DocumentViewer.vue";

export default {
  components: {
    DocumentViewer,
    ReportAssignmentForm,
    NewWeeklyReportModal,
  },

  data() {
    return {
      templateReportName: "",
      isDeleteReport: false,
      closeOnClick: false,
      loadOnClick: false,
      commentReport: null,
      OpenReportCommentsDialog: false,
      zIndex: 0,
      panelKey: 0,
      overlay: false,
      templateUniqueId: null,
      template: null,
      reportBuilderDialog: false,
      date: null,
      dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      modalTo: false,
      menuTo: false,
      tab: true,
      showWeeklyReportModal: false,
      weekId: "",
      reportIsPublished: true,
      filterObj: {
        filterReportType: `MONTHLY`,
        reportIsPublished: false,
        filterMonth: null,
        filterYear: parseInt(new Date().getFullYear()),
        filterQuarter: null
      },
      openFileDialog: false,
      title: '',
      docValue: ''
    };
  },

  methods: {
    openReportBuilderPanel(draft) {
      this.$router.push(`/management/management_monthly_reports/${this.$route.params.weekId}/${draft.templateUniqueId}`)
    },
    closeReportBuilderPanel() {
      this.filterManagementReportTemplatesByPeriod();
      this.reportBuilderDialog = false;
    },

    openReportAttachment(attachment) {
      this.title = attachment.documentName
      this.docValue = attachment.documentPath
      this.openFileDialog = true
    },

    formatFilterMonth() {
      const formattedMonth = this.date.split("-");
      this.filterObj.filterMonth = parseInt(formattedMonth[1]);
      this.filterObj.filterYear = parseInt(formattedMonth[0]);
      this.filterManagementReportTemplatesByPeriod();
    },


    closeNewReportModal() {
      this.showWeeklyReportModal = false;
      this.filterManagementReportTemplatesByPeriod();
    },

    openReportsBuilderDialog(draft) {
      this.templateUniqueId = draft.templateUniqueId;
      this.template = draft;
      this.showWeeklyReportModal = false;
      this.overlay = false;
      this.reportBuilderDialog = true;
    },

    async deleteManagementReport() {
      if (!this.hasAccess("can_respond_to_report")) {
        this.reportIsPublished = false;
      }
      this.isDeleteReport = false;
      await this.$store.dispatch("deleteManagementMeetingReport", {
        templateUniqueId: this.templateUniqueId,
      });
      await this.filterManagementReportTemplatesByPeriod();
    },

    async filterManagementReportTemplatesByPeriod() {
      if (!this.hasAccess("can_respond_to_report")) {
        this.filterObj.reportIsPublished = false;
      }

      await this.$store.dispatch("filterManagementReportTemplatesByPeriod", this.filterObj);
      await this.$store.dispatch("loadManagementMembers");
    }
  },

  computed: {
    draftReports() {
      return this.$store.getters.getManagementReportsInPeriod;
    },
  },

  mounted() {
    this.filterManagementReportTemplatesByPeriod();
  },
};
</script>

<style></style>
