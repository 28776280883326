<template>
  <v-container app class="my-3" fluid>
    <v-layout class="mb-0">
      <div class="flex justify-content-between items-center">
        <span>List of all Committee Meetings</span>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="addNewCommitteeMeeting"
                color="primary white--text"
                fab
                right
                small
                style="margin: 5px"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>New Committee Meeting</span>
        </v-tooltip>
      </div>
      <div class="p-4">
      </div>
    </v-layout>

    <v-layout>
      <v-flex md12 xs12>
        <div style="display: flex; justify-content: center; flex-wrap: wrap">
          <div
              v-for="meeting in managementCommitteeMeetings"
              :key="meeting.id"
              :to="details + meeting.id"
              class="padding intro-x"
              link
              style="display: flex; justify-content: center; margin-top: 40px"
          >
            <v-card
                class="meeting-info-card"
                elevation="3"
                style="border-radius: 7px"
                @click="moveToAnotherPage(details + meeting.id)"
            >
              <div
                  class="flex flex-justify-content-start"
                  style="
                  width: 100%;
                  padding-left: 20px;
                  padding-top: 10px;
                  border-radius: 3px 3px 0 0;
                "
              >
                <p>
                  {{ managementCommitteeName }}
                                    Committee
                </p>
              </div>
              <div
                  class="flex flex-justify-content-start"
                  style="
                  width: 100%;
                  background-color: #ccc;
                  border-radius: 3px 3px 0 0;
                "
              >
                <span
                    style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 1px 0 0;
                    border-radius: 3px 0 0 0;
                  "
                ></span>
                <span
                    style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 1px;
                  "
                ></span>
                <span
                    style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 1px;
                  "
                ></span>
                <span
                    style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 1px;
                  "
                ></span>
                <span
                    style="
                    background-color: #6267ff;
                    height: 2px;
                    width: 20%;
                    margin: 0 0 0 1px;
                    border-radius: 0 3px 0 0px;
                  "
                ></span>
              </div>
              <li
                  :to="details + meeting.id"
                  class="hand-cursor"
                  link
              >
                <div class="flex flex-justify-content-start py px">
                  <div
                      :to="details + meeting.id"
                      link
                      style="margin-left: 20px"
                  >
                    <span class="date-container">
                      <span class="date-month">{{ meeting.meetingMonth }}</span>
                      <span class="date-date">{{ meeting.meetingDay }}</span>
                    </span>
                    <span class="flex-column" style="margin-left: 0px">
                      <span class="date-day center">{{
                          meeting.meetingWeekday
                        }}</span>
                      <span class="date-time center" style="font-size: 14px">{{
                          meeting.meetingStartTime
                        }}</span>
                      <span class="date-timezone center"
                      >({{ meeting.meetingLocation.locationTimezone }})</span
                      >
                    </span>
                  </div>
                  <v-divider
                      style="height: 100px; margin-right: 25px; margin-left: 10px"
                      vertical
                  ></v-divider>
                  <div
                      :to="details + meeting.id"
                      class="flex-column left"
                      link
                  >
                    <h4>{{ meeting.meetingTitle }}</h4>
                    <span
                        class="flex flex-align-items-start"
                        style="padding-top: 10px"
                    >
                      <v-icon>mdi-map-marker</v-icon>
                      <span>
                        <b>{{ meeting.meetingLocation.locationName }}</b>
                        <br />
                        <span>{{
                            meeting.meetingLocation.locationAddress
                          }}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            </v-card>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <add-new-management-committee-meeting
        :new-management-committee-meeting="newManagementCommitteeMeeting"
    @closeNewCommitteeMeetingDialog="newManagementCommitteeMeeting = false"></add-new-management-committee-meeting>
  </v-container>
</template>

<script>
import AddNewManagementCommitteeMeeting
  from "@/modules/management_committee/management_committee_meeting/AddNewManagementCommitteeMeeting.vue";

export default {
  name: "ManagementCommitteeMeeting",
  components: {AddNewManagementCommitteeMeeting},
  data() {
    return {
      newManagementCommitteeMeeting: false,
      details: "/management-committee/meeting-schedules/meeting_details/",
    }
  },
  mounted() {
    this.$store.dispatch('loadManagementCommitteeMeetings');
  },
  methods: {
    addNewCommitteeMeeting() {
      this.newManagementCommitteeMeeting = true
    },
    moveToAnotherPage(pageRoute) {
      this.$router.push(pageRoute);
    },
  },
  computed: {
    managementCommitteeMeetings() {
      return this.$store.getters.getManagementCommitteeMeetings
    },
    managementCommitteeName() {
      return localStorage.getItem('my-managementCommitteeName')
    }
  }
}
</script>

<style scoped>

</style>