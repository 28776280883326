<template>
  <v-row>
    <v-dialog
        v-model="editInstitutionCategoryDialog"
        max-width="70%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <icon class="pt-1 mr-3" name="role" width="w-5" height="h-5" color="#000"></icon><span class="text-h6">Edit Institution Category</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                      outlined
                      v-model="categoryName"
                      :counter="100"
                      label="Category Name *"
                      prepend-icon="mdi-text-short"
                      required >
                  </v-text-field>
                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="$emit('close-dialog')"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="editInstitutionCategory"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "EditInstitutionCategory",

  props:[
    "categoryName",
    "categoryUniqueId",
    "editInstitutionCategoryDialog"
  ],

  methods: {
    async editInstitutionCategory(){
      let input = {
        categoryName: this.categoryName,
        categoryUniqueId: this.categoryUniqueId
      }

      await this.$store.dispatch('editInstitutionCategory', input)
      this.$emit('close-dialog')
    },
  }
}
</script>

<style scoped>

</style>