<template>
  <nav>
    <v-app-bar  dense  app style="background: #3f51b5;color: #fff;">
      <v-app-bar-nav-icon><v-icon  @click="mini = !mini" class="white--text" left>mdi-menu</v-icon></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="white--text text-lowercase">e-</span>
        <span class="white--text font-weight-light">Board / </span>
        <span class="white--text text-lowercase">e-</span>
        <span class="white--text">GA</span>
      </v-toolbar-title>
      <!-- dropdown menu -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" slot="activator"  style="padding-left:10px">
            [ {{currentBoard}} ]
            <v-icon   class="white--text" right>mdi-chevron-down</v-icon>
          </span>
      </template>
        <v-list>
            <v-list-item v-for="boards in allBoardsByUser" :key="boards.memberBoard.boardUniqueId" router :to="board_account+boards.memberBoard.boardInstitution.institutionUniqueId+'/'+boards.memberBoard.boardDescriptions +'-'+boards.memberBoard.boardInstitution.institutionShortform">
              <v-list-item-title>{{ boards.memberBoard.boardDescriptions }} - {{ boards.memberBoard.boardInstitution.institutionShortform }}</v-list-item-title>
          </v-list-item>
        </v-list>

      </v-menu>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  class="white--text"  v-bind="attrs" v-on="on" slot="activator" right>mdi-chevron-down</v-icon>
          <span style="padding-left:10px">Jackson Bakari </span>
      </template>
        <v-list>
            <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
              <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list>

      </v-menu>
    </v-app-bar >

      <v-card >
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
          app
        >
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="/arm.png"></v-img>
            </v-list-item-avatar>

            <v-list-item-title>John Leider</v-list-item-title>

            <v-btn
              icon
              @click.stop="mini = !mini"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item  v-for="link in links"  :key="link.name" link :to="link.routinglink" >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list> 
                <template v-slot:append>
                  <div class="pa-2">
                    <router-link
                      to="/settings"
                      tag="v-btn"
                    >
                      <v-btn    right fab x-small color="indigo white--text" style="margin:5px"><v-icon>mdi-cog-outline</v-icon></v-btn>
                    </router-link>

                       <router-link
                      to="/settings/eboard_system_settings"
                      tag="v-btn"
                    >
                    <v-btn   right fab x-small color="blue white--text" style="margin:5px"><v-icon>mdi-account-tie</v-icon></v-btn>
                     </router-link>
                    <v-btn    right fab x-small color="pink white--text" style="margin:5px"><v-icon>mdi-power</v-icon></v-btn>
                  </div>
                </template>
        </v-navigation-drawer>
      </v-card>



                
                

  </nav>
</template>

<script>
// import gql from 'graphql-tag'
import allBoardsByUser from '../../../graphql/eboard-boards-graphql/allBoardsByUser.gql'

export default {
  watch:{
    '$route'(to){
      if(to.params.board_name !=null){
        this.storeUserBaords(to.params.board_name)
        this.currentBoard=localStorage.getItem('currentBoard');
      }
    }
  },
  data() {
    return {
    settings:"/settings/eboard_system_settings",
    drawer: true,
    currentBoard:"System settings",
    mini:false,
    links:[
    {
        name:'User Management',
        icon:'mdi-account-cog',
        routinglink:'/settings/user_management'
    },
    {
        name:'Boards Management',
        icon:'mdi-clipboard-multiple',
        routinglink:'/settings/board_management'
    },
    {
        name:'Meetings Management',
        icon:'mdi-clock-outline',
        routinglink:'/settings/meetings_management'
    },
    
    {
        name:'Comittees',
        icon:'mdi-account-group',
        routinglink:'/actions'
    },
    ],
    }
  },
  apollo: {
    allBoardsByUser: allBoardsByUser,
  },

  methods:{
    storeUserBaords(currentBoard){
      localStorage.setItem('currentBoard',currentBoard)
    }
  }
}
</script>

