import { apolloClient } from '@/vue-apollo'
import userLogin from '../../graphql/eboard-auth-graphql/userLogin.gql'
import customUserTokenAuthentication from '../../graphql/eboard-auth-graphql/customUserTokenAuthentication.gql'
import refreshToken from '../../graphql/eboard-auth-graphql/refreshToken.gql'
import swal from 'sweetalert2'
import router from '../../router/index.js'



export default {
  namespaced:true,

  state: {
    token: localStorage.getItem('apollo-token') || null,
    user: {},
    authStatus: false,
  },

  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.authStatus,
    user: state => state.user,
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },

    LOGIN_USER(state, user) {
      state.user = user

      localStorage.setItem('my-boardUniqueId', user.profileUser.edges[0].node.userProfileBoard.boardUniqueId || "")
      localStorage.setItem('my-boardKey', user.profileUser.edges[0].node.userProfileBoard.primaryKey || "")
      localStorage.setItem('my-managementUniqueId', user?.managementUser?.edges[0]?.node.managementMemberManagement.managementUniqueId || "")

    },

    LOGOUT_USER(state) {
      state.authStatus = ''
      state.token = '' && localStorage.removeItem('apollo-token')
      localStorage.removeItem('refresh')
      sessionStorage.clear();
    },


  },

  actions: {
    async clearUserPermissions(context){
      context.commit('clearPermissions')
    },

    async authenticateUser(context, authDetails) {
      try {

        try {
          await apolloClient.mutate({ mutation: customUserTokenAuthentication, variables: { ...authDetails } })
        } catch (error) {
          console.error("CUSTOM_TOKEN_AUTHENTICATION ==> ", error);
        }

        const { data } = await apolloClient.mutate({ mutation: userLogin, variables: { ...authDetails } })

        if (data.tokenAuth.success) {
          await context.dispatch('riderectUser', data)
        } else {
          await swal.fire({
            toast: true,
            icon: 'error',
            title: "Sorry - that didn't work. The credentials provided are not valid",
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
          await router.push('/login')
        }

      } catch (e) {
        console.log(e)
      }
    },

    async riderectUser(context, data) {
      const token = JSON.stringify(data.tokenAuth.token)
      const refreshToken = JSON.stringify(data.tokenAuth.refreshToken)
      context.commit('SET_TOKEN', token)
      context.commit('LOGIN_USER', data.tokenAuth.user)
      localStorage.setItem('apollo-token', token)
      localStorage.setItem('refreshToken', refreshToken)


      if (context.rootState.lastVisitedRoute) {
        router.push(context.rootState.lastVisitedRoute);
      } else {
          await router.push('/')
      }

      await swal.fire({
        toast: true,
        icon: 'success',
        title: 'You have successfuly logged in',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      })
    },

    async logOut({ commit }) {
      commit('LOGOUT_USER')
    },

    async tokenRefresher(context) {
      await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: refreshToken,
        variables: {
          token: localStorage.getItem("refreshToken")
        }
      }).then((response) => {
        console.log(response.data.refreshToken);
        console.log(context);
      })
    },

  },


}

