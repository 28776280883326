import Vue from "vue";
import App from "./App.vue";
import {createProvider} from "./vue-apollo";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueDraggable from "vue-draggable";
import VueSweetalert2 from "vue-sweetalert2";
import VueQuillEditor from "vue-quill-editor";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import store from "./store";
import moment from "moment";
import VueInitialsImg from 'vue-initials-img';
import VueYoutube from 'vue-youtube'
import VueParticles from 'vue-particles'

import DataTable from "@andresouzaabreu/vue-data-table";
Vue.component("data-table", DataTable);

import "@andresouzaabreu/vue-data-table/dist/DataTable.css";


import 'slide-panel'

Vue.use(VueParticles)

Vue.use(VueYoutube)

Vue.filter("formatMonth", function (value) {
    if (value) {
        return moment(String(value)).format("MMM");
    }
});

Vue.filter("formatDay", function (value) {
    if (value) {
        return moment(String(value)).format("DD");
    }
});

Vue.filter("formatDate", function (value) {
    if (value) {
        return moment(String(value)).format("LL");
    }
});

Vue.filter("formatMonth", function (value) {
    if (value) {
        return moment(String(value)).format("MMM");
    }
});

Vue.filter("formatFull", function (value) {
    if (value) {
        return moment(String(value)).format("MMMM D, YYYY, h:mm:ss a");
    }
});

Vue.filter("formatTime", function (value) {
    if (value) {
        return moment(String(value)).format("LTS");
    }
});

Vue.mixin({
    data: function () {
        return {
            MEDIA_SERVER: process.env.VUE_APP_MEDIA_SERVER,
        };
    },
});

Vue.mixin({
    computed: {
        hasHandoverDuties: () => () => {
            const handover = store.getters["RolesStore/getHandOverStatus"];
            return handover
        },
        hasAccess: () => (permision) => {
            const permissions = store.getters["RolesStore/userPermissions"];
            return permissions.includes(permision);
        },
        boardSetting: () => () => {
            const settings = store.getters["SettingsStore/BoardSetting"];
            return settings;
        },
        institutionDetails: () => () => {
            const settings = store.getters["SettingsStore/institutionDetails"];
            return settings;
        },
        designationsList: () => () => {
            const designations = store.getters["SettingsStore/DesignationsList"];
            return designations;
        },
        valueChecker: () => (value) => {
            return value ? value : '-'
        },
        enumToArray: () => (enumValue) => {
            let enumKeys = Object.keys(enumValue)
            let enumValues = Object.values(enumValue)
            return enumKeys.map((itemValue, index) => {
                return {value: enumValues[index], name: itemValue}
            })
        }
    },
});

Vue.use(CKEditor);

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme

Vue.use(VueQuillEditor /* { default global options } */);

import ECharts from "vue-echarts";
import {use} from "echarts/core";

// import ECharts modules manually to reduce bundle size
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {GridComponent, TooltipComponent} from "echarts/components";

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

// register globally (or you can do it locally)
Vue.component("v-chart", ECharts);

Vue.use(VueSweetalert2);
Vue.use(VueDraggable);
Vue.use(VueInitialsImg);

Vue.config.productionTip = false;
import "./assets/style.css";
import "./assets/eboard.css";

new Vue({
    apolloProvider: createProvider(),
    router,
    vuetify,
    store,
    render: (h) => h(App),
}).$mount("#app");
