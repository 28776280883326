<template>
  <v-row justify="center">
    <v-dialog
        v-model="isRevokeAccess"
        persistent
        max-width="80%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"><v-icon>mdi-cancel</v-icon> Select Access Level</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-radio-group v-model="userRole">
                  <v-radio  v-for="role in roles" :key="role.primaryKey" :value="role.primaryKey">
                    <template v-slot:label>
                      <div>{{role.roleName}}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="pink"
              fab
              small
              class="white--text"
              @click="$emit('closeRevokeAccessModal')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
              color="blue darken-1"
              fab
              small
              class="white--text"
              @click="revokeUserAccessLevel"
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import allRolesPerBoard from '../../../graphql/eboard-auth-graphql/allRolesPerBoard.gql'
export default {
  components:{
  },
  data: () => ({
    userRole: "",
    boardUniqueId: localStorage.getItem('my-boardUniqueId') || "",
    roles:[],
  }),
  props: ["isRevokeAccess","revokeAccessUser"],
  created(){
    this.$apollo.query({
      query:allRolesPerBoard,
      variables:{
        roleBoard: this.boardUniqueId
      }
    }).then((response)=>{
      this.roles=[...response.data.rolesPerBoard];
    })
  },
  methods:{
    async revokeUserAccessLevel(){
      this.$store.dispatch('showLoadingSpinner',true)
      var membersDetails={
        userId:this.revokeAccessUser,
        userRole:this.userRole,
      }
      await this.$store.dispatch('revokeUserAccessLevel', membersDetails)
      await this.$store.dispatch('showLoadingSpinner',false)
      await this.$store.dispatch('allBoardUsers')
      this.$emit('closeRevokeAccessModal')
    },
  }

}
</script>
