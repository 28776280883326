<template>
  <v-row justify="center">
    <v-dialog
        key="1"
        v-model="newNoteBookDialog"
        max-width="50%"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6"
          ><v-icon>mdi-notebook</v-icon> {{ noteBookEdit ? 'Edit' : 'New'}} Notebook</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                      v-model="notebookTitle"
                      :rules="nameRules"
                      label="Notebook Title *"
                      prepend-icon="mdi-alpha-n"
                      required
                  >
                  </v-text-field>

                  <v-text-field
                      v-model="noteBookDescription"
                      :rules="nameRules"
                      label="Notebook Descriptions *"
                      prepend-icon="mdi-text-long"
                      required
                  >
                  </v-text-field>

                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn color="red"
                     class="text-capitalize mr-2"
                     right
                     small
                     @click="$emit('closeNewNoteBook')"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     v-if="!noteBookEdit"
                     class="text-capitalize"
                     right
                     small
                     @click="createNoteBook"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Add Notebook
              </v-btn>
              <v-btn color="primary"
                     v-else
                     class="text-capitalize"
                     right
                     small
                     @click="createNoteBook"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>
import Icon from "@/views/Icon.vue";

export default {
  name: "NewNotebook",
  components: {Icon},
  props: ["newNoteBookDialog", "selectedNoteBook", "noteBookEdit"],
  data() {
    return {
      nameRules: [
        (v) => !!v || "This field is required",
      ],
      notebookTitle: '',
      noteBookDescription: ''
    }
  },
  methods: {
    createNoteBook() {
      let input = {
        notebookUniqueId: this.noteBookEdit ? this.selectedNoteBook.id : null,
        notebookTitle: this.notebookTitle,
        notebookDescription: this.noteBookDescription
      }
      this.noteBookEdit ? this.$store.dispatch('editNotebook', input) : this.$store.dispatch('createNotebook', input)
      this.$emit('closeNewNoteBook')
    }
  },
  watch: {
    newNoteBookDialog() {
      if (this.newNoteBookDialog && this.noteBookEdit) {
        this.notebookTitle = this.selectedNoteBook.notebookTitle
        this.noteBookDescription = this.selectedNoteBook.notebookDescription
      } else {
        this.notebookTitle = ''
        this.noteBookDescription = ''
      }
    }
  }
}
</script>

<style scoped>

</style>