import {apolloClient} from '@/vue-apollo'
import createBoardMeetingPacks from '../../graphql/eboard-boardpacks-graphql/createBoardMeetingPacks.gql'
import meetingPack from '../../graphql/eboard-boardpacks-graphql/meetingPack.gql'
import allMeetingBoardPack from '../../graphql/eboard-boardpacks-graphql/allMeetingBoardPack.gql'
import createBoardPackAnnotations from '../../graphql/eboard-boardpacks-graphql/createBoardPackAnnotations.gql'
import annotatedBoardPack from '../../graphql/eboard-boardpacks-graphql/annotatedBoardPack.gql'
import shareBoardPackAnnotations from '../../graphql/eboard-boardpacks-graphql/shareBoardPackAnnotations.gql'
import sharedAnnotatedBoardPack from '../../graphql/eboard-boardpacks-graphql/sharedAnnotatedBoardPack.gql'
import createBoardMeetingPackViewer from "../../graphql/eboard-boardpacks-graphql/createBoardMeetingPackViewer.gql";
import archiveBoardMeetingPacks from "../../graphql/eboard-boardpacks-graphql/archiveBoardMeetingPacks.gql";
import swal from 'sweetalert2'


export default {
    state: {
        meetingBoardPack: null,
        opendPack: null,
        boardPacksList: [],
        annotatedBoardPacksList: [],
        sharedAnnotatedBoardPacksList: []
    },

    mutations: {
        cleanMeetingPacks(state) {
            state.meetingBoardPack = null
            state.boardPacksList = []
        },
        cleanSharedAnnotatedPacks(state) {
            state.sharedAnnotatedBoardPacksList = []
        },
        cleanAnnotatedPacks(state) {
            state.annotatedBoardPacksList = []
        },
        addMeetingPacks(state, pack) {
            state.meetingBoardPack = {'boardPackName': pack.boardPackName, 'boardPackFullPath': pack.boardPackFullPath}
        },
        setMeetingPacks(state, pack) {
            state.meetingBoardPack = {'boardPackName': pack.packName, 'boardPackFullPath': pack.packPath}
        },
        addBoardsPacks(state, packs) {
            state.boardPacksList = packs
        },
        addAnnotatedBoardsPacks(state, packs) {
            state.annotatedBoardPacksList = packs
        },
        addSharedAnnotatedBoardsPacks(state, packs) {
            state.sharedAnnotatedBoardPacksList = packs
        },

        setAnnotatedBoardsPacks(state, boardPack) {
            state.annotatedBoardPacksList.push(boardPack)
        },

    },

    getters: {
        meetingNoHasPack(state) {
            return state.meetingBoardPack === null
        }
    },

    actions: {
        async createBoardMeetingPacks(context, meetingUniqueId) {
            context.commit('cleanMeetingPacks')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: createBoardMeetingPacks,
                variables: {
                    meetingUniqueId: meetingUniqueId
                }
            }).then((response) => {
                context.commit('addMeetingPacks', response.data.createBoardMeetingPacks.boardPack)
                context.dispatch("showLoadingSpinner", false);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Board pack was generated successfully. \nYou can priview it on Board Pack button',
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadBoardMeetingPacks(context, meetingUniqueId) {
            context.commit('cleanMeetingPacks')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingPack,
                variables: {
                    meetingUniqueId: meetingUniqueId
                }
            }).then((response) => {
                if (response.data.meetingPack != null) {
                    context.commit('setMeetingPacks', response.data.meetingPack)
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllBoardPacks(context) {
            context.commit('cleanMeetingPacks')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMeetingBoardPack,
                variables: {
                    boardUniqueId: localStorage.getItem('my-boardUniqueId'),
                }
            }).then((response) => {
                if (response.data.allMeetingBoardPack.length > 0) {
                    context.commit('addBoardsPacks', response.data.allMeetingBoardPack)
                    context.dispatch("showLoadingSpinner", false);
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async startBoardPackAnnotation(context, packUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: createBoardPackAnnotations,
                variables: {
                    packUniqueId: packUniqueId,
                }
            }).then((response) => {
                context.commit('setAnnotatedBoardsPacks', response.data.createBoardPackAnnotations.boardPack)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAnnotatedBoardPacks(context) {
            context.commit('cleanAnnotatedPacks')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: annotatedBoardPack,
                variables: {
                    boardUniqueId: localStorage.getItem('my-boardUniqueId'),
                }
            }).then((response) => {
                if (response.data.annotatedBoardPack.length > 0) {
                    context.commit('addAnnotatedBoardsPacks', response.data.annotatedBoardPack)
                    context.dispatch("showLoadingSpinner", false);
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        setSelectedPack(context, doc) {
            context.rootState.BoardPacksStore.opendPack = doc
        },

        async shareMyAnnotationBoardPacks(context, shareAnnotation) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: shareBoardPackAnnotations,
                variables: {
                    shareAnnotation: shareAnnotation,
                }
            }).then((response) => {
                context.dispatch("showLoadingSpinner", false);
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: response.data.shareBoardPackAnnotations.boardPack.saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadSharedAnnotatedBoardPacks(context, source = 'other') {
            context.commit('cleanSharedAnnotatedPacks')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: sharedAnnotatedBoardPack,
                variables: {
                    source: source,
                }
            }).then((response) => {
                if (response.data.sharedAnnotatedBoardPack.length > 0) {
                    context.commit('addSharedAnnotatedBoardsPacks', response.data.sharedAnnotatedBoardPack)
                    context.dispatch("showLoadingSpinner", false);
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async createBoardMeetingPackViewer(context, packUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: createBoardMeetingPackViewer,
                variables: {
                    packUniqueId: packUniqueId,
                }
            }).then((response) => {
                console.log(response.data.createBoardMeetingPackViewer)
                context.dispatch("loadAllBoardPacks")
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async archiveBoardMeetingPacks() {
            await apolloClient.mutate({
                mutation: archiveBoardMeetingPacks,
                variables: {
                    boardUniqueId: localStorage.getItem('my-boardUniqueId')
                }
            }).then((response) => {
                if (response.data.archiveBoardMeetingPacks.response.status) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: response.data.archiveBoardMeetingPacks.response.message,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: response.data.archiveBoardMeetingPacks.response.message,
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                console.error(error);
            })
        },
    }
}



