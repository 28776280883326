<template>
  <v-container fluid>
    <v-row  justify="center">
      <v-dialog
          :key="newCommitteeKey"
          v-model="addCommitteeMembersDialog"
          persistent
          width="60%"
          
          
      >
        <v-card height="400">
          <v-card-title>
            <span class="text-h6"><v-icon>mdi-account-multiple-plus-outline</v-icon> Add Board Committee Member</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-select
                      v-model="committeeMembersList"
                      :items="boardMembers"
                      item-value="id"
                      item-text="email"
                      attach
                      chips
                      label="Select Committee Members"
                      :menu-props="{left:true,offsetY: true }"
                      multiple
                      @change="updateLeaderselector"
                      clearable
                  ></v-select>
                  <v-radio-group v-if="addmemberNow" v-model="committeeLeaderId">
                    <template v-slot:label>
                      <div>Committee <strong>Leader</strong>
                      </div>
                    </template>
                    <v-radio
                        v-for="member in leadersMembersList"
                        :key="'memb'+member.id"
                        :label="`Radio ${member}`"
                        :value="member.id"
                    >
                      <template v-slot:label>
                        <div>{{member.email}}</div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="pink"
                fab
                small
                class="white--text"

                @click="$emit('close')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn
                color="blue darken-1"
                fab
                small
                class="white--text"
                :loading="loadoncreate"
                @click="addCommitteeMember"
            >
              <v-icon>mdi-check-all</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>


<script>
import allBoardUsers from '../../../graphql/eboard-boards-graphql/allBoardUsers.gql'

export default {
  components:{

  },
  data: () => ({
    loadoncreate:false,
    newCommitteeKey:0,
    addmemberNow:false,
    committeeMembersList:"",
    committeeLeaderId:"",
    boardMembers: [],
    leadersMembersList:[],
  }),

  props: ["addCommitteeMembersDialog", "committeeUniqueId", "membersList"],

  watch:{
    addmemberNow: function(){
      if(this.addmemberNow==false){
        this.committeeMembersList=''
        this.leadersMembersList=[]
      }
    }
  },

  methods:{
    async addCommitteeMember(){
      await this.$store.dispatch("showLoadingSpinner", true);
      const committeeObj = {
        committeeMembersList: this.committeeMembersList,
        committeeUniqueId: this.committeeUniqueId
      }

      await this.$store.dispatch('addCommitteeMember', committeeObj)
      this.committeeMembersList=null
      this.committeeUniqueId=null
      this.$emit('close')
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    updateLeaderselector(){
      this.leadersMembersList=[]
      this.committeeMembersList.forEach(selected => {
        this.boardMembers.forEach(member => {
          if(selected==member.id)
            this.leadersMembersList.push(member)
        })
      });

    },

    async loadBoardMambers(){
       await this.$apollo.query({
        query:allBoardUsers,
        variables:{
          boardUniqueId: localStorage.getItem('my-boardUniqueId'),
          pageNumber: 1,
        }
      }).then((response)=>{
        let temp = [];


      response.data.boardUsers.boardUsers.forEach(member => {
          const existCommitteeMember = this.membersList.some(commMember => commMember.memberUserId === member.id)
            if (!existCommitteeMember) {
            temp.push(member)
          }
        });

        response.data.boardUsers.managementUsers.forEach(member => {
          const existCommitteeMember = this.membersList.some(commMember => commMember.memberUserId === member.id)
            if (!existCommitteeMember) {
            temp.push(member)
          }
        });

        this.boardMembers = temp;

      })
    }
  },
  async mounted(){
    this.loadBoardMambers()
  }
}
</script>