<template>
  <v-row>
    <v-dialog
        v-model="editDefaultUserRole"
        max-width="70%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <icon class="pt-1 mr-3" name="role" width="w-5" height="h-5" color="#000"></icon><span class="text-h6">Edit Default Role</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                      outlined
                      v-model="roleName"
                      :counter="100"
                      label="Role Name *"
                      prepend-icon="mdi-text-short"
                      required >
                  </v-text-field>

                  <v-text-field
                      outlined
                      v-model="roleDescription"
                      :counter="150"
                      label="Role Description *"
                      prepend-icon="mdi-text-long"
                      required
                  >
                  </v-text-field>

                  <div class="flex-end">
                    <v-checkbox
                        v-model="roleIsAdmin"
                        :label="`Is Admin`"
                        color="success"
                        class="mr-2"
                    ></v-checkbox>
                  </div>

                  <v-col md="12" cols="12">
                    <v-expansion-panels multiple class="mb-0">
                      <v-expansion-panel>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          Assigned Permissions List
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-container fluid>
                            <v-row>
                              <v-col
                                  v-for="permission in rolePermissions"
                                  :key="permission.id"
                                  cols="12"
                                  sm="4"
                                  md="6"
                                  lg="6"
                              >
                                <v-checkbox
                                    disabled
                                    :label="permission.permissionName"
                                    color="info"
                                    :input-value="permission.permissionName"
                                    hide-details
                                    @change="changeCurrentPermission(permission.id)"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header expand-icon="mdi-menu-down">
                          Permissions List
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-treeview
                                selectable
                                :items="permissiongroups"
                                v-model="permissions"
                            ></v-treeview >
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="$emit('close-dialog')"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="editDefaultRole"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Icon from "@/views/Icon.vue";
import allPermisionGroups from "@/graphql/eboard-auth-graphql/allPermisionGroups.gql";

export default {
  name: "EditUserRole",
  components: {Icon},


  data: () => ({
    dialog: false,
    permissions: [],
    permissiongroups: [],
  }),

  props: [
      "roleUniqueId",
      "roleName",
      "roleDescription",
      "roleIsAdmin",
      "roleIsSystemDefault",

      "permissionsList",
      "rolePermissions",
      "roleItem",

      "editDefaultUserRole",

  ],

  created(){
    this.$apollo.query({
      query:allPermisionGroups
    }).then((response)=>{
      let groups_response=response.data.userPermissionsGroups.edges;
      groups_response.forEach(group => {
        let group_id=group.node.permissionGroupUniqueId
        let group_name=group.node.permissionGroupName
        let group_children=[]

        group.node.permissionGroup.edges.forEach(children => {
          group_children.push({
            id:children.node.permissionUniqueId,
            name:children.node.permissionName
          })
        });

        this.permissiongroups.push(
            {
              id:group_id,
              name:group_name,
              children:group_children
            }
        )
      });
    })
  },


  methods: {
    async editDefaultRole(){
      let selectedPermissions = []
      let permissionList = [];

      this.permissions.forEach((perm)=>{
        selectedPermissions.push(perm)
      })

      this.rolePermissions.forEach((permission)=>{
        const is_selected=permissionList.find((permision)=> permision===permission.permissionUniqueId)
        if (!is_selected){
          permissionList.push(permission.permissionUniqueId)
        }
      })

      let permissions = selectedPermissions.concat(permissionList)
      let uniqueList = permissions.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      });

      let input = {
        roleUniqueId: this.roleUniqueId,
        roleName: this.roleName,
        roleDescription: this.roleDescription,
        rolePermissions: uniqueList,
        roleIsAdmin: this.roleIsAdmin,
      }
      await this.$store.dispatch('editDefaultUserRole', input)
      this.$emit('close-dialog')
    },

    changeCurrentPermission(key) {
      this.$emit("changePermission", key);
    },

    changeRemainedPermission(key) {
      this.$emit("changeRemainedPermission", key);
    },


  },

}
</script>

<style scoped>

</style>