<template>
  <v-footer dark padless>
    <v-card flat tile color="rgba(30, 58, 138)" class=" white--text text-center">
      <v-card-text>

      </v-card-text>

      <v-card-text class="white--text pt-0">
       This product was designed and maintained by e-Government Authority Tanzania
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>eGA - All Rights reserved</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-linkedin",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/joabson_arley/",
      },
    ],
  }),
};
</script>
