<template>
    <v-container fluid>
        <v-row>
            
            
            <v-col sm='12' cols="12">
                <v-chip style="width:100%" label outlined >
                    <h4>Build your board report from the best available report templates</h4>
                </v-chip>
            </v-col>
      </v-row>
        <v-row>
            <v-col sm='3' cols="3">
                <v-card
                    :loading="loading"
                    link
                    to="/eboard_report_builder_templates"
                    class="mx-auto my-12"
                    max-width="374"
                >
                    <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                    </template>

                    <v-img
                    height="250"
                    src="/templates.png"
                    ></v-img>
                    <v-card-title> Report Templates </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                </v-card>
            </v-col>
            <v-col sm='3' cols="3">
                <v-card
                    :loading="loading"
                    link
                    to="/e_board_report_builder_drafts"
                    class="mx-auto my-12"
                    max-width="374"
                >
                    <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                    </template>

                    <v-img
                    height="250"
                    src="/drafts.png"
                    ></v-img>
                    <v-card-title> Draft Reports </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                </v-card>
            </v-col>
            <!-- <v-col sm='3' cols="3">
                <v-card
                    :loading="loading"
                    link
                    to="/e_board_report_builder_final"
                    class="mx-auto my-12"
                    max-width="374"
                >
                    <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                    </template>

                    <v-img
                    height="250"
                    src="/report.png"
                    ></v-img>
                    <v-card-title> Saved Reports </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                </v-card>
            </v-col> -->
        </v-row>



    </v-container>
</template>

<script>

export default {
    data(){
        return{
        }
    },

}
</script>

<style>

</style>


