<template>
  <v-row>
    <v-dialog
        v-model="newInstitutionCategoryDialog"
        max-width="70%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <span class="text-h6"> <v-icon>mdi-home-group</v-icon> Add Institution Category</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                      outlined
                      v-model="categoryName"
                      :counter="100"
                      label="Category Name *"
                      prepend-icon="mdi-text-short"
                      required >
                  </v-text-field>
                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="closeDialog"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="createInstitutionCategory"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Icon from "@/views/Icon.vue";

export default {
  name: "AddInstitutionCategory",
  components: {Icon},

  data: () => ({
    categoryName: ""
  }),

  props: ["newInstitutionCategoryDialog"],

  methods: {
    async closeDialog() {
      this.categoryName = ""
      this.$emit('close-dialog')
    },

    async createInstitutionCategory(){
      let input = {
        categoryName: this.categoryName,
      }

      await this.$store.dispatch('createInstitutionCategory', input)
      this.categoryName = ""

      this.$emit('close-dialog')
    },
  }
}
</script>

<style scoped>

</style>