import {apolloClient} from '@/vue-apollo'
import allMyAsignedDirectives from '../../graphql/eboard-actions-graphql/allMyAsignedDirectives.gql'
import reportDirectiveProgress from '../../graphql/eboard-actions-graphql/reportDirectiveProgress.gql'
import swal from 'sweetalert2'
import directiveProgress from "../../graphql/eboard-actions-graphql/directiveProgress.graphql";
import sendDirectiveReminder from "../../graphql/eboard-actions-graphql/sendDirectiveReminder.gql";
import downloadMeetingDirectives from "@/graphql/eboard-actions-graphql/downloadMeetingDirectives.gql";
import {errorAlert} from "@/services/notificationService";


export default {

    state: {
        completedDirectives: {
            completedDirectivesList: [],
            page: null
        },
        assignedDirectives: {
            assignedDirectivesList: [],
            page: null
        },
        progressReportDocs: [],
        directiveProgress: []
    },

    mutations: {
        cleanDirectives(state) {
            state.assignedDirectives.assignedDirectivesList = []
            state.completedDirectives.completedDirectivesList = []
        },
        cleanDirectiveProgress(state) {
            state.directiveProgress = []
        },
        updateDirectives(state, progress) {
            let progressDirective = progress.progressDirective
            let progressType = progress.progressType
            let directiveIndex
            state.assignedDirectives.assignedDirectivesList = state.assignedDirectives.assignedDirectivesList.map((directive) => {
                let directiveImplementation = directive.directiveImplementation
                let directiveStatus = directive.directiveStatus
                if (progressDirective === directive.directiveUniqueId) {
                    if (progressType === "Done") {
                        directiveImplementation = "DONE"
                        directiveStatus = true
                        state.completedDirectives.completedDirectivesList.push({
                            ...directive,
                            directiveStatus,
                            directiveImplementation
                        })
                    }
                    if (progressType === "In Progress") {
                        directiveImplementation = "INPROG"
                    }
                    if (progressType === "On Hold") {
                        directiveImplementation = "ONHOLD"
                    }
                    if (progressType === "Not Started") {
                        directiveImplementation = "NOTSTARTED"
                    }
                }
                return {
                    ...directive,
                    directiveStatus,
                    directiveImplementation
                }
            });

            if (progressType === "Done") state.assignedDirectives.assignedDirectivesList.splice(directiveIndex, 1)

            // var tempList = []
            // state.assignedDirectives.assignedDirectivesList.forEach(directive => {
            //     if (progressDirective === directive.directiveUniqueId) {
            //         directive.directiveStatus = true
            //         state.completedDirectivesList.push(directive)
            //     } else {
            //         tempList.push(directive)
            //     }
            // });
            //
            // state.assignedDirectives.assignedDirectivesList = tempList
        },
        addDirectives(state, directive) {
            state.assignedDirectives.assignedDirectivesList = directive.data
            state.assignedDirectives.page = directive.page
        },
        addCompletedDirectives(state, directive) {
            state.completedDirectives.completedDirectivesList = directive.data
            state.completedDirectives.page = directive.page
        },
        addDirectiveProgress(state, directive) {
            state.directiveProgress.push(directive)
        },
    },

    actions: {
        async loadCompletedAssignedDirectives(context, directiveObj) {
            context.rootState.ResolutionStore.progressReportDocs = []
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMyAsignedDirectives,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    filterKey: directiveObj.filterKey,
                    filterBy: directiveObj.filterBy,
                    directiveStatus: true,
                    pageNumber: directiveObj.pageNumber
                }
            }).then((response) => {
                const directives = response.data.allMyAsingedDirectives;
                if (directives.response.status) {
                    context.commit('addCompletedDirectives', {data: directives.data, page: directives.page})
                }
            }).catch(async (error) => {
                console.error(error);
            })
        },

        async loadAssignedDirectives(context, directiveObj) {
            context.rootState.ResolutionStore.progressReportDocs = []
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMyAsignedDirectives,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    filterKey: directiveObj.filterKey,
                    filterBy: directiveObj.filterBy,
                    directiveStatus: false,
                    pageNumber: directiveObj.pageNumber
                }
            }).then((response) => {
                const directives = response.data.allMyAsingedDirectives;
                if (directives.response.status) {
                    context.commit('addDirectives', {data: directives.data, page: directives.page})
                }
            }).catch(async (error) => {
                console.error(error);
            })
        },


        async reportDirectivesProgress(context, progress) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: reportDirectiveProgress,
                variables: {
                    progress: progress
                }
            }).then((response) => {
                const saved = response.data.reportDirectiveProgress.progress.saved;
                context.rootState.ResolutionStore.progressReportDocs = []
                swal.fire({
                    toast: true,
                    icon: 'success',
                    title: saved,
                    animation: false,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                context.commit('updateDirectives', progress)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadDirectiveImplementationProgress(context, directiveUniqueId) {
            context.commit('cleanDirectiveProgress')
            await context.dispatch("showLoadingSpinner", true);
            context.rootState.ResolutionStore.progressReportDocs = []
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: directiveProgress,
                variables: {
                    directiveUniqueId: directiveUniqueId,
                }
            }).then((response) => {
                var directives_progress = response.data.directiveProgress.progressDirective.edges
                directives_progress.forEach(item => {
                    context.commit('addDirectiveProgress', item.node)
                })
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


        async SendDirectivesReminder(context, reminder) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: sendDirectiveReminder,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    directiveUniqueId: reminder.directiveUniqueId,
                    reminderDate: reminder.reminderDate,
                    reminderMessage: reminder.reminderMessage,
                }
            }).then((response) => {
                if (response.data.sendDirectiveReminder.reminderSent === true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Reminder sent successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Error occored while set reminder',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);

            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },



        async downloadBoardMeetingDirectives(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: downloadMeetingDirectives,
                variables: { ...input },
            }).then(async (result) => {
                let {success, directiveDocumentPath} = result.data.downloadMeetingDirectives;
                if (success)
                    window.open(`${process.env.VUE_APP_MEDIA_SERVER}/${directiveDocumentPath}`, "_blank");
                else {
                    await errorAlert('Error occurred while downloading directives.');
                }

                await context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                console.error("downloadManagementBoardMeetingDirectives ==>", error);
                await context.dispatch("showLoadingSpinner", false);
            })
        },

    },
}
