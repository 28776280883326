import {apolloClient} from '@/vue-apollo'
import uploadFile from '../../graphql/management-documents-graphql/uploadFile.gql'
import addManagementCommitteeAgendaSupportingDocumentMutation
  from '../../graphql/management-committee-supporting-docs-graphql/addManagementCommitteeAgendaSupportingDocumentMutation.gql'
import deleteManagementCommitteeAgendaSupportingDocumentMutation from "../../graphql/management-committee-supporting-docs-graphql/deleteManagementCommitteeAgendaSupportingDocumentMutation.graphql";
import {errorAlert, successAlert} from "@/services/notificationService";

export default {
  state: {
    managementAgendaSupportingDocs: [],
    managementMinutesSupportingDocs: [],
  },

  mutations: {
    clearManagementAgendaDocuments(state) {
      state.managementAgendaSupportingDocs = []
    },
    addManagementSupportingAgendaDocuments(state, file) {
      state.managementAgendaSupportingDocs.push(file)
    },
    addManagementSupportingMinutesDocuments(state, file) {
      state.itemMinutesSupportingDocs.push(file)
    }
  },

  getters: {
    getManagementMeetingAgendaSupportingDocuments(state) {
      return state.managementAgendaSupportingDocs
    },
    getManagementSelectedAgendaDocuments(state) {
      return state.managementAgendaSupportingDocs
    }
  },

  actions: {
    async addManagementCommitteeAgendaSupportingDocumentMutation(context, input) {
      await apolloClient.mutate({
        mutation: addManagementCommitteeAgendaSupportingDocumentMutation,
        variables: {input},
      }).then((result) => {
        let {response, data} = result.data.addManagementCommitteeAgendaSupportingDocumentMutation;

        if (response.code === 9000) {
          context.commit('addSelectedAgendaSupportingDocument', data)
          successAlert(result.response.message)
        } else {
          errorAlert(result.response.message)
        }
      })
    },

    async deleteManagementCommitteeAgendaSupportingDocumentMutation(context, input) {
      await apolloClient.mutate({
        mutation: deleteManagementCommitteeAgendaSupportingDocumentMutation,
        variables: {input},
      }).then((response) => {
        let result = response.data.deleteManagementCommitteeAgendaSupportingDocumentMutation;

        if (result.response.code === 9000) {
          context.commit('deleteSelectedAgendaSupportingDocument', input.documentUniqueId)
          successAlert(result.response.message)
        } else {
          errorAlert(result.response.message)
        }
      })
    },

    async submitManagementCommitteeFile(context, sendData) {
      await context.dispatch("showLoadingSpinner", true);
      await apolloClient.mutate({
        mutation: uploadFile,
        loading: this.value,
        variables: {
          file: sendData.file,
        },
      }).then(async (response) => {
        let result = response.data.uploadFile;

        if (result.success) {
          const supportingDocumentObj = {
            agendaUniqueId: sendData.data.id,
            documentName: result.fileName,
            documentPath: result.fullFileName,
          }
          await context.dispatch("addManagementCommitteeAgendaSupportingDocumentMutation", supportingDocumentObj);
        }

        await errorAlert("Failed to upload agenda Supporting document");

        await context.dispatch("showLoadingSpinner", false);
      }).catch(async (error) => {
        console.error(error)
        await context.dispatch("showLoadingSpinner", false);
      })
    },

  }
}