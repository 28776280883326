<template>
  <v-container fluid class="my-5" app>
    <v-row>
      <v-col class="flex items-center">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                right
                small
                outlined
                rounded
                fab
                color="primary white--text"
                style="margin: 5px"
                @click="$router.go('-1')"
            ><v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Meeting Details</span>
        </v-tooltip>
        <h2 class="text-xl ml-2">{{ meetingDetails.meetingTitle }}</h2>
      </v-col>
    </v-row>
    <v-row justify="center">
        <v-layout  style="padding-top:10px" justify-space-around>
          <v-flex  xs12 md4 max-width="100%">
            <ManagementCommitteeAgendaSidePanel :meetingHasPublishedAgenda="hasPublishedAgenda" :currentMeeting="currentMeeting" />
          </v-flex>
          <v-flex xs12 md8 max-width="100%">
            <ManagementAgendaDetails :meetingHasPublishedAgenda="hasPublishedAgenda" :currentMeeting="currentMeeting" />
          </v-flex>
        </v-layout>
    </v-row>
  </v-container>
</template>

<script>
import ManagementAgendaDetails from './ManagementCommitteeAgendaDetails.vue'
import ManagementCommitteeAgendaSidePanel from './ManagementCommitteeAgendaSidePanel.vue'

export default {
  name: "ManagementCommitteeAgendaBuilder",
  props:['AgendaBuilderWindow'],
  components:{
    ManagementAgendaDetails,
    ManagementCommitteeAgendaSidePanel
  },
  computed:{
    hasPublishedAgenda(){
      return this.$store.getters.getSelectedManagementCommitteeMeetings.meetingHasPublishedAgenda || false
    },
    meetingDetails() {
      return this.$store.getters.getSelectedManagementCommitteeMeetings
    },
    currentMeeting() {
      return this.$route.params.meetingUniqueId
    },
    agendaList() {
      return this.$store.getters.getManagementCommitteeAgendas
    },
  },
  mounted() {
    this.$store.dispatch('loadManagementCommitteeMeetings', { meetingUniqueId: this.currentMeeting })
    this.$store.dispatch('loadManagementCommitteeAgendas', this.currentMeeting)
  }

}
</script>

<style>

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>