<template>
  <v-container app class="my-5" fluid>
    <div class="flex justify-content-center padding">
      <div class="flex justify-content-between full-width">

        <v-layout justify-space-around row wrap>
          <v-btn
              @click="$router.go(-1)"
              color="primary" link outlined rounded small
          >
            <span class="flex center"><v-icon>mdi-arrow-left</v-icon> Back </span>
          </v-btn>

          <v-flex></v-flex>

          <v-menu v-if="agendaList.length >0" app offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn slot="activator" class="mr-1" color="primary" outlined rounded small v-bind="attrs" v-on="on"><span
                  class="flex center">
                    <v-icon>mdi-file-table-outline</v-icon>
                        &nbsp; Agenda &nbsp;<v-icon>mdi-chevron-down</v-icon>
                </span>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="openAgendaView">
                <v-list-item-title>
                  <v-icon>mdi-archive-eye-outline</v-icon>
                  Open
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  v-if="meetingDetails.meetingHasPublishedAgenda"
                  @click="previewAgendas">
                <v-list-item-title>
                  <v-icon>mdi-open-in-new</v-icon>
                  Preview
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu v-if="meetingDetails.meetingHasPublishedAgenda" app offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn slot="activator" class="mr-1" color="primary" outlined rounded small v-bind="attrs" v-on="on"><span
                  class="flex center"><v-icon style="height: 18px;font-size: 18px;">mdi-note-multiple</v-icon>
                                              &nbsp; minutes &nbsp;<v-icon>mdi-chevron-down</v-icon>
                                      </span>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="previewMinutes">
                <v-list-item-title>
                  <v-icon style="height: 18px;font-size: 18px;">mdi-open-in-new</v-icon>
                  Preview
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!meetingDetails.meetingHasPublishedAgenda" @click="editMeeting" class="mr-1"
                     color="primary white--text" outlined right
                     rounded
                     small v-bind="attrs"
                     v-on="on">
                <icon name="meeting_add" width="w-5" height="h-5" color="#3F51B5" class="mr-2"></icon>
                Edit Meeting
              </v-btn>
            </template>
            <span>Edit Management Meeting</span>
          </v-tooltip>
          <v-tooltip bottom color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!meetingDetails.meetingHasPublishedAgenda" @click="postPoneMeeting" class="mr-1"
                     color="warning white--text" outlined right
                     rounded
                     small v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-calendar-weekend</v-icon>
                Postpone Meeting
              </v-btn>
            </template>
            <span>Postpone or Re-Schedule Meeting</span>
          </v-tooltip>
          <v-tooltip bottom color="red">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="meetingDetails.meetingHasPublishedAgenda" @click="closeMeeting" class="mr-1"
                     color="red white--text" outlined right
                     rounded
                     small v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-close</v-icon>
                Close Meeting
              </v-btn>
            </template>
            <span>Close Meeting</span>
          </v-tooltip>

        </v-layout>

      </div>
    </div>


    <div class="flex justify-content-center padding">
      <div class="flex full-width" style="padding-top: 10px; padding-bottom: 10px">
        <v-layout align-content-center row wrap>
          <v-flex>
            <h2 style="font-size: x-large">{{ meetingDetails.meetingTitle }}</h2>
          </v-flex>

          <v-flex></v-flex>

          <div>
            <div class="flex flex-align-items-center">
              <v-tooltip v-if="agendaList.length <1" bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      class="white--text"
                      color="primary white--text"
                      outlined
                      right rounded small style="margin:5px" title="" v-bind="attrs"
                      @click="buildAgenda"
                      v-on="on">
                    <v-icon>mdi-file-table-outline</v-icon>
                    Build Agenda
                  </v-btn>
                </template>
                <span>Build Agenda</span>
              </v-tooltip>

              <v-tooltip v-if="meetingDetails.meetingHasPublishedAgenda" bottom color="blue">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="blue white--text" outlined right rounded small style="margin:5px" title=""
                         v-bind="attrs"
                         @click="takeManagementCommitteeMinutes()" v-on="on">
                    <v-icon>mdi-script-text</v-icon>
                    Take Minutes
                  </v-btn>
                </template>
                <span>Take Meeting Minutes</span>
              </v-tooltip>
            </div>
          </div>
        </v-layout>
      </div>
    </div>
    <!--    Meeting Details Start -->

    <div>
      <v-layout justify-space-around row wrap>
        <v-card class="full-width">
          <v-simple-table>
            <template>
              <thead>
              <tr>
                <th class="text-left" style="width:10%">

                </th>
                <th class="text-left" style="width:80%">

                </th>
              </tr>
              </thead>
              <tbody>

              <tr>
                <td><strong left>Date:</strong></td>
                <td>{{ meetingDetails.meetingDate|formatDate }}</td>
              </tr>

              <tr>
                <td><strong left>Time:</strong></td>
                <td>
                  <span> {{ meetingDetails.meetingStartTime }} -</span>
                  <span> {{ meetingDetails.meetingEndTime }}</span>
                </td>
              </tr>

              <tr>
                <td><strong left>Location:</strong></td>
                <td>
                  <span> {{
                      meetingDetails.meetingLocation.locationName
                    }} ({{ meetingDetails.meetingLocation.locationAddress }})</span>
                </td>
              </tr>

              <tr>
                <td><strong>Meeting Administrator:&nbsp;</strong></td>
                <td>
                  <span>{{ meetingDetails.meetingCreatedBy }}</span>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Attendees/Apologies: &nbsp;</strong>
                  <span
                  ><small color="orange"
                  >NOTE: You can drag and move people between Attendees or
                        Apologies.</small
                  ></span
                  >
                </td>
                <td>
                  <v-layout>
                    <v-flex
                        md5
                        xs12
                        @drop="onDrop($event, true)"
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                    >
                      <v-subheader color="primary">
                        <strong>Attendees:</strong>
                      </v-subheader>
                      <draggable
                          v-model="attendees"
                          :options="{ group: 'people' }"
                          style="min-height: 10px"
                      >
                        <template v-for="item in meetingAttendees">
                          <v-chip
                              :id="item.id"
                              :key="item.id"
                              :draggable="draggable"
                              class="ma-2"
                              height="31"
                              width="88"
                              @dragstart="startDrag($event, item)"
                              @mouseleave="c_index = -1"
                              @mouseover="c_index = index"
                              @drag.prevent="drag"
                          >
                            {{ item.firstName }} {{ item.lastName }}
                            <v-icon v-show="index === c_index" right
                            >mdi-drag-vertical
                            </v-icon
                            >
                          </v-chip>
                        </template>
                      </draggable>
                    </v-flex>

                    <v-flex
                        md5
                        xs12
                        @drop="onDrop($event, false)"
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                    >
                      <v-subheader>
                        <strong>Absentees with Apologies: </strong>
                      </v-subheader>
                      <draggable style="min-height: 10px">
                        <template v-for="item in meetingApologies">
                          <v-chip
                              :id="item.id"
                              :key="item.id"
                              :draggable="draggable"
                              class="ma-2"
                              height="31"
                              width="88"
                              @dragstart="startDrag($event, item)"
                              @mouseleave="c_index = -1"
                              @mouseover="c_index = index"
                              @drag.prevent="drag"
                          >
                            {{ item.firstName }} {{ item.lastName }}
                            <v-icon v-show="index === c_index" right
                            >mdi-drag-vertical
                            </v-icon
                            >
                          </v-chip>
                        </template>
                      </draggable>
                    </v-flex>

                    <v-flex
                        md2
                        xs12
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                    >
                      <v-menu app offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              v-if="unConfirmMembers.length > 0"
                              slot="activator"
                              color="indigo"
                              small
                              style="text-transform: capitalize"
                              text
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-icon small>mdi-account-plus-outline</v-icon>
                            Add from Management List
                          </v-btn>
                          <v-btn
                              v-else
                              color="indigo"
                              small
                              style="text-transform: capitalize"
                              text
                              @click="addPeopleDialog = true"
                          >
                            <v-icon small>mdi-account-plus-outline</v-icon>
                            Add from Management List
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                              v-for="(people, index) in unConfirmMembers"
                              :key="index.id"
                              link
                          >
                            <v-list-item-title @click="addAttendees(people)"
                            >{{
                                people.userProfileTitle
                              }} {{ people.firstName }}
                              {{ people.lastName }}
                            </v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>

                  </v-layout>
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

        </v-card>
      </v-layout>
    </div>

<!--  Models Starts-->
    <ManagementCommitteeMeetingPostpone
        :is-postpone-meeting="isPostponeMeeting"
        :current-meeting-details="currentMeetingDetails"
        @closePostponeMeeting="closePostponeMeeting"></ManagementCommitteeMeetingPostpone>
    <EditManagementCommitteeMeeting
        :editMeetingDialog="editMeetingDialog"
        :current-meeting-details="currentMeetingDetails"
        @closeEditMeeting="closeEditMeeting"></EditManagementCommitteeMeeting>
    <ManagementCommitteeBuildAgenda
        :builder-mode-dialog="builderModeDialog"
        :current-meeting="meetingDetails"
        @closeBuildAgendaDialog="builderModeDialog = false"></ManagementCommitteeBuildAgenda>

    <DocumentViewer v-if="OpenfileDialog===true" :OpenfileDialog="OpenfileDialog" :docValue="docValue" :title="title"
                    @CloseDocumentViwer="OpenfileDialog=false"/>

<!--    Take minutes Model-->

  </v-container>
</template>

<script>

import ManagementCommitteeMeetingPostpone
  from "@/modules/management_committee/management_committee_meeting/ManagementCommitteeMeetingPostpone.vue";
import Icon from "@/views/Icon.vue";
import EditManagementCommitteeMeeting
  from "@/modules/management_committee/management_committee_meeting/EditManagementCommitteeMeeting.vue";
import ManagementCommitteeBuildAgenda
  from "@/modules/management_committee/management_committee_agenda/ManagementCommitteeBuildAgenda.vue";
import DocumentViewer from '@/modules/management_meeting_management/management-documents/DocumentViwer.vue'
import {confirmAlert} from "@/services/notificationService";

export default {
  name: "ManagementCommitteeMeetingDetails",
  components: {
    ManagementCommitteeBuildAgenda,
    EditManagementCommitteeMeeting,
    Icon,
    ManagementCommitteeMeetingPostpone,
    DocumentViewer
  },
  mounted() {
    this.$store.dispatch('loadManagementCommitteeMeetings', { meetingUniqueId: this.$route.params.meetingUniqueId })
    this.$store.dispatch('loadManagementCommitteeMeetingAttendances', this.$route.params.meetingUniqueId)
    this.$store.dispatch('loadManagementCommitteeAgendas', this.$route.params.meetingUniqueId)
    this.$store.dispatch('previewManagementCommitteeMinutes', this.$route.params.meetingUniqueId)
    this.$store.dispatch('loadManagementCommitteeMembers')
  },
  data() {
    return {
      builderModeDialog: false,
      ListP: '',
      draggable: true,
      newAgendaKey: 77,
      bestPractise: null,
      index: null,
      value: null,
      OpenNewUserForm: false,
      addPeopleDialog: false,
      docValue: '',
      OpenfileDialog: false,
      Agendadialog: false,
      meetingData: '',
      title: '',
      OpenNoticeDialog: false,
      TakeminutesWindow: false,
      hover: true,
      minutesDialog: false,
      AgendaBuilderWindow: false,
      hidden: false,
      disabled: true,
      progressShown: false,
      c_index: -1,
      primary: "",
      dialog2: false,
      questionadd: false,
      eboard_schedules: '/eboard_schedules/',
      meetingUniqueId: '',
      value1: 'value1',
      attendees: [],
      people_list: [],
      switchValue: localStorage.getItem("dragable"),
      meetingTitle: '',
      isPostponeMeeting: false,
      editMeetingDialog: false,
      currentMeetingDetails: {},
      meetingLocation: '',
      closeMeetingFlag: false
    }
  },
  watch: {
    closeMeetingFlag() {
      if (this.closeMeetingFlag) {
        this.$router.push('/management-committee/meeting-schedules')
      }
    }
  },
  computed: {
    meetingDetails() {
      return this.$store.getters.getSelectedManagementCommitteeMeetings
    },
    meetingMinutes() {
      return this.$store.getters.getSelectedManagementCommitteeMeetingMinutes
    },
    meetingAttendees() {
      return this.$store.getters.getManagementCommitteeMeetingAttendees
    },
    meetingAllAttendees() {
      return this.$store.getters.getManagementCommitteeMeetingAttendances
    },
    meetingApologies() {
      return this.$store.getters.getManagementCommitteeMeetingApologiesAttendees
    },
    managementCommitteeMembers() {
      return this.$store.getters.getManagementCommitteeMembers
    },
    agendaList() {
      return this.$store.getters.getManagementCommitteeAgendas
    },
    unConfirmMembers() {
      let usersList = [];

      this.managementCommitteeMembers.forEach((member) => {
        usersList.push({
          id: member.id,
          userProfileTitle: member.userProfileTitle,
          firstName: member.firstName,
          lastName: member.lastName,
          userId: member.username,
        });
      });

      this.meetingAllAttendees.forEach((attendee) => {
        usersList = usersList.filter(
            (member) => member.id !== attendee.id
        );
      });

      return usersList;
    },
  },
  methods: {
    previewAgendas() {
      this.docValue = this.meetingDetails.meetingDocumentPath
      this.OpenfileDialog = true
    },
    previewMinutes() {
      this.docValue = this.meetingMinutes
      this.title = "Meeting Minutes"
      this.OpenfileDialog = true
    },
    buildAgenda() {
      this.builderModeDialog = true
    },
    openAgendaView() {
      this.$router.push({path: '/management-committee/meeting-schedules/meeting-agendas/' + this.$route.params.meetingUniqueId})
    },
    takeManagementCommitteeMinutes() {
      this.$router.push({path: '/management-committee/meeting-schedules/agenda-minutes/' + this.$route.params.meetingUniqueId})
    },
    postPoneMeeting() {
      this.isPostponeMeeting = true;
      this.currentMeetingDetails = this.meetingDetails;
    },
    closeMeeting() {
      confirmAlert("Are you sure you want to close this meeting?", "Close").then((data) => {
        if (data.isConfirmed) {
          this.$store.dispatch('closeManagementCommitteeMeeting', {
            meetingUniqueId: this.meetingDetails.id
          })
          // this.$store.dispatch('loadManagementCommitteeMeetings')

          setTimeout(() => {
            this.closeMeetingFlag = true
          }, 1000)
        }
      })
    },
    editMeeting() {

      this.currentMeetingDetails = this.meetingDetails;
      this.editMeetingDialog = true;
    },
    closePostponeMeeting() {
      this.isPostponeMeeting = false;
      this.currentMeetingDetails = {};
    },
    closeEditMeeting() {
      this.editMeetingDialog = false;
      this.currentMeetingDetails = {};
    },
    async addAttendees(newAttendee) {
      const attendee = {
        attendanceMeeting: this.$route.params.meetingUniqueId,
        attendanceUser: newAttendee.id,
        attendanceAcceptInvitation: true,
      };
      await this.$store.dispatch("createManagementCommitteeMeetingAttendance", attendee);
    },

    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
      localStorage.setItem("itemID", item.id);
    },

    async onDrop(evt, side) {
      const data = localStorage.getItem("itemID");
      let input = {
        attendanceUser: data,
        attendanceAcceptInvitation: side,
        attendanceExplanation: '',
        attendanceMeeting: this.$route.params.meetingUniqueId,
      }
      await this.$store.dispatch("editManagementCommitteeMeetingAttendance", input);
    },

    allowDrop: (ev) => {
      ev.preventDefault();
    },

    drag: (ev) => {
      localStorage.setItem("dragable", ev.target.id);
    },

    drop: (ev) => {
      ev.preventDefault();
      const data = localStorage.getItem("dragable");
      ev.target.appendChild(document.getElementById(data));
    },
  }
}
</script>


<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>

<style>

.node {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 40px;
}

.events-banner {
  padding: 5px;
  font-size: small;
  display: block;
}

.events-banner .prefix {
  color: #858585;
}

.events-banner .content {
  font-weight: bold;
  color: #273080;
}

.events-banner .value {
  color: #3a5831;
  font-size: larger;
}

.events-banner-event {
  background-color: #f9f9f9;
  margin: 2px;
  padding: 10px;
  display: inline-block;
  clear: left;
  width: auto;
}

.vue-xeditable-value {
  cursor: pointer;
  color: #17c;
  border-bottom: 1px dashed #07c;
  line-height: 22px;
}

h1 {
  margin: 0;
  text-align: center;
  color: #42b983;
}

h4 {
  margin-bottom: 0;
  color: #404040;
}

a {
  color: #07c;
}

pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}

.highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre code, pre tt {
  background-color: transparent;
  border: none;
}
</style>
