<template>
  <v-container app class="my-5" fluid>
    <div class="flex justify-content-center padding">
      <div class="flex justify-content-between full-width">
        <v-layout justify-space-around row wrap>
          <v-flex md6 style="padding: 10px" xs12>
            <v-btn
              @click="$router.go(-1)"
              color="primary"
              link
              rounded
              small
            >
              <span class="flex center"
                ><v-icon>mdi-arrow-left</v-icon> Meeting List
              </span>
            </v-btn>
          </v-flex>
          <v-flex md6 xs12>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              outlined
              rounded
              small
              @click="OpenRepliesDialog = true"
              v-if="HasPublishedAgenda && hasAccess('can_view_absentees')"
            >
              <span class="flex center">
                <v-icon style="heigh: 18px; font-size: 18px">
                  mdi-hands-pray </v-icon
                >&nbsp; Absentees
              </span>
            </v-btn>
            <ApollogyReplies
              :OpenRepliesDialog="OpenRepliesDialog"
              @CloseRepliesDialog="OpenRepliesDialog = false"
            />

            <v-btn
              v-if="hasAccess('can_schedule_meeting')"
              color="primary"
              outlined
              rounded
              small
              @click="OpenNoticeDialog = true"
            >
              <span class="flex center">
                <v-icon style="height: 18px; font-size: 18px"
                  >mdi-calendar-check</v-icon
                >
                &nbsp; Notice
              </span>
            </v-btn>

            <MeetingNotice
              :OpenNoticeDialog="OpenNoticeDialog"
              :meetingData="meetingDetails"
              @CloseNoticeDialog="OpenNoticeDialog = false"
            />

            <v-menu v-if="listOfagendas.length > 0" app offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  slot="activator"
                  color="primary"
                  outlined
                  rounded
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><span class="flex center">
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-file-table-outline</v-icon
                    >
                    &nbsp; Agenda &nbsp;<v-icon>mdi-chevron-down</v-icon>
                  </span>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="AgendaBuilderWindow = true">
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-archive-eye-outline
                    </v-icon>
                    Open
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="
                    (
                      this.$store.state.MeetingsStore.selectedMeetingDetails ||
                      {}
                    ).meetingHasPublishedAgenda &&
                    hasAccess('can_declare_interest')
                  "
                  @click="openInterestRegisterWindow = true"
                >
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-circle-edit-outline
                    </v-icon>
                    Declare Conflict of Interest
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="
                    (
                      this.$store.state.MeetingsStore.selectedMeetingDetails ||
                      {}
                    ).meetingHasPublishedAgenda &&
                    hasAccess('can_view_meeting_interest_list')
                  "
                  @click="loadMeetingConflictsOfInterest"
                >
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-circle-multiple-outline
                    </v-icon>
                    View Conflicts of Interest
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="
                    (
                      this.$store.state.MeetingsStore.selectedMeetingDetails ||
                      {}
                    ).meetingHasPublishedAgenda
                  "
                  @click="previewAgendas"
                >
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-open-in-new
                    </v-icon>
                    Preview
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu v-if="meetingNoHasPack" app offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="HasPublishedAgenda && !meetingNoHasPack"
                  slot="activator"
                  color="primary"
                  outlined
                  rounded
                  small
                  v-bind="attrs"
                  v-on="on"
                  ><span class="flex center">
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-book-multiple</v-icon
                    >
                    &nbsp; Board Pack &nbsp;<v-icon>mdi-chevron-down</v-icon>
                  </span>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item link @click="openPack()">
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-file-document-edit-outline
                    </v-icon>
                    Pack Annotations
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link @click="previewBoardPack">
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-open-in-new
                    </v-icon>
                    Preview
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="hasAccess('can_schedule_meeting')" link>
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-email-outline
                    </v-icon>
                    Email
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-download-circle-outline
                    </v-icon>
                    Download
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-delete
                    </v-icon>
                    Delete My Notes
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>


            <v-menu app offset-y  v-if="(meetingDetails || {}).meetingHasNotice && HasPublishedAgenda && (this.$store.state.MeetingsStore.selectedMeetingDetails || {}).meetingMinutesStarted">
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-btn
                  slot="activator"
                  color="primary"
                  outlined
                  rounded
                  small
                  v-bind="attrs"
                  v-on="on"
                  >
                  <span class="flex center">
                    <v-icon style="height: 18px; font-size: 18px">mdi-note-multiple</v-icon>
                    &nbsp; minutes &nbsp;<v-icon>mdi-chevron-down</v-icon>
                  </span>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-if="hasAccess('can_preview_meeting')"
                  link
                  @click="previewMeetingMinutesMinutes"
                >
                  <v-list-item-title>
                    <v-icon style="height: 18px; font-size: 18px"
                      >mdi-open-in-new
                    </v-icon>
                    Preview
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip
              bottom
              color="warning"
              v-if="
                hasAccess('can_schedule_meeting') &&
                !quorumStatus &&
                !HasPublishedAgenda
              "
            >
              <template v-slot:activator="{ on, attrs }">
              <v-btn @click="postPoneMeeting" class="mr-1"
                     color="warning white--text" outlined right
                     rounded
                     small v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-calendar-weekend</v-icon>
                Postpone Meeting
              </v-btn>
              </template>
              <span>Postpone or Re-Schedule Meeting</span>
            </v-tooltip>

            <v-tooltip
              v-if="
                hasAccess('can_schedule_meeting') &&
                !quorumStatus &&
                !HasPublishedAgenda
              "
              bottom
              color="error"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="deleteBoardMeeting" class="mr-1"
                       color="pink white--text" outlined right
                       rounded
                       small v-bind="attrs"
                       v-on="on">
                  <v-icon>mdi-calendar-weekend</v-icon>
                  Delete Meeting
                </v-btn>
              </template>
              <span>Delete this Meeting</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </div>
    </div>

    <div class="flex justify-content-center padding">
      <div class="flex justify-content-between center full-width">
        <v-layout justify-space-around row wrap align-center>
          <v-flex md9 style="padding: 10px" xs12>
            <v-text-field
              v-if="hasAccess('can_schedule_meeting') && editMeetingTitle"
              placeholder="Placeholder"
              outlined
              dense
              v-model="titleTextField"
            ></v-text-field>
            <h2 v-else style="font-size: x-large; font-weight: bold">
              {{ (meetingDetails || {}).meetingTitle }}
            </h2>
            <v-btn
              class="ml-2"
              @click="isOpenEditTitle"
              v-if="!editMeetingTitle && !HasPublishedAgenda"
              icon
              color="info"
              ><v-icon>mdi-pen</v-icon></v-btn
            >
            <v-btn
              class="ml-2"
              @click="editMeetingTitle = false"
              v-if="editMeetingTitle"
              icon
              color="pink"
              ><v-icon>mdi-close</v-icon></v-btn
            >
            <v-btn
              class="ml-2"
              @click="saveNewMeetingTitle('meeting_title')"
              v-if="editMeetingTitle"
              icon
              color="primary"
              ><v-icon>mdi-floppy</v-icon></v-btn
            >
          </v-flex>

          <v-flex md3 style="padding: 10px" xs12>
            <div
              v-if="hasAccess('can_schedule_meeting')"
              class="flex flex-column center"
            >
              <span
                v-if="
                  !($store.state.MeetingsStore.selectedMeetingDetails || {})
                    .meetingMinutesApproved &&
                  ($store.state.MeetingsStore.selectedMeetingDetails || {})
                    .meetingHasPublishedAgenda &&
                  quorumStatus
                "
                >Minutes in Review</span
              >
              <span
                v-if="
                  ($store.state.MeetingsStore.selectedMeetingDetails || {})
                    .meetingMinutesApproved &&
                  ($store.state.MeetingsStore.selectedMeetingDetails || {})
                    .meetingHasPublishedAgenda &&
                  quorumStatus
                "
                >Minutes Waiting for Confirmation</span
              >
              <div class="flex flex-align-items-center mt-3">
                <v-tooltip
                  v-if="listOfagendas.length < 1"
                  bottom
                  color="indigo"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="builderModeDialogy = true" class="mr-1"
                           color="indigo white--text" outlined right
                           rounded
                           small v-bind="attrs"
                           v-on="on">
                      <v-icon>mdi-file-table-outline</v-icon>
                      Build Agenda
                    </v-btn>
                  </template>
                  <span>Build Agenda</span>
                </v-tooltip>
                <v-tooltip
                  v-if="
                    quorumStatus &&
                    (
                      this.$store.state.MeetingsStore.selectedMeetingDetails ||
                      {}
                    ).meetingHasPublishedAgenda
                  "
                  bottom
                  color="blue"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue white--text"
                      fab
                      right
                      small
                      style="margin: 5px"
                      title=""
                      v-bind="attrs"
                      @click="TakeminutesWindow = true"
                      v-on="on"
                    >
                      <v-icon>mdi-script-text</v-icon>
                    </v-btn>
                  </template>
                  <span>Take Meeting Minutes</span>
                </v-tooltip>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>

    <div>
      <v-layout justify-space-around row wrap>
        <v-card class="full-width">
          <v-simple-table>
            <template>
              <thead>
                <tr>
                  <th class="text-left" style="width: 10%"></th>
                  <th class="text-left" style="width: 80%">
                    <span
                      v-if="
                        !quorumStatus &&
                        (
                          this.$store.state.MeetingsStore
                            .selectedMeetingDetails || {}
                        ).meetingHasPublishedAgenda
                      "
                      style="color: #eb5834; font-size: 16px"
                    >
                      You can not initiate the meeting since Quorum is not
                      reached yet. Need More than
                      {{
                        (meetingDetails.meetingBoard || {}).settingBoard
                          .edges[0].node.settingQuorumPercentage
                      }}% of Board Members to Confirm Attendance
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong left>Date:</strong></td>
                  <td
                    v-if="
                      !HasPublishedAgenda && hasAccess('can_schedule_meeting')
                    "
                  >
                    <vue-xeditable
                      v-model="(meetingDetails || {}).meetingDate"
                      type="date"
                    ></vue-xeditable>
                  </td>
                  <td v-else>
                    {{ (meetingDetails || {}).meetingDate | formatDate }}
                  </td>
                </tr>
                <tr>
                  <td><strong left>Time:</strong></td>
                  <td
                    v-if="
                      !HasPublishedAgenda && hasAccess('can_schedule_meeting')
                    "
                  >
                    <span contenteditable="true">
                      {{ (meetingDetails || {}).meetingStartTime }} -</span
                    >
                    <span contenteditable="true">
                      {{ (meetingDetails || {}).meetingEndTime }}</span
                    >
                  </td>
                  <td v-else>
                    <span>
                      {{ (meetingDetails || {}).meetingStartTime }} -</span
                    >
                    <span> {{ (meetingDetails || {}).meetingEndTime }}</span>
                    <br />
                    <strong>Timezone</strong>: {{((meetingDetails || {}).meetingLocation || {}).locationTimezone}}<br/>
                  </td>
                </tr>
                <tr>
                  <td><strong left>Location:</strong></td>
                  <td>
                    <span>
                      {{
                        ((meetingDetails || {}).meetingLocation || {})
                          .locationName
                      }}</span
                    >
                    <br />
                    <span>
                      {{
                        ((meetingDetails || {}).meetingLocation || {})
                          .locationAddress
                      }}<br /></span>
                  </td>
                </tr>
                <tr>
                  <td><strong>Video URL: &nbsp;</strong></td>
                  <td>
                    <div class="flex">
                      <v-btn
                        v-if="hasAccess('can_schedule_meeting')"
                        v-show="!hidden"
                        color="primary"
                        text
                        x-small
                        @click="addVideoMeeting = !addVideoMeeting"
                      >
                        <v-icon>mdi-plus</v-icon>
                        Add Meeting URL
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="text-capitalize mr-1"
                        @click="
                          sendLinktoInvitees(
                            meetingVideoDetails,
                            (meetingVideoDetails||{}).videoCallType
                          )
                        "
                        v-if="(meetingVideoDetails||{}).videoCallType"
                        color="success"
                        outlined
                        rounded
                      >
                        Send Video link to Meeting Invitees
                        <v-icon>mdi-link-variant</v-icon>
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        @click="
                          openVideoCallScreen(
                            (meetingVideoDetails||{}).videoCallParticipantToken,
                            'e-Mikutano'
                          )
                        "
                        v-if="(meetingVideoDetails||{}).videoCallType == 'e-Mikutano'"
                        color="indigo"
                        outlined
                        elevation="0"
                      >
                        Join e-Mikutano Meeting <v-icon>mdi-video</v-icon>
                      </v-btn>
                      <v-btn
                        class="text-capitalize"
                        @click="
                          openVideoCallScreen(
                            (meetingVideoDetails||{}).videoCallModeratorUrl
                          )
                        "
                        v-else-if="(meetingVideoDetails||{}).videoCallType == 'URL'"
                        color="indigo"
                        outlined
                        elevation="0"
                      >
                        Join Meeting <v-icon>mdi-video</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><strong>Meeting Organiser:&nbsp;</strong></td>
                  <td>
                    <span
                      >{{
                        ((meetingDetails || {}).meetingCreatedby || {})
                          .firstName
                      }}
                      {{
                        ((meetingDetails || {}).meetingCreatedby || {}).lastName
                      }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td><strong>Meeting Quorum:&nbsp;</strong></td>
                  <td>
                    <v-layout>
                      <v-flex md5 xs12>
                        <strong style="color: #9c7508"
                          >Need More than {{ getQuorum }}% of Board Members to
                          Confirm Attendance</strong
                        >
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-flex md5 xs12>
                        <v-chip v-if="quorumStatus" color="success" small>
                          <v-icon>mdi-check</v-icon>
                          The quorum is achieved
                        </v-chip>
                        <v-chip v-else close color="error" small
                          >The quorum is not reached
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Attendance: &nbsp;</strong>
                    <span
                      ><small color="orange"
                        >NOTE: You can drag and move people between Attendees or
                        Apologies.</small
                      ></span
                    >
                  </td>
                  <td>
                    <v-row class="py-2">
                      <v-col
                        md="12"
                        xs12
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                      >
                        <v-menu
                          v-if="hasAccess('can_schedule_meeting')"
                          app
                          offset-y
                          style="padding-bottom:2px;"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="unConfirmMembers.length > 0"
                              slot="activator"
                              color="indigo"
                              small
                              rounded
                              style="text-transform: capitalize; border-radius: 5px; padding:2px; border: 1px solid #9da6a68a; border-style: dotted;"
                              text
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon style="padding: 2px;" small>mdi-account-plus-outline</v-icon>
                              Add Members
                            </v-btn>
                            <v-btn
                              v-else
                              color="indigo"
                              small
                              style="text-transform: capitalize"
                              text
                              @click="addPeopleDialog = true"
                            >
                              <v-icon small>mdi-account-plus-outline</v-icon>
                              Add Members
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item
                              v-for="(people, index) in unConfirmMembers"
                              :key="index.id"
                              link
                            >
                              <v-list-item-title @click="addAtendees(people)"
                                >{{ people.userProfileTitle }}.
                                {{ people.firstName }}
                                {{ people.lastName }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>


                      <v-col
                        md="6"
                        xs12
                        @drop="onDrop($event, true)"
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                      >
                      <div style="border-radius: 5px; padding:2px; border: 1px solid #9da6a6b6; border-style: dotted;width: 100%;height: 100%;background-color:rgba(217, 229, 219, 0.54)">
                        <v-subheader color="primary">
                          <strong>Attendees:</strong>
                        </v-subheader>
                        <draggable
                          v-model="atendees"
                          :options="{ group: 'people' }"
                          style="min-height: 10px"
                        >
                          <template v-for="item in meetingAtendees">
                            <v-chip
                              :id="item.id"
                              :key="item.id"
                              :draggable="draggable"
                              class="ma-2"
                              height="31"
                              width="88"
                              @dragstart="startDrag($event, item)"
                              @mouseleave="c_index = -1"
                              @mouseover="c_index = index"
                              @drag.prevent="drag"
                            >
                              {{ item.name }}
                              <v-icon style="margin-left: 10px;"  right
                                >mdi-drag-vertical
                              </v-icon>
                            </v-chip>
                          </template>
                        </draggable>
                        </div>
                      </v-col>

                      <v-col
                        md="6"
                        xs12
                        @drop="onDrop($event, false)"
                        @drop.prevent="drop"
                        @dragover.prevent="allowDrop"
                      >
                      <div style="border-radius: 5px; padding:2px; border: 1px solid #9da6a68a; border-style: dotted;width: 100%;height: 100%;background-color:rgba(229, 221, 217, 0.54)">
                        <v-subheader>
                          <strong>Apologies: </strong>
                        </v-subheader>
                        <draggable style="min-height: 10px;">
                          <template v-for="item in meetingApologies">
                            <v-chip
                              :id="item.id"
                              :key="item.id"
                              :draggable="draggable"
                              class="ma-2"
                              height="31"
                              width="88"
                              @dragstart="startDrag($event, item)"
                              @mouseleave="c_index = -1"
                              @mouseover="c_index = index"
                              @drag.prevent="drag"
                            >
                              {{ item.name }}
                              <v-icon style="margin-left: 10px;" right
                                >mdi-drag-vertical
                              </v-icon>
                            </v-chip>
                          </template>
                        </draggable>

                      </div>
                      </v-col>
                    </v-row>
                  </td>
                </tr>


                <tr>
                  <td><strong>Meeting Management:&nbsp;</strong></td>
                  <td>
                    <div class="row my-2">
                      <div class="col-md-4 bg-slate-400 border-r">
                        <div class="text-gray-600 font-medium">Chair Person</div>
                        <div class="flex justify-content-between">
                          <template v-if="chairPersonPositionViewType === 'VIEW'">
                            <div class="text-primary font-bold py-2">
                              {{ chairPerson.name}}
                            </div>
                            <div class="text-gray-700 py-2 cursor-pointer border rounded-md pl-2 pr-3 edit-btn"
                                 v-if="hasAccess('can_add_management_meeting')"
                                 @click="chairPersonPositionViewType = 'ADD'">
                              <v-icon style="font-size: 15px;">mdi-pencil</v-icon>
                              Edit
                            </div>
                          </template>
                          <v-select v-else
                                    class="mt-2"
                                    :items="meetingAtendees"
                                    :item-text="'name'"
                                    :item-value="'id'"
                                    label="Select Chair Person"
                                    outlined
                                    dense
                                    v-model="chairPositionedUser"
                                    @change="updateMeetingPositions(chairPositionedUser,'Chair Person')"
                          ></v-select>
                        </div>
                      </div>

                      <div class="col-md-4 bg-slate-400 border-r">
                        <div class="text-gray-600 font-medium">Secretary</div>
                        <div class="flex justify-content-between">
                          <template v-if="secretaryPositionViewType === 'VIEW'">
                            <div class="text-primary font-bold py-2">
                              {{ secretary.name}}
                            </div>
                            <div class="text-gray-700 py-2 cursor-pointer border rounded-md pl-2 pr-3 edit-btn"
                                 v-if="hasAccess('can_add_management_meeting')"
                                 @click="secretaryPositionViewType = 'ADD'">
                              <v-icon style="font-size: 15px;">mdi-pencil</v-icon>
                              Edit
                            </div>
                          </template>
                          <v-select v-else
                                    class="mt-2"
                                    :items="meetingAtendees"
                                    :item-text="'name'"
                                    :item-value="'id'"
                                    label="Select Secretary"
                                    outlined
                                    dense
                                    v-model="secretaryPositionedUser"
                                    @change="updateMeetingPositions(secretaryPositionedUser,'Secretary')"
                          ></v-select>
                        </div>
                      </div>

                      <div class="col-md-4 bg-slate-400">
                        <div class="text-gray-600 font-medium">Secretariat</div>
                        <div class="flex justify-content-between">
                          <template v-if="secretariatPositionViewType === 'VIEW'">
                            <div class="text-primary font-bold py-2">
                              {{ secretariat.name}}
                            </div>
                            <div class="text-gray-700 py-2 cursor-pointer border rounded-md pl-2 pr-3 edit-btn"
                                 v-if="hasAccess('can_add_management_meeting')"
                                 @click="secretariatPositionViewType = 'ADD'">
                              <v-icon style="font-size: 15px;">mdi-pencil</v-icon>
                              Edit
                            </div>
                          </template>
                          <v-select v-else
                                    class="mt-2"
                                    :items="meetingAtendees"
                                    :item-text="'name'"
                                    :item-value="'id'"
                                    label="Select Secretariat"
                                    outlined
                                    dense
                                    v-model="secretariatPositionedUser"
                                    @change="updateMeetingPositions(secretariatPositionedUser,'Secretariat')"
                          ></v-select>
                        </div>
                      </div>
                    </div>

                  </td>
                </tr>


                <tr>
                  <td>
                    <strong>Invitees: &nbsp;</strong>
                  </td>
                  <td
                    v-if="
                      !HasPublishedAgenda && hasAccess('can_schedule_meeting')
                    "
                  >
                    <small
                      >Separate invitees names with commas. E.g. John
                      Smith-john.smith@gmail.com, Carol
                      Kiggs-carol.kiggs@gmail.com
                    </small>
                    <div
                      id="guestList"
                      contenteditable="true"
                      style="
                        background-color: #f2f7f7;
                        border: 1px solid #9da6a6;
                        border-radius: 5px;
                        padding: 3px;
                      "
                      @blur="
                        (event) =>
                          onInveteesInput(
                            event,
                            (meetingDetails || {}).meetingUniqueId
                          )
                      "
                    >
                      <span
                        v-for="invetee in (
                          (meetingDetails || {}).inviteeMeeting || {}
                        ).edges"
                        :key="invetee.inviteeUniqueId"
                        >{{ invetee.node.inviteeName }}
                        {{
                          invetee.node.inviteeEmail
                            ? " - " + invetee.node.inviteeEmail
                            : ""
                        }},
                      </span>
                    </div>
                  </td>
                  <td v-else>
                    <span
                      v-for="invetee in (
                        (meetingDetails || {}).inviteeMeeting || {}
                      ).edges"
                      :key="invetee.inviteeUniqueId"
                      >{{ invetee.node.inviteeName }},
                    </span>
                  </td>
                </tr>
                <tr>
                  <td><strong>Notes: &nbsp;</strong></td>
                  <td
                    v-if="
                      !HasPublishedAgenda && hasAccess('can_schedule_meeting')
                    "
                  >
                    <input
                      v-model="meetingNotes"
                      :placeholder="(meetingDetails || {}).meetingNotes"
                      style="width: 100%"
                      @change="takeMeetingNotes"
                    />
                  </td>
                  <td v-else>
                    <span>
                      {{ (meetingDetails || {}).meetingNotes }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-dialog v-model="addVideoMeeting" max-width="800px">
            <v-card>
              <v-card-title class="text-h6 grey lighten-2">
                <v-icon>mdi-plus</v-icon>
                Add Video Meeting
              </v-card-title>
              <v-form class="px-3">
                <v-card-text>
                  <v-checkbox
                    v-model="useMikutano"
                    :label="`Use e-Mikutano Instead`"
                  ></v-checkbox>
                </v-card-text>
                <v-card-text v-if="useMikutano">
                  <v-text-field
                    v-model="mikutanoToken"
                    autofocus
                    :counter="10"
                    maxlength="10"
                    label="e-Mikutano Participants Token"
                    prepend-icon="mdi-video-wireless-outline"
                  ></v-text-field>
                </v-card-text>
                <v-card-text v-else>
                  <v-text-field
                    v-model="videoURL"
                    autofocus
                    label="Meeting URL"
                    prepend-icon="mdi-link-plus"
                  ></v-text-field>
                </v-card-text>
              </v-form>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="pink"
                  fab
                  small
                  @click="addVideoMeeting = !addVideoMeeting"
                >
                  <v-icon class="white--text">mdi-close</v-icon>
                </v-btn>

                <v-btn
                  @click="saveVideoMeetingDetails"
                  color="primary"
                  fab
                  small
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-layout>
    </div>

    <NewAgendaBuilder
      :AgendaBuilderWindow="AgendaBuilderWindow"
      :agendaMode="bestPractise"
      :currentMeeting="currentMeeting"
      :listOfagendas="listOfagendas"
      @close="AgendaBuilderWindow = !AgendaBuilderWindow"
    />

    <NewMinutes
      :TakeminutesWindow="TakeminutesWindow"
      :agendaMode="bestPractise"
      :currentMeeting="currentMeeting"
      :listOfagendas="listOfagendas"
      @reload="loadMeetingDetails"
      @close="TakeminutesWindow = !TakeminutesWindow"
    />

    <v-dialog v-model="Agendadialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading . . .
          <v-progress-linear
            class="mb-0"
            color="white"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="addPeopleDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <v-icon>mdi-creation</v-icon>
          <span>Everyone is here</span>
        </v-card-title>
        <v-card-text>
          <span
            >Everyone on the “People List” page is already listed in Meeting
            Attendees/Apologies detail.</span
          >
          <v-radio-group v-model="ListP" row>
            <v-col cols="12" md="12" sm="12">
              <v-btn block color="indigo" outlined @click="focusOnGuestList">
                <v-icon>mdi-account-plus</v-icon>
                Go Back and Add Guest
              </v-btn>
            </v-col>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DocumentViwer
      v-if="OpenfileDialog == true"
      :OpenfileDialog="OpenfileDialog"
      :docValue="docValue"
      :title="title"
      @CloseDocumentViwer="OpenfileDialog = false"
    />

    <AddNewUser
      :OpenNewUserForm="OpenNewUserForm"
      @close="OpenNewUserForm = !OpenNewUserForm"
    />

    <AgendaBuildingMode
      :builderModeDialogy="builderModeDialogy"
      :currentMeeting="currentMeeting"
      @closeBuilderModeDialogy="builderModeDialogy = !builderModeDialogy"
    />

    <PostponeMeeting
      :currentMeetingDetails="currentMeetingDetails"
      :isPostponeMeeting="isPostponeMeeting"
      :meetingUniqueId="meetingUniqueId"
      @close="isPostponeMeeting = false"
      @fetchingMeetingDetails="fetchingMeetingDetails"
    />

    <AgendaActiveInterestForm
      :agendaItems="listOfagendas"
      :meetingUniqueId="meetingUniqueId"
      :openInterestRegisterWindow="openInterestRegisterWindow"
      @closeNewInterestFormDialog="openInterestRegisterWindow = false"
    />

    <MeetingAgendaActiveInterestList
      :agendaItems="listOfagendas"
      :meetingUniqueId="meetingUniqueId"
      :openMeetingInterestsWindow="openMeetingInterestsWindow"
      @closeNewInterestFormDialog="openMeetingInterestsWindow = false"
    />
  </v-container>
</template>

<script>
import PostponeMeeting from "./PostponeMeeting.vue";
import AddNewUser from "../eboard-boards/AddNewUser.vue";
import { XEditable } from "@onekiloparsec/vue-xeditable";
import { VueDraggableDirective } from "vue-draggable";
import AgendaBuildingMode from "../eboard-agendas/AgendaBuildingMode.vue";
import NewAgendaBuilder from "../eboard-agendas/NewAgendaBuilder.vue";
import NewMinutes from "../eboard-minutes/NewMinutes.vue";
import MeetingNotice from "../e_board_notifications/MeetingNotice.vue";
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";
import AgendaActiveInterestForm from "../eboard-interest/AgendaActiveInterestForm.vue";
import MeetingAgendaActiveInterestList from "../eboard-interest/MeetingAgendaActiveInterestList.vue";
import { mapGetters } from "vuex";
import ApollogyReplies from "./ApollogyReplies.vue";
import swal from "sweetalert2";

export default {
  directives: {
    dragAndDrop: VueDraggableDirective,
  },

  components: {
    "vue-xeditable": XEditable,
    AddNewUser,
    NewAgendaBuilder,
    NewMinutes,
    MeetingNotice,
    DocumentViwer,
    AgendaBuildingMode,
    PostponeMeeting,
    AgendaActiveInterestForm,
    MeetingAgendaActiveInterestList,
    ApollogyReplies,
  },

  data() {
    return {
      mikutanoToken: "",
      videoURL: "",
      useMikutano: false,
      titleTextField: "",
      editMeetingTitle: false,
      OpenRepliesDialog: false,
      openMeetingInterestsWindow: false,
      openInterestRegisterWindow: false,
      meetingNotes: "",
      isPostponeMeeting: false,
      builderModeDialogy: false,
      ListP: "",
      draggable: true,
      newAgendaKey: 77,
      bestPractise: null,
      index: null,
      details: null,
      value: null,
      OpenNewUserForm: false,
      addPeopleDialog: false,
      docValue: "",
      OpenfileDialog: false,
      Agendadialog: false,
      meetingData: "",
      chairPersonPositionViewType: 'VIEW',
      secretaryPositionViewType: 'VIEW',
      secretariatPositionViewType: 'VIEW',
      title: "",
      OpenNoticeDialog: false,
      TakeminutesWindow: false,
      hover: true,
      minutesDialog: false,
      AgendaBuilderWindow: false,
      hidden: false,
      disabled: true,
      progressShown: false,
      c_index: -1,
      primary: "",
      dialog2: false,
      addVideoMeeting: false,
      eboard_schedules: "/eboard_schedules/",
      meetingUniqueId: "",
      value1: "value1",
      atendees: [],
      people_list: [{ id: 1 }],
      switchValue: localStorage.getItem("dragable"),
      currentMeetingDetails: {},
    };
  },

  methods: {
    async loadMeetingDetails() {
      await this.$store.dispatch("fetchingMeetingDetails", this.meetingUniqueId);
    },
    async updateMeetingPositions(user,position){
      await this.$store.dispatch("updateBoardAttendeesMeetingPosition", {
        user: user,
        position: position,
      });
      switch (position) {
        case 'Chair Person':
          this.chairPersonPositionViewType = 'VIEW'
          break
        case 'Secretary':
          this.secretaryPositionViewType = 'VIEW'
          break
        case 'Secretariat':
          this.secretariatPositionViewType = 'VIEW'
          break
      }
      await this.$store.dispatch("fetchingMeetingAttendees", this.meetingUniqueId);
    },
    async sendLinktoInvitees(meetingVideoDetails, callType = "URL") {
      var meeting_ULR = "";
      if (callType != "URL") {
        meeting_ULR =
          "https://mikutano.gov.go.tz/" +
          meetingVideoDetails?.videoCallParticipantToken;
      } else {
        meeting_ULR = meetingVideoDetails?.videoCallModeratorUrl;
      }

      this.$store.dispatch("shareVideoLinktoInvitees", {
        meetingUniqueId: this.currentMeeting,
        videoUrl: meeting_ULR,
      });
    },

    async openVideoCallScreen(meetingToken, callType = "URL") {
      let height = window.outerHeight;
      var actualSize = height * (100 / 100);
      if (callType != "URL") {
        window.open(
          "https://mikutano.gov.go.tz/" + meetingToken,
          "e-Board Call",
          "top=200,bottom=300,left=500,width=" + actualSize + ",height=400"
        );
      } else {
        window.open(
          meetingToken,
          "e-Board Call",
          "top=200,bottom=300,left=500,width=" + actualSize + ",height=400"
        );
      }
    },
    async saveVideoMeetingDetails() {
      var videoCallType = "URL";
      if (this.useMikutano) {
        videoCallType = "e-Mikutano";
      }
      this.$store.dispatch("saveVideoMeetingDetails", {
        meetingUniqueId: this.currentMeeting,
        videoCallModeratorUrl: this.videoURL,
        videoCallParticipantToken: this.mikutanoToken,
        videoCallType: videoCallType,
      });

      this.useMikutano = false;
      this.videoURL = "";
      this.mikutanoToken = "";
      this.addVideoMeeting = false;

      swal.fire({
        toast: true,
        icon: "success",
        title: "Video Meeting details successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    async takeMeetingNotes() {
      await this.$store.dispatch("takeMeetingNotes", {
        meetingNotes: this.meetingNotes,
        meetingUniqueId: this.meetingUniqueId,
      });

      await this.$store.dispatch(
        "fetchingMeetingDetails",
        this.meetingUniqueId
      );
      await this.$store.dispatch("setCurrentMeeting", this.meetingUniqueId);
      await this.$store.dispatch("loadUnConfirmMeetings");
      await this.$store.dispatch(
        "fetchingMeetingAttendees",
        this.meetingUniqueId
      );
      await this.$store.dispatch(
        "loadBestPracticeAgendaTemplates",
        this.currentMeeting
      );
      await this.$store.dispatch("loadBoardMambers");
      await this.$store.dispatch("previewAgendas", this.meetingUniqueId);
      await this.$store.dispatch("loadBoardMeetingPacks", this.meetingUniqueId);
    },

    postPoneMeeting() {
      this.isPostponeMeeting = true;
      this.currentMeetingDetails =
        this.$store.state.MeetingsStore.selectedMeetingDetails || {};
    },

    async deleteBoardMeeting() {
      await swal
        .fire({
          icon: "warning",
          title: "Are you sure?",
          text: "You want to delete this meeting ?",
          animation: false,
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
        })
        .then((result) => {
          if (result.isConfirmed)  {
            this.$store.dispatch(
              "deleteBoardMeeting",
              this.$route.params.meeting_uuid
            );
            this.$router.push(
              "/board_account/" +
                localStorage.getItem("my-boardUniqueId") +
                "/My-Board"
            );
          }
        });
    },

    openPack() {
      this.$store.dispatch(
        "setSelectedPack",
        this.$store.state.BoardPacksStore.meetingBoardPack.boardPackFullPath
      );
      this.$router.push("/eboard_open_packs");
    },

    async onInveteesInput(event, meetingUniqueId) {
      await this.$store.dispatch("updateMeetingInvitees", {
        meetingUniqueId: meetingUniqueId,
        invitees: event.target.innerText,
      });
    },

    previewAgendas() {
      this.docValue = this.$store.state.AgendasStore.combinedAgendas;
      this.OpenfileDialog = true;
    },

    async previewMeetingMinutesMinutes() {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
        "previewMeetingMinutesMinutes",
        this.meetingUniqueId
      );
      this.docValue =
        "/" + this.$store.state.MinutesStore.priviewMinutes.attachmentPath;
      this.title = this.$store.state.MinutesStore.priviewMinutes.attachmentName;
      await this.$store.dispatch("showLoadingSpinner", false);
      this.OpenfileDialog = true;
    },

    previewBoardPack() {
      this.docValue =
        this.$store.state.BoardPacksStore.meetingBoardPack.boardPackFullPath;
      this.OpenfileDialog = true;
    },

    isOpenEditTitle() {
      this.editMeetingTitle = true;
      this.titleTextField = (this.meetingDetails || {}).meetingTitle;
    },

    async saveNewMeetingTitle(editedPart) {
      let Meeting = {
        meetingUniqueId: this.meetingUniqueId,
        updateType: editedPart,
        updateValue: this.titleTextField,
      };
      this.editMeetingTitle = false;
      this.titleTextField = this.currentMeeting.meetingTitle;
      await this.$store.dispatch("updateMeetingDetails", Meeting);
      await this.$store.dispatch(
        "fetchingMeetingDetails",
        this.meetingUniqueId
      );
      swal.fire({
        toast: true,
        icon: "success",
        title: "Meeting title edited successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
      localStorage.setItem("itemID", item.id);
    },

    async onDrop(evt, side) {
      const data = localStorage.getItem("itemID");
      await this.$store.dispatch("switchingMeetingAttendees", {
        data: data,
        side: side,
      });
      await this.$store.dispatch(
        "loadBoardMemberswithApologies",
        this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },

    allowDrop: (ev) => {
      ev.preventDefault();
    },

    drag: (ev) => {
      localStorage.setItem("dragable", ev.target.id);
    },

    drop: (ev) => {
      ev.preventDefault();
      var data = localStorage.getItem("dragable");
      ev.target.appendChild(document.getElementById(data));
    },

    async addAtendees(newateendee) {
      const attendee = {
        attendanceMeeting: (this.meetingDetails || {}).primaryKey,
        attendanceUser: newateendee.id,
      };
      await this.$store.dispatch("addMeetingAttandees", attendee);
    },

    focusOnGuestList() {
      this.addPeopleDialog = false;
      const div = document.getElementById("guestList");
      document.querySelector("#guestList").focus();
      div.focus();
    },

    async fetchingMeetingDetails() {
      await this.$store.dispatch(
        "fetchingMeetingDetails",
        this.meetingUniqueId
      );
    },

    async loadMeetingConflictsOfInterest() {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
        "loadMeetingConflictsOfInterest",
        this.meetingUniqueId
      );
      await this.$store.dispatch("showLoadingSpinner", false);
      this.openMeetingInterestsWindow = true;
    },
  },

  watch: {
    switchValue(data) {
      this.$store.dispatch("switchingMeetingAttendees", data);
    },

    dialog(val) {
      if (!val) return;

      setTimeout(() => ((this.dialog = false), (this.dialog2 = true)), 1000);
    },

    Agendadialog(val) {
      if (!val) return;
      setTimeout(
        () => ((this.Agendadialog = false), this.openAgendaBuilderWindow()),
        2000
      );
    },

    // currentMeeting: function () {
    //   this.$store.dispatch(
    //       "loadBestPracticeAgendaTemplates",
    //       this.currentMeeting
    //   );
    // },

    listOfagendas() {
      if (
        this.listOfagendas.length > 0 &&
        !(this.$store.state.MeetingsStore.selectedMeetingDetails || {})
          .meetingHasPublishedAgenda
      ) {
        this.AgendaBuilderWindow = true;
      }
    },
  },

  computed: {
    isForCommiteeMeeting() {
      if (
        this.$store.state.MeetingsStore.selectedMeetingDetails
          .meetingCommitteeMeeting.edges.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    HasPublishedAgenda() {
      return (this.$store.state.MeetingsStore.selectedMeetingDetails || {}).meetingHasPublishedAgenda;
    },

    currentMeeting() {
      return this.$store.state.CurrentMeetingStore.currentMeeting;
    },
    getQuorum() {
      var meeting_quorum = 0;
      try {
        meeting_quorum =
          this.meetingDetails.meetingBoard.settingBoard.edges[0].node
            .settingQuorumPercentage;
      } catch (error) {
        console.log("Error in finding quorum==1");
      }
      return meeting_quorum;
    },
    quorumStatus() {
      var minlevel = this.getQuorum;
      var quorum = 0;
      try {
        quorum = (this.meetingAtendees.length / this.boardMembers.length) * 100;
      } catch (error) {
        console.log("Error in finding quorum==2");
      }

      if (quorum >= minlevel) {
        return true;
      }

      return false;
    },

    unConfirmMembers() {
      let usersList = [];
      const combinedAttendence = this.meetingAtendees.concat(
        this.meetingApologies
      );

      this.boardMembers.forEach((member) => {
        usersList.push({
          id: member.id,
          userProfileTitle: member.userProfileTitle,
          firstName: member.firstName,
          lastName: member.lastName,
          userId: member.username,
        });
      });

      combinedAttendence.forEach((attendee) => {
        const filteredPeople = usersList.filter(
          (member) => member.userId !== attendee.userId
        );
        usersList = filteredPeople;
      });
      return usersList;
    },

    ...mapGetters({
      meetingDetails: "getMeetingDetails",
      meetingAtendees: "getMeetingAttendees",
      meetingApologies: "getMeetingApologiesAttendees",
      listOfagendas: "getMeetingAgendas",
      boardMembers: "getBoardMembers",
      meetingNoHasPack: "meetingNoHasPack",
      meetingVideoDetails: "getMeetingVideoDetails",
    }),

    meetingTitle: {
      get() {
        return (this.meetingDetails || {}).meetingTitle;
      },
      set(newTitle) {
        return newTitle;
      },
    },
    chairPerson() {
      let data = this.meetingAtendees?.find(a => a.attendancePosition === 'Chair Person')
      return data ? data : { name: "-" }
    },
    secretary() {
      let data = this.meetingAtendees?.find(a => a.attendancePosition === 'Secretary')
      return data ? data : { name: "-" }
    },
    secretariat() {
      let data = this.meetingAtendees?.find(a => a.attendancePosition === 'Secretariat')
      return data ? data : { name: "-" }
    }
  },

  async mounted() {
    // await this.$store.dispatch("showLoadingSpinner", true);
    this.meetingUniqueId = this.$route.params.meeting_uuid;
    await this.$store.dispatch("fetchingMeetingDetails", this.meetingUniqueId);
    await this.$store.dispatch("setCurrentMeeting", this.meetingUniqueId);
    await this.$store.dispatch("loadUnConfirmMeetings");
    await this.$store.dispatch(
      "loadMeetingConflictsOfInterest",
      this.meetingUniqueId
    );
    await this.$store.dispatch(
      "fetchingMeetingAttendees",
      this.meetingUniqueId
    );
    await this.$store.dispatch(
      "loadBestPracticeAgendaTemplates",
      this.currentMeeting
    );
    await this.$store.dispatch("loadBoardMambers");
    // await this.$store.dispatch("showLoadingSpinner", false);
    await this.$store.dispatch("previewAgendas", this.meetingUniqueId);
    await this.$store.dispatch("loadBoardMeetingPacks", this.meetingUniqueId);
    await this.$store.dispatch("getVideoMeetingDetails", this.meetingUniqueId);
  },
};
</script>

<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>

<style>

.edit-btn:hover {
  background-color: #f6f6f6;
}

.node {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 40px;
}

.events-banner {
  padding: 5px;
  font-size: small;
  display: block;
}

.events-banner .prefix {
  color: #858585;
}

.events-banner .content {
  font-weight: bold;
  color: #273080;
}

.events-banner .value {
  color: #3a5831;
  font-size: larger;
}

.events-banner-event {
  background-color: #f9f9f9;
  margin: 2px;
  padding: 10px;
  display: inline-block;
  clear: left;
  width: auto;
}

.vue-xeditable-value {
  cursor: pointer;
  color: #17c;
  border-bottom: 1px dashed #07c;
  line-height: 22px;
}

h1 {
  margin: 0;
  text-align: center;
  color: #42b983;
}

h4 {
  margin-bottom: 0;
  color: #404040;
}

a {
  color: #07c;
}

pre code {
  margin: 0;
  padding: 0;
  white-space: pre;
  border: none;
  background: transparent;
}

.highlight pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre {
  background-color: #f8f8f8;
  border: 1px solid #cccccc;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

pre code,
pre tt {
  background-color: transparent;
  border: none;
}
</style>

<style>
#div1,
#div2 {
  float: left;
  width: 100px;
  height: 35px;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}
</style>
