var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"text-h6 grey lighten-2"},[_vm._v(" Vote Results for "+_vm._s(_vm.motion)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.show_resolution_viwers ? 'pink' : 'info'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","fab":"","color":_vm.show_resolution_viwers ? 'pink white--text' : 'blue white--text'},on:{"click":function($event){_vm.show_resolution_viwers = !_vm.show_resolution_viwers}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(
              _vm.show_resolution_viwers ? 'mdi-close' : 'mdi-account-eye-outline'
            )}})],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(
          _vm.show_resolution_viwers ? 'Close' : 'People Who See Resolution'
        )}})])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.show_resolution_viwers ? '4' : '6'}},[_c('v-list',{attrs:{"subheader":"","two-line":""}},[_c('v-divider',{attrs:{"inset":""}}),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"info",attrs:{"dark":""}},[_vm._v("mdi-account-group ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Total Voters")]),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.voteData.voteVoters)}})],1),_c('v-list-item-action')],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"success",attrs:{"dark":""}},[_vm._v("mdi-thumb-up ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Supporters")]),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.voteData.votersSupport)}})],1),_c('v-list-item-action')],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"pink",attrs:{"dark":""}},[_vm._v("mdi-thumb-down ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Opposers")]),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.voteData.votersOppose)}})],1),_c('v-list-item-action')],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"info",attrs:{"dark":""}},[_vm._v("mdi-minus-thick ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Abstainers")]),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.voteData.votersAbstain)}})],1),_c('v-list-item-action')],1)],1),_c('v-btn',{staticClass:"mb-2",attrs:{"small":"","color":!_vm.show_votter_summary ? 'success' : 'pink',"block":"","outlined":"","plain":""},domProps:{"textContent":_vm._s(
              !_vm.show_votter_summary
                ? 'View Votters Responses'
                : 'Hide Votters Responses'
            )},on:{"click":function($event){_vm.show_votter_summary = !_vm.show_votter_summary}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-ballot-outline")])],1),(_vm.show_votter_summary)?_c('v-list',{attrs:{"dense":"","color":"rgba(161, 173, 230, 0.123)"}},[_c('v-subheader',[_c('h2',{staticClass:"intro-y text-lg font-medium mr-auto mt-2"},[_vm._v(" Voted Members ")]),_c('v-spacer'),_c('span',{staticClass:"text-lg"},[_vm._v("Acceptance: "+_vm._s(_vm.acceptance)+"%")])],1),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.resolutionsVotingDetails.votedVoters),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.voterName)}})],1),_c('v-list-item-icon',[_vm._v(" "+_vm._s(item.voterSelection)+" ")]),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.voterSelection == 'Supported'
                        ? 'success'
                        : item.voterSelection == 'Opposed'
                        ? 'pink'
                        : 'info'},domProps:{"textContent":_vm._s(
                      item.voterSelection == 'Supported'
                        ? 'mdi-thumb-up'
                        : item.voterSelection == 'Opposed'
                        ? 'mdi-thumb-down'
                        : 'mdi-minus-thick'
                    )}})],1)],1)}),1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":_vm.show_resolution_viwers ? '4' : '6'}},[_c('v-list',{attrs:{"dense":"","color":"rgba(161, 173, 230, 0.123)"}},[_c('v-subheader',[_vm._v("Un-Voted Members")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.resolutionsVotingDetails.unvotedVoters),function(item,i){return _c('v-list-item',{key:i,attrs:{"disabled":""}},[_c('v-list-item-icon',[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.voterName)}})],1)],1)}),1)],1)],1),(_vm.show_resolution_viwers)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-list',{attrs:{"dense":"","color":"rgba(161, 173, 230, 0.123)"}},[_c('v-subheader',[_vm._v("People Viewed this Resolution")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.resolutionsVotingDetails.resolutionViwers),function(item,i){return _c('v-list-item',{key:i,attrs:{"disabled":""}},[_c('v-list-item-icon',[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.viwerName)}})],1)],1)}),1)],1)],1):_vm._e()],1)],1)],1),_c('v-card-text',[(_vm.resolution.resolutionIsActive)?_c('v-select',{attrs:{"items":[
        { name: 'Approved', value: true },
        { name: 'Not Approved', value: false } ],"item-text":"name","item-value":"value","label":"Outcome","prepend-icon":"mdi-star","required":""},model:{value:(_vm.decisionOutcome),callback:function ($$v) {_vm.decisionOutcome=$$v},expression:"decisionOutcome"}}):_vm._e(),(_vm.resolution.resolutionIsActive)?_c('v-text-field',{attrs:{"counter":100,"rules":_vm.nameRules,"label":"Descriptions *","prepend-icon":"mdi-text-long","required":""},model:{value:(_vm.outcomeDescription),callback:function ($$v) {_vm.outcomeDescription=$$v},expression:"outcomeDescription"}}):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","text":""},on:{"click":_vm.closeVoteDialog}},[_vm._v(" Close ")]),(_vm.resolution.resolutionIsActive)?_c('v-btn',{attrs:{"color":"primary","outlined":"","text":""},on:{"click":_vm.circularResolutionVoteResult}},[_vm._v(" Save OutCome ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }