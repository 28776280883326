<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col class="text-lg font-weight-medium"> List of administrators</v-col>
      <v-spacer></v-spacer>
      <!-- Create System administrator -->
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="newSystemAdministratorDialog=true"
              v-bind="attrs"
              v-on="on"
              right
              small
              outlined
              color="primary white--text"
              style="margin: 5px"
          >
            <v-icon>mdi-account-lock-open-outline</v-icon>
            Create System Administrator
          </v-btn>
        </template>
        <span>Create System Administrator</span>
      </v-tooltip>
      <!--   Create System administrator role   -->
      <v-tooltip bottom color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="newSystemAdministratorRoleDialog=true"
              v-bind="attrs"
              v-on="on"
              right
              small
              outlined
              color="success white--text"
              style="margin: 5px"
          >
            <v-icon>mdi-account-plus</v-icon>
            Create System Admin Roles
          </v-btn>
        </template>
        <span>Create System Admin Roles</span>
      </v-tooltip>

    </v-row>
    <v-row>
      <v-col sm="12 intro-x">
        <v-card class="shadow-sm">
          <v-tabs v-model="tab" color="#3f51b5" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon :color="tab.color" class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- System Administrator -->
            <v-tab-item style="background: rgba(39, 24, 245, 0.04)" class="rounded-md mb-15">
              <v-row>
                <div class=" mt-3 w-full">
                  <table class="p-3 w-100 table table-hover bg-white">
                    <thead>
                    <tr class="bg-primary text-white">
                      <th scope="col">No.</th>
                      <th scope="col">Username/Email</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">System Admin Role</th>
                      <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(admin, index) in getSystemAdminList" :key="admin.id">
                      <td>{{index+1}}</td>
                      <td>{{admin.userName}}</td>
                      <td>{{ admin.firstName }}</td>
                      <td>{{ admin.lastName }}</td>
                      <td>{{ (admin.adminRole||{}).roleName}}</td>

                      <td>
                        <v-tooltip bottom color="warning">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" @click="editSystemAdmin(admin)" class="btn btn-warning btn-sm mr-2" type="button">
                              <icon name="edit" width="w-5" height="w-5" color="#ffffff"></icon>
                            </div>
                          </template>
                          <span>Edit Admin</span>
                        </v-tooltip>
                        <v-tooltip bottom color="red">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" @click="deleteSystemAdmin(admin.userProfileUniqueId)" class="btn btn-danger btn-sm" type="button">
                              <icon name="delete" width="w-5" height="w-5" color="#ffffff"></icon>
                            </div>
                          </template>
                          <span>Delete Admin</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </v-row>
            </v-tab-item>
            <!-- System Administrator Roles -->
            <v-tab-item style="background: rgba(39, 24, 245, 0.04)" class="rounded-md mb-15">
              <v-row>

                <div class="mt-3 w-full">
                  <table class="p-3 w-100 table table-hover bg-white">
                    <thead>
                    <tr class="bg-primary text-white">
                      <th scope="col">No.</th>
                      <th scope="col">Role Name</th>
                      <th scope="col">Role Description</th>
                      <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(role, index) in getSystemAdminRoleList" :key="role.id">
                      <td>{{index+1}}</td>
                      <td>{{role.roleName}}</td>
                      <td>{{ role.roleDescription }}</td>
                      <td>
                        <v-tooltip bottom color="primary">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" @click="viewAdminRole(role)" class="btn btn-primary btn-sm mr-2" type="button">
                              <icon name="view" width="w-5" height="w-5" color="#ffffff"></icon>
                            </div>
                          </template>
                          <span>View Role</span>
                        </v-tooltip>
                        <v-tooltip bottom color="warning">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" @click="editSystemAdminRole(role)" class="btn btn-warning btn-sm mr-2" type="button">
                              <icon name="edit" width="w-5" height="w-5" color="#ffffff"></icon>
                            </div>
                          </template>
                          <span>Edit Role</span>
                        </v-tooltip>
                        <v-tooltip bottom color="red">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs" @click="deleteSystemAdminRole(role.roleUniqueId)" class="btn btn-danger btn-sm" type="button">
                              <icon name="delete" width="w-5" height="w-5" color="#ffffff"></icon>
                            </div>
                          </template>
                          <span>Delete Role</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        </v-col>
    </v-row>

    <add-new-system-administrator @close-dialog="newSystemAdministratorDialog=false" :new-system-administrator-dialog="newSystemAdministratorDialog"></add-new-system-administrator>
    <add-new-system-administrator-role @close-role-dialog="newSystemAdministratorRoleDialog=false" :new-system-administrator-role-dialog="newSystemAdministratorRoleDialog"></add-new-system-administrator-role>
    <edit-system-administrator-role
      @close-dialog="editSystemAdminRoleDialog=false"
      :edit-system-admin-role-dialog="editSystemAdminRoleDialog"
      :role-unique-id="roleUniqueId"
      :role-name="roleName"
      :role-description="roleDescription"
      :role-permissions="rolePermissions"
      :permissionsList="permissionsList"
    >
    </edit-system-administrator-role>

    <edit-system-administrator
      @close-dialog="editSystemAdministratorDialog=false"
      :edit-system-administrator-dialog="editSystemAdministratorDialog"
      :user-profile-unique-id="userProfileUniqueId"
      :role-unique-id="roleUniqueId"
      :role-name="roleName"
      :first-name="firstName"
      :last-name="lastName"
      :email="email"
    >

    </edit-system-administrator>

    <view-system-administrator-role
      @close-dialog="viewSystemAdminRoleDialog=false"
      :view-system-admin-role-dialog="viewSystemAdminRoleDialog"
      :role-unique-id="roleUniqueId"
      :role-name="roleName"
      :role-description="roleDescription"
      :role-permissions="rolePermissions"
    >

    </view-system-administrator-role>
  </v-container>
</template>

<script>
import AddNewSystemAdministrator
  from "@/modules/administration-panel/admin-panel-administrator/AddNewSystemAdministrator.vue";
import Icon from "@/views/Icon.vue";
import AddNewSystemAdministratorRole
  from "@/modules/administration-panel/admin-panel-administrator/AddNewSystemAdministratorRole.vue";
import EditSystemAdministratorRole
  from "@/modules/administration-panel/admin-panel-administrator/EditSystemAdministratorRole.vue";
import EditSystemAdministrator
  from "@/modules/administration-panel/admin-panel-administrator/EditSystemAdministrator.vue";
import ViewSystemAdministratorRole
  from "@/modules/administration-panel/admin-panel-administrator/ViewSystemAdministratorRole.vue";
import {confirmAlert} from "@/services/notificationService";

export default {
  name: "AdminPanelDashboard",
  components: {
    AddNewSystemAdministrator,
    AddNewSystemAdministratorRole,

    EditSystemAdministratorRole,
    EditSystemAdministrator,

    ViewSystemAdministratorRole,
    Icon
  },

  data(){
    return {
      roleUniqueId: "",
      roleName: "",
      roleDescription: "",
      rolePermissions: [],

      userProfileUniqueId: "",
      userName: "",
      firstName: "",
      lastName: "",
      email: "",
      userProfilePicture: "",

      tab: true,
      newSystemAdministratorDialog: false,
      newSystemAdministratorRoleDialog: false,
      editSystemAdminRoleDialog: false,
      editSystemAdministratorDialog: false,
      viewSystemAdminRoleDialog: false,

      tabs: [
        {title: "System Administrators", icon:"mdi-account-lock-outline", color: "orange" },
        {title: "System Admin Roles", icon:"mdi-account-key-outline", color: "success" },
      ],

      permissionsList: []

    }
  },

  computed: {
    getSystemAdminList (){
      return this.$store.getters.getSystemAdminList;
    },

    getSystemAdminRoleList (){
      return this.$store.getters.getSystemAdminRoleList
    },

    mediaUrl() {
      let _mediaUrl = process.env.VUE_APP_MEDIA_SERVER;
      return `${_mediaUrl}`;
    }
  },

  async mounted(){
    await this.$store.dispatch('loadSystemAdmins',)
    await this.$store.dispatch('loadSystemAdminRoles',)
  },

  methods: {
    changeAdminPermission(permission_id) {
      this.rolePermissions.forEach(permission => {
        if (permission.permission_id === permission_id) {
          permission.checked = !permission.checked
        }
      })
    },

    changeRemainedAdminPermission(permission_id) {
      this.rolePermissions.forEach(permission => {
        if (permission.permission_id === permission_id) {
          permission.checked = !permission.checked
        }
      });
    },

    async deleteSystemAdmin(userProfileUniqueId){
      confirmAlert("Are you sure you want to delete system administrator?", "Delete").then( async (data) => {
        if (data.isConfirmed) {
          await this.$store.dispatch('deleteSystemAdmin',userProfileUniqueId)
          await this.$store.dispatch('loadSystemAdmins')
        }
      })
    },

    async deleteSystemAdminRole(roleUniqueId){
      confirmAlert("Are you sure you want to delete system admin role?", "Delete").then( async (data) => {
        if (data.isConfirmed) {
          await this.$store.dispatch('deleteSystemAdminRole',roleUniqueId)
          await this.$store.dispatch('loadSystemAdminRoles')
        }
      })
    },

    async viewAdminRole(adminRole){
      this.roleUniqueId = adminRole.roleUniqueId
      this.roleName = adminRole.roleName
      this.roleDescription = adminRole.roleDescription
      this.rolePermissions = adminRole.rolePermissions

      this.viewSystemAdminRoleDialog=true
    },

    async editSystemAdminRole(adminRole){
      this.roleUniqueId = adminRole.roleUniqueId
      this.roleName = adminRole.roleName
      this.roleDescription = adminRole.roleDescription
      this.rolePermissions = adminRole.rolePermissions

      this.editSystemAdminRoleDialog=true
    },

    async editSystemAdmin(admin){
      this.userProfileUniqueId = admin.userProfileUniqueId
      this.roleUniqueId = admin.roleUniqueId
      this.firstName = admin.firstName
      this.lastName = admin.lastName
      this.email = admin.email

      this.editSystemAdministratorDialog=true
    },


  }




}
</script>

<style scoped>

</style>