var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-system-bar',{staticClass:"mt-7 mb-7",attrs:{"window":"","height":"70"}},[_c('v-icon',[_vm._v("mdi-magnify")]),_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v("Filters")]),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"startmenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},on:{"change":function($event){_vm.end_date = ''}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startmenu),callback:function ($$v) {_vm.startmenu=$$v},expression:"startmenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startmenu = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"endmenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endmenu),callback:function ($$v) {_vm.endmenu=$$v},expression:"endmenu"}},[_c('v-date-picker',{attrs:{"min":_vm.start_date,"no-title":"","scrollable":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endmenu = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","items":_vm.logTypes,"label":"Logs Type"},model:{value:(_vm.logs_type),callback:function ($$v) {_vm.logs_type=$$v},expression:"logs_type"}})],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.filterLogs()}}},[_vm._v(" Search "),_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.auditTrails},scopedSlots:_vm._u([{key:"item.actionTaken",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.getChipColor(item.actionTaken)}},[_vm._v(" "+_vm._s(item.actionTaken)+" ")])]}},{key:"item.actionTimestap",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatFull")(item.actionTimestap)))])]}},{key:"item.more",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"title":"View More Logs","icon":"","fab":"","x-small":"","outlined":""},on:{"click":function($event){return _vm.viewMoreLogs(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"60%"},model:{value:(_vm.showMoreDialog),callback:function ($$v) {_vm.showMoreDialog=$$v},expression:"showMoreDialog"}},[_c('v-card',[_c('v-system-bar',{attrs:{"height":"40"}},[_c('v-icon',[_vm._v("mdi-eye")]),_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v("View In Depth Logs")]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"pink"},on:{"click":function($event){_vm.showMoreDialog = false}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"overflow-x-auto mt-7"},[_c('table',{staticClass:"table table-bordered table-hover"},[_c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("#")]),_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("Affected Field")]),_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("Change From")]),_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("To")])])]),_c('tbody',_vm._l((_vm.changeLogs),function(change,index){return _c('tr',{key:change.changedField + index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(change.changedField))]),_c('td',[_vm._v(_vm._s(change.changeFrom))]),_c('td',[_vm._v(_vm._s(change.changeTo))])])}),0)])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }