<template>
  <v-container>
    <v-dialog v-model="openAction" persistent width="80%">
      <v-card>
        <v-card-title class="ma-3">
          {{ directive.directiveTitle }}
        </v-card-title>
        <v-card-text>
          <v-row class="ma-3">
            <div class="grey--text ms-1">
              <strong>Issued Date:</strong> {{ new Date(directive.directiveCreateddate).toDateString() }}
            </div>

            <v-spacer></v-spacer>
                <v-chip
                  class="ma-2"
                  color="success"
                  outlined
                  small
                  v-if="directive.directiveStatus"
                >
                  <strong>COMPLETED</strong>: {{
                    new Date(directive.directiveCompletedDate).toDateString()
                  }}
                </v-chip>
                <v-chip v-else class="ma-2" :color="directive.directiveImplementation === 'NOTSTARTED'
                ? 'red'
                : directive.directiveImplementation === 'ONHOLD'
                ? 'orange'
                : 'primary'" small outlined>
                  {{
                    directive.directiveImplementation === "NOTSTARTED"
                        ? "NOT STARTED"
                        : directive.directiveImplementation === "ONHOLD"
                            ? "ON-HOLD"
                            : "IN PROGRESS"
                  }}
                </v-chip>
          </v-row>

          <div class="mt-4 text-subtitle-1 ma-3">
            <strong>Meeting:</strong> {{ directive.directiveMeeting }}
          </div>

          <div class="mt-0 text-subtitle-1 ma-3">
            <v-row>
              <v-col sm="4">
                <strong>Due Date:</strong> {{ new Date(directive.directiveDuedate).toDateString() }}
              </v-col>
              <v-col sm="4">
                <strong>Designation:</strong>
                {{ directive.directiveOwner.designationShortForm }}
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider class="mx-1"></v-divider>

        <v-card
          class="pa-3 agenda complete ma-6"
          outlined
          v-for="(progress, index) in directiveProgresses"
          :key="index"
          style="margin: 10px"
        >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-card-title
            ><div style="font-size:14px" v-html="progress.progressDescriptions"></div
          ></v-card-title>

          <v-card-text>
            <v-divider class="mb-2"></v-divider>
            <span><strong>Reporter:</strong>{{ progress.progressReporter.firstName }}{{ progress.progressReporter.lastName }}</span> <span><strong> Date:</strong> {{ progress.progressDate }}</span>
            <v-divider class="my-2"></v-divider>
              <strong>Attachments</strong>
              <v-chip
                close
                close-icon="mdi-attachment"
                small
                outlined
                color="success"
                v-for="(document, index) in progress.attachmentReport.edges"
                :key="index"
                class="ma-1"
                link
                @click="openDocumentViewer(document.node.attachmentPath)"
                >{{ document.node.attachmentName }}</v-chip
              >
          </v-card-text>
        </v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            small
            text
            outlined
            @click="$emit('CloseViewActionCard')"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DocumentViewer
      v-if="documentViewer === true"
      :docValue="documentPath"
      :OpenfileDialog="documentViewer"
      :title="`View Report Attachment`"
      @CloseDocumentViwer="documentViewer = false"
    />
  </v-container>
</template>

<script>
import DocumentViewer from "../eboard-documents/DocumentViewer.vue";

export default {
  props: ["openAction", "directive"],
  components: { DocumentViewer },
  data() {
    return {
      content: "",
      editorOption: {},
      documentViewer: false,
      documentPath: "",
    };
  },
  computed: {
    directiveProgresses() {
      return this.$store.state.DirectiveStore.directiveProgress;
    },
  },
  methods: {
    openDocumentViewer(documentPath) {
      this.documentPath = documentPath;
      this.documentViewer = true;
    },
  },
};
</script>

<style></style>
