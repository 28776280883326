<template>
  <v-dialog v-model="startMyVideoCallInvitationDialog" width="40%" persistent>
    <v-card>
      <div class="d-flex flex-no-wrap justify-space-between">
        <div style="width: 100%">
          <v-card-title class="text-h5" style="color: rgba(6, 4, 40, 1)">
            <v-icon>mdi-phone-classic</v-icon> Call Invitations</v-card-title
          >
          <v-card-text>
            <p v-if="inviteesList.length > 0">Invitee List</p>

            <v-card
              max-width="100%"
              outlined
              v-for="pack in items"
              :key="pack"
              style="margin: 3px"
            >
              <v-list-item>
                <v-list-item-avatar tile size="50">
                  <v-img src="rington.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h7 mb-0">
                    <span style="font-weight: bold; color: grey"
                      >Meeting Theme:</span
                    >
                    {{ pack }} TWENDE tunarudi aki vile ss tutaenda wap akati
                    tunkua tushakula...
                  </v-list-item-title>
                  <v-list-item-subtitle
                    ><small
                      >Meeting Created by: <strong>{{ pack }}</strong></small
                    >,
                    <small
                      >Invited On: <strong>{{ pack }}</strong></small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="$emit('endMyVideoInvitationCallDialog')"
                  color="pink"
                  class="ml-2 mt-5"
                  outlined
                  rounded
                  small
                >
                  <v-icon small>mdi-phone-remove-outline</v-icon>
                  CANCEL MEETING
                </v-btn>
                <v-btn
                  @click="openNewMeetingScreen"
                  color="success"
                  class="ml-2 mt-5"
                  rounded
                  small
                >
                  <v-icon small>mdi-phone-forward-outline</v-icon>
                  JOIN MEETING
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["startMyVideoCallInvitationDialog"],

  data: () => ({
    items: ["foo"],
    values: [],
    inviteesList: [],
  }),
  methods: {
    openNewMeetingScreen() {
      let height = window.outerHeight;
      var actualSize = height * (90 / 100);
      window.open(
        "https://mikutano.gov.go.tz/ekby6nf7sr",
        "e-Board Call",
        "top=200,bottom=300,left=500,width=" + actualSize + ",height=600"
      );
      this.$emit("endMyVideoInvitationCallDialog");
    },
  },
};
</script>

<style>
</style>