<template>
  <v-row justify="center">
    <v-dialog
        v-model="isEditAgendaDetails"
        max-width="800px"
        persistent
    >
      <v-card>
        <v-card-title>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="12"
                  sm="12"
              >
                <v-text-field
                    v-model="value"
                    :label="updatePlace"
                    prepend-icon="mdi-text-recognition"
                    required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="white--text"
              color="pink"
              fab
              small
              @click="$emit('closeEditingAgendaDetails')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
              class="white--text"
              color="blue darken-1"
              fab
              small
              @click="editAgendaItemDetails"
          >
            <v-icon>mdi-send-check-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  data: () => ({
    value: '',
    selectedAgenda: '',
    updatePlace: ''
  }),

  watch: {
    itemDetails() {
      this.value = this.itemDetail.value
      this.selectedAgenda = this.itemDetails.selectedAgenda
      this.updatePlace = this.itemDetails.updatePlace
    }
  },

  computed: {
    itemDetails() {
      return this.itemDetail
    }
  },

  methods: {
    async editAgendaItemDetails() {
      const details = {
        newPurpose: this.value,
        detailKey: this.selectedAgenda,
        updatePlace: this.updatePlace
      };
      await this.$store.dispatch('updateManagementAgendaItemsDetails', details);
      await this.$store.dispatch('loadManagementBestPracticeAgendaTemplates', this.$store.state.ManagementCurrentMeetingStore.currentManagementMeeting)
      this.$emit('closeEditingAgendaDetails')
    }
  },

  props:["isEditAgendaDetails", "itemDetail", "closeEditingAgendaDetails"]
}
</script>