import {apolloClient} from "@/vue-apollo";
import createMeetingReport from "../../graphql/management-report-builder-graphql/createMeetingReport.graphql";
import filterManagementReportTemplate
    from "../../graphql/management-report-builder-graphql/filterManagementReportTemplate.gql";
import managementWeeklyReports from "../../graphql/management-report-builder-graphql/managementWeeklyReports.gql";
import commentOnWeeklyReport from "../../graphql/management-report-builder-graphql/commentOnWeeklyReport.gql";
import addWeeklyReportDocuments from "../../graphql/management-report-builder-graphql/addWeeklyReportDocuments.gql";
import submitWeeklyReport from "../../graphql/management-report-builder-graphql/submitWeeklyReport.gql";
import uploadFile from "../../graphql/eboard-documents-graphql/uploadFile.gql";
import deleteManagementReport from "../../graphql/management-report-builder-graphql/deleteManagementReport.graphql";
import swal from "sweetalert2";
import {errorAlert, successAlert} from "@/services/notificationService";
import filterManagementReportTemplatesByPeriod
    from "../../graphql/management-report-builder-graphql/filterManagementReportTemplatesByPeriod.graphql";
import filterReportTemplates from "../../graphql/management-report-builder-graphql/filterReportTemplates.gql"
import updateManagementSection from "../../graphql/management-report-builder-graphql/updateManagementSection.gql"
import newManagementSection from "../../graphql/management-report-builder-graphql/newManagementSection.gql"
import deleteManagementSection from "../../graphql/management-report-builder-graphql/deleteManagementSection.graphql"
import updateManagementTemplateReportName
    from "../../graphql/management-report-builder-graphql/updateManagementTemplateReportName.graphql"
import deleteWeeklyReportDocuments
    from "../../graphql/management-report-builder-graphql/deleteWeeklyReportDocuments.graphql"


export default {
    state: {
        managementMeetingsReportsList: [],
        managementWeeklyReportsList: [],
        managementWeeklySupportingDocuments: [],
        managementReportsInPeriod: [],
        reportTemplateDetails: {}
    },

    getters: {
        getManagementWeeklyReportsList: (state) => state.managementWeeklyReportsList,
        getManagementReportsInPeriod: (state) => state.managementReportsInPeriod,
        getManagementReportTemplateDetails: (state) => state.reportTemplateDetails,
    },

    mutations: {
        cleanManagementMeetingsReportsList(state) {
            state.managementMeetingsReportsList = [];
            state.managementWeeklySupportingDocuments = [];
        },
        setManagementReportsList(state, meetingReport) {
            state.managementMeetingsReportsList = meetingReport;
        },
        setManagementWeeklyReportsList(state, week) {
            state.managementWeeklyReportsList = [];
            state.managementWeeklyReportsList = week;
        },
        addManagementWeeklySupportingDocuments(state, week) {
            state.managementWeeklySupportingDocuments.push(week);
        },
        seManagementReportsInPeriod(state, data) {
            state.managementReportsInPeriod = [];
            state.managementReportsInPeriod = data;
        },
        seManagementReportTemplateDetails(state, data) {
            state.reportTemplateDetails = data;
        },
        updateManagementReportTemplateName(state, templateName) {
            state.reportTemplateDetails.templateReportName = templateName
        },
        editManagementReportTemplate(state, data) {
            let componentIndex = state.reportTemplateDetails.templateComponents.findIndex(component => component.primaryKey == data.primaryKey)
            let templateDetails = state.reportTemplateDetails.templateComponents
            templateDetails[componentIndex] = data;
            state.reportTemplateDetails.templateComponents = [...templateDetails];
        },
        addManagementReportComponent(state, reportComponent) {
            state.reportTemplateDetails.templateComponents.push(reportComponent)
        },
        deleteManagementReportComponent(state, componentId) {
            let componetIndex = state.reportTemplateDetails.templateComponents.findIndex(component => component.primaryKey === componentId)
            state.reportTemplateDetails.templateComponents.splice(componetIndex, 1)
            state.reportTemplateDetails = {}
        },
        deleteReportDocument(state, documentUniqueId) {
            let documentIndex = state.reportTemplateDetails.templateDocuments.findIndex(document => document.documentUniqueId === documentUniqueId)
            state.reportTemplateDetails.templateDocuments.splice(documentIndex, 1)
        },
        addReportSupportingDocument(state, document) {
            state.reportTemplateDetails.templateDocuments.push(document);
        },
        deleteManagementReport(state, templateUniqueId) {
            let reportIndex = state.managementMeetingsReportsList.findIndex(report => report.templateUniqueId === templateUniqueId)
            state.managementMeetingsReportsList.splice(reportIndex, 1)
        },
    },

    actions: {
        async filterManagementReportTemplatesByPeriod(context, input) {
            await context.dispatch("showLoadingSpinner", true);

            apolloClient.query({
                query: filterManagementReportTemplatesByPeriod,
                fetchPolicy: 'no-cache',
                variables: {
                    filterReportType: input.filterReportType,
                    reportIsPublished: input.reportIsPublished,
                    filterMonth: input.filterMonth,
                    filterYear: input.filterYear,
                    filterQuarter: input.filterQuarter,
                },
            })
                .then(async (result) => {
                    const {response, data} = result.data.filterManagementReportTemplatesByPeriod;

                    if (response.status) {
                        context.commit('seManagementReportsInPeriod', data);
                    } else {
                        await errorAlert(response.message)
                    }

                    await context.dispatch("showLoadingSpinner", false);
                }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error);
            });
        },

        async loadManagementMeetingsReportsList(context, weekIdObj) {
            await context.dispatch("showLoadingSpinner", true);
            context.commit('cleanManagementMeetingsReportsList');

            apolloClient.query({
                query: filterManagementReportTemplate,
                fetchPolicy: 'no-cache',
                variables: {
                    weekUniqueId: weekIdObj.weekId,
                    managementUniqueId: localStorage.getItem("my-managementUniqueId"),
                    reportIsPublished: weekIdObj.reportIsPublished,
                    designationUniqueId: weekIdObj.designationUniqueId
                },
            })
                .then(async (result) => {
                    const {response, data} = result.data.filterManagementReportTemplate;

                    if (response.status) {
                        context.commit('setManagementReportsList', data);
                    } else {
                        await errorAlert(response.message)
                    }

                    await context.dispatch("showLoadingSpinner", false);
                }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error);
            });
        },

        async createManagementMeetingReport(context, input) {
            await context.dispatch("showLoadingSpinner", true);

            await apolloClient
                .mutate({
                    mutation: createMeetingReport,
                    variables: {input},
                })
                .then((result) => {
                    let {response, data} = result.data.createManagementReport;

                    if (response.status) {
                        context.commit("setManagementReportsList", data);
                        successAlert("Report Created Successfully");
                    } else {
                        errorAlert(response.message);
                    }

                    context.dispatch("showLoadingSpinner", false);
                })
                .catch(async (error) => {
                    await context.dispatch("showLoadingSpinner", false);
                    console.error(error);
                });
        },

        async deleteManagementMeetingReport(context, reportObj) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient
                .mutate({
                    mutation: deleteManagementReport,
                    variables: {
                        templateUniqueId: reportObj.templateUniqueId,
                    },
                })
                .then((response) => {
                    if (response.data.deleteManagementReport.success) {
                        context.commit('deleteManagementReport', reportObj.templateUniqueId)
                        successAlert("Report deleted successfully");
                    } else {
                        errorAlert("Failed to delete Report");
                    }

                }).finally(() => {
                context.dispatch("showLoadingSpinner", false);
            })
        },

        async getManagementsWeeklyReports(context) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient
                .query({
                    fetchPolicy: "no-cache",
                    query: managementWeeklyReports,
                    variables: {
                        managementUniqueId: localStorage.getItem("my-managementUniqueId"),
                    },
                })
                .then(async (response) => {
                    const weeks = response.data.managementWeeklyReports;
                    context.commit("setManagementWeeklyReportsList", weeks);
                    await context.dispatch("showLoadingSpinner", false);
                })
                .catch(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                    await swal.fire({
                        toast: true,
                        icon: "error",
                        title:
                            "Sorry, we encounter some issues when processing your request. Please try again or contact your adminstrator . . . !",
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: false,
                    });
                });
        },

        async commentOnWeeklyReport(context, comments) {
            await apolloClient
                .mutate({
                    mutation: commentOnWeeklyReport,
                    variables: {
                        comments: comments,
                    },
                })
                .then((response) => {
                    const meetingReport = response.data.commentOnWeeklyReport.success;
                    if (meetingReport) {
                        swal.fire({
                            toast: true,
                            icon: "success",
                            title: "Comments sent successfully",
                            animation: false,
                            position: "top-right",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        });
                    }
                })
                .catch(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                    await swal.fire({
                        toast: true,
                        icon: "error",
                        title:
                            "Sorry, we encounter some issues when processing your request. Please try again or contact your adminstrator . . . !",
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: false,
                    });
                });
        },

        async reportSubmitFile(context, sendData) {
            var SavedFile = null;
            await apolloClient
                .mutate({
                    mutation: uploadFile,
                    loading: this.value,
                    variables: {
                        file: sendData.file,
                    },
                    update: (store, {data: {uploadFile}}) => {
                        SavedFile = {
                            fileName: uploadFile.fileName,
                            fullFileName: uploadFile.fullFileName,
                        };
                        context.commit("addManagementWeeklySupportingDocuments", SavedFile);
                    },
                })
                .then(() => {
                    apolloClient
                        .mutate({
                            mutation: addWeeklyReportDocuments,
                            variables: {
                                reportKey: sendData.data,
                                documentName: SavedFile.fileName,
                                filePath: SavedFile.fullFileName,
                            },
                        })
                        .then(async (response) => {
                            const result = response.data.addWeeklyReportDocuments;
                            if (result.response.status) {
                                SavedFile = {
                                    ...result.data,
                                    fileName: result.data.documentName,
                                    fullFileName: result.data.documentUniqueId,
                                };
                                context.commit('addReportSupportingDocument', SavedFile)
                                await successAlert(result.response.message)
                            } else {
                                await errorAlert(result.message)
                            }

                        })
                        .catch(async () => {
                            await context.dispatch("showLoadingSpinner", false);
                            await swal.fire({
                                toast: true,
                                icon: "error",
                                title:
                                    "Sorry, we encounter some issues when processing your request. Please try again or contact your adminstrator . . . !",
                                animation: false,
                                position: "top-right",
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: false,
                            });
                        });
                })
                .catch(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                    await swal.fire({
                        toast: true,
                        icon: "error",
                        title:
                            "Sorry, we encounter some issues when processing your request. Please try again or contact your adminstrator . . . !",
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: false,
                    });
                });
        },

        async weeklyReportSubmition(context, reportKey) {
            await apolloClient
                .mutate({
                    mutation: submitWeeklyReport,
                    variables: {
                        reportKey: reportKey,
                    },
                })
                .then((response) => {
                    let result = response.data.submitWeeklyReport
                    if (result.response.status) {
                        // context.commit('deleteReportDocument', documentUniqueId)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                }).finally(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
        },

        async getReportTemplateDetails(context, templateUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                query: filterReportTemplates,
                fetchPolicy: 'no-cache',
                variables: {templateUniqueId},
            })
                .then(async (result) => {
                    const {response, data} = result.data.filterManagementReportTemplates;

                    if (response.status) {
                        context.commit('seManagementReportTemplateDetails', data);
                    } else {
                        await errorAlert(response.message)
                    }

                    await context.dispatch("showLoadingSpinner", false);
                }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error);
            });
        },
        async editReportTemplateComponent(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateManagementSection,
                variables: {
                    ...input
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateManagementSection
                if (result.response.code === 9000) {
                    context.commit('editManagementReportTemplate', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async editReportTemplateName(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateManagementTemplateReportName,
                variables: {
                    ...input
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateManagementTemplateReportName
                if (result.response.code === 9000) {
                    context.commit('updateManagementReportTemplateName', input.templateReportName)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async createReportTemplateComponent(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: newManagementSection,
                variables: {
                    ...input
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.newManagementSection
                if (result.response.code === 9000) {
                    context.commit('addManagementReportComponent', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async deleteReportTemplateComponent(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            console.log(input.componentId)
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteManagementSection,
                variables: {
                    componentId: input.componentId
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.deleteManagementSection
                if (result.response.status) {
                    context.dispatch('getReportTemplateDetails', input.templateUniqueId)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async deleteReportSupportDocument(context, documentUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteWeeklyReportDocuments,
                variables: {
                    documentUniqueId
                }
            }).then(async (response) => {
                let result = response.data.deleteWeeklyReportDocuments
                if (result.response.status) {
                    context.commit('deleteReportDocument', documentUniqueId)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
    },



}
;
