<template>
  <v-container app class="my-5" fluid>
    <NewNoticePage
      :Takenotewindow="Takenotewindow"
      @close="Takenotewindow = !Takenotewindow"
    />
  </v-container>
</template>

<script>
import NewNoticePage from "./NewNoticePage.vue";
export default {
  components: {
    NewNoticePage,
  },

  data() {
    return {
      Takenotewindow: false,
    };
  },
  methods: {
    deletePage() {
      this.$emit("delete-page");
    },
    savePage() {
      this.$emit("save-page");
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  padding: 1rem;
  box-shadow: 1rem 0 1rem 1rem #c1d2ff;
}

.content,
.title {
  border-style: none;
  border-radius: 0.25rem;
  border: solid 1px lightgray;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.25rem;
}

.content:focus,
.title:focus {
  outline: 0;
}

.content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  resize: vertical;
  font-size: 1.5rem;
  padding: 0.5rem;
  height: 20rem;
}

.title {
  font-size: 2rem;
  padding: 0.5rem 1rem;
}

label {
  margin-bottom: 0.5rem;
  display: inline-block;
}

button {
  border-style: none;
  padding: 0.5rem 0.75rem;
  background-color: #447bc3;
  margin-right: 1rem;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #368ea2;
}
</style>