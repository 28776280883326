<template>
  <v-container fluid class="my-5" app>
    <v-row>
      <v-col class="flex items-center">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                right
                small
                outlined
                rounded
                fab
                color="primary white--text"
                style="margin: 5px"
                @click="$router.go('-1')"
            ><v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Meeting Details</span>
        </v-tooltip>
        <h2 class="text-xl ml-2">{{ meetingDetails.meetingTitle }}</h2>
      </v-col>
    </v-row>
    <v-row justify="center">
        <v-layout  style=" padding-top:10px" justify-space-around>
          <v-flex  xs12 md4 max-width="100%">
            <ManagementCommitteeAgendaSidePanel :meetingHasPublishedAgenda="HasPublishedAgenda" :currentMeeting="currentMeeting" />
          </v-flex>
          <v-flex xs12 md8>
<!--            <AgendaMinutes @closeGreatModel="closeGreatModel" style="width:100%" :currentMeeting="currentMeeting" />-->
            <ManagementCommitteeAgendaMinutes />
          </v-flex>
        </v-layout>
    </v-row>
  </v-container>
</template>

<script>
import ManagementCommitteeAgendaMinutes from './ManagementCommitteeAgendaMinutes.vue'
import ManagementCommitteeAgendaSidePanel from '@/modules/management_committee/management_committee_agenda/ManagementCommitteeAgendaSidePanel.vue'

export default {
  name: "ManagementCommitteeMinutesBuilder",
  props:['TakeminutesWindow','agendaMode','listOfagendas'],
  components:{
    ManagementCommitteeAgendaMinutes,
    ManagementCommitteeAgendaSidePanel
  },
  data () {
    return {
      scrollable:false,
      dialog2: false,
      dialog3: false,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },

  methods:{
    lockScrolling(){
      const body = document.getElementById("agendaPanels")
      body.style.overflow = 'hidden'

      body.style.overflow = 'auto'
    },
    getAgendaItemDetails(ItemId){
      console.log(ItemId);
    },
    closeGreatModel(){
      this.$emit('close')
    }
  },
  computed: {
    HasPublishedAgenda(){
      return this.$store.getters.getSelectedManagementCommitteeMeetings.meetingHasPublishedAgenda || false
    },
    currentMeeting() {
      return this.$route.params.meetingUniqueId
    },
    meetingDetails() {
      return this.$store.getters.getSelectedManagementCommitteeMeetings
    },
  }
}
</script>

<style>
.fixed-position {
  position: fixed;
}

.transparent {
  background-color: white!important;
  opacity: 0.3;
  border-color: transparent!important;
}
.scroll {
  overflow-y: scroll;

}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>