import {apolloClient} from '@/vue-apollo'
import allMinuteNotes from '../../graphql/eboard-minutes-graphql/allMinuteNotes.gql'
import saveMinuteNotes from '../../graphql/eboard-minutes-graphql/saveMinuteNotes.gql'
import saveMinuteDecision from '../../graphql/eboard-minutes-graphql/saveMinuteDecision.gql'
import allMinuteDecisions from '../../graphql/eboard-minutes-graphql/allMinuteDecisions.gql'
import allMinuteDirectives from '../../graphql/eboard-minutes-graphql/allMinuteDirectives.gql'
import saveMinuteDirective from '../../graphql/eboard-minutes-graphql/saveMinuteDirective.gql'
import previewMeetingMinutes from '../../graphql/eboard-minutes-graphql/previewMeetingMinutes.gql'
import confirmMeetingMinutes from '../../graphql/eboard-minutes-graphql/confirmMeetingMinutes.gql'
import swal from 'sweetalert2'
import editMinuteDirective from "@/graphql/eboard-minutes-graphql/editMinuteDirective.gql";
import deleteMinuteDirective from "@/graphql/eboard-minutes-graphql/deleteMinuteDirective.gql";
import editMinuteDecision from "@/graphql/eboard-minutes-graphql/editMinuteDecision.gql";
import deleteMinuteDecision from "@/graphql/eboard-minutes-graphql/deleteMinuteDecision.gql";
import saveCustomMinutesSections from "@/graphql/eboard-minutes-graphql/saveCustomMinutesSections.gql";
import allCustomMinuteSections from "@/graphql/eboard-minutes-graphql/allCustomMinuteSections.gql";


export default {
    state: {
        agendaItemMinuteNote: {},
        agendaItemMinuteDecision: [],
        agendaItemMinuteDirective: [],
        agendaItemCustomMinuteSections: [],
        priviewMinutes: null,
    },

    mutations: {
        cleanNotes(state) {
            state.agendaItemMinuteNote = {}
        },
        cleanDecisions(state) {
            state.agendaItemMinuteDecision = []
        },
        addMinuteNotes(state, note) {
            state.agendaItemMinuteNote = note
        },
        updateMinuteNotes(state, notesDescriptions) {
            state.agendaItemMinuteNote.notesDescriptions = notesDescriptions
        },
        addMinuteDecisions(state, decisions) {
            state.agendaItemMinuteDecision = decisions
        },
        addMinuteDirectives(state, directive) {
            state.agendaItemMinuteDirective = directive
        },
        addCustomMinuteSections(state, sections) {
            state.agendaItemCustomMinuteSections = sections
        },

        setMeetingMinutesMinutes(state, minutes) {
            state.priviewMinutes = minutes
        },
        updateMinutesNotes(state, noteData) {
            state.agendaItemMinuteNote.notesDescriptions = noteData
        }
    },

    actions: {
        async loadAllMinuteNotes(context, itemUniqueId) {
            context.commit('cleanNotes')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMinuteNotes,
                variables: {
                    itemUniqueId: itemUniqueId
                }
            }).then((response) => {
                const notes = response.data.allMinuteNotes
                context.commit('addMinuteNotes', notes)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async updateMinutesNotesOnTyping(context, noteData) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: saveMinuteNotes,
                variables: {
                    notesContents: noteData.note,
                    notesItemKey: noteData.itemKey
                }
            }).then(() => {
                context.commit('updateMinutesNotes', noteData.note)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async confirmMeetingMinutes(context, itemKey) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: confirmMeetingMinutes,
                variables: {
                    itemUniqueId: itemKey,
                    signatureParafraise: ""
                }
            }).then((response) => {
                context.dispatch("showLoadingSpinner", false);
                if (response.data.confirmMeetingMinutes.success) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Meeting minutes has been confirmed successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'There is problem occur when confirming meeting minutes. Please try again.',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                    })
                }
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllDecisionMinutes(context, itemUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMinuteDecisions,
                variables: {
                    itemUniqueId: itemUniqueId
                }
            }).then((response) => {
                const decisions = response.data.allMinuteDecisions
                context.commit('addMinuteDecisions', decisions)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllCustomMinuteSections(context, itemUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allCustomMinuteSections,
                variables: {
                    itemUniqueId: itemUniqueId
                }
            }).then((response) => {
                const sections = response.data.allCustomMinuteSections
                context.commit('addCustomMinuteSections', sections)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async saveDecisionsMinutes(context, decisionData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: saveMinuteDecision,
                variables: {
                    decisionContents: decisionData.decisionContents,
                    decisionDate: decisionData.decisionDate,
                    decisionOutcome: decisionData.decisionOutcome,
                    decisionTitle: decisionData.decisionTitle,
                    decisionItemKey: decisionData.decisionItemKey
                }
            }).then((response) => {
                context.commit('addMinuteDecisions', response.data.saveMinuteDecision.decision)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async loadAllDirectiveMinutes(context, itemUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMinuteDirectives,
                variables: {
                    itemUniqueId: itemUniqueId
                }
            }).then((response) => {
                const directives = response.data.allMinuteDirectives
                context.commit('addMinuteDirectives', directives)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async saveDirectivesMinutes(context, directiveData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: saveMinuteDirective,
                variables: {
                    directiveAssingee: directiveData.directiveAssingee,
                    directiveContents: directiveData.decisionContents,
                    directiveDueDate: directiveData.directiveDueDate,
                    directiveItemKey: directiveData.decisionItemKey,
                    directiveTitle: directiveData.directiveTitle
                }
            }).then((response) => {
                context.commit('addMinuteDirectives', response.data.saveMinuteDirective.directive)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async previewMeetingMinutesMinutes(context, meetingUniqueId) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: previewMeetingMinutes,
                variables: {
                    meetingUniqueId: meetingUniqueId
                }
            }).then((response) => {
                context.commit('setMeetingMinutesMinutes', response.data.previewMeetingMinutes.minutes)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async editDirectivesMinutes(context, directiveData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: editMinuteDirective,
                variables: {
                    directiveAssingee: directiveData.directiveAssingee,
                    directiveContents: directiveData.directiveContents,
                    directiveDueDate: directiveData.directiveDueDate,
                    directiveItemKey: directiveData.directiveItemKey,
                    directiveTitle: directiveData.directiveTitle,
                    directiveUniqueId: directiveData.directiveUniqueId
                }
            }).then(() => {
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteDirectivesMinutes(context, directiveData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deleteMinuteDirective,
                variables: {
                    directiveItemKey: directiveData.directiveItemKey,
                    directiveUniqueId: directiveData.directiveUniqueId
                }
            }).then(() => {
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async editDecisionsMinutes(context, decisionData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: editMinuteDecision,
                variables: {
                    decisionContents: decisionData.decisionContents,
                    decisionDate: decisionData.decisionDate,
                    decisionOutcome: decisionData.decisionOutcome,
                    decisionTitle: decisionData.decisionTitle,
                    decisionItemKey: decisionData.decisionItemKey,
                    decisionUniqueId: decisionData.decisionUniqueId
                }
            }).then(() => {
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteDecisionsMinutes(context, decisionData) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deleteMinuteDecision,
                variables: {
                    decisionUniqueId: decisionData.decisionUniqueId
                }
            }).then(() => {
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },
        async updateCustomMinutesSectionsOnTyping(context, customData) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: saveCustomMinutesSections,
                variables: {
                    sectionAgendaItem: customData.sectionAgendaItem,
                    sectionUniqueId: customData.sectionUniqueId,
                    sectionValues: customData.sectionValues,
                }
            }).then(() => {
                context.dispatch("showLoadingSpinner", false);
                // context.commit('updateMinutesNotes', customData.note)
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        }
    }
}

