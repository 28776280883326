<template>
  <div class="text-center">
    <v-dialog v-model="OpenSectionEditor" persistent width="80%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon left>mdi-text-box-plus-outline</v-icon> {{ ActionTitle }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="sectionHeaderData"
                  :counter="100"
                  label="Section Tittle"
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <Editor
              :content="sectionBodyData"
              :editorOption="editorOption"
              @valueHasChange="onEditorChange"
              style="height: 500px"
            />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseSectionEditor')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>

          <v-btn
            v-if="ActionTitle === `Edit Report Section`"
            color="success"
            outlined
            small
            :loading="submiting"
            @click="updateSection"
          >
            <v-icon small>mdi-floppy</v-icon> Save Changes
          </v-btn>
          <v-btn
            v-else
            color="success"
            outlined
            small
            :loading="submiting"
            @click="createSection"
          >
            <v-icon small>mdi-floppy</v-icon> Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Editor from "./ReportEditor.vue";
// import updateSection from "../../../graphql/management-report-builder-graphql/updateSection.gql";


export default  {
  props: [
    "OpenSectionEditor",
    "ActionTitle",
    "componentTemplateId",
    "CloseSectionEditor",
    "componentObj"
  ],

  components: {
    Editor,
  },

  data: () => ({
    submiting: false,
    deleteSubmiting: false,
    content: "",
    sectionTitle: "",
    sectionBodyData: "",
    sectionHeaderData: "",
    dateFormatted: "",
    menu1: false,
    editorOption: {
      placeholder: "Write Contents here . . . ",
      theme: "snow",
      toolbar: "minimal",
    },
    openDialog: false,
    component: {
      componentTitle: "",
      componentBody: ""
    }
  }),

  watch: {
    OpenSectionEditor(value) {
      if (value) {
        // alert(JSON.stringify(this.componentObj))
        console.log(this.componentObj ? "this.componentObj.componentTitle" : "")
        this.sectionHeaderData = this.componentObj ? this.componentObj.componentTitle : "";
        this.sectionBodyData = this.componentObj ? this.componentObj.componentBody : "";
        this.content = this.sectionBodyData;

        // this.openDialog = true;
      }
    }
  },

  computed: {

  },

  async mounted() {
    this.sectionTitle = this.sectionHeaderData;
    this.content = this.sectionBodyData;
  },

  methods: {
    onEditorChange(new_values) {
      this.content = new_values;
    },

    async createSection() {
      this.$store.dispatch('createReportTemplateComponent', {
        componentTitle: this.sectionHeaderData,
        componentBody: this.content,
        componentTemplateId: this.componentTemplateId,
        componentIsTable: false,
      })
      this.sectionHeaderData = ""
      this.content = ""
      this.$emit('CloseSectionEditor')
    },

    async updateSection() {
      this.$store.dispatch('editReportTemplateComponent', {
        componentId: this.componentObj.primaryKey.toString(),
        componentTitle: this.sectionHeaderData,
        componentBody: this.content
      })
      this.sectionHeaderData = ""
      this.content = ""
      this.$emit('CloseSectionEditor')
    },
  },
};
</script>
