import {apolloClient} from "@/vue-apollo";
import getSystemAdminstratorsRoles from "@/graphql/admin-panel-graphql/administrators-graphql/getSystemAdminstratorsRoles.gql.graphql";
import {errorAlert, successAlert} from "@/services/notificationService";
import createSystemAdminstratorsRole from "@/graphql/admin-panel-graphql/administrators-graphql/addSystemAdminstratorsRole.gql.graphql";
import deleteSystemAdminstratorsRole from "@/graphql/admin-panel-graphql/administrators-graphql/deleteSystemAdminstratorsRole.gql.graphql";
import updateSystemAdminstratorsRole from "@/graphql/admin-panel-graphql/administrators-graphql/updateSystemAdminstratorsRole.gql.graphql";



export default {
    state: {
        systemAdministratorRoleList: []

    },

    mutations: {
        clearSystemAdminRoles(state) {
            state.systemAdministratorRoleList = []
        },

        addSystemAdminRole(state, adminObj){
            state.systemAdministratorRoleList.push(adminObj)
        },

        deleteSystemAdminstratorsRole(state, roleUniqueId){
            let roleIndex = state.systemAdministratorRoleList.findIndex(role => role.roleUniqueId === roleUniqueId)
            state.systemAdministratorRoleList.splice(roleIndex, 1)

            // var temp_list = []
            // state.defaulfUserRoleList.forEach(role=>{
            //     console.log(role);
            //     if (role.roleUniqueId !== roleUniqueId){
            //         temp_list.push(role)
            //     }
            // })
        },

        saveSystemAdminRole(state, admin){
            state.systemAdministratorRoleList = admin
        },

        updateSystemAdminstratorsRole(state, adminRoleObj){
            state.systemAdministratorRoleList = state.systemAdministratorRoleList.map((admin) => {
                return admin.id == adminRoleObj.id ? adminRoleObj : admin
            })
        }

    },

    getters: {
        getSystemAdminRoleList(state){
            return state.systemAdministratorRoleList
        },
    },

    actions: {
        async loadSystemAdminRoles(context) {
            context.commit('clearSystemAdminRoles')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getSystemAdminstratorsRoles
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                const results = response.data.getSystemAdminstratorsRoles
                if (results.response.code == 9000) {
                    context.commit('saveSystemAdminRole', results.data)
                } else {
                    errorAlert(results.response.message)
                }
            })
        },

        async createSystemAdminRoles(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createSystemAdminstratorsRole,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createSystemAdminstratorsRole
                if (result.response.code == 9000) {
                    context.commit('addSystemAdminRole', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },


        async deleteSystemAdminRole(context, roleUniqueId){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteSystemAdminstratorsRole,
                variables: {
                    roleUniqueId: roleUniqueId
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.deleteSystemAdminstratorsRole
                if (result.response.code == 9000) {
                    context.commit('deleteSystemAdminstratorsRole', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

        async editSystemAdminRole(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateSystemAdminstratorsRole,
                variables: {
                    input: {
                        ...input,
                    }
                }
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateSystemAdminstratorsRole
                console.log(result)
                if (result.response.code == 9000) {
                    context.commit('updateSystemAdminstratorsRole', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },


    }
}