import {  apolloClient } from '@/vue-apollo'

import  getManagementCommitteeMeetingRepository from '../../graphql/management-committee-meeting-repository-grapqhl/getManagementCommitteeMeetingRepository.gql'
import {errorAlert} from "@/services/notificationService";

export default {
    state: {
        managementCommitteeRepository:[],
    },

    mutations:{
        clearManagementCommitteeRepository(state){
            state.managementCommitteeRepository=[]
        },
        saveManagementCommitteeRepository(state, repository){
            state.managementCommitteeRepository=repository
        },
    },
    getters:{
        getManagementCommitteeRepository(state){
            return state.managementCommitteeRepository
        }
    },
    actions: {
        async loadManagementCommitteeMeetingRepository(context, filterObj) {
            context.commit('clearManagementCommitteeRepository');
            await context.dispatch("showLoadingSpinner", true);

                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: getManagementCommitteeMeetingRepository,
                    variables: {
                        committeeUniqueId: filterObj.committeeUniqueId,
                        search: filterObj.search,
                        pageNumber: filterObj.pageNumber,
                    }
                }).then(async (response) => {
                    let result = response.data.getManagementCommitteeMeetingRepository
                    if (result.response.code === 9000) {
                        context.commit('saveManagementCommitteeRepository', { data: result.data, page: result.page });
                    } else {
                        await errorAlert(result.response.message)
                    }
                }).finally(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
        },
    }
}