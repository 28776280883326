<template>
  <v-container>
    <v-alert
      icon="mdi-file-cog"
      prominent
      dense
      text
      type="info"
      color="green"
      border="left"
      class="ml-2 mr-2 mt-4"
    >
      Perform important settings which will be used by your institution only
    </v-alert>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-switch
            @change="updateBoardSettingsValues('allow_sms_notification', $event)"
            v-model="(boardSetting()||{}).allowSmsNotification"
            inset
            color="orange darken-3"
          >
            <template v-slot:label> SMS Notification </template>
          </v-switch>
          <SMSConfigutationForm v-if="(boardSetting()||{}).allowSmsNotification" />
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-switch
            @change="updateBoardSettingsValues('invitation_expire_one_day_before_meeting', $event)"
           v-model="(boardSetting()||{}).invitationExpireOneDayBeforeMeeting"
            inset
            color="primary"
          >
            <template v-slot:label> Invitation  Expire One Day before Meeting </template>
          </v-switch>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-switch
            readonly
            @change="updateBoardSettingsValues('digital_signature_enabled', $event)"
            v-model="(boardSetting()||{}).digitalSignatureEnabled"
            inset
            color="pink"
          >
            <template v-slot:label> Digital Signature Enabled </template>
          </v-switch>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-switch
            @change="updateBoardSettingsValues('show_in_depth_logs', $event)"
            v-model="(boardSetting()||{}).showInDepthLogs"
            inset
            color="indigo"
          >
            <template v-slot:label> Show in depth Logs </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import SMSConfigutationForm from "./SMSConfigutationForm.vue";

export default {
  components: { SMSConfigutationForm },

  data() {
    return {
      templateColor: "#0062FF4D",
      smsConfigurations: false,
    };
  },

  methods: {
    async updateBoardSettingsValues(editedPart, currentValue) {
      let Setting = {
        settingType: editedPart,
        settingsValue: currentValue,
        settingIsColor: false,
        settingsColorValue: ""
      }
      await this.$store.dispatch('SettingsStore/updateSystemSettings', Setting);
      await this.$store.dispatch('SettingsStore/loadSMSConfigurations', {root: true})
    },
  },

  async mounted(){
    await this.$store.dispatch('SettingsStore/loadSMSConfigurations', {root: true})
  }
};
</script>

<style>
</style>
