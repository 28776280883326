<template>
  <div>
    <v-flex xs10 md11 max-width="100%">
      <span style="padding-left: 10px; font-weight: bold; width: 100%"
        >Supporting Documents :</span
      >
    </v-flex>
    <v-flex xs10 md11 max-width="100%">
      <v-layout>
        <v-flex xs12 md8>
          <div style="width: 100%; padding-left: 10px" id="app">
            <div>
              <div
                :class="['dropZone', dragging ? 'dropZone-over' : '']"
                @dragenter="dragging = true"
                @dragleave="dragging = false"
              >
                <div class="dropZone-info" @drag="onChange">
                  <span class="fa fa-cloud-upload dropZone-title"></span>
                  <span class="dropZone-title">Drop file or click here</span>
                  <div class="dropZone-upload-limit-info">
                    <div>Maximum file size: 5 MB</div>
                  </div>
                </div>
                <input
                  type="file"
                  accept="application/pdf"
                  @change="onChange"
                />
              </div>
            </div>
          </div>
        </v-flex>

        <v-flex xs12 md4>
          <div style="width: 100%; padding-left: 10px" id="app">
            <div>
              <div
                class="dropZone"
                @dragenter="dragging = true"
                @dragleave="dragging = false"
              >
                <div class="dropZone-info" @drag="onChange">
                  <span class="fa fa-cloud-upload dropZone-title"></span>
                  <span class="dropZone-title"
                    ><v-icon>mdi-palette-swatch</v-icon></span
                  >
                  <div class="dropZone-upload-limit-info">
                    <div>Load from Repository</div>
                  </div>
                </div>
                <input type="file" @change="onChange" />
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-layout v-if="supportingDocs.length < 1" style="padding-top: 10px">
      <v-alert dense text style="width: 100%">
        <v-flex xs12 md12>
          <div class="no-documents ng-star-inserted">
            <span>No documents uploaded.</span>
          </div>
        </v-flex>
      </v-alert>
    </v-layout>
    <v-layout v-else style="padding-top: 10px">
      <v-alert dense color="success" text style="width: 100%">
        <v-flex xs12 md12>
          <div class=""><span>Documents uploaded.</span></div>
        </v-flex>
      </v-alert>
    </v-layout>
    <v-divider v-if="file" inset></v-divider>
    <v-list dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="file in supportingDocs"
          :key="file.fileName"
          @click="OpenfileDialogFunc(file.fullFileName)"
          style="border-bottom: 1px solid gray"
        >
          <v-list-item-avatar size="50">
            <v-img src="@/assets/docs.png" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="file.fileName"></v-list-item-title>
            <v-list-item-subtitle v-text="file.fileName"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-menu offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  text
                  icon
                  x-small
                  color="indigo"
                  ><v-icon small>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list dense>
                <v-list-item link to="" @click="OpenfileDialogFunc(file.fullFileName)">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-eye-outline</v-icon
                    >

                      Preview
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item link to="" @click="
                        deleteSupportingDocument(
                          file.fullFileName,
                          'NewsUpdates'
                        )
                      ">
                  <v-list-item-title color="pink"
                    ><v-icon color="pink" style="height: 16px; font-size: 16px"
                      >mdi-trash-can-outline</v-icon
                    >
                    Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-dialog v-model="uploadDialog" max-width="500px">
      <v-card>
        <v-card-title style="font-size: 16px">
          <span
            ><v-icon>mdi-upload-outline</v-icon> Uploading Document . . .</span
          >
        </v-card-title>
        <v-card-text>
          <div style="min-height: 4px">
            <v-progress-linear
              v-model="value"
              :active="show"
              indeterminate
            ></v-progress-linear>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <DocumentViwer
      v-if="OpenfileDialog == true"
      :docValue="docValue"
      :OpenfileDialog="OpenfileDialog"
      :title="`View Attachment`"
      @CloseDocumentViwer="OpenfileDialog = false"
    />
  </div>
</template>

<script>
import swal from "sweetalert2";
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";

export default {
  props: ["type", "data",'suportingDocs'],
  components: {
    DocumentViwer,
  },
  data() {
    return {
      selectedItem: null,
      file: "",
      show: true,
      docValue: "",
      docType: "office",
      OpenfileDialog: false,
      uploadDialog: false,
      loading3: false,
      dragging: false,
      presenter: "No Presenter",
      duration: 0,
      value: 0,
      Purpose: "",
      responseDocs: [],
    };
  },

  methods: {
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      console.log(files);

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },
    async createFile(file) {
      if (!file.type.match("pdf.*")) {
        alert("please select PDF file");
        this.dragging = false;
        console.log(file);
        return;
      }

      if (file.size > 100000000) {
        alert("please check file size no over 5 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      this.uploadDialog = true;

      var sendData = {
        file: file,
        type: this.type,
      };
      await this.$store.dispatch("newsUpdateSubmitFile", sendData);
      this.dragging = false;
      this.uploadDialog = false;
      this.$emit("suportingDocs", this.supportingDocs);
    },
    removeFile() {
      this.file = "";
    },
    setPresenter(presenter) {
      this.presenter = presenter;
    },
    onInput(event) {
      var cleared = event.target.innerText.replace(/[^\d.-]/g, "");
      console.log(cleared);
      this.duration = cleared;
    },
    clearAllUnwantedChar(event) {
      event.target.innerText = this.duration;
    },
    onPurposeInput(event) {
      this.Purpose = event.target.innerText;
    },
    publishAgendas() {
      this.loading3 = true;
      swal.fire({
        toast: true,
        icon: "success",
        title: "Posted successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      setTimeout(() => (this.loading3 = false), 3000);
    },
    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      console.log(this.docValue);
      this.OpenfileDialog = true;
    },

    async deleteSupportingDocument(documentPeth,deletedFrom) {
      await this.$store.dispatch('deleteUploadedDocument',{
        "deletedDocument":documentPeth,
        "deletedFrom":deletedFrom
      })

      const index = this.$store.state.NewsUpdatesStore.actions.clearnewsandUpdateSuppotingDocuments()
      this.$store.state.NewsUpdatesStore.circularResolutionSupportingDocument.splice(index,1);

      await this.$store.dispatch('loadBoardDraftNewsUpdates')
    },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
    supportingDocs() {
        return this.$store.state.NewsUpdatesStore.newsandUpdateSupportingDocument;
    },
  },
};
</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}

iframe {
  width: 100%;
  height: 100%;
}

.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>