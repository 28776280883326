<template>
  <v-dialog persistent v-model="OpenEditDialog">
    <div class="grid grid-cols-12 gap-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Validation -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400"
          >
            <h2
              style="font-size: 1.25rem"
              class="font-medium text-base mr-auto"
            >
              <span
                ><v-icon color="indigo"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Edit Circular Resolution</span
              >
            </h2>

            <div class="form-check form-switch w-full sm:w-auto mt-3 sm:mt-0">
              <v-btn fab small text @click="$emit('CloseNewCircularDialog')">
                <v-icon large color="pink">mdi-close-circle</v-icon>
              </v-btn>
            </div>
          </div>
          <div id="form-validation" class="p-5">
            <v-stepper elevation="0" v-model="stepManager" vertical>
              <v-stepper-step :complete="stepManager > 1" step="1">
                Resolution Details
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="title"
                      counter="100"
                      hint="Max 100 characters"
                      label="Resolution Title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-select
                      v-model="organiser"
                      :items="loadedMemebrs"
                      attach
                      item-text="name"
                      item-value="id"
                      chips
                      label="Organiser / Finalizer:"
                    ></v-select>
                  </v-col>
                </v-row>

                <button
                  @click="stepManager = 2"
                  class="btn btn-primary w-24 ml-2"
                >
                  Continue
                </button>
              </v-stepper-content>

              <v-stepper-step :complete="stepManager > 2" step="2">
                Set resolution active time-frame
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="startDate"
                      v-model="startDate"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startVoteDate"
                          label="Voting will start: *"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startVoteDate"
                        no-title
                        scrollable
                        :min="date"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text @click="startDate = false"> Cancel </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-dialog ref="dialog1" v-model="modal1" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startTime"
                          label="Start Time *"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modal1"
                        v-model="startTime"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn text @click="modal1 = false"> Cancel </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>

                  <v-col v-if="startVoteDate" cols="12" sm="6" md="6">
                    <v-menu
                      ref="menuend"
                      v-model="menuend"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endVoteDate"
                          label="Voting will End *"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endVoteDate"
                        no-title
                        scrollable
                        :min="startVoteDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text @click="menuend = false"> Cancel </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col v-if="startVoteDate" cols="12" sm="6" md="6">
                    <v-dialog ref="dialog" v-model="modal2" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endTime"
                          label="End Time *"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker v-if="modal2" v-model="endTime" full-width>
                        <v-spacer></v-spacer>
                        <v-btn text @click="modal2 = false"> Cancel </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>

                <button
                  class="btn btn-secondary w-24"
                  text
                  @click="stepManager = 1"
                >
                  Cancel
                </button>

                <button
                  @click="stepManager = 3"
                  class="btn btn-primary w-24 ml-2"
                >
                  Continue
                </button>
              </v-stepper-content>

              <v-stepper-step step="3">
                Resolution Notifications
              </v-stepper-step>
              <v-stepper-content step="3">
                <Editor
                  :content="content"
                  :editorOption="editorOption"
                  style="height: 220px"
                  @onEditorChange="updateCircularMessage"
                />
                <button
                  class="btn btn-secondary w-24"
                  text
                  @click="stepManager = 1"
                >
                  Cancel
                </button>
              </v-stepper-content>
 

            </v-stepper>
            <v-card-actions v-if="stepManager == 3">
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                outlined
                small
                @click="updateCircularResolution"
              >
                <v-icon small>mdi-floppy</v-icon> Start Circular Resolution
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
      <DocumentViwer
        v-if="OpenfileDialog === true"
        :docValue="docValue"
        :OpenfileDialog="OpenfileDialog"
        :title="`View Attachment`"
        @CloseDocumentViwer="OpenfileDialog = false"
      />
    </div>
  </v-dialog>
</template>

<script>
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";
import Editor from "../e_board_shared/fields/Editor.vue";
import swal from "sweetalert2";
export default {
  props: ["OpenEditDialog", "resolution"],
  components: {
    DocumentViwer,
    Editor,
  },
  data() {
    return {
      stepManager: 1,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      OpenfileDialog: false,
      modal2: "",
      modal1: false,
      menuend: "",
      content: "",
      docValue: "",
      circularResolutionQuorum: "50",
      editorOption: {
        placeholder: "Enter the motion to be voted on",
        theme: "snow",
      },
      title: "",
      organiser: "",
      startVoteDate: "",
      startTime: "",
      endVoteDate: "",
      endTime: "",
      Greetings: "Hi",
      greetingname: "",
      circularBody: "",
      startDate: "",
      suportingDocs: "",
      reminderTimer: "",
      boadMemberList: [],
    };
  },
  computed: {
    loadedMemebrs() {
      var members = [];
      this.$store.state.MembersStore.boardMembers.forEach((member) => {
        members.push({
          id: member.id,
          name: member.firstName + " " + member.lastName,
        });
      });
      return members;
    },
  },
  watch: {
    loadedMemebrs: function () {
      var childrens = [];
      this.loadedMemebrs.forEach((members) => {
        childrens.push({
          id: members.id,
          name: members.firstName + " " + members.lastName,
        });
      });
      this.boadMemberList.push({
        id: 1,
        name: "Board Members(" + this.loadedMemebrs.length + ")",
        children: childrens,
      });
      this.overlay = false;
    },
  },
  methods: {

    assingValues(){
        this.title=this.resolution.resolutionTitle
        this.startVoteDate=this.resolution.resolutionStartDate
        this.startTime=this.resolution.resolutionStartTime
        this.endVoteDate=this.resolution.resolutionEndDate
        this.endTime=this.resolution.resolutionEndTime
        this.content=this.resolution.resolutionDescriptions
        this.circularBody=this.resolution.resolutionDescriptions
        this.circularResolutionQuorum=this.resolution
    },




    updateCircularMessage(value) {
      this.circularBody = value;
    },



    async updateCircularResolution() {
      await this.$store.dispatch("showLoadingSpinner", true);

      const resolution = {
        resolutionUniqueId: this.resolution.resolutionUniqueId,
        resolutionTitle: this.title,
        resolutionOrganisers: this.organiser,
        resolutionVoteStartDate: this.startVoteDate,
        resolutionVoteStartTime: this.startTime,
        resolutionVoteEndDate: this.endVoteDate,
        resolutionVoteEndTime: this.endTime,
        resolutionNoticeBody: this.circularBody,
        resolutionQuorum: this.circularResolutionQuorum,
      };

      await this.$store.dispatch("updateBoardResolutions", resolution);
          swal.fire({
            toast: true,
            icon: "success",
            title: "Circular Resolution updated successfully",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
      await this.$store.dispatch("loadBoardResolutions");
      this.$emit("CloseNewCircularDialog");
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
  async mounted() {
    await this.$store.dispatch("loadBoardMambers");
    this.assingValues()
  },
};
</script>
