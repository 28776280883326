import { apolloClient } from "@/vue-apollo";
import swal from "sweetalert2";
import getManagementMeetingsReports from "@/graphql/eboard-report-builder-graphql/getManagementMeetingsReports.gql";
import getManagementMeetingsDirectivesReports from "@/graphql/eboard-report-builder-graphql/getManagementMeetingsDirectivesReports.gql";
import getManagementMeetingsDecisionsReports from "@/graphql/eboard-report-builder-graphql/getManagementMeetingsDecisionsReports.gql";
import getManagementCommitteesReports from "@/graphql/eboard-report-builder-graphql/getManagementCommitteesReports.gql";

export default {
    state: {
        managementMeetingsReports: [],
        managementMeetingsDirectivesReports: [],
        managementMeetingsDecisionsReports: [],
        managementCommitteesReports: [],
    },

    mutations: {
        addManagementMeetingsReports(state, reports) {
            state.managementMeetingsReports = reports;
        },
        addManagementMeetingsDirectivesReports(state, reports) {
            state.managementMeetingsDirectivesReports = reports;
        },
        addManagementMeetingsDecisionsReports(state, reports) {
            state.managementMeetingsDecisionsReports = reports;
        },
        addManagementCommitteesReports(state, reports) {
            state.managementMeetingsDecisionsReports = reports;
        },

    },

    getters: {
        getManagementMeetingsReports(state) {
            return state.managementMeetingsReports;
        },
        getManagementMeetingsDirectivesReports(state) {
            return state.managementMeetingsDirectivesReports;
        },
        getManagementMeetingsDecisionsReports(state) {
            return state.managementMeetingsDecisionsReports;
        },
        getManagementCommitteesReports(state) {
            return state.managementMeetingsDecisionsReports;
        },
    },

    actions: {

        async loadManagementMeetingReports(context,filtering) {
            await apolloClient
                .query({
                    fetchPolicy: "no-cache",
                    query: getManagementMeetingsReports,
                    variables: {
                        filtering: filtering,
                    },
                })
                .then((response) => {
                    const results = response.data.getManagementMeetingsReports;
                    console.log("results")
                    console.log(results)
                    if (results.response.status) {
                        let meetingReports = results.data.map((report) => {
                            return {
                                meetingCategory: report.meetingCategory,
                                meetingsScheduled: report.meetingsScheduled,
                                meetingsConducted: report.meetingsConducted,
                                meetingsOnProgress: report.meetingsOnProgress,
                                meetingsAttendees: report.meetingsAttendees,
                                meetingsAbsentees: report.meetingsAbsentees,
                                totalDirectives: report.totalDirectives,
                                totalDecisions: report.totalDecisions,
                            }
                        })
                        context.commit("addManagementMeetingsReports", meetingReports);
                    } else {
                        swal.fire({
                            toast: true,
                            icon: "error",
                            title: results.response.message,
                            animation: false,
                            position: "top-right",
                            showConfirmButton: false,
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                });
        },

        async loadManagementMeetingDirectivesReports(context,filtering) {
            await apolloClient
                .query({
                    fetchPolicy: "no-cache",
                    query: getManagementMeetingsDirectivesReports,
                    variables: {
                        filtering: filtering,
                    },
                })
                .then((response) => {
                    const results = response.data.getManagementMeetingsDirectivesReports;
                    console.log("results")
                    console.log(results)
                    if (results.response.status) {
                        context.commit("addManagementMeetingsDirectivesReports", results.data);
                    } else {
                        swal.fire({
                            toast: true,
                            icon: "error",
                            title: results.response.message,
                            animation: false,
                            position: "top-right",
                            showConfirmButton: false,
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                });
        },

        async loadManagementMeetingDecisionsReports(context,filtering) {
            await apolloClient
                .query({
                    fetchPolicy: "no-cache",
                    query: getManagementMeetingsDecisionsReports,
                    variables: {
                        filtering: filtering,
                    },
                })
                .then((response) => {
                    const results = response.data.getManagementMeetingsDecisionsReports;
                    console.log("results")
                    console.log(results)
                    if (results.response.status) {
                        context.commit("addManagementMeetingsDecisionsReports", results.data);
                    } else {
                        swal.fire({
                            toast: true,
                            icon: "error",
                            title: results.response.message,
                            animation: false,
                            position: "top-right",
                            showConfirmButton: false,
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                });
        },

        async loadManagementCommitteesReports(context,filtering) {
            await apolloClient
                .query({
                    fetchPolicy: "no-cache",
                    query: getManagementCommitteesReports,
                    variables: {
                        filtering: filtering,
                    },
                })
                .then((response) => {
                    const results = response.data.getManagementCommitteesReports;
                    if (results.response.status) {
                        context.commit("addManagementCommitteesReports", results.data);
                    } else {
                        swal.fire({
                            toast: true,
                            icon: "error",
                            title: results.response.message,
                            animation: false,
                            position: "top-right",
                            showConfirmButton: false,
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                });
        },
    },
};
