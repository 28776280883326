<template>
  <div >
    <ManagementToolBar/>
    <div style="padding:20px">
      <router-view/>
    </div>
  </div>
</template>

<script>
import ManagementToolBar from '../modules/meeting_management/e_board_shared/navigations/ManagementToolBar.vue'

export default {
  name: 'ManagementHome',
  components: {
    ManagementToolBar,
  },
}
</script>


