<template>
  <v-row justify="center">
    <v-dialog v-model="isViewRoles" max-width="80%" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-seal</v-icon> User Access Levels
          </span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-expansion-panels popout>
                  <v-expansion-panel
                    v-for="item in permissiongroups"
                    :key="item.roleName"
                  >
                    <v-expansion-panel-header disable-icon-rotate>
                      <strong>{{ item.name }}</strong>
                      <template v-slot:actions>
                        <v-btn
                          fab
                          flat
                          icon
                          small
                          @click="editRolePermissions(item)"
                        >
                          <v-icon color="info"> mdi-pencil-circle </v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          flat
                          icon
                          small
                          @click="deleteUserRole(item.id)"
                        >
                          <v-icon color="error"> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <v-row>
                        <v-col
                          v-for="permission in item.children"
                          :key="permission.id"
                          md="3"
                          sm="3"
                        >
                          <v-list flat subheader three-line>
                            <v-list-item>
                              <v-checkbox
                                :key="permission.id"
                                :input-value="permission.name"
                                color="info"
                                disabled
                              >
                              </v-checkbox>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="permission.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="pink"
            fab
            small
            @click="$emit('closeViewRoles')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay" :z-index="zIndex">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <edit-role
      :OpenEditAccessForm="OpenEditAccessForm"
      :roleItem="roleItem"
      :permissionsList="permissionsList"
      :rolePermissions="rolePermissions"
      @CloseEditAccessForm="OpenEditAccessForm = false"
      @changePermission="changePermission"
      @changeRemainedPermission="changeRemainedPermission"
    >
    </edit-role>
  </v-row>
</template>

<script>
import rolesPerBoard from "../../../graphql/eboard-auth-graphql/rolesPerBoard.graphql";
import EditRole from "./EditRole.vue";
import swal from "sweetalert2";

export default {
  components: { EditRole },

  data: () => ({
    OpenEditAccessForm: false,
    roleItem: null,
    roleName: "",
    loading: false,
    zIndex: 1,
    overlay: false,
    boardUniqueId: localStorage.getItem("my-boardUniqueId") || "",
    boardKey: localStorage.getItem("my-boardKey") || "",
    existedRoles: "",
    permissions: "",
    is_clonned: false,
    openLocationDialog: false,
    time: null,
    menu4: false,
    menu3: false,
    modal2: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    Tittle: "",
    nameRules: [
      (v) => !!v || "Role Name is required",
      (v) => v.length <= 100 || "Name must be less than 10 characters",
    ],
    roles: [],
    permissiongroups: [],
    rolePermissions: [],
    permissionsList: [],
  }),

  props: ["isViewRoles"],

  watch: {
    isViewRoles() {
      if (this.isViewRoles) {
        this.get_roles_per_board();
      }
    }
  },

  methods: {
    changePermission(permission_id) {
      this.rolePermissions.forEach((permission) => {
        if (permission.permission_id === permission_id) {
          permission.checked = !permission.checked;
        }
      });
    },

    async get_roles_per_board() {
      await this.$store.dispatch("showLoadingSpinner", true);
      this.permissiongroups = [];
      this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: rolesPerBoard,
          variables: {
            boardUniqueId: localStorage.getItem("my-boardUniqueId"),
          },
        })
        .then((response) => {
          var groups_response = response.data.rolesPerBoard;
          groups_response.forEach((group) => {
            var group_id = group.roleUniqueId;
            var group_name = group.roleName;
            var group_children = [];

            group.userRoleWithPermissionRole.edges.forEach((children) => {
              group_children.push({
                id: children.node.roleWithPermissionPermission
                  .permissionUniqueId,
                name: children.node.roleWithPermissionPermission.permissionName,
              });
            });

            this.permissiongroups.push({
              id: group_id,
              name: group_name,
              children: group_children,
            });
          })
        }).finally(async () => {
        await this.$store.dispatch("showLoadingSpinner", false);
      })
    },

    changeRemainedPermission(permission_id) {
      this.permissionsList.forEach((permission) => {
        if (permission.permission_id === permission_id) {
          permission.checked = !permission.checked;
        }
      });
    },

    editRolePermissions(role) {
      try {
        this.rolePermissions = [];
        this.permissionsList = [];
        let allPermissions = [];

        role.children.forEach((item) => {
          this.rolePermissions.push({ permission_id: item.id, checked: true });
        });

        this.permissiongroups.forEach((permission_group) => {
          permission_group.children.forEach((permission) => {
            const permId = allPermissions.some((item) => {
              return item.id === permission.id;
            });
            if (!permId) {
              allPermissions.push(permission);
            }
          });
        });

        allPermissions.forEach((perm) => {
          const permExist = this.rolePermissions.some((item) => {
            return item.permission_id === perm.id;
          });

          if (!permExist) {
            this.permissionsList.push({
              permission_id: perm.id,
              checked: false,
              permission_name: perm.name,
            });
          }
        });

        this.roleItem = role;
        this.OpenEditAccessForm = true;
      } catch (e) {
        this.roleItem = role;
        this.OpenEditAccessForm = true;
      }
    },

    async creaNewRole() {
      this.overlay = true;
      this.loading = true;
      var rolesDetails = {
        rolePermissions: this.permissions,
        isCloned: this.is_clonned,
        roleName: this.roleName,
        roleBoard: localStorage.getItem("my-boardUniqueId"),
        roleClonedRoles: this.existedRoles,
        roleDescription: "",
      };
      await this.$store.dispatch("addNewRoles", rolesDetails);
    },

    async deleteUserRole(roleUniqueId) {
      await this.$store.dispatch("showLoadingSpinner", true);

      await this.$store.dispatch("deleteUserRole", roleUniqueId);
      this.get_roles_per_board();

      await swal.fire({
        toast: true,
        icon: "success",
        title: "Role deleted successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: false,
      });
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>
