<template>
  <v-container fluid>
    <v-row class="pt-3">
      <v-col class="d-flex align-content-center" cols="12" sm="12">
        <v-btn
          :to="'/management/management_directives_categories'"
          class="mr-3"
          color="primary white--text"
          link
          outlined
          right
          rounded
          small
        >
          <v-icon>mdi-arrow-left</v-icon>
          Back
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p>List of Directives Assigned in Reports.</p>
      </v-col>

      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          v-model="directiveObj.designationUniqueId"
          :items="boardDesignationList"
          dense
          item-text="designationShortForm"
          item-value="designationUniqueId"
          label="Designation *"
          @change="directiveObj.reportType=null; loadManagementReportsDirectives();"
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          v-model="directiveObj.reportType"
          :items="reportTypes"
          dense
          item-text="name"
          item-value="id"
          label="Report Type *"
          @change="directiveObj.designationUniqueId=null; loadManagementReportsDirectives();"
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined rounded small @click="openDownloadDirectivesModal">
        <v-icon> mdi-tray-arrow-down</v-icon>
        Download Directives
      </v-btn>
    </v-row>

    <v-row>
      <v-col sm="12">
        <v-card elevation="6">
          <v-tabs v-model="tab" color="#3f51b5" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon :color="tab.color" class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <ActionCard
                v-for="directive in weeklyReportDirectives.data"
                :key="directive.directiveUniqueId"
                :directive="directive"
              />
              <v-card-actions>
                <v-btn
                  @click="paginateManagementWeeklyDirectives(weeklyReportDirectives.page.previousPageNumber)"
                  v-if="weeklyReportDirectives.page.hasPreviousPage" outlined small
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  Previous Page ({{ weeklyReportDirectives.page.previousPageNumber }})
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  @click="paginateManagementCompletedWeeklyDirectives(weeklyReportDirectives.page.nextPageNumber)"
                  v-if="weeklyReportDirectives.page.hasNextPage" outlined small
                >
                  Next Page ({{ weeklyReportDirectives.page.nextPageNumber }})
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-tab-item>

            <v-tab-item>
              <ActionCard
                v-for="directive in completedWeeklyReportDirectives.data"
                :key="directive.directiveUniqueId"
                :directive="directive"
              />
              <v-card-actions>
                <v-btn
                  @click="paginateManagementWeeklyDirectives(completedWeeklyReportDirectives.page.previousPageNumber)"
                  v-if="completedWeeklyReportDirectives.page.hasPreviousPage" outlined small
                >
                  <v-icon left>mdi-arrow-left</v-icon>
                  Previous Page ({{ completedWeeklyReportDirectives.page.previousPageNumber }})
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  @click="paginateManagementCompletedWeeklyDirectives(completedWeeklyReportDirectives.page.nextPageNumber)"
                  v-if="completedWeeklyReportDirectives.page.hasNextPage" outlined small
                >
                  Next Page ({{ completedWeeklyReportDirectives.page.nextPageNumber }})
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <DownloadManagementDirectiveDocumentModal
      :showContextMenu="showContextMenu"
      @closeDownloadDirectiveModal="showContextMenu=false"
      :filterObj="filterObj"
    />
  </v-container>
</template>

<script>
import ActionCard from "./ManagementWeeklyReportsActionCard.vue";
import DownloadManagementDirectiveDocumentModal from "./DownloadManagementDirectiveDocumentModal.vue";

export default {
  components: {ActionCard, DownloadManagementDirectiveDocumentModal},

  data() {
    return {
      tab: true,
      tabs: [
        {title: "Current Actions", icon: "mdi-pin-outline", color: "orange"},
        {
          title: "Completed Actions",
          icon: "mdi-check-decagram-outline",
          color: "success",
        },
      ],
      filterOwner: '',
      showContextMenu: false,
      reportTypes: [
        {id: 'WEEKLY', name: 'Weekly Reports'},
        {id: 'MONTHLY', name: 'Monthly Reports'},
        {id: 'QUARTERLY', name: 'Quarterly Reports'},
        {id: 'ANNUALLY', name: 'Annual Reports'},
      ],
      filterObj: {
        reportType: "WEEKLY",
        designationUniqueId: null
      },
      directiveObj: {
        managementUniqueId: localStorage.getItem("my-managementUniqueId"),
        designationUniqueId: null,
        reportType: "WEEKLY",
        pageNumber: 1,
      },
      weeklyReportObj: {}
    };
  },

  computed: {
    managementsMembers() {
      const management_list = [];
      const all_mambers = this.$store.state.ManagementMembersStore.managementMembers;
      all_mambers.forEach((member) => {
        if (member.isLeader !== true) {
          management_list.push({
            name: member.firstName + " " + member.lastName,
            id: member.id,
          });
        }
      });
      management_list.push({name: "All Directives", id: "all"});
      return management_list;
    },

    weeklyReportDirectives() {
      return this.$store.getters.getManagementWeeklyAssignedDirectivesList;
    },

    completedWeeklyReportDirectives() {
      return this.$store.getters.getManagementWeeklyCompletedDirectivesList;
    },

    boardDesignationList() {
      let temp = [];
      temp = this.designationsList();
      temp.push({
        designationIsActive: true,
        designationName: "All designations",
        designationShortForm: "All",
        designationUniqueId: null,
        designation_owner: "",
        designation_owners: [],
      });
      return temp;
    }
  },

  methods: {
    async paginateManagementWeeklyDirectives(pageNumber) {
      this.directiveObj.pageNumber = pageNumber;
      await this.$store.dispatch("loadAssignedManagementWeeklyReportDirectives", this.directiveObj);
    },

    async paginateManagementCompletedWeeklyDirectives(pageNumber) {
      this.directiveObj.pageNumber = pageNumber;
      await this.$store.dispatch("loadManagementCompletedWeeklyReportDirectives", this.directiveObj);
    },

    openDownloadDirectivesModal() {
      this.filterObj.managementUniqueId = localStorage.getItem("my-managementUniqueId");
      this.filterObj.weeklyReportDirective = true;
      this.filterObj.showAssignee = false;
      this.filterObj.designationUniqueId = this.directiveObj.designationUniqueId;
      this.filterObj.reportType = this.directiveObj.reportType;
      this.showContextMenu = true;
    },

    async loadManagementReportsDirectives() {
      await this.$store.dispatch("loadAssignedManagementWeeklyReportDirectives", this.directiveObj);
      await this.$store.dispatch("loadManagementCompletedWeeklyReportDirectives", this.directiveObj);
    }

  },

  async mounted() {
    await this.loadManagementReportsDirectives();
    await this.$store.dispatch('loadManagementMembers');
  },
};
</script>

<style>
</style>
