<template>
  <v-container fluid>
    <v-card v-if="!hasHandoverDuties()">
      <v-tabs right>
        <v-tab style="text-transform: capitalize">
          <v-icon left>mdi-run-fast</v-icon>
          <v-badge
            :content="listofInvitation.length"
            :value="listofInvitation.length"
            color="green"
          >
            Meetings & Resolutions
          </v-badge>
        </v-tab>
        <v-tab style="text-transform: capitalize">
          <v-icon left>mdi-view-dashboard-outline</v-icon>
          Directives
        </v-tab>
        <v-tab style="text-transform: capitalize">
          <v-icon left>mdi-checkbox-multiple-outline</v-icon>Attendence & Users
        </v-tab>
        <v-tab
          v-if="hasAccess('can_view_update')"
          style="text-transform: capitalize"
        >
          <v-icon left>mdi-newspaper</v-icon>
          <v-badge :content="newsCount.length" :value="newsCount.length" color="orange">
            News & Updates
          </v-badge>
        </v-tab>

        <v-tab-item>
          <v-container fluid>
            <div class="content">
              <!-- BEGIN: Meeting Invitations -->
              <div
                v-if="listofInvitation.length > 0"
                class="col-span-12 md:col-span-6 xl:col-span-12 xl:col-start-1 xl:row-start-1 2xl:col-start-auto 2xl:row-start-auto mt-3"
              >
                <div class="intro-x flex items-center h-10">
                  <h2 class="text-lg font-medium truncate mr-auto">
                    Meeting Invitations
                  </h2>
                  <button
                    data-carousel="important-notes"
                    data-target="prev"
                    class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
                  >
                    <i data-lucide="chevron-left" class="w-4 h-4"></i>
                  </button>
                  <button
                    data-carousel="important-notes"
                    data-target="next"
                    class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
                  >
                    <i data-lucide="chevron-right" class="w-4 h-4"></i>
                  </button>
                </div>
                <div class="mt-5 intro-x">
                  <v-carousel
                    v-model="model"
                    cycle
                    hide-delimiters
                    show-arrows-on-hover
                  >
                    <div class="box">
                      <div class="tiny-slider" id="important-notes">
                        <v-carousel-item
                          v-for="notice in listofInvitation"
                          :key="notice.invitationUniqueId"
                        >
                          <InvitationCard
                            v-if="notice.invitationIsActive"
                            :invitation="notice"
                          />
                        </v-carousel-item>
                      </div>
                    </div>
                  </v-carousel>
                </div>
              </div>
              <!-- END: Important Notes -->

              <v-row>
                <v-col class="intro-y" cols="5" md="5">
                  <div class="grid grid-cols-12 gap-12">
                    <div class="col-span-12 2xl:col-span-9">
                      <div class="grid grid-cols-12 gap-12">
                        <div class="col-span-12 xl:col-span-12 mt-6">
                          <div class="intro-y flex items-center h-10">
                            <h2 class="text-lg font-medium truncate mr-5">
                              Meetings Summary
                            </h2>
                          </div>
                          <div class="mt-5">
                            <div class="intro-y">
                              <div
                                class="box px-4 py-4 mb-3 flex items-center zoom-in"
                              >
                                <div
                                  class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                                >
                                  <img
                                    alt="Midone - HTML Admin Template"
                                    src="@/assets/schedule.png"
                                  />
                                </div>
                                <div class="ml-4 mr-auto">
                                  <div class="font-medium">Scheduled</div>
                                  <div class="text-slate-500 text-xs mt-0.5">
                                    All of meetings schedude
                                  </div>
                                </div>
                                <div
                                  class="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium"
                                >
                                  {{ MeetingDashboard.scheduleMeetings }}
                                  Meetings
                                </div>
                              </div>
                            </div>
                            <div class="intro-y">
                              <div
                                class="box px-4 py-4 mb-3 flex items-center zoom-in"
                              >
                                <div
                                  class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                                >
                                  <img
                                    alt="Midone - HTML Admin Template"
                                    src="@/assets/calendar.png"
                                  />
                                </div>
                                <div class="ml-4 mr-auto">
                                  <div class="font-medium">Conducted</div>
                                  <div class="text-slate-500 text-xs mt-0.5">
                                    Total of meetings conducted
                                  </div>
                                </div>
                                <div
                                  class="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium"
                                >
                                  {{ MeetingDashboard.conductedMeetings }}
                                  Meetings
                                </div>
                              </div>
                            </div>
                            <div class="intro-y">
                              <div
                                class="box px-4 py-4 mb-3 flex items-center zoom-in"
                              >
                                <div
                                  class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                                >
                                  <img
                                    alt="Midone - HTML Admin Template"
                                    src="@/assets/time.png"
                                  />
                                </div>
                                <div class="ml-4 mr-auto">
                                  <div class="font-medium">Awaiting</div>
                                  <div class="text-slate-500 text-xs mt-0.5">
                                    Total of meetings awaiting
                                  </div>
                                </div>
                                <div
                                  class="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium"
                                >
                                  {{ MeetingDashboard.awaitngMeetings }}
                                  Meetings
                                </div>
                              </div>
                            </div>
                            <div
                              v-for="meetingType in MeetingDashboard.meetingsByTypes"
                              :key="'bord' + meetingType.value"
                              class="intro-y"
                            >
                              <div
                                class="box px-4 py-4 mb-3 flex items-center zoom-in"
                              >
                                <div
                                  class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                                >
                                  <img
                                    alt="Midone - HTML Admin Template"
                                    src="@/assets/application.png"
                                  />
                                </div>
                                <div class="ml-4 mr-auto">
                                  <div class="font-medium">
                                    {{ meetingType.name }} Only
                                  </div>
                                  <div class="text-slate-500 text-xs mt-0.5">
                                    {{ meetingType.name }} scheduded
                                  </div>
                                </div>
                                <div
                                  class="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium"
                                >
                                  {{ meetingType.value }} Meetings
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col class="intro-y" cols="7" md="7">
                  <div class="intro-y flex items-center h-10">
                    <h2 class="text-lg font-medium truncate mr-5">
                      Circular Resolutions Summary
                    </h2>
                  </div>
                  <v-layout justify-start wrap>
                    <v-card
                      class="ma-1"
                      width="49%"
                      color="rgba(240, 169, 177, 0.44)"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">Closed</div>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ ResolutionsDashboard.closedResolutions }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Total of circular resolutions closed
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="70">
                          <v-img src="@/assets/rejected.png"></v-img
                        ></v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                    <v-card
                      class="mx-auto ma-1"
                      width="49%"
                      color="rgba(76, 75, 74, 0.27)"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">Ongoing</div>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ ResolutionsDashboard.openResolutions }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Circular resolutions in progress
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="70">
                          <v-img src="@/assets/update.png"></v-img
                        ></v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                    <v-card
                      class="mx-auto ma-1"
                      width="49%"
                      color="rgba(169, 240, 227, 0.44)"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">Approved</div>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ ResolutionsDashboard.approvedResoluutions }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Circular resolutions in appproved by board
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="70">
                          <v-img src="@/assets/approval.png"></v-img
                        ></v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                    <v-card
                      class="mx-auto ma-1"
                      width="49%"
                      color="rgba(233, 233, 233, 0.37)"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">Not Approved</div>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ ResolutionsDashboard.notApprovedResoluutions }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Circular resolutions in rejected by board
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="70">
                          <v-img src="@/assets/rejected.png"></v-img
                        ></v-list-item-avatar>
                      </v-list-item>
                    </v-card>

                    <v-card
                      v-for="meetingType in MeetingDashboard.meetingsByTypes"
                      :key="'comm' + meetingType.value"
                      class="mx-auto ma-1"
                      width="49%"
                      color="rgba(234, 209, 249, 0.25)"
                      outlined
                    >
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            {{ meetingType.name }} Only
                          </div>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ meetingType.value }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Total {{ meetingType.name }} scheduded
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar tile size="70">
                          <v-img src="@/assets/application.png"></v-img
                        ></v-list-item-avatar>
                      </v-list-item>
                    </v-card>
                  </v-layout>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="intro-y" cols="6" md="6">
                  <MeetingCustomGraph
                    :graphTitle="'Meetings per Categories'"
                    :dataObject="MeetingDashboard.meetingsByCategories"
                  />
                </v-col>
                <v-col class="-intro-x" cols="6" md="6">
                  <MeetingCustomGraph
                    :graphTitle="'Meetings per Committees'"
                    :dataObject="MeetingDashboard.meetingsByCommitees"
                  />
                </v-col>
                <v-col class="-intro-x" cols="12" md="12">
                  <MeetingsByLocations :dataObject="MeetingDashboard" />
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="7" sm="12" xs="12" md="7">
                <ActionDistributions
                  :directivesByDesignations="DirectivesDashboard"
                />
              </v-col>
              <v-col cols="5" sm="12" xs="12" md="5">
                <div class="col-span-12 md:col-span-6 lg:col-span-4 mt-1">
                  <div class="intro-y box p-5 mt-12 sm:mt-1">
                    <div
                      class="flex text-slate-500 border-b border-slate-200 dark:border-darkmode-300 border-dashed pb-3 mb-3"
                    >
                      <div>Summary of my Board Directives</div>
                    </div>
                    <div
                      v-for="dirStatus in directives_status"
                      :key="dirStatus.actions"
                      class="flex items-center mb-5"
                    >
                      <div>{{ dirStatus.from }}</div>
                      <div class="ml-auto">
                        <div
                          class="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium"
                        >
                          {{ dirStatus.actions }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="5" sm="12" xs="12" md="12">
                <v-card width="100%">
                  <v-card-text>
                    <div class="font-weight-bold ml-8 mb-2">
                      Upcomming Deadlines
                    </div>
                    <ActionsInDeadLine
                      :upcomingDeadlines="DirectivesDashboard.upcomingDeadlines"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="8" sm="12" xs="12" md="8">
                <AttendenceChart :dataObject="AttendanceDashboard" />
              </v-col>
              <v-col cols="4" sm="12" xs="12" md="4">
                <v-row>
                  <v-col cols="12" sm="12" xs="12" md="12">
                    <v-card width="100%">
                      <v-card-text>
                        <div class="font-weight-bold ml-8 mb-2">
                          Over all board meeting attendance status
                        </div>

                        <v-timeline align-top dense>
                          <v-timeline-item
                            v-for="attendence in overAllSummary"
                            :key="attendence.time"
                            :color="attendence.color"
                            small
                          >
                            <div>
                              <div class="font-weight-normal">
                                <strong>{{ attendence.name }}</strong> =
                                {{ attendence.value }}
                              </div>
                            </div>
                          </v-timeline-item>
                        </v-timeline>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" xs="12" md="12">
                    <v-card width="100%">
                      <v-card-text>
                        <div class="font-weight-bold ml-8 mb-2">
                          Board Committees status
                        </div>

                        <v-timeline align-top dense>
                          <v-timeline-item
                            v-for="attendence in (BoardDashboard||{}).committeeStatus"
                            :key="attendence.time"
                            :color="attendence.color"
                            small
                          >
                            <div>
                              <div class="font-weight-normal">
                                <strong>{{ attendence.name }}</strong> =
                                {{ attendence.value }}
                              </div>
                            </div>
                          </v-timeline-item>
                        </v-timeline>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" sm="12" xs="12" md="4">
                <v-row>
                  <v-col class="intro-y" cols="12" md="12">
                    <div class="grid grid-cols-12 gap-12">
                      <div class="col-span-12 2xl:col-span-12">
                        <div class="grid grid-cols-12 gap-12">
                          <div class="col-span-12 xl:col-span-12 mt-6">
                            <div class="mt-5">
                              <div class="intro-y">
                                <div
                                  class="box px-4 py-4 mb-3 flex items-center zoom-in"
                                >
                                  <div
                                    class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                                  >
                                    <img
                                      alt="Midone - HTML Admin Template"
                                      src="@/assets/img/commitee.png"
                                    />
                                  </div>
                                  <div class="ml-4 mr-auto">
                                    <div class="font-medium">Board Members</div>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                      Number of Board Members
                                    </div>
                                  </div>
                                  <div
                                    class="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium"
                                  >
                                    {{
                                      BoardDashboard.numberOfManagementsMembers
                                    }}
                                  </div>
                                </div>
                              </div>
                              <div class="intro-y">
                                <div
                                  class="box px-4 py-4 mb-3 flex items-center zoom-in"
                                >
                                  <div
                                    class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden"
                                  >
                                    <img
                                      alt="Midone - HTML Admin Template"
                                      src="@/assets/people.png"
                                    />
                                  </div>
                                  <div class="ml-4 mr-auto">
                                    <div class="font-medium">
                                      Managements Members
                                    </div>
                                    <div class="text-slate-500 text-xs mt-0.5">
                                      Number of Managements Members
                                    </div>
                                  </div>
                                  <div
                                    class="py-1 px-2 rounded-full text-xs bg-success text-white cursor-pointer font-medium"
                                  >
                                    {{
                                      BoardDashboard.numberOfManagementsMembers
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="hasAccess('can_view_update')">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="12">
                <what-is-new></what-is-new>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <div
      class="pa-10 mt-10 border border-dotted border-slate-400 flex flex-col items-center justify-center"
      v-else
    >
      <h1 style="font-size: 30px" class="pa-10 mt-10 text-xl">
        You have hand over your duties on this board please return to office
        first.
      </h1>
      <v-btn @click="returnToOffice()" outlined color="primary"
        >Return to Office</v-btn
      >
    </div>

    <VideoCallSelection
      @endVideoCallDialog="startVideoCallDialog = false"
      :startVideoCallDialog="startVideoCallDialog"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import WhatIsNew from "./WhatIsNew.vue";
import AttendenceChart from "./AttendanceChart.vue";
import ActionDistributions from "./ActionDistributions.vue";
import ActionsInDeadLine from "./ActionsInDeadLine.vue";
import InvitationCard from "./InvitationCard.vue";
import MeetingsByLocations from "./MeetingsByLocations.vue";
import MeetingCustomGraph from "./MeetingCustomGraph.vue";
import VideoCallSelection from "../eboard-videochatting/VideoCallSelection.vue";

export default {
  components: {
    WhatIsNew,
    AttendenceChart,
    ActionDistributions,
    ActionsInDeadLine,
    InvitationCard,
    VideoCallSelection,
    MeetingsByLocations,
    MeetingCustomGraph,
  },
  data: () => ({
    startVideoCallDialog: false,
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    messages: [
      {
        from: "All Meeting Schedules",
        attended: `9`,
        apologies: `3`,
        time: "13",
        color: "deep-purple lighten-1",
      },
      {
        from: "Baord Meetings Only",
        attended: `4`,
        apologies: `2`,
        time: "6",
        color: "green",
      },
      {
        from: "Committees Meetings Only",
        attended: `4`,
        apologies: `3`,
        time: "7",
        color: "deep-purple lighten-1",
      },
    ],
    resolutions: [
      {
        title: "Budget",
        expired: "24/August/2022",
        color: "indigo",
      },
      {
        title: "Maadhimio ya Kununua Mfumo",
        expired: "24/September/2022",
        color: "red",
      },
      {
        title: "Kupitisha Uchaguzi wa Msaidizi wa Idara ya Tehama",
        expired: "11/December/2022",
        color: "pink",
      },
    ],

    // newsCount: this.$store.getters.boardNewsPublishedList
  }),

  computed: {
    ...mapGetters({
      MeetingDashboard: "getMeetingDashboard",
      DirectivesDashboard: "getDirectivesDashboard",
      ResolutionsDashboard: "getResolutionsDashboard",
      AttendanceDashboard: "getAttendanceDashboard",
      BoardDashboard: "getBoardDashboard",
    }),
    listofInvitation() {
      var invitations = [];
      this.$store.state.InvitationStore.invitationList.forEach((invitation) => {
        if (invitation.invitationIsActive == true) {
          invitations.push(invitation);
        }
      });
      return invitations;
    },
    directives_status() {
      return [
        {
          from: "Issued Directives",
          actions: this.DirectivesDashboard.issuedDirectives,
          color: "deep-purple lighten-0",
        },
        {
          from: "Completed Directives",
          actions: this.DirectivesDashboard.completedDirectives,
          color: "success",
        },
        {
          from: "In Progress Actions",
          actions: this.DirectivesDashboard.inProgressDirectives,
          color: "primary lighten-1",
        },
      ];
    },
    overAllSummary(){
      return this.AttendanceDashboard.overallBoardAttendance
    },

    newsCount(){
      return this.$store.getters.boardNewsPublishedList
    }  

  },

  methods: {
    callVideoControlDialog() {
      this.startVideoCallDialog = true;
    },
    openNewChattingScreen() {
      let height = window.outerHeight;
      var actualSize = height * (90 / 100);
      var DifferentWindow = window.open(
        "https://oxygen.co.tz/#/home",
        "e-Board Chatting",
        "top=200,bottom=300,left=500,width=" + actualSize + ",height=600"
      );

      DifferentWindow.localStorage.setItem(
        "mx_hs_url",
        "https://app.oxygen.co.tz	"
      );
      DifferentWindow.localStorage.setItem("mx_is_url", "https://vector.im");
      DifferentWindow.localStorage.setItem(
        "mx_user_id",
        "@eboarduser:app.oxygen.co.tz"
      );
      DifferentWindow.localStorage.setItem("mx_is_guest", false);
      DifferentWindow.localStorage.setItem("mx_has_access_token", true);
      DifferentWindow.localStorage.setItem("mx_has_pickle_key", true);
      DifferentWindow.localStorage.setItem("mx_device_id", "XYDRGIDZMK");
      DifferentWindow.localStorage.setItem("mx_crypto_initialised", true);
    },

    async returnToOffice() {
      await this.$store.dispatch("showLoadingSpinner", true);

      var handingOverUser=this.$store.getters["AuthStore/user"].username
      await this.$store.dispatch("RolesStore/returnToOffice",handingOverUser,{
        root: true,
      });
      await this.$store.dispatch("RolesStore/loadUserPermissionsByBoard", {
        root: true,
      });

      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },

  async mounted() {
    await this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("RolesStore/loadUserPermissionsByBoard", {
      root: true,
    });

    await this.$store.dispatch("loadBoardInvitations");
    await this.$store.dispatch("loadAllDashboard");
    await this.$store.dispatch("loadMyResolutionNotifications");
    await this.$store.dispatch("SettingsStore/loadInstitutionDetails", {
      root: true,
    });

    await this.$store.dispatch('loadBoardPublishedNewsUpdates')

    await this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>
<style lang="scss" scoped>
img {
  position: relative;
  display: block;
  float: left;
  width: 100px;
  height: 120px;
}
</style>
