<template>
  <v-dialog v-model="openInterestRegisterWindow" max-width="60%" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          <v-icon color="indigo">mdi-cards-heart</v-icon> Declare Conflict of
          Interest</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row
            >
            <v-col cols="12" sm="12" md="12">
              <v-select
                v-model="interestedAgendaItems"
                :items="agendaItemsList"
                multiple
                item-text="name"
                item-value="id"
                label="Select Agenda Item from list of meeting Agenda"
                dense
              ></v-select>
            </v-col>
            <v-col v-if="(boardSetting()||{}).showConflictedOrganization" cols="12" sm="12" md="12">
              <v-text-field
              
                v-model="interestOrganisation"
                label="Organisation"
                hint="This field is optional"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="interestNature"
                label="Nature of Interest*"
                hint=""
                persistent-hint
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="pink"
          class="white--text"
          small
          fab
          @click="closeNewInterestFormDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          color="indigo"
          class="white--text"
          small
          fab
          :loading="interestLoading"
          @click="registerNewInterest"
        >
          <v-icon>mdi-floppy</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["openInterestRegisterWindow", "meetingUniqueId", "agendaItems"],
  data() {
    return {
      interestedAgendaItems: [],
      interestLoading: false,
      interestAgendaItem: null,
      interestOrganisation: " ",
      interestNature: null,
    };
  },
  computed: {
    agendaItemsList() {
      var temp_agenda_item = [];
      this.agendaItems.forEach((agenda) => {
        agenda.AgendaItems.forEach((item) => {
          temp_agenda_item.push({
            id: item.itemKey,
            name: item.itemName + " (" + agenda.Agenda + ")",
          });
        });
      });
      return temp_agenda_item;
    },
  },
  methods: {
    async registerNewInterest() {
      this.$store.dispatch("showLoadingSpinner", true);
      var noticeReceivers = [];
      this.interestedAgendaItems.forEach((item) => {
        noticeReceivers.push({
          itemUniqueId: item,
        });
      });

      var data = {
        declarationMeeting: this.meetingUniqueId,
        declarationOrganization: this.interestOrganisation,
        declarationInterest: this.interestNature,
        selectedAgendaItems: noticeReceivers,
      };
      await this.$store.dispatch("declareAgendaConflictOfInterest", data);

      this.interestOrganisation=" "
      this.interestNature=" "
      this.interestedAgendaItems=[]

      this.$emit("closeNewInterestFormDialog");
      this.$store.dispatch("showLoadingSpinner", false);
    },
    closeNewInterestFormDialog() {
      this.$emit("closeNewInterestFormDialog");
    },
  },
};
</script>
