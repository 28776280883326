<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col class="text-lg "> List of all Management Committees</v-col>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="addNewManagementCommittee"
              v-bind="attrs"
              v-on="on"
              color="indigo"
              class="mx-2"
              dark
              fab
              small
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn
          >

        </template>
        <span>New Management Committee</span>
      </v-tooltip>
    </v-row>


    <div class="mt-8 row">

      <div class="col-md-6 m-2" v-for="managementCommittee of managementCommittees"
           :key="managementCommittee.id">
        <div class="flex flex-column bg-white shadow-sm p-3">
          <div class="flex w-100 items-center">
            <icon name="management_committee" width="w-12" height="h-12"></icon>
            <div class="row ml-2">
              <div class="col-md-4 flex-column">
                <h2 class="font-weight-medium">
                  {{ managementCommittee.committeeName }}
                </h2>
                <span class="">
              {{ managementCommittee.committeeDescriptions }}
            </span>

                <div>
                  <div class="text-sm">
                    <span class="text-fade">ChairPerson:</span> <span
                      class="font-bold">{{ valueChecker(managementCommittee.committeeChairperson) }}</span>
                  </div>
                </div>

                <div>
                  <v-btn
                      v-if="!managementCommittee.committeeIsDeactivated"
                      color="rgba(15, 201, 98, 0.11)"
                      depressed
                      rounded
                      x-small
                  >
                    <v-icon x-small color="success">mdi-check</v-icon>
                    <span class="text-capitalize success--text"
                    >Active with {{ managementCommittee.committeeMembers.length }} Members</span
                    >
                  </v-btn>

                  <v-btn
                      v-else
                      color="rgba(175, 11, 11, 0.16)"
                      depressed
                      rounded
                      x-small
                  >
                    <v-icon x-small color="warning">mdi-check</v-icon>
                    <span class="text-capitalize warning--text"
                    >In-Active since {{
                        new Date(managementCommittee.committeeDeactivatedDate).toDateString()
                      }}
              </span
              >
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="flex justify-end">

              <v-menu offset-y app>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      slot="activator"
                      icon
                      color="indigo"
                  >
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn
                  >
                </template>
                <v-list dense>
                  <v-list-item
                      link
                      to=""
                      @click="editCommitteeDetails(managementCommittee)"
                  >
                    <v-list-item-title
                    >
                      <v-icon style="height: 16px; font-size: 16px"
                      >mdi-book-edit-outline
                      </v-icon
                      >
                      Edit Committee
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                      link
                      to=""
                      @click="
                              viewCommitteeMembers(managementCommittee)
                            "
                  >
                    <v-list-item-title
                    >
                      <v-icon style="height: 16px; font-size: 16px">mdi-account-multiple-outline
                      </v-icon
                      >
                      View Members
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                      v-if="!managementCommittee.committeeIsDeactivated"
                      @click="deactivateCommittee(managementCommittee)"
                  >
                    <v-list-item-title
                    >
                      <v-icon
                          color="error"
                          style="height: 16px; font-size: 16px"
                      >mdi-block-helper
                      </v-icon
                      >
                      Deactivate
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item
                      v-else
                      @click="activateCommittee(managementCommittee)"
                  >
                    <v-list-item-title
                    >
                      <v-icon
                          color="success"
                          style="height: 16px; font-size: 16px"
                      >mdi-check
                      </v-icon
                      >
                      Activate
                    </v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>

        </div>
      </div>

    </div>

    <add-new-management-committee :newManagementCommittee="newManagementCommittee"
                                  @closeManagementCommitteeDialog="newManagementCommittee = false"></add-new-management-committee>
    <edit-management-committee :newManagementCommittee="editManagementCommittee"
                               :committee-name="committeeName" :committee-descriptions="committeeDescriptions"
                               :management-committee-members="managementCommitteeMembers"
                               :add-member-now="addMemberNow"
                               :committee-unique-id="committeeUniqueId"
                               @closeEditManagementCommitteeDialog="editManagementCommittee = false"></edit-management-committee>

    <view-management-committee-members :view-management-committee-members="viewManagementCommitteeMembers"
                                       :management-committee-members="managementCommitteeMembers"
    @closeViewManagementCommitteeMembers="viewManagementCommitteeMembers = false"></view-management-committee-members>

  </v-container>
</template>

<script>
import Icon from "@/views/Icon.vue";
import AddNewManagementCommittee from "./AddNewManagementCommittee.vue";
import EditManagementCommittee from "./EditManagementCommittee.vue";
import ViewManagementCommitteeMembers from "./ViewManagementCommitteeMembers.vue";

export default {
  name: "ManagementCommittee",
  components: {ViewManagementCommitteeMembers, EditManagementCommittee, AddNewManagementCommittee, Icon},
  data() {
    return {
      viewManagementCommitteeMembers: false,
      newManagementCommittee: false,
      editManagementCommittee: false,
      committeeUniqueId: '',
      committeeName: '',
      committeeDescriptions: '',
      addMemberNow: false,
      managementCommitteeMembers: [{memberUserId: '', memberLevel: ''}]
    }
  },

  methods: {
    editCommitteeDetails(managementCommittee) {
      this.committeeUniqueId = managementCommittee.id
      this.committeeName = managementCommittee.committeeName
      this.committeeDescriptions = managementCommittee.committeeDescriptions
      this.managementCommitteeMembers = managementCommittee.committeeMembers.map((member) => {
        return {...member, memberUserId: member.id}
      })
      this.addMemberNow = managementCommittee.committeeMembers.length !== 0


      this.editManagementCommittee = true
    },
    addNewManagementCommittee() {
      this.newManagementCommittee = true
    },
    viewCommitteeMembers(managementCommittee) {
      this.viewManagementCommitteeMembers = true
      this.managementCommitteeMembers = managementCommittee.committeeMembers
    },
    deactivateCommittee(managementCommittee) {
      let input = {
        committeeUniqueId: managementCommittee.id
      }
      this.$store.dispatch('deactivateManagementCommitteeMutation', input)
    },
    activateCommittee(managementCommittee) {
      let input = {
        committeeUniqueId: managementCommittee.id
      }
      this.$store.dispatch('activateManagementCommitteeMutation', input)
    }
  },

  async mounted() {
    await this.$store.dispatch('loadManagementCommittees')
  },
  computed: {
    managementCommittees() {
      return this.$store.getters.getManagementCommittees;
    }
  }
}
</script>

<style scoped>

</style>