<template>
  <div >
    <BoardCommitteeToolBar />
    <div style="padding:20px">
      <router-view/>
    </div>
  </div>
</template>

<script>
import BoardCommitteeToolBar
  from "@/modules/board_committee/board_committee_shared/BoardCommitteeToolBar.vue";

export default {
  name: "BoardCommitteeHome",
  components: {
    BoardCommitteeToolBar
  }
}
</script>

<style scoped>

</style>
