var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('p')]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.pathList}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"5"}}),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"right":"","fab":"","small":"","icon":"","outlined":"","color":"primary white--text"},on:{"click":function($event){return _vm.callToCreateFolders(_vm.folderName, false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-folder-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Add New Folder")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"right":"","fab":"","small":"","icon":"","outlined":"","color":"primary white--text"},on:{"click":function($event){_vm.createFolderDialog = !_vm.createFolderDialog}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-upload-outline")])],1)]}}])},[_c('span',[_vm._v("Upload New Documents")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-layout',{attrs:{"justify-start":"","wrap":""}},_vm._l((_vm.folderAndFilesList),function(folder){return _c('v-card',{key:folder.primaryKey,staticStyle:{"margin":"5px"},attrs:{"max-width":"344","link":"","to":'/eboard_governance_repository/' + folder.folderUniqueId,"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',{attrs:{"tile":"","size":"90"}},[_c('v-img',{attrs:{"lazy-src":require("@/assets/folder_lazy.png"),"max-height":"100%","max-width":"100%","src":require("@/assets/folder.png")}})],1),_c('v-list-item-content',[_c('div',{staticClass:"text"},[_vm._v(_vm._s(folder.folderName))]),_c('v-list-item-subtitle',[_c('strong',[_vm._v("Files:")]),_vm._v(" "+_vm._s(folder.listOfFiles.length)+" "),_c('br'),_c('strong',[_vm._v("Created:")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(folder.folderDate))+" ")])],1),_c('v-menu',{attrs:{"offset-x":"","top":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.callToCreateFolders(
                      folder.folderName,
                      false,
                      folder.primaryKey
                    )}}},[_c('v-item-text',[_c('v-icon',[_vm._v("mdi-folder-plus-outline")]),_vm._v(" Create New Folder ")],1)],1),_c('v-list-item',{attrs:{"dense":"","link":""}},[_c('v-item-text',[_c('v-icon',[_vm._v("mdi-cloud-upload-outline")]),_vm._v(" Upload File ")],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"dense":"","link":""}},[_c('v-item-text',[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Delete Folder ")],1)],1)],1)],1)],1)],1)}),1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_vm._v(" Files List ")]),_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"100%","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_vm._v("My Annotation")]),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Headline 5 ")]),_c('v-list-item-subtitle',[_vm._v("Greyhound divisely hello coldly fonwderfully")])],1),_c('v-list-item-avatar',{attrs:{"tile":"","size":"80"}},[_c('v-img',{attrs:{"src":require("@/assets/note.png")}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","rounded":"","text":""}},[_vm._v(" Button ")])],1)],1),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":15,"total-visible":7,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('CreateNewFolder',{attrs:{"parentFolderKey":_vm.parentFolderKey,"isRoot":_vm.isRoot,"parentFolder":_vm.parentFolderName,"createFolderDialog":_vm.createFolderDialog},on:{"CloseCreateFolderDialog":function($event){_vm.createFolderDialog = false}}}),(_vm.OpenfileDialog == true)?_c('DocumentViwer',{attrs:{"docValue":_vm.docValue,"OpenfileDialog":_vm.OpenfileDialog,"title":"View Governance Repository Document"},on:{"CloseDocumentViwer":function($event){_vm.OpenfileDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }