<template>
  <v-container>
    <v-system-bar window height="70" class="mt-7 mb-7">
      <v-icon>mdi-magnify</v-icon>
      <span style="font-size: 20px">Filters</span>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="3">
          <v-menu
            ref="startmenu"
            v-model="startmenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                v-model="start_date"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                class="mt-5"
                @change="end_date = ''"
              ></v-text-field>
            </template>
            <v-date-picker v-model="start_date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="startmenu = false">
                Cancel
              </v-btn>
<!--              <v-btn-->
<!--                text-->
<!--                color="primary"-->
<!--                @click="$refs.startmenu.save(start_date)"-->
<!--              >-->
<!--                OK-->
<!--              </v-btn>-->
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-menu
            ref="endmenu"
            v-model="endmenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                dense
                v-model="end_date"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                class="mt-5"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="end_date"
              :min="start_date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="endmenu = false">
                Cancel
              </v-btn>
<!--              <v-btn text color="primary" @click="$refs.endmenu.save(end_date)">-->
<!--                OK-->
<!--              </v-btn>-->
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            class="mt-5"
            outlined
            dense
            :items="logTypes"
            label="Logs Type"
            v-model="logs_type"
          ></v-select>
        </v-col>
      </v-row>
      <v-btn @click="filterLogs()" class="ml-2" small outlined>
        Search <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-system-bar>
    <v-row class="fill-height">
      <v-col>
        <v-data-table
          class=""
          item-key="id"
          :headers="headers"
          :items="auditTrails"
        >
          <template v-slot:item.actionTaken="{ item }">
            <v-chip :color="getChipColor(item.actionTaken)" class="white--text">
              {{ item.actionTaken }}
            </v-chip>
          </template>
          <template v-slot:item.actionTimestap="{ item }">
            <span>{{ item.actionTimestap | formatFull }}</span>
          </template>
          <template v-slot:item.more="{ item }">
            <v-btn
              title="View More Logs"
              @click="viewMoreLogs(item)"
              icon
              fab
              x-small
              outlined
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="showMoreDialog" width="60%">
      <v-card>
        <v-system-bar height="40">
          <v-icon>mdi-eye</v-icon>
          <span style="font-size: 16px">View In Depth Logs</span>
          <v-spacer></v-spacer>
          <v-icon color="pink" @click="showMoreDialog = false"
            >mdi-close</v-icon
          >
        </v-system-bar>

        <v-divider></v-divider>

        <v-card-text>
          <div class="overflow-x-auto mt-7">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">#</th>
                  <th class="whitespace-nowrap">Affected Field</th>
                  <th class="whitespace-nowrap">Change From</th>
                  <th class="whitespace-nowrap">To</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(change, index) in changeLogs"
                  :key="change.changedField + index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ change.changedField }}</td>
                  <td>{{ change.changeFrom }}</td>
                  <td>{{ change.changeTo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "sweetalert2";

export default {
  components: {},

  data() {
    return {
      logTypes: ["Activity", "Authentication"],
      logs_type: "Activity",
      showMoreDialog: false,
      startmenu: "",
      endmenu: "",
      start_date: "",
      end_date: "",
      headers: [
        { text: "Rec. NO", value: "id" },
        { text: "Log No", value: "logNumber" },
        {
          text: "Person Involved",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "Action Taken", value: "actionTaken" },
        { text: "Designation/Position", value: "userPosition" },
        { text: "Username", value: "userName" },
        { text: "Timestamp", value: "actionTimestap" },
        { text: "Affected Entity", value: "affectedEntity" },
        { text: "Actions", value: "more" },
      ],
      changeLogs: [],
    };
  },

  computed: {
    auditTrails() {
      return this.$store.getters.getAuditTrail;
    },
  },

  methods: {
    viewMoreLogs(itemLog) {
      this.changeLogs = itemLog.changesLogs;
      this.showMoreDialog = true;
    },
    getChipColor(action) {
      if (action == "CREATE") {
        return "success";
      } else if (action == "UPDATE") {
        return "indigo";
      } else {
        return "pink";
      }
    },

    async filterLogs() {
      await this.$store.dispatch("showLoadingSpinner", true);
      if (this.start_date == "" || this.end_date == "") {
        swal.fire({
          toast: true,
          icon: "error",
          title: "Make sure  provided date range",
          animation: false,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      } else {
        var filtering = {
          timestampStartDate: this.start_date,
          timestampEndDate: this.end_date,
          logsType: this.logs_type,
          boardUniqueId: localStorage.getItem("my-boardUniqueId"),
        };
        await this.$store.dispatch("loadBoardAuditTrails", filtering);
        await this.$store.dispatch("showLoadingSpinner", false);
      }
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardAuditTrails");
  },
};
</script>
