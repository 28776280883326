<template>
  <div style="background: rgba(39, 24, 245, 0.06)" class="dashboard rounded-md">
    <v-container class="my-1">
      <div
        v-if="egandaItems.length > 0"
        class="mt-5 relative before:block before:absolute before:w-px before:h-[85%] before:bg-slate-200 before:dark:bg-darkmode-400 before:ml-5 before:mt-2"
      >
        <div
          v-for="item in egandaItems"
          :key="'__k' + item.itemKey"
          class="intro-x relative flex items-center mb-3"
        >
          <div
            class="before:block before:absolute before:w-20 before:h-px before:bg-slate-200 before:dark:bg-darkmode-400 before:mt-5 before:ml-3"
          >
            <div
              class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden"
            >
              <img alt="Agenda" src="@/assets/img/agenda.png" />
            </div>
          </div>
          <div
            @click="getAgendaItemDetails(agendaId + '.' + item.itemNumber);updateSelectedAgendaItem(item);"
            class="box px-5 py-3 ml-2 flex-1 zoom-in"
        
          >
            <div class="flex items-center">
              <div class="font-medium">
                {{ item.itemNumber }} . {{ item.itemName }}
              </div>
              <div class="text-xs text-slate-500 ml-auto">
                <v-menu v-if="hasAccess('can_schedule_meeting') && !HasPublishedAgenda" app offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      slot="activator"
                      color="indigo"
                      icon
                      v-bind="attrs"
                      @click="onClickButton"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item  link to="">
                      <v-list-item-title
                        @click="updateSelectedAgendaItem(item)"
                      >
                        <v-icon style="height: 16px; font-size: 16px"
                          >mdi-square-edit-outline</v-icon
                        >
                        Edit Agenda Item
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      @click="moveAgendaItem(item.itemKey, 'UP')"
                    >
                      <v-list-item-title>
                        <v-icon style="height: 16px; font-size: 16px"
                          >mdi-arrow-up</v-icon
                        >
                        Move it Up
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      @click="moveAgendaItem(item.itemKey, 'DOWN')"
                    >
                      <v-list-item-title>
                        <v-icon style="height: 16px; font-size: 16px"
                          >mdi-arrow-down</v-icon
                        >
                        Move it Down
                      </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item
                      link
                      @click="addAgendaItemToSpecificPosition(item.itemKey)"
                    >
                      <v-list-item-title>
                        <v-icon style="height: 16px; font-size: 16px"
                          >mdi-plus</v-icon
                        >
                        New Item Below
                      </v-list-item-title>
                    </v-list-item> -->
                    <v-divider></v-divider>
                    <v-list-item
                      link
                      @click="
                        deleteAgendaItem(
                          agendaKey,
                          item.itemKey,
                          currentMeeting
                        )
                      "
                    >
                      <v-list-item-title>
                        <v-icon style="height: 16px; font-size: 16px"
                          >mdi-delete</v-icon
                        >
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <span v-else>
                  <small class="success--text">Published</small>
                  
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>


      <p v-else>
        There are no Agenda Items for this Section. Select an option bellow and
        add one
      </p>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["egandaItems", "agendaId", "agendaKey", "currentMeeting","HasPublishedAgenda"],

  data() {
    return {
      expanded: [0],
    };
  },

  methods: {
    async addAgendaItemToSpecificPosition(itemUniqueId) {
      await this.$store.dispatch("addAgendaItemToSpecificPosition", {
        itemUniqueId: itemUniqueId,
      });
      await this.$store.dispatch(
        "loadBestPracticeAgendaTemplates",
        this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },

    async moveAgendaItem(itemUniqueId, position) {
      await this.$store.dispatch("moveMeetingAgendaItem", {
        itemUniqueId: itemUniqueId,
        position: position,
      });
      await this.$store.dispatch(
        "loadBestPracticeAgendaTemplates",
        this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },

    deleteAgendaItem(agendaKey, itemKey, currentMeeting) {
      const agendaObj = {
        agendaKey: agendaKey,
        itemUniqueId: itemKey,
      };
      this.$store.dispatch("deleteAgendaItem", agendaObj);
      this.$store.dispatch("loadBestPracticeAgendaTemplates", currentMeeting);
    },

    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },

    onClickButton() {
      this.$emit("lockScroll", "someValue");
    },

    getAgendaItemDetails(ItemId) {
      this.$emit("getAgendaItemDetails", ItemId);
    },

    async updateSelectedAgendaItem(item) {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadAllDecisionMinutes", item.itemKey);
      await this.$store.dispatch("loadAllDirectiveMinutes", item.itemKey);
      await this.$store.dispatch("loadAllMinuteNotes", item.itemKey);
      await this.$store.dispatch("loadAllCustomMinuteSections", item.itemKey);
      await this.$store.dispatch("updateSelectedAgendaItem", item);
      await this.$store.dispatch("loadMembersInterests");

      if (item.itemType === "Vote") {
        const new_item = this.$store.state.AgendasStore.selecteAgenda;
        await this.$store.dispatch("fetchVoteResultsFromServer",new_item.itemVoteDetails.edges[0].node.voteUniqueId );
      }
      this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>

<style>
.agenda.complete {
  border-left: 4px solid #3cd1c2;
}

.agenda.ongoing {
  border-left: 4px solid #ffaa2c;
}

.agenda.blocked {
  border-left: 4px solid #3e73f8;
}

.agenda.overdue {
  border-left: 4px solid #f83e70;
}

.v-chip.complete {
  background: #3cd1c2;
}

.v-chip.ongoing {
  background: #ffaa2c;
}

.v-chip.overdue {
  background: #f83e70;
}
</style>
