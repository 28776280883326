var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.showEditEvent),callback:function ($$v) {_vm.showEditEvent=$$v},expression:"showEditEvent"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_c('v-icon',[_vm._v("mdi-calender ")]),_vm._v(" Edit Board Event ")],1)]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.nameRules,"counter":100,"label":"Event Name *","required":"","prepend-icon":"mdi-calendar-star"},model:{value:(_vm.eventName),callback:function ($$v) {_vm.eventName=$$v},expression:"eventName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.nameRules,"counter":100,"label":"Event Descriptions *","required":"","prepend-icon":"mdi-text-recognition"},model:{value:(_vm.eventDescription),callback:function ($$v) {_vm.eventDescription=$$v},expression:"eventDescription"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date *","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.eventStartDate),callback:function ($$v) {_vm.eventStartDate=$$v},expression:"eventStartDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.date},model:{value:(_vm.eventStartDate),callback:function ($$v) {_vm.eventStartDate=$$v},expression:"eventStartDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startDateMenu = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-dialog',{ref:"dialog1",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Time *","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.eventStartTime),callback:function ($$v) {_vm.eventStartTime=$$v},expression:"eventStartTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimeModal),callback:function ($$v) {_vm.startTimeModal=$$v},expression:"startTimeModal"}},[(_vm.startTimeModal)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.eventStartTime),callback:function ($$v) {_vm.eventStartTime=$$v},expression:"eventStartTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startTimeModal = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-menu',{ref:"endDateMenu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date *","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.eventEndDate),callback:function ($$v) {_vm.eventEndDate=$$v},expression:"eventEndDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":_vm.eventStartDate},model:{value:(_vm.eventEndDate),callback:function ($$v) {_vm.eventEndDate=$$v},expression:"eventEndDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endDateMenu = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Time *","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.eventEndTime),callback:function ($$v) {_vm.eventEndTime=$$v},expression:"eventEndTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimeModal),callback:function ($$v) {_vm.endTimeModal=$$v},expression:"endTimeModal"}},[(_vm.endTimeModal)?_c('v-time-picker',{attrs:{"min":_vm.eventStartTime,"full-width":""},model:{value:(_vm.eventEndTime),callback:function ($$v) {_vm.eventEndTime=$$v},expression:"eventEndTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endTimeModal = false}}},[_vm._v(" Cancel ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-combobox',{attrs:{"rules":_vm.colorRules,"dense":"","filter":_vm.filter,"items":_vm.items,"re":"","label":"Select event color","small-chips":"","outlined":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v("Create")]),_c('v-chip',{attrs:{"color":((_vm.colors[_vm.nonce - 1]) + " lighten-3"),"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","text":"","dense":"","clearable":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.eventColor),callback:function ($$v) {_vm.eventColor=$$v},expression:"eventColor"}}):_c('v-chip',{attrs:{"color":((item.color) + " lighten-3"),"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-spacer')]}}]),model:{value:(_vm.eventColor),callback:function ($$v) {_vm.eventColor=$$v},expression:"eventColor"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-switch',{attrs:{"inset":"","color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Send Reminder ")]},proxy:true}]),model:{value:(_vm.sendReminder),callback:function ($$v) {_vm.sendReminder=$$v},expression:"sendReminder"}})],1),(_vm.sendReminder)?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":"Reminder Message","outlined":""},model:{value:(_vm.reminderMessage),callback:function ($$v) {_vm.reminderMessage=$$v},expression:"reminderMessage"}})],1):_vm._e()],1)],1),_c('v-divider')],1),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"flex justify-end text-white"},[_c('v-btn',{staticClass:"text-capitalize mr-2",attrs:{"color":"red","right":"","small":""},on:{"click":_vm.closeDialog}},[_c('icon',{attrs:{"name":"close","width":"w-5","height":"h-5","color":"#ffffff"}}),_c('span',{staticClass:"text-white"},[_vm._v("Cancel")])],1),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","disabled":_vm.saveDisabled,"right":"","small":""},on:{"click":_vm.editBoardEvent}},[_c('icon',{attrs:{"name":"save","width":"w-5","height":"h-5","color":"#ffffff"}}),_vm._v(" Save ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }