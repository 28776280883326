<template>
  <v-row justify="center">
    <v-dialog
        v-model="editInstitutionAdmin"
        max-width="70%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <icon class="pt-1 mr-3" name="institution" width="w-5" height="h-5" color="#000"></icon><span class="text-h6">Update Institution Admin - {{institutionShortform}}</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                    <v-text-field
                        outlined
                        v-model="institutionAdminUsername"
                        :counter="100"
                        label="New Institution Admin Username *"
                        prepend-icon="mdi-account-circle"
                        required >
                    </v-text-field>
                </v-col>
              </v-col>
            </v-row>
            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="closeDialog"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="editInstitutionsAdmin"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "EditInstitutionAdmin",
  data: ()=> ({
    dialog: false,
  }),

  props: [
    "editInstitutionAdmin",
    "institutionAdminUsername",
    "institutionUniqueId",
    "institutionShortform",
  ],

  methods: {
    closeDialog(){
      this.$emit('close-dialog')
    },

    async editInstitutionsAdmin(){
      let input = {
        institutionUniqueId: this.institutionUniqueId,
        institutionAdminUsername: this.institutionAdminUsername
      }

      await this.$store.dispatch('updateInstitutionAdmin', input)
      this.$store.dispatch('loadInstitutionLists')
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>

</style>