<template>
  <v-card tile>
    <v-card-title v-if="hasAccess('can_schedule_meeting') && !meetingHasPublishedAgenda">
      <v-menu
          :close-on-content-click="false"
          top
          :offset-y="offset"
          ref="agendaTitle" v-model="agendaTitle"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mr-3 mb-2 ml-2 mt-5"
            small
            icon
            color="indigo"
            ><v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>

        <v-card width="700">
          <v-card-text class="flex">
            <input
              type="text"
              class="mt-2 w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Agenda Name"
              style="border: 1px solid black"
              required
              v-model="selectedAgenda.itemName"
            />
            <v-btn
              class="mr-3 mb-1 ml-4 mt-5 indigo--text"
              @click="onPurposeInput(selectedAgenda.itemKey, 'Title')"
              small
              outlined
              icon
              ><v-icon>mdi-check</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-menu>

      <strong>
        <span class="maincolor" style="padding-left: 10px"
          >{{ selectedAgenda.itemName }}
        </span>
      </strong>

    </v-card-title>

    <v-card-title v-else>
      <strong>
        <span class="maincolor" style="padding-left: 10px">{{
          selectedAgenda.itemName
        }}</span></strong
      >
    </v-card-title>

    <v-card-text v-if="selectedAgenda.itemType === 'Standard'">
      <v-container>
        <v-row justify-space-around>
          <AgendaTopDetails />
        </v-row>
      </v-container>
      <AgendaSupportingDocs
        :data="selectedAgenda"
        type="Agenda"
      />
    </v-card-text>

    <v-card-text v-else-if="selectedAgenda.itemType === 'ConfirmMinutes'">
      <v-container>
        <v-row justify-space-around>
          <ConfirmMinutesAgendaTopDetails />

          <v-list dense width="100%">
            <v-list-item-group color="primary">
              <v-layout style="padding-top: 10px; width: 100%" width="100%">
                <v-alert dense style="width: 100%" text>
                  <v-flex md12 xs12>
                    <div class=""><span>Minutes to be Confirmed:</span></div>
                  </v-flex>
                </v-alert>
              </v-layout>
              <v-list-item
                style="
                  border-top: 1px solid gray;
                  border-bottom: 1px solid gray;
                "
                @click="
                  OpenfileDialogFunc(
                    '/' +
                      selectedAgenda.itemMinutesDetails.edges[0].node
                        .documentPath
                  )
                "
              >
                <v-list-item-avatar size="30">
                  <v-img src="@/assets/docs.png" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="selectedAgenda.itemMinutesDetails.edges[0].node.documentName"
                  ></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="meetingHasPublishedAgenda">
                  <v-menu app offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        slot="activator"
                        color="indigo"
                        flat
                        icon
                        v-bind="attrs"
                        x-small
                        v-on="on"
                      >
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-if="hasAccess('can_confirm_minutes') && showConfirm"
                        color="success"
                        link
                        to=""
                        @click="ConfirmMeetingMinutes(selectedAgenda.itemKey)"
                      >
                        <v-list-item-title>
                          <v-icon
                            style="
                              height: 16px;
                              font-size: 16px;
                              color: limegreen;
                            "
                            >mdi-check-all
                          </v-icon>
                          Confirm Minutes
                        </v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-row>
      </v-container>
      <AgendaSupportingDocs
        v-if="hasAccess('can_schedule_meeting')"
        :data="selectedAgenda"
        type="Agenda"
      />
    </v-card-text>

    <v-card-text v-else-if="selectedAgenda.itemType === 'Vote'">
      <v-container>
        <v-row justify-space-around>
          <VotingAgendaTopDetails />
        </v-row>
      </v-container>
      <AgendaSupportingDocs
        v-if="hasAccess('can_schedule_meeting')"
        :data="selectedAgenda"
        type="Agenda"
      />
    </v-card-text>

    <v-card-text v-else-if="selectedAgenda.itemType === 'InterestRegister'">
      <v-container>
        <v-row justify-space-around>
          <AgendaTopDetails />
          <v-list dense width="100%">
            <v-list-item-group color="primary">
              <v-layout style="padding-top: 10px; width: 100%" width="100%">
                <v-alert dense style="width: 100%" text>
                  <v-flex md12 xs12>
                    <div class="">
                      <span
                        >List of interests of directors and officers including
                        actual, potential or perceived conflicts:</span
                      >
                    </div>
                  </v-flex>
                </v-alert>
              </v-layout>
              <v-list-item>
                <v-card elevation="0" width="100%">
                  <v-tabs style="content-align: left" vertical>
                    <v-tab
                      v-for="member in activeInterestList"
                      :key="member.primaryKey"
                    >
                      {{ member.firstName }} {{ member.lastName }}
                    </v-tab>

                    <v-tab-item
                      v-for="member in activeInterestList"
                      :key="member.interestKey"
                    >
                      <v-card>
                        <v-card-text>
                          <v-simple-table>
                            <template>
                              <thead>
                                <tr>
                                  <th class="text-left" style="width: 30%">
                                    <strong>Organization</strong>
                                  </th>
                                  <th class="text-left" style="width: 40%">
                                    <strong>Interest</strong>
                                  </th>
                                  <th class="text-left" style="width: 20%">
                                    <strong>Notified Date</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="interest in member.listOfInterest"
                                  :key="interest.primaryKey"
                                >
                                  <td>
                                    <strong>{{
                                      interest.interestOrganisation
                                    }}</strong>
                                  </td>
                                  <td>
                                    {{ interest.interestNature }}
                                  </td>
                                  <td>
                                    {{
                                      interest.interestNotificationDate
                                        | formatDate
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-card>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-row>
      </v-container>
      <AgendaSupportingDocs
        v-if="hasAccess('can_schedule_meeting')"
        :data="selectedAgenda"
        type="Agenda"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="ma-2"
        color="pink"
        outlined
        small
        @click="
          AgendaBuilderWindow = false;
          $emit('closeGreatModel');
        "
      >
        <v-icon small>mdi-close</v-icon>
        Close
      </v-btn>
      <v-btn
        v-if="hasAccess('can_schedule_meeting') && meetingHasPublishedAgenda"
        class="ma-2"
        color="accent"
        outlined
        small
        @click="previewAgendas"
      >
        Preview
        <v-icon small>mdi-archive-eye-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="!meetingHasPublishedAgenda && hasAccess('can_schedule_meeting')"
        :loading="loading3"
        color="success"
        small
        @click="publishAgendas"
      >
        Publish Agenda
        <v-icon small>mdi-upload-multiple</v-icon>
      </v-btn>
      <v-btn
        v-if="meetingHasPublishedAgenda && hasAccess('can_schedule_meeting')"
        :loading="loading3"
        color="warning"
        small
        @click="unPublishAgendas"
      >
        Un-Publish Agenda
        <v-icon small>mdi-download-multiple</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>

    <DocumentViwer
      v-if="OpenfileDialog === true"
      :OpenfileDialog="OpenfileDialog"
      :docValue="docValue"
      :title="`View Attachment`"
      @CloseDocumentViwer="OpenfileDialog = false"
    />
  </v-card>
</template>

<script>
import AgendaTopDetails from "./AgendaTopDetails.vue";
import AgendaSupportingDocs from "./AgendaSupportingDocs.vue";
import VotingAgendaTopDetails from "./VotingAgendaTopDetails.vue";
import ConfirmMinutesAgendaTopDetails from "./ConfirmMinutesAgendaTopDetails.vue";
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";
import swal from "sweetalert2";

export default {
  props: ["currentMeeting", "meetingHasPublishedAgenda"],

  components: {
    AgendaTopDetails,
    AgendaSupportingDocs,
    VotingAgendaTopDetails,
    ConfirmMinutesAgendaTopDetails,
    DocumentViwer,
  },

  data() {
    return {
      file: "",
      docValue: "",
      OpenfileDialog: false,
      loading3: false,
      dragging: false,
      presenter: "No Presenter",
      duration: 0,
      Purpose: "",

      isEditItemName: false,
      itemName: "",
      isEditItemKey: "",
      autofocus: false,
      showConfirm: true,
      agendaTitle: "",
    };
  },

  methods: {
    previewAgendas() {
      this.docValue = this.$store.state.AgendasStore.combinedAgendas;
      this.OpenfileDialog = true;
    },
    async ConfirmMeetingMinutes(itemKey) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("confirmMeetingMinutes", itemKey);
      this.showConfirm=false
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    setIsEditItemName() {
      this.autofocus = true;
      this.isEditItemName = true;
      this.isEditItemKey = this.selectedAgenda.itemKey;
    },

    clearIsEditItemName() {
      this.autofocus = false;
      this.isEditItemName = false;
      this.isEditItemKey = "";
      this.itemName = "";
    },

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },

    createFile(file) {
      if (!file.type.match("pdf.*")) {
        alert("please select PDF file");
        this.dragging = false;
        return;
      }

      if (file.size > 100000000) {
        alert("please check file size no over 5 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      console.log(this.file);
      this.dragging = false;
    },

    removeFile() {
      this.file = "";
    },

    setPresenter(presenter) {
      this.presenter = presenter;
    },

    onInput(event) {
      var cleared = event.target.innerText.replace(/[^\d.-]/g, "");
      console.log(cleared);
      this.duration = cleared;
    },

    clearAllUnwantedChar(event) {
      event.target.innerText = this.duration;
    },

    async onPurposeInput(selectedAgenda, updatePlace) {
      this.agendaTitle=false
      var details = {
        newItem: this.selectedAgenda.itemName,
        itemKey: selectedAgenda,
        updatePlace: updatePlace,
      };
      await this.$store.dispatch("updateAgendaItem", details);
      this.clearIsEditItemName();


      swal.fire({
        toast: true,
        icon: "success",
        title: "Agenda edited successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    onVottingPurposeInput(event, selectedAgenda, updatePlace) {
      console.log(selectedAgenda, updatePlace);
      this.Purpose = event.target.innerText;
      var details = {
        newPurpose: this.Purpose,
        newItem: selectedAgenda,
        updatePlace: updatePlace,
      };
      this.$store.dispatch("updateVottingAgendaItemsDetails", details);
    },

    async publishAgendas() {
      this.loading3 = true;
      var publish_keys = {
        meetingKey: this.currentMeeting,
      };
      await this.$store.dispatch("publishAgendas", publish_keys);

      this.$router.go("");
    },

    async unPublishAgendas() {
      this.loading3 = true;
      var publish_keys = {
        meetingKey: this.currentMeeting,
      };
      await this.$store.dispatch("unPublishAgendas", publish_keys);

      this.$router.go("");
    },


    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      console.log(this.docValue);
      this.OpenfileDialog = true;
    },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },

    selectedAgenda() {
      return this.$store.state.AgendasStore.selecteAgenda;
    },

    activeInterestList() {
      return this.$store.state.InterestStore.meetingActiveInterestList;
    },
  },

  async mounted() {
    await this.$store.dispatch(
      "loadMeetingConflictsInterests",
      this.$route.params.meeting_uuid
    );
  },
};
</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}

.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
