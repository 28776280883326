<template>
  <v-container>
    <div class="w-full">
      <v-col class="flex items-center">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                right
                small
                outlined
                rounded
                fab
                color="primary white--text"
                style="margin: 5px"
                @click="$router.go('-1')"
            ><v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <h2 class="text-xl ml-2">Circular Resolutions Reports</h2>
      </v-col>
      <div class="grid grid-cols-12 gap-6 mt-5 mb-3">
        <div
          class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
          <div class="hidden md:block mx-auto text-slate-500"></div>
          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <v-system-bar color="rgba(255, 255, 255, 0)" window>
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="startmenu"
                    v-model="startmenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="start_date"
                        label="Start Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-5"
                        @change="end_date = ''"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="start_date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startmenu = false">
                        Cancel
                      </v-btn>
<!--                      <v-btn-->
<!--                        text-->
<!--                        color="primary"-->
<!--                        @click="$refs.startmenu.save(start_date)"-->
<!--                      >-->
<!--                        OK-->
<!--                      </v-btn>-->
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="endmenu"
                    v-model="endmenu"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        v-model="end_date"
                        label="End Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-5"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="end_date"
                      :min="start_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="endmenu = false">
                        Cancel
                      </v-btn>
<!--                      <v-btn-->
<!--                        text-->
<!--                        color="primary"-->
<!--                        @click="$refs.endmenu.save(end_date)"-->
<!--                      >-->
<!--                        OK-->
<!--                      </v-btn>-->
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-btn @click="filteringReport()" class="btn btn-primary shadow-md mx-4 mb-2" color="white" outlined>
                Search <v-icon class="ml-2" color="white">mdi-magnify</v-icon>
              </v-btn>
            </v-system-bar>
          </div>
        </div>
      </div>
      <div class="px-10 py-5 w-full intro-y box">
        <data-table
          :datafld="reportColumn.id"
          :data="closedPublishedBoardResolutions"
          :columns="reportColumn"
          class="intro-y overflow-hidden"
        >
        </data-table>
      </div>
    </div>
  </v-container>
</template>

<script>
import swal from "sweetalert2";

export default {
  data() {
    return {
      startmenu: "",
      endmenu: "",
      start_date: "",
      end_date: "",
      reportColumn: [
        {
          key: "resolutionTitle",
          title: "Title",
        },
        {
          key: "resolutionStartDate",
          title: "Date",
        },
        {
          key: "resolutionVotters",
          title: "Total Vote",
        },
        {
          key: "resolutionSupporters",
          title: "Support",
        },
        {
          key: "resolutionOpposers",
          title: "Oppose",
        },
        {
          key: "resolutionAbstains",
          title: "Abstain",
        },
        {
          key: "resolutionDecisions",
          title: "Resolution",
        },
      ],
    };
  },
  methods: {
    async filteringReport() {
        if (this.start_date=="" || this.end_date=="") {
            swal.fire({
              icon: "warning",
              title: "Ooops . . . !",
              text: "You must provide Start & End dates",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
        } else {
            this.$store.dispatch("showLoadingSpinner", true);
              this.$store.dispatch("loadBoardResolutions", {startDate:this.start_date, endDate:this.end_date});
            this.$store.dispatch("showLoadingSpinner", false);
        }
    },
  },
  computed: {
    closedPublishedBoardResolutions() {
      var reports_list=[]
      var resulutions = this.$store.getters.closedCircularResolution;
      resulutions.forEach(resolution => {
          reports_list.push({
              resolutionTitle: resolution.resolutionTitle,
              resolutionStartDate: resolution.resolutionStartDate,
              resolutionVotters: resolution.voteResults.voteVoters,
              resolutionSupporters: resolution.voteResults.votersOppose,
              resolutionOpposers: resolution.voteResults.votersOppose,
              resolutionAbstains: resolution.voteResults.votersAbstain,
              resolutionDecisions: resolution.resolutionDecision,
          })
      });

      return reports_list
    },
    meetingReports() {
      return this.$store.getters.getCommitteeMeetingReports;
    },
  },
  async mounted() {
    this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("loadBoardResolutions");
    this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>

<style></style>
