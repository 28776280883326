<template>
  <v-container fluid>
    <v-row class="mt-5 mx-3">
      <v-spacer></v-spacer>
      <button
        color="indigo"
        class="btn btn-primary shadow-lg mr-2 white--text"
        @click="
          createCategoryDialogFunction(`Add New Meeting Category`, null, `Add`)
        "
      >
        <v-icon small class="mr-1 white--text">mdi-view-grid-plus</v-icon> New
        Category
      </button>
    </v-row>

    <div>
      <div class="col-span-12 lg:col-span-9 2xl:col-span-10">
        <div class="intro-y inbox box mt-5">
          <div
            class="p-5 flex flex-col-reverse sm:flex-row text-slate-500 border-b border-slate-200/60"
          >
            <div class="flex items-center sm:ml-auto">
              <div class="" style="font-size: 20px">Meeting Categories</div>
            </div>
          </div>
          <div class="overflow-x-hidden sm:overflow-x-hidden">
            <div
              v-for="data in boardMeetingCategories"
              :key="data.id"
              class="intro-y"
            >
              <div
                class="inbox__item inline-block sm:block text-slate-600 dark:text-slate-500 bg-slate-100 dark:bg-darkmode-400/70 border-b border-slate-200/60 dark:border-darkmode-400 inbox__item--active"
              >
                <div class="flex px-5 py-3">
                  <div class="w-72 flex-none flex items-center mr-5">
                    {{ data.serial_number }}
                    <span
                     
                      class="w-5 h-5 flex-none ml-4 flex items-center info--text justify-center text-slate-400"
                    >
                    </span>
                    <div class="inbox__item--sender truncate ml-3">
                      {{ data.name }}
                    </div>
                  </div>
                  <div class="w-64 sm:w-auto truncate">
                    <span class="inbox__item--highlight"
                      >{{ data.meetings_type }}
                    </span>
                  </div>
                  <div
                    class="inbox__item--time whitespace-nowrap ml-auto pl-10"
                  >
                    {{ data.date }}
                  </div>
                  <div
                    class="inbox__item--time whitespace-nowrap ml-auto pl-10"
                  >
                    <div
                      v-if="data.is_editable && hasAccess('can_manage_users')"
                      class="flex justify-center items-center"
                    >
                      <button
                        @click="
                          createCategoryDialogFunction(
                            `Edit Meeting Category`,
                            data,
                            `Edit`
                          )
                        "
                        class="flex items-center mr-3 info--text"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit w-4 h-4 mr-1"
                        >
                          <path
                            d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                          ></path>
                          <path
                            d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                          ></path>
                        </svg>

                        Edit
                      </button>
                      <button
                        @click="deleteCategory(data.id)"
                        class="flex items-center text-danger"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide w-4 h-4 mr-1"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path
                            d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"
                          ></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                        Delete
                      </button>
                    </div>
                    <div class="flex justify-center items-center" v-else>
                      No edit or delete to this Category
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="p-5 flex flex-col sm:flex-row items-center text-center sm:text-left text-slate-500"
          >
            <div class="sm:ml-auto mt-2 sm:mt-0"></div>
          </div>
        </div>
      </div>
    </div>

    <v-row justify="center" class="mb-5">
      <v-dialog v-model="createCategoryDialog" persistent max-width="60%">
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            <v-icon small class="mr-2">mdi-view-grid-plus</v-icon>
            {{ title }}
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="categoryname"
                    label="Category name*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="meetingtype"
                    :items="items"
                    item-text="name"
                    item-value="value"
                    attach
                    chips
                    label="Meeting Type"
                    dense
                    @change="checkBoardMeeting"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-checkbox
                    v-model="isSpecialCommittee"
                    :label="`Category for Committee Meetings?`"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="pink"
              small
              outlined
              @click="createCategoryDialog = false"
            >
              Close
            </v-btn>
            <v-btn small color="primary" @click="saveNewCategory" outlined>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showSpecialCommitteeCheckbox: false,
      isSpecialCommittee: false,
      categoryname: null,
      meetingtype: null,
      items: [
        { name: "Board Meetings", value: "board" },
        { name: "Management Meetings", value: "management" },
      ],
      createCategoryDialog: false,
      createBoard: false,
      search: "",
      headers: [
        {
          text: "S/N",
          align: "start",
          sortable: true,
          value: "serial_number",
          filterable: false,
        },
        {
          text: "Category Name",
          align: "start",
          value: "name",
        },
        { text: "Meeting Group", value: "meetings_type" },
        { text: "Created", value: "date" },
        { text: "Actions", value: "actions" },
      ],
      title: "",
      category: "",
      action: "",
    };
  },

  computed: {
    boardMeetingCategories() {
      const categories =
        this.$store.state.CategoriesStore.boardMeetingCategories;
      const categories_list = [];
      categories.forEach((category) => {
        categories_list.push({
          serial_number: category.sn,
          name: category.title,
          meetings_type: category.type,
          actions: category.key,
          date: new Date(category.date).toDateString(),
          id: category.uniqueId,
          type: category.type,
          is_editable: category.is_editable,
        });
      });
      return categories_list;
    },

    categoryObject() {
      return this.category;
    },
  },

  watch: {
    categoryObject() {
      this.categoryname = this.category.name;
      this.meetingtype = {
        name: this.category.type,
        value: this.category.type === "Board Meetings" ? "board" : "management",
      };
    },
  },

  methods: {
    checkBoardMeeting() {
      this.showSpecialCommitteeCheckbox = this.meetingtype === "board";
    },

    async saveNewCategory() {
      await this.$store.dispatch("showLoadingSpinner", true);

      if (this.action === "Add") {
        const category = {
          categoryName: this.categoryname,
          categoryType: this.meetingtype,
          categoryBoard: localStorage.getItem("my-boardUniqueId"),
          categoryManagement: localStorage.getItem("my-managementUniqueId"),
          isSpecialCommittee: this.isSpecialCommittee,
        };
        await this.$store.dispatch("addNewMeetingCategory", category);
      } else {
        const category = {
          categoryName: this.categoryname,
          categoryType: this.meetingtype,
          categoryBoard: localStorage.getItem("my-boardUniqueId"),
          categoryManagement: localStorage.getItem("my-managementUniqueId"),
          isSpecialCommittee: this.isSpecialCommittee,
          categoryUniqueId: this.category.id,
        };
        await this.$store.dispatch("updateMeetingCategory", category);
      }

      await this.$store.dispatch("loadBoardMeetingCategories");

      this.categoryname = null;
      this.meetingtype = null;
      this.createCategoryDialog = false;

      await this.$store.dispatch("showLoadingSpinner", false);
    },
    async deleteCategory(categoryKey) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("deleteMeetingCategory", categoryKey);
      await this.$store.dispatch("loadBoardMeetingCategories");
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    createCategoryDialogFunction(title, category, action) {
      this.title = title;
      this.category = category;
      this.action = action;
      this.createCategoryDialog = true;
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardMeetingCategories");
  },
};
</script>

<style></style>
