<template>
  <nav>
    <v-app-bar app dense style="background: #3f51b5;color: #fff;">
      <v-app-bar-nav-icon>
        <v-icon class="white--text" left @click="mini = !mini">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="white--text text-lowercase">e-</span>
        <span class="white--text font-weight-light">Board/ </span>
      </v-toolbar-title>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span slot="activator" style="padding-left:10px" v-bind="attrs" v-on="on">
             {{ currentBoard }}
            <v-icon class="white--text" right>mdi-chevron-down</v-icon>
          </span>
        </template>
        <v-list>
          <v-list-item v-for="boards in allBoardsByUser" :key="boards.memberBoard.boardUniqueId"
                       @click="changeSelectedBoard(boards.memberBoard.boardUniqueId,boards.memberBoard.boardDescriptions)">
            <v-list-item-title>{{ boards.memberBoard.boardDescriptions }} -
              {{ boards.memberBoard.boardInstitution.institutionShortform }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>


      <v-app-bar-nav-icon>
        <router-link style="text-decoration: none" to="/">
          <v-icon class="white--text" color="white" left>mdi-home</v-icon>
        </router-link>
      </v-app-bar-nav-icon>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon slot="activator" class="white--text" left v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
        </template>
        <v-list>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-note-text</v-icon>
                User Manual
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
<!--          <v-list-item link>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>-->
<!--                <v-icon>mdi-information-variant</v-icon>-->
<!--                About e-Board-->
<!--              </v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
        </v-list>

      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon slot="activator" class="white--text" right v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
          <span style="padding-left:10px">{{ logeduser.fullname }}</span>
        </template>
        <v-list>
          <v-list-item v-for="link in topLinks" :key="link.name" :to="link.routinglink" link>
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>{{ link.icon }}</v-icon>
                {{ link.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="callLogoutMethod">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon>mdi-power</v-icon>
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-menu>
    </v-app-bar>

    <v-card>
      <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          app
          permanent
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>

            <v-img :src="
                MEDIA_SERVER +(institutionDetails()||{}).institutionLogo"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-title>
            {{
              this.$store.state.AuthStore.user.profileUser.edges[0].node.userProfileBoard.boardInstitution.institutionShortform
            }}
          </v-list-item-title>

          <v-btn
              icon
              @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item v-for="link in links" :key="link.name" :to="link.routinglink" link>
            <v-list-item-icon class="iconclass">
              <v-icon style="color:rgba(63, 81, 181, 0.90)">{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <v-btn color="indigo white--text" fab link right style="margin:5px" to="/eboard_my_dashboard"
                   x-small>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </v-card>
  </nav>
</template>

<script>
// import gql from 'graphql-tag'
import allBoardsByUser from '../../../../graphql/eboard-boards-graphql/allBoardsByUser.gql'
import store from '@/store'

export default {
  watch: {
    '$route'(to) {
      if (to.params.board_name != null) {
        this.storeUserBaords(to.params.board_name)
        this.currentBoard = localStorage.getItem('currentBoard');
      }
    }
  },
  data() {
    return {
      board_account: "/board_account/",
      drawer: true,
      logeduser: {fullname: ""},
      mini: false,
      topLinks: [
        {
          name: 'My Profile',
          icon: 'mdi-account',
          routinglink: '/eboard_my_profile'
        },
        {
          name: 'Help',
          icon: 'mdi-account',
          routinglink: 'meetings/my-eboards'
        },
      ],
      links: [
        {
          name: 'System Settings',
          icon: 'mdi-cogs',
          routinglink: '/settings/eboard_system_settings'
        },
        {
          name: 'Board Committees',
          icon: 'mdi-account-group-outline',
          routinglink: '/settings/eboard_committees'
        },
        {
          name: 'Management Committees',
          icon: 'mdi-account-group-outline',
          routinglink: '/settings/management_committees'
        },

        {
          name: 'Users',
          icon: 'mdi-account-multiple-check-outline',
          routinglink: '/settings/eboard_members'
        },

        {
          name: 'Meeting Categories',
          icon: 'mdi-grid',
          routinglink: '/settings/eboard_meetings_category'
        },

        {
          name: 'Meeting Locations',
          icon: 'mdi-map-marker-multiple-outline',
          routinglink: '/settings/eboard_meetings_location'
        },

        {
          name: 'Users Designation',
          icon: 'mdi-star-circle',
          routinglink: '/settings/eboard_meetings_designations'
        },

      ],
    }
  },
  apollo: {
    allBoardsByUser: allBoardsByUser,
  },

  computed: {
    currentBoard() {
      return this.$store.state.MyboardStore.currentBoard
    }
  },

  methods: {
    callLogoutMethod() {
      localStorage.removeItem('vuex')
      localStorage.removeItem('my-boardUniqueId')
      localStorage.removeItem('my-managementCommitteeName')
      localStorage.removeItem('my-managementCommitteeUniqueId')
      localStorage.removeItem('my-boardCommitteeName')
      localStorage.removeItem('my-boardCommitteeUniqueId')
      localStorage.removeItem('my-boardKey')
      localStorage.removeItem('apollo-token')
      this.$router.go('/landing_page')
    },
    changeSelectedBoard(board_uuid, board_name) {
      this.$store.dispatch('updateCurrentMyboardName', board_name)
      localStorage.setItem('my-boardUniqueId', board_uuid)
      this.$router.go('')
    }
  },
  mounted() {
    const userDetails = store.getters['AuthStore/user']
    this.logeduser.fullname = userDetails.firstName + " " + userDetails.lastName
  }
}
</script>

