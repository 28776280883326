<template>
  <v-banner outlined type="">
    <v-chart class="chart" :option="option" />
  </v-banner>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  props: ["dataObject"],
  name: "HelloWorld",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "white",
  },
  data() {
    return {};
  },
  computed: {
    option() {
      var labes = [];
      var data = [];
      var coloPillet = [
        "rgba(201, 176, 94, 0.93)",
        "rgba(15, 170, 39, 0.63)",
        "rgba(98, 146, 204, 0.93)",
        "rgba(108, 105, 17, 0.63)",
        "rgba(201, 176, 94, 0.93)",
        "rgba(15, 170, 39, 0.63)",
        "rgba(98, 146, 204, 0.93)",
        "rgba(108, 105, 17, 0.63)",
        "rgba(201, 176, 94, 0.93)",
        "rgba(15, 170, 39, 0.63)",
        "rgba(98, 146, 204, 0.93)",
        "rgba(108, 105, 17, 0.63)",
      ];
      var values_list=[5,4,3,6,1,6,8,2,5,7,3,5];

      ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul','Aug', 'Sept', 'Oct','Nov','Dec'].forEach((dataPck, index) => {
        labes.push(dataPck);
        data.push({
          value: values_list[index],
          itemStyle: {
            color: coloPillet[index],
            borderWidth: 1,
          },
        });
      });
      return {
        title: {
          text: 'Meetings conducted each month',
          top: "left",
        },
        tooltip: {
          trigger: "axis",
          showContent: true,
          formatter: "<strong>{b}</strong> <br/>Conducted Meetings : {c}",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
            dataZoom: { show: true },
          },
        },
        xAxis: {
          type: "category",
          data: labes,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            symbolSize: 30,
            data: data,
            type: "line",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(243, 243, 243, 0.7)",
            },
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>



