import MeeitingList from "./eboard-meetings/MeeitingList.vue";
import MeetingDetails from "./eboard-meetings/MeetingDetails.vue";
import CommitteesDashboard from "./eboard-committees/CommitteesDashboard.vue";
import MembersList from "./eboard-boards/MembersList.vue";
import BoardPacksList from "../meeting_management/eboard-boardpacks/BoardPacksList.vue";
import BoardPacksAnnotation from "../meeting_management/eboard-boardpacks/BoardPacksAnnotation.vue";
import OpenPack from "../meeting_management/eboard-boardpacks/OpenPack.vue";
import OpenDocument from "../meeting_management/eboard-boardpacks/OpenDocument.vue";
import MyActionsList from "../meeting_management/eboard-actions/MyActionsList.vue";
import MyDashboard from "./eboard_myboard/MyDashboard.vue";
import BoardStructure from "./eboard_myboard/BoardStructure.vue";
import VottingList from "./eboard-votting/VottingList.vue";
import DecisionRegister from "./eboard-decisions/DecisionRegister.vue";
import CircularResolution from "./eboard-flyingminutes/CircularResolution.vue";
import InterestRegister from "./eboard-interest/InterestRegister.vue";
import GovernanceRepository from "./eboard-repository/GovernanceRepository.vue";
import GovernanceRepositoryFolderContents from "./eboard-repository/GovernanceRepositoryFolderContents.vue";
import ReportBuilder from "./eboard_reports_builder/ReportBuilder.vue";
import BuilderTemplates from "./eboard_reports_builder/BuilderTemplates.vue";
import ReportDrafts from "./eboard_reports_builder/ReportDrafts.vue";
import CalenderView from "./eboard-calendar/CalenderView.vue";
import CalenderActivity from "./eboard-calendar/CalenderActivity.vue";
import MyProfile from "./eboard_my_profile/MyProfile.vue";
import DigitalSignature from "./eboard_my_profile/DigitalSignature.vue";
import ChattingMain from "./eboard-chatting/ChattingMain.vue";
import NewsUpdateMain from "./eboard_news_updates/NewsUpdateMain.vue";
import NotebooksMain from "./eboard_notebook/NotebooksMain.vue";
import PageNoteDetails from "./eboard_notebook/Page.vue";
import AuditTrail from "./e_board_audit_trail/AuditTrail.vue";
import MeetingReports from "./eboard_meeting_reports/MeetingReports.vue";
import BoardMeetingsReport from "./eboard_meeting_reports/BoardMeetingsReport.vue";
import CommiteesMeetingsReport from "./eboard_meeting_reports/CommiteesMeetingsReport.vue";
import CircularsReport from "./eboard_meeting_reports/CircularsReport.vue";
import DecisionsReport from "./eboard_meeting_reports/DecisionsReport.vue";
import DirectivesReport from "./eboard_meeting_reports/DirectivesReport.vue";



export default [
  {
    path: "/board_account/:board_uuid/:board_name",
    name: "BoardAccount",
    component: MeeitingList,
    meta: { requiresAuth: true },
  },

  {
    path: "meeting_details/:meeting_uuid",
    name: "MeeitingDetails",
    component: MeetingDetails,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_committees",
    name: "CommitteesDashboard",
    component: CommitteesDashboard,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_calender",
    name: "CalenderView",
    component: CalenderView,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_calender_event_activities/:event_id",
    name: "CalenderActivity",
    component: CalenderActivity,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_members",
    name: "MembersDashboard",
    component: MembersList,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_packs",
    name: "eBoardPacks",
    component: BoardPacksList,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_open_packs",
    name: "eBoardPacksOpen",
    component: OpenPack,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_documents_annotation",
    name: "eBoardDocumentOpen",
    component: OpenDocument,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_actions",
    name: "Actions",
    component: MyActionsList,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_packs_annotations",
    name: "Annotation",
    component: BoardPacksAnnotation,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_my_profile",
    name: "profile",
    component: MyProfile,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_my_digital_signature",
    name: "signature",
    component: DigitalSignature,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_my_dashboard",
    name: "Dashboard",
    component: MyDashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_votting",
    name: "Voting",
    component: VottingList,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_decisions",
    name: "Decisions",
    component: DecisionRegister,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_flying_minutes",
    name: "CirecularResolutions",
    component: CircularResolution,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_interest_register",
    name: "Interest",
    component: InterestRegister,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_governance_repository",
    name: "Repository",
    component: GovernanceRepository,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_governance_repository/:unique_id/:id/:folderName",
    name: "Repository",
    component: GovernanceRepositoryFolderContents,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_reports_builder",
    name: "eBoardReportBuilder",
    component: ReportBuilder,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_report_builder_templates",
    name: "eBoardReportBuilderTemplates",
    component: BuilderTemplates,
    meta: { requiresAuth: true },
  },
  {
    path: "e_board_report_builder_drafts",
    name: "eBoardReportBuilderDrafts",
    component: ReportDrafts,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_board_structure",
    name: "eBoardStructure",
    component: BoardStructure,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_chatting",
    name: "eChattingMain",
    component: ChattingMain,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_newsupdates",
    name: "NewsUpdatesMain",
    component: NewsUpdateMain,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_PageNoteDetails",
    name: "PageNoteDetails",
    component: PageNoteDetails,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_notebook",
    name: "NootbooksMain",
    component: NotebooksMain,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_notebook/:notebookUniqueId",
    name: "NotebookPages",
    component: PageNoteDetails,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_audit_trail",
    name: "NootbooksMain",
    component: AuditTrail,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_meeting_reports",
    name: "MeetingReportsMain",
    component: MeetingReports,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_meeting_reports/board_meeting",
    name: "BoardMeetingReports",
    component: BoardMeetingsReport,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_meeting_reports/committee_meeting",
    name: "CommiteesMeetingReports",
    component: CommiteesMeetingsReport,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_meeting_reports/circular_resolution",
    name: "CircularsReport",
    component: CircularsReport,
    meta: { requiresAuth: true },
  },

  {
    path: "eboard_meeting_reports/decision_report",
    name: "DecisionsReport",
    component: DecisionsReport,
    meta: { requiresAuth: true },
  },
  {
    path: "eboard_meeting_reports/directives_report",
    name: "DirectivesReport",
    component: DirectivesReport,
    meta: { requiresAuth: true },
  },
];
