<template>
  <v-container>
    <v-layout>
      <v-spacer></v-spacer>

      <v-flex xs12 md1>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="hasAccess('can_add_timetable')"
              v-bind="attrs"
              v-on="on"
              @click="showContextMenu = true"
              right
              fab
              small
              color="primary white--text"
              style="margin: 5px"
              ><v-icon>mdi-calendar-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Board Event</span>
        </v-tooltip>
        <AddNewEvent
          :showContextMenu="showContextMenu"
          @closeNewEventDialog="showContextMenu = !showContextMenu"
        />
        <EditEvent
          @close-dialog="showEditEvent=false"
          :showEditEvent="showEditEvent"
          :event-unique-id="eventUniqueId"
          :board-unique-id="boardUniqueId"
          :event-name="eventName"
          :event-description="eventDescription"
          :event-start-date="eventStartDate"
          :event-end-date="eventEndDate"
          :event-end-time="eventEndTime"
          :event-start-time="eventStartTime"
          :send-reminder="sendReminder"
          :event-color="eventColor.color"
          :reminder-message="reminderMessage"
        />
      </v-flex>
    </v-layout>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn fab text small color="primary darken-2" @click="prev">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn fab text small color="primary darken-2" @click="next">
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  Filter by:
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>

          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-system-bar dark color="primary" height="50">
              <h1 class="white--text fonts-20">Event Details</h1>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="editEventBoard(selectedEvent)"
                    fab
                    small
                    outlined
                    class="ma-1"
                  >
                  <v-icon color="">mdi-calendar-edit</v-icon>
                </v-btn>
                </template>
                <span>Edit Calendar Event</span>
              </v-tooltip>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="white"
                    link
                    :to="
                      '/eboard_calender_event_activities/' +
                      selectedEvent.eventUniqueId
                    "
                    fab
                    small
                    outlined class="ma-1"
                    ><v-icon  color="">mdi-calendar-check</v-icon>
                  </v-btn>
                </template>
                <span>Add Board Activity</span>
              </v-tooltip>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-on="on"
                      v-bind="attrs"
                      @click="deleteEventBoard(selectedEvent.eventUniqueId)"
                      color="white"
                      fab
                      small
                      outlined
                      class="ma-1"
                    >
                    <v-icon  color="red">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete Calendar Event</span>
              </v-tooltip>
            </v-system-bar>
            <v-card color="grey lighten-4" min-width="500px" flat>
              <div class="intro-y box p-5 mt-1 sm:mt-1">

                <p><strong>Name:</strong> {{ selectedEvent.name }}</p>
                <p>
                  <strong>Descriptions:</strong>
                  {{ selectedEvent.eventDescription }}
                </p>
                <p>
                  <strong>Start Date:</strong>
                  {{ selectedEvent.start | formatFull }}
                </p>
                <p>
                  <strong>End Date:</strong>
                  {{ selectedEvent.end | formatFull }}
                </p>
<!--                <p>-->
<!--                  <strong>Status: </strong-->
<!--                  ><span-->
<!--                    v-text="-->
<!--                      selectedEvent.eventIsCompleted ? 'Completed' : 'Waiting'-->
<!--                    "-->
<!--                  ></span>-->
<!--                </p>-->
              </div>
              <v-card-actions>
                <v-btn
                  small
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddNewEvent from "./AddNewEvent.vue";
import EditEvent from "@/modules/meeting_management/eboard-calendar/EditEvent.vue";
export default {
  components: {
    AddNewEvent,
    EditEvent,
  },

  data: () => ({
    isEditActivity: false,
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    selectedCalendarEvent: {},
    showContextMenu: false,


    showEditEvent: false,

    boardUniqueId: "",
    eventUniqueId: "",
    eventName: "",
    eventDescription: "",
    eventStartDate: "",
    eventEndDate: "",
    eventStartTime: "",
    eventEndTime: "",
    sendReminder: false,
    eventColor: "",
    reminderMessage: "",



  }),



  computed: {
    events: {
      get: function () {
        return this.$store.getters.eventList;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardEventList");
    this.$refs.calendar.checkChange();
  },

  methods: {
    async deleteEventBoard(uniqueId) {
      await this.$store.dispatch("deleteBoardEvent", uniqueId)
      await this.$store.dispatch("loadBoardEventList")
    },


    async editEventBoard(event){

      this.eventUniqueId = event.eventUniqueId
      this.boardUniqueId = event.boardUniqueId
      this.eventName = event.name
      this.eventDescription = event.eventDescription
      this.eventStartDate = event.start.substr(0,10)
      this.eventStartTime = event.start.substr(12,14)
      this.eventEndDate = event.end.substr(0,10)
      this.eventEndTime = event.end.substr(12,16)
      this.sendReminder = false
      this.reminderMessage = event.reminderMessage
      this.eventColor = event.color

      this.showEditEvent = true
    },


    editActivity(selectedCalendarEvent) {
      this.selectedCalendarEvent = selectedCalendarEvent;
      this.isEditActivity = true;
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
      this.events = this.$store.state.CalenderStore.boardEventList;
    },
  },
};
</script>
