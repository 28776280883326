<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="10" md="8" lg="10" class="pb-9 mt-4">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  right
                  fab
                  small
                  color="primary white--text"
                  style="margin: 5px"
                  link
                  :to="'/eboard_calender'"
                  ><v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
            <v-spacer></v-spacer
          ></v-col>

          <v-col cols="2" md="4" lg="2" class="pb-9 mt-4">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="hasAccess('can_add_timetable')"
                  v-bind="attrs"
                  v-on="on"
                  @click="showContextMenu = true"
                  right
                  fab
                  small
                  color="primary white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Timetable</span>
            </v-tooltip>

            <AddnewSingleActivity
              :showContextMenu="showContextMenu"
              @closeNewEventActivityDialog="showContextMenu = !showContextMenu"
            />

          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="container" style="max-height: 80vh; min-height: 10vh">
      <div id="printed_area">
        <div
          class="card"
          style="border: 1px solid rgba(158, 157, 157, 0.678); padding: 10px"
        >
          <div
            class="row"
            style="
              margin-top: 1px;
              margin: 30px;
              background-color: rgba(192, 192, 230, 0.24);
            "
          >
            <div
              style="
                float: left;
                text-align: center;
                width: 60%;
                margin-top: 2em;
                margin-left: 15em;
                margin-bottom: 1em;
              "
            >
              <center>
                <h3 style="padding: 5px">{{ currentBoard }} <br /></h3>
                <h3 style="padding: 5px">{{ singleEvent.name }} <br /></h3>
              </center>
              <center>
                <h4 style="font-weight: bold; color: rgb(29, 66, 116)">
                  {{ singleEvent.eventDescription }}
                </h4>
              </center>

              <small>
                <strong>Start Date:</strong>
                {{ singleEvent.start | formatFull }} <strong>End Date:</strong>
                {{ singleEvent.end | formatFull }}
              </small>
            </div>
          </div>
          <v-simple-table style="padding-left: 2em; padding-right: 2em">
            <template>
              <thead>
                <tr>
                  <td><h3>Activity Description</h3></td>
                  <td><h3>Start Time</h3></td>
                  <td><h3>End Time</h3></td>
                  <td><h3>Responsible Personel</h3></td>
                  <td><h3>Actions</h3></td>
                </tr>
              </thead>

              <tbody>
                <tr v-for="activity in activitieslist" :key="activity.activityUniqueId" >
                  <td>{{activity.activityDescription}}</td>
                  <td>{{activity.activityStartTime}}</td>
                  <td>{{activity.activityEndTime}}</td>
                  <td>{{activity.activityResponsiblePersonel}}</td>
                  <td>
                    <div @click="editEventActivity(activity)" class="btn btn-primary btn-sm mr-2" type="button" >
                      <icon name="edit" width="w-5" height="w-5" color="#ffffff"></icon>
                    </div>
                    <div @click="deleteEventActivity(activity.activityUniqueId)" class="btn btn-danger btn-sm" type="button">
                      <icon name="delete" width="w-5" height="w-5" color="#ffffff"></icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </div>
    <edit-activity
        @close-dialog="editEventActivityDialog=false"
        :edit-event-activity-dialog="editEventActivityDialog"
        :activity-description="activityDescription"
        :activity-start-time="activityStartTime"
        :activity-end-time="activityEndTime"
        :activity-responsible-personel="activityResponsiblePersonel"
        :activity-unique-id="activityUniqueId"
        :activity-event-unique-id="activityEventUniqueId"
    >
    </edit-activity>
  </div>
</template>

<script>
import AddnewSingleActivity from "./AddNewSingleActivity.vue";
import Icon from "@/views/Icon.vue";
import EditActivity from "@/modules/meeting_management/eboard-calendar/EditActivity.vue";
export default {
  components: {
    AddnewSingleActivity,
    EditActivity,
    Icon
  },
  data() {
    return {
      showContextMenu: false,

      activityUniqueId: "",
      activityDescription: "",
      activityStartTime: "",
      activityEndTime: "",
      activityResponsiblePersonel: "",
      activityEventUniqueId: "",
      editEventActivityDialog: false,
    };
  },

  computed: {
    activitieslist() {
      return this.$store.state.CalenderStore.boardActivity;
    },
    currentBoard() {
      return this.$store.state.MyboardStore.currentBoard;
    },
    singleEvent() {
      var event_id = this.$route.params.event_id;
      var all_events = this.$store.state.CalenderStore.boardEventList;
      var single = all_events.find((event) => event.eventUniqueId === event_id);
      return single;
    },
  },

   async mounted(){
     this.$store.dispatch('loadEventActivities',this.$route.params.event_id)
   },

  methods: {
    async deleteEventActivity(activityUniqueId) {
      await this.$store.dispatch('deleteEventActivity', activityUniqueId)
      this.$store.dispatch('loadEventActivities',this.$route.params.event_id)
    },
    editEventActivity(activity){
      this.activityEventUniqueId = activity.activityEventUniqueId
      this.activityUniqueId = activity.activityUniqueId
      this.activityDescription = activity.activityDescription
      this.activityStartTime = activity.activityStartTime
      this.activityEndTime = activity.activityEndTime
      this.activityResponsiblePersonel = activity.activityResponsiblePersonel

      this.editEventActivityDialog = true
    }
  }
};
</script>

<style>
</style>