import {apolloClient} from '@/vue-apollo'
import allInterestRegister from '../../graphql/eboard-interest-graphql/allInterestRegister.gql'
import createInterestRegister from '../../graphql/eboard-interest-graphql/createInterestRegister.gql'
import meetingInterestsConflicts from '../../graphql/eboard-interest-graphql/meetingInterestsConflicts.gql'
import membersInterests from '../../graphql/eboard-interest-graphql/membersInterests.gql'
import deactivateMemberInterestRegister
    from '../../graphql/eboard-interest-graphql/deactivateMemberInterestRegister.gql'
import declareAgendaConflictOfInterest from '../../graphql/eboard-interest-graphql/declareAgendaConflictOfInterest.gql'
import meetingConflictsOfInterest from '../../graphql/eboard-interest-graphql/meetingConflictsOfInterest.gql'
import deleteInterestRegisterAgendaItem from '../../graphql/eboard-interest-graphql/deleteInterestRegisterAgendaItem.gql'
import swal from 'sweetalert2'


export default {
    state: {
        interestList: [],
        activeInterestList: [],
        meetingActiveInterestList: [],
        meetingConflictsOfInterest: [],
    },

    mutations: {
        cleanInterest(state) {
            state.interestList = []
        },
        addInterest(state, interestObj) {
            state.interestList.push(interestObj)
        },
        appendInterest(state, interestObj) {
            state.interestList.unshift(interestObj)
        },
        appendActiveInterest(state, interestObj) {
            state.activeInterestList = interestObj
        },
        cleanActiveInterest(state) {
            state.activeInterestList = []
        },
        appendMeetingActiveInterest(state, interestObj) {
            state.meetingActiveInterestList = interestObj
        },
        cleanMeetingActiveInterest(state) {
            state.meetingActiveInterestList = []
        },
        addMeetingConflictsOfInterest(state, interests) {
            state.meetingConflictsOfInterest = interests
        },
        cleanMeetingConflictsOfInterest(state) {
            state.meetingConflictsOfInterest = []
        }
    },

    getters: {
        getMeetingConflictsOfInterest(state) {
            return state.meetingConflictsOfInterest
        }
    },

    actions: {
        async loadBoardInterests(context) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allInterestRegister,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }
            }).then((response) => {
                const interests = response.data.boardInterestRegisters.edges
                context.commit('cleanInterest')

                interests.forEach(interest => {
                    var single = interest.node
                    var object02 = {
                        key: single.primaryKey,
                        avatar: single.interestUser.profileUser.edges[0].node.userProfilePhoto,
                        fullname: single.interestUser.firstName + " " + single.interestUser.lastName,
                        nature: single.interestNature,
                        organisation: single.interestOrganisation,
                        notified: single.interestCreateddate,
                    }
                    context.commit('addInterest', object02)
                });

                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async createBoardInterests(context, data) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: createInterestRegister,
                variables: data

            }).then((response) => {
                const interests = response.data.createInterestRegister.newInterest
                var object02 = {
                    key: interests.primaryKey,
                    avatar: interests.interestUser.profileUser.edges[0].node.userProfilePhoto,
                    fullname: interests.interestUser.firstName + " " + interests.interestUser.lastName,
                    nature: interests.interestNature,
                    organisation: interests.interestOrganisation,
                    notified: interests.interestCreateddate,
                }
                context.commit('appendInterest', object02)
                context.dispatch("showLoadingSpinner", false);

            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            });

        },

        async loadMembersInterests(context) {
            context.commit('cleanActiveInterest')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: membersInterests,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }

            }).then((response) => {
                const interests = response.data.membersInterests
                context.commit('appendActiveInterest', interests)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            });

        },

        async deactivateMemberInterest(context, interest) {
            context.commit('cleanActiveInterest')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: deactivateMemberInterestRegister,
                variables: {
                    interestUser: interest.member,
                    interestKey: interest.interest,
                    boardUniqueId: localStorage.getItem("my-boardUniqueId")
                }

            }).then((response) => {
                const interests = response.data.deactivateMemberInterestRegister.newInterests
                context.commit('appendActiveInterest', interests)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            });
        },

        async loadMeetingConflictsInterests(context, meetingUniqueId) {
            context.commit('cleanMeetingActiveInterest')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                query: meetingInterestsConflicts,
                variables: {
                    meetingUniqueId: meetingUniqueId,
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                }

            }).then((response) => {
                const interests = response.data.meetingInterestsConflicts
                context.commit('appendMeetingActiveInterest', interests)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            });

        },

        async declareAgendaConflictOfInterest(context, data) {
            context.commit('cleanMeetingActiveInterest')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                mutation: declareAgendaConflictOfInterest,
                variables: {
                    memberInterest: data,
                }

            }).then((response) => {
                if (response.data.declareAgendaConflictOfInterest.declared) {
                    swal.fire({
                        toast: true,
                        icon: "success",
                        title: "Interest declared successfully",
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: "error",
                        title: "Fail to declare conflict of interest",
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            });
        },

        async loadMeetingConflictsOfInterest(context, meetingUniqueId) {
            context.commit('cleanMeetingConflictsOfInterest')
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: meetingConflictsOfInterest,
                variables: {
                    meetingUniqueId: meetingUniqueId
                }
            }).then((response) => {
                context.commit('addMeetingConflictsOfInterest', response.data.meetingConflictsOfInterest)
            }).catch(async (error) => {
                console.error(error);
            });
        },

        async deleteInterestRegisterAgendaItem(context, declarationUniqueId) {
            await apolloClient.mutate({
                mutation: deleteInterestRegisterAgendaItem,
                variables: {
                    declarationUniqueId: declarationUniqueId
                }
            }).then((response) => {
                if (response.data.deleteInterestRegisterAgendaItem.response.status) {
                    swal.fire({
                        toast: true,
                        icon: "success",
                        title: response.data.deleteInterestRegisterAgendaItem.response.message,
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    swal.fire({
                        toast: true,
                        icon: "error",
                        title: response.data.deleteInterestRegisterAgendaItem.response.message,
                        animation: false,
                        position: "top-right",
                        showConfirmButton: false,
                        timer: 4000,
                        timerProgressBar: true,
                    })
                }
            })
        },

    }
}
