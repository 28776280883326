import { apolloClient } from "@/vue-apollo";
import getBoardMeetingReports from "../../graphql/eboard-report-builder-graphql/getBoardMeetingReports.gql";
import getCommiteesMeetingReports from "../../graphql/eboard-report-builder-graphql/getCommiteesMeetingReports.gql";
import getDirectivesReports from "../../graphql/eboard-report-builder-graphql/getDirectivesReports.gql";
import getDecisionsReports from "../../graphql/eboard-report-builder-graphql/getDecisionsReports.gql";
import swal from "sweetalert2";

export default {
  state: {
    boardMeetingReports: [],
    committeeMeetingReports: [],
    directiveReports: [],
    decisionsReports: [],
    managementMeetingsReports: [],
  },

  mutations: {
    addCommitteesMeetingReports(state, reports) {
      state.committeeMeetingReports = reports;
    },
    addBoardMeetingReports(state, reports) {
      state.boardMeetingReports = reports;
    },
    addDirectivesReports(state, reports) {
      state.directiveReports = reports;
    },
    addDecisionsReports(state, reports) {
      state.decisionsReports = reports;
    },
  },

  getters: {
    getCommitteeMeetingReports(state) {
      return state.committeeMeetingReports;
    },
    getBoardMeetingReports(state) {
      return state.boardMeetingReports;
    },
    getDirectivesReports(state) {
      return state.directiveReports;
    },
    getDecisionsReports(state) {
      return state.decisionsReports;
    },
  },

  actions: {
    async loadBoardMeetingReports(context,filtering) {
      await apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: getBoardMeetingReports,
          variables: {
            filtering: filtering,
          },
        })
        .then((response) => {
          const results = response.data.getBoardMeetingReports;
          if (results.response.status) {
            context.commit("addBoardMeetingReports", results.data);
          } else {
            swal.fire({
              toast: true,
              icon: "error",
              title: results.response.message,
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          }
        });
    },
    async loadCommitteesMeetingReports(context,filtering) {
      await apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: getCommiteesMeetingReports,
          variables: {
            filtering: filtering,
          },
        })
        .then((response) => {
          const results = response.data.getCommiteesMeetingReports;
          if (results.response.status) {
            context.commit("addCommitteesMeetingReports", results.data);
          } else {
            swal.fire({
              toast: true,
              icon: "error",
              title: results.response.message,
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          }
        });
    },
    async loadDirectivesReports(context,filtering) {
      await apolloClient
          .query({
            fetchPolicy: "no-cache",
            query: getDirectivesReports,
            variables: {
              filtering: filtering,
            },
          })
          .then((response) => {
            const results = response.data.getDirectivesReports;
            if (results.response.status) {
              context.commit("addDirectivesReports", results.data);
            } else {
              swal.fire({
                toast: true,
                icon: "error",
                title: results.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            }
          });
    },
    async loadDecisionsReports(context,filtering) {
      await apolloClient
          .query({
            fetchPolicy: "no-cache",
            query: getDecisionsReports,
            variables: {
              filtering: filtering,
            },
          })
          .then((response) => {
            const results = response.data.getDecisionsReports;
            if (results.response.status) {
              context.commit("addDecisionsReports", results.data);
            } else {
              swal.fire({
                toast: true,
                icon: "error",
                title: results.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            }
          });
    },
  },
};
