<template>
  <v-container fluid>
<!--  <v-card class="scroll" height="400px" style="width: 97%; padding-top: 7px">-->
    <div class="bg-white border rounded-md scroll h-screen">
    <div class="p-2 font-bold text-xl text-white bg-gradient" style="background: linear-gradient(90deg, rgba(30,58,138,1) 0%, rgba(2,209,216,1) 100%);">
      Notes
    </div>
    <v-divider></v-divider>
      <div class="text-center pt-3" >
        <v-btn
          color="#3F51B5"
          depressed
          elevation="2"
          raised
          rounded
          small
          @click="createNotebookPage"
        >
        <v-icon style="color:white" small>
          mdi-sticker-plus-outline
        </v-icon>
        <span style="color:white">  New Note </span>
        </v-btn>
      </div>
      <v-card
        elevation="0"
        v-for="(note, index) in notebookPages"
        :key="index"
      >
        <v-card-subtitle class="cursor-pointer"
        @click="changeSelected(note)">
          <v-layout style="background-color: #edf4ff; padding: 5px">
            <v-flex xs10 md11 max-width="100%">
              <strong
                style="
                  font-size: 13px;
                  font-weight: bold;
                  padding: 5px;
                "
                >
                <v-icon x-small>mdi-sticker-text-outline  </v-icon> {{ index+1 }}. {{ note.pageTitle }}</strong
              >
            </v-flex>
          </v-layout>
        </v-card-subtitle>
      </v-card>

    </div>
 
  </v-container>
</template>

<script>

export default {
  methods: {
    createNotebookPage() {
      let input = {
        pageTitle: 'Page Title',
        pageContent: '<p>Write your note here...</p>',
        pageNotebook: this.$route.params.notebookUniqueId
      }
      this.$store.dispatch('createBoardNotebookPage', input)
    },

    changeSelected(notebook) {
      this.$store.dispatch('changeSelectedBoardNotebookPage', notebook)
    },

  },

  computed: {
    notebookPages() {
      return this.$store.getters.getBoardNotebookPages
    },
  },
   mounted() {
    this.$store.dispatch('loadBoardNotebookPages', this.$route.params.notebookUniqueId)
   }
};
</script>

<style>
</style>