<template>
  <v-card
      id="agendaPanels"
      class="scroll"
      style="width: 97%; padding-top: 10px"
  >
<!--    <span style="font-size: 24px; margin-left: 20px; font-weight: bold">-->
<!--      Agenda of the Meeting-->
<!--    </span>-->
<!--    <v-divider></v-divider>-->
    <v-card-text>
      <div v-if="listOfagendas.length < 1" class="text-center">
        <v-sheet
            class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block"
            color="blue-grey darken-3"
            dark
        >
          <div class="grey--text text--lighten-1 text-body-2 mb-4">
            If your meeting agenda does not apper in this panel please click
            the button bellow to synchronize and open again. . . !
          </div>

          <v-btn
              :loading="loading"
              class="ma-1"
              color="white"
              plain
              outlined
              @click="reloadAgendas"
          >
            Reload agenda
          </v-btn>
        </v-sheet>
      </div>
      <div v-else>
        <v-card
            style="margin: 5px"
            elevation="0"
            v-for="agenda in listOfagendas"
            :key="agenda.AgendaUniqueId"
        >
          <v-card-subtitle>
            <v-layout
            class="rounded-md" style="background-color: #edf4ff; padding: 12px">
              <v-flex xs10 md11 max-width="100%">
                <strong
                    v-if="!hasAccess('can_schedule_meeting')"
                    style="
                    font-size: 16px;
                    font-weight: bold;
                    padding-left: 5px;
                    padding-top: 5px;
                  "
                >{{ agenda.Agenda }}</strong>

                <span
                    v-else-if="hasAccess('can_schedule_meeting') && isEditAgendaKey !== agenda.agendaKey"
                    @blur="
                    (event) => onPurposeInput(event, agenda.agendaKey, 'Title')
                  "
                    style="
                    font-size: 22px;
                    font-weight: bold;
                    padding-left: 5px;
                    padding-top: 5px;
                  "
                >
                  <strong>{{ agenda.Agenda }}</strong>
                </span>

                <span
                    v-else-if="hasAccess('can_schedule_meeting') && isEditAgendaKey === agenda.agendaKey"
                    style="
                    font-size: 16px;
                    font-weight: bold;
                    padding-left: 5px;
                    padding-top: 5px;
                  "
                >
                  <input
                    @change="
                      (event) => onPurposeInput(event, agenda.agendaKey, 'Title')
                    "
                    :placeholder="agenda.Agenda"
                    v-model="agendaName"
                    :key="agenda.agendaKey"
                    :autofocus="autofocus"
                  />
                </span>

              </v-flex>

              <v-flex xs2 md1 max-width="100%">
                <v-menu v-if="hasAccess('can_schedule_meeting') && !HasPublishedAgenda" offset-y app>
  <!--                <template v-slot:activator="{ on, attrs }">-->
  <!--                  <v-btn-->
  <!--                      v-bind="attrs"-->
  <!--                      v-on="on"-->
  <!--                      slot="activator"-->
  <!--                      icon-->
  <!--                      color="indigo"-->
  <!--                  >-->
  <!--                    <v-icon>mdi-dots-vertical</v-icon>-->
  <!--                  </v-btn>-->
  <!--                </template>-->
                  <v-list dense>
                    <v-list-item  link @click="testFunction(agenda.agendaKey)">
                      <v-list-item-title
                      >
                        <v-icon style="height: 16px; font-size: 16px"
                        >mdi-square-edit-outline
                        </v-icon
                        >
                        Edit Agenda
                      </v-list-item-title
                      >
                    </v-list-item
                    >
                    <v-list-item link @click="moveAgenda(agenda.primaryKey, 'UP')">
                      <v-list-item-title
                      >
                        <v-icon style="height: 16px; font-size: 16px"
                        >mdi-arrow-up
                        </v-icon
                        >
                        Move it Up
                      </v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link @click="moveAgenda(agenda.primaryKey, 'DOWN')">
                      <v-list-item-title
                      >
                        <v-icon style="height: 16px; font-size: 16px"
                        >mdi-arrow-down
                        </v-icon
                        >
                        Move it Down
                      </v-list-item-title
                      >
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="deleteAgendas(agenda.agendaKey)">
                      <v-list-item-title
                      >
                        <v-icon style="height: 16px; font-size: 16px"
                        >mdi-delete
                        </v-icon
                        >
                        Delete
                      </v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card-subtitle>

          <v-card-text>
            <AgendaItems
                :agendaKey="agenda.agendaKey"
                @lockScroll="lockScrolling"
                @getAgendaItemDetails="getAgendaItemDetails"
                :agendaId="agenda.AgendaNumber"
                :egandaItems="agenda.AgendaItems"
                :currentMeeting="currentMeeting"
                :HasPublishedAgenda="HasPublishedAgenda"
            />
          </v-card-text>

          <v-card-actions v-if="hasAccess('can_schedule_meeting') && !HasPublishedAgenda">
            <v-spacer></v-spacer>
            <v-menu offset-y app>
              <template  v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    slot="activator"
                    text
                    x-small
                    color="indigo"
                >
                  <v-icon small>mdi-plus</v-icon>
                  Add Agenda
                </v-btn
                >
              </template>
              <v-list dense>
                <v-list-item @click="addNewAgendaItem(agenda.primaryKey, 'Last')">
                  <v-list-item-title
                  >
                    <v-icon style="height: 16px; font-size: 16px"
                    >mdi-air-filter
                    </v-icon
                    >
                    Standard Agenda Item
                  </v-list-item-title
                  >
                </v-list-item
                >
                <v-list-item
                    @click="addVotingNewAgendaItem(agenda.primaryKey, 'Last')"
                >
                  <v-list-item-title
                  >
                    <v-icon style="height: 16px; font-size: 16px"
                    >mdi-vote-outline
                    </v-icon
                    >
                    Vote
                  </v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                    v-if="!meetingHasConfirmMinutes"
                    @click="
                    confimMinutesDialog = true;
                    selectedAgenda = agenda.primaryKey;
                  "
                >
                  <v-list-item-title
                  >
                    <v-icon style="height: 16px; font-size: 16px"
                    >mdi-lock-check
                    </v-icon
                    >
                    Confirm minutes
                  </v-list-item-title
                  >
                </v-list-item>
                <v-list-item v-else disabled>
                  <v-list-item-title
                  >
                    <v-icon style="height: 16px; font-size: 16px"
                    >mdi-lock-check
                    </v-icon
                    >
                    Confirm minutes
                  </v-list-item-title
                  >
                </v-list-item>
                <!-- <v-list-item
                    v-if="!meetingHasInterestRegister"
                    link
                    @click="
                    interestRegisterDialog = true;
                    selectedAgenda = agenda.primaryKey;
                  "
                > -->
  <!--                <v-list-item-title-->
  <!--                >-->
  <!--                  <v-icon style="height: 16px; font-size: 16px"-->
  <!--                  >mdi-shape-->
  <!--                  </v-icon-->
  <!--                  >-->
  <!--                  Interest Register-->
  <!--                </v-list-item-title-->
  <!--                >-->
  <!--              </v-list-item>-->
  <!--              <v-list-item v-else disabled>-->
  <!--                <v-list-item-title-->
  <!--                >-->
  <!--                  <v-icon style="height: 16px; font-size: 16px"-->
  <!--                  >mdi-shape-->
  <!--                  </v-icon-->
  <!--                  >-->
  <!--                  Interest Register-->
  <!--                </v-list-item-title-->
  <!--                >-->
                <!-- </v-list-item> -->
              </v-list>
            </v-menu>
  <!--          <v-spacer></v-spacer>-->
  <!--          <v-btn-->
  <!--              v-if="hasAccess('can_schedule_meeting')"-->
  <!--              @click="addNewAgenda(currentMeeting, agenda.primaryKey)"-->
  <!--              text-->
  <!--              x-small-->
  <!--              color="indigo"-->
  <!--          >-->
  <!--            <v-icon small>mdi-plus</v-icon>-->
  <!--            Section-->
  <!--          </v-btn-->
  <!--          >-->
          </v-card-actions>
        </v-card>
      </div>
    </v-card-text>

    <ConfimMinutesDialog
        @CloseConfimMinutesDialog="confimMinutesDialog =false"
        :confimMinutesDialog="confimMinutesDialog"
        :selectedAgenda="selectedAgenda"
        :lastMeetings="lastMeetings"
    />

    <InterestRegisterAgendaDialog
        @CloseInterestRegisterDialog="interestRegisterDialog=false"
        :interestRegisterDialog="interestRegisterDialog"
        :selectedAgenda="selectedAgenda"
        :lastMeetings="currentMeeting"
    />
  </v-card>
</template>

<script>
import AgendaItems from "./AgendaItems.vue";
import ConfimMinutesDialog from "./ConfimMinutesDialog.vue";
import InterestRegisterAgendaDialog from "./InterestRegisterAgendaDialog.vue";

export default {
  props: ["AgendaBuilderWindow", "currentMeeting", "listOfagendas"],

  components: {
    AgendaItems,
    ConfimMinutesDialog,
    InterestRegisterAgendaDialog,
  },

  data() {
    return {
      selectedAgenda: null,
      interestRegisterDialog: false,
      confimMinutesDialog: false,
      loading: false,

      isEditAgendaKey: "",
      agendaName: "",
      autofocus: false
    };
  },

  methods: {
    testFunction(agendaKey) {
      this.isEditAgendaKey = agendaKey;
      this.autofocus=true
    },

    lockScrolling() {
      const body = document.getElementById("agendaPanels");
      body.style.overflow = "hidden";
      body.style.overflow = "auto";
    },

    getAgendaItemDetails(ItemId) {
      console.log(ItemId);
    },

    closeGreatModel() {
      this.$emit("close");
    },

    async reloadAgendas() {
      this.loading = true;
      await new Promise((resolve) =>
          setTimeout(resolve, this.$router.go(), 3000)
      );
      this.$store.dispatch(
          "loadBestPracticeAgendaTemplates",
          this.currentMeeting
      );
    },

    async onPurposeInput(event, selectedAgenda) {
      var details = {
        newItem: this.agendaName,
        agendaKey: selectedAgenda,
      };
      await this.$store.dispatch("updateAgenda", details);
      this.agendaName = "";
      this.isEditAgendaKey = "";
      this.autofocus = false
    },

    async addNewAgendaItem(agendaKey, Position) {
      await this.$store.dispatch("addAgendaItem", {
        agendaKey: agendaKey,
        Position: Position,
      });
    },

    async addVotingNewAgendaItem(agendaKey, Position) {
      await this.$store.dispatch("addNewVotingAgendaItem", {
        agendaKey: agendaKey,
        Position: Position,
      });
    },

    async addNewAgenda(meetingKey, Position) {
      await this.$store.dispatch("addAgenda", {
        meetingKey: meetingKey,
        Position: Position,
      });
    },

    async deleteAgendas(agendaKey) {
      await this.$store.dispatch("deleteAgendas", {
        meetingKey: this.$store.state.CurrentMeetingStore.currentMeeting,
        agendaKey: agendaKey,
      });
      await this.$store.dispatch(
          "loadBestPracticeAgendaTemplates",
          this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },

    async moveAgenda(agendaUniqueId, position) {
      await this.$store.dispatch("moveMeetingAgenda", {
        agendaUniqueId: agendaUniqueId,
        position: position,
      });
      await this.$store.dispatch(
          "loadBestPracticeAgendaTemplates",
          this.$store.state.MeetingsStore.selectedMeetingDetails.meetingUniqueId
      );
    },

  },

  computed: {
    HasPublishedAgenda() {
      return (this.$store.state.MeetingsStore.selectedMeetingDetails || {})
        .meetingHasPublishedAgenda;
    },
    lastMeetings() {
      return this.$store.state.MeetingsStore.unConfirmMeetings;
    },

    meetingHasConfirmMinutes() {
      return this.$store.state.MeetingsStore.selectedMeetingDetails
          .meetingHasConfirmMinutes;
    },

    meetingHasInterestRegister() {
      return this.$store.state.MeetingsStore.selectedMeetingDetails
          .meetingHasInterestRegister;
    },
  },

  async mounted() {
    await this.$store.dispatch("loadUnConfirmMeetings");
    await this.$store.dispatch("loadMembersInterests");
  },
};
</script>


<style scoped>
.cencontent {
  align-content: center;
}
</style>


<style>
.fixed-position {
  position: fixed;
}

.transparent {
  background-color: white !important;
  opacity: 0.3;
  border-color: transparent !important;
}

.scroll {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>
