<script
  src="../../../store/management-committee-meeting-repository-store/managementCommitteeMeetingRepository.js"></script>
<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p>Documents generated during Management Meetings</p>
      </v-col>
      <v-col class="d-flex" cols="12" sm="4">
        <v-text-field label="Search" append-icon="mdi-magnify"
                      v-model="searchValue"
                      @input="searchMeetingRepository()"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <div class="intro-y" style="padding: 10px" v-for="(repository, index) in managementRepository.data" :key="index">
          <v-card class="mx-auto intro-y" max-width="100%" outlined>
            <v-row>
              <v-col md="12" sm="12">
                <v-card-title>
                  <v-icon size="35" color="rgba(227, 150, 0, 0.56)" class="mr-1">mdi-folder</v-icon>
                  {{ repository.meetingTitle }}
                  <v-spacer></v-spacer>

                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn rounded style="padding: 25px" icon
                             @click="openDocumentViewer(repository.meetingAgendaDocumentPath)">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-note-multiple-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Meeting Agenda</span>
                  </v-tooltip>

                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn rounded style="padding: 25px" icon @click="previewMinutes(repository.meetingUniqueId)">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-file-document-multiple
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Meeting Minutes</span>
                  </v-tooltip>

                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn rounded style="padding: 25px" icon @click="previewDirectives(repository.meetingUniqueId)">
                        <v-icon color="primary" dark v-bind="attrs" v-on="on">
                          mdi-book-multiple
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Meeting Directives</span>
                  </v-tooltip>

                  <v-tooltip bottom color="success">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn rounded style="padding: 25px" icon @click="uploadDocument(repository.meetingUniqueId)"
                             v-if="hasAccess('can_add_management_meeting')">
                        <v-icon color="success" dark v-bind="attrs" v-on="on">
                          mdi-file-upload-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Upload Document</span>
                  </v-tooltip>
                </v-card-title>

                <v-card-text>
                  <v-row align="center" class="mx-">
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <span><v-icon>mdi-paperclip</v-icon> {{ repository.meetingDocumentsCount }} Other Documents</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          style="rgba(38, 27, 216, 0.11)"
                          v-if="repository.meetingSupportingDocuments.length > 0"
                        >
                          <v-list subheader>
                            <v-list-item v-for="(doc, index) in repository.meetingSupportingDocuments" :key="index">
                              <v-list-item-avatar>
                                <v-img
                                  style="width: 90%"
                                  src="/pdf.png"
                                ></v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>
                                  <span link
                                  >{{ doc.documentName }}</span
                                  ></v-list-item-title
                                >
                              </v-list-item-content>

                              <v-list-item-icon>
                                <v-btn icon @click="openDocumentViewer(doc.documentPath)" class="ma-1">
                                  <v-icon color="indigo">mdi-file-eye</v-icon>
                                </v-btn>
                                <v-btn v-if="doc.documentIsUploaded" icon @click="deleteRepositoryDocument(doc.documentUniqueId)" class="ma-1">
                                  <v-icon color="error">mdi-delete</v-icon>
                                </v-btn>
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
      <v-col>
        <v-card-actions>
          <v-btn
              @click="paginateGorvernanceRepository(managementRepository.page.previousPageNumber)"
              v-if="managementRepository.page.hasPreviousPage" outlined small
          >
            <v-icon left>mdi-arrow-left</v-icon>
            Previous Page ({{ managementRepository.page.previousPageNumber }})
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              @click="paginateGorvernanceRepository(managementRepository.page.nextPageNumber)"
              v-if="managementRepository.page.hasNextPage" outlined small
          >
            Next Page ({{ managementRepository.page.nextPageNumber }})
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>

    <DocumentViewer v-if="OpenfileDialog===true" :OpenfileDialog="OpenfileDialog" :docValue="docValue" :title="title"
                    @CloseDocumentViwer="OpenfileDialog=false"/>

    <DocumentUploader v-if="supportDoc===true" :supportDoc="supportDoc" @closeSupportDocumentUploader="closeSupportDocumentUploader"
                      :meetingUniqueId="meetingUniqueId"/>

    <v-dialog
      v-model="isDeleteDocument"
      width="500"
    >
      <v-card>
        <v-card-title class="">
        </v-card-title>

        <v-card-text>
          <div>
            <h1>Confirm to Delete Document: <strong> {{ documentName }}</strong></h1>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="isDeleteDocument = false"
            outlined
            small
          >
            <v-icon>mdi-close</v-icon>
            Cancel
          </v-btn>

          <v-btn
            color="error"
            text
            @click="deleteRepositoryDocument"
            outlined
            small
          >
            <v-icon>mdi-delete</v-icon>
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import managementPreviewManagementMinutes from "@/graphql/management-agendas-graphql/previewMeetingMinutes.graphql";
import previewManagementMeetingDirectives
  from '../../../graphql/management-actions-graphql/previewManagementMeetingDirectives.graphql'
import DocumentViewer from '../management-documents/DocumentViwer.vue'
import {confirmAlert} from '@/services/notificationService'

import DocumentUploader from "@/modules/management_meeting_management/management-repository/DocumentUploader";

export default {
  components: {DocumentViewer, DocumentUploader},
  data() {
    return {
      documentName: "",
      OpenfileDialog: false,
      docValue: "",
      title: "",
      supportDoc: false,
      meetingUniqueId: "",
      documentUniqueId: "",
      isDeleteDocument: false,
      searchValue: '',
      repositoryObj: {
        pageNumber: 1,
        search: null
      }
    };
  },

  methods: {
    async closeSupportDocumentUploader() {
      this.supportDoc = false;
      await this.loadManagementMeetingRepository();
    },

    async paginateGorvernanceRepository(pageNumber) {
      this.repositoryObj.pageNumber = pageNumber;
      await this.$store.dispatch("loadAllManagementMeetingRepository", this.repositoryObj)
    },

    async searchMeetingRepository() {
      this.repositoryObj.search = this.searchValue
      this.repositoryObj.pageNumber = 1
      await this.$store.dispatch("loadAllManagementMeetingRepository", this.repositoryObj);
    },

    uploadDocument(meetingUniqueId) {
      this.meetingUniqueId = meetingUniqueId;
      this.supportDoc = true
    },

    previewMinutes(meetingUniqueId) {
      this.previewLoading = true
      this.$apollo.mutate({
        mutation: managementPreviewManagementMinutes,
        variables: {
          meetingUniqueId: meetingUniqueId,
        }
      }).then((response) => {
        this.previewLoading = false
        this.docValue = "/" + response.data.managementPreviewManagementMinutes.minutesDocumentPath
        this.$emit('closeGreatModel');
        this.OpenfileDialog = true
      })
    },

    previewDirectives(meetingUniqueId) {
      this.previewLoading = true
      this.$apollo.mutate({
        mutation: previewManagementMeetingDirectives,
        variables: {
          meetingUniqueId: meetingUniqueId,
        }
      }).then((response) => {
        this.previewLoading = false
        this.docValue = "/" + response.data.previewManagementMeetingDirectives.directiveDocumentPath
        this.$emit('closeGreatModel');
        this.OpenfileDialog = true
      })
    },

    openDocumentViewer(meetingAgendaDocumentPath) {
      this.previewLoading = true
      this.docValue = meetingAgendaDocumentPath;
      this.OpenfileDialog = true
    },

    async deleteRepositoryDocument(documentUniqueId) {
      confirmAlert("Are you sure?", "Delete").then(async (data) => {
        if (data.isConfirmed) {
          await this.$store.dispatch('deleteManagementRepositoryDocument', documentUniqueId);
          await this.$store.dispatch("loadAllManagementMeetingRepository");
        }
      })
    },

    async loadManagementMeetingRepository() {
      await this.$store.dispatch("loadAllManagementMeetingRepository", this.repositoryObj);
    }
  },

  computed: {
    managementRepository() {
      return this.$store.state.ManagementRepositoryStore.managementRepository;
    },
  },

  async mounted() {
    await this.loadManagementMeetingRepository();
  },
};
</script>

<style>
</style>
