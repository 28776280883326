import {apolloClient} from '@/vue-apollo'
import addManagementMeetingRepresentative
    from '../../graphql/management-meeting-representative-graphql/addManagementMeetingRepresentative.graphql'
import getManagementMeetingRepresentatives
    from '../../graphql/management-meeting-representative-graphql/getManagementMeetingRepresentatives.graphql'
import updateManagementMeetingRepresentative
    from '../../graphql/management-meeting-representative-graphql/updateManagementMeetingRepresentative.graphql'

import {errorAlert, successAlert} from "@/services/notificationService";


export default {
    state: {
        managementMeetingRepresentatives: [],
    },
    getters: {
        getManagementMeetingRepresentatives(state) {
            return state.managementMeetingRepresentatives
        },
    },
    mutations: {
        clearManagementMeetingRepresentatives(state) {
            state.managementMeetingRepresentatives = []
        },
        saveManagementMeetingRepresentatives(state, representatives) {
            state.managementMeetingRepresentatives = [...representatives]
        },
        addManagementMeetingRepresentative(state, representatives) {
            for (const representative of representatives) {
                state.managementMeetingRepresentatives.push(representative)
            }
        },
    },
    actions: {
        async loadManagementMeetingRepresentatives(context, meetingUniqueId = null) {
            context.commit('clearManagementMeetingRepresentatives')
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getManagementMeetingRepresentatives,
                variables: {
                    meetingUniqueId
                }
            }).then(async (response) => {
                let representatives = response.data.getManagementMeetingRepresentatives
                context.commit('saveManagementMeetingRepresentatives', representatives)
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async createManagementMeetingRepresentative(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: addManagementMeetingRepresentative,
                variables: {
                    input
                }
            }).then(async (response) => {
                let result = response.data.addManagementMeetingRepresentative
                if (result.response.code === 9000) {
                    context.commit('addManagementMeetingRepresentative', result.data)
                    await successAlert(result.response.message)
                } else {
                    await errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })
        },
        async editManagementMeetingRepresentatives(context, input) {
            await context.dispatch("showLoadingSpinner", true);
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: updateManagementMeetingRepresentative,
                    variables: {
                        input
                    }
                }).then( async (response) => {
                    await context.dispatch("showLoadingSpinner", false);
                    let result = response.data.updateManagementMeetingRepresentative
                    if (result.response.code === 9000) {
                        context.commit('saveManagementMeetingRepresentatives', result.data)
                        await successAlert(result.response.message)
                    } else {
                        await errorAlert(result.response.message)
                    }
                })

        },
    },

}