import {apolloClient} from "@/vue-apollo";
import getSystemAdminstrators from "@/graphql/admin-panel-graphql/administrators-graphql/getSystemAdministrators.gql.graphql";
import {errorAlert, successAlert} from "@/services/notificationService";
import createSystemAdminstrator from "@/graphql/admin-panel-graphql/administrators-graphql/addSystemAdministrator.gql.graphql";
import updateSystemAdminstrator
    from "@/graphql/admin-panel-graphql/administrators-graphql/updateSystemAdminstrator.gql.graphql";
import deleteSystemAdminstrator
    from "@/graphql/admin-panel-graphql/administrators-graphql/deleteSystemAdminstrator.gql.graphql";


export default {
    state: {
        systemAdministratorsList: [],
    },

    mutations: {
        clearSystemAdmins(state) {
            state.systemAdministratorsList = []
        },

        addSystemAdmin(state, adminObj){
            state.systemAdministratorsList.push(adminObj)
        },

        deleteSystemAdminstrator(state, userProfileUniqueId){
            var temp_list = []
            state.systemAdministratorsList.forEach(systemAdmin => {
                console.log(systemAdmin);
                if (systemAdmin.userProfileUniqueId !== userProfileUniqueId) {
                    temp_list.push(systemAdmin)
                }
            })
        },

        saveSystemAdmin(state, admin){
            state.systemAdministratorsList = admin
        },

        updateSystemAdminstrator(state, adminObj){
            state.systemAdministratorsList = state.systemAdministratorsList.map((admin) => {
                return admin.id == adminObj.id ? adminObj : admin
            })
        }

    },

    getters: {
        getSystemAdminList(state){
            return state.systemAdministratorsList
        },


    },

    actions: {
        async loadSystemAdmins(context) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getSystemAdminstrators
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                const results = response.data.getSystemAdminstrators
                if (results.response.code == 9000) {
                    context.commit('saveSystemAdmin', results.data)
                } else {
                    errorAlert(results.response.message)
                }
            })
        },

        async createSystemAdmins(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createSystemAdminstrator,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createSystemAdminstrator
                if (result.response.code == 9000) {
                    context.commit('addSystemAdmin', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

        async editSystemAdmin(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateSystemAdminstrator,
                variables: {
                    input: {
                        ...input,
                    }
                }
            }).then( async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateSystemAdminstrator
                console.log(result)
                if (result.response.code == 9000) {
                    context.commit('updateSystemAdminstrator', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },

        async deleteSystemAdmin(context, userProfileUniqueId){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteSystemAdminstrator,
                variables: {
                    userProfileUniqueId: userProfileUniqueId
                }
            }).then( async (response)=>{
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.deleteSystemAdminstrator
                console.log(result)
                if (result.response.code == 9000) {
                    context.commit('deleteSystemAdminstrator', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })
        },


    }
}