<template>
  <div>
    <v-col class="flex items-center justify-between">
      <div class="flex items-center">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                right
                small
                outlined
                rounded
                fab
                color="primary white--text"
                style="margin: 5px"
                @click="$router.go('-1')"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <h2 class="text-xl ml-2">Report Details</h2>
      </div>
      <v-btn
          class="intro-y"
          :loading="loading3"
          color="success"
          small
          @click="weeklyReportSubmition"
      >
        <v-icon small>mdi-book-check-outline</v-icon>
        Submit
      </v-btn>
    </v-col>
    <v-card-text>
      <v-card
          class="mx-auto intro-y"
          max-width="100%"
          style="padding-left: 30px; padding-right: 30px"
      >

        <v-card-title class="mt-5">
          <v-row>
            <v-spacer></v-spacer>
            <v-badge
                left
                color="white"
                transition="slide-x-transition"
            >
              <v-hover v-model="hover">
                <img
                    alt="Emblem"
                    src="/arm.png"
                    style="width: 100%; height: 80px"
                />
              </v-hover>
            </v-badge>
            <v-spacer></v-spacer>

            <v-col sm="6">
              <div id="reportTopHeaderSub">
                <center>
                  <h3>{{ reportTemplateDetails.templateTitle }}</h3>
                </center>
                <center>
                  <h3>
                    {{ reportTemplateDetails.templateInstitutionName | safe }}
                  </h3>
                </center>
                <div class="flex items-center justify-center">
                  <h3
                      style="
                    text-align: center;
                    align-content: center;
                    align-self: center;
                  "
                  >
                    {{ reportTemplateDetails.templateReportName }}
                  </h3>
                  <v-menu :close-on-content-click="false" top :offset-y="offset">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-bind="attrs"
                          x-small
                          v-on="on"
                          color="primary"
                          class="ml-2"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card width="700">
                      <v-card-text class="flex">
                        <input
                            type="text"
                            class="mt-2 w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Report Name"
                            style="border: 1px solid black"
                            required
                            v-model="reportTemplateReportName"
                        />
                        <v-btn
                            class="mr-3 mb-1 ml-4 mt-5 indigo--text"
                            @click="editTemplateReportName"
                            small
                            outlined
                            icon
                        >
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>

            <v-badge
                color="white"
                right
                transition="slide-x-transition"
            >
              <v-hover>
                <img
                    :src="
                    MEDIA_SERVER +
                    '' +
                    reportTemplateDetails.templateInstitutionLogo
                  "
                    alt="Institution Logo"
                    style="width: 100%; height: 80px"
                />
              </v-hover>
            </v-badge>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-title>

        <v-divider class="mx-4"></v-divider>

        <v-card-text>
          <v-list :key="compl_key" subheader three-line>
            <v-list-item
                class="intro-x"
                v-for="(component, index) in reportTemplateDetails.templateComponents"
                :key="index"
            >
              <v-list-item-content>
                <v-list-item-title
                    v-text="component.componentTitle"
                    :id="component.primaryKey"
                ></v-list-item-title>

                <v-list-item-content>
                  <div
                      v-html="component.componentBody"
                      :id="component.componentUniqueId"
                  >
                    {{ component.componentBody }}
                  </div>
                </v-list-item-content>
              </v-list-item-content>

              <v-list-item-action>
                <v-row>
                  <v-btn
                      v-if="component.componentIsTable"
                      icon
                      x-small
                      class="mx-1"
                      @click="
                      editTableContents(
                        component
                      )
                    "
                  >
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                      v-else
                      icon
                      x-small
                      class="mx-1"
                      @click="
                      editSectionContents(component)
                    "
                  >
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                      icon
                      x-small
                      class="mx-1"
                      @click="deleteSection(component.primaryKey)"
                  >
                    <v-icon color="error">mdi-trash-can</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <ReportSupportingDocument
            :data="templateUniqueId"
            :template="reportTemplateDetails"
            :type="'directiveReport'"
            class="col-12"
        />
        <div class="flex justify-end mb-4">

          <v-btn
              class="ma-2 intro-x"
              color="primary"
              small
              flat
              title="Add Section"
              @click="createNewSection"
          >
            <v-icon class="mr-1">mdi-text-box-plus-outline</v-icon>
            Add Section
          </v-btn>
          <v-btn
              class="ma-2 intro-x"
              color="pink"
              small
              title="Add Table"
              @click="TableEditor = true"
          >
            <v-icon color="white" class="mr-1">mdi-table-large-plus</v-icon>
            <span class="text-white">Add Table</span>
          </v-btn>
        </div>
      </v-card>
    </v-card-text>


    <NewAction
        :OpenActionEditor="ActionEditor"
        :actionName="`Add Action`"
        @CloseActionEditor="ActionEditor = false"
    />

    <ReportSection
        :ActionTitle="ActionTitle"
        :OpenSectionEditor="OpenSectionEditor"
        :component-template-id="reportTemplateDetails.primaryKey"
        :componentObj="componentObj"
        @CloseSectionEditor="CloseSectionEditorfunction"
        @afterCreateSection="updateComponents"
        @afterUpdatingSection="updatingSectionSignal"
        @updatingAfterDeleteSection="updatingAfterDeleteSection"
    />

    <ReportTable
        :ActionTitle="TableActionTitle"
        :OpenTableEditor="TableEditor"
        :component-obj="componentObj"
        :componentTemplateId="reportTemplateDetails.primaryKey"
        :editAction="editAction"
        :tableBodyData="tableBodyData"
        :tableHeaderData="tableHeaderData"
        @CloseTableEditor="CloseTableEditorfunction"
        @afterCreateSection="updateComponents"
        @afterUpdatingSection="updatingSectionSignal"
        @updatingAfterDeleteSection="updatingAfterDeleteSection"
    />


    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          v-if="reportTemplateDetails.templateIsReport"
          class="ma-2"
          color="accent"
          outlined
          small
      >
        <v-icon small>mdi-archive-eye-outline</v-icon>
        Preview
      </v-btn>


      <v-spacer></v-spacer>
    </v-card-actions>
  </div>
</template>

<script>
import NewAction from "../management-actions/NewAction.vue";
import ReportSection from "./ReportSection.vue";
import ReportTable from "./ReportTable.vue";
import ReportSupportingDocument from "./ReportSupportingDocument.vue";

export default {

  components: {
    NewAction,
    ReportSection,
    ReportTable,
    ReportSupportingDocument,
  },

  data() {
    return {
      openDialog: false,
      reportKey: null,
      compl_key: 1,
      panelKeyGround: 80,
      componentId: null,
      editAction: false,
      newSectionKey: 0,
      tableHeaderData: null,
      zIndex: 0,
      topheaderhoverd: false,
      loading3: false,
      hover: false,
      hover2: false,
      sectionhover1: false,
      MinuteNoteEditor: false,
      ActionEditor: false,
      TableEditor: false,
      OpenSectionEditor: false,
      ActionTitle: "Add Report Section",
      TableActionTitle: "Add Report Table",
      reportComponents: [],
      report_details: null,
      report_top_details: null,
      report_components_details: [],
      tableBodyData: null,
      componentObj: {},
      reportTemplateReportName: ""
    };
  },

  methods: {
    createNewSection() {
      this.ActionTitle = "Add Report Section";
      this.editAction = false;
      this.OpenSectionEditor = true;
      this.componentObj = null
      this.componentId = null;
    },

    async weeklyReportSubmition() {
      this.$store.dispatch(
          "weeklyReportSubmition",
          this.templateUniqueId
      ).then(() => this.$router.go(-1))
    },

    editSectionContents(component) {
      this.componentObj = component;
      this.ActionTitle = "Edit Report Section";
      this.OpenSectionEditor = true;
    },

    editTableContents(component) {
      this.TableActionTitle = "Edit Report Table";
      this.editAction = true;
      this.TableEditor = true;
      this.componentObj = component
    },

    CloseSectionEditorfunction() {
      this.ActionTitle = "Add Report Section";
      this.OpenSectionEditor = false;
    },

    CloseTableEditorfunction() {
      this.TableActionTitle = "Add Report Table";
      this.editAction = false;
      this.tableBodyData = null;
      this.TableEditor = false;
    },

    updateComponents(newComponents) {
      this.newSectionKey++;
      const component = newComponents.data.newManagementSection.components;
      this.report_components_details.push({
        componentId: component.primaryKey,
        sectionBody: component.componentUniqueId,
        sectionHeader: component.componentHeader,
        hovered: component.componentHovered,
        componentTitle: component.componentTitle,
        componentBody: component.componentBody,
        componentIsTable: component.componentIsTable,
      });
      this.CloseSectionEditorfunction();
    },

    updatingSectionSignal(object) {
      const objIndex = this.report_components_details.findIndex(
          (obj) => obj.componentId === object.componentId
      );
      let stoper = 0;
      this.report_components_details.forEach((component) => {
        if (stoper === objIndex) {
          document.getElementById(component.sectionHeader).innerHTML =
              object.componentTitle;
          document.getElementById(component.sectionBody).innerHTML =
              object.componentBody;
        }
        stoper++;
      });
      this.newSectionKey++;

      this.CloseSectionEditorfunction();
    },

    updatingAfterDeleteSection(object) {
      const objIndex = this.report_components_details.findIndex(
          (obj) => obj.componentId === object.componentId
      );
      this.report_components_details.splice(objIndex, 1);
      this.tableBodyData = null;
      this.OpenSectionEditor = false;
    },

    deleteSection(componentId) {
      this.$store.dispatch('deleteReportTemplateComponent', {
        templateUniqueId: this.$route.params.templateUniqueId,
        componentId: componentId
      })
    },

    editTemplateReportName() {
      this.$store.dispatch('editReportTemplateName', {
        templateUniqueId: this.$route.params.templateUniqueId,
        templateReportName: this.reportTemplateReportName
      })
    },

    async getReportTemplateDetails() {
      await this.$store.dispatch("getReportTemplateDetails", this.templateUniqueId);
    }
  },

  computed: {
    reportTemplateDetails() {
      return this.$store.getters.getManagementReportTemplateDetails;
    },
    templateUniqueId() {
      return this.$route.params.templateUniqueId
    },
    weekId() {
      return this.$route.params.weekId
    },
  },

  mounted() {
    this.getReportTemplateDetails();
    this.reportTemplateReportName = this.reportTemplateDetails.templateReportName
  }
};
</script>

<style>
figure table,
figure table > thead > tr > th,
figure table > tbody > tr > td {
  border: 1px solid black;
  border-collapse: collapse;
}

figure table > thead > tr > th {
  background-color: #e8eaeb;
}
</style>
