<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p></p>
      </v-col>
      <v-col class="d-flex" cols="12" sm="4">
        <v-breadcrumbs :items="pathList"></v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" sm="5"> </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" sm="1">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="callToCreateFolders(folderName, false)"
              v-bind="attrs"
              v-on="on"
              right
              fab
              small
              icon
              outlined
              color="primary white--text"
              style="margin: 5px"
            >
              <v-icon>mdi-folder-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Add New Folder</span>
        </v-tooltip>
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="createFolderDialog = !createFolderDialog"
              v-bind="attrs"
              v-on="on"
              right
              fab
              small
              icon
              outlined
              color="primary white--text"
              style="margin: 5px"
            >
              <v-icon>mdi-cloud-upload-outline</v-icon>
            </v-btn>
          </template>
          <span>Upload New Documents</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-layout justify-start wrap>
          <v-card
            style="margin: 5px"
            v-for="folder in folderAndFilesList"
            :key="folder.primaryKey"
            max-width="344"
            link
            :to="'/eboard_governance_repository/' + folder.folderUniqueId"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-avatar tile size="90">
                <v-img
                  lazy-src="@/assets/folder_lazy.png"
                  max-height="100%"
                  max-width="100%"
                  src="@/assets/folder.png"
                >
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="text">{{ folder.folderName }}</div>
                <v-list-item-subtitle>
                  <strong>Files:</strong> {{ folder.listOfFiles.length }} <br />
                  <strong>Created:</strong> {{ folder.folderDate | formatDate }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-menu offset-x top right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    @click="
                      callToCreateFolders(
                        folder.folderName,
                        false,
                        folder.primaryKey
                      )
                    "
                    dense
                    link
                  >
                    <v-item-text>
                      <v-icon>mdi-folder-plus-outline</v-icon>
                      Create New Folder
                    </v-item-text>
                  </v-list-item>
                  <v-list-item dense link>
                    <v-item-text>
                      <v-icon>mdi-cloud-upload-outline</v-icon>
                      Upload File
                    </v-item-text>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item dense link>
                    <v-item-text>
                      <v-icon>mdi-delete</v-icon>
                      Delete Folder
                    </v-item-text>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-card>
        </v-layout>

        <v-col class="d-flex" cols="12" sm="4"> Files List </v-col>
           <v-card class="mx-auto" max-width="100%" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">My Annotation</div>
              <v-list-item-title class="text-h5 mb-1">
                Headline 5
              </v-list-item-title>
              <v-list-item-subtitle
                >Greyhound divisely hello coldly
                fonwderfully</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
            > <v-img src="@/assets/note.png"></v-img> </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text> Button </v-btn>
          </v-card-actions>
        </v-card>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="15"
            :total-visible="7"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <CreateNewFolder
      :parentFolderKey="parentFolderKey"
      :isRoot="isRoot"
      :parentFolder="parentFolderName"
      :createFolderDialog="createFolderDialog"
      @CloseCreateFolderDialog="createFolderDialog = false"
    />
    <DocumentViwer
      v-if="OpenfileDialog == true"
      :docValue="docValue"
      :OpenfileDialog="OpenfileDialog"
      :title="`View Governance Repository Document`"
      @CloseDocumentViwer="OpenfileDialog = false"
    />
  </v-container>
</template>

<script>
import CreateNewFolder from "./CreateNewFolder.vue";
import DocumentViwer from '../management-documents/DocumentViwer.vue'
export default {
  components: {
    CreateNewFolder,
    DocumentViwer
  },
  data() {
    return {
      docValue: null,
      OpenfileDialog: false,
      createFolderDialog: false,
      parentFolderName: "",
      isRoot: false,
      page: 1,
    };
  },
  methods: {
    callToCreateFolders(parentFolderName, isRoot) {
      this.parentFolderName = parentFolderName;
      this.isRoot = isRoot;
      this.createFolderDialog = !this.createFolderDialog;
    },
    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      this.OpenfileDialog = true;
    },
  },
  computed: {
    parentFolderKey() {
      return this.$route.params.id;
    },
    folderName() {
      return this.$route.params.folderName;
    },
    pathList() {
      return [
        {
          text: "Dashboard",
          disabled: false,
          href: "/eboard_governance_repository",
        },
        {
          text: this.folderName,
          disabled: true,
          href: "breadcrumbs_link_1",
        },
      ];
    },
    folderAndFilesList() {
      return this.$store.state.RepositoryStore.governanceRepositoryFolders;
    },
    governanceRepositoryFiles() {
      return this.$store.state.RepositoryStore.governanceRepositoryFiles;
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "loadAllFolderContentsByUniqueId",
      this.$route.params.unique_id
    );
  },
};
</script>

<style>
</style>