var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2"},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"right":"","outlined":"","small":"","color":"primary white--text"},on:{"click":function($event){_vm.memberDialog = !_vm.memberDialog}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-plus")]),_vm._v(" Create User ")],1)]}}])},[_c('span',[_vm._v("Create New User")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"right":"","small":"","outlined":"","color":"success white--text"},on:{"click":function($event){_vm.OpenNewAccessForm = !_vm.OpenNewAccessForm}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lock-plus")]),_vm._v(" Create Access Level ")],1)]}}])},[_c('span',[_vm._v("Create New Access Level")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"right":"","small":"","outlined":"","color":"orange white--text"},on:{"click":function($event){_vm.isViewRoles = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v(" View Access Levels ")],1)]}}])},[_c('span',[_vm._v("View Access Levels")])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.icon,staticClass:"p-2 shadow-lg bg-lime"},[_c('v-icon',{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('Table',{attrs:{"listType":'board',"tableData":_vm.boardMembers},on:{"openRevokeAccessModal":_vm.openRevokeAccessModal}})],1),_c('v-tab-item',[_c('Table',{attrs:{"listType":'management',"tableData":_vm.nonBoardMembers},on:{"openRevokeAccessModal":_vm.openRevokeAccessModal}})],1)],1)],1)],1),_c('AddNewUser',{key:_vm.userKey,attrs:{"OpenNewUserForm":_vm.OpenNewUserForm},on:{"close":function($event){_vm.OpenNewUserForm = !_vm.OpenNewUserForm;
      _vm.userKey++;
      _vm.memberDialogKey++; _vm.loadUsers()}}}),_c('RevokeAccessModal',{attrs:{"isRevokeAccess":_vm.isRevokeAccess,"revokeAccessUser":_vm.revokeAccessUser},on:{"closeRevokeAccessModal":function($event){_vm.isRevokeAccess = false;
      _vm.revokeAccessUser = '';}}}),_c('ViewRoles',{attrs:{"isViewRoles":_vm.isViewRoles},on:{"closeViewRoles":function($event){_vm.isViewRoles = false}}}),_c('AddNewRole',{key:_vm.roleKey,attrs:{"OpenNewAccessForm":_vm.OpenNewAccessForm},on:{"close":function($event){_vm.OpenNewAccessForm = !_vm.OpenNewAccessForm;
      _vm.roleKey++;}}}),_c('v-dialog',{key:_vm.memberDialogKey,attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.memberDialog),callback:function ($$v) {_vm.memberDialog=$$v},expression:"memberDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-account-plus-outline")]),_c('span',[_vm._v(" Add Board Members / Users")])],1),_c('v-card-text',[_c('span',[_vm._v("You can create new member or invite the member that already using e-Board ")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.row),callback:function ($$v) {_vm.row=$$v},expression:"row"}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-alert',{attrs:{"elevation":"4","outlined":""}},[_c('v-radio',{attrs:{"label":"Invite Member to Board","value":"radio-1"},on:{"change":function($event){_vm.inviteMember = !_vm.inviteMember;
                  _vm.disabled = true;}}})],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-alert',{attrs:{"elevation":"4","outlined":""}},[_c('v-radio',{attrs:{"label":"Create New User","value":"radio-2"},on:{"change":function($event){_vm.inviteMember = false;
                  _vm.disabled = false;}}})],1)],1)],1),(_vm.inviteMember === true)?_c('v-text-field',{attrs:{"clearable":"","label":"Search member email to invite to Board","type":"text","hint":_vm.searchResults},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,1969053175)},[_vm._v(" Type Member Email, Click search button to find if user exist in e-Board database ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-fade-transition',{attrs:{"leave-absolute":""}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"24","color":"info","indeterminate":""}}):_c('v-icon',[_vm._v("mdi-email-sync")])],1)]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"flat":"","icon":"","rounded":"","color":"primary"},on:{"click":_vm.searchBoardMambers}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',[_vm._v(" mdi-magnify")])],1)]},proxy:true}],null,false,175017851),model:{value:(_vm.searchedEmail),callback:function ($$v) {_vm.searchedEmail=$$v},expression:"searchedEmail"}}):_vm._e(),(_vm.inviteMember === true)?_c('div',_vm._l((_vm.inviteMemberList),function(member){return _c('v-checkbox',{key:member.primaryKey,attrs:{"true-value":member.primaryKey,"label":"" + (member.memberFullName) +
              '' +
              " ( " + (member.memberEmail) +
              ' - ' +
              (member.memberInstitutionShortform) + " )"},on:{"change":function($event){return _vm.addInvitee($event, member.primaryKey)}}})}),1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(_vm.progressShown)?_c('v-progress-circular',{staticStyle:{"padding-right":"10px"},attrs:{"indeterminate":"","color":"primary","width":2}}):_vm._e(),_c('v-btn',{attrs:{"color":"pink","text":"","right":"","small":"","outlined":""},on:{"click":function($event){_vm.memberDialog = false}}},[_vm._v(" Cancel ")]),(_vm.inviteMember)?_c('v-btn',{attrs:{"color":"success","disabled":_vm.invitationBtn,"right":"","small":""},on:{"click":function($event){return _vm.sendBoardInvitations()}}},[_vm._v(" Send Invitation "),_c('v-icon',[_vm._v("mdi-email-fast-outline")])],1):_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disabled,"right":"","small":""},on:{"click":function($event){_vm.progressShown = true;
            _vm.OpenNewUserFormWindow();}}},[_vm._v(" Add User "),_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }