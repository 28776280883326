<template>
  <v-container>
    <v-alert
      icon="mdi-tune-variant"
      prominent
      dense
      text
      type="info"
      color="primary"
      border="left"
      class="ml-2 mr-2 mt-4"
    >
      Configure look and feel for your exported documents
    </v-alert>
    <v-container>
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="boardSetting().includeInstitutionLogo"
            label="Include Institution logo"
            color="red"
            hide-details
            @change="updateBoardSettingsValues('include_institution_logo', $event, false)"
          ></v-checkbox>
          <v-checkbox
            v-model="boardSetting().exportTimestamp"
            label="Export Timestamp"
            color="red darken-3"
            hide-details
            @change="updateBoardSettingsValues('export_timestamp', $event, false)"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="boardSetting().showExportBy"
            label="Export by"
            color="indigo"
            hide-details
            @change="updateBoardSettingsValues('show_export_by', $event, false)"
          ></v-checkbox>
          <v-checkbox
            v-model="boardSetting().allowInstitutionName"
            label="Allow Institution Name on Second Header"
            color="indigo darken-3"
            hide-details
            @change="updateBoardSettingsValues('allow_institution_name', $event, false)"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <label for="color">Set Template Border Color </label>
          <v-color-picker
            v-model="boardSetting().templateBorderColor"
            class="ma-2"
            hide-canvas
            hide-inputs
            @update:color="updateBoardSettingsValues('template_border_color', $event, true)"
          ></v-color-picker>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      templateColor: "#0062FF4D",
    };
  },

  methods: {
    async updateBoardSettingsValues(editedPart, currentValue, isColor) {
      let Setting = {};

      if (isColor) {
        Setting = {
          settingType: editedPart,
          settingsValue: false,
          settingIsColor: true,
          settingsColorValue: currentValue.hex
        }
      } else {
        Setting = {
          settingType: editedPart,
          settingsValue: currentValue,
          settingIsColor: false,
          settingsColorValue: ""
        }
      }

      await this.$store.dispatch('SettingsStore/updateSystemSettings', Setting);
      await this.$store.dispatch('SettingsStore/loadSMSConfigurations', {root: true})
    },
  },


};
</script>

<style>
</style>
