<template>
    <v-container fluid>
        <v-row class="mt-5 mx-3">
            <v-btn color="primary" class="mx-2 text-capitalize" elevation="2"  @click="createBoard = true"><v-icon small>mdi-plus-network</v-icon> Create Board</v-btn> 
            
            <v-btn color="indigo" class="mx-2 text-capitalize" dark elevation="2"  @click="createUser = true"> <v-icon small>mdi-account-plus</v-icon> &nbsp; Create User</v-btn>
            <v-spacer></v-spacer>
        </v-row>
        <v-row justify="center" class="mb-5">
            <v-dialog
              v-model="createUser"
              persistent
              max-width="600px"
            >
              
              <v-card>
                <v-card-title>
                  <span class="text-h5">Create User</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          label="First name*"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          label="Middle name"
                          hint="This field is optional"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          label="Last name*"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Email*"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Password*"
                          type="password"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="createUser = false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="#3f51b5"
                    @click="createUser = false"
                    dark
                  >
                    Create User
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center" class="mb-5">
            <v-dialog
              v-model="createBoard"
              persistent
              max-width="600px"
            >
              
              <v-card>
                <v-card-title>
                  <span class="text-h5">Add New Institution</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          label="Institution short Name*"
                          required
                        ></v-text-field>
                      </v-col> 
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          label="Institution Full Name*"
                          required
                        ></v-text-field>
                      </v-col> 
                    <v-col
                        cols="12"
                        sm="12"
                    >
                        <v-select
                        v-model="e7"
                        :items="names"
                        label="Select Board Admin"
                        multiple
                        chips
                        hint="Person who will manage settings and users for this board"
                        persistent-hint
                        ></v-select>
                    </v-col>
                    <v-col>
                      <v-checkbox
                                    v-model="checkbox"
                                    color="indigo"
                                    :label="`Activate Now`"
                                    ></v-checkbox>
                    </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="createBoard = false"
                    class="text-capitalize"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="#3f51b5"
                    dark
                    @click="createBoard = false"
                    class="text-capitalize"
                  >
                  Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-row>
        <v-row class="mt-5 mx-2">
            <v-col sm="12" md="4" lg="3">
              <router-link to="/settings/eboard_clients" class="text-decoration-none">
                <v-alert class="rounded-lg pa-5" elevation="2" border="left"
                icon="mdi-clipboard"
                prominent
                colored-border
                color="#3f51b5">
                    <v-row class="text-h3 d-flex justify-center">
                          230
                    </v-row>
                    <v-row class="d-flex justify-center">
                          Total Board
                    </v-row>
                </v-alert>
              </router-link>
            </v-col>
            <v-col sm="12" md="4" lg="3">
                <v-alert class="rounded-lg pa-5" elevation="2" border="left"
                icon="mdi-account-group"
                prominent
                colored-border
                color="primary">
                    <v-row class="text-h3 d-flex justify-center">
                          23
                    </v-row>
                    <v-row class="d-flex justify-center">
                          Total Comittees
                    </v-row>
                </v-alert>
            </v-col>
            <v-col sm="12" md="4" lg="3">
              <router-link to="/settings/user_management" class="text-decoration-none">
                  <v-alert class="rounded-lg pa-5" elevation="2" border="left"
                  icon="mdi-account-multiple"
                  prominent
                  colored-border
                  color="success">
                      <v-row class="text-h3 d-flex justify-center">
                            2312
                      </v-row>
                      <v-row class="d-flex justify-center">
                            Total Users
                      </v-row>
                  </v-alert>
                </router-link>
            </v-col>
            <v-col sm="12" md="4" lg="3">
                <v-alert class="rounded-lg pa-5" elevation="2" border="left"
                icon="mdi-video-input-component"
                prominent
                colored-border
                color="#3f51b5">
                    <v-row class="text-h3 d-flex justify-center">
                          10
                    </v-row>
                    <v-row class="d-flex justify-center">
                          Total Meetings
                    </v-row>
                </v-alert>
            </v-col>

        </v-row>
        <v-row class="pa-5">
            <v-spacer></v-spacer>
            <v-col md="12">
                <canvas id="planet-chart" style="max-height: 400px;"></canvas>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
            <div>
              </div>
    </v-container>
   
</template>

<script>
    import Chart from 'chart.js/auto'

export default {
    data() {
      return {
        createUser: false,
        createBoard:false,
        e6: [],
        e7: [],
        names: [
          'Fredrick Chami', 'Jackson Bakari', 'Germanus Xavier', 'Martin Blasi',
        ],
        planetChartData: planetChartData,
        }
    },
    mounted() {
        const ctx = document.getElementById('planet-chart');
        new Chart(ctx, this.planetChartData);
    }
}

export const planetChartData = {
  type: "bar",
  data: {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Monday"],
    datasets: [
      {
        label: "Scheduled Meetings",
        data: [5, 20, 13, 29, 79, 82, 27, 14],
        backgroundColor: "rgba(63,73,93,.5)",
        borderColor: "rgba(63,73,93,.5)",
        borderWidth: 1
      },
      {
        label: "Meetings Occured",
        data: [2, 10, 17, 42, 49, 80, 20, 93],
        backgroundColor: "rgba(71, 183,132,.5)",
        borderColor: "rgba(71, 183,132,.5)",
        borderWidth: 1
      }
    ]
  },
  options: {
    responsive: true,
    lineTension: 1,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            padding: 25
          }
        }
      ]
    }
  }
};
</script>

<style>

 
</style>






