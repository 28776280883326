function createEnum(values) {
    const enumObject = {};
    for (const val of values) {
        enumObject[val] = val;
    }
    return Object.freeze(enumObject);
}





export const ManagementMemberLevelTypeEnum = createEnum(['CHAIRPERSON', 'SECRETARY', 'MEMBER'])










