<template>
  <div class="text-center">
    <v-dialog v-model="OpenReminderDialog" persistent width="60%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="success">mdi-alarm-multiple</v-icon>
          Send resolution reminder to board members
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                Resolution due date:
                <strong>{{ resolutionDueDate | formatDate }}</strong>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Reminder Date *"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFormatted"
                    no-title
                    @input="menu1 = false"
                    :min="todaydate"
                    :max="resolutionDueDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div
                  class="border border-slate-200/60 dark:border-darkmode-400 rounded-md p-3"
                >
                  <div
                    class="font-medium flex items-center border-b border-slate-200/60 dark:border-darkmode-400"
                  >
                    <label
                      for="validation-form-6"
                      class="form-label w-full flex flex-col sm:flex-row"
                    >
                      <span
                        class="sm:ml-auto mt-1 sm:mt-0 text-xs text-slate-500"
                        >Required, at least 100 characters</span
                      >
                    </label>
                  </div>
                  <div class="mt-5">
                    <v-textarea
                      prepend-icon="mdi-text-recognition"
                      outlined
                      name="input-7-4"
                      v-model="reminderMessage"
                      label="Reminder Message"
                      :counter="100"
                      value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                    ></v-textarea>
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseReminderDialog')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn color="success" outlined small @click="sendDirectiveReminder">
            <v-icon small>mdi-floppy</v-icon> Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "OpenReminderDialog",
    "actionName",
    "CloseReminderDialog",
    "resolutionDueDate",
    "directiveUniqueId",
  ],

  components: {},

  data: () => ({
    menu1:false,
    dateFormatted: null,
    reminderMessage: null,
    todaydate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),
  methods: {
    async sendDirectiveReminder() {
      var reminderData = {
        directiveUniqueId: this.directiveUniqueId,
        reminderDate: this.dateFormatted,
        reminderMessage: this.reminderMessage,
      };
      this.dateFormatted = null;
      this.reminderMessage = null;
      await this.$store.dispatch("SendDirectivesReminder", reminderData);
      this.$emit("CloseReminderDialog");
    },
  },
};
</script>
