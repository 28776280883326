import {  apolloClient } from '@/vue-apollo'
import allMyManagementDecisions from '../../graphql/management-decisions-graphql/allMyManagementDecisions.gql'
import {errorAlert} from "@/services/notificationService";

export default {

  state: {
   managementAssignedDecisionList:[],
  },

  mutations: {
    cleanManagementDecision(state){
      state.managementAssignedDecisionList=[]
  },
  addManagementDecision(state, decision) {
    state.managementAssignedDecisionList = decision
},
  },

  actions: {
      async loadManagementAssignedDecisions(context, input = null) {
          await context.dispatch("showLoadingSpinner", true);
          context.rootState.ResolutionStore.progressReportDocs = []
          apolloClient.query({
              fetchPolicy: 'no-cache',
              query: allMyManagementDecisions,
              variables: {
                  managementUniqueId: localStorage.getItem("my-managementUniqueId"),
                  ...input
              }
          }).then(async (result) => {
              const {response, page, data} = result.data.allMyManagementDecisions;

              if (response.status) {
                  context.commit('addManagementDecision', {data: data, page: page});
              } else {
                  await errorAlert(response.message);
              }
              await context.dispatch("showLoadingSpinner", false);
          }).catch(async () => {
              await context.dispatch("showLoadingSpinner", false);
          })
      },
  },
}