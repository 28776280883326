<template>
  <v-row justify="center">
    <v-dialog persistent v-model="folderAccessMatrixDialog" max-width="60%">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$emit('closeFolderAccessMatrixDialog')"
          >
            Close
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-title class="text-h7">
          <v-icon style="margin-right: 10px">mdi-lock-outline</v-icon>
          Folder Access Control Matrix
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="11">
              <v-autocomplete
                v-model="selectedUsers"
                auto-select-first
                chips
                clearable
                deletable-chips
                multiple
                small-chips
                label="Select user to give access"
                :items="usersList"
                item-text="name"
                item-value="id"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="1">
              <v-btn
                @click="saveSelectedUsersForAccessControl"
                fab
                small
                color="primary"
                ><v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Full Name</th>
                  <th class="text-left">Write</th>
                  <th class="text-left">Read</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in accessGrantees" :key="item.name">
                  <td>{{ item.granteeName }}</td>
                  <td>
                    <v-icon color="primary" v-if="item.granteeWrite"
                      >mdi-checkbox-outline</v-icon
                    >
                    <v-icon v-else color="pink"
                      >mdi-checkbox-blank-outline</v-icon
                    >
                  </td>
                  <td>
                    <v-icon color="primary" v-if="item.granteeRead">mdi-checkbox-outline</v-icon>
                    <v-icon v-else color="pink">mdi-checkbox-blank-outline</v-icon>
                  </td>
                  <td>
                    <v-btn @click="editUsersAccessControl(item)" icon
                      ><v-icon color="info">mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn
                      @click="removeUsersForAccessControl(item.primaryKey)"
                      icon
                      ><v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editAccessControlDialog"   width="30%">
      <v-card>
        <v-card-title>
          Edit {{selectedEditItem.granteeName}} Access Control
        </v-card-title>
        <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
          <v-switch
            v-model="writeEditAccess"
            :label="`Write Access`"
            @change="updateUserFolderAccessControl('Write',selectedEditItem.primaryKey)"
          ></v-switch>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
          <v-switch
            v-model="readEditAccess"
            :label="`Read Access`"
            @change="updateUserFolderAccessControl('Read',selectedEditItem.primaryKey)"
          ></v-switch>
          </v-col>
        </v-row>
      </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeEditUsersAccessControl()" color="primary" text> Save Changes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";

export default {
  props: ["folderAccessMatrixDialog", "folderUniqueId"],
  data() {
    return {
      readEditAccess:null,
      writeEditAccess:null,
      selectedEditItem:{},
      editAccessControlDialog:false,
      checked: true,
      unChecked: false,
      selectedUsers: [],
      folderName: "New Folder",
    };
  },
  computed: {
    ...mapGetters({
      systemUsers: "getBoardMembers",
      accessGrantees: "folderAccessGrentees",
    }),
    usersList() {
      var list = [];
      this.systemUsers.forEach((user) => {
        list.push({
          name:
            user.userProfileTitle + " " + user.firstName + " " + user.lastName,
          id: user.id,
        });
      });
      return list;
    },
  },
  methods: {
    async closeEditUsersAccessControl(){
      await this.$store.dispatch("loadPeopleWithFolderAccess",this.folderUniqueId);
      this.editAccessControlDialog=false
    },
    editUsersAccessControl(item){
      this.selectedEditItem=item
      this.editAccessControlDialog=true
    },
    async updateUserFolderAccessControl(access,accessId){
      console.log(access,accessId);
      this.$store.dispatch('updateUserFolderAccessControl',{access:access,accessId:accessId})
    },
    async removeUsersForAccessControl(accessId) {
      console.log(accessId);
      await this.$store.dispatch("removeUserAccessFromFolder", accessId);
      await this.$store.dispatch(
        "loadPeopleWithFolderAccess",
        this.folderUniqueId
      );
    },
    async saveSelectedUsersForAccessControl() {
      if (this.selectedUsers.length < 1) {
        swal.fire({
          toast: true,
          icon: "error",
          title: "Please select users for granting access",
          animation: false,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        var accessUsers = [];

        this.selectedUsers.forEach((user) => {
          accessUsers.push({
            userId: user,
          });
        });
        await this.$store.dispatch("grantFolderAccesstoUsers", {
          accessFolder: this.folderUniqueId,
          accessUsers: { userList: accessUsers },
        });
        this.selectedUsers = [];
        this.$emit("closeFolderAccessMatrixDialog");
      }
    },
  },
};
</script>