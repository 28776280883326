<template>
  <v-dialog v-model="startVideoCallInviteeDialog" width="40%" persistent>
    <v-card>
      <div class="d-flex flex-no-wrap justify-space-between">
        <div style="width: 100%">
          <v-card-title class="text-h5" style="color: rgba(6, 4, 40, 1)">
            <v-icon>mdi-account-group-outline</v-icon> Add user to the
            call</v-card-title
          >

          <v-card-text>
            <v-text-field
              v-model="meetingTheme"
              label="Meeting theme (Optional but recommended)"
              dense
              outlined
            ></v-text-field>
          </v-card-text>

          <v-card-text>
            <v-autocomplete
              v-model="boardUserInvitees"
              :items="loadedMemebrs"
              item-text="name"
              item-value="id"
              outlined
              dense
              chips
              small-chips
              label="Users from this Board"
              multiple
            ></v-autocomplete>

            <v-text-field
              dense
              label="Add invitee email"
              placeholder="Write email and press plus icon"
              outlined
              :append-icon="'mdi-plus'"
              @click:append="appendInviteesList"
              v-model="inviteeEmail"
              :rules="emailRules"
            ></v-text-field>
          </v-card-text>
          <v-card-text>
            <p v-if="inviteesList.length > 0">Invitee List</p>
            <v-chip
              v-for="invitee in inviteesList"
              :key="invitee"
              close
              @click:close="removeInviteeFromList(invitee)"
              close-icon="mdi-close"
              color="blue"
              filter
              link
              outlined
              small
              class="mr-1 mt-1"
              >{{ invitee }}</v-chip
            >
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="$emit('endVideoInviteesCallDialog')"
              color="pink"
              class="ml-2 mt-5"
              outlined
              rounded
              small
            >
              <v-icon small>mdi-phone-remove-outline</v-icon>
              CANCEL MEETING
            </v-btn>
            <v-btn
              @click="startVideoConferenceInvitation"
              color="success"
              class="ml-2 mt-5"
              rounded
              small
            >
              <v-icon small>mdi-phone-ring</v-icon>
              START MEETING
            </v-btn>
          </v-card-actions>
        </div>

        <v-avatar class="ma-3" size="125" tile>
          <v-img src="videoinvitees.png"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["startVideoCallInviteeDialog"],

  data: () => ({
    items: ["foo", "bar", "fizz", "buzz"],
    values: [],
    value: null,
    inviteesList: [],
    inviteeEmail: null,
    emailRules: [],
    meetingTheme:"",
    boardUserInvitees:[]
  }),
  computed: {
    loadedMemebrs() {
      var members = [];
      this.$store.state.MembersStore.boardMembers.forEach((member) => {
        members.push({
          id: member.id,
          name: member.firstName + " " + member.lastName,
        });
      });
      return members;
    },
  },
  methods: {
    appendInviteesList() {
      if (this.inviteeEmail != null) {
        this.inviteesList.push(this.inviteeEmail);
        this.inviteeEmail = null;
      }
    },
    removeInviteeFromList(invitee) {
      const index = this.inviteesList.indexOf(invitee);
      if (index > -1) {
        this.inviteesList.splice(index, 1);
      }
    },
    async startVideoConferenceInvitation() {
      await this.$store.dispatch("showLoadingSpinner", false);
      var boardUsers = [];
      this.boardUserInvitees.forEach((user) => {
        boardUsers.push({
          userId: user,
        });
      });

      var otherInvitees = [];
      this.inviteesList.forEach((user) => {
        otherInvitees.push({
          inviteeEmail: user,
        });
      });

      var newVideoCall = {
        meetingTheme: this.meetingTheme,
        boardUniqueId: localStorage.getItem("my-boardUniqueId"),
        boardInvitees: boardUsers,
        otherInvitees: otherInvitees,
      };

      await this.$store.dispatch("startVideoCalling", newVideoCall);

      this.$emit("endVideoInviteesCallDialog");
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
  watch: {
    inviteeEmail: function (mail) {
      // inviteeEmail is the exact name used in v-model
      if (mail !== "") {
        this.emailRules = [
          (v) =>
            v.match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) || "Invalid Email address",
        ];
      } else if (mail === "") {
        this.emailRules = [];
      }
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardMambers");
  },
};
</script>

<style>
</style>
