<template>
  <v-card width="100%">
    <v-card-title v-if="!meetingHasPublishedAgenda" class="flex items-center">
      <v-menu :close-on-content-click="false" :value="agendaTitleMenu" top :offset-y="offset" ref="agendaTitle">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mr-3 mb-2 ml-2 mt-5"
            small
            icon
            color="indigo"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>

        <v-card width="700">
          <v-card-text class="flex">
            <input
              type="text"
              class="mt-2 w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Agenda Name"
              style="border: 1px solid black"
              required
              v-model="selectedAgenda.agendaTitle"
            />
            <v-btn
              class="mr-3 mb-1 ml-4 mt-5 indigo--text"
              @click="$refs.agendaTitle.save();onPurposeInput(selectedAgenda)"
              small
              outlined
              icon
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-menu>

      <strong>
        <span class="maincolor" style="padding-left: 10px"
        >{{ selectedAgenda.agendaTitle }}
        </span>
      </strong>

    </v-card-title>

    <v-card-title v-else>
      <strong>
        <span class="maincolor" style="padding-left: 10px">{{
            selectedAgenda.agendaTitle
          }}</span></strong
      >
    </v-card-title>


    <v-card-text>
      <v-container>
        <v-row justify-space-around>
          <ManagementCommitteeAgendaDetailsTop/>
        </v-row>
      </v-container>
      <!--      <ManagementCommitteeSupportingDocs-->
      <!--          v-if="!hasPublishedAgenda"-->
      <!--          :data="selectedAgenda"-->
      <!--          type="Agenda"-->
      <!--      />-->
      <ManagementCommitteeSupportingDocs
        v-if="hasAccess('can_schedule_meeting') && !hasPublishedAgenda"
        :data="selectedAgenda"
        type="Agenda"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="meetingHasPublishedAgenda"
        class="ma-2"
        color="accent"
        outlined
        small
        @click="previewAgendas"
      >
        Preview
        <v-icon small>mdi-archive-eye-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="!meetingHasPublishedAgenda"
        :loading="loading3"
        color="success"
        small
        @click="publishUnpublishAgendas(true)"
      >
        Publish Agenda
        <v-icon small>mdi-upload-multiple</v-icon>
      </v-btn>
      <v-btn
        v-if="meetingHasPublishedAgenda"
        color="warning"
        small
        @click="publishUnpublishAgendas(false)"
      >
        Un-Publish Agenda
        <v-icon small>mdi-download-multiple</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>

    <DocumentViwer
      v-if="OpenfileDialog === true"
      :OpenfileDialog="OpenfileDialog"
      :docValue="docValue"
      :title="`View Attachment`"
      @CloseDocumentViwer="OpenfileDialog = false"
    />
  </v-card>
</template>

<script>
import ManagementCommitteeAgendaDetailsTop from "./ManagementCommitteeAgendaDetailsTop.vue";
import {mapGetters} from "vuex";
import ManagementCommitteeSupportingDocs
  from "@/modules/management_committee/management_committee_agenda/ManagementCommitteeSupportingDocs.vue";
// import AgendaSupportingDocs from "./AgendaSupportingDocs.vue";
// import VotingAgendaTopDetails from "./VotingAgendaTopDetails.vue";
// import ConfirmMinutesAgendaTopDetails from "./ConfirmMinutesAgendaTopDetails.vue";
import DocumentViwer from '@/modules/management_meeting_management/management-documents/DocumentViwer.vue'

export default {
  name: "ManagementAgendaDetails",
  props: ["currentMeeting", "meetingHasPublishedAgenda"],

  components: {
    ManagementCommitteeAgendaDetailsTop,
    ManagementCommitteeSupportingDocs,
    // VotingAgendaTopDetails,
    // ConfirmMinutesAgendaTopDetails,
    DocumentViwer,
  },

  data() {
    return {
      file: "",
      docValue: "",
      OpenfileDialog: false,
      loading3: false,
      dragging: false,
      presenter: "No Presenter",
      duration: 0,
      Purpose: "",

      agendaTitleMenu: false,

      isEditItemName: false,
      agendaTitle: "",
      isEditItemKey: "",
      autofocus: false,
    };
  },

  methods: {
    previewAgendas() {
      this.docValue = this.selectedMeeting.meetingDocumentPath
      this.OpenfileDialog = true;
    },
    async ConfirmMeetingMinutes(itemKey) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("confirmMeetingMinutes", itemKey);
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    setIsEditItemName() {
      this.autofocus = true;
      this.isEditItemName = true;
      this.isEditItemKey = this.selectedAgenda.itemKey;
    },

    clearIsEditItemName() {
      this.autofocus = false;
      this.isEditItemName = false;
      this.isEditItemKey = "";
      this.agendaTitle = "";
    },

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },

    createFile(file) {
      if (!file.type.match("pdf.*")) {
        alert("please select PDF file");
        this.dragging = false;
        return;
      }

      if (file.size > 100000000) {
        alert("please check file size no over 5 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      console.log(this.file);
      this.dragging = false;
    },

    removeFile() {
      this.file = "";
    },

    setPresenter(presenter) {
      this.presenter = presenter;
    },

    onInput(event) {
      var cleared = event.target.innerText.replace(/[^\d.-]/g, "");
      console.log(cleared);
      this.duration = cleared;
    },

    clearAllUnwantedChar(event) {
      event.target.innerText = this.duration;
    },

    switchMemberNamesWithNames() {
      return this.managementCommitteeMembers.find((member) => member.firstName + " " + member.lastName === this.selectedAgenda.agendaPresenter)?.id
    },

    async onPurposeInput(selectedAgenda) {
      let input = {
        agendaUniqueId: selectedAgenda.id,
        agendaTitle: selectedAgenda.agendaTitle,
        agendaPresenter: this.switchMemberNamesWithNames(),
        agendaPurpose: selectedAgenda.agendaPurpose,
        agendaDuration: selectedAgenda.agendaDuration,
      };

      await this.$store.dispatch("editManagementCommitteeAgenda", input);
      this.agendaTitleMenu = false
    },

    // onVottingPurposeInput(event, selectedAgenda, updatePlace) {
    //   console.log(selectedAgenda, updatePlace);
    //   this.Purpose = event.target.innerText;
    //   var details = {
    //     newPurpose: this.Purpose,
    //     newItem: selectedAgenda,
    //     updatePlace: updatePlace,
    //   };
    //   this.$store.dispatch("updateVottingAgendaItemsDetails", details);
    // },

    async publishUnpublishAgendas(publishStatus) {
      publishStatus ? await this.$store.dispatch("publishManagementCommitteeMeetingAgendas", this.currentMeeting)
        : await this.$store.dispatch("unPublishManagementCommitteeMeetingAgendas", this.currentMeeting);
    },


    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      console.log(this.docValue);
      this.OpenfileDialog = true;
    },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
    selectedAgenda() {
      return this.$store.getters.getSelectedManagementCommitteeAgenda;
    },
    hasPublishedAgenda() {
      return this.$store.getters.getSelectedManagementCommitteeMeetings.meetingHasPublishedAgenda || false
    },
    selectedMeeting() {
      return this.$store.getters.getSelectedManagementCommitteeMeetings
    },

    activeInterestList() {
      return this.$store.state.InterestStore.meetingActiveInterestList;
    },
    ...mapGetters({
      managementCommitteeMembers: "getManagementCommitteeMembers",
    }),
  },

  async mounted() {
    await this.$store.dispatch(
      "loadMeetingConflictsInterests",
      this.$route.params.meeting_uuid
    );
    this.$store.dispatch('loadManagementCommitteeMembers')
  },
};
</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}

.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>
