<template>
  <div class="text-center">
    <v-dialog v-model="isVoteResultsDialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5">
          Vote Results
        </v-card-title>

        <v-card-text>
          <v-treeview :items="unanonymousVoteReults" v-slot:prepend="{ item }">
            <v-icon v-if="item.id === 1"> mdi-thumb-down-outline </v-icon>
            <v-icon v-if="item.id === 2"> mdi-thumb-up-outline </v-icon>
            <v-icon v-if="item.id === 3"> mdi-minus-circle </v-icon>
          </v-treeview>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="$emit('CloseVoteResults')"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["isVoteResultsDialog", "selectedAgenda"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    unanonymousVoteReults() {
      let items = [];
      let votersOpposeList = this.$store.state.VotesStore.unanonymousVotes?.votersOpposeList;
      let votersSupportList = this.$store.state.VotesStore.unanonymousVotes?.votersSupportList;
      let votersAbstainList = this.$store.state.VotesStore.unanonymousVotes?.votersAbstainList;

      let votersOpposeListChildren = [];
      let votersSupportListChildren = [];
      let votersAbstainListChildren = []

      votersOpposeList.forEach(item => {
        votersOpposeListChildren.push({id: Math.random(), name: item.voterFirstName + ' ' + item.voterLastName})
      });
      votersSupportList.forEach(item => {
        votersSupportListChildren.push({id: Math.random(), name: item.voterFirstName + ' ' + item.voterLastName})
      });
      votersAbstainList.forEach(item => {
        votersAbstainListChildren.push({id: Math.random(), name: item.voterFirstName + ' ' + item.voterLastName})
      });

      items.push(
          {
            id: 1,
            name: `Oppose(${this.$store.state.VotesStore.unanonymousVotes.votersOppose}) :`,
            children: votersOpposeListChildren,
          },
          {
            id: 2,
            name: `Support(${this.$store.state.VotesStore.unanonymousVotes.votersSupport}) :`,
            children: votersSupportListChildren,
          },
          {
            id: 3,
            name: `Abstain(${this.$store.state.VotesStore.unanonymousVotes.votersAbstain}) :`,
            children: votersAbstainListChildren,
          },
      );

      return items
    },
  },
};
</script>