import {apolloClient} from '@/vue-apollo'
import allMyBoardDecisions from '../../graphql/eboard-decisions-graphql/allMyBoardDecisions.gql'
import exportBoardDecisions from '../../graphql/eboard-decisions-graphql/exportBoardDecisions.gql'
import {errorAlert} from "@/services/notificationService";

export default {

    state: {
        assignedDecisions: {page: {}, data:[]},
        exportedBoardDecisions: {}
    },

    mutations: {
        cleanDecision(state) {
            state.assignedDecisions = {}
        },
        cleanExportedBoardDecisions(state) {
            state.exportedBoardDecisions = {}
        },
        addDecision(state, decision) {
            state.assignedDecisions = decision
        },
        setExportedBoardDecisions(state, decision) {
            state.exportedBoardDecisions = decision
        },
    },

    actions: {
        async loadAssignedDecisions(context,filters) {
            await context.dispatch("showLoadingSpinner", true);
            context.commit('cleanDecision')
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allMyBoardDecisions,
                variables: {
                   filtering: {
                       boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                       meetingUniqueId: filters.meetingUniqueId,
                       resolutionUniqueId: filters.resolutionUniqueId,
                       pageNumber: filters.pageNumber,
                   }
                }
            }).then(async (resp) => {
                const {response, data, page} = resp.data.allMyBoardDecisions
                if (response.status) {
                    context.commit('addDecision', {data, page})
                } else {
                    await errorAlert(response.message);
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error);
            })
        },
        async loadExportedBoardDecisions(context,filters) {
            context.commit('cleanExportedBoardDecisions')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: exportBoardDecisions,
                variables: {
                   filtering: {
                       boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                       meetingUniqueId: filters.meetingUniqueId,
                       resolutionUniqueId: filters.resolutionUniqueId,
                       pageNumber: filters.pageNumber,
                   }
                }
            }).then((response) => {
                let result = response.data.exportBoardDecisions
                context.commit('setExportedBoardDecisions', result.data)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error);
            })
        }
    },
}
