var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"text-lg font-weight-medium"},[_vm._v(" List of all Institutions")]),_c('v-spacer'),_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.institutionNameQuery),expression:"institutionNameQuery"}],staticClass:"form-control py-3 px-4 w-full lg:w-64 box pr-10",attrs:{"type":"text","placeholder":"Search by name ..."},domProps:{"value":(_vm.institutionNameQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.institutionNameQuery=$event.target.value}}}),_c('i',{staticClass:"w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0 text-slate-500",attrs:{"data-lucide":"search"}})]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 my-2",attrs:{"color":"indigo","dark":"","outlined":"","small":""},on:{"click":_vm.clearLogoFullName}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("   New Institution")],1)]}}])},[_c('span',[_vm._v("Add New Institution")])])],1),_c('div',{staticClass:"row"},_vm._l((_vm.getInstitutions),function(institution){return _c('div',{key:institution.id,staticClass:"col-md-6  m-2 intro-y"},[_c('div',{staticClass:"p-5 bg-white flex shadow-sm w-100 items-center"},[_c('v-img',{staticClass:"mr-5",attrs:{"max-width":"8%","src":_vm.MEDIA_SERVER+institution.institutionLogo}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9 flex-row"},[_c('h2',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(institution.institutionShortform))]),_c('span',{},[_vm._v(_vm._s(institution.institutionDescriptions))]),_vm._v(" "),_c('br'),_c('b',[_vm._v("Admin:")]),_vm._v(" "),_c('span',{},[_vm._v(_vm._s(institution.institutionAdminUsername))]),_vm._v(" "),_c('br'),_c('b',[_vm._v("Category:")]),_vm._v(" "),_c('span',{},[_vm._v(_vm._s(institution.institutionCategory.categoryName))])]),_c('div',{staticClass:"col-md-3 justify-center align-content-center"},[_c('v-row',{staticClass:"mt-2 justify-space-around"},[_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-warning btn-sm p-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.editInstitutions(institution)}}},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Institution")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#3F51B5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-sm p-1",staticStyle:{"background-color":"#3F51B5"},attrs:{"type":"button"},on:{"click":function($event){return _vm.editInstitutionsAdmin(institution)}}},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Assign Admin")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#9FE080"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-sm p-1",staticStyle:{"background-color":"#9FE080"},attrs:{"type":"button"},on:{"click":function($event){return _vm.editInstitutionsCategory(institution)}}},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-home-group")])],1)]}}],null,true)},[_c('span',[_vm._v("Shift Category")])])],1)],1)])],1)])}),0),_c('add-new-institution',{attrs:{"newInstitution":_vm.newInstitution},on:{"close-dialog":function($event){_vm.newInstitution=false}}}),_c('edit-institution',{attrs:{"edit-institution":_vm.editInstitution,"institution-unique-id":_vm.institutionUniqueId,"institution-shortform":_vm.institutionShortform,"institution-descriptions":_vm.institutionDescriptions,"institution-admin-username":_vm.institutionAdminUsername,"institution-logo":_vm.institutionLogo,"category-unique-id":_vm.categoryUniqueId},on:{"close-dialog":function($event){_vm.editInstitution=false}}}),_c('edit-institution-admin',{attrs:{"edit-institution-admin":_vm.editInstitutionAdmin,"institution-unique-id":_vm.institutionUniqueId,"institution-shortform":_vm.institutionShortform},on:{"close-dialog":function($event){_vm.editInstitutionAdmin=false}}}),_c('edit-institution-category',{attrs:{"edit-institution-category-dialog":_vm.editInstitutionCategoryDialog,"institution-unique-id":_vm.institutionUniqueId},on:{"close-dialog":function($event){_vm.editInstitutionCategoryDialog=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }