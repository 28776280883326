<template>
  <v-card tile class="w-full">
    <v-card-title v-if="!meetingHasPublishedAgenda" class="flex items-center">
      <v-menu :close-on-content-click="false" top :offset-y="offset">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              class="mr-3 mb-2 ml-2 mt-5"
              small
              icon
              color="indigo"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>

        <v-card width="700">
          <v-card-text class="flex">
            <input
                type="text"
                class="mt-2 w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Agenda Name"
                style="border: 1px solid black"
                required
                v-model="selectedAgenda.agendaTitle"
            />
            <v-btn
                class="mr-3 mb-1 ml-4 mt-5 indigo--text"
                @click="onPurposeInput(selectedAgenda)"
                small
                outlined
                icon
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-menu>

      <strong>
        <span class="maincolor" style="padding-left: 10px"
        >{{ selectedAgenda.agendaTitle }}
        </span>
      </strong>

    </v-card-title>
    <v-card-title v-else>
      <strong>
        <span class="maincolor" style="padding-left: 10px">{{
            selectedAgenda.agendaTitle
          }}</span></strong
      >
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row justify-space-around>
          <ManagementCommitteeAgendaDetailsTop/>
        </v-row>
      </v-container>
    </v-card-text>


    <center><p style="padding-bottom: 0px"
               v-if="!selectedAgendaNotes.id">Add a Minute...</p></center>

    <v-card-actions style="padding-top: 0px">
      <v-spacer></v-spacer>

      <v-btn
          class="ma-2"
          color="primary"
          outlined
          small
          @click="loadPreviousMeetingNotes('Add New Note')"
      >
        <v-icon small>mdi-file-document</v-icon>
        Note
      </v-btn>
      <v-btn
          class="ma-2"
          color="primary"
          outlined
          small
          @click="loadPreviousMeetingRecommendations('Add New Recommendation')"
      >
        <v-icon small>mdi-file-document</v-icon>
        Recommendation
      </v-btn>


      <v-spacer></v-spacer>

      <ManagementCommitteeMinuteNote
          :OpenMinuteNoteEditor="MinuteNoteEditor"
          :selectedAgenda="selectedAgenda"
          @CloseMinuteNoteEditor="MinuteNoteEditor = false"
          :noteTitle="noteTitle"
      />

      <ManagementCommitteeRecommendation
          :openMinuteRecommendationEditor="MinuteRecommendationEditor"
          :selectedAgenda="selectedAgenda"
          @closeMinuteRecommendationEditor="MinuteRecommendationEditor = false"
          :recommendationModelTitle="recommendationModelTitle"
      />

    </v-card-actions>

    <v-card-text>

      <v-card
          v-if="selectedAgendaNotes.id"
          class="mx-auto my-2 pa-3 agenda blocked"
          max-width="100%"
          outlined
      >
        <template slot="progress">
          <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title>
          Note:
          <v-spacer></v-spacer>
          <v-btn
              @click="loadPreviousMeetingNotes('Edit Note')"
              icon
              color="info"
          >
            <v-icon
            >
              mdi-pencil-circle
            </v-icon>
          </v-btn>
          <v-btn
              @click="deleteMeetingNotes"
              icon
              color="red"
          >
            <v-icon
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-html="selectedAgendaNotes.notesDescriptions"></div>
        </v-card-text>
      </v-card>

    </v-card-text>
    <v-card-text>

      <v-card
          v-if="selectedAgendaRecommendations.id"
          class="mx-auto my-2 pa-3 agenda blocked"
          max-width="100%"
          outlined
      >
        <template slot="progress">
          <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
          ></v-progress-linear>
        </template>
        <v-card-title>
          Recommendation:
          <v-spacer></v-spacer>
          <v-btn
              @click="loadPreviousMeetingRecommendations('Edit Recommendation')"
              icon
              color="info"
          >
            <v-icon
            >
              mdi-pencil-circle
            </v-icon>
          </v-btn>
          <v-btn
              @click="deleteMeetingNotes"
              icon
              color="red"
          >
            <v-icon
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="font-bold text-xl">{{selectedAgendaRecommendations.recommendationTitle}}</div>
          <div v-html="selectedAgendaRecommendations.recommendationDescriptions"></div>
        </v-card-text>
      </v-card>
    </v-card-text>

  </v-card>
</template>

<script>
import ManagementCommitteeAgendaDetailsTop
  from "@/modules/management_committee/management_committee_agenda/ManagementCommitteeAgendaDetailsTop.vue";
import ManagementCommitteeMinuteNote from "./ManagementCommitteeMinuteNote.vue";
import ManagementCommitteeRecommendation from "./ManagementCommitteeRecommendation.vue";

import {mapGetters} from "vuex";

export default {
  name: "ManagementCommitteeAgendaMinutes",
  components: {
    ManagementCommitteeAgendaDetailsTop,
    ManagementCommitteeMinuteNote,
    ManagementCommitteeRecommendation,
  },

  data() {
    return {
      previewLoading: false,
      loading3: false,
      publishAgendas: null,
      MinuteNoteEditor: false,
      MinuteRecommendationEditor: false,
      ActionEditor: false,
      DecisionEditor: false,
      OpenfileDialog: false,
      docValue: '',
      title: '',
      noteTitle: '',
      recommendationModelTitle: '',
      selectedDirective: null,
      actionName: '',
      decisionName: '',
      selectedDecision: null
    };
  },

  computed: {
    meetingHasPublishedAgenda() {
      return this.$store.getters.getSelectedManagementCommitteeMeetings.meetingHasPublishedAgenda || false
    },
    selectedAgendaNotes() {
      return this.$store.getters.getManagementCommitteeAgendaNotes
    },
    selectedAgendaRecommendations() {
      return this.$store.getters.getManagementCommitteeAgendaRecommendation
    },
    selectedAgenda() {
      return this.$store.getters.getSelectedManagementCommitteeAgenda;
    },
    ...mapGetters({
      managementCommitteeMembers: "getManagementCommitteeMembers",
    }),
  },

  methods: {
    switchMemberNamesWithNames() {
      return this.managementCommitteeMembers.find((member) => member.firstName + " " + member.lastName === this.selectedAgenda.agendaPresenter).id
    },
    async onPurposeInput(selectedAgenda) {
      let input = {
        agendaUniqueId: selectedAgenda.id,
        agendaTitle: selectedAgenda.agendaTitle,
        agendaPresenter: this.switchMemberNamesWithNames(),
        agendaPurpose: selectedAgenda.agendaPurpose,
        agendaDuration: selectedAgenda.agendaDuration,
      };

      await this.$store.dispatch("editManagementCommitteeAgenda", input);
    },
    async deleteMeetingNotes() {
      let input = {
        notesUniqueId: this.selectedAgendaNotes.id
      }
      await this.$store.dispatch("deleteManagementCommitteeAgendaNote", input);
    },
    async loadPreviousMeetingNotes(title) {
      this.noteTitle = title;
      this.MinuteNoteEditor = true;
    },
    async loadPreviousMeetingRecommendations(title) {
      this.recommendationModelTitle = title;
      this.MinuteRecommendationEditor = true;
    },
  },

  async mounted() {
    // await this.$store.dispatch('loadAllManagementMinuteNotes', this.selectedAgenda.itemKey)
    // await this.$store.dispatch('loadAllManagementDecisionMinutes', this.selectedAgenda.itemKey)
    // await this.$store.dispatch('loadAllManagementDirectiveMinutes', this.selectedAgenda.itemKey)
    await this.$store.dispatch('loadManagementCommitteeMembers')
  }
};
</script>

<style>
</style>
