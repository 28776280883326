<template>
  <v-flex>
    <v-simple-table style="width: 100%">
      <template>
        <v-overlay v-if="!selectedAgenda" :absolute="absolute" :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <thead>
        <tr>
          <th class="text-left" style="width: 10%"></th>
          <th class="text-left" style="width: 80%"></th>
        </tr>
        </thead>
        <tbody>

        <tr v-if="boardSetting().showAgendaPurpose" >
          <td>
            <strong>Purpose :</strong>
          </td>
          <td v-if="hasAccess('can_schedule_meeting')">
            <input
                v-if="isEditItemPurpose && isEditItemPurposeKey === selectedAgenda.itemDetails.itemKey"
                v-model="itemPurpose"
                :autofocus="true"
                :placeholder="selectedAgenda.itemDetails.itemPuporse || ''"
                @change="
                  (event) => onPurposeInput(event, selectedAgenda.itemDetails.itemKey, 'Purpose')
                "
                style="width: 80%"
            />

            <span v-else>
              {{ selectedAgenda.itemDetails.itemPuporse }}
            </span>

            <v-tooltip
                right
                v-if="isEditItemPurpose && isEditItemPurposeKey === selectedAgenda.itemDetails.itemKey"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="clearIsEditItemPurpose"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to close Edit</span>
            </v-tooltip>

            <v-tooltip
                right
                v-else
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="setIsEditItemPurpose"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to Edit Content</span>
            </v-tooltip>

          </td>
          <td v-else>
            <p>
              {{ selectedAgenda.itemDetails.itemPuporse }}
            </p>
          </td>
        </tr>

        <tr  v-if="boardSetting().showAgendaPresenter">
          <td>
            <strong>Presenter :</strong>
          </td>

          <td v-if="hasAccess('can_schedule_meeting')">
            <v-menu app offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    slot="activator"
                    color="indigo"
                    small
                    style="text-transform: capitalize"
                    text
                    v-bind="attrs"
                    v-on="on"
                >{{ presenter }}
                </v-btn
                >
              </template>
              <v-list dense>
                <v-list-item
                    v-for="member in getBoardMembers"
                    :key="member.username"
                    link
                >
                  <v-list-item-title
                      @click="changePresenter(selectedAgenda.itemKey, member)"
                  >
                    {{ member.firstName }} {{ member.lastName }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>

          <td v-else>
            <v-btn
                slot="activator"
                color="indigo"
                small
                style="text-transform: capitalize"
                text
                v-bind="attrs"
                v-on="on"
            >{{ presenter }}
            </v-btn
            >
          </td>
        </tr>

        <tr  v-if="boardSetting().showAgendaDuration">
          <td>
            <strong>Duration :</strong>
          </td>
          <td v-if="hasAccess('can_schedule_meeting')" class="Editable">
            <input
                v-if="isEditItemDuration && isEditItemPurposeKey === selectedAgenda.itemDetails.itemKey"
                v-model="itemPurpose"
                :autofocus="true"
                :placeholder="selectedAgenda.itemDetails.itemDuration || '0'"
                @change="
                  (event) => onPurposeInput(event, selectedAgenda.itemDetails.itemKey, 'Time')
                "
            />

            <span v-else>{{ selectedAgenda.itemDetails.itemDuration }}</span> Min

            <v-tooltip
                right
                v-if="isEditItemDuration && isEditItemPurposeKey === selectedAgenda.itemDetails.itemKey"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="clearIsEditItemDuration"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to close Edit</span>
            </v-tooltip>

            <v-tooltip
                right
                v-else
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="setIsEditItemDuration"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Click to Edit Content</span>
            </v-tooltip>
          </td>

          <td v-else>
              <span>{{ selectedAgenda.itemDetails.itemDuration }}</span
              >
            Min
          </td>
        </tr>

        <tr></tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-flex>
</template>

<script>
import swal from "sweetalert2";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      absolute: true,
      overlay: true,
      file: "",
      loading3: false,
      dragging: false,
      duration: 0,
      Purpose: "",

      isEditItemPurpose: false,
      isEditItemDuration: false,
      autofocus: false,
      isEditItemPurposeKey: "",
      itemPurpose: "",
    };
  },
  methods: {
    setIsEditItemDuration() {
      this.autofocus = true;
      this.isEditItemDuration = true;
      this.isEditItemPurposeKey = this.selectedAgenda.itemDetails.itemKey;
    },

    clearIsEditItemDuration() {
      this.autofocus = false;
      this.isEditItemDuration = false;
      this.isEditItemPurposeKey = "";
      this.itemPurpose = "";
    },

    setIsEditItemPurpose() {
      this.autofocus = true;
      this.isEditItemPurpose = true;
      this.isEditItemPurposeKey = this.selectedAgenda.itemDetails.itemKey;
    },

    clearIsEditItemPurpose() {
      this.autofocus = false;
      this.isEditItemPurpose = false;
      this.isEditItemPurposeKey = "";
      this.itemPurpose = "";
    },

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },

    createFile(file) {
      if (!file.type.match("pdf.*")) {
        alert("please select PDF file");
        this.dragging = false;
        return;
      }

      if (file.size > 100000000) {
        alert("please check file size no over 5 MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      console.log(this.file);
      this.dragging = false;
    },

    removeFile() {
      this.file = "";
    },

    onInput(event) {
      var cleared = event.target.innerText.replace(/[^\d.-]/g, "");
      console.log(cleared);
      this.duration = cleared;
    },

    clearAllUnwantedChar(event) {
      event.target.innerText = this.duration;
    },

    async onPurposeInput(event, selectedAgenda, updatePlace) {
      var details = {
        newPurpose: this.itemPurpose,
        detailKey: selectedAgenda,
        updatePlace: updatePlace,
      };
      await this.$store.dispatch("updateAgendaItemsDetails", details);
      console.log(this.$store.state.AgendasStore.selecteAgenda);
      if (updatePlace=='Time') {
        this.$store.state.AgendasStore.selecteAgenda.itemDetails.itemDuration=this.itemPurpose
      }
      await this.clearIsEditItemPurpose();
    },

    publishAgendas() {
      this.loading3 = true;
      swal.fire({
        toast: true,
        icon: "success",
        title: "Posted successfully",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        // didOpen: (toast) => {
        //     toast.addEventListener('mouseenter', swal.stopTimer)
        //     toast.addEventListener('mouseleave', swal.resumeTimer)
        // }
      });

      setTimeout(() => (this.loading3 = false), 3000);
    },

    changePresenter(itemKey, member) {
      this.presenter = member.firstName + " " + member.lastName;

      this.$store.dispatch("switchAgendaItemsPresenter", {
        itemKey: itemKey,
        member: member.id,
      });
    },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },

    presenter: {
      get: function () {
        return this.selectedAgenda.itemDetails.itemPresenter;
      },
      set: function (newValue) {
        this.selectedAgenda.itemDetails.itemPresenter = newValue;
      },
    },

    ...mapGetters({
      getBoardMembers: "getBoardMembers",
    }),

    selectedAgenda() {
      return this.$store.state.AgendasStore.selecteAgenda;
    },
  },
};
</script>

<style>
.Editable:hover > *[contenteditable="true"] {
  background-color: rgba(217, 245, 255, 0.5);
}

[contenteditable=true]:empty:not(:focus):before {
  content: attr(data-ph);
  color: grey;
  font-style: italic;
}

.dropZone {
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px dashed #3f51b5;
  border-radius: 5px;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 70px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile {
  width: 200px;
}
</style>