// import ManagementCommitteeDashboard
//     from "@/modules/management_committee/management_committee_dashboard/ManagementCommitteeDashboard.vue";
import ManagementCommitteeMeeting
    from "@/modules/management_committee/management_committee_meeting/ManagementCommitteeMeeting.vue";
import ManagementCommitteeMeetingDetails
    from "@/modules/management_committee/management_committee_meeting/ManagementCommitteeMeetingDetails.vue";

import ManagementCommitteeAgendaBuilder
    from "@/modules/management_committee/management_committee_agenda/ManagementCommitteeAgendaBuilder.vue";
import ManagementCommitteeMinutesBuilder
    from "@/modules/management_committee/management_committee_minutes/ManagementCommitteeMinutesBuilder.vue";
import ManagementCommitteeRepository
    from "@/modules/management_committee/management_committee_repository/ManagementCommitteeRepository.vue";
import ManagementCommitteeRecommendation
    from "@/modules/management_committee/management_committee_recommendation/ManagementCommitteeRecommendation.vue";


export default [
    // {
    //     path: 'my-dashboard',
    //     name: 'ManagementCommitteeDashboard',
    //     component: ManagementCommitteeDashboard,
    //     meta: {requiresAuth: true},
    // },
    {
        path: 'meeting-schedules',
        name: 'ManagementCommitteeMeeting',
        component: ManagementCommitteeMeeting,
        meta: {requiresAuth: true},
    },
    {
        path: 'meeting-schedules/meeting_details/:meetingUniqueId',
        name: 'ManagementCommitteeMeetingDetails',
        component: ManagementCommitteeMeetingDetails,
        meta: {requiresAuth: true},
    },
    {
        path: 'meeting-schedules/meeting-agendas/:meetingUniqueId',
        name: 'ManagementCommitteeBuildAgenda',
        component: ManagementCommitteeAgendaBuilder,
        meta: {requiresAuth: true},
    },
    {
        path: 'meeting-schedules/agenda-minutes/:meetingUniqueId',
        name: 'ManagementCommitteeBuildAgenda',
        component: ManagementCommitteeMinutesBuilder,
        meta: {requiresAuth: true},
    },
    {
        path: 'meeting-repository',
        name: 'ManagementCommitteeRepository',
        component: ManagementCommitteeRepository,
        meta: {requiresAuth: true},
    },
    {
        path: 'meeting-recommendations',
        name: 'ManagementCommitteeRecommendation',
        component: ManagementCommitteeRecommendation,
        meta: {requiresAuth: true},
    },
]