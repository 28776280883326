<template>
  <v-row justify="center">
    <v-dialog persistent v-model="OpenSharedAnnotationDialog" max-width="30%">
      <v-card>
        <v-card-title class="text-h5"> Share Annotation </v-card-title>

        <v-card-text>
          <v-select
            v-model="receivers"
            :items="loadedMemebrs"
            item-text="name"
            item-value="id"
            label="Select Member for Sharing"
            multiple
            chips
            hint="Give other board members to work on your annotations"
            persistent-hint
            dense
          ></v-select>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="comments"
            label="Comments"
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="$emit('CloseSharedAnnotationDialog')"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="shareBoardPackAnnotation">
            Share
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["OpenSharedAnnotationDialog", "annotation"],
  data() {
    return {
      dialog: false,
      receivers: null,
      comments: "",
    };
  },
  computed: {
    loadedMemebrs() {
      var members = [];
      var myId=this.$store.state.AuthStore.user.username
      this.$store.state.MembersStore.boardMembers.forEach((member) => {
          if (myId!==member.username) {
              members.push({
                id: member.id,
                name: member.firstName + " " + member.lastName,
              });
          }
      });
      return members;
    },
  },
  methods: {
    async shareBoardPackAnnotation() {
      this.$store.dispatch("showLoadingSpinner", true);

      var shared_user_list = [];
      this.receivers.forEach((user) => {
        shared_user_list.push({
          userId: user,
        });
      });

      var shareAnnotation = {
        annotationUniqueId: this.annotation,
        sharedComments: this.comments,
        sharedUsers: shared_user_list,
      }
      await this.$store.dispatch(
        "shareMyAnnotationBoardPacks",
        shareAnnotation
      );

      this.$store.dispatch("showLoadingSpinner", false);
      this.comments=''
      this.receivers=null
      this.$emit('CloseSharedAnnotationDialog')
    },
  },

  async mounted() {
    await this.$store.dispatch("loadBoardMambers");
  },
};
</script>

<style>
</style>
