<template>
  <v-container fluid class="my-5" app>
    <v-dialog v-model="openNewsUpdateDialog" max-width="60%">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          <v-icon class="mr-2">mdi-newspaper</v-icon>
          Add News/Update
        </v-card-title>
        <v-form class="px-3">
          <v-card-text>
            <v-text-field
                outlined
                prepend-icon="mdi-text-short"
                v-model="newsTitle"
                autofocus
                label="News Title*"
            ></v-text-field>

            <v-textarea
                outlined
                prepend-icon="mdi-text-long"
                v-model="newsDescriptions"
                label="News Description*"
                required
            ></v-textarea>

            <v-row>
              <v-col sm="12">
                <UpdateSupportingDocuments/>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-menu
                    ref="newsExpireDate"
                    v-model="newsExpireDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="newsExpire"
                        label="News will Expire on: *"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="newsExpire"
                      no-title
                      scrollable
                      :min="date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDate = false">
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-dialog ref="dialog1" v-model="modal1" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="expireTime"
                        label="News Expirey Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="modal1" v-model="expireTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <v-switch
                v-model="newsShowDelivery"
                inset
                color="#E65100"
                class="pl-5"
                :label="`Show Delivery Status`"
            ></v-switch>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              small
              fab
              color="pink"
              @click="closeDialog"
          >
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
          <v-btn small fab color="primary" @click="saveNewsUpdate">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
        <DocumentViwer
            v-if="OpenfileDialog === true"
            :docValue="docValue"
            :OpenfileDialog="OpenfileDialog"
            :title="`View Attachment`"
            @CloseDocumentViwer="OpenfileDialog = false"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";

import UpdateSupportingDocuments from "./UpdateSupportingDocuments.vue";

export default {
  props: ["openNewsUpdateDialog"],
  components: {
    UpdateSupportingDocuments,
    DocumentViwer,
  },
  data() {
    return {
      OpenfileDialog: false,
      newsShowDelivery: "",
      newsExpireDate: "",
      newsDescriptions: "",
      newsExpireTime: "",
      expireTime: "",
      newsTitle: "",
      newsExpire: "",
      newsDocuments: "",
      dialog: false,
      dialog1: false,
      modal1: false,
      startDate: "",
      docValue: "",


      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
    };
  },
  methods: {
    async closeDialog() {
      this.newsTitle = ""
      this.newsDescriptions = ""
      this.newsExpireDate = ""
      this.expireTime = ""
      this.newsExpire = ""
      this.supportingDocs = []

      await this.$store.dispatch('clearnewsandUpdateSuppotingDocumentsAction')
      await this.$emit('closeNewUpdateDialog')
    },

    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      console.log(this.docValue);
      this.OpenfileDialog = true;
    },

    updateSuportingDocs(docs) {
      this.suportingDocs = docs;
    },

    async saveNewsUpdate() {

      var documents_list = [];

      console.log("this.supportingDocs")
      console.log(this.supportingDocs)

      this.supportingDocs.forEach((document) => {
        documents_list.push({
          documentName: document.fileName,
          filePath: document.fullFileName,
        });
      });

      const UpdateData = {
        newsDescriptions: this.newsDescriptions,
        newsDocuments: documents_list,
        newsExpireDate: this.newsExpire,
        newsExpireTime: this.expireTime,
        newsTitle: this.newsTitle,
        newsShowDelivery: this.newsShowDelivery,
      };
      console.log(UpdateData);
      await this.$store.dispatch(
          "createNewsAndUpdates",
          UpdateData
      );
      this.newsTitle = null;
      this.newsDescriptions = null;
      this.newsExpireTime = null;
      this.newsExpireDate = null;
      this.newsDocuments = null;
      this.newsShowDelivery = null;
      await this.$store.dispatch("loadBoardDraftNewsUpdates");
      this.$emit("closeNewUpdateDialog");
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
  computed: {
    supportingDocs() {
      return this.$store.state.NewsUpdatesStore.newsandUpdateSupportingDocument;
    },
  },
};
</script>

<style>
</style>
