

export default {
    state: {
        currentMeeting:null,
    },

    mutations:{
        cleanCurrentMeeting(state){
            state.currentMeeting=null
        },
        updateCurrentMeeting(state,meetingUniqueId){
            state.currentMeeting=meetingUniqueId
        }
    },

    actions: {
        async setCurrentMeeting(context,meetingUniqueId){
            context.commit('cleanCurrentMeeting')
            context.commit('updateCurrentMeeting',meetingUniqueId)
        },
    }
}


