<template>
  <div style="background: white">
    <v-card-title class="text-h6 grey lighten-2">
      Board Members Comments
      <v-spacer></v-spacer>
      <div class="form-check form-switch w-full sm:w-auto mt-3 sm:mt-0">
        <v-btn fab small text @click="$emit('closeCommentsDialog')">
          <v-icon large color="pink">mdi-close-circle</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <div class="intro-y col-span-12 lg:col-span-8 2xl:col-span-9 mr-2">
      <template v-for="comment in resolutionComments" class="w-full">
        <div :key="comment.commentsUniqueId" >
          <div
            v-if="
              (comment.commentsCreatedby || {}).email != userprofile.username
            "
            class="overflow-y-scroll scrollbar-hidden px-5 pt-5 flex-1 w-full"
          >
            <div class="chat__box__text-box flex items-end float-left mb-4">
              <div
                class="w-10 h-10 hidden sm:block flex-none image-fit relative mr-5"
              >
                <img
                  v-if="comment.commentsCreatedby.profileUser.edges.length"
                  alt="User"
                  class="rounded-full"
                  :src="
                    MEDIA_SERVER +
                    comment.commentsCreatedby.profileUser.edges[0].node
                      .userProfilePhoto
                  "
                />
              </div>
              <div
                class="bg-slate-100 dark:bg-darkmode-400 px-4 py-3 text-slate-500 rounded-r-md rounded-t-md"
              >
                {{ comment.commentsDescriptions }}
                <div class="mt-1 text-xs text-slate-500">
                  <small>{{ comment.commentsCreateddate | formatDate }} by </small>  
                  <small><strong>{{ comment.commentsCreatedby.firstName }} {{ comment.commentsCreatedby.lastName }}</strong></small>
                </div>
              </div>
            </div>
          </div>

          <div class="clear-both"></div>

          <div
            v-if="
              (comment.commentsCreatedby || {}).email == userprofile.username
            "
            class="chat__box__text-box flex items-end float-right mb-4 px-5 pt-5 flex-1"
          >
            <div
              class="bg-primary px-4 py-3 text-white rounded-l-md rounded-t-md"
            >
              {{ comment.commentsDescriptions }}
              <div class="mt-1 text-xs text-white text-opacity-80">
                  <small>{{ comment.commentsCreateddate | formatDate }} by </small>  
                  <small><strong>{{ comment.commentsCreatedby.firstName }} {{ comment.commentsCreatedby.lastName }}</strong></small>
              </div>
            </div>
            <div
              class="w-10 h-10 hidden sm:block flex-none image-fit relative ml-5"
            >
              <img
                v-if="comment.commentsCreatedby.profileUser.edges.length"
                alt="User"
                class="rounded-full"
                :src="
                  MEDIA_SERVER +
                  comment.commentsCreatedby.profileUser.edges[0].node
                    .userProfilePhoto
                "
              />
            </div>
          </div>
        </div>
      </template>

      <div class="clear-both"></div>
      <div
        class="pt-4 pb-10 sm:py-4 flex items-center border-t border-slate-200/60 dark:border-darkmode-400"
      >
        <textarea
          class="ma-2 form-control dark:bg-darkmode-600 h-16 resize-none border-transparent px-5 py-3 shadow-none focus:border-transparent focus:ring-0"
          rows="1"
          placeholder="Type your message or comments..."
          v-model="commentsDescriptions"
        ></textarea>
        <v-btn
          text
          fab
          small
          @click="sendResoutionComments"
          class="w-8 h-8 sm:w-10 sm:h-10 block bg-primary text-white rounded-full flex-none flex items-center justify-center mr-5"
        >
          <v-icon class="white--text">mdi-send</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["resolution"],
  data() {
    return {
      resolutionUniqueId: "",
      commentsDescriptions: "",
    };
  },
  computed: {
    resolutionComments() {
      return this.$store.getters.getResolutionComments;
    },
    userprofile() {
      var profile = this.$store.state.AuthStore.user;
      return profile;
    },
  },
  methods: {
    async sendResoutionComments() {
      if (this.commentsDescriptions != "") {
        this.$store.dispatch("showLoadingSpinner", true);
        await this.$store.dispatch("addResolutionComments", {
          resolutionUniqueId: this.resolution.resolutionUniqueId,
          commentsDescriptions: this.commentsDescriptions,
        });
        this.commentsDescriptions = "";
        this.$store.dispatch("showLoadingSpinner", false);
      }
    },
  },
};
</script>
