<template>
  <v-container fluid>
    <v-row class="mt-5 mx-3">
      <v-spacer></v-spacer>
      <v-btn
        color="indigo"
        class="mx-2 text-capitalize"
        dark
        elevation="2"
        @click="createUser = true"
      >
        <v-icon small>mdi-account-plus</v-icon> &nbsp; Create User</v-btn
      >
      <v-spacer></v-spacer>
    </v-row>
    <v-row justify="center" class="mb-5">
      <v-dialog v-model="createUser" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Create User</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="First name*" required></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Middle name"
                    hint="This field is optional"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field label="Last name*" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Email*" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Password*"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="createUser = false">
              Close
            </v-btn>
            <v-btn color="#3f51b5" @click="createUser = false" dark>
              Create User
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="mt-5 mx-2">
      <v-spacer></v-spacer>
      <v-col sm="12" md="4" lg="3">
        <v-alert
          class="rounded-lg pa-5"
          elevation="2"
          border="left"
          icon="mdi-clipboard"
          prominent
          colored-border
          color="success"
        >
          <v-row class="text-h3 d-flex justify-center"> 230 </v-row>
          <v-row class="d-flex justify-center"> Active users </v-row>
        </v-alert>
      </v-col>
      <v-col sm="12" md="4" lg="3">
        <v-alert
          class="rounded-lg pa-5"
          elevation="2"
          border="left"
          icon="mdi-account-group"
          prominent
          colored-border
          color="#aaa"
        >
          <v-row class="text-h3 d-flex justify-center"> 23 </v-row>
          <v-row class="d-flex justify-center"> Inactive Users </v-row>
        </v-alert>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="px-5">
      <v-spacer></v-spacer>
      <v-col md="10">
        <template>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :items-per-page="5"
              :search="search"
              class="elevation-1"
            ></v-data-table>
          </v-card>
        </template>
      </v-col>

      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      createUser: false,
      createBoard: false,
      e6: [],
      e7: [],
      names: [
        "Fredrick f",
        "Jackson Bakari",
        "Germanus Xavier",
        "Martin Blasi",
      ],
      search: "",
      headers: [
        {
          text: "S/N",
          align: "start",
          sortable: true,
          value: "serial_number",
          filterable: false,
        },
        {
          text: "Full Name",
          align: "start",
          value: "name",
        },
        { text: "Active Status", value: "active_status" },
        { text: "Associated Boards", value: "associated_boards" },
      ],
      desserts: [
        {
          serial_number: 1,
          name: "Fredrick Chafghmi",
          active_status: true,
          associated_boards: 6,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          serial_number: 2,
          name: "John Smith",
          active_status: false,
          associated_boards: 2,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
        {
          serial_number: 12,
          name: "Somebody Else",
          active_status: true,
          associated_boards: 5,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          serial_number: 13,
          name: "Another Person",
          active_status: true,
          associated_boards: 2,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          serial_number: 14,
          name: "Rachel Samwel",
          active_status: false,
          associated_boards: 2,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          serial_number: 15,
          name: "Jelly bean",
          active_status: true,
          associated_boards: 2,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          serial_number: 16,
          name: "Lollipop Pop",
          active_status: true,
          associated_boards: 2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
        {
          serial_number: 17,
          name: "Honey Comb",
          active_status: true,
          associated_boards: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%",
        },
        {
          serial_number: 18,
          name: "Donut Burger",
          active_status: true,
          associated_boards: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%",
        },
        {
          serial_number: 19,
          name: "KitKat Nougat",
          active_status: true,
          associated_boards: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%",
        },
      ],
    };
  },
};
</script>

<style>
</style>






