<template>
  <v-dialog v-model="builderModeDialogy" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <v-icon>mdi-creation</v-icon> <span>Build Agenda</span>
      </v-card-title>
      <v-card-text>
        <span
          >Would you like to clone a previous agenda, or use our best practice
          template?</span
        >
        <v-radio-group v-model="bestPractise" row>
          <v-col cols="6" sm="6" md="6">
            <v-alert elevation="4" outlined>
              <v-radio
                label="Clone another Agenda"
                value="clone"
                @change="
                  hidden = !hidden;
                  disabled = false;
                  btnTitle = 'Clone Agenda';
                "
              ></v-radio>
            </v-alert>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-alert elevation="4" outlined>
              <v-radio
                label="Best Practice Template"
                value="template"
                @change="
                  hidden = false;
                  disabled = false;
                  btnTitle = 'Build Agenda';
                "
              ></v-radio>
            </v-alert>
          </v-col>
        </v-radio-group>
        {{selectedMeetingToClone}}
        <v-select
          v-model="selectedMeetingToClone"
          :items="meetingList"
          item-text="meetingTitle"
          label="Select a Meeting to Clone"
          item-value="meetingUniqueId"
          v-if="hidden == true"
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-progress-circular
          indeterminate
          color="primary"
          :width="2"
          style="padding-right: 10px"
          v-if="progressShown"
        >
        </v-progress-circular>
        <v-btn
          small
          color=""
          text
          right
          outlined
          @click="$emit('closeBuilderModeDialogy')"
        >
          Cancel
        </v-btn>
        <v-btn
          small
          color="primary"
          :disabled="disabled"
          right
          @click="
            progressShown = true;
            openAgendaBuilderWindow();
          "
        >
          {{ btnTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["builderModeDialogy", "currentMeeting"],
  data() {
    return {
      btnTitle: "Build Agenda",
      selectedMeetingToClone: null,
      disabled: true,
      progressShown: false,
      bestPractise: null,
      hidden: false,
      dialog2: false,
    };
  },
  methods: {
    async openAgendaBuilderWindow() {
      this.$store.dispatch("showLoadingSpinner", true);
      if (this.bestPractise == "template") {
        await this.$store.dispatch(
          "creatMeetingAgendaFromTemplate",
          this.currentMeeting
        );
      } else if (
        this.bestPractise == "clone" &&
        this.selectedMeetingToClone !== null
      ) {
        await this.$store.dispatch("cloneMeetingAgendas", {
          oldMeetingUniqueId: this.selectedMeetingToClone,
          newMeetingUniqueId: this.currentMeeting,
        });
      } else {
        this.progressShown = false;
        this.$store.dispatch("showLoadingSpinner", false);
        return;
      }

      this.disabled = true;
      this.bestPractise = null;
      this.$store.dispatch("showLoadingSpinner", false);
      this.$router.go("");
    },
    async mounted() {
      // await this.$store.dispatch('loadBestPracticeAgendaTemplates',this.currentMeeting)
    },
  },
  computed: {
    ...mapGetters({
      meetingByInstituionUniqueId: "meetingsList",
    }),
    meetingList() {
      var temp_list = [];
      this.meetingByInstituionUniqueId.forEach((meeting) => {
        if (meeting.meetingHasPublishedAgenda) {
          temp_list.push(meeting);
        }
      });
      return temp_list;
    },
  },
};
</script>

<style></style>
