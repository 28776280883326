import { apolloClient } from "@/vue-apollo";
import newBoardRole from "../../graphql/eboard-auth-graphql/newBoardRole.gql";
import userPermissionsByBoard from "../../graphql/eboard-auth-graphql/userPermissionsByBoard.gql";
import handoverOfficeToUser from "../../graphql/eboard-auth-graphql/handoverOfficeToUser.gql";
import returnToOffice from "../../graphql/eboard-auth-graphql/returnToOffice.gql";
import checkMyAuthenticationStatus from "../../graphql/eboard-auth-graphql/checkMyAuthenticationStatus.gql";
import managementUserPermissions from "../../graphql/management-permissions-graphql/managementUserPermissions.graphql";
import swal from "sweetalert2";
import router from "../../router/index.js";
import {errorAlert, successAlert} from "@/services/notificationService";

export default {
  namespaced: true,
  state: {
    roles_list: [],
    userPermissions: [],
    autoRefresh: false,
    userHasHandover: false,
  },
  getters: {
    role_available: (state) => !!state.roles_list,
    roles: (state) => state.roles_list,
    userPermissions: (state) => state.userPermissions,
    getHandOverStatus: (state) => state.userHasHandover,
  },
  mutations: {
    updateHandOverStatus(state, value) {
      state.userHasHandover = value;
    },
    updateAutoRefresh(state, value) {
      state.autoRefresh = value;
    },
    clearPermissions(state) {
      state.userPermissions = [];
    },
    addPermissions(state, permission) {
      state.userPermissions.push(permission);
    },
    addNewRole(state, role) {
      state.roles_list.push(role);
    },
    REMOVE_ROLE(state, user) {
      state.roles_list = user;
    },
  },
  actions: {
    async addNewRoles(context, rolesDetails) {
      try {
        await apolloClient
          .mutate({
            mutation: newBoardRole,
            variables: { ...rolesDetails },
          })
          .then((response) => {
            context.commit("addNewRole", response.data.createUserRole.newRole);
            context.dispatch("showLoadingSpinner", false);
            swal.fire({
              toast: true,
              icon: "success",
              title: "New role created successffully",
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch(async (error) => {
            console.error(error);
          });
      } catch (e) {
        console.log(e);
      }
    },

    async loadUserPermissionsByBoard(context) {
      context.commit("clearPermissions");
      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: userPermissionsByBoard,
          variables: {
            boardUniqueId: localStorage.getItem("my-boardUniqueId"),
          },
        })
        .then(async(response) => {
          const permissions = response.data.userPermissionsByBoard;
          const perm_list = [];
          if (permissions.hasHandover) {
            context.commit("updateHandOverStatus", permissions.hasHandover);
          } else {
            permissions.permissionsList.forEach((permission) => {
              context.commit("addPermissions", permission.permissionCode);
              perm_list.push(permission.permissionCode);
            });
            context.commit("updateHandOverStatus", false);
          }
          await context.dispatch("showLoadingSpinner", false);
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error);
        });
    },

    async loadManagementUserPermissions(context) {
      context.commit("clearPermissions");
      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: managementUserPermissions,
        })
        .then((response) => {
          const permissions = response.data.managementUserPermissions;
          const permissions_list = [];

          permissions.forEach((item) => {
            context.commit("addPermissions", item.permissionCode);
            permissions_list.push(item.permissionCode);
          });
          context.dispatch("showLoadingSpinner", false);
        })
        .catch(async (error) => {
          console.error(error);
        });
    },

    async updateAutoRefresh(context) {
      context.commit("updateAutoRefresh", false);
    },

    async setAutRefreshTrue(context) {
      context.commit("updateAutoRefresh", true);
    },

    async checkMyAuthenticationStatus() {
      apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: checkMyAuthenticationStatus,
        })
        .then((response) => {
          const is_authenticated =
            response.data.checkMyAuthenticationStatus.isAuthenticated;
          if (!is_authenticated) {
            localStorage.removeItem("vuex");
            localStorage.removeItem("my-boardUniqueId");
            localStorage.removeItem("my-boardKey");
            localStorage.removeItem("apollo-token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("my-managementUniqueId");
            localStorage.removeItem("my-managementCommitteeUniqueId");
            localStorage.removeItem("my-managementCommitteeName");
            router.push("/landing_page");
          }
        })
        .catch((error) => {
          console.log(error);
          localStorage.removeItem("vuex");
          localStorage.removeItem("my-boardUniqueId");
          localStorage.removeItem("my-boardKey");
          localStorage.removeItem("apollo-token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("my-managementUniqueId");
          localStorage.removeItem("my-managementCommitteeUniqueId");
          localStorage.removeItem("my-managementCommitteeName");
          if (!router.currentRoute.PasswordResetAccount === "PasswordResetAccount") {
            router.push("/landing_page");
          }
        });
    },

    async handoverMyOfficeDuties(context,handingOverUser) {
      try {
        await apolloClient
          .mutate({
            mutation: handoverOfficeToUser,
            variables: {
              handover: handingOverUser,
            },
          })
          .then(async(response) => {
            if (response.data.handoverOfficeToUser.response.status) {
              successAlert(response.data.handoverOfficeToUser.response.message);
            } else {
              errorAlert(response.data.handoverOfficeToUser.response.message);
            }
            await this.$store.dispatch("showLoadingSpinner", false);
          })
          .catch(async (error) => {
            await this.$store.dispatch("showLoadingSpinner", false);
            console.error(error);
          });
      } catch (e) {
        console.log(e);
      }
    },

    async returnToOffice(context,handingOverUser) {
      try {
        await apolloClient
          .mutate({
            mutation: returnToOffice,
            variables: {
              input: {
                onBehalf: false,
                boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                handingOverUser: handingOverUser,
              },
            },
          })
          .then((response) => {
            if (response.data.returnToOffice.response.status) {
              swal.fire({
                toast: true,
                icon: "success",
                title: response.data.returnToOffice.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              swal.fire({
                toast: true,
                icon: "error",
                title: response.data.returnToOffice.response.message,
                animation: false,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch(async (error) => {
            console.error(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
