<template>
  <v-dialog
      v-model="builderModeDialog"
      max-width="600px"
      persistent
  >
    <v-card>
      <v-card-title>
        <v-icon class="mr-2">mdi-creation</v-icon>
        <span>Build Agenda</span>
      </v-card-title>
      <v-card-text>
        <span>Would you like to clone a previous agenda, or use our best practice template?</span>
        <v-radio-group
            v-model="bestPractise"
            row
        >
          <v-col
              cols="6"
              sm="6"
              md="6"
          >
            <v-alert
                elevation="4"
                outlined
            >
              <v-radio
                  label="Clone another Agenda"
                  value="clone"
                  @change="cloneAgenda=!cloneAgenda;disabled=true"
              ></v-radio>
            </v-alert>
          </v-col>
          <v-col
              cols="6"
              sm="6"
              md="6"
          >
            <v-alert
                elevation="4"
                outlined
            >
              <v-radio
                  label="Best Practice Template"
                  value="template"
                  @change="cloneAgenda=false;disabled=false"
              ></v-radio>
            </v-alert>
          </v-col>
        </v-radio-group>
        <v-select
            v-model="selectedMeeting"
            :items="managementCommitteeMeetings"
            label="Select a Meeting to Clone"
            item-value="id"
            item-text="meetingTitle"
            v-if="cloneAgenda===true"
            @change="disabled=false"
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-progress-circular
            indeterminate
            color="primary"
            :width="2"
            style="padding-right:10px"
            v-if="progressShown"
        >
        </v-progress-circular>
        <v-btn
            color=""
            text
            right
            outlined
            @click="$emit('closeBuildAgendaDialog')"
        >
          Cancel
        </v-btn>
        <v-btn
            color="primary"
            :disabled="disabled"
            right
            @click="openAgendaBuilderWindow"
        >
          Build Agenda
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['builderModeDialog', 'currentMeeting'],
  data() {
    return {
      disabled: true,
      progressShown: false,
      bestPractise: null,
      cloneAgenda: false,
      dialog2: false,
      selectedMeeting: ''
    }
  },
  methods: {
    async openAgendaBuilderWindow() {
      !this.cloneAgenda ?
      await this.$store.dispatch('managementCommitteeBestPractiseAgenda', {
        agendaMeeting: this.currentMeeting.id
      }) :
      await this.$store.dispatch('managementCommitteeCloneMeetingAgenda', {
        agendaMeeting: this.currentMeeting.id,
        agendaCloneMeeting: this.selectedMeeting
      })
      this.$emit('closeBuildAgendaDialog')
    },
    async mounted() {
      // await this.$store.dispatch('loadBestPracticeAgendaTemplates',this.currentMeeting)
    }
  },
  watch: {
    meetingAgenda() {
      if (this.builderModeDialog && this.meetingAgenda.length !== 0) {
        this.$router.push({path: '/management-committee/meeting-schedules/meeting-agendas/' + this.$route.params.meetingUniqueId})
      }
    },
    builderModeDialog() {
      if (this.builderModeDialog) {
        this.$store.dispatch('loadManagementCommitteeMeetings', { meetingAgendaPublished: true })
      }
    }
  },
  computed: {
    managementCommitteeMeetings() {
      return this.$store.getters.getManagementCommitteeMeetings
    },
    meetingAgenda() {
      return this.$store.getters.getManagementCommitteeAgendas
    }
  }
}
</script>

<style>

</style>
