<template>
    <v-dialog
        v-model="confimMinutesDialog"
        max-width="50%"
        persistent
        >
        <v-card>
            <v-card-title class="text-h5;cencontent">
                Confirm Minutes
            </v-card-title>

            <v-card-text class="cencontent">
            Select one of the last un-confirmed minutes from previous meetings
            </v-card-text>

            <v-card-text>
                <v-autocomplete v-model="lastMeeting" item-value="primaryKey" item-text="meetingTitle"  :items="unConfirmedMeetings" label="Select Previous Meetings*" required ></v-autocomplete>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
            small
                color="green darken-1"
                text
                @click="$emit('CloseConfimMinutesDialog')"
            >
                Cancel
            </v-btn>

            <v-btn
            small
                color="green darken-1"
                text
                @click="addUnConfirmMinutes"
            >
                Select
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
</template>

<script>
export default {
    props:['confimMinutesDialog','lastMeetings','selectedAgenda'],
    data(){
        return{
            lastMeeting:null,
        }
    },
    methods:{
        async addUnConfirmMinutes(){
            await this.$store.dispatch('showLoadingSpinner',true)
            await this.$store.dispatch('addUnConfirmMeetingsMinutesAgendaItem',{selectedAgenda:this.selectedAgenda,lastMeeting:this.lastMeeting,})
            this.$emit("CloseConfimMinutesDialog")
            this.lastMeeting=null
            await this.$store.dispatch('showLoadingSpinner',false)
        }
    },
    computed:{
        unConfirmedMeetings(){
            var temp_meeting=[]

            this.lastMeetings.forEach(meeting => {
                if (this.$store.state.MeetingsStore.selectedMeetingDetails.primaryKey !== meeting.primaryKey && meeting.minutesApproved === true) {
                    temp_meeting.push(meeting)
                }
            });
            return temp_meeting
        }
    }
}
</script>
