<template>
  <v-row justify="center">
    <v-dialog v-model="isPostponeMeeting" persistent max-width="50%">
      <v-card>
        <v-card-title>
        <span class="text-h6 flex items-center"
        ><icon name="meeting_add" width="w-5" height="h-5" class="mr-2"></icon> Postpone / Reschedule Meeting </span
        >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="meetingDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="meetingDate"
                        label="Date *"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="meetingDate"
                      no-title
                      scrollable
                      :min="date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(meetingDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-dialog
                    ref="dialog1"
                    v-model="modal1"
                    :return-value.sync="startTime"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="startTime"
                        label="Start Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-if="modal1" v-model="startTime" full-width>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog1.save(startTime)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="endTime"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="endTime"
                        label="End Time *"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-if="modal2"
                      v-model="endTime"
                      :min="startTime"
                      full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(endTime)"
                    >
                      OK
                    </v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="12">
                <v-select
                    return-object
                    :items="locations"
                    item-text="location"
                    label="Location *"
                    required
                    prepend-icon="mdi-map-marker"
                    v-model="defaultSelected"
                    :hint="timezone"
                    persistent-hint
                    @change="getSelectedTimeZone($event)"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip text-color="" small>{{ item.location }}</v-chip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <div class="flex justify-end text-white">
            <v-btn color="red"
                   class="text-capitalize mr-2"
                   right
                   small
                   @click="$emit('closePostponeMeeting')"
            >
              <icon name="close" width="w-5" height="h-5" color="#ffffff"></icon>
              <span class="text-white">Cancel</span>
            </v-btn>
            <v-btn color="primary"
                   class="text-capitalize"
                   right
                   small
                   @click="postponeBoardMeeting"
            >
              <icon name="save" width="w-5" height="h-5" color="#ffffff"></icon>
              Save
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LocationSettings
        :openLocationDialog="openLocationDialog"
        @closeNewLocationDialog="openLocationDialog = !openLocationDialog"
    />
  </v-row>
</template>

<script>
import LocationSettings from "@/modules/system_management/eboard_settings/LocationSettings.vue";
import Icon from "@/views/Icon.vue";

export default {
  name: "ManagementCommitteeMeetingPostpone",
  props: ["isPostponeMeeting", "currentMeetingDetails", "meetingUniqueId"],
  components: {Icon, LocationSettings },

  data: () => ({
    meetingCommittees: [],
    overlay: false,
    isCommittees: false,
    zIndex: 0,
    hidden: false,
    checkbox: false,
    loading: false,
    openLocationDialog: false,
    timezone: "",
    defaultSelected: "",
    selectedLocations: [],
    time: null,
    menu4: false,
    menu3: false,
    modal2: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    timeCounter: 0,
    picker: new Date().toISOString().substr(0, 7),
    dialog: false,
    dialog1: false,
    modal1: false,
    meetingDate: "",
    startTime: "",
    meetingCategory: "",
    endTime: "",
    meetingTittle: "",
    nameRules: [
      (v) => !!v || "Meeting title is required",
      (v) => v.length <= 100 || "Title must be less than 100 characters",
    ],
    createdMeeting: "",
    meeting_location: {}
  }),

  async mounted() {
    await this.$store.dispatch("loadBoardMeetingLocations");
  },

  watch: {
    meetingStartTime() {
      this.startTime = this.currentMeetingDetails.meetingStartTime
    },
    meetingEndTime() {
      this.endTime = this.currentMeetingDetails.meetingEndTime
    },
    meetingDatee() {
      this.meetingDate = this.currentMeetingDetails.meetingDate
    },
    meetingLocation() {
      this.defaultSelected = {
        id: this.currentMeetingDetails.meetingLocation.locationUniqueId ? this.currentMeetingDetails.meetingLocation.locationUniqueId : this.currentMeetingDetails.meetingLocation.id,
        locationUniqueId: this.currentMeetingDetails.meetingLocation.locationUniqueId ? this.currentMeetingDetails.meetingLocation.locationUniqueId : this.currentMeetingDetails.meetingLocation.id,
        location: this.currentMeetingDetails.meetingLocation.locationName + " (" + this.currentMeetingDetails.meetingLocation.locationAddress + " )",
        locationTimezone: this.currentMeetingDetails.meetingLocation.locationTimezone,
      }
    }
  },

  computed: {
    locations() {
      return this.$store.state.SettingsStore.boardLocations;
    },
    meetingStartTime() {
      return this.currentMeetingDetails.meetingStartTime
    },
    meetingEndTime() {
      return this.currentMeetingDetails.meetingEndTime
    },
    meetingDatee() {
      return this.currentMeetingDetails.meetingDate
    },
    meetingLocation() {
      return this.currentMeetingDetails.meetingLocation
    },
  },

  methods: {
    getSelectedTimeZone(eventvalue) {
      this.timezone = this.defaultSelected.locationTimezone;
      console.log(eventvalue);
    },

    postponeBoardMeeting() {

      const meeting = {
        meetingDate: this.meetingDate,
        meetingStartTime: this.startTime,
        meetingEndTime: this.endTime,
        meetingLocation: this.defaultSelected.locationUniqueId,
        meetingUniqueId: this.currentMeetingDetails.id,
        meetingCategory: this.currentMeetingDetails.meetingCategory.id,
        meetingTitle: this.currentMeetingDetails.meetingTitle
      };

      this.$store.dispatch("editManagementCommitteeMeeting", meeting);

      this.meetingDate = null;
      this.startTime = null;
      this.endTime = null;
      this.defaultSelected = null;

      this.$emit("closePostponeMeeting");

    },
  },
}
</script>

<style scoped>

</style>