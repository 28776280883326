import {  apolloClient } from '@/vue-apollo'
import managementMeetingCategories from '../../graphql/management-meetings-graphql/meetingsCategories.gql'

export default {
    state: {
        managementMeetingCategories:[],
    },

    mutations:{
        cleanManagementCategories(state){
            state.managementMeetingCategories=[]
        },
        addManagementCategories(state,categoryObj){
            state.managementMeetingCategories.push(categoryObj)
        }
    },

    actions: {
        async loadManagementMeetingCategories(context, isSpecialCategory = false){
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: managementMeetingCategories,
                variables: {
                    managementUniqueId:localStorage.getItem("my-managementUniqueId"),
                    isSpecialCategory: isSpecialCategory
                }
            }).then((response)=>{
                const categories=response.data.managementMeetingCategories.edges;
                context.commit('cleanManagementCategories');

                categories.forEach(category => {
                    const single = category.node;
                    const categoryObj = {
                        key: single.primaryKey,
                        title: single.categoryTittle,
                        uniqueId: single.categoryUniqueId,
                        date: single.categoryDate,
                    };

                    context.commit('addManagementCategories',categoryObj);
                });
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })

        }
    }
}
