<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p>Meetings Directives</p>
      </v-col>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          v-model="filterOwner"
          :items="boardMembers"
          dense
          item-text="name"
          item-value="id"
          label="Owner *"
          @change="filterDirectiveByMember($event)"
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="12" sm="3">
        <v-autocomplete
          v-model="boardDesignation"
          :items="boardDesignationsList"
          item-text="designationName"
          item-value="designationUniqueId"
          dense
          label="Filter by Designation *"
          @change="filterDirectiveByDesignation($event)"
        ></v-autocomplete>
      </v-col>

      <v-col class="d-flex" cols="12" sm="3">
        <v-autocomplete
          v-model="filterMeeting"
          :items="meetingsList"
          item-text="name"
          item-value="id"
          dense
          label="Filter by Meeting *"
          @change="filterDirectiveByMeeting($event)"
        ></v-autocomplete>
      </v-col>

      <v-spacer></v-spacer>
      <v-btn color="primary" fab small @click="openDownloadDirectivesModal">
        <v-icon> mdi-tray-arrow-down</v-icon>
      </v-btn>
    </v-row>

    <v-row v-if="boardDesignationsList.length > 0">
      <v-col sm="12">
        <v-card class="shadow-sm">
          <v-tabs v-model="tab" color="#3f51b5" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon :color="tab.color" class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item style="background: rgba(39, 24, 245, 0.04)" class="rounded-md">
              <ActionCard
                class="intro-y mt-2"
                v-for="directive in boardDirectives.data"
                :key="directive.directiveUniqueId"
                :directive="directive"
              />
              <v-card-actions>
                <v-btn @click="pageFilteringDirectives(boardDirectives.page.previousPageNumber,'incompleted')" v-if="boardDirectives.page.hasPreviousPage" outlined small> <v-icon left>mdi-arrow-left</v-icon> Prevoius Page ({{boardDirectives.page.previousPageNumber}})</v-btn>
                  <v-spacer></v-spacer>
                <v-btn @click="pageFilteringDirectives(boardDirectives.page.nextPageNumber,'incompleted')" v-if="boardDirectives.page.hasNextPage" outlined small> Next Page ({{boardDirectives.page.nextPageNumber}}) <v-icon right>mdi-arrow-right</v-icon></v-btn>
              </v-card-actions>
            </v-tab-item>

            <v-tab-item style="background: rgba(39, 24, 245, 0.04)" class="rounded-md">
              <ActionCard
                class="intro-y mt-2"
                v-for="directive in completedBoardDirectives.data"
                :key="directive.directiveUniqueId"
                :directive="directive"
              />
              <v-card-actions>
                <v-btn @click="pageFilteringDirectives(completedBoardDirectives.page.previousPageNumber,'completed')" v-if="completedBoardDirectives.page.hasPreviousPage" outlined small> <v-icon left>mdi-arrow-left</v-icon> Prevoius Page ({{completedBoardDirectives.page.previousPageNumber}})</v-btn>
                  <v-spacer></v-spacer>
                <v-btn @click="pageFilteringDirectives(completedBoardDirectives.page.nextPageNumber,'completed')" v-if="completedBoardDirectives.page.hasNextPage" outlined small> Next Page ({{ completedBoardDirectives.page.nextPageNumber }}) <v-icon right>mdi-arrow-right</v-icon></v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col sm="12">
        <v-card class="shadow-sm">
          <v-tabs v-model="tab" color="#3f51b5" show-arrows>
            <v-tab v-for="tab in tabs" :key="tab.icon">
              <v-icon :color="tab.color" class="me-3" size="20">
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item style="background: rgba(39, 24, 245, 0.04)" class="rounded-md">
              <ActionCard
                  class="intro-y mt-2"
                  v-for="directive in boardDirectives.data"
                  :key="directive.directiveUniqueId"
                  :directive="directive"
              />
              <v-card-actions>
                <v-btn @click="pageFilteringDirectives(boardDirectives.page.previousPageNumber,'incompleted')" v-if="boardDirectives.page.hasPreviousPage" outlined small> <v-icon left>mdi-arrow-left</v-icon> Prevoius Page ({{boardDirectives.page.previousPageNumber}})</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="pageFilteringDirectives(boardDirectives.page.nextPageNumber,'incompleted')" v-if="boardDirectives.page.hasNextPage" outlined small> Next Page ({{boardDirectives.page.nextPageNumber}}) <v-icon right>mdi-arrow-right</v-icon></v-btn>
              </v-card-actions>
            </v-tab-item>

            <v-tab-item style="background: rgba(39, 24, 245, 0.04)" class="rounded-md">
              <ActionCard
                  class="intro-y mt-2"
                  v-for="directive in completedBoardDirectives.data"
                  :key="directive.directiveUniqueId"
                  :directive="directive"
              />
              <v-card-actions>
                <v-btn @click="pageFilteringDirectives(completedBoardDirectives.page.previousPageNumber,'completed')" v-if="completedBoardDirectives.page.hasPreviousPage" outlined small> <v-icon left>mdi-arrow-left</v-icon> Prevoius Page ({{completedBoardDirectives.page.previousPageNumber}})</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="pageFilteringDirectives(completedBoardDirectives.page.nextPageNumber,'completed')" v-if="completedBoardDirectives.page.hasNextPage" outlined small> Next Page ({{ completedBoardDirectives.page.nextPageNumber }}) <v-icon right>mdi-arrow-right</v-icon></v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <DownloadDirectivesDocumentModal
      :showContextMenu="showContextMenu"
      @closeDownloadDirectiveModal="showContextMenu=false"
      :filterObj="filterObj"
    />
  </v-container>
</template>

<script>

import ActionCard from "./ActionCard.vue";
import DownloadDirectivesDocumentModal
  from "@/modules/meeting_management/eboard-actions/DownloadDirectivesDocumentModal.vue";

export default {
  components: {ActionCard, DownloadDirectivesDocumentModal},

  data() {
    return {
      tab: true,
      isLoading: false,
      tabs: [
        {title: "Current Actions", icon: "mdi-pin-outline", color: "orange"},
        {
          title: "Completed Actions",
          icon: "mdi-check-decagram-outline",
          color: "success",
        },
      ],
      filterObj: {},
      filterMeeting: 'all',
      boardDesignation: '',
      filterOwner: '',
      filterBy: 'Meeting',
      showContextMenu: false,
    };
  },

  computed: {
    boardMembers() {
      const board_members_list = [];
      const all_members = this.$store.state.MembersStore.boardMembers;
      all_members.forEach((member) => {
        if (member.memberLevel !== "CHR") {
          board_members_list.push({
            name: member.firstName + " " + member.lastName,
            id: member.id,
          });
        }
      });
      board_members_list.push({name: "All Users", id: "all"});
      return board_members_list;
    },

    meetingsList() {
      const temp_list = [];
      const all_meetings = this.$store.state.MeetingsStore.boardMeetingsList;

      all_meetings.forEach((meeting) => {
        temp_list.push({
          name: meeting.meetingTitle + ' - ' + new Date(meeting.meetingDate).toLocaleDateString(),
          id: meeting.primaryKey,
        });
      });

      temp_list.push({name: "All Meetings", id: "all"});
      return temp_list;
    },

    boardDirectives() {
      return { data:this.$store.state.DirectiveStore.assignedDirectives.assignedDirectivesList, page:this.$store.state.DirectiveStore.assignedDirectives.page }
    },

    completedBoardDirectives() {
      return { data:this.$store.state.DirectiveStore.completedDirectives.completedDirectivesList, page:this.$store.state.DirectiveStore.completedDirectives.page }
    },

    boardDesignationsList() {
      return this.$store.state.SettingsStore.boardDesignationsList;
    }
  },

  methods: {
    async filterDirectiveByMember(value) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadAssignedDirectives", {filterKey: value,pageNumber:1,filterBy: "Owner"});
      await this.$store.dispatch("loadCompletedAssignedDirectives", {filterKey: value, filterBy: "Owner",pageNumber:1});
      this.filterMeeting = '';
      this.boardDesignation = '';
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async filterDirectiveByDesignation(value) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadAssignedDirectives", {filterKey: value,pageNumber:1,filterBy: "Designation"});
      await this.$store.dispatch("loadCompletedAssignedDirectives", {filterKey: value, filterBy: "Designation",pageNumber:1});
      this.filterOwner = '';
      this.filterMeeting = '';
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async filterDirectiveByMeeting(value) {
      await this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("loadAssignedDirectives", {filterKey: value,pageNumber:1,filterBy: "Meeting"});
      await this.$store.dispatch("loadCompletedAssignedDirectives", {filterKey: value, filterBy: "Meeting",pageNumber:1});
      this.filterOwner = '';
      this.boardDesignation = '';
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    async pageFilteringDirectives(value,pageType) {
      await this.$store.dispatch("showLoadingSpinner", true);
      if (pageType=="completed") {
        await this.$store.dispatch("loadCompletedAssignedDirectives", {filterKey: "all", filterBy: "Owner",pageNumber:value});
      } else {
        await this.$store.dispatch("loadAssignedDirectives", {filterKey: "all", filterBy: "Owner",pageNumber:value});
      }
      await this.$store.dispatch("showLoadingSpinner", false);
    },

    openDownloadDirectivesModal() {
      if (this.filterMeeting === '' && this.boardDesignation === '')
        this.filterBy = 'Owner';

      if (this.filterOwner === '' && this.boardDesignation === '')
        this.filterBy = 'Meeting'

      if (this.filterMeeting === '' && this.filterOwner === '')
        this.filterBy = 'Designation'


      this.filterObj.showAssignee = false;
      this.filterObj.directiveAssignee = this.filterOwner;
      this.filterObj.filterBy = this.filterBy
      this.filterObj.meetingUniqueId = this.filterMeeting;
      this.filterObj.designationUniqueId = this.boardDesignation;

      this.showContextMenu = true;
    }
  },

  async mounted() {
    await this.$store.dispatch("showLoadingSpinner", true);
    await this.$store.dispatch("loadAssignedDirectives", {filterKey: "None", filterBy: "Owner",pageNumber:1});
    await this.$store.dispatch("loadCompletedAssignedDirectives", {filterKey: "None", filterBy: "Owner",pageNumber:1});
    await this.$store.dispatch("loadBoardMambers",{filterKey: "None", filterBy: "Owner",});
    await this.$store.dispatch("loadMeetingByBoardUniqueId");
    await this.$store.dispatch("showLoadingSpinner", false);
  },
};
</script>

<style>
</style>
