<template>
  <v-row>
    <v-dialog
      v-model="newUserRoleDialog"
      max-width="70%"
      persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <icon class="pt-1 mr-3" name="role" width="w-5" height="h-5" color="#000"></icon><span class="text-h6">Add Role</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                      v-model="roleName"
                      :counter="100"
                      label="Role Name *"
                      prepend-icon="mdi-text-short"
                      required >
                  </v-text-field>

                  <v-text-field
                      v-model="roleDescription"
                      :counter="150"
                      label="Role Description *"
                      prepend-icon="mdi-text-long"
                      required
                  >
                  </v-text-field>
                  <div class="flex ">
                    <v-checkbox
                        v-model="roleIsAdmin"
                        :label="`Is Admin`"
                        color="success"
                        class="mr-2"
                    ></v-checkbox>
                  </div>


                  <v-col md="9" cols="9">
                    <v-card-title class="text-h6">
                      Role Permissions
                    </v-card-title>
                    <v-treeview
                        selectable
                        :items="permissiongroups"
                        v-model="permissions"
                    ></v-treeview>
                  </v-col>



                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="closeDialog"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="createUserRoles"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Icon from "@/views/Icon.vue";
import allPermisionGroups from "@/graphql/eboard-auth-graphql/allPermisionGroups.gql";
export default {
  name: "AddNewUserRoles",
  components: {Icon},


  data: () => ({
    roleUniqueId: "",
    roleName: "",
    roleDescription: "",
    roleIsAdmin: false,
    permissions: "",
    dialog: false,
    permissiongroups: []
  }),

  props: ["newUserRoleDialog"],


  methods: {
    async closeDialog() {
      this.roleName = ""
      this.roleDescription = ""
      this.permissions=""
      this.$emit('close-dialog')
    },

    async createUserRoles(){
      let input = {
        roleName: this.roleName,
        roleDescription: this.roleDescription,
        rolePermissions: this.permissions,
        roleIsAdmin: this.roleIsAdmin,
      }

      await this.$store.dispatch('createUserRoles', input)
      this.roleName = ""
      this.roleDescription = ""
      this.rolePermissions = []

      this.$emit('close-dialog')
    },


  },

  created(){

    this.$apollo.query({
      query:allPermisionGroups
    }).then((response)=>{
      let groups_response=response.data.userPermissionsGroups.edges;
      groups_response.forEach(group => {
        let group_id=group.node.permissionGroupUniqueId
        let group_name=group.node.permissionGroupName
        let group_children=[]

        group.node.permissionGroup.edges.forEach(children => {
          group_children.push({
            id:children.node.permissionUniqueId,
            name:children.node.permissionName
          })
        });

        this.permissiongroups.push(
            {
              id:group_id,
              name:group_name,
              children:group_children
            }
        )
      });
    })



  },

}
</script>

<style scoped>

</style>