<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col class="text-lg font-weight-medium"> List of all Default User Roles</v-col>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              @click="newUserRoleDialog=true"
              v-bind="attrs"
              v-on="on"
              color="indigo"
              class="mx-2"
              dark
              outlined
              small
          >
            <v-icon small>mdi-plus</v-icon> &nbsp; New Default Role</v-btn
          >

        </template>
        <span>Add Default Role</span>
      </v-tooltip>
    </v-row>

    <v-row>
      <div class="mt-3 w-full">
        <table class="p-3 w-100 table table-hover bg-white">
          <thead>
          <tr class="text-white bg-primary">
            <th class="whitespace-nowrap">#</th>
            <th class="whitespace-nowrap">Role Name</th>
            <th class="whitespace-nowrap">Role Description</th>
            <th class="whitespace-nowrap">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(role, index) in getDefaultRoleLists"
              :key="role.id + index"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ role.roleName }}</td>
            <td>{{ role.roleDescription }}</td>
            <td>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" @click="viewDefaultRole(role)" class="btn btn-primary btn-sm mr-2" type="button" >
                    <icon name="view" width="w-5" height="w-5" color="#ffffff"></icon>
                  </div>
                </template>
                <span>View Role</span>
              </v-tooltip>
              <v-tooltip bottom color="warning">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" @click="editDefaultRole(role)" class="btn btn-warning btn-sm mr-2" type="button">
                    <icon name="edit" width="w-5" height="w-5" color="#ffffff"></icon>
                  </div>
                </template>
                <span>Edit Role</span>
              </v-tooltip>
              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" @click="deleteDefaultRole(role.roleUniqueId)" class="btn btn-danger btn-sm" type="button">
                    <icon name="delete" width="w-5" height="w-5" color="#ffffff"></icon>
                  </div>
                </template>
                <span>Delete Role</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </v-row>
    <add-new-user-roles
      @close-dialog="newUserRoleDialog=false"
      :new-user-role-dialog="newUserRoleDialog"
    >
    </add-new-user-roles>
    <edit-user-role
      @close-dialog="editDefaultUserRole=false"
      :edit-default-user-role="editDefaultUserRole"
      :role-unique-id="roleUniqueId"
      :role-name="roleName"
      :role-description="roleDescription"
      :role-is-admin="roleIsAdmin"
      :role-permissions="rolePermissions"
      :permissiongroups="permissiongroups"

      :roleItem="roleItem"
      :permissionsList="permissionsList"
      :rolePermissions="rolePermissions"
      @changePermission="changePermission"
      @changeRemainedPermission="changeRemainedPermission"
    >

    </edit-user-role>
    <view-default-role
      @close-dialog="viewDefaultRoleDialog=false"
      :view-default-role-dialog="viewDefaultRoleDialog"
      :role-unique-id="roleUniqueId"
      :role-name="roleName"
      :role-description="roleDescription"
      :role-permissions="rolePermissions"
      :permissiongroups="permissiongroups"
      :role-is-admin="roleIsAdmin"
    >

    </view-default-role>
  </v-container>
</template>

<script>
import Icon from "@/views/Icon.vue";
import AddNewUserRoles from "@/modules/administration-panel/admin-panel-roles/AddNewUserRoles.vue";
import EditUserRole from "@/modules/administration-panel/admin-panel-roles/EditUserRole.vue";
import ViewDefaultRole from "@/modules/administration-panel/admin-panel-roles/ViewDefaultRole.vue";
import {confirmAlert} from "@/services/notificationService";
export default {
  name: "AdminPanelRoles",
  components: {AddNewUserRoles, EditUserRole, ViewDefaultRole, Icon},

  data(){
    return {
      roleUniqueId: "",
      roleName: "",
      roleDescription: "",
      roleIsAdmin: false,

      newUserRoleDialog: false,
      editDefaultUserRole: false,
      viewDefaultRoleDialog: false,

      roleItem: null,
      permissiongroups: [],
      rolePermissions: [],
      permissionsList: [],

      columns: [
        {
          key: "roleName",
          title: "Role Name"
        },
        {
          key: "roleDescription",
          title: "Role Description"
        },
      ]
    }
  },
  async mounted(){
    await this.$store.dispatch('loadDefaultUserRoles')
  },

  computed: {
    getDefaultRoleLists (){
      return this.$store.getters.getDefaultUserRoleList
    }
  },
  methods:{
    async deleteDefaultRole(roleUniqueId){
      confirmAlert("Are you sure you want to delete default role?", "Delete").then( async (data) => {
        if (data.isConfirmed) {
          await this.$store.dispatch('deleteDefaultRole',roleUniqueId)
          await this.$store.dispatch('loadDefaultUserRoles')
        }
      })
    },

    editDefaultRole(role) {
      this.roleUniqueId = role.roleUniqueId
      this.roleName = role.roleName
      this.roleDescription = role.roleDescription
      this.roleIsAdmin = role.roleIsAdmin
      this.rolePermissions = role.rolePermissions
      this.permissiongroups = role.permissiongroups

      this.editDefaultUserRole = true
    },

    viewDefaultRole(role){
      this.roleUniqueId = role.roleUniqueId
      this.roleName = role.roleName
      this.roleDescription = role.roleDescription
      this.roleIsAdmin = role.roleIsAdmin
      this.rolePermissions = role.rolePermissions
      this.permissiongroups = role.permissiongroups

      this.viewDefaultRoleDialog = true
    },

    changePermission(permission_id) {
      this.rolePermissions.forEach(permission => {
        if (permission.permission_id === permission_id) {
          permission.checked = !permission.checked
        }
      })
    },

    changeRemainedPermission(permission_id) {
      this.permissionsList.forEach(permission => {
        if (permission.permission_id === permission_id) {
          permission.checked = !permission.checked
        }
      });
    },
  }
}
</script>

<style scoped>

</style>