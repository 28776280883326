import {apolloClient} from '@/vue-apollo'
import getManagementCommitteeMeetingAttendance
    from '../../graphql/management-committee-meeting-attendance-graphql/getManagementCommitteeMeetingAttendance.gql'

import createManagementCommitteeMeetingAttendanceMutation
    from '../../graphql/management-committee-meeting-attendance-graphql/createManagementCommitteeMeetingAttendanceMutation.gql'
import updateManagementCommitteeMeetingAttendanceMutation
    from '../../graphql/management-committee-meeting-attendance-graphql/updateManagementCommitteeMeetingAttendanceMutation.gql'

import {errorAlert, successAlert} from "../../services/notificationService";


export default {
    state: {
        managementCommitteeMeetingAttendances: []
    },
    getters: {
        getManagementCommitteeMeetingAttendances(state) {
            return state.managementCommitteeMeetingAttendances
        },
        getManagementCommitteeMeetingAttendees(state) {
            return state.managementCommitteeMeetingAttendances.filter(attendee => {
                return attendee.attendanceAcceptInvitation
            })
        },
        getManagementCommitteeMeetingApologiesAttendees(state) {
            return state.managementCommitteeMeetingAttendances.filter(attendee => {
                return !attendee.attendanceAcceptInvitation
            })
        },
    },
    mutations: {
        clearManagementCommitteeMeetingAttendances(state) {
            state.managementCommitteeMeetingAttendances = []
        },
        saveManagementCommitteeMeetingAttendance(state, attendaces) {
            state.managementCommitteeMeetingAttendances = [...attendaces]
        },
        addManagementCommitteeMeetingAttendance(state, attendace) {
            state.managementCommitteeMeetingAttendances.push(attendace)
        },
        updateManagementCommitteeMeetingAttendance(state, editedAttendee) {
            state.managementCommitteeMeetingAttendances = state.managementCommitteeMeetingAttendances.map((attendee) => {
                return attendee.id == editedAttendee.id ? editedAttendee : attendee
            })
        }
    },
    actions: {
        async loadManagementCommitteeMeetingAttendances(context, meetingUniqueId = null) {
            context.commit('clearManagementCommitteeMeetingAttendances')
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getManagementCommitteeMeetingAttendance,
                variables: {
                    meetingUniqueId
                }
            }).then(async (response) => {
                let result = response.data.getManagementCommitteeMeetingAttendance
                if (result.response.code == 9000) {
                    context.commit('saveManagementCommitteeMeetingAttendance', result.data)
                } else {
                    errorAlert(result.response.message)
                }
            }).finally(async () => {
                await context.dispatch("showLoadingSpinner", false);
            })

        },
        async createManagementCommitteeMeetingAttendance(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: createManagementCommitteeMeetingAttendanceMutation,
                variables: {
                    input: input
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.createManagementCommitteeMeetingAttendanceMutation
                if (result.response.code == 9000) {
                    context.commit('addManagementCommitteeMeetingAttendance', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        async editManagementCommitteeMeetingAttendance(context, input) {
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: updateManagementCommitteeMeetingAttendanceMutation,
                variables: {
                    input: {
                        ...input
                    }
                }
            }).then(async (response) => {
                await context.dispatch("showLoadingSpinner", false);
                let result = response.data.updateManagementCommitteeMeetingAttendanceMutation
                if (result.response.code == 9000) {
                    context.commit('updateManagementCommitteeMeetingAttendance', result.data)
                    successAlert(result.response.message)
                } else {
                    errorAlert(result.response.message)
                }
            })

        },
        // async deactivateManagementCommitteeMutation(context, input) {
        //     let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
        //     await context.dispatch("showLoadingSpinner", true);
        //     if (managementCommitteeUniqueId !== "") {
        //         await apolloClient.mutate({
        //             fetchPolicy: 'no-cache',
        //             mutation: deactivateManagementCommitteeMutation,
        //             variables: {
        //                 input: {
        //                     ...input,
        //                     managementCommitteeUniqueId
        //                 }
        //             }
        //         }).then( async (response) => {
        //             await context.dispatch("showLoadingSpinner", false);
        //             let result = response.data.deactivateManagementCommitteeMutation
        //             if (result.response.code == 9000) {
        //                 context.commit('updateManagementCommittee', result.data)
        //                 successAlert(result.response.message)
        //             } else {
        //                 errorAlert(result.response.message)
        //             }
        //         })
        //     }
        // },
        // async activateManagementCommitteeMutation(context, input) {
        //     let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
        //     await context.dispatch("showLoadingSpinner", true);
        //     if (managementCommitteeUniqueId !== "") {
        //         await apolloClient.mutate({
        //             fetchPolicy: 'no-cache',
        //             mutation: activateManagementCommitteeMutation,
        //             variables: {
        //                 input: {
        //                     ...input,
        //                     managementCommitteeUniqueId
        //                 }
        //             }
        //         }).then( async (response) => {
        //             await context.dispatch("showLoadingSpinner", false);
        //             let result = response.data.activateManagementCommitteeMutation
        //             if (result.response.code == 9000) {
        //                 context.commit('updateManagementCommittee', result.data)
        //                 successAlert(result.response.message)
        //             } else {
        //                 errorAlert(result.response.message)
        //             }
        //         })
        //     }
        // },
        // async loadMyManagementCommittees(context) {
        //     let managementCommitteeUniqueId = localStorage.getItem('my-managementCommitteeUniqueId')
        //     await context.dispatch("showLoadingSpinner", true);
        //     if (managementCommitteeUniqueId !== "") {
        //         await apolloClient.query({
        //             fetchPolicy: 'no-cache',
        //             query: getMyManagementCommittees
        //         }).then( async (response) => {
        //             await context.dispatch("showLoadingSpinner", false);
        //             let result = response.data.getMyManagementCommittees
        //             if (result.response.code == 9000) {
        //                 context.commit('saveMyManagementCommittees', result.data)
        //             } else {
        //                 errorAlert(result.response.message)
        //             }
        //         })
        //
        //             .finally( async () => {
        //                 await context.dispatch("showLoadingSpinner", false);
        //             })
        //     }
        // },


    },


}


