var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('p',[_vm._v("Access to Documents generated during Meetings")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control py-3 px-4 w-full lg:w-64 box pr-8",attrs:{"type":"text","placeholder":"Search here..."},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}}),_c('svg',{staticClass:"lucide lucide-search w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","icon-name":"search","data-lucide":"search"}},[_c('circle',{attrs:{"cx":"11","cy":"11","r":"8"}}),_c('line',{attrs:{"x1":"21","y1":"21","x2":"16.65","y2":"16.65"}})])])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"5"}}),_c('v-spacer'),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"5px"},attrs:{"color":"primary white--text","fab":"","icon":"","outlined":"","right":"","small":""},on:{"click":function($event){return _vm.callToCreateFolders('Root/', true, '')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-folder-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Add New Folder")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-layout',{attrs:{"justify-start":"","wrap":""}},_vm._l((_vm.folderAndFilesList),function(folder){return _c('v-card',{key:folder.primaryKey,staticClass:"intro-y",staticStyle:{"margin":"5px"},attrs:{"link":"","max-width":"344","outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',{attrs:{"size":"90","tile":""}},[_c('v-img',{attrs:{"lazy-src":require("@/assets/folder_lazy.png"),"max-height":"100%","max-width":"100%","src":require("@/assets/folder.png")}})],1),_c('v-list-item-content',[_c('div',{staticClass:"text"},[_vm._v(_vm._s(folder.folderName))]),_c('v-list-item-subtitle',[_c('strong',[_vm._v("Files:")]),_vm._v(" "+_vm._s(folder.listOfFiles.length)+" "),_c('br'),_c('strong',[_vm._v("Created:")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(folder.folderDate))+" ")])],1),_c('v-menu',{attrs:{"offset-x":"","right":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":'/eboard_governance_repository/' +
                      folder.folderUniqueId +
                      '/' +
                      folder.primaryKey +
                      '/' +
                      folder.folderName,"dense":"","link":""}},[_c('v-item-text',[_c('v-icon',[_vm._v("mdi-folder-open")]),_vm._v(" View Contents ")],1)],1),(folder.folderOwner)?_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.loadPeopleWithFolderAccess(folder.folderUniqueId)}}},[_c('v-item-text',[_c('v-icon',[_vm._v("mdi-lock")]),_vm._v(" Access Control Matrix ")],1)],1):_vm._e(),_c('v-divider'),(folder.writeAccess || folder.folderOwner)?_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.deleteRepositoryFolder(folder.folderUniqueId)}}},[_c('v-item-text',[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Delete Folder ")],1)],1):_vm._e()],1)],1)],1)],1)}),1),_vm._l((_vm.meetingRepository),function(repository,index){return _c('div',{key:index,staticClass:"intro-y",staticStyle:{"padding":"10px"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"100%","outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"rgba(227, 150, 0, 0.56)","size":"35"}},[_vm._v("mdi-folder ")]),_vm._v(" "+_vm._s(repository.meetingTitle)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"padding":"25px"},attrs:{"icon":""},on:{"click":function($event){return _vm.openDocumentViewer(
                            repository.meetingAgendaDocumentPath
                          )}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-note-multiple-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Agenda")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"padding":"25px"},attrs:{"icon":""},on:{"click":function($event){return _vm.openDocumentViewer(
                            repository.meetingBoardPackDocumentPath
                          )}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-book-multiple ")])],1)]}}],null,true)},[_c('span',[_vm._v("Board Packs")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"padding":"25px"},attrs:{"icon":""},on:{"click":function($event){return _vm.previewMeetingMinutesMinutes(repository.meetingUniqueId)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-multiple ")])],1)]}}],null,true)},[_c('span',[_vm._v("Minutes")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{staticStyle:{"padding":"25px"},attrs:{"icon":""},on:{"click":function($event){return _vm.previewDirectives(repository.meetingUniqueId)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-delimited-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Directives")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [(_vm.hasAccess('can_add_management_meeting'))?_c('v-btn',{staticStyle:{"padding":"25px"},attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.uploadDocument(repository.meetingUniqueId)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-upload-outline ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Upload Document")])])],1),_c('v-card-text',[_c('v-row',{staticClass:"mx-",attrs:{"align":"center"}},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[_c('v-icon',[_vm._v("mdi-paperclip")]),_vm._v(" "+_vm._s(repository.meetingDocumentsCount)+" Agenda Documents")],1)]),(
                            repository.meetingSupportingDocuments.length > 0
                          )?_c('v-expansion-panel-content',{staticStyle:{}},[_c('v-list',{attrs:{"subheader":""}},_vm._l((repository.meetingSupportingDocuments),function(doc,index){return _c('v-list-item',{key:index},[_c('v-list-item-avatar',[_c('v-img',{staticStyle:{"width":"90%"},attrs:{"src":"/pdf.png"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{attrs:{"link":""}},[_vm._v(_vm._s(doc.documentName))])])],1),_c('v-list-item-icon',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDocumentViewer(doc.documentPath)}}},[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-file-eye")])],1),(doc.documentIsUploaded)?_c('v-btn',{staticClass:"ma-1",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteRepositoryDocument(doc.documentUniqueId)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)}),1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)})],2)],1),(_vm.OpenfileDialog === true)?_c('DocumentViewer',{attrs:{"OpenfileDialog":_vm.OpenfileDialog,"docValue":_vm.docValue,"title":_vm.title},on:{"CloseDocumentViwer":function($event){_vm.OpenfileDialog = false}}}):_vm._e(),_c('CreateNewFolder',{attrs:{"createFolderDialog":_vm.createFolderDialog,"isRoot":_vm.isRoot,"parentFolder":_vm.parentFolderName,"parentFolderKey":_vm.parentFolderKey},on:{"CloseCreateFolderDialog":function($event){_vm.createFolderDialog = false},"loadContents":_vm.loadRootDocumentsFromRepository}}),_c('FolderAccessControl',{attrs:{"folderAccessMatrixDialog":_vm.folderAccessMatrixDialog,"folderUniqueId":_vm.folderUniqueId},on:{"closeFolderAccessMatrixDialog":function($event){_vm.folderAccessMatrixDialog = false}}}),(_vm.supportDoc===true)?_c('BoardDocumentUploader',{attrs:{"supportDoc":_vm.supportDoc,"meetingUniqueId":_vm.meetingUniqueId},on:{"closeSupportDocumentUploader":_vm.closeSupportDocumentUploader}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }