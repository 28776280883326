import {apolloClient} from '@/vue-apollo'
import swal from 'sweetalert2'
import uploadFile from '../../graphql/eboard-documents-graphql/uploadFile.gql'
import createNewsAndUpdates from '../../graphql/eboard_news_updates/createNewsAndUpdates.gql'
import allNewsAndUpdates from '../../graphql/eboard_news_updates/allNewsandUpdates.gql'
import deleteNewsAndUpdates from "@/graphql/eboard_news_updates/deleteNewsAndUpdates.gql";
import publishNewsAndUpdates from "@/graphql/eboard_news_updates/publishNewsAndUpdates.gql"
import editNewsAndUpdates from "@/graphql/eboard_news_updates/editNewsAndUpdates.gql"
import allNewsAndUpdatesDocuments from "@/graphql/eboard_news_updates/allNewsAndUpdatesDocuments.gql"

export default {
    state: {
        newsandUpdateSupportingDocument: [],
        boardPulishedNewsUpdatesList: [],
        boardDraftedNewsUpdatesList: [],
        boardUnpublishedNewsUpdatesList: [],

        publishedNewsDocument: []

    },

    getters: {
        boardNewsPublishedList: state => state.boardPulishedNewsUpdatesList,
        boardNewsUnPublishedList: state => state.boardUnpublishedNewsUpdatesList,
        boardNewsDraftedList: state => state.boardDraftedNewsUpdatesList,

        publishedNewsDocument: state => state.publishedNewsDocument
    },
    mutations: {
        clearnewsandUpdateSuppotingDocuments(state) {
            state.newsandUpdateSupportingDocument = []
        },
        addnewsandUpdateSuppotingDocuments(state, SupportingDocument) {
            state.newsandUpdateSupportingDocument.push(SupportingDocument)

        },

        addPublishedNewsDocuments(state, newsObj) {
            state.publishedNewsDocument = newsObj
        },

        savePublishednewsandUpdate(state, publishednewsUpdates) {
            state.boardPulishedNewsUpdatesList = publishednewsUpdates
        },
        saveDraftedNewsandUpdate(state, draftednewsUpdates) {
            state.boardDraftedNewsUpdatesList = draftednewsUpdates
        },
        saveUnpublishedNewsandUpdate(state, unpublishednewsUpdates) {
            state.boardUnpublishedNewsUpdatesList = unpublishednewsUpdates
        },

    },

    actions: {

        async clearnewsandUpdateSuppotingDocumentsAction(context) {
          await context.commit('clearnewsandUpdateSuppotingDocuments')
        },


        async newsUpdateSubmitFile(context, sendData) {
            await context.dispatch("showLoadingSpinner", true);
            var SavedFile = null
            await apolloClient.mutate({
                mutation: uploadFile, loading: this.value,
                variables: {file: sendData.file,},
                update: (store, {data: {uploadFile}}) => {
                    SavedFile = {'fileName': uploadFile.fileName, 'fullFileName': uploadFile.fullFileName}
                    context.commit('addnewsandUpdateSuppotingDocuments', SavedFile)
                    context.dispatch("showLoadingSpinner", false);

                },
            }).then((response) => {
                console.log(sendData);
                console.log(response);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


        async loadBoardPublishedNewsUpdates(context) {
            context.dispatch('loadBoardDraftNewsUpdates')
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allNewsAndUpdates,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    isDraft: false,
                    isPublished: true,


                }
            }).then((response) => {
                const published = response.data.allNewsAndUpdates
                context.commit('savePublishednewsandUpdate', published)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })

        },


        async loadBoardDraftNewsUpdates(context) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allNewsAndUpdates,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    isDraft: true,
                    isPublished: false,
                }
            }).then((response) => {
                const drafted = response.data.allNewsAndUpdates
                context.commit('saveDraftedNewsandUpdate', drafted)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })

        },


        async loadBoardUnpublishedNewsUpdates(context) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: allNewsAndUpdates,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    isPublished: false,
                    isDraft: false,

                }
            }).then((response) => {
                const unpublished = response.data.allNewsAndUpdates
                context.commit('saveUnpublishedNewsandUpdate', unpublished)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })

        },


        async createNewsAndUpdates(context, UpdateData) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: createNewsAndUpdates,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    newsDescriptions: UpdateData.newsDescriptions,
                    newsTitle: UpdateData.newsTitle,
                    newsExpireDate: UpdateData.newsExpireDate,
                    newsExpireTime: UpdateData.newsExpireTime,
                    newsShowDelivery: UpdateData.newsShowDelivery,
                    newsDocuments: UpdateData.newsDocuments,
                }

            }).then((response) => {
                const success = response.data.createNewsAndUpdates.success
                if (success == true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'News created on draft successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Failed to create news',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.commit('clearnewsandUpdateSuppotingDocuments')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


        async editDraftNewsAndUpdates(context, UpdateData) {
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: editNewsAndUpdates,
                variables: {
                    boardUniqueId: localStorage.getItem("my-boardUniqueId"),
                    newsDescriptions: UpdateData.newsDescriptions,
                    newsUniqueId: UpdateData.newsUniqueId,
                    newsTitle: UpdateData.newsTitle,
                    newsExpireDate: UpdateData.newsExpireDate,
                    newsExpireTime: UpdateData.newsExpireTime,
                    newsShowDelivery: UpdateData.newsShowDelivery,
                    newsDocuments: UpdateData.newsDocuments,
                }

            }).then((response) => {
                const success = response.data.editNewsAndUpdates.success
                if (success === true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'News on Draft updated successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Failed to update news on draft',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.commit('clearnewsandUpdateSuppotingDocuments')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


        async unPublishBoardNewsAndUpdates(context, input){
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: publishNewsAndUpdates,
                variables: {
                    ...input
                }

            }).then((response) => {
                const success = response.data.publishNewsAndUpdates.success
                if (success == true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "News on draft published Successfully",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: "Failed to publish news",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.commit('clearnewsandUpdateSuppotingDocuments')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async publishBoardNewsAndUpdates(context, input){
            await context.dispatch("showLoadingSpinner", true);
            apolloClient.mutate({
                mutation: publishNewsAndUpdates,
                variables: {
                    ...input
                }

            }).then((response) => {
                const success = response.data.publishNewsAndUpdates.success
                if (success == true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: "News on draft published Successfully",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: "Failed to publish news",
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.commit('clearnewsandUpdateSuppotingDocuments')
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },

        async deleteDraftNews(context, newsUniqueId){
            await context.dispatch("showLoadingSpinner", true);
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: deleteNewsAndUpdates,
                variables: {
                    newsUniqueId: newsUniqueId
                }
            }).then( async (response)=>{
                const success = response.data.deleteNewsAndUpdates.success
                if (success === true) {
                    swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'News on draft deleted successfully',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                } else {
                    swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Fail to delete news on draft, please try again',
                        animation: false,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        },


        async getNewsAndUpdatesDocuments(context, newsUniqueId){
            await context.dispatch('showLoadingSpinner', true)
            await apolloClient.mutate({
                fetchPolicy: 'no-cache',
                mutation: allNewsAndUpdatesDocuments,
                variables: {
                    newsUniqueId: newsUniqueId
                }
            }).then(async (response)=>{
                const news = response.data.allNewsAndUpdatesDocuments
                console.log('Response News')
                console.log(news)
                context.commit('addPublishedNewsDocuments', news)
                context.dispatch("showLoadingSpinner", false);
            }).catch(async (error) => {
                await context.dispatch("showLoadingSpinner", false);
                console.error(error.messages);
            })
        }
    }

}
