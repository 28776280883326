<template>
  <div>
    <div class="col-span-12 mt-2 p-5">
      <div class="intro-y overflow-hidden lg:overflow-visible mt-8 sm:mt-0">
        <table class="table table-report sm:mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">NAME</th>
              <th class="text-start whitespace-nowrap">ROLE</th>
              <th class="text-start whitespace-nowrap">STATUS</th>
              <th class="text-start whitespace-nowrap">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="data in tableData">
              <tr v-if="data.divider !== true" :key="data" class="intro-x">
                <td>
                  <div class="flex">
                    <div class="w-10 h-10 image-fit zoom-in mr-2">
                      <img
                        v-if="data.avatar"
                        alt="Midone Tailwind HTML Admin Template"
                        class="rounded-full"
                        :src="MEDIA_SERVER + data.avatar"
                      />
                      <vue-initials-img
                        class="rounded-full"
                        v-else
                        :name="data.fullname"
                      />
                    </div>
                    <div class="flex flex-column">
                      <span
                        class="font-medium whitespace-nowrap secondary--text"
                        >{{ data.fullname }}</span
                      >
                      <div
                        class="text-slate-500 text-xs whitespace-nowrap mt-0.5"
                      >
                        {{ data.subtitle }}
                      </div>

                      <div v-if="!data.verified">
                        <v-icon x-small color="warning"
                          >mdi-clock-outline</v-icon
                        >
                        <small class="text-capitalize warning--text"
                          >Invitation Pending</small
                        >
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-start">{{ data.role }}</td>
                <td class="">
                  <div
                    v-if="data.verified"
                    class="flex items-start justify-start text-success"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide w-4 h-4 mr-2"
                    >
                      <polyline points="9 11 12 14 22 4"></polyline>
                      <path
                        d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"
                      ></path>
                    </svg>
                    Active
                  </div>
                  <div
                    v-else
                    class="flex items-start justify-start text-danger"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="pink"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-x-circle mr-2"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="15" y1="9" x2="9" y2="15"></line>
                      <line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                    In Active
                  </div>
                </td>
                <td class="table-report__action">
                  <div
                    v-if="hasAccess('can_manage_users')"
                    class="flex justify-start items-start mt-3"
                  >
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          small
                          class="text-capitalize items-center justify-center"
                          v-bind="attrs"
                          v-on="on"
                          text
                        >
                          More Actions
                        </v-chip>
                      </template>
                      <v-list>
                        <v-list-item link dense>
                          <v-list-item-title
                            @click="
                              $router.push(`/settings/user_profile/${data.id}`)
                            "
                            class="flex items-start mr-3 info--text"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-user w-4 h-4 mr-1"
                            >
                              <path
                                d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                              ></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>

                            View Profile
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense v-if="listType != 'board'">
                          <v-list-item-title
                            @click="grantUserAccesstoBoard(data)"
                            class="flex items-start"
                          >
                            <v-icon small>mdi-account-edit</v-icon>
                            Grant Board Access
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense v-if="listType == 'management'">
                          <v-list-item-title
                            @click="openUpdatePosition(data)"
                            class="flex items-start"
                          >
                            <v-icon small>mdi-account-edit</v-icon>
                            Update Management Position
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense v-if="listType == 'board'">
                          <v-list-item-title
                            @click="openUpdatePosition(data)"
                            class="flex items-start"
                          >
                            <v-icon small>mdi-account-edit</v-icon>
                            Update Board Position
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link dense>
                          <v-list-item-title
                            @click="$emit('openRevokeAccessModal', data.id)"
                            class="flex items-start text-danger"
                          >
                            <v-icon small color="pink">mdi-lock</v-icon>
                            Change User Role
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="data.verified" link dense>
                          <v-list-item-title
                            @click="blockUserAccesstoBoard(data)"
                            class="flex items-start text-danger"
                          >
                            <v-icon small color="pink">mdi-cancel</v-icon>
                            Deactivate User
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="!data.verified" link dense>
                          <v-list-item-title
                            @click="$emit('openRevokeAccessModal', data.id)"
                            class="flex items-start text-access"
                          >
                            <v-icon small color="success">mdi-power</v-icon>
                            Activate User
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="flex">
        <v-btn small rounded v-if="boardUsersPage.hasPreviousPage && listType == 'board'" style="text-transform:capitalize;" color="primary" outlined>
          Previous Page ({{boardUsersPage.previousPageNumber}})
        </v-btn>
        <v-btn small rounded v-if="nonBoardUsersPage.hasPreviousPage && listType != 'board'" style="text-transform:capitalize;" color="primary" outlined>
          Previous Page ({{nonBoardUsersPage.previousPageNumber}})
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small rounded v-if="boardUsersPage.hasNextPage && listType == 'board'" style="text-transform:capitalize;" color="primary" outlined>
          Next Page ({{boardUsersPage.nextPageNumber}})
        </v-btn>
        <v-btn small rounded v-if="nonBoardUsersPage.hasNextPage && listType != 'board'" style="text-transform:capitalize;" color="primary" outlined>
          Next Page ({{nonBoardUsersPage.nextPageNumber}})
        </v-btn>
      </div>
    </div>
    <UpdatePosition :updateType = "listType" :data = "data" :updatePositionDialog="updatePositionDialog"/>
    <GrantBoardAccess :data = "data" :grantBoardAccessDialog="grantBoardAccessDialog"/>
  </div>
</template>

<script>
import UpdatePosition from './UpdatePosition.vue'
import GrantBoardAccess from './GrantBoardAccess.vue'
export default {
  components:{
    UpdatePosition,
    GrantBoardAccess,
  },
  data() {
    return{
      updatePositionDialog:false,
      grantBoardAccessDialog:false,
      data:{}
    }
  },
  
  props: ["tableData", "listType"],
  computed: {
    boardUsersPage() {
      return this.$store.state.MembersStore.boardUsersPage;
    },
    nonBoardUsersPage() {
      return this.$store.state.MembersStore.nonBoardUsersPage;
    },
  },
  methods: {
    openUpdatePosition(data){
      this.data = data
      this.updatePositionDialog = true
    },
    grantUserAccesstoBoard(data){
      this.data = data
      this.grantBoardAccessDialog = true
    },

    async blockUserAccesstoBoard(data){
      var input = {
        "input": {
          "userId": data.id,
          "boardUniqueId": localStorage.getItem("my-boardUniqueId")
        }
      }

      await this.$store.dispatch('blockUserAccesstoBoard',input)
      await this.$store.dispatch('allBoardUsers')

    }
    
  }
};
</script>

<style></style>
