<template>
  <div class="text-center">
    <v-dialog
      v-model="OpenSectionEditor"
      persistent
      width="80%"
    >
      <v-card>
        <v-card-title class="text-h6  lighten-3">
        <v-icon left >mdi-text-box-plus-outline</v-icon>  {{ActionTitle}} 
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
                        <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                    <v-text-field
                        v-model="sectionHeaderData"
                        :counter="100"
                        label="Section Tittle"
                        prepend-icon="mdi-text-recognition"
                    >
                    </v-text-field>
                        </v-col>
                        </v-row>

          <Editor
            :content="sectionBodyData"
            :editorOption="editorOption" 
            @valueHasChange="onEditorChange"
            style="height: 500px"
          />
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseSectionEditor')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn
          v-if="editAction"
            color="success"
            outlined
            small
            :loading="submiting"
            @click="updateSection"
          >
            <v-icon small>mdi-floppy</v-icon> Save Changes
          </v-btn>
          <v-btn
            v-else
            color="success"
            outlined
            small
            :loading="submiting"
            @click="createSection"
          >
            <v-icon small>mdi-floppy</v-icon> Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>




</template>

<script>
import Editor from './ReportEditor.vue'
import createSection from '../../../graphql/eboard-report-builder-graphql/createSection.gql'
import updateSection from '../../../graphql/eboard-report-builder-graphql/updateSection.gql'


export default {
  props:['OpenSectionEditor','sectionHeaderData','sectionBodyData', 'ActionTitle','componentTemplateId','editAction','componentId'],
  components: {
    Editor
  },
  data: () => ({
        submiting:false,
        content: '',
        sectionTitle:'',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: '',
        menu1: false,
        editorOption:{
        placeholder: 'Write Contents here . . . ',
        theme: 'snow',
        toolbar:"minimal"
        }
  }),
    watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },
    },

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },

    mounted(){
      this.sectionTitle=this.sectionHeaderData
      this.content=this.sectionBodyData
    },

    methods:{
      onEditorChange(new_values){
        this.content=new_values
      },
      formatDate (date) {
          if (!date) return null

          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day,month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      createSection(){
        this.submiting=true
        this.$apollo.mutate({
          mutation:createSection,
          variables:{
            componentTitle:this.sectionHeaderData,
            componentBody:this.content,
            componentTemplateId:this.componentTemplateId,
            componentIsTable:false,
          }
        }).then((response)=>{
          setTimeout(() => {
            this.$emit('afterCreateSection',response)
          }, 2000);
        })
      },
      updateSection(){
        this.submiting=true
        this.$apollo.mutate({
          mutation:updateSection,
          variables:{
            componentId:this.componentId,
            componentTitle:this.sectionHeaderData,
            componentBody:this.content,
          }
        }).then((response)=>{
          console.log(response);
          setTimeout(() => {
            const object={
                componentId:this.componentId,
                componentTitle:this.sectionHeaderData,
                componentBody:this.content,
            }
            this.$emit('afterUpdatingSection',object)
          }, 2000);
        })
      }
    }
}
</script>