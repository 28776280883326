import AdminPanelDashboard from "@/modules/administration-panel/admin-panel-dashboard/AdminPanelDashboard.vue";
import AdminPanelInstitution from "@/modules/administration-panel/admin-panel-institutions/AdminPanelInstitution.vue";
import AdminPanelAdministrator
    from "@/modules/administration-panel/admin-panel-administrator/AdminPanelAdministrator.vue";
import AdminPanelRoles from "@/modules/administration-panel/admin-panel-roles/AdminPanelRoles.vue";
import AdminPanelInstitutionCategory
    from "@/modules/administration-panel/admin-panel-institution-category/AdminPanelInstitutionCategory.vue";
import AdminPanelReport from "@/modules/administration-panel/admin-panel-report/AdminPanelReport.vue";

export default [
    {
        path: 'admin-panel-dashboard',
        name: 'AdminPanelDashboard',
        component: AdminPanelDashboard,
        meta: {requiresAuth: true},
    },
    {
        path: 'admin-panel-institution-category',
        name: 'AdminPanelInstitutionCategory',
        component: AdminPanelInstitutionCategory,
        meta: {requiresAuth: true},
    },
    {
        path: 'admin-panel-institution',
        name: 'AdminPanelInstitution',
        component: AdminPanelInstitution,
        meta: {requiresAuth: true},
    },
    {
        path: 'admin-panel-administrators',
        name: 'AdminPanelAdministrator',
        component: AdminPanelAdministrator,
        meta: {requiresAuth: true},
    },
    {
        path: 'admin-panel-roles',
        name: 'AdminPanelRoles',
        component: AdminPanelRoles,
        meta: {requiresAuth: true},
    },
     {
        path: 'admin-panel-report',
        name: 'AdminPanelReports',
        component: AdminPanelReport,
        meta: {requiresAuth: true},
    },

]