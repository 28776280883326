<template>
  <v-container fluid>
    <div class="flex justify-content-center padding">
      <div class="flex justify-content-between full-width">
        <v-layout justify-space-around row wrap>
          <v-flex md12 style="padding: 10px" xs12>
            <v-btn
                to="/eboard_governance_repository"
                color="primary"
                link
                rounded
                small
            >
              <span class="flex center"
              ><v-icon>mdi-arrow-left</v-icon> Back
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </div>

    <v-row>
      <v-col class="d-flex" cols="12" sm="12">
        <p></p>
      </v-col>
      <v-col class="d-flex" cols="12" sm="4">
        <v-breadcrumbs :items="pathList"></v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" sm="5"> </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex" cols="12" sm="1">
        <v-tooltip v-if="folderWriteAccess" bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="callToCreateFolders(folderName+'/', false, folderUniqueId)"
              v-bind="attrs"
              v-on="on"
              right
              fab
              small
              icon
              outlined
              color="primary white--text"
              style="margin: 5px"
            >
              <v-icon>mdi-folder-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Add New Folder</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <UploadNewDocuments  v-if="folderWriteAccess" />

    <v-layout justify-start wrap class="mt-4">
      <v-card
          style="margin: 5px"
          v-for="folder in subFoldersList"
          :key="folder.folderUniqueId"
          max-width="344"
          link
          outlined
          class="intro-y"
      >
        <v-list-item three-line>
          <v-list-item-avatar tile size="90">
            <v-img
                lazy-src="@/assets/folder_lazy.png"
                max-height="100%"
                max-width="100%"
                src="@/assets/folder.png"
            >
            </v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <div class="text">{{ folder.folderName }}</div>
            <v-list-item-subtitle>
              <strong>Files:</strong> {{ folder.listOfFiles.length }} <br />
              <strong>Created:</strong> {{ folder.folderDate | formatDate }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-menu offset-x top right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                  dense
                  link
                  :to="
                    '/eboard_governance_repository/' +
                    folder.folderUniqueId +
                    '/' +
                    folder.primaryKey +
                    '/' +
                    folder.folderName
                  "
              >
                <v-item-text>
                  <v-icon>mdi-folder-open</v-icon>
                  View Contents
                </v-item-text>
              </v-list-item>
              <v-list-item
                  v-if="folderWriteAccess"
                  @click="callToCreateFolders(folder.folderName+'/', false, folder.folderUniqueId)"
                  dense
                  link
              >
                <v-item-text>
                  <v-icon>mdi-folder-plus-outline</v-icon>
                  Create Folder
                </v-item-text>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item
                  v-if="folderWriteAccess"
                  dense
                  link
                  @click="deleteRepositoryFolder(folder.folderUniqueId)"
              >
                <v-item-text>
                  <v-icon>mdi-delete</v-icon>
                  Delete Folder
                </v-item-text>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
      </v-card>
    </v-layout>

    <v-row v-if="folderAndFilesList.length > 0" class="mt-3">
      <v-col sm="6" md="6" cols="6">
        <v-col class="d-flex" cols="12" sm="12"> Files List </v-col>
        <v-card
          max-width="100%"
          outlined
          v-for="pack in folderAndFilesList[0].listOfFiles"
          :key="pack.primaryKey"
          style="margin: 3px"
          class="intro-y"
        >
          <v-list-item>
            <v-list-item-avatar tile size="50">
              <v-img src="@/assets/pdf.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-0">
                {{ pack.fileName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <small>
                  Created On:
                  <strong>{{ pack.fileDate | formatDate }}</strong></small
                ></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-menu z-index="1" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="opened = !opened"
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  >mdi-dots-vertical</v-icon
                >
              </template>
              <v-list dense>
                <v-list-item link to="">
                  <v-list-item-title @click="OpenfileDialogFunc(pack.filePath)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-file-eye-outline</v-icon
                    >
                    View Document</v-list-item-title
                  >
                </v-list-item>
                <v-list-item  v-if="folderWriteAccess" link to="">
                  <v-list-item-title
                    @click="createDocumentAnnotation(pack.primaryKey)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-file-edit-outline</v-icon
                    >
                    Make Annotations</v-list-item-title
                  >
                </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                   v-if="folderWriteAccess"
                    dense
                    link
                    @click="deleteRepositoryFolder(pack.primaryKey,'Document')"
                  >
                    <v-item-text>
                      <v-icon>mdi-delete</v-icon>
                      Delete
                    </v-item-text>
                  </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-card>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="folderAndFilesList.length"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-col>

      <v-col sm="6" md="6" cols="6">
        <v-col class="d-flex" cols="12" sm="12"> Annotated </v-col>
        <v-card
          max-width="100%"
          outlined
          v-for="pack in annotatedDocumentsList"
          :key="pack.packUniqueId"
          style="margin: 3px; background-color:#f1f0f7"
        >
          <v-list-item>
            <v-list-item-avatar tile size="50">
              <v-img src="@/assets/pdf.png"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-0">
                {{ pack.packName }}
              </v-list-item-title>
              <v-list-item-subtitle
                ><small
                  >Created by: <strong>{{ pack.packCreator }}</strong></small
                >,
                <small
                  >Created On:
                  <strong>{{
                    pack.packCreateddate | formatDate
                  }}</strong></small
                ></v-list-item-subtitle
              >
            </v-list-item-content>
            <v-menu z-index="1" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="opened = !opened"
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  >mdi-dots-vertical</v-icon
                >
              </template>
              <v-list dense>
                <v-list-item link to="">
                  <v-list-item-title @click="openPack(pack.packPath)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-file-document-edit-outline</v-icon
                    >
                    View Annotations</v-list-item-title
                  >
                </v-list-item>
                <!-- <v-list-item link to="">
                  <v-list-item-title
                    @click="startBoardPackAnnotationSharing(pack.packUniqueId)"
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-share</v-icon
                    >
                    Share Annotations</v-list-item-title
                  >
                </v-list-item> -->
                  <v-divider></v-divider>
                  <v-list-item
                   v-if="folderWriteAccess"
                    dense
                    link
                    @click="deleteRepositoryFolder(pack.packUniqueId,'Annotated Document')"
                  >
                    <v-item-text>
                      <v-icon>mdi-delete</v-icon>
                      Delete
                    </v-item-text>
                  </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-card>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="folderAndFilesList.length"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>

    <CreateNewFolder
        :parentFolderKey="parentFolderUniqueKey"
        :isRoot="isRoot"
        :parentFolder="parentFolderName"
        :createFolderDialog="createFolderDialog"
        @CloseCreateFolderDialog="createFolderDialog = false"
        @loadContents="loadSubFolderContents"
    />

    <DocumentViwer
      v-if="OpenfileDialog == true"
      :docValue="docValue"
      :OpenfileDialog="OpenfileDialog"
      :title="`View Governance Repository Document`"
      @CloseDocumentViwer="OpenfileDialog = false"
    />
  </v-container>
</template>

<script>
import CreateNewFolder from "./CreateNewFolder.vue";
import DocumentViwer from "../eboard-documents/DocumentViewer.vue";
import UploadNewDocuments from "./UploadNewDocuments.vue";

export default {
  components: {
    CreateNewFolder,
    DocumentViwer,
    UploadNewDocuments,
  },

  data() {
    return {
      docValue: null,
      OpenfileDialog: false,
      createFolderDialog: false,
      parentFolderName: "",
      isRoot: false,
      page: 1,
      parentFolderUniqueKey: ""
    };
  },

  watch: {
    parentFolderKey() {
      this.loadAllContents()
    }
  },

  methods: {
    openPack(document) {
      this.$store.dispatch("setSelectedPack", "/" + document);
      this.$router.push({
        name: "eBoardDocumentOpen",
        params: { backtrackUrl: this.$route.path },
      });
    },
    callToCreateFolders(parentFolderName, isRoot, parentFolderKey) {
      this.parentFolderName = parentFolderName;
      this.parentFolderUniqueKey = parentFolderKey;
      this.isRoot = isRoot;
      this.createFolderDialog = !this.createFolderDialog;
    },
    OpenfileDialogFunc(filename) {
      this.docValue = filename;
      this.OpenfileDialog = true;
    },
    async createDocumentAnnotation(unique_id) {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("createDocumentAnnotation", unique_id);
      await this.$store.dispatch(
        "loadAnnotatedRepositoryDocuments",
        this.$route.params.unique_id
      );
      this.$store.dispatch("showLoadingSpinner", false);
    },
    async deleteRepositoryFolder(folder, docType) {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("deleteFoldersAndContents", {
        docKey: folder,
        docType: docType,
      });
      await this.$store.dispatch(
        "loadAllFolderContentsByUniqueId",
        this.$route.params.unique_id
      );
      await this.$store.dispatch(
        "loadAnnotatedRepositoryDocuments",
        this.$route.params.unique_id
      );
      this.$store.dispatch("showLoadingSpinner", false);
    },
    async loadAllContents() {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch(
          "loadAllFolderContentsByUniqueId",
          this.$route.params.unique_id
      );
      await this.$store.dispatch(
          "loadAllSubFolderContentsByUniqueId",
          this.$route.params.unique_id
      );
      await this.$store.dispatch(
          "loadAnnotatedRepositoryDocuments",
          this.$route.params.unique_id
      );
      this.$store.dispatch("showLoadingSpinner", false);
    },
    async loadSubFolderContents() {
      await this.$store.dispatch(
          "loadAllFolderContentsByUniqueId",
          this.$route.params.unique_id
      );
      await this.$store.dispatch(
          "loadAllSubFolderContentsByUniqueId",
          this.$route.params.unique_id
      );
      await this.$store.dispatch(
          "loadAnnotatedRepositoryDocuments",
          this.$route.params.unique_id
      );
      this.$router.go("")
    }
  },

  computed: {
    parentFolderKey() {
      return this.$route.params.id;
    },
    folderName() {
      return this.$route.params.folderName;
    },
    pathList() {
      return [
        {
          text: "Dashboard",
          disabled: false,
          href: "/eboard_governance_repository",
        },
        {
          text: this.folderName,
          disabled: true,
          href: "breadcrumbs_link_1",
        },
      ];
    },
    folderWriteAccess() {
      return this.$store.state.RepositoryStore.folderWriteAccess;
    },
    folderAndFilesList() {
      return this.$store.state.RepositoryStore.governanceRepositoryFolders;
    },
    subFoldersList() {
      return this.$store.state.RepositoryStore.governanceRepositorySubFolders;
    },
    governanceRepositoryFiles() {
      return this.$store.state.RepositoryStore.governanceRepositoryFiles;
    },
    annotatedDocumentsList() {
      return this.$store.state.RepositoryStore.annotedRepositoryDocuments;
    },
    folderUniqueId() {
      return this.$route.params.unique_id
    }
  },

  async mounted() {
    await this.loadAllContents()
  },
};
</script>

<style>
</style>
