<template>
<div >
  <ToolBar/>
  <div style="padding:20px" class="intro-y">
    <router-view/>
  </div>
  
  <!-- <PSPDFKit documentUrl="document.pdf"/> -->
</div>
</template>

<script>
  // import HelloWorld from '../components/HelloWorld'
  import ToolBar from '../modules/meeting_management/e_board_shared/navigations/ToolBar.vue'
  // import PSPDFKit from '../modules/meeting_management/e_board_annotations/pspdfkit.vue'

  export default {
    name: 'Home',

    components: {
      // HelloWorld,
      ToolBar,
      // PSPDFKit
    },
  }
</script>


