<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <small
            ><strong
              >NOTE: SMS Configurations parameters can be obtained from
              <a
                target="mgov"
                style="text-decoration: none"
                href="https://mgov.ega.go.tz/"
                >m-Gov</a
              >
              platform only</strong
            ></small
          >
        </v-col>
        <v-col cols="12" sm="6"
        
          >
          <v-text-field
            v-model="SENDERID"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="SENDER ID"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="USERID"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="USER ID"
            hint="At least 8 characters"
            value="wqfasds"
            class="input-group--focused"
            @click:append="show2 = !show2"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="SENDERKEY"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="show3 ? 'text' : 'password'"
            name="input-10-2"
            label="SENDER KEY"
            hint="At least 8 characters"
            value="wqfasds"
            class="input-group--focused"
            @click:append="show3 = !show3"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="MOBILESERVICEID"
            :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="show4 ? 'text' : 'password'"
            name="input-10-2"
            label="MOBILE SERVICE ID"
            hint="At least 8 characters"
            value="Pa"
            @click:append="show4 = !show4"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },
  computed: {
    smsConfigurations() {
      return this.$store.state.SettingsStore.smsConfigurations;
    },
    SENDERID(){
      return this.$store.state.SettingsStore.smsConfigurations.configurationSenderId
    },
    USERID(){
      return this.$store.state.SettingsStore.smsConfigurations.configurationUserId
    },
    SENDERKEY(){
      return this.$store.state.SettingsStore.smsConfigurations.configurationSenderKey
    },
    MOBILESERVICEID(){
      return this.$store.state.SettingsStore.smsConfigurations.configurationMobileService
    }
  },
};
</script>