<template>
    <v-container fluid>
        <v-row>
            <v-spacer></v-spacer>
            <v-col sm='6' cols="4">
                <v-text-field
                    v-model="message"
                    outlined
                    clearable
                    rounded
                    dense
                    label="Search Packs by Board Name"
                    type="text"
                    @keypress="clickMe"
                >
                    <template v-slot:prepend>
                    <v-tooltip
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                            mdi-help-circle-outline
                        </v-icon>
                        </template>
                        Filter for any Meeting by Typing Board name
                    </v-tooltip>
                    </template>
                    <template v-slot:append>
                    <v-fade-transition leave-absolute>
                        <v-progress-circular
                        v-if="loading"
                        size="24"
                        color="info"
                        indeterminate
                        ></v-progress-circular>
                        <v-icon v-else>mdi-magnify</v-icon>
                    </v-fade-transition>
                    </template>
                </v-text-field>
            </v-col>
      </v-row>
        <v-row>
            <pack-card  v-for="pack in meetinglist" :key="pack"  :packdata="pack"></pack-card>
        </v-row>
    </v-container>
</template>

<script>
import PackCard from './PackCard.vue'
export default {
    components:{PackCard},
    data(){
        return{
            message: '',
            loading: false,
            meetinglist:[
                {
                    'MeetingName':'KIKAO CHA 44 CHA MWAKA 2021 ',
                    'packRedingStatus':"warning"
                },
                {
                    'MeetingName':'KIKAO CHA 44 CHA MWAKA 2021 ',
                    'packRedingStatus':"info"
                },
                {
                    'MeetingName':'KIKAO CHA 44 CHA MWAKA 2021 ',
                    'packRedingStatus':"success"
                },
                {
                    'MeetingName':'KIKAO CHA 44 CHA MWAKA 2021 ',
                    'packRedingStatus':"success"
                }
            ],
        }
    },
    methods: {
      clickMe () { 
        this.loading = true
        this.message = 'Wait for it...'
        setTimeout(() => {
          this.loading = false
          this.message = `You've clicked me!`
        }, 2000)
      },
    },
  async mounted() {
    await this.$store.dispatch("loadAllBoardPacks");
  },

}
</script>

<style>

</style>


