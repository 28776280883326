import { apolloClient } from "@/vue-apollo";
import swal from "sweetalert2";
import uploadFile from "../../graphql/eboard-documents-graphql/uploadFile.gql";
import addItemSupportingDocument from "../../graphql/eboard-documents-graphql/addItemSupportingDocument.gql";
import uploadProfilePicture from "../../graphql/eboard-documents-graphql/uploadProfilePicture.gql";
import updateUserProfilePicture from "../../graphql/eboard-users-graphql/updateUserProfilePicture.gql";
import deleteUploadedDocument from "../../graphql/eboard-documents-graphql/deleteUploadedDocument.gql";

export default {
  state: {
    itemAgendaSupportingDocs: [],
    itemMinutesSupportingDocs: [],
  },

  mutations: {
    cleanAgendaDocuments(state) {
      state.itemAgendaSupportingDocs = [];
    },
    addSupportingAgendaDocuments(state, file) {
      state.itemAgendaSupportingDocs.push(file);
    },
    addSupportingMinutesDocuments(state, file) {
      state.itemMinutesSupportingDocs.push(file);
    },
  },

  getters: {
    getMeetingAgendaDocuments(state) {
      return state.itemAgendaSupportingDocs;
    },
    getSelecteAgendaDocuments(state) {
      return state.itemAgendaSupportingDocs;
    },
  },

  actions: {
    async submitFile(context, sendData) {
      let SavedFile = null;
      await context.dispatch("showLoadingSpinner", true);
      await apolloClient
        .mutate({
          mutation: uploadFile,
          loading: this.value,
          variables: {
            file: sendData.file,
          },
          update: (store, { data: { uploadFile } }) => {
            SavedFile = {
              fileName: uploadFile.fileName,
              fullFileName: uploadFile.fullFileName,
              fileSource: "Uploaded",
            };
            if (sendData.type === "Agenda") {
              context.commit("addSupportingAgendaDocuments", SavedFile);
            } else if (sendData.type === "Minutes") {
              context.commit("itemMinutesSupportingDocs", SavedFile);
            }
          },
        })
        .then(() => {
          context.dispatch("addItemSupportingDocument", {
            itemKey: sendData.data.itemDetails.itemKey,
            documentName: SavedFile.fileName,
            filePath: SavedFile.fullFileName,
            fileSource: "Uploaded",
            type: "None",
          });
          context.dispatch("showLoadingSpinner", false);
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async uploadProfilePicture(context, sendData) {
      await context.dispatch("showLoadingSpinner", true);
      await apolloClient
        .mutate({
          mutation: uploadProfilePicture,
          variables: {
            file: sendData.file,
          },
        })
        .then(async (response) => {
          await apolloClient
            .mutate({
              mutation: updateUserProfilePicture,
              variables: {
                userId: sendData.userId,
                profilePicturePath:
                  response.data.uploadProfilePicture.fullFileName,
              },
            })
            .then(async () => {
              await context.dispatch("userProfileDetails", sendData.userId);
            });
          await context.dispatch("showLoadingSpinner", false);
        })
        .catch(async (error) => {
          await context.dispatch("showLoadingSpinner", false);
          console.error(error.messages);
        });
    },

    async addItemSupportingDocument(context, ItemSupportingDocument) {
      let SavedFile = null;
      await apolloClient
        .mutate({
          mutation: addItemSupportingDocument,
          variables: {
            itemKey: ItemSupportingDocument.itemKey,
            documentName: ItemSupportingDocument.documentName,
            filePath: ItemSupportingDocument.filePath,
            fileSource: ItemSupportingDocument.fileSource,
          },
          update: () => {
            console.log(ItemSupportingDocument);
            SavedFile = {
              fileName: ItemSupportingDocument.documentName,
              fullFileName: ItemSupportingDocument.filePath,
              documentSource: ItemSupportingDocument.fileSource,
            };
            if (ItemSupportingDocument.type === "Agenda") {
              context.commit("addSupportingAgendaDocuments", SavedFile);
            } else if (ItemSupportingDocument.type === "Minutes") {
              context.commit("itemMinutesSupportingDocs", SavedFile);
            }
          },
        })
        .then((response) => {
          context.rootState.AgendasStore.selecteAgenda.itemDetails.documentDetails.push(
            {
              fileName:response.data.addItemSupportingDocument.newDocument.documentName,
              fullFileName:response.data.addItemSupportingDocument.newDocument.documentPath,
              documentSource:response.data.addItemSupportingDocument.newDocument.documentSource,
            }
          );

          swal.fire({
            toast: true,
            icon: "success",
            title: "File Uploaded successfully",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async addVottingDocuments(context, sendData) {
      var SavedFile = null;
      await apolloClient
        .mutate({
          mutation: uploadFile,
          loading: this.value,
          variables: {
            file: sendData.file,
          },
          update: (store, { data: { uploadFile } }) => {
            SavedFile = {
              fileName: uploadFile.fileName,
              fullFileName: uploadFile.fullFileName,
            };
            if (sendData.type == "Agenda") {
              context.commit("addSupportingAgendaDocuments", SavedFile);
            } else if (sendData.type == "Minutes") {
              context.commit("itemMinutesSupportingDocs", SavedFile);
            }
          },
        })
        .then(() => {
          apolloClient
            .mutate({
              mutation: addItemSupportingDocument,
              variables: {
                itemKey: sendData.data.itemDetails.itemKey,
                documentName: SavedFile.fileName,
                filePath: SavedFile.fullFileName,
              },
            })
            .then((response) => {
              context.rootState.AgendasStore.selecteAgenda.itemDetails.documentDetails.push(
                {
                  fileName:
                    response.data.addItemSupportingDocument.newDocument
                      .documentName,
                  fullFileName:
                    response.data.addItemSupportingDocument.newDocument
                      .documentPath,
                }
              );
            })
            .then(() => {
              apolloClient
                .mutate({
                  mutation: addItemSupportingDocument,
                  variables: {
                    itemKey: sendData.data.itemDetails.itemKey,
                    documentName: SavedFile.fileName,
                    filePath: SavedFile.fullFileName,
                  },
                })
                .then((response) => {
                  context.rootState.AgendasStore.selecteAgenda.itemDetails.documentDetails.push(
                    {
                      fileName:
                        response.data.addItemSupportingDocument.newDocument
                          .documentName,
                      fullFileName:
                        response.data.addItemSupportingDocument.newDocument
                          .documentPath,
                    }
                  );

                  swal.fire({
                    toast: true,
                    icon: "success",
                    title: "File Uploaded  successfully",
                    animation: false,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                });
            });
        });
    },

    async deleteUploadedDocument(context, deletedDoc) {
      await apolloClient
        .mutate({
          mutation: deleteUploadedDocument,
          variables: {
            deletedDocument: deletedDoc.deletedDocument,
            deletedFrom: deletedDoc.deletedFrom,
          },
        })
        .then((response) => {
          if (response.data.deleteUploadedDocument.deleted) {
            swal.fire({
              toast: true,
              icon: "success",
              title: "Document deleted successfully",
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            swal.fire({
              toast: true,
              icon: "error",
              title:
                "Document your deleting does not exist or you dont have a permission to delete document",
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
};
