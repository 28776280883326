<template>
  <v-row justify="center">
    <v-dialog v-model="createFolderDialog" max-width="20%">
      <v-card>
        <v-card-title class="text-h7">
          <v-icon style="margin-right: 10px">mdi-folder-plus-outline</v-icon>
          Add Folder
        </v-card-title>

        <v-card-text>{{folderName}}
            Parent Folder: <strong>{{parentFolder}}</strong>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="folderName"
            dense
            label="Folder Name"
            outlined
            clearable
          ></v-text-field>
          <v-layout justify-end>
            <v-btn
              color="green darken-1"
              text
              small
              @click="$emit('CloseCreateFolderDialog')"
            >
              Cancel
            </v-btn>

            <v-btn @click="createNewFolderRepository" color="green darken-1" text small> Add Folder </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["createFolderDialog",'parentFolder','parentFolderKey','isRoot'],
  data() {
    return {
        folderName:'New Folder'
    };
  },
  methods:{
      async createNewFolderRepository(){
      this.$store.dispatch("showLoadingSpinner", true);
          await this.$store.dispatch('createNewFolderRepository',{
            parentId:this.parentFolderKey,
            folderIsRoot:this.isRoot,
            folderName:this.folderName
          })
      this.folderName='New Folder'
      this.$emit('CloseCreateFolderDialog')
      this.$store.dispatch("showLoadingSpinner", false);
      }
  }
};
</script>
