var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"text-lg font-weight-medium"},[_vm._v(" List of all Default User Roles")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"indigo","dark":"","outlined":"","small":""},on:{"click":function($event){_vm.newUserRoleDialog=true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("   New Default Role")],1)]}}])},[_c('span',[_vm._v("Add Default Role")])])],1),_c('v-row',[_c('div',{staticClass:"mt-3 w-full"},[_c('table',{staticClass:"p-3 w-100 table table-hover bg-white"},[_c('thead',[_c('tr',{staticClass:"text-white bg-primary"},[_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("#")]),_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("Role Name")]),_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("Role Description")]),_c('th',{staticClass:"whitespace-nowrap"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.getDefaultRoleLists),function(role,index){return _c('tr',{key:role.id + index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(role.roleName))]),_c('td',[_vm._v(_vm._s(role.roleDescription))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-primary btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.viewDefaultRole(role)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"view","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("View Role")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-warning btn-sm mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.editDefaultRole(role)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"edit","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Edit Role")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"btn btn-danger btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDefaultRole(role.roleUniqueId)}}},'div',attrs,false),on),[_c('icon',{attrs:{"name":"delete","width":"w-5","height":"w-5","color":"#ffffff"}})],1)]}}],null,true)},[_c('span',[_vm._v("Delete Role")])])],1)])}),0)])])]),_c('add-new-user-roles',{attrs:{"new-user-role-dialog":_vm.newUserRoleDialog},on:{"close-dialog":function($event){_vm.newUserRoleDialog=false}}}),_c('edit-user-role',{attrs:{"edit-default-user-role":_vm.editDefaultUserRole,"role-unique-id":_vm.roleUniqueId,"role-name":_vm.roleName,"role-description":_vm.roleDescription,"role-is-admin":_vm.roleIsAdmin,"role-permissions":_vm.rolePermissions,"permissiongroups":_vm.permissiongroups,"roleItem":_vm.roleItem,"permissionsList":_vm.permissionsList,"rolePermissions":_vm.rolePermissions},on:{"close-dialog":function($event){_vm.editDefaultUserRole=false},"changePermission":_vm.changePermission,"changeRemainedPermission":_vm.changeRemainedPermission}}),_c('view-default-role',{attrs:{"view-default-role-dialog":_vm.viewDefaultRoleDialog,"role-unique-id":_vm.roleUniqueId,"role-name":_vm.roleName,"role-description":_vm.roleDescription,"role-permissions":_vm.rolePermissions,"permissiongroups":_vm.permissiongroups,"role-is-admin":_vm.roleIsAdmin},on:{"close-dialog":function($event){_vm.viewDefaultRoleDialog=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }