<template>
  <v-card>
    <v-card-title class="text-h6 grey lighten-2">
      Supporting Documents
    </v-card-title>

    <v-card-text>
      <v-list subheader two-line>
        <!-- <v-subheader inset>Folders</v-subheader> -->

        <v-divider inset></v-divider>

        <v-list-item v-for="file in attachmentList" :key="file.title">
          <v-list-item-avatar>
            <v-icon :class="file.color" dark v-text="file.icon"></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="file.title"></v-list-item-title>

            <v-list-item-subtitle
              >Source: {{ file.source }}</v-list-item-subtitle
            >
            <v-list-item-subtitle>{{ file.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="$emit('OpenfileDialog', file.title, file.path)">
              <v-icon color="indigo lighten-1">mdi-file-eye-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-divider></v-divider>
    <CircularResolutionSupportingDocs style="padding-top: 30px" />

    <v-card-actions
      >
      <v-spacer></v-spacer>
      <v-btn color="pink" text @click="closefileDialog" outlined>
        Close
      </v-btn>
      <v-btn v-if="supportingDocs.length > 0" color="primary" text @click="addAttachmentToResolution" outlined>
        Save Changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CircularResolutionSupportingDocs from "./CircularResolutionSupportingDocs.vue";
export default {
  components: {
    CircularResolutionSupportingDocs,
  },
  props: ["documentList", "resolution"],
  data() {
    return {
      files: [],
    };
  },
  computed: {
    attachmentList() {
        this.resolution.resolutionDocuments.forEach((attachment) => {
          this.files.push({
            color: "blue",
            icon: "mdi-clipboard-text",
            subtitle: "Supporting Document",
            source: "Uploaded",
            title: attachment.documentName,
            path: attachment.documentPath,
          });
        });
        return this.files;
    },
    supportingDocs() {
      return this.$store.state.ResolutionStore
        .circularResolutionSupportingDocument;
    },
  },
  methods: {
    closefileDialog() {
      this.files = [];
      this.$emit("closefileDialog");
    },
    async addAttachmentToResolution(){
      var templ_file_list=[]
      this.supportingDocs.forEach(doc => {
        templ_file_list.push(
          {
            docName:doc.fileName,
            docPath:doc.fullFileName
          }
        )
      });

      var input = {
        resolutionUniqueId:this.resolution.resolutionUniqueId,
        resolutionDocuments:templ_file_list
      }

      this.$store.dispatch('addResolutionSupportingDocuments',{input:input,resolution:this.resolution})
    }
  },
};
</script>

<style></style>
