<template>
  <v-dialog v-model="OpenReportCommentsDialog" max-width="60%" persistent>
    <v-card>
      <v-card-title style="font-size: 20px">
        <span
          ><v-icon color="indigo">mdi-comment-text</v-icon> Comments on Report
          Summary</span
        >
      </v-card-title>
      <v-card-subtitle>
        <span
          >You can add comments which will appear along side with report
          sumary:</span
        >
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <template>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="assignees"
                  :items="managementMembers"
                  attach
                  chips
                  dense
                  item-text="name"
                  item-value="id"
                  label="Assigned Users"
                  multiple
                  outlined
                  small-chips
                  style="margin: 30px"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="assignees.length > 0 ? 'indigo darken-4' : ''"
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>

            <Editor
              :content="content"
              :editorOption="editorOption"
              style="height: 180px"
              @onEditorChange="updateNoticeMessage($event)"
            />
          </v-container>
        </template>
      </v-card-text>
      <v-card-text>
        <v-icon color="pink" small>mdi-alert</v-icon>
        e-Board will automatically sends email reminders of this action to the
        selected users.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="pink"
          outlined
          small
          text
          @click="$emit('CloseReportCommentsDialog')"
        >
          <v-icon small>mdi-close</v-icon>
          Close
        </v-btn>
        <v-btn color="primary" outlined small @click="commentOnWeeklyReport">
          <v-icon small>mdi-floppy</v-icon>
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "../../meeting_management/e_board_shared/fields/Editor.vue";

export default {
  props: ["OpenReportCommentsDialog", "commentReport"],
  components: {
    Editor,
  },
  data() {
    return {
      content: null,
      assignees: [],
      Greetings: "Hi",
      editorOption: {
        placeholder:
          "You can add comments which will appear along side with report sumary",
        theme: "snow",
      },
    };
  },
  computed: {
    managementMembers() {
      const mambers =
        this.$store.state.ManagementMembersStore.managementMembers;
      const temp_mambers_list = [];
      mambers.forEach((member) => {
        var userProfileTitle = "";
        if (member.userProfileTitle) {
          userProfileTitle = " - (" + member.userProfileTitle + ")";
        }
        temp_mambers_list.push({
          id: member.id,
          name:
            member.firstName + " " + member.lastName + " " + userProfileTitle,
        });
      });

      return temp_mambers_list;
    },
    selectAllManagement() {
      return this.assignees.length === this.managementMembers.length;
    },
    selectSomeMember() {
      return this.assignees.length > 0 && !this.selectAllManagement;
    },
    icon() {
      if (this.selectAllManagement) return "mdi-close-box";
      if (this.selectSomeMember) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllManagement) {
          this.assignees = [];
        } else {
          this.managementMembers.forEach((user) => {
            this.assignees.push(user?.id);
          });
        }
      });
    },
    updateNoticeMessage(new_value) {
      console.log(new_value);
      this.content = new_value;
    },
    async commentOnWeeklyReport() {
      var reportIsPublished = true;
      await this.$store.dispatch("showLoadingSpinner", true);
      if (!this.hasAccess("can_respond_to_report")) {
        reportIsPublished = false;
      }
      const tmp_asing = [];
      this.assignees.forEach((asgn) => {
        tmp_asing.push(asgn);
      });
      const comments = {
        commentDescriptions: this.content,
        commentReport: this.commentReport,
        assigneesList: tmp_asing,
      };

      await this.$store.dispatch("commentOnWeeklyReport", comments);
      const weekIdObj = {
        weekId: this.$route.params.weekId,
        reportIsPublished: reportIsPublished,
      };
      this.assignees = [];

      await this.$store.dispatch(
        "loadManagementMeetingsReportsList",
        weekIdObj
      );
      this.content = null;
      this.$emit("CloseReportCommentsDialog");
      await this.$store.dispatch("showLoadingSpinner", false);
    },
  },
};
</script>

<style></style>
