import {apolloClient} from '@/vue-apollo'
import allNoteBooks from '@/graphql/eboard-notebook-graphql/allNoteBooks.graphql'
import createNotebook from '@/graphql/eboard-notebook-graphql/createNotebook.graphql'
import deleteNotebook from '@/graphql/eboard-notebook-graphql/deleteNotebook.graphql'
import updateNotebook from '@/graphql/eboard-notebook-graphql/updateNotebook.graphql'

import {errorAlert, successAlert} from "@/services/notificationService";


export default {
    state: {
        boardNotebooks: [],
        selectedBoardNotebook: {},
    },
    getters: {
        getBoardNotebooks(state) {
            return state.boardNotebooks
        },
        getSelectedBoardNotebooks(state) {
            return state.selectedBoardNotebook
        },
    },
    mutations: {
        clearBoardNotebooks(state) {
            state.boardNotebooks = []
        },
        saveBoardNotebooks(state, notebooks) {
            state.boardNotebooks = [...notebooks]
        },
        setSelectedBoardNotebook(state, notebooks) {
            state.selectedBoardNotebook = notebooks
        },
        addNotebook(state, notebook) {
            state.boardNotebooks.push(notebook)
        },
        updateNotebook(state, notebook) {
            state.boardNotebooks = state.boardNotebooks.map((boardNotebook) => {
                return boardNotebook.id === notebook.id ? notebook : boardNotebook
            })
        },
        deleteNotebook(state, notebookUniqueId) {
            let notebookIndex = state.boardNotebooks.findIndex((noteBook) => noteBook.id === notebookUniqueId)
            state.boardNotebooks.splice(notebookIndex, 1)
        },
    },
    actions: {
        async loadBoardNotebooks(context, notebookUniqueId = null) {
            if (!notebookUniqueId) context.commit('clearBoardNotebooks')
            await context.dispatch("showLoadingSpinner", true);
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: allNoteBooks
                }).then(async (response) => {
                    let result = response.data.allNoteBooks
                    if (result.response.code === 9000) {
                        if (!notebookUniqueId) {
                            context.commit('saveBoardNotebooks', result.data ? result.data : [])
                        } else {
                            let selectedNotebook = result.data.find(notebook => notebook.id === notebookUniqueId)
                            context.commit('setSelectedBoardNotebook', selectedNotebook)
                        }
                    } else {
                        errorAlert(result.response.message)
                    }
                }).finally(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })

        },
        async deleteBoardNotebook(context, input) {
            await context.dispatch("showLoadingSpinner", true);
                await apolloClient.query({
                    fetchPolicy: 'no-cache',
                    query: deleteNotebook,
                    variables: {
                        input
                    }
                }).then(async (response) => {
                    let result = response.data.deleteNotebook
                    if (result.response.code === 9000) {
                        context.commit('deleteNotebook', input.notebookUniqueId)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                }).finally(async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })

        },
        async createNotebook(context, input) {
            await context.dispatch("showLoadingSpinner", true)
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: createNotebook,
                    variables: {
                        input
                    }
                }).then(async (response) => {
                    let result = response.data.createNotebook
                    if (result.response.code === 9000) {
                        context.commit('addNotebook', result.data)
                        successAlert(result.response.message)
                    } else {
                        errorAlert(result.response.message)
                    }
                }).finally( async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
        },
        async editNotebook(context, input) {
            await context.dispatch("showLoadingSpinner", true)
                await apolloClient.mutate({
                    fetchPolicy: 'no-cache',
                    mutation: updateNotebook,
                    variables: {
                        input
                    }
                }).then(async (response) => {
                    let result = response.data.updateNotebook
                    if (result.response.code === 9000) {
                        context.commit('updateNotebook', result.data)
                        await successAlert(result.response.message)
                    } else {
                        await errorAlert(result.response.message)
                    }
                }).finally( async () => {
                    await context.dispatch("showLoadingSpinner", false);
                })
        },
    },
}
