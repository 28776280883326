<template>
  <div class="text-center">
    <v-dialog v-model="OpenReminderDialog" persistent width="60%">
      <v-card>
        <v-card-title class="text-h6 lighten-3">
          <v-icon color="success">mdi-alarm-multiple</v-icon>
          {{ actionName }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                Directive due date:
                <strong>{{ directiveDueDate | formatDate }}</strong>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      label="Reminder Date *"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFormatted"
                    no-title
                    @input="menu1 = false"
                    :min="todaydate"
                    :max="directiveDueDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="reminderMessage"
                  :counter="100"
                  label="Reminder Message"
                  prepend-icon="mdi-text-recognition"
                >
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="pink"
            text
            outlined
            small
            @click="$emit('CloseReminderDialog')"
          >
            <v-icon small>mdi-close</v-icon> Close
          </v-btn>
          <v-btn color="success" outlined small @click="sendDirectiveReminder">
            <v-icon small>mdi-floppy</v-icon> Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "OpenReminderDialog",
    "actionName",
    "CloseReminderDialog",
    "directiveDueDate",
    "directiveUniqueId",
  ],

  components: {},

  data: () => ({
    dateFormatted: null,
    reminderMessage: null,
    todaydate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),
  methods: {
    async sendDirectiveReminder() {
      var reminderData = {
        directiveUniqueId: this.directiveUniqueId,
        reminderDate: this.dateFormatted,
        reminderMessage: this.reminderMessage,
      };
      this.dateFormatted = null;
      this.reminderMessage = null;
      await this.$store.dispatch("SendDirectivesReminder", reminderData);
      this.$emit("CloseReminderDialog");
    },
  },
};
</script>