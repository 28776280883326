<template>
  <v-container fluid app
  >
    <div class="flex items-center mb-3">
      <v-tooltip small bottom color="indigo">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              fab
              color="indigo"
              x-small
              v-bind="attrs"
              v-on="on"
              link
              to="/eboard_notebook"
          >
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>To Notebooks</span>
      </v-tooltip>
      <span class="ml-4 text-xl">
        <v-icon color="warning">mdi-notebook</v-icon>
        Notebook: <span
          class="font-medium">{{ selectedNoteBook.notebookTitle }}</span></span>
    </div>
    <v-row>
      <v-col>
        <div class="bg-white rounded-md px-4 py-4 flex flex-col">
          <span class="font-medium">Description</span>
          <span>{{ selectedNoteBook.notebookDescription }}</span>
          <v-divider></v-divider>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-layout
          style="padding-top: 10px"
          justify-space-around
      >
        <v-flex xs12 md3 max-width="100%">
          <TakeNoteSidepanel
          />
        </v-flex>
        <v-flex xs12 md9>
          <TakeNote
              style="width: 100%"
          />
        </v-flex>
      </v-layout>
    </v-row>
  </v-container>
</template>

<script>
import TakeNoteSidepanel from "./TakeNoteSidepanel.vue";
import TakeNote from "./TakeNote.vue";

export default {
  components: {
    TakeNoteSidepanel,
    TakeNote,
  },
  computed: {
    selectedNoteBook() {
      return this.$store.getters.getSelectedBoardNotebooks
    }
  },
  mounted() {
    this.$store.dispatch('loadBoardNotebooks', this.$route.params.notebookUniqueId)
  }
};
</script>

<style>
.fixed-position {
  position: fixed;
}

.transparent {
  background-color: white !important;
  opacity: 0.3;
  border-color: transparent !important;
}

.scroll {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>