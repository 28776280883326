<template>
  <v-container fluid>
    <div class="bg-white border rounded-md" v-if="this.selectedNotebookPages.pageTitle">
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="pageTitle"
              color="indigo indigo-darken-4"
              label="Page Title"
              prepend-icon="mdi-text-shadow"
            ></v-text-field>
          </v-col>
            <NoteBookPageContentEditor
                :content="content"
                :editorOption="editorOption"
                @onEditorChange="onEditorChange($event)"
            />
        </v-row>
      </v-card-title>
      <v-card-actions style="margin-top: 2em">
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          color="red"
          outlined
          small
          @click="deleteNotebookPage"
        >
          <v-icon small>mdi-delete</v-icon>
          Delete
        </v-btn>
        <v-btn
          class="ma-2"
          color="primary"
          outlined
          small
          @click="updateNotebook"
        >
          <v-icon small>mdi-floppy</v-icon>
          Save
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </div>
    <div
        v-else
        class="bg-white p-8 rounded flex flex-col justify-center items-center border rounded-md">
      <v-icon >mdi-notebook</v-icon>
      <span>No notes available</span>
    </div>
  </v-container>
</template>

<script>
import NoteBookPageContentEditor from "@/modules/meeting_management/eboard_notebook/NoteBookPageContentEditor.vue";
import {confirmAlert} from '@/services/notificationService'

export default {
  components: {
    NoteBookPageContentEditor,
  },
  data() {
    return {
      content: "",
      pageTitle: "",
      pageContent: "",
      editorOption: {
        placeholder: "Take Your Note Here",
        theme: "snow",
      },
    };
  },

  methods: {
    onEditorChange(event) {
      this.pageContent = event;
      console.log(this.pageContent)
    },
    deleteNotebookPage() {
      confirmAlert("Are you sure?", "Delete").then((data) => {
        if (data.isConfirmed) {
          let input = {
            notebookPageUniqueId: this.selectedNotebookPages.id
          }
          this.$store.dispatch('deleteBoardNotebookPage', input)
        }
      })

    },
    updateNotebook() {
      let input = {
        pageTitle: this.pageTitle,
        pageContent: this.pageContent,
        notebookPageUniqueId: this.selectedNotebookPages.id
      }
      this.$store.dispatch('editBoardNotebookPage', input)
    }
  },
  computed: {
    selectedNotebookPages() {
      return this.$store.getters.getSelectedBoardNotebookPage
    },
  },
  watch: {
    selectedNotebookPages() {
      this.pageTitle = this.selectedNotebookPages.pageTitle
      this.pageContent = this.selectedNotebookPages.pageContent
      this.content = this.selectedNotebookPages.pageContent
    }
  }
};
</script>

<style>
</style>