<template>
  <v-container fluid>
<!--    <data-table :data="getInstitutions" :columns="institutionColumns"/>-->
    <v-row>
      <v-col class="sm intro-x">
        <v-tabs v-model="tab" color="#3f51b5" show-arrows>
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon :color="tab.color" class="me-3" size="20">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Institution Report -->
          <v-tab-item style="background: rgba(39, 24, 245, 0.04)" class="rounded-md mb-15">
            <v-row>
              <div class="px-10 py-5 w-full intro-y">
                <data-table :datafld="getInstitutions.institutionLogo" :data="getInstitutions" :columns="institutionColumns" class="intro-y"/>
              </div>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "AdminPanelReport",

  data(){
    return{
      tab: true,
      tabs: [
        {title: "Institution Report", icon:"mdi-office-building", color: "orange" }
      ],

      institutionColumns: [
        {
          key: "institutionShortform",
          title: "Institution Short Name"
        },
        {
          key: "institutionDescriptions",
          title: "Institution Description"
        },
      ]
    }
  },

  computed: {
    getInstitutions (){
      return this.$store.getters.getInstitutionList;
    },
  },
}
</script>

<style scoped>

</style>