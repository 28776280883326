<template>
  <v-row justify="center">
    <v-dialog
        v-model="editInstitutionCategoryDialog"
        max-width="70%"
        persistent
    >
      <v-card>
        <v-card-title>
          <div class="row p-3">
            <icon class="pt-1 mr-3" name="institution" width="w-5" height="h-5" color="#000"></icon><span class="text-h6">Update Institution Category</span>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="12" sm="12">
                  <v-select
                      outlined
                      v-model="categoryUniqueId"
                      :items="getInstitutionCategoryList"
                      item-text="categoryName"
                      item-value="categoryUniqueId"
                      label="Institution Category Name"
                      required
                      prepend-icon="mdi-home-group"
                  ></v-select>
                </v-col>
              </v-col>
            </v-row>

            <div class="flex justify-end text-white">
              <v-btn
                  color="red"
                  class="text-capitalize mr-2"
                  right
                  small
                  @click="closeDialog"
              >
                <icon name="close" width="w-5" height="h-5" color="#ffffff" ></icon>
                <span class="text-white">Cancel</span>
              </v-btn>
              <v-btn color="primary"
                     class="text-capitalize"
                     right
                     small
                     @click="shitfInstitutionCategory"
              >
                <icon name="save" width="w-5" height="h-5" color="#ffffff" ></icon>
                Save
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "EditInstitutionCategory",
  props: [
      "editInstitutionCategory",
      "editInstitutionCategoryDialog",
      "institutionUniqueId",
  ],
  data (){
    return {
      categoryUniqueId: ""
    }
  },

  computed: {
    getInstitutionCategoryList (){
      return this.$store.getters.getInstitutionCategoryList
    },
  },

  mounted(){
    this.$store.dispatch('loadInstitutionCategoryLists')
  },

  methods: {
    closeDialog(){
      this.$emit('close-dialog')
    },

    async shitfInstitutionCategory(){
      let input = {
        institutionUniqueId: this.institutionUniqueId,
        categoryUniqueId: this.categoryUniqueId
      }

      await this.$store.dispatch('shiftInstitutionToNewCategory', input)
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>

</style>