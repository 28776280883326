<template>
  <v-card>
    <v-card-title class="text-h6 grey lighten-2">
      Vote Results for {{ motion }}
      <v-spacer></v-spacer>
      <v-tooltip bottom :color="show_resolution_viwers ? 'pink' : 'info'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="show_resolution_viwers = !show_resolution_viwers"
            v-bind="attrs"
            v-on="on"
            x-small
            fab
            :color="
                    show_resolution_viwers ? 'pink white--text' : 'blue white--text'
                  "
            ><v-icon
              v-text="
                show_resolution_viwers ? 'mdi-close' : 'mdi-account-eye-outline'
              "
            ></v-icon>
          </v-btn>
        </template>
        <span
          v-text="
            show_resolution_viwers ? 'Close' : 'People Who See Resolution'
          "
        ></span>
      </v-tooltip>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col :cols="show_resolution_viwers ? '4' : '6'">
            <v-list subheader two-line>
              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="info" dark>mdi-account-group </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Total Voters</v-list-item-title>
                  <v-list-item-title
                    v-text="voteData.voteVoters"
                  ></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action> </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="success" dark>mdi-thumb-up </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Supporters</v-list-item-title>
                  <v-list-item-title
                    v-text="voteData.votersSupport"
                  ></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action> </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="pink" dark>mdi-thumb-down </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Opposers</v-list-item-title>
                  <v-list-item-title
                    v-text="voteData.votersOppose"
                  ></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action> </v-list-item-action>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-icon class="info" dark>mdi-minus-thick </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Abstainers</v-list-item-title>
                  <v-list-item-title
                    v-text="voteData.votersAbstain"
                  ></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action> </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-btn
              v-text="
                !show_votter_summary
                  ? 'View Votters Responses'
                  : 'Hide Votters Responses'
              "
              @click="show_votter_summary = !show_votter_summary"
              small
              class="mb-2"
              :color="!show_votter_summary ? 'success' : 'pink'"
              block
              outlined
              plain
            >
              <v-icon small>mdi-ballot-outline</v-icon>
            </v-btn>

            <v-list
              v-if="show_votter_summary"
              dense
              color="rgba(161, 173, 230, 0.123)"
            >
              <v-subheader>
                <h2 class="intro-y text-lg font-medium mr-auto mt-2">
                  Voted Members
                </h2>
                <v-spacer></v-spacer>
                <span class="text-lg">Acceptance: {{acceptance}}%</span>
              </v-subheader>
              <v-list-item-group  color="primary">
                <v-list-item
                  v-for="(item, i) in resolutionsVotingDetails.votedVoters"
                  :key="i"
                >
                  <v-list-item-icon>
                    {{ i + 1 }}
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.voterName"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    {{ item.voterSelection }}
                  </v-list-item-icon>
                  <v-list-item-icon>
                    <v-icon
                      :color="
                        item.voterSelection == 'Supported'
                          ? 'success'
                          : item.voterSelection == 'Opposed'
                          ? 'pink'
                          : 'info'
                      "
                      v-text="
                        item.voterSelection == 'Supported'
                          ? 'mdi-thumb-up'
                          : item.voterSelection == 'Opposed'
                          ? 'mdi-thumb-down'
                          : 'mdi-minus-thick'
                      "
                    ></v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col :cols="show_resolution_viwers ? '4' : '6'">
            <v-list dense color="rgba(161, 173, 230, 0.123)">
              <v-subheader>Un-Voted Members</v-subheader>
              <v-list-item-group  color="primary" >
                <v-list-item
                  v-for="(item, i) in resolutionsVotingDetails.unvotedVoters"
                  :key="i"
                  disabled
                  
                >
                  <v-list-item-icon>
                    {{ i + 1 }}
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.voterName"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col v-if="show_resolution_viwers" cols="4">
            <v-list dense color="rgba(161, 173, 230, 0.123)">
              <v-subheader>People Viewed this Resolution</v-subheader>
              <v-list-item-group  color="primary">
                <v-list-item
                  v-for="(item, i) in resolutionsVotingDetails.resolutionViwers"
                  :key="i"
                  disabled
                >
                  <v-list-item-icon>
                    {{ i + 1 }}
                  </v-list-item-icon>
                  <v-list-item-content>
                    
                    <v-list-item-title
                      
                   
                      v-text="item.viwerName"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text>
      <v-select
        v-if="resolution.resolutionIsActive"
        v-model="decisionOutcome"
        :items="[
          { name: 'Approved', value: true },
          { name: 'Not Approved', value: false },
        ]"
        item-text="name"
        item-value="value"
        label="Outcome"
        prepend-icon="mdi-star"
        required
      ></v-select>
      <v-text-field
        v-if="resolution.resolutionIsActive"
        v-model="outcomeDescription"
        :counter="100"
        :rules="nameRules"
        label="Descriptions *"
        prepend-icon="mdi-text-long"
        required
      >
      </v-text-field>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" outlined text @click="closeVoteDialog">
        Close
      </v-btn>
      <v-btn
        color="primary"
        outlined
        text
        @click="circularResolutionVoteResult"
        v-if="resolution.resolutionIsActive"
      >
        Save OutCome
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["voteData", "motion", "primaryKey", "resolution"],
  data() {
    return {
      show_votter_summary: false,
      show_resolution_viwers: false,
      outcomeDescription:'',
      files: [],
      descriptions: "",
      decisionOutcome: false,
      nameRules: [
        (v) => !!v || "Event description is required",
        (v) =>
          v.length <= 100 || "Description must be less than 100 characters",
      ],
    };
  },

  watch: {
    voteDescription() {
      this.descriptions = this.resolution.resolutionDescriptions;
    },
  },

  computed: {
    voteDescription() {
      return this.resolution.resolutionDescriptions;
    },
    ...mapGetters({
      resolutionsVotingDetails: "getResolutionsVotingDetails",
    }),
    acceptance(){
      try {
          var votting = this.resolutionsVotingDetails.votedVoters
          var supporters=0

          votting.forEach(vote => {
            if (vote.voterSelection=="Supported") {
              supporters+=1
            }
          });
          var accptance=(supporters/votting.length)*100
          console.log(accptance);
          return accptance

      } catch (error) {
        console.log(error);
        return 0
      }
    }
  },

  methods: {
    async circularResolutionVoteResult() {
      await this.$store.dispatch("circularResolutionVoteResult", {
        outcomeDescription: this.outcomeDescription,
        resolutionUniqueId: this.primaryKey,
        voteOutcome: this.decisionOutcome,
      });
      await this.$store.dispatch("loadBoardResolutions");
      this.$emit("closeVoteDialog");
    },

    closeVoteDialog() {
      this.$emit("closeVoteDialog");
    },
  },

  async mounted() {
    await this.$store.dispatch(
      "loadResolutionVottingDetails",
      this.resolution.resolutionUniqueId
    );
  },
};
</script>

<style>
</style>
