import { apolloClient } from "@/vue-apollo";
import getAuditTrail from "../../graphql/eboard-audit-graphql/getAuditTrail.gql";
import swal from "sweetalert2";

export default {
  state: {
    auditTrailList: [],
  },

  mutations: {
    addAuditTrail(state, logs) {
      state.auditTrailList = logs;
    },
  },

  getters: {
    getAuditTrail(state) {
      return state.auditTrailList;
    },
  },

  actions: {
    async loadBoardAuditTrails(context,filtering=null) {
      if (filtering==null) {
        context.commit("addAuditTrail", []);
      }
      await apolloClient
        .query({
          fetchPolicy: "no-cache",
          query: getAuditTrail,
          variables: {
            filtering: filtering,
          },
        })
        .then((response) => {
          const results = response.data.getAuditTrail;
          if (results.response.status) {
            context.commit("addAuditTrail", results.data);
          } else {
            swal.fire({
              toast: true,
              icon: "error",
              title: results.response.message,
              animation: false,
              position: "top-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
};
