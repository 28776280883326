import { apolloClient } from '@/vue-apollo'
import allManagementMembersManagementUniqueId from '../../graphql/eboard-managements-graphql/allManagementMembersBoardUniqueId.gql'


export default {
  state: {
    managementsMembers: [],
  },
  getters: {
    getManagementsMembers(state) {
      return state.managementsMembers
    }
  },
  mutations: {
    clearManagementsMembers(state) {
      state.managementsMembers = []
    },
    saveManagementsMembers(state, members) {
      state.managementsMembers = [...members]
    }
  },
  actions: {
    async loadManagementsMambers(context) {
      context.commit('clearManagementsMembers')
      if (localStorage.getItem('my-managementUniqueId')!=="") {
        await apolloClient.query({
          fetchPolicy: 'no-cache',
          query: allManagementMembersManagementUniqueId,
          variables: {
            managementUniqueId: localStorage.getItem('my-managementUniqueId')|| ""
          }
        }).then((response) => {
          context.commit('saveManagementsMembers', response.data.allManagementMembersManagementUniqueId)
        })
      }
    },
  },


}



