<template>
  <v-card tile>
    <v-card-title>
      <strong><span contenteditable="true" style="padding-left:10px"
                    class="maincolor">e-Board Reports Builder</span></strong>
    </v-card-title>
    <v-card-text>
      <v-card
          class="mx-auto my-12"
          max-width="100%"
          style="padding-left:30px;padding-right:30px"
      >
        <template slot="progress">
          <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
          ></v-progress-linear>
        </template>


        <v-card-title class="mt-5">

          <v-row>
            <v-spacer></v-spacer>
            <v-badge
                color="white"
                left
                transition="slide-x-transition"
            >
              <v-hover v-model="hover">
                <img style="width:100%; height:80px" src="/arm.png" alt="Emblem">
              </v-hover>
            </v-badge>
            <v-spacer></v-spacer>
            <v-col sm="6">
              <div id="reportTopHeaderSub">
                <center><h3>{{ report_top_details.templateTitle }} </h3></center>
                <center><h5 contenteditable="">{{ report_top_details.templateSubtitle }}</h5></center>
                <center><h4 contenteditable="">{{ report_top_details.templateInstitutionName }}</h4></center>
              </div>
            </v-col>
            <v-badge
                :value="topheaderhoverd"
                color="indigo"
                content="Click to Edit Report Header"
                left
                transition="slide-x-transition"
            >
              <v-hover v-model="topheaderhoverd">
                <v-btn icon @click="editSingeContents('reportTopHeaderSub')">
                  <v-icon small>mdi-pencil-outline</v-icon>
                </v-btn>
              </v-hover>
            </v-badge>
            <v-spacer></v-spacer>
            <v-badge
                color="white"
                right
                transition="slide-x-transition"
            >
              <v-hover v-model="hover2">
                <img style="width:100%; height:60px" :src="MEDIA_SERVER+''+report_top_details.templateInstitutionLogo"
                     alt="Emblem">
              </v-hover>
            </v-badge>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-title>


        <v-divider class="mx-4"></v-divider>
        <v-card-title contenteditable="">
          <center>{{ report_top_details.templateReportName }}</center>
        </v-card-title>

        <v-card-text>
          <v-list
              subheader
              three-line
              :key="compl_key"

          >
            <v-list-item v-for="component in report_components_details" :key="component.componentId">
              <v-list-item-content>
                <v-list-item-title>
                  <v-badge
                      v-if="component.componentIsTable"
                      :value="component.hovered"
                      color="indigo"
                      content="Click to Edit Table Contents"
                      right
                      transition="slide-x-transition"
                  >
                    <v-hover v-model="component.hovered">
                      <v-btn icon
                             @click="editTableContents(component.sectionHeader,component.sectionBody,component.componentId)">
                        <v-icon small>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </v-hover>
                  </v-badge>
                  <v-badge
                      v-else
                      :value="component.hovered"
                      color="indigo"
                      content="Click to Edit Section Contents"
                      right
                      transition="slide-x-transition"
                  >
                    <v-hover v-model="component.hovered">
                      <v-btn icon
                             @click="editSectionContents(component.sectionHeader,component.sectionBody,component.componentId)">
                        <v-icon small>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </v-hover>
                  </v-badge>
                  <div><strong :id="component.sectionHeader">{{ component.componentTitle }}</strong>
                  </div>
                </v-list-item-title>
                <v-list-item-content>
                  <div class="text-justify text--secondary" :id="component.sectionBody">
                    <div class="reportComponents" v-html="component.componentBody">{{ component.componentBody }}</div>
                  </div>
                </v-list-item-content>
              </v-list-item-content>
            </v-list-item>


          </v-list>
        </v-card-text>
      </v-card>

    </v-card-text>
    <center><p style="padding-bottom:0px">Add Report Components </p></center>
    <v-card-actions style="padding-top:0px">
      <v-spacer></v-spacer>
      <v-btn
          class="ma-2"
          icon
          title="Add Section"

          color="primary"
          @click="SectionEditor = true"
      >
        <v-icon>mdi-text-box-plus-outline</v-icon>

      </v-btn>
      <v-btn icon
             class="ma-2"
             color="pink"
             title="Add Table"


             @click="TableEditor=true"

      >
        <v-icon>mdi-table-large-plus</v-icon>

      </v-btn>

      <v-spacer></v-spacer>
      <MinuteNote :OpenMinuteNoteEditor="MinuteNoteEditor" @CloseMinuteNoteEditor="MinuteNoteEditor=false"/>
      <NewAction :OpenActionEditor="ActionEditor" :actionName="`Add Action`" @CloseActionEditor="ActionEditor=false"/>
      <ReportSection @afterUpdatingSection="updatingSectionSignal" :componentId="componentId" :editAction="editAction"
                     :key="newSectionKey" @afterCreateSection="updateComponents" :componentTemplateId="reportKey"
                     :ActionTitle="ActionTitle" :OpenSectionEditor="SectionEditor"
                     @CloseSectionEditor="CloseSectionEditorfunction" :sectionHeaderData="sectionHeaderData"
                     :sectionBodyData="sectionBodyData"/>
      <ReportTable :componentTemplateId="reportKey" @afterCreateSection="updateComponents"
                   :ActionTitle="TableActionTitle" :OpenTableEditor="TableEditor"
                   @CloseTableEditor="CloseTableEditorfunction" :tableBodyData="tableBodyData"
                   :tableHeaderData="tableHeaderData"/>
    </v-card-actions>

    <v-card-text>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
          class="ma-2"
          outlined
          small
          color="pink"
          @click="closeReportBuilderPanel"

      >
        <v-icon small>mdi-close</v-icon>
        Close
      </v-btn>
      <v-btn class="ma-2" color="accent" small outlined>
        <v-icon small>mdi-archive-eye-outline</v-icon>
        Priview
      </v-btn>

      <v-btn :loading="loading3" small color="primary">
        <v-icon small>mdi-text-box-multiple-outline</v-icon>
        Save as Draft
      </v-btn>

      <v-btn :loading="loading3" small color="success">
        <v-icon small>mdi-book-check-outline</v-icon>
        Save as Report
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>

  </v-card>
</template>

<script>

// import AgendaTopDetails from '../eboard-agendas/AgendaTopDetails.vue'
import NewAction from '../eboard-actions/NewAction.vue'
import ReportSection from './ReportSection.vue'
import ReportTable from './ReportTable.vue'
import filterReport from '../../../graphql/eboard-report-builder-graphql/filterReportTemplates.gql'


export default {
  props: ['templateUniqueId'],
  components: {
    // AgendaTopDetails,
    NewAction,
    ReportSection,
    ReportTable
  },

  data() {
    return {
      reportKey: null,
      compl_key: 1,
      panelKeyGround: 80,
      componentId: null,
      editAction: false,
      newSectionKey: 0,
      tableHeaderData: null,
      zIndex: 0,
      topheaderhoverd: false,
      loading3: false,
      hover: false,
      hover2: false,
      sectionhover1: false,
      MinuteNoteEditor: false,
      ActionEditor: false,
      TableEditor: false,
      SectionEditor: false,
      sectionHeaderData: '',
      sectionBodyData: '',
      ActionTitle: "Add Report Section",
      TableActionTitle: "Add Report Table",
      reportComponents: [],
      report_details: null,
      report_top_details: null,
      report_components_details: [],
      tableBodyData: `<p><strong>2.0 CHALLENGE AND REMEDIAL ACTION</strong></p><figure class="table"><table><thead><tr><th rowspan="2"><strong>S/No.</strong></th><th rowspan="2"><strong>Challenges Encountered</strong></th><th colspan="2"><strong>Quarterly Actual Expenditure&nbsp;</strong></th><th colspan="2" rowspan="2"><strong>Remedial Action&nbsp;</strong></th></tr><tr><th><strong>Quarter</strong></th><th><strong>Cumulative</strong></th></tr></thead><tbody><tr><td>1.0</td><td>Uncertainty of sustainable funding from central Government and difficulties in collecting debts from customers who are Public Institutions depending on Government subvention.</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">Strengthening efforts of finding other sources of &nbsp; revenue and strengthening mechanisms of collecting debts from customers especially by using technology.</td></tr><tr><td colspan="6"><strong>RECURRENT EXPENDITURE</strong></td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">&nbsp;</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td colspan="2">&nbsp;</td></tr><tr><td colspan="5"><strong>No. of Employees as at the end of the Quarter</strong></td><td>191</td></tr></tbody></table></figure><p><strong>i. ANNEXURES</strong></p>`,

    }
  },

  methods: {
    editSectionContents(sectionheader01, sectionbody01, componentId) {
      var currentheaderContents = document.getElementById(sectionheader01).innerHTML
      var currentbodyContents = document.getElementById(sectionbody01).innerHTML
      this.sectionHeaderData = currentheaderContents
      this.sectionBodyData = currentbodyContents
      this.ActionTitle = "Edit Report Section"
      this.editAction = true
      this.SectionEditor = true
      this.componentId = componentId
    },
    editTableContents(sectionheader01, sectionbody01, componentId) {
      console.log("componentIdPPPPPPPPPPPPPPPPPPPPPPPPP");

      var currentheaderContents = document.getElementById(sectionheader01).innerHTML
      var currentbodyContents = document.getElementById(sectionbody01).innerHTML
      console.log(currentheaderContents);
      console.log(currentbodyContents);

      this.tableHeaderData = currentheaderContents
      this.tableBodyData = currentbodyContents
      this.TableActionTitle = "Edit Report Table"
      this.editAction = true
      this.TableEditor = true
      this.componentId = componentId
    },
    CloseSectionEditorfunction() {
      this.ActionTitle = 'Add Report Section'
      this.SectionEditor = false
      this.sectionHeaderData = ''
      this.editAction = false
      this.sectionBodyData = ''
      this.componentId = null
    },
    CloseTableEditorfunction() {
      this.TableActionTitle = 'Add Report Table'
      this.TableEditor = false
    },
    loadCreatedTemplate() {
      this.$apollo.query({
        query: filterReport,
        variables: {
          templateUniqueId: this.templateUniqueId,
        }
      }).then((response) => {
        setTimeout(() => (
            this.overlay = false,
                this.templateUniqueId = response.data.templateUniqueId,
                this.reportBuilderDialog = true
        ), 2000)
      })
    },
    closeReportBuilderPanel() {
      this.$emit('closeReportBuilderPanel');
      this.$router.push({name: 'eBoardReportBuilderDrafts', params: {reload: true}})
    },
    updateComponents(newComponents) {
      this.CloseSectionEditorfunction();
      this.newSectionKey++
      const component = newComponents.data.newSection.components;
      this.report_components_details.push({
        'componentId': component.primaryKey,
        'sectionBody': component.componentUniqueId,
        'sectionHeader': component.componentHeader,
        'hovered': component.componentHovered,
        'componentTitle': component.componentTitle,
        'componentBody': component.componentBody,

      })

    },
    updatingSectionSignal(object) {
      const objIndex = this.report_components_details.findIndex((obj => obj.componentId == object.componentId));
      var stoper = 0
      this.report_components_details.forEach(component => {
        if (stoper == objIndex) {
          console.log(component);
          console.log(component.componentId);
          console.log(component.sectionBody);
          console.log(component.sectionHeader);
          console.log(document.getElementById(component.sectionHeader));
          document.getElementById(component.sectionHeader).innerHTML = object.componentTitle
          console.log(document.getElementById(component.sectionBody));
          document.getElementById(component.sectionBody).innerHTML = object.componentBody
        }
        stoper++
      });
      this.newSectionKey++
      this.SectionEditor = false


    }
  },
  created() {
    this.$apollo.query({
      query: filterReport,
      variables: {
        templateUniqueId: this.templateUniqueId,
      }
    }).then((response) => {
      console.log("response.data");
      const data = response.data.filterReportTemplates.edges[0].node;
      this.reportKey = data.primaryKey
      this.report_top_details = {
        "templateTitle": data.templateTitle,
        "templateNumber": data.templateNumber,
        "templateIsDraft": data.templateIsDraft,
        "templateUniqueId": data.templateUniqueId,
        "templateSubtitle": data.templateSubtitle,
        "templateIsReport": data.templateIsReport,
        "templateReportName": data.templateReportName,
        "templateInstitutionLogo": data.templateInstitutionLogo,
        "templateInstitutionName": data.templateInstitutionName
      }

      data.componentTemplate.edges.forEach(component => {
        this.report_components_details.push({
          'componentId': component.node.primaryKey,
          'sectionBody': component.node.componentUniqueId,
          'sectionHeader': component.node.componentHeader,
          'hovered': component.node.componentHovered,
          'componentTitle': component.node.componentTitle,
          'componentIsTable': component.node.componentIsTable,
          'componentBody': component.node.componentBody,

        })
      });
    })
  }

}
</script>

<style>
figure table, figure table > thead > tr > th, figure table > tbody > tr > td {
  border: 1px solid black;
  border-collapse: collapse;
}

figure table > thead > tr > th {
  background-color: #e8eaeb;
}
</style>

