<template>
  <div>
    <v-card class="scroll">
      <v-list color="rgba(161, 173, 230, 0.123)" v-if="meeting.length > 0">
        <v-subheader>Meetings Notifications</v-subheader>
        <v-list-item v-for="notice in meeting" :key="notice.invitationUniqueId">
          <v-list-item-avatar  tile size="50" >
            <v-img :aspect-ratio="3 / 3" src="@/assets/workshop.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >{{ notice.invitationGreetings }}
              {{ notice.inviteeName }}</v-list-item-title
            >
            <v-list-item-subtitle
              v-html="notice.invitationBody"
            ></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-actions>
            <v-tooltip bottom :color="optToApology ? 'blue' : 'pink'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    optForApologyResponce(notice);
                    optToApology = !optToApology;
                  "
                  v-bind="attrs"
                  v-on="on"
                  title=""
                  right
                  fab
                  x-small
                  :color="
                    optToApology ? 'blue white--text' : 'pink white--text'
                  "
                  style="margin: 5px"
                  ><v-icon
                    v-text="
                      optToApology ? 'mdi-window-close' : 'mdi-hands-pray'
                    "
                  ></v-icon>
                </v-btn>
              </template>
              <span
                v-text="
                  optToApology ? 'Cancel Apology' : 'Apology for Invitation'
                "
              ></span>
            </v-tooltip>
            <v-tooltip v-if="!optToApology" bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  title=""
                  right
                  fab
                  x-small
                  @click="submitApologyResponce(notice, true)"
                  color="success white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-check-all</v-icon>
                </v-btn>
              </template>
              <span>Accept Invitation</span>
            </v-tooltip>
          </v-list-item-actions>
        </v-list-item>
        <v-container v-if="optToApology">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="10" sm="10">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="content"
                  label="State your apology reasons"
                  outlined
                  clearable
                  dense
                  :rules="nameRules"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col cols="2" sm="2">
              <v-btn
                class="blue--text mr-2"
                fab
                x-small
                @click="submitApologyResponce(selectedNotice, false)"
              >
                <v-icon>mdi-share-all-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list>

      <v-divider></v-divider>

      <v-list color="rgba(161, 173, 230, 0.123)" v-if="resolutions.length > 0">
        <v-subheader>Resolution Notifications</v-subheader>
        <v-list-item
          v-for="notification in resolutions"
          :key="notification.noticeUniqueId"
        >
          <v-list-item-avatar  tile size="60" >
            <v-img :aspect-ratio="3 / 3" src="@/assets/meeting.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >{{ notification.noticeGreetings }}
              {{ notification.noticeName }}</v-list-item-title
            >
            <v-list-item-subtitle
              v-html="notification.noticeMessage"
            ></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              link
              to="/eboard_flying_minutes"
              :class="fav ? 'blue--text' : ''"
              icon
            >
              <v-icon>mdi-arrow-right-top</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="$emit('closeContentMenu')"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["meeting", "resolutions"],
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    dialog: false,
    content: "",
    optToApology: false,
    nameRules: [(v) => !!v || "Apology reason is required"],
    valid: true,
    selectedNotice: null,
  }),
  methods: {
    optForApologyResponce(notice) {
      console.log(notice);
      this.selectedNotice = notice;
    },
    async submitApologyResponce(notice, noticeAccepted) {
      var valid_data = true;

      if (noticeAccepted == false) {
        valid_data = this.$refs.form.validate();
      }

      if (valid_data == true) {
        this.$store.dispatch("showLoadingSpinner", true);
        var data = {
          noticeAccepted: noticeAccepted,
          noticeMeeting: notice.invitationMeeting,
          noticeReplyExplanations: this.content,
        };
        await this.$store.dispatch("replyMeetingNotice", data);
        await this.$store.dispatch("loadBoardInvitations");
        this.dialog = false;
        this.$store.dispatch("showLoadingSpinner", false);
      } else {
        return;
      }
    },
  },
};
</script>
