

export default {
    state: {
        currentManagementMeeting:null,
    },

    mutations:{
        cleanCurrentMeeting(state){
            state.currentManagementMeeting=null
        },
        updateCurrentMeeting(state,meetingUniqueId){
            state.currentManagementMeeting=meetingUniqueId
        }
    },

    actions: {
        async setManagementCurrentMeeting(context,meetingUniqueId){
            context.commit('cleanCurrentMeeting')
            context.commit('updateCurrentMeeting',meetingUniqueId)
        },
    }
}


