<template>
  <v-row justify="center">
    <v-dialog v-model="showWeeklyReportModal" max-width="1000px" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h6"
            ><v-icon>mdi-file-plus-outline</v-icon> Basic Report Details
          </span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="reportDesignation"
                  :items="designationsList()"
                  clearable
                  item-text="designationShortForm"
                  item-value="designationUniqueId"
                  label="Designation / Department *"
                  required
                  prepend-icon="mdi-account-star-outline"
                  outlined
                  dense
                >
                </v-select>
              </v-col>


              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  dense
                  outlined
                  v-model="reportTittle"
                  :counter="200"
                  :rules="nameRules"
                  label="Report Title *"
                  prepend-icon="mdi-text-recognition"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="pink"
            outlined
            small
            @click="$emit('closeNewReportModal')"
          >
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
          <v-btn
            :disabled="loading"
            :loading="loading"
            class="white--text"
            color="blue darken-1"
            outlined
            small
            @click="createNewManagementReport"
          >
            <v-icon>mdi-send-check-outline</v-icon>
            Generate Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  data: () => ({
    loading: false,
    reportTittle: "",
    nameRules: [(v) => !!v || "Title is required"],
    reportDesignation: "",
    weeklyReportObj: {},
    reportTypes: [
      { id: "WEEKLY", name: "Weekly Reports"},
      { id: "MONTHLY", name: "Monthly Reports"},
      { id: "QUARTERLY", name: "Quarterly Reports"},
      { id: "ANNUALLY", name: "Annual Reports"},
    ]
  }),
  props: ["showWeeklyReportModal", "templateUniqueId", "weekUniqueId", "reportType"],

  computed: {
    meetingsList() {
      return this.$store.state.ManagementMeetingsStore.managementMeetingsList;
    },
  },

  methods: {
    async createNewManagementReport() {
      this.weeklyReportObj.reportTitle = this.reportTittle;
      this.weeklyReportObj.reportType = this.reportType;
      this.weeklyReportObj.weekUniqueId = this.weekUniqueId;
      this.weeklyReportObj.designationUniqueId = this.reportDesignation;

      await this.$store.dispatch("createManagementMeetingReport", this.weeklyReportObj);

      this.reportTittle = "";
      this.reportDesignation = "";

      this.$emit("closeNewReportModal");
    },
  },
};
</script>
